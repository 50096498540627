// const Enum = {
//     TikTok: 1,
//     Youtube: 2,
//     Facebook: 3,
//     Instagram: 4,
//     propertiesMedia: {
//         1: {name: "TikTok", value: 1, code: "T"},
//         2: {name: "Youtube", value: 2, code: "Y"},
//         3: {name: "Facebook", value: 3, code: "F"},
//         3: {name: "Instagram", value: 3, code: "I"}
//     }
// }
const Enum = {
    propertiesTarget: {
        1: {val: '直播带货', icon:''},
        2: {val: '品牌传播', icon:''},
        3: {val: '商品种草', icon:''},
        4: {val: '商品销售', icon:''},
        5: {val: '商品好评', icon:''},
        6: {val: '开箱视频', icon:''},
    },
    propertiesMedia: {
        1: {val: 'TikTok', icon:'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/dy.png'},
        2: {val: 'Youtube', icon:'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/product/Youtube.png'},
        3: {val: 'Facebook', icon:'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/product/Facebook.png'},
        4: {val: 'Instagram', icon:'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/product/Instagram.png'},
    }
}
export default Enum