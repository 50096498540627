import React, { useState, useEffect, memo } from 'react'
import withRouter from '../../../utils/withRouter'
import '../../../asset/css/BloggerRanking.scss'
import '../../../asset/css/SensationDetail.scss'
import { CalendarOutlined,DownSquareTwoTone,UpSquareTwoTone } from '@ant-design/icons';
import { Select, Checkbox, List, notification, Spin, Skeleton, Divider, Image, Tooltip, InputNumber, Button } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../../utils/redux/index'
import domtoimage from 'dom-to-image';
import intl from 'react-intl-universal';
import * as Api from '../../../service/https'
import toKMB from '../../../utils/KMB'
import { Area, Bar, Pie } from '@ant-design/plots';
import moment from 'moment';
import geolocation from '../../../utils/geolocation'
import ReactEcharts from 'echarts-for-react';
import ChildComp from '../../../components/Sensation/res'
import three from '../../../utils/three';
import SmallShopBlogger from "../../../components/Goods/SmallShopBlogger";

const { Option } = Select;

const SmallShopDetail = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [textJson, setTextJson] = useState(intl.get('Index'))
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）

    const [widths, setWidths] = useState(0)

    const bloggerId = props.params.bloggerId

    const openNotificationWithIcon = (type, message = '') => {
        notification[type]({
            description: message, top: 100, duration: 3
        });
    };

    useEffect(() => {
        store.subscribe(storeChange);
        // console.log(props)
        getShopDetail()
        getCargo()
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
        }
    }, [])

    const storeChange = () => {
        setSate(store.getState())
    };

    const [tabList, setTabList] = useState([
        {val:intl.get('SmallShop')['基础分析'], id:1},
        {val:intl.get('SmallShop')['全部商品'], id:2},
        {val:intl.get('SmallShop')['关联博主'], id:3},
        // {val:'关联视频', id:4},
    ])

    const [tabIndex, setTabIndex] = useState(1)
    const [pageIndex, setPageIndex] = useState(1);
    const tabChange = e => {
        setTabIndex(e)
        if (e == 1) {
            // Api.getRedSummary({ filterDays: 1, uniqueId: props.params.tiktok }).then(res => {
            //     if (res.code == 200) {
            //         setSummary(res.data)
            //     }
            // })

            getDatas()
            // getAllDatas()
            // getWord()
        }else if(e == 2){
            setPageIndex(1)
        }
    }

    const [classList, setClassList] = useState([])
    const [classIndex, setClassIndex] = useState(0)
    const [classShow, setClassShow] = useState(false)
    const sortClassIndex = (index) => {
        setPageIndex(1)
        setClassIndex(index)
    }
    const getCargo = ()=>{
        Api.getCargo().then(res=>{
            if(res.code == 200){
                let all = res.data
                all.unshift({id: 0, goodsName: '全部', goodsEnglishName: 'All'})
                setClassList(all)
            }
        })
    }

    const [tabShow, setTabShow] = useState(false)
    const [price_index, price_indexSet] = useState(-1)
    const [priceMin, priceMinSet] = useState()
    const [priceMax, priceMaxSet] = useState()
    const price_list = [{
        priceMin: '0',
        priceMax: '1',
        name: '≤1'
    }, {
        priceMin: '1',
        priceMax: '10',
        name: '1~10'
    }, {
        priceMin: '10',
        priceMax: '50',
        name: '10~50'
    }, {
        priceMin: '50',
        priceMax: '200',
        name: '50~200'
    }, {
        priceMin: '200',
        priceMax: '',
        name: '≥200'
    }]
    const price_indexChange = (e, index) => {
        setPageIndex(1)
        if (index === -2) {
            price_index == -2 ? loadMoreData(1) : price_indexSet(-2)
        } else {
            // console.log(e.target.checked ? index : -1)
            price_indexSet(e.target.checked ? index : -1)
            priceMinSet('')
            priceMaxSet('')
        }
    }

    const [shopDetail, setShopDetal] = useState()
    const [tiktok, setTikTok] = useState()
    const getShopDetail = () => {
        Api.getShopDetail(props.params.shopId).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setShopDetal(res.data.shopInfo)
                // setTikTok(res.data.tiktok)
                // getIsCollect(res.data.id)
                let list = []
                res.data.tags?.map((item, index) => {
                    list.push({ name: item.title, value: item.count })
                })
                optionWs.series[0].data = list
                setOptionW(optionWs)
            }
        })
    }
    const [isCollect, setIsCollect] = useState(false)
    const getIsCollect = (id) => {
        Api.getIsBloggerCollect({ bloggerUserId: id }).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setIsCollect(res.data)
            }
        })
    }

    const [left, setLeft] = useState(250)

    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(width)
        let num = width > 1836 ? 5 : 3
        if (state.collapsed) {
            setLeft(98)
        } else {
            setLeft(250)
        }
        setWidths(num)
    }
    useEffect(() => {
        heightFun()
    }, [state.collapsed])

    const [sortList, setSortList] = useState([
        {name:intl.get('SmallShop')['价格'], val:'minPrice', type:'orderByDesc'},
        // '佣金率',
        {name:intl.get('SmallShop')['销量'], val:'soldCount', type:'orderByDesc'}, 
        {name:intl.get('SmallShop')['销售额'], val:'soldTotal', type:'orderByAsc'},
    ]);

    const [sortIndex, setSortIndex] = useState(0);
    const sortMethod = (index) => {
        let obj = JSON.parse(JSON.stringify(sortList))
        obj[index].type = sortList[index].type == 'orderByDesc' ? 'orderByAsc' : 'orderByDesc'
        setSortList(obj)
        setPageIndex(1)
        setSortIndex(index)
    }

    useEffect(() => {
        if (tabIndex == 2)
            loadMoreData()
    }, [sortIndex, sortList, price_index, tabIndex])

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    
    const loadMoreData = (page) => {
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            current: page || pageIndex, size: 20
        }, objs = {
            secUid: shopDetail.secUid, 
            minPrice: price_index === -1 ? '' : price_index === -2 ? priceMin || '' : price_list[price_index].priceMin,
            maxPrice: price_index === -1 ? '' : price_index === -2 ? priceMax || '' : price_list[price_index].priceMax,
            countryCode: shopDetail.countryCode,
            [sortList[sortIndex].type]: sortList[sortIndex].val
        }
        Api.getShopProductList(obj, objs).then(res => {
            // console.log(res)
            setLoading(false);
            if (res.code == 200) {
                setTotal(res.data.total)
                setData(pageIndex == 1 || page == 1 ? res.data.records : [...data, ...res.data.records]);
                setPageIndex(page == 1 ? 2 : (pageIndex + 1))
            }
        }).catch(() => {
            setLoading(false);
        });
    };




    const palyVideo = (e, item, index) => {
        e.stopPropagation();
        // console.log('collection', index)
        window.open(`https://www.tiktok.com/@${item.elasticSearchUser.nickname}/video/${item.video.id}`)
    }

    // 别表
    const SmallDiv = (item, index) => {
        return <div key={index} style={{
            width: '100%', height: '100px', display: 'flex', alignItems: 'center',
            backgroundColor: index % 2 == 0 ? '#FFFFFF' : '#F6F6F6'
        }}
            onClick={()=>detail(item)}>

            <div style={{ width: '9%', paddingLeft: '19px' }}>
                {index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png'} alt=""
                    style={{}} /> :
                    <div style={{ width: '32px', textAlign: 'center', fontWeight: 500 }}>{index + 1}</div>
                }
            </div>
            <div style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                <Image onClick={e => e.stopPropagation()} src={item.cover} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                    preview={false}
                    style={{ width: '60px', height: '60px', borderRadius:'4px', marginRight: '16px' }} />
                <div style={{ color: '#888888', cursor: 'pointer' }}>
                    <div style={{
                        color: '#333333', fontWeight: 'bold', fontSize: '16px',
                        display: 'flex', alignItems: 'center'
                    }}>
                        <div className='over' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item.productName}</div>
                    </div>
                    {/* <div style={{fontSize:'12px', color:'#F7385B', display:'flex', marginTop:'3px'}}>
                        {
                            (lang=='zh'?item.cargoClassificationInterpretation:item.cargoClassificationInterpretationEn)?.split(',')?.map((val,index)=>{
                                return <span key={index} style={{background:'#FFE7E9', padding:'1px 10px', borderRadius:'15px', marginRight:'6px'}}>{val}</span>
                            })
                        }
                    </div> */}
                </div>
            </div>
            {/* <div style={{ width: '12%', float:'left', display:'flex', alignItems:'center' }}>
                <img src={item.countryUrl} style={{width:'14px', marginRight:'3px'}} />{lang=='zh'?item.country:item.currency}
            </div> */}
            <div style={{ width: '12%' }}>{item.currencyStr} {three(item.minPrice)}</div>
            <div style={{ width: '10%' }}>{three(item.soldCount)}</div>
            <div style={{ width: '15%' }}>{item.currencyStr} {three(item.soldTotal)}</div>
            {/* <div style={{ width: '15%' }}>{item.productCount}</div> */}
            <div style={{ width: '13%' }}>{item.bloggerCount}</div>
            {/* <div style={{ width: '10.5%' }}>{item.shopVideos}</div> */}
            {
                state?.userInfo?.userType != 2 ? <div style={{ width: '' }}>
                    {/* <Tooltip placement="top" title={<span style={{ fontSize: '12px' }}>添加PK</span>}>
                    <img onClick={(e) => isChecked(item) ? delPkItem(e, item, index) : addPK(e, item, index)}
                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pk' + (isChecked(item) ? 's' : '') + '.png'} style={{ marginRight: '9px', cursor: 'pointer' }} alt="" />
                </Tooltip> */}
                    <img onClick={(e)=>addProductCollect(e, item,index)} style={{ cursor:'pointer' }}
                         src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc'+(item.collect?'s':'')+'.png'} alt="" />
                </div> : ''
            }
        </div>
    }

    // 大图列表
    const BigDiv = (item, index) => {
        return <div className='BigDivs' style={{
            width: '240px', height: '370px', backgroundColor: '#FFF', margin: '0 auto',
            marginBottom:'15px', borderRadius: '4px',
            cursor: 'pointer'
        }}
            onClick={() => detail(item)}>

            <div style={{
                width: '100%', height: '240px', position: 'relative', backgroundColor: 'rgb(246,246,246)',
                borderTopLeftRadius: '4px', borderTopRightRadius: '4px',
            }}>
                <Image src={item.cover || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                    style={{ width: '240px', height: '240px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} />
                <div style={{
                    width: '100%', height: '100%', backgroundColor: 'rgb(0,0,0,0.22)',
                    display: 'flex', flexFlow: 'column', justifyContent: 'space-between', position: 'absolute', top: 0,
                    borderTopLeftRadius: '4px', borderTopRightRadius: '4px',
                }}>
                    {
                        state?.userInfo?.userType != 2 ? <div style={{textAlign: 'right', paddingTop: '14px', paddingRight: '10px'}}>
                            <img onClick={(e) => addProductCollect(e, item, index)} style={{marginLeft: '9px'}}
                             src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/SCB' + (item.collect ? 'S' : '') + '.png'}/>
                        </div> : ''
                    }
                    <div style={{ width: '100%', padding: '20px 14px' }}>
                        <div style={{ color: '#F6F6F6', fontWeight: 600, textShadow: '0px 0px 14px #333333' }}>{item.productName}</div>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', padding: '0 10px', marginTop:'20px', marginBottom:'-10px' }}>
                <div className='flex-jb'>
                    <div>
                        <span style={{color:'#F7385B', fontWeight:'bold'}}>{item.currencyStr} {item.minPrice}</span>
                    </div>
                    <div>
                        <span><img src={item.countryUrl} style={{width:'14px', marginRight:'8px', marginTop:'-3px'}} />{lang=='zh'?item.country:item.countryCode}</span>
                    </div>
                </div>
            </div>
            <Divider dashed />
            <div style={{
                width: '100%', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                textAlign: 'center', marginTop:'-18px'
            }}>
                <div style={{ width: '100px' }}>
                    <strong style={{ fontSize: '16px' }}>{three(item.soldCount)}</strong>
                    <p style={{ fontSize: '12px', color: '#888888', marginBottom: '4px' }}>{intl.get('SmallShop')['销量']}</p>
                </div>
                <div style={{ width: '100px' }}>
                    <strong style={{ fontSize: '16px' }}>{item.currencyStr} {three(item.soldTotal)}</strong>
                    <p style={{ fontSize: '12px', color: '#888888', marginBottom: '4px' }}>{intl.get('SmallShop')['销售额']}</p>
                </div>
            </div>

        </div>
    }

    const detail = (item,) => {
        window.open(`/index/GoodsRankingDetail/${item.id}/${item.productId}`)
    }

    const addCollection = (e)=>{
        e.stopPropagation();
        // console.log('collection',index)
        Api.shopCollect(shopDetail.id).then(res=>{
            if(res.code == 200){
                openNotificationWithIcon('success', intl.get('Common')[shopDetail.collect?'取消成功':'收藏成功'])
                getShopDetail()
            }
        })
    }
    const addProductCollect = (e, item,index)=>{
        e.stopPropagation();
        // console.log('collection',index)
        Api.shopProductCollect(item.id).then(res=>{
            if(res.code == 200){
                let obj = JSON.parse(JSON.stringify(data))
                obj[index].collect = !data[index].collect
                setData(obj)
                openNotificationWithIcon('success',  intl.get('Common')[obj[index].collect?'收藏成功':'取消成功'])
            }
        })
    }

    

    const [isAll, setIsAll] = useState(true)
    const changeAll = (e) => {
        setIsAll(e)
    }

    

    const [choseList, setChoseList] = useState([7, 7, 7, 7])
    const [tagList, setTagList] = useState([1, 1])
    const chooseChange = (e, i) => {
        let t = JSON.parse(JSON.stringify(choseList))
        t[i] = e
        setChoseList(t)
    }

    const tagListChange = (e, i) => {
        let t = JSON.parse(JSON.stringify(tagList))
        t[i] = e
        setTagList(t)
    }


    const dataPie = [
        {
          type: '分类一',
          value: 27,
        },
        {
          type: '分类二',
          value: 25,
        },
        {
          type: '分类三',
          value: 18,
        },
        {
          type: '分类四',
          value: 15,
        },
        {
          type: '分类五',
          value: 10,
        },
        {
          type: '其他',
          value: 5,
        },
      ];
      const configPie = {
        appendPadding: 10,
        data: dataPie,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.6,
        label: {
          type: 'inner',
          offset: '-50%',
          content: '{value}',
          style: {
            textAlign: 'center',
            fontSize: 14,
          },
        },
        interactions: [
          {
            type: 'element-selected',
          },
          {
            type: 'element-active',
          },
        ],
        statistic: {
          title: false,
          content: {
            style: {
              whiteSpace: 'pre-wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            content: '',
          },
        },
      };

    const dataD = [
        {
          type: '家具家电',
          sales: 38,
        },
        {
          type: '粮油副食',
          sales: 52,
        },
        {
          type: '粮油副食',
          sales: 72,
        },
        {
          type: '生鲜水果',
          sales: 101,
        },
        {
          type: '美容洗护',
          sales: 145,
        },
        {
          type: '母婴用品',
          sales: 48,
        },
      ];
      const configBar = {
        data: dataD,
        xField: 'sales',
        yField: 'type',
        seriesField: 'type',
        color: ({ type }) => {
          return type === '美容洗护' ? 'l(0) 0:#FFEEF1 1:#F7385B' : 'l(0) 0:#FFF7E6 1:#FFC146';
        },
        tooltip: {
                formatter: (datum) => {
                    return {
                        name: datum.type,
                        value: datum.sales
                    };
                },
            },
        legend: false,
        meta: {
          type: {
            alias: '类别',
          },
          sales: {
            alias: '销售额',
          },
        },
    };
    const [configD, setConfigD] = useState(configBar)


    const config = {
        data: [],
        color: '#F7385B',
        autoFit: true,
        areaStyle: () => {
            return {
                fill: 'l(270) 0:#FFFFFF 1:#F7385B',
            };
        },
        xField: 'date',
        yField: 'count',
        xAxis: {
            range: [0, 1],
        },
        tooltip: {
            formatter: (datum) => {
                return {
                    name: tagList[0] == 1 ? intl.get('SmallShop')['销量'] : tagList[0] == 2 ? intl.get('SmallShop')['销售额'] : tagList[0] == 3 ? intl.get('SmallShop')['关联博主数'] :'',
                    value: toKMB(datum.count, 2)
                };
            },
        }
    };
    const [configV, setConfigV] = useState(config)
    const [saleData, setSaleData] = useState()

    const configsF = {
        data: [],
        height: 250,
        color: '#6FC644',
        areaStyle: () => {
            return {
                fill: 'l(270) 0:#FFFFFF 1:#6FC644',
            };
        },
        xField: 'date',
        yField: 'count',
        xAxis: {
            range: [0, 1],
        },
        tooltip: {
            formatter: (datum) => {
                return {
                    name: tagList[1] == 1 ? '总量' : '增量',
                    value: toKMB(datum.count, 2)
                };
            },
        }
    };
    const [configF, setConfigF] = useState(configsF)
    const [fansData, setFansData] = useState()


    useEffect(() => {
        if (tabIndex == 1) getDatas()
    }, [choseList[1]])
    useEffect(() => {
        if (tabIndex == 1) {
            let data = tagList[0] == 1 ? saleData?.soldCountMap?.soldCountList : tagList[0] == 2 ? saleData?.soldTotalMap?.soldTotalList : tagList[0] == 3 ?saleData?.bloggerCountMap?.bloggerList:  {}
            config.data = data
            setConfigV(config)
        }
    }, [tagList[0]])

    useEffect(() => {
        // if (tabIndex == 1) getAllDatas()
    }, [choseList[2]])
    useEffect(() => {
        if (tabIndex == 1) {
            let data = tagList[1] == 1 ? fansData?.fansTotalMap?.fansTotalList : fansData?.fansIncrementMap?.fansIncrementList
            configsF.data = data
            setConfigF(configsF)
        }
    }, [tagList[1]])


    const getDatas = () => {
        let obj = {
            startTime: moment().add(-choseList[1], 'd').format("YYYY-MM-DD HH:mm:ss"), endTime:'',
            shopId: props.params.shopId
        }
        Api.getShopAnalysis(obj).then(res => {
            if (res.code == 200) {
                setSaleData(res.data)
                let data = tagList[0] == 1 ? res.data?.soldCountMap?.soldCountList : tagList[0] == 2 ? res.data?.soldTotalMap?.soldTotalList : tagList[0] == 3 ? res.data?.bloggerCountMap?.bloggerList:{}
                config.data = data
                setConfigV(config)
            }
        })
    }


    const getAllDatas = () => {
        let obj = {
            videoTimeScope: choseList[1] == 7 ? 5 : choseList[1] == 15 ? 6 : choseList[1] == 30 ? 7 : choseList[1] == 90 ? 8 : 5,
            uniqueId: props.params.tiktok
        }
        Api.getRedFansAnalysis(obj).then(res => {
            if (res.code == 200) {
                setFansData(res.data)
                let data = tagList[1] == 1 ? res.data.fansTotalMap.fansTotalList : res.data.fansIncrementMap.fansIncrementList
                configsF.data = data
                setConfigF(configsF)
            }
        })
    }

    const optionWs = {
        series: [
            {
                type: 'wordCloud', size: ['100%', '100%'],
                gridSize: 2,
                sizeRange: [12, 30],
                rotationRange: [0, 90],
                shape: 'square',
                drawOutOfBound: true,
                textStyle: {
                    color: () => {
                        return (
                            'rgb(' +
                            [
                                Math.round(Math.random() * 255),
                                Math.round(Math.random() * 255),
                                Math.round(Math.random() * 255),
                            ].join(',') +
                            ')'
                        );
                    },
                    fontWeight: 'bold',
                    emphasis: {
                        shadowBlur: 10,
                        shadowColor: '#333',
                    },
                },
                data: [],
            },
        ],
    };
    const [optionW, setOptionW] = useState(optionWs)

    const getWord = () => {
        let obj = {
            uniqueId: props.params.tiktok
        }
        Api.getRedWordAnalysis(obj).then(res => {
            if (res.code == 200) {
                let list = []
                res.data.tags?.map((item, index) => {
                    list.push({ name: item.title, value: item.count })
                })
                optionWs.series[0].data = list
                setOptionW(optionWs)
            }
        })
    }


    return shopDetail ? <div id="SensationDetail" style={{ paddingBottom: '50px' }}>
        <div className='information'>

            {/* 博主信息卡 */}
            <div className='info-box' style={{ position: 'fixed', top: '86px', left: left, transition: 'all 0.2s' }}>
                <div className='info-tips-box'>
                    <div className='info-tips'>
                        {intl.get('SmallShop')['小店信息']}
                    </div>
                </div>
                <img onClick={addCollection} style={{position:'absolute', top:'10px', right:'23px', cursor:'pointer'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc'+(shopDetail.collect?'s':'')+'.png'} alt="" />
                <div style={{ width: '300px', minWidth: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Image src={shopDetail.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                        style={{ width: '80px', height: '80px', borderRadius: '50px' }} />
                    <div style={{ color: '#888888', textAlign: 'center' }}>
                        <div style={{
                            color: '#333333', fontWeight: 'bold', fontSize: '16px',
                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                            <div className='over' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>
                                {shopDetail.nickname}
                            </div>
                        </div>
                        <div>TikTok: {shopDetail.uniqueId}</div>
                        {/* <span style={{marginTop:'5px', background:'#FFE7E9', padding:'4px 14px', borderRadius:'15px', color:'#F7385B', fontSize:'12px'}}>女装与女士内衣</span> */}
                    </div>
                    {/* <div className='target-list'>
                        {
                            (lang=='zh'?shopDetail.cargoClassificationInterpretation:shopDetail.cargoClassificationInterpretationEn).split(',')?.map((item, index) => {
                                if (index < 3) {
                                    return <div className='over1' key={index}>{item}</div>
                                }
                            })
                        }
                    </div> */}
                    
                    <div style={{ width: '100%', padding: '0 10px', marginTop:'20px', marginBottom:'10px' }}>
                        <div className='flex-jb'>
                            <div>
                                <span style={{color:'#888888'}}>{textJson['国籍']}：</span>
                                <span><img src={shopDetail.countryFlag} style={{width:'14px', marginRight:'8px', marginTop:'-3px'}} />{lang=='zh'?shopDetail.countryName:shopDetail.countryEnName}</span>
                            </div>
                            <div>
                                <span style={{color:'#888888'}}>{intl.get('BloggerRanking').FansNum}：</span>
                                <span>{toKMB(shopDetail.fansCount)}</span>
                            </div>
                        </div>
                    </div>
                    <Divider dashed />
                    <div style={{ width: '100%', marginTop: 14, marginBottom:'25px', textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
                        <div>
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(shopDetail.productCount, 2)}</div>
                            <div style={{ textAlign: 'center', color: '#888888', marginTop: '5px' }}>
                                {intl.get('SmallShop')['商品数']}
                            </div>
                        </div>
                        <Divider type="vertical" style={{ height: 35 }} />
                        <div>
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{three(shopDetail.soldCount)}</div>
                            <div style={{ textAlign: 'center', color: '#888888', marginTop: '5px' }}>
                            {intl.get('SmallShop')['销量']}
                            </div>
                        </div>
                        <Divider type="vertical" style={{ height: 35 }} />
                        <div>
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{shopDetail.currencyStr} {three(shopDetail.soldTotal)}</div>
                            <div style={{ textAlign: 'center', color: '#888888', marginTop: '5px' }}>
                            {intl.get('SmallShop')['销售额']}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div style={{ marginLeft: '20px', width: '100%', flex: 1, minWidth: 0 }}>

            {/* 选项卡 */}
            <div style={{
                width: '100%', height: '60px', backgroundColor: '#FFF',
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '37px',
            }}>
                <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                    {
                        tabList.map((item,index)=>{
                            return <div key={index} style={{
                                backgroundColor: tabIndex === item.id ? '#FFEEF1' : '#FFF', padding: '9px 27px', borderRadius: '4px',
                                color: tabIndex === item.id ? '#F7385B' : '#888888', cursor: 'pointer'
                            }}
                                onClick={() => tabChange(item.id)}>{item.val}
                            </div>
                        })
                    }
                </div>
            </div>
            {
                tabIndex === 2 ?
                    <div>
                        <div style={{
                            width: '100%', backgroundColor: '#FFF', paddingTop: '24px', paddingLeft: '8px', paddingBottom: '25px',
                            fontWeight: 500, marginTop:'10px'
                        }}>
                            
                            {/* 带货分类 */}
                            {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <label style={{ minWidth: state.language == 'zh' ? '102px' : '90px', marginTop: '6px', textAlign: 'right', paddingRight: '6px' }}>{intl.get('Index')['带货分类']}：</label>
                                <div style={{
                                    width: 'calc(100% - 70px)', height: classShow ? 'auto' : '39px', display: 'flex', flexWrap: 'wrap',
                                    overflow: classShow ? '' : 'hidden'
                                }}>
                                    {
                                        classList?.map((item, index) => {
                                            return <span key={index} className='tabItem' style={{
                                                padding: '6px 10px',
                                                background: index == classIndex ? '#FFE7E9' : 'none', marginBottom: '5px',
                                                color: index == classIndex ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => sortClassIndex(index)}>{lang == 'zh' ?item.goodsName:item.goodsEnglishName}</span>
                                        })
                                    }
                                </div>
                                <div style={{ width: '45px' }}>
                                    {!classShow ?
                                        <DownSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                                            onClick={() => { setClassShow(true) }} />
                                        :
                                        <UpSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                                            onClick={() => setClassShow(false)} />}
                                </div>
                            </div> */}

                            <div style={{ width: '100%', lineHeight: '30px', display: 'flex', marginTop: '15px' }}>
                                <label style={{ minWidth: state.language == 'zh' ? '102px' : '90px', textAlign: 'right', paddingRight: '6px' }}>{intl.get('GoodsLists')['商品价格：']}：</label>
                                <div style={{
                                    width: 'calc(100% - 90px - 45px)', display: 'flex', flexWrap: 'wrap',
                                    overflow: tabShow ? '' : 'hidden'
                                }}>
                                    {
                                        price_list?.map((item, index) => (
                                            <Checkbox key={index} style={{ marginRight: '30px', marginBottom:'10px' }} checked={index === price_index} onChange={e => price_indexChange(e, index)}>
                                                {item.name}
                                            </Checkbox>
                                        ))
                                    }
                                    <div style={{ display: 'flex', marginBottom:'10px' }}>
                                        <div style={{ marginRight: '20px' }}>{intl.get('SmallShop')['自定义']}</div>
                                        <InputNumber controls={false} placeholder={intl.get('GoodsLists')['最小值']} style={{ width: 80 }} value={priceMin} onChange={e => priceMinSet(e)} />
                                        <div style={{ margin: '0 5px' }}>~</div>
                                        <InputNumber controls={false} placeholder={intl.get('GoodsLists')['最大值']} style={{ width: 80 }} value={priceMax} onChange={e => priceMaxSet(e)} />
                                        <Button disabled={!(priceMin || priceMax)}
                                            style={{
                                                lineHeight: '32px', border:0, outline:'none',
                                                padding: '0 10px',
                                                fontSize: '12px',
                                                marginLeft: '10px',
                                                borderRadius: '3px',
                                                textAlign: 'center',
                                                color: '#FFF',
                                                background: priceMin || priceMax ? 'linear-gradient(180deg, #F7385B, #FF716E)' : '#EEE'
                                            }}
                                            onClick={e => price_indexChange(e, -2)}
                                        >确定</Button>
                                    </div>
                                </div>
                            </div>

                            
                        </div>

                        {/* 排序 */}
                        <div style={{
                            width: '100%', height: '60px', backgroundColor: '#FFF', marginTop: '10px',
                            display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 37px',
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {
                                    sortList?.map((item, index) => {
                                        return <div key={index} className='active' style={{
                                            display: 'flex', alignItems: 'center', marginRight: '36px',
                                            color: sortIndex == index ? '#F7385B' : '#888888', cursor: 'pointer'
                                        }}
                                            onClick={() => sortMethod(index)}>
                                            {item.name}
                                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index && item.type == 'orderByAsc' ? 's' : '') + '.png'} alt=""
                                                style={{ marginLeft: '2px' ,transform: 'rotate(180deg)' }} />
                                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index && item.type == 'orderByDesc' ? 's' : '') + '.png'} alt=""
                                                style={{ marginLeft: '2px', }} />
                                        </div>
                                    })
                                }
                            </div>
                            <div>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/datu' + (!isAll ? 's' : '') + '.png'} alt=""
                                    style={{ cursor: 'pointer' }} onClick={() => changeAll(false)} />
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/lb' + (isAll ? 's' : '') + '.png'} alt=""
                                    style={{ marginLeft: '6px', cursor: 'pointer' }}
                                    onClick={() => changeAll(true)} />
                            </div>
                        </div>

                        {/* 列表 */}
                        <div style={{
                            width: '100%', backgroundColor: !isAll ? '#F6F6F6' :'#FFFFFF', padding: !isAll ? '14px 0px 18px 0px' : '24px 24px 24px 30px',
                            marginTop: '2px'
                        }}>
                            {!isAll ? '' : <div style={{
                                width: '100%', height: '50px', display: 'flex', alignItems: 'center', backgroundColor: '#F6F6F6',
                                fontWeight: 500
                            }}>
                                <div style={{ width: '9%', paddingLeft: '19px' }}>{intl.get('BloggerRanking').Ranking}</div>
                                <div style={{ width: '30%', paddingLeft: '14px' }}>{intl.get('SmallShop')['商品']}</div>
                                {/* <div style={{ width: '12%', paddingRight: '10px' }}>{intl.get('Index')['国籍']}</div> */}
                                <div style={{ width: '12%', paddingRight: '10px' }}>{intl.get('SmallShop')['价格']}</div>
                                <div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('SmallShop')['销量']}</div>
                                <div style={{ width: '15%', paddingRight: '10px' }}>{intl.get('SmallShop')['销售额']}</div>
                                {/* <div style={{ width: '15%', paddingRight: '10px' }}>{intl.get('SmallShop')['关联商品数']}</div> */}
                                <div style={{ width: '13%', paddingRight: '10px' }}>{intl.get('SmallShop')['关联博主数']}</div>
                                {/* <div style={{ width: '10.5%', paddingRight: '10px' }}>{intl.get('SmallShop')['关联视频数']}</div> */}
                                { state?.userInfo?.userType != 2 ? <div style={{ width: '' }}>{intl.get('BloggerRanking').Operation}</div> : ''}
                            </div>}
                            <Spin tip="数据加载中..." spinning={loading}>
                                <InfiniteScroll
                                    dataLength={data.length} scrollThreshold={'20px'}
                                    next={loadMoreData}
                                    hasMore={data.length < total}
                                    loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                                    endMessage={<Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>}
                                    scrollableTarget="scrollableDiv"
                                >
                                    <List
                                        dataSource={data} grid={{ gutter: 0, column: !isAll ? widths : 1 }}
                                        renderItem={(item, index) => <div style={{}}>
                                            {!isAll ? BigDiv(item, index) : SmallDiv(item, index)}
                                        </div>}
                                    />
                                </InfiniteScroll>
                            </Spin>

                        </div>
                    </div>
                    :
                    tabIndex == 1 ? <div id='analysis'>
                        <div style={{ padding: '24px 28px', backgroundColor: '#fff', marginTop: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='sd-title'>{intl.get('SmallShop')['小店数据概述']}</div>
                            </div>
                            <div style={{
                                width: '100%', height: '110px', backgroundColor: '#FFF', marginTop: '10px',
                                textAlign: 'center', display: 'flex', justifyContent: 'space-around', alignItems: 'center',
                                // padding: '0 35px'
                            }}>
                                <div className='flex-ac' style={{ flexDirection: 'column' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{three(shopDetail.productCount)}</div>
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/product/total.png'} alt=""
                                            style={{ width: '14px', marginRight: '4px', }} /> {intl.get('SmallShop')['总商品数']}
                                    </div>
                                </div>
                                <div className='flex-ac' style={{ flexDirection: 'column' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{three(shopDetail.soldCount)}</div>
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/product/xiaoliang.png'} alt=""
                                            style={{ width: '14px', marginRight: '4px', }} /> {intl.get('SmallShop')['总销量']}
                                    </div>
                                </div>
                                <div className='flex-ac' style={{ flexDirection: 'column' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{three(shopDetail.soldTotal)}</div>
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/product/qian.png'} alt=""
                                            style={{ width: '14px', marginRight: '4px', }} /> {intl.get('SmallShop')['总销售额']}
                                    </div>
                                </div>
                                <div className='flex-ac' style={{ flexDirection: 'column' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{three(shopDetail.bloggerCount)}</div>
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/product/renshu.png'} alt=""
                                            style={{ width: '14px', marginRight: '4px', }} /> {intl.get('SmallShop')['总关联博主数']}
                                    </div>
                                </div>
                                {/* <div className='flex-ac' style={{ flexDirection: 'column' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(summary.avgCommentsCount, 2)}5</div>
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/product/shipin.png'} alt=""
                                            style={{ width: '14px', marginRight: '4px', }} /> {intl.get('SmallShop')['总关联视频数']}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div style={{ padding: '24px 28px', backgroundColor: '#fff', marginTop: '10px' }}>
                            {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='sd-title'>{intl.get('SmallShop')['商品分类和价格分析']}</div>
                            </div>
                            <div className='flex-acjb' style={{ marginTop: '24px', padding: '5px 19px', background: '#F6F6F6' }}>
                                {saleData ? <div style={{ width:'60%', fontWeight: 600 }}>
                                    美妆护理分类占比最多，为<span style={{ color: '#F7385B' }}>45.75%</span>；价格大于$200的占比最多，为<span style={{ color: '#F7385B' }}>32.91%</span>
                                </div> : ''}
                                <div>{textJson['更新时间']}：{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD HH:mm:ss')}</div>
                            </div>
                            <div style={{ marginTop: 40, display:'flex', justifyContent:'space-between', alignItems:'center' }}>
                                <div style={{width:'38%', height:'200px'}}>
                                    <Pie {...configPie} />
                                </div>
                                <div style={{width:'55%', height:'200px'}}>
                                    <Bar {...configD} />
                                </div>
                            </div> */}
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='sd-title'>{intl.get('SmallShop')['商品销售数据趋势分析']}</div>
                                <div className='time-box'>
                                    <div className={choseList[1] === 7 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(7, 1)}>7{intl.get('ReleaseDemand')['天']}</div>
                                    <div className={choseList[1] === 15 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(15, 1)}>15{intl.get('ReleaseDemand')['天']}</div>
                                    <div className={choseList[1] === 30 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(30, 1)}>30{intl.get('ReleaseDemand')['天']}</div>
                                    <div className={choseList[1] === 90 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(90, 1)}>90{intl.get('ReleaseDemand')['天']}</div>
                                    <div style={{
                                        width: 240,
                                        height: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: '10px',
                                        border: '1px solid #EEEEEE',
                                        color: '#CCCCCC',
                                        marginLeft: '13px',
                                        fontSize: '12px'
                                    }}>
                                        <CalendarOutlined style={{ marginRight: '17px' }} />
                                        <div style={{ marginRight: '17px' }}>{moment(moment().subtract(choseList[1], 'days')).format('YYYY-MM-DD')}</div>
                                        <div style={{ marginRight: '17px' }}>~</div>
                                        <div style={{ marginRight: '17px' }}>{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width:'100%', display:'flex'}}>
                                {
                                    lang === 'zh' ?
                                        <div style={{ display: 'flex', width:'250px', marginTop: 26, height: 30, alignItems: 'center', border: '1px solid #EEEEEE', borderRadius: '30px', position: 'relative', textAlign: 'center', lineHeight: '28px' }}>
                                            <div style={{ width: '33.33%', height: 28, background: 'linear-gradient(180deg, #F7385B, #FF716E)', position: 'absolute', left: `${(tagList[0] - 1) * 33.33}%`, top: 0, zIndex: 1, borderRadius: '30px', transition: 'all 0.5s' }}></div>
                                            <div style={{ zIndex: 2, width: '33.33%', cursor: 'pointer', color: tagList[0] === 1 ? '#fff' : '', transition: 'all 0.5s' }} onClick={e => tagListChange(1, 0)}>{intl.get('SmallShop')['销量']}</div>
                                            <div style={{ zIndex: 2, width: '33.33%', cursor: 'pointer', color: tagList[0] === 2 ? '#fff' : '', transition: 'all 0.5s' }} onClick={e => tagListChange(2, 0)}>{intl.get('SmallShop')['销售额']}</div>
                                            <div style={{ zIndex: 2, width: '33.33%', cursor: 'pointer', color: tagList[0] === 3 ? '#fff' : '', transition: 'all 0.5s' }} onClick={e => tagListChange(3, 0)}>{intl.get('SmallShop')['关联博主数']}</div>
                                        </div>
                                        :<div style={{ display: 'flex', width:'420px', marginTop: 26, height: 30, alignItems: 'center', border: '1px solid #EEEEEE', borderRadius: '30px', position: 'relative', textAlign: 'center', lineHeight: '28px' }}>
                                            <div style={{ width: tagList[0] === 3 ? '50%' : '25%', height: 28, background: 'linear-gradient(180deg, #F7385B, #FF716E)', position: 'absolute', left: `${(tagList[0] - 1) * 25}%`, top: 0, zIndex: 1, borderRadius: '30px', transition: 'all 0.5s' }}></div>
                                            <div style={{ zIndex: 2, width: '25%', cursor: 'pointer', color: tagList[0] === 1 ? '#fff' : '', transition: 'all 0.5s' }} onClick={e => tagListChange(1, 0)}>{intl.get('SmallShop')['销量']}</div>
                                            <div style={{ zIndex: 2, width: '25%', cursor: 'pointer', color: tagList[0] === 2 ? '#fff' : '', transition: 'all 0.5s' }} onClick={e => tagListChange(2, 0)}>{intl.get('SmallShop')['销售额']}</div>
                                            <div style={{ zIndex: 2, width: '50%', cursor: 'pointer', color: tagList[0] === 3 ? '#fff' : '', transition: 'all 0.5s' }} onClick={e => tagListChange(3, 0)}>{intl.get('SmallShop')['关联博主数']}</div>
                                        </div>
                                }
                            </div>
                            <div className='flex-acjb' style={{ marginTop: '24px', padding: '5px 19px', background: '#F6F6F6' }}>
                                {saleData ? <div style={{ width:'60%', fontWeight: 600 }}>
                                    {moment(moment().subtract(choseList[1], 'days')).format('YYYY-MM-DD')} ～ {moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')}
                                    {tagList[0] === 1?intl.get('SmallShop')['总销量']:tagList[0] === 2?intl.get('SmallShop')['总销售额']:intl.get('SmallShop')['总关联博主数']}{intl.get('GoodsRankingData')['为']}<span style={{ color: '#F7385B' }}>{tagList[0] === 1?saleData?.soldCountMap?.sum:saleData?.soldTotalMap?.sum}</span>，
                                    {intl.get('GoodsRankingData')['其中']}{tagList[0] === 1?saleData?.soldCountMap?.soldCountList.filter(item=> item.count==saleData?.soldCountMap.max)[0]?.date :
                                    saleData?.soldTotalMap?.soldTotalList.filter(item=> item.count==saleData?.soldTotalMap.max)[0]?.date}{intl.get('GoodsRankingData')['最高']}，{intl.get('GoodsRankingData')['为']}<span style={{ color: '#F7385B' }}>{tagList[0] === 1?saleData?.soldCountMap?.max:saleData?.soldTotalMap?.max}</span>
                                </div> : ''}
                                <div>{textJson['更新时间']}：{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD HH:mm:ss')}</div>
                            </div>
                            <div style={{ marginTop: 40 }}>
                                {
                                    configV.data?.length > 0 ? <Area {...configV} /> :
                                        <div style={{ width: '345px', height: '200px', textAlign: 'center', margin: '0 auto', marginTop: '100px' }}>
                                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                                            <div style={{ marginTop: '24px', textAlign: 'center', color: '#333' }}>{intl.get('Common')['暂无数据']}</div>
                                        </div>
                                }
                                {/* <ChildComp config={configF} /> */}
                            </div>
                            {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 100 }}>
                                <div className='sd-title'>{intl.get('SmallShop')['关联视频数据趋势分析']}</div>
                                <div className='time-box'>
                                    <div className={choseList[2] === 7 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(7, 2)}>7{intl.get('ReleaseDemand')['天']}</div>
                                    <div className={choseList[2] === 15 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(15, 2)}>15{intl.get('ReleaseDemand')['天']}</div>
                                    <div className={choseList[2] === 30 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(30, 2)}>30{intl.get('ReleaseDemand')['天']}</div>
                                    <div className={choseList[2] === 90 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(90, 2)}>90{intl.get('ReleaseDemand')['天']}</div>
                                    <div style={{
                                        width: 240,
                                        height: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: '10px',
                                        border: '1px solid #EEEEEE',
                                        color: '#CCCCCC',
                                        marginLeft: '13px',
                                        fontSize: '12px'
                                    }}>
                                        <CalendarOutlined style={{ marginRight: '17px' }} />
                                        <div style={{ marginRight: '17px' }}>{moment(moment().subtract(choseList[2], 'days')).format('YYYY-MM-DD')}</div>
                                        <div style={{ marginRight: '17px' }}>~</div>
                                        <div style={{ marginRight: '17px' }}>{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: 26, width: 160, height: 30, alignItems: 'center', border: '1px solid #EEEEEE', borderRadius: '30px', position: 'relative', textAlign: 'center', lineHeight: '28px' }}>
                                <div style={{ width: 80, height: 28, background: 'linear-gradient(180deg, #F7385B, #FF716E)', position: 'absolute', left: `${(tagList[1] - 1) * 50}%`, top: 0, zIndex: 1, borderRadius: '30px', transition: 'all 0.5s' }}></div>
                                <div style={{ zIndex: 2, width: '50%', cursor: 'pointer', color: tagList[1] === 1 ? '#fff' : '', transition: 'all 0.5s' }} onClick={e => tagListChange(1, 1)}>{textJson['总量']}</div>
                                <div style={{ zIndex: 2, width: '50%', cursor: 'pointer', color: tagList[1] === 2 ? '#fff' : '', transition: 'all 0.5s' }} onClick={e => tagListChange(2, 1)}>{textJson['增量']}</div>
                            </div>
                            <div className='flex-acjb' style={{ marginTop: '24px', padding: '5px 19px', background: '#F6F6F6' }}>
                                {fansData ? <div style={{ width:'60%', fontWeight: 600 }}>
                                    2022-05-16 ～ 2022-05-22点赞数最高值为<span style={{ color: '#F7385B', marginRight:'10px' }}>1.5M</span> 最低值为<span style={{ color: '#F7385B', marginRight:'10px' }}>254.5K </span> 平均值为<span style={{ color: '#F7385B' }}>642.3</span>
                                </div> : ''}
                                <div>{textJson['更新时间']}：{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD HH:mm:ss')}</div>
                            </div>
                            <div style={{ marginTop: 40 }}>
                                {
                                    configF.data?.length > 0 ? <Area {...configF} /> :
                                        <div style={{ width: '345px', height: '200px', textAlign: 'center', margin: '0 auto', marginTop: '100px' }}>
                                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                                            <div style={{ marginTop: '24px', textAlign: 'center', color: '#333' }}>{intl.get('Common')['暂无数据']}</div>
                                        </div>
                                }
                            </div> */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '100px' }}>
                                <div className='sd-title'>{intl.get('SmallShop')['关联视频话题分析']}</div>
                            </div>
                            <div className='flex-acjc'>
                                {optionW.length > 0 ? <ReactEcharts
                                    option={optionW}
                                    notMerge
                                    lazyUpdate
                                    style={{ height: '315px', width: '725px', marginTop: '50px' }}
                                /> :
                                <div style={{ width: '345px', height: '200px', textAlign: 'center', margin: '0 auto', marginTop: '100px' }}>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                                    <div style={{ marginTop: '24px', textAlign: 'center', color: '#333' }}>{intl.get('Common')['暂无数据']}</div>
                                </div>}
                            </div>
                        </div>
                    </div> : 
                    tabIndex == 3 ? <SmallShopBlogger shop_id={shopDetail.id} /> : ''
            }
        </div>
    </div> : <Spin spinning={true} tip={'加载中...'} />
}
export default withRouter(SmallShopDetail);