import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import '../../asset/css/BloggerRanking.scss'
import {Skeleton,List, Image, Divider, Spin, message, DatePicker, Space, Tooltip} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../utils/redux/index'
import intl from 'react-intl-universal';
import * as Api from '../../service/https'
import toKMB from '../../utils/KMB'
import moment from 'moment'
import {CalendarOutlined} from "@ant-design/icons";
import openNotificationWithIcon from '../../components/Notification'
import {getMusicRankingVideoList} from "../../service/https";

const GoodsRankingVideo = (props) => {
    const { RangePicker } = DatePicker;
    const [state, setSate] = useState(store.getState());
    const [orderField, orderFieldSet] = useState(0);
    const [sortWay, sortWaySet] = useState(2);
    const [sortIndex, setSortIndex] = useState(0);
    const [total, setTotal] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);
    const sortChange = e => {
        setPageIndex(1)
        setSortIndex(e)
        if (e === orderField) {
            sortWaySet(sortWay === 1 ? 2 : 1)
        } else {
            sortWaySet(2)
            orderFieldSet(e)
        }
    }
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [widths, setWidths] = useState(0)
    useEffect(() => {
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
        }
    }, [])

    const heightFun = () => {
        let width = document.documentElement.clientWidth
        let num = state.collapsed + (width > 1890 ? 6 : width > 1650 ? 5 : 4)
        setWidths(num)
    }
    useEffect(() => {
        heightFun()
    }, [state.collapsed])

    const [isBig] = useState(false)

    const [sortList] = useState([{title:intl.get('MusicRanking')['发布时间'],value:'createTime'},
        {title:intl.get('MusicRanking')['播放数'],value:'playCount'},
        {title:intl.get('MusicRanking')['点赞数'],value:'diggCount'},
        {title:intl.get('MusicRanking')['评论数'],value:'commentCount'},
        {title:intl.get('MusicRanking')['分享数'],value:'shareCount'}]);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        loadMoreData()
    }, [sortIndex, orderField, sortWay])

    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            id:props.id,
            current: pageIndex,
            size: 20,
            [sortWay == 2 ? 'descColumn' : 'ascColumn']: sortIndex > -1 ? sortList[sortIndex].value : '',
        }
        Api.getMusicRankingVideoList(obj).then(res => {
            if (res.code === 200) {
                setTotal(res.data.total)
                setData(pageIndex == 1 ? res.data.records : [...data, ...res.data.records]);
                setPageIndex(pageIndex + 1)
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    };

    const addCollection = (e, item, index) => {
        e.stopPropagation()
        let data = {
            productId: item.productDetail.productId
        }
        if (item.isCollect) {
            Api.cancelCollectProduct(data).then(res => {
                if (res.code === 200) {
                    openNotificationWithIcon('success', intl.get('GoodsLists')['取消成功！'])
                    let t = JSON.parse(JSON.stringify(data))
                    t[index].isCollect = !t[index].isCollect
                    setData(t)
                }
            })
        } else {
            Api.collectProduct(data).then(res => {
                if (res.code === 200) {
                    openNotificationWithIcon('success', intl.get('GoodsRanking')['收藏成功！'])
                    let t = JSON.parse(JSON.stringify(data))
                    t[index].isCollect = !t[index].isCollect
                    setData(t)
                }
            })
        }
    }

    // 别表
    const SmallDiv = (item, index) => {
        return <div key={index} style={{
            width: '100%', height: '150px', display: 'flex', alignItems: 'center',
            backgroundColor: index % 2 == 0 ? '#FFFFFF' : '#F6F6F6', cursor: 'pointer'
        }}
                    onClick={() => detail(item, index)}>

            <div style={{ width: '10%', paddingLeft: '19px' }}>
                {index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png'} alt=""
                                  style={{}} /> :
                    <div style={{ width: '32px', textAlign: 'center', fontWeight: 500 }}>{index + 1}</div>
                }
            </div>
            <div style={{ width: '40%', display: 'flex' }}>
                <div style={{
                    width: '80px', height: '110px', backgroundImage: 'url(' + item.cover + ')',
                    backgroundSize: 'cover', borderRadius: '4px', backgroundRepeat: 'no-repeat'
                }}>
                    <div style={{
                        width: '100%', height: '100%', background: 'rgba(0,0,0,0.2)', color: '#FFF',
                        textAlign: 'center', paddingTop: '86px', borderRadius: '4px', fontSize: '13px'
                    }}>
                        <span>{Math.floor(item.duration / 60)}:{(item.duration % 60) < 10 ? '0' : ''}{item.duration % 60}</span>
                    </div>
                </div>
                <div className='videoList' style={{
                    width: 'calc(100% - 80px - 85px)', paddingLeft: '15px',
                    color: '#888888'
                }}>
                    <div className='over-word' style={{
                        fontSize: '16px', fontWeight: 'bold', lineHeight: '20px',
                        color: '#333', paddingTop: '2px'
                    }}>{item.desc}</div>
                    <div className='over1' style={{ height: '20px', marginTop: '8px' }}>
                        {
                            item.challenges?.map((item, index) => {
                                return <span key={index} style={{ marginRight: '5px' }}>#{item.title}</span>
                            })
                        }
                    </div>
                    <div style={{ marginTop: '13px' }}>{intl.get('GoodsRankingVideo')['发布时间']}：{moment(item.createTime).format('YYYY-MM-DD hh:mm:ss')}</div>
                </div>
            </div>
            <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>{toKMB(item.playCount,2)}</div>
            <div style={{ width: '10%' }}>{toKMB(item.diggCount,2)}</div>
            <div style={{ width: '10%' }}>{toKMB(item.commentCount,2)}</div>
            <div style={{ width: '10%' }}>{toKMB(item.shareCount,2)}</div>
            <div style={{ width: '' }}>
                <Tooltip placement="top" title={<span style={{fontSize:'12px'}}>{intl.get('GoodsRankingVideo')['播放视频']}</span>}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/play_grey.png'} style={{ marginRight: '9px' }} alt="" />
                </Tooltip>
            </div>
        </div>
    }

    const detail = (item) => {
        window.open(`https://www.tiktok.com/@${item.uniqueId}/video/${item.id}`)
    }

    return <div id="MusicRankingVideo">
        {/* 排序 */}
        <div style={{
            width: '100%', height: '60px', backgroundColor: '#FFF', marginTop: '30px'
        }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {
                    sortList?.map((item, index) => {
                        return <div key={index} className='active' style={{
                            display: 'flex', alignItems: 'center', marginRight: '36px',
                            color: sortIndex == index ? '#F7385B' : '#888888', cursor: 'pointer'
                        }}
                                    onClick={() => sortChange(index)}>
                            {item.title}
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index && sortWay == 1 ? 's' : '') + '.png'} alt=""
                                 style={{ marginLeft: '6px', marginTop: '2px', transform: 'rotate(180deg)' }} />
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index && sortWay == 2 ? 's' : '') + '.png'} alt=""
                                 style={{ marginLeft: '2px', marginTop: '2px' }} />
                        </div>
                    })
                }
            </div>
        </div>

        {/* 列表 */}
        <div style={{width: '100%'}}>
            {isBig ? '' : <div style={{
                width: '100%', height: '50px', display: 'flex', alignItems: 'center', backgroundColor: '#F6F6F6',
                fontWeight: 500
            }}>
                <div style={{ width: '10%', paddingLeft: '19px' }}>{intl.get('MusicRanking')['排名']}</div>
                <div style={{ width: '40%', paddingLeft: '24px' }}>{intl.get('MusicRanking')['视频']}</div>
                <div style={{ width: '10%', paddingRight: '14px' }}>{intl.get('MusicRanking')['播放数']}</div>
                <div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('MusicRanking')['点赞数']}</div>
                <div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('MusicRanking')['评论数']}</div>
                <div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('MusicRanking')['分享数']}</div>
                <div style={{ width: '' }}>{intl.get('BloggerRanking').Operation}</div>
            </div>}
            <Spin tip="数据加载中..." spinning={loading}>
                <InfiniteScroll
                    dataLength={data.length} scrollThreshold={'20px'}
                    next={loadMoreData}
                    hasMore={total > 0 && data.length < total}
                    loader={data.length>0?<Skeleton avatar paragraph={{ rows: 2 }} active />:''}
                    endMessage={<Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={data} grid={{ gutter: 0, column: isBig ? widths : 1 }}
                        renderItem={(item, index) => state.userInfo?.userType == 2 || (state.userInfo?.userType == 1 && state.userInfo?.memberStatus == 0 && index < 200) || (state.userInfo?.userType == 1 && state.userInfo?.memberStatus == 1) ? <div style={{ paddingTop: isBig ? '10px' : 0 }}>
                            {isBig ? '' : SmallDiv(item, index)}
                        </div> : ''}
                    />
                </InfiniteScroll>
            </Spin>
        </div>
    </div>

}
export default withRouter(GoodsRankingVideo);