import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import '../../../asset/css/BloggerRanking.scss'
import { CaretRightFilled, StarFilled } from '@ant-design/icons';
import { Select, Checkbox, List, notification, Spin, Skeleton, Divider } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../../utils/redux/index'
import domtoimage from 'dom-to-image';
import intl from 'react-intl-universal';
import * as Api from '../../../service/https'
import toKMB from '../../../utils/KMB'
import moment from 'moment';
import openNotificationWithIcon from '../../../components/Notification'

const { Option } = Select;

const BloggerDetail = (props)=>{

    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [widths, setWidths] = useState(0)

    const bloggerId = props.params.bloggerId


    useEffect(()=>{
        // console.log(props)
        getBloggerDetail()
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
        }
    },[])
    const [bloggerDetail, setBloggerDetal] = useState()
    const getBloggerDetail = ()=>{
        Api.getBloggerDetail({ bloggerId: props.params.bloggerId }).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setBloggerDetal(res.data.data[0])
                getIsCollect(res.data.data[0].id)
            }
        })
    }
    const [isCollect,setIsCollect] = useState(false)
    const getIsCollect = (id)=>{
        Api.getIsBloggerCollect({ bloggerUserId: id }).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setIsCollect(res.data)
            }
        })
    }
    
    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(width)
        let num = width > 1836 ? 3 : 2
        setWidths(num)
    }
    useEffect(()=>{
        heightFun()
    },[state.collapsed])




    const [sortList, setSortList] = useState([intl.get('BloggerDetail')['发布时间'], intl.get('TopicList').PlaysNum,
    intl.get('BloggerRanking').LikesNum, intl.get('TopicList').CommentsNum, intl.get('TopicList').SharesNum]);
    const [sortIndex, setSortIndex] = useState(0);
    const sortMethod = (index) =>{
        setPageIndex(0)
        setSortIndex(index)
    }

    useEffect(()=>{
        loadMoreData()
    },[sortIndex])





    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const loadMoreData = () => {
        if (loading) {
          return;
        }
        setLoading(true);
        let obj = {
            authorId: bloggerId,
            bloggerVideoSortRuler: sortIndex + 1,
            from: pageIndex * 10, size: 10
        }
        Api.getBloggerAllVideo(obj).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setTotal(res.data.total)
                setData(pageIndex == 0 ? res.data.data : [...data, ...res.data.data]);
                setPageIndex(pageIndex+1)
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    };
    





    // 别表
    const SmallDiv = (item, index) =>{
        return <div key={index} style={{width:'100%', height:'120px', display:'flex', alignItems:'center', 
            backgroundColor: index%2==0 ?'#FFFFFF':'#F6F6F6', cursor:'pointer'}}
            onClick={()=>detail(item,index)}>

            <div style={{width:'10%', paddingLeft:'19px'}}>
                {index<3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/'+(index+1)+'.png'} alt="" 
                    style={{}}/> : 
                    <div style={{width:'32px', textAlign:'center', fontWeight:500}}>{index+1}</div>
                }
            </div>
            <div style={{width:'37%', display:'flex', alignItems:'center'}}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/tupian.png'} alt="" 
                    style={{width:'80px', height:'80px', borderRadius:'4px', marginRight:'16px'}}/>
                <div style={{width:'70%', color:'#888888'}}>
                    <div style={{width:'100%', color:'#333333', fontWeight:'bold', fontSize:'16px',
                        display:'flex', alignItems:'center'}}>
                        <div className='over' style={{width:'100%', height:'40px', fontWeight:'bold', fontSize:'16px', 
                            lineHeight:'20px' }}>
                        2022 summer Latest Ladies Sandals Hot Sale High Quality Beach Travel Shoes Luxury Shoes for
                        </div>
                    </div>
                    <div style={{marginTop:'5px', color:'#F7385B', fontSize:'16px'}}>$ 0.771</div>
                </div>
            </div>
            <div style={{width:'14%', marginLeft:'20px'}}>
                <div style={{backgroundColor: '#FFEEF1', width:'100px', height:'30px', borderRadius:'20px',
                    color: '#F7385B', cursor:'pointer', border:'1px solid #F7385B',
                    textAlign:'center', lineHeight:'28px'}}
                    >鞋靴箱包</div>
            </div>
            <div style={{width:'9%'}}>1.6K</div>
            <div style={{width:'11%'}}>28.2M</div>
            <div style={{width:'10%'}}>2.1B</div>
            <div style={{width:''}}>
                <img onClick={(e)=>addCollection(e, item,index)} 
                    src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc.png'} alt="" />
            </div>
        </div>
    }
    // 大图列表
    const BigDiv = (item, index) =>{
        return <div style={{width:'100%', height:'208px', backgroundColor:'#FFF', margin:'0 auto', 
            marginBottom: '16px', paddingRight:'16px',
            }}
            onClick={()=>detail(item,index)}>

            <div style={{width:'100%', height:'100%', padding:'14px', border:'1px solid #EEEEEE', borderRadius:'4px', 
                display:'flex', cursor:'pointer'}}>
                <div style={{width:'130px', height:'180px', backgroundImage:'url('+item.video.cover+')',
                    backgroundSize:'cover', borderRadius:'4px',
                    }}>
                    <div style={{width:'100%', height:'100%', background:'rgba(0,0,0,0.2)', color:'#FFF',
                        textAlign:'center', paddingTop:'150px', borderRadius:'4px'}}>
                        <span>{Math.floor(item.video.duration/60)}:{(item.video.duration%60)<10?'0':''}{item.video.duration%60}</span>
                    </div>
                </div>
                <div style={{width:'calc(100% - 130px)', paddingLeft:'13px', paddingTop:'6px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <div className='over' style={{fontSize:'16px', fontWeight:'bold', lineHeight:'20px'}}>
                        {item.desc}
                    </div>
                    <div className='over1' style={{color:'#888888', lineHeight:'20px'}}>
                    {
                        item.challenges?.map((item,index)=>{
                            return <span key={index}>#{item.title}</span>
                        })
                    }
                    </div>
                    <div style={{width:'100%', color:'#888888', textAlign:'center', display:'flex', alignItems:'center',}}>
                        <div style={{display:'flex', alignItems:'center', marginRight:'15px'}}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/playCircleL.png'} alt="" 
                                style={{width:'14px', marginRight: '4px',}}/> {toKMB(item.playCount,2)}
                        </div>
                        <div style={{display:'flex', alignItems:'center', marginRight:'15px'}}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hanhan.png'} alt="" 
                                style={{width:'14px', marginRight: '4px',}}/> {toKMB(item.diggCount,2)}
                        </div>
                        <div style={{display:'flex', alignItems:'center', marginRight:'15px'}}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pinglun.png'} alt="" 
                                style={{width:'14px', marginRight: '4px',}}/> {toKMB(item.commentCount,2)}
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/fenxiang.png'} alt="" 
                                style={{width:'14px', marginRight: '4px',}}/> {toKMB(item.shareCount,2)}
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <span style={{color:'#888888'}}>{moment(item.createTime*1000).format('YYYY-MM-DD hh:mm:ss')}</span>
                        <div style={{display:'flex'}}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/play_grey.png'} style={{marginRight:'10px'}} alt="" />
                            {/* <img onClick={(e)=>addCollection(e, item,index)} 
                                 src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc.png'} alt="" /> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    }

    const detail = (item,index)=>{
        // console.log('detail',index)
        window.open(`https://www.tiktok.com/@${bloggerId}/video/${item.video.id}`)
    }
    const addPK = (e, item,index)=>{
        props.navigate(`/index/bloggerComparison`, {state:'/t'})
    }
    const addCollection = (e, item,index)=>{
        e.stopPropagation();
        // console.log('collection',index)
    }




    const [isAll, setIsAll] = useState(false)
    const changeAll = (e) => {
        setIsAll(e)
    }
    
    
    const addCollect = () => {
        if(isCollect){
            Api.delBloggerCollect({bloggerCollectId: isCollect.id}).then(res => {
                // console.log(res)
                if (res.code == 200) {
                    openNotificationWithIcon('success', intl.get('Common')['取消成功'])
                    setIsCollect(false)
                    getIsCollect(bloggerDetail.id)
                }
            })
        }else{
            var obj = bloggerDetail
            console.log(obj)
            obj.userId = obj.id
            obj.accountCreateTime = obj.createTime
            obj.bioLink = obj.bioLink?.link ? obj.bioLink?.link : null
            obj.ptvRatioSpeculate = obj.ptvRatio_speculate
            Api.addBloggerCollect(bloggerDetail).then(res => {
                // console.log(res)
                if (res.code == 200) {
                    openNotificationWithIcon('success', intl.get('Common')['收藏成功'])
                    getIsCollect(bloggerDetail.id)
                }
            })
        }
    }
    const [isPk, setIsPk] = useState(false)




    return bloggerDetail ? <div id="BloggerDetail" style={{paddingBottom:'50px'}}>

        <div style={{width:'100%', backgroundColor:'#FFF', padding:'30px', fontWeight:500,
            display:'flex', alignItems:'center',justifyContent:'space-between'}}>
            <div style={{width:'300px', minWidth:'300px', display:'flex', alignItems:'center'}}>
                <img src={bloggerDetail.avatarMedium} alt="" 
                    style={{width:'100px', height:'100px', borderRadius:'50px', marginRight:'16px'}}/>
                <div style={{color:'#888888'}}>
                    <div style={{color:'#333333', fontWeight:'bold', fontSize:'16px',
                        display:'flex', alignItems:'center'}}>
                        <div className='over' style={{height:'25px', fontWeight:'bold', fontSize:'16px', lineHeight:'25px'}}>{bloggerDetail.nickname}</div>
                        {bloggerDetail.verified?<img style={{marginLeft:'3px', width:'14px', height:'14px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} />:''}
                    </div>
                    TikTok: {bloggerDetail.uniqueId}
                </div>
            </div>
            <div style={{width:'1px', height:'70px', backgroundColor:'#EEEEEE'}}></div>
            <div style={{width:'70%', padding:'0 57px', color:'#888888'}}>
                <div className='over'>Bio：{bloggerDetail.signature!=''?bloggerDetail.signature:''} </div>
                <div>Link：<span onClick={()=>window.open(bloggerDetail.bioLink?.link)} style={{color:'#F7385B', cursor:'pointer'}}>{bloggerDetail.bioLink?.link}</span></div>
            </div>
            <div style={{width:'1px', height:'70px', backgroundColor:'#EEEEEE'}}></div>
            <div className='detailDC' style={{display:'flex', marginLeft:'70px'}}>
                <div style={{width:'160px', height:'38px', background: false?'linear-gradient(180deg, #F7385B, #FF716E)':'',
                    display:'flex', justifyContent:'center', alignItems:'center', color: false?'#FFF':'#888888', fontSize:'12px',
                    borderRadius:'4px', cursor:'pointer', border:false?0:'1px solid #EEEEEE'}}
                    onClick={()=>addPK()}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pkdb'+(false?'':'s')+'.png'} alt="" 
                        style={{width:'14px', marginRight: '4px',}}/>{intl.get('BloggerComparison').BloggerComparison}
                </div>
                <div style={{width:'160px', height:'38px', background: isCollect?'linear-gradient(180deg, #F7385B, #FF716E)':'',
                    display:'flex', justifyContent:'center', alignItems:'center', color: isCollect?'#FFF':'#888888', fontSize:'12px',
                    borderRadius:'4px', cursor:'pointer', border: isCollect?0:'1px solid #EEEEEE', marginLeft:'23px'}}
                    onClick={()=>addCollect()}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/shoucang'+(isCollect?'':'s')+'.png'} alt="" 
                        style={{width:'14px', marginRight: '4px',}}/>{isCollect?intl.get('BloggerDetail')['取消收藏']:intl.get('Workbench').Collection}
                </div>
            </div>
        </div>
        
        <div style={{width:'100%', height:'114px', backgroundColor:'#FFF', boxShadow: '0px 0px 15px 20px #F6F6F6',
            marginTop:'-10px', textAlign:'center', display:'flex', justifyContent:'space-around', alignItems:'center',
            padding:'0 35px' }}>
            <div>
                <div style={{fontSize:'16px', fontWeight:'bold'}}>{toKMB(bloggerDetail.followerCount,2)}</div>
                <div style={{display:'flex', alignItems:'center', color:'#888888'}}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/fensiguanli.png'} alt="" 
                        style={{width:'14px', marginRight: '4px',}}/> {intl.get('BloggerRanking').FansNum}
                </div>
            </div>
            <div>
                <div style={{fontSize:'16px', fontWeight:'bold'}}>{toKMB(bloggerDetail.videoCount,2)}</div>
                <div style={{display:'flex', alignItems:'center', color:'#888888'}}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/shipin.png'} alt="" 
                        style={{width:'14px', marginRight: '4px',}}/> {intl.get('TopicList').VideosNum}
                </div>
            </div>
            <div>
                <div style={{fontSize:'16px', fontWeight:'bold'}}>{toKMB(bloggerDetail.ptvRatio_speculate,2)}</div>
                <div style={{display:'flex', alignItems:'center', color:'#888888'}}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/playCircleL.png'} alt="" 
                        style={{width:'14px', marginRight: '4px',}}/> {intl.get('TopicList').AveragePlayback}
                </div>
            </div>
            <div>
                <div style={{fontSize:'16px', fontWeight:'bold'}}>{toKMB(bloggerDetail.heartCount,2)}</div>
                <div style={{display:'flex', alignItems:'center', color:'#888888'}}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hanhan.png'} alt="" 
                        style={{width:'14px', marginRight: '4px',}}/> {intl.get('TopicList').LikesNum}
                </div>
            </div>
            <div>
                <div style={{fontSize:'16px', fontWeight:'bold'}}>{toKMB(bloggerDetail.htfRatio,2)}</div>
                <div style={{display:'flex', alignItems:'center', color:'#888888'}}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/bilifenxi.png'} alt="" 
                        style={{width:'14px', marginRight: '4px',}}/> {intl.get('BloggerRanking').AveragePpowderRatio}
                </div>
            </div>
        </div>

        {/* 排序 */}
        <div style={{width:'100%', height:'60px', backgroundColor:'#FFF', marginTop:'10px',
            display:'flex', alignItems:'center', justifyContent:'space-between', paddingLeft: '37px',
            }}>
            <div style={{display:'flex', alignItems:'center', fontWeight:500}}>
                <div style={{backgroundColor: !isAll?'#FFEEF1':'#FFF', padding:'9px 27px', borderRadius:'4px',
                    color: !isAll?'#F7385B':'#888888', cursor:'pointer'}}
                    onClick={()=>changeAll(false)}>{intl.get('BloggerDetail')['全部视频']}</div>
                {/* <div style={{backgroundColor: isAll?'#FFEEF1':'#FFF', padding:'9px 27px', borderRadius:'4px',
                    color: isAll?'#F7385B':'#888888', cursor:'pointer'}}
                    onClick={()=>changeAll(true)}>带货商品</div> */}
            </div>
            <div style={{display:'flex', alignItems:'center'}}>
                {
                    sortList?.map((item,index)=>{
                        return <div key={index} className='active' style={{display:'flex', alignItems:'center', marginRight:'36px',
                            color: sortIndex == index ? '#F7385B' : '#888888', cursor:'pointer'}}
                            onClick={()=> sortMethod(index)}>
                            {item}
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou'+(sortIndex == index ?'s':'')+'.png'} alt="" 
                                style={{marginLeft:'6px', marginTop:'2px'}}/>
                        </div>
                    })
                }
            </div>
        </div>

        {/* 列表 */}
        <div style={{width:'100%', backgroundColor: '#FFFFFF', padding: !isAll?'24px 24px 18px 38px':'24px 24px 24px 30px',
            marginTop: '10px'}}>
            {!isAll ? '' : <div style={{width:'100%', height:'50px', display:'flex', alignItems:'center', backgroundColor:'#F6F6F6',
                fontWeight:500}}>
                <div style={{width:'10%', paddingLeft:'19px'}}>{intl.get('BloggerRanking').Ranking}</div>
                <div style={{width:'37%', paddingLeft:'14px'}}>商品信息</div>
                <div style={{width:'14%', paddingRight:'10px', marginLeft:'20px', paddingLeft:'20px'}}>商品分类</div>
                <div style={{width:'9%', paddingRight:'10px'}}>佣金比例</div>
                <div style={{width:'11%', paddingRight:'10px'}}>销量</div>
                <div style={{width:'10%', paddingRight:'10px'}}>销售额</div>
                <div style={{width:''}}>{intl.get('BloggerRanking').Operation}</div>
            </div>}
            <Spin tip="数据加载中..." spinning={loading}>
                <InfiniteScroll
                    dataLength={data.length} scrollThreshold={'20px'}
                    next={loadMoreData}
                    hasMore={data.length < total}
                    loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                    endMessage={<Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={data} grid={{ gutter: 0, column: !isAll ? widths : 1 }}
                        renderItem={(item, index) => <div style={{}}>
                            {!isAll ? BigDiv(item, index) : SmallDiv(item, index)}
                        </div>}
                    />
                </InfiniteScroll>
            </Spin>
            
        </div>


    </div> :''
                        
}
export default withRouter(BloggerDetail);