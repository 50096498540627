import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, NavLink, useRoutes } from "react-router-dom";
import { ConfigProvider, Select } from 'antd';
import RoutersList from './utils/router'
import store from './utils/redux/index'




// 中英文切换
import intl from 'react-intl-universal';


// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale-provider/en_US';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
moment.locale('zh-cn');

// require('./App.css')

// 中英文切换
const locales = {
    "en": require('./utils/locales/en-US.json'),
    "zh": require('./utils/locales/zh-CN.json'),
};


const { Option } = Select;


const AppRouter = (props)=> {

    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [initDone, setInitDone] = useState(false)
    const [language, setLanguage] = useState('zh')

    useEffect(()=>{
        let lang =(navigator.language || navigator.browserLanguage).toLowerCase();
        if(lang.indexOf('zh')>=0) {
            // 假如浏览器语言是中文
            localStorage.setItem("defaultLng","zh")
        }else{
            // 假如浏览器语言是其它语言
            localStorage.setItem("defaultLng","en")
        }
        IsPC()
        loadLocales()
        window.addEventListener('resize', IsPC)
        return () => {
            window.removeEventListener('resize', IsPC)
        }
    },[])

    const IsPC = ()=> {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone",
            "SymbianOS", "Windows Phone",
            "iPad", "iPod"
        ];
        // console.log(userAgentInfo)
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        store.dispatch({type:'isWeb', value: flag})
    }

    // 加载语言
    const loadLocales = ()=> {
        let langs = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
        store.dispatch({type:'language', value: langs})
        intl.init({
            currentLocale: langs, 
            locales,
        })
        .then(() => {
            setInitDone(true)
            if(langs == 'zh'){
                import('./App.scss')
            }else{
                import('./App.scss')
            } 
        });
    }




    return <div style={{minWidth: state.isWeb ? '1420px':'100%'}} >
        {initDone && <ConfigProvider locale={state.language == 'zh' ? zhCN : enUS}>
            <Router>
                {/* <ul style={{listStyle:'none', display:'flex', alignItems:'center', justifyContent:'center',}}>
                    <li><NavLink to="/" style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>{intl.get('home')}</NavLink> </li>
                    <li><NavLink to="/about" style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>{intl.get('about')}</NavLink> </li>
                    
                </ul> */}
                <RoutersList/>

                {/* <div id='app' style={{width:'1420px', position:'fixed', top:0, right: 0, height:'66px', 
                    zIndex:3, display:'flex', alignItems:'center', justifyContent:'flex-end', paddingRight:'100px' }}>
                    <Select style={{width: '100px'}} value={language} onChange={e=>changeLang(e)} bordered={false} 
                        getPopupContainer={() => document.getElementById('app')}>
                        <Option value="zh">简体中文</Option>
                        <Option value="en">英文</Option>
                    </Select>
                </div> */}

            </Router>
        </ConfigProvider>}
    </div>;
}
export default AppRouter;