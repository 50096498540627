import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import intl from 'react-intl-universal';
import * as Api from '../../../service/https'
import { Modal, Input, List, notification, Button, Skeleton, Divider, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CloseCircleOutlined } from '@ant-design/icons';

const MyMcnWallet = () => {
    const [textJson, setTextJson] = useState(intl.get('Wallet'))
    useEffect(() => {
        getWallet()
        loadMoreData()
    }, [])
    const [walletTotal, setWallet] = useState([])


    const getWallet = () => {
        Api.getMcn_redtotal({}).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setWallet(res.data)
            }
        })
    }

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            current: pageIndex, size: 10,
        }
        Api.getRecordsWallet(obj).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setTotal(res.data.total)
                setData(pageIndex == 1 ? res.data.walletInfos : [...data, ...res.data.walletInfos]);
                setPageIndex(pageIndex + 1)
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    };


    return <div id='wallet' style={{ paddingBottom: '43px' }}>
        <nav style={{ width: '100%', position: 'relative', fontWeight: 500 }}>
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt=""
                style={{ width: '100%' }} />
            <div style={{
                width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt=""
                    style={{ width: '190px' }} />
                <div style={{
                    width: '10px', height: '10px', backgroundColor: 'black', borderRadius: '5px',
                    margin: '0 20px'
                }}></div>
                <span style={{ fontSize: '20px' }}>{textJson['我的钱包']}</span>
            </div>
        </nav>

        <div style={{ width: '100%', background: '#FFF', padding: '26px 30px' }}>
            <div style={{color:'#666666', paddingLeft:'43px'}}>
                {intl.get('AccountRecharge')['收益总计']} <span style={{color:'#333333', fontSize:'22px', fontWeight:'bold', position:'relative', top:'2px', left:'20px'}}>¥{walletTotal.totalSum}</span>
                <span style={{marginLeft:'25px'}}>（所属机构总计¥4000，所属红人总计¥1320.5）</span>
            </div>
            <div style={{ width: '100%', height: '50px', background: '#F6F6F6', marginTop:'23px' }}>
                <div style={{
                    width: '100%', height: '100%', display: 'flex', alignItems: 'center'
                }}>
                    <div className='over' style={{ width: '45%', paddingLeft: '45px', paddingRight: '45px' }}>{intl.get('ReleaseDemand')['商品/品牌']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['需求目标']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['佣金']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['内容形式']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['媒体传播']}</div>
                    <div style={{ width: '15%' }}>{intl.get('AccountRecharge')['到账时间']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['平台抽佣']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['红人收入']}</div>
                </div>
            </div>
            
            {data?.length > 0 ? <Spin tip="数据加载中..." spinning={loading}>
                <InfiniteScroll
                    dataLength={data?.length} scrollThreshold={'20px'}
                    next={loadMoreData}
                    hasMore={data?.length < total}
                    // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                    endMessage={<Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>}
                    // scrollableTarget="wallet"
                >
                    <List
                        dataSource={data} grid={{ gutter: 0, column: 1 }}
                        renderItem={(item, index) => <div key={index} style={{
                            width: '100%', height: '100px', display: 'flex', alignItems: 'center',
                            background: (index + 1) % 2 == 0 ? '#F6F6F6' : '#FFF'
                        }}>
                            <div className='over' style={{ width: '45%', paddingLeft: '45px', paddingRight: '45px' }}>{item.listName}</div>
                            <div style={{ width: '10%' }}>{item.target == 1 ? intl.get('ReleaseDemand')['电商卖货'] : item.target == 3 ? intl.get('ReleaseDemand')['商品种草'] : intl.get('ReleaseDemand')['品牌传播']}</div>
                            <div style={{ width: '10%' }}>￥{item.cost}</div>
                            <div style={{ width: '10%' }}>{item.cooperationWay == 1 ? intl.get('ReleaseDemand')['短视频'] : intl.get('ReleaseDemand')['直播']}</div>
                            <div style={{ width: '10%' }}>{item.media == 1 ? 'TikTok' : '--'}</div>
                            <div style={{ width: '15%' }}>{item.successTime}</div>
                            <div style={{ width: '10%', color: '#F7385B' }}>- ￥{item.costTip}</div>
                            <div style={{ width: '10%', color: '#68C33B' }}>+ ￥{item.costEarning}</div>
                        </div>}
                    />
                </InfiniteScroll>
            </Spin> :
            <div style={{width:'345px', height:'200px', textAlign:'center', margin:'0 auto', marginTop:'100px' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                <div style={{marginTop:'24px', textAlign: 'center', color:'#333' }}>{'No Data'}</div>
            </div>}
        </div>
        

    </div>
}
export default withRouter(MyMcnWallet);