import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import '../../asset/css/BloggerRanking.scss'
import {CalendarOutlined, DownSquareTwoTone, UpSquareTwoTone} from '@ant-design/icons';
import {Select, List, notification, Image, Divider, Spin, message, DatePicker, Space} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../utils/redux/index'
import domtoimage from 'dom-to-image';
import intl from 'react-intl-universal';
import { saveAs } from 'file-saver';
import * as Api from '../../service/https'
import toKMB from '../../utils/KMB'
import moment from 'moment'
import toHMS from "../../utils/toHMS";
import {
    addBloggerCollect,
    delBloggerCollect,
    getBloggerRankingList,
    getGoodsRelatedBlogger,
    shopCollect
} from "../../service/https";
import three from '../../utils/three';
import openNotificationWithIcon from '../../components/Notification'

const { Option } = Select;

const BloggerRanking = (props) => {
    const id = props.id
    const shop_id = props.shop_id
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh'
    const [state, setSate] = useState(store.getState());
    const [dayList] = useState(intl.get('GoodsRankingDetail').DaysArr)
    const [dayIndex, setDayIndex] = useState(0)
    const [orderField, orderFieldSet] = useState(0);
    const [sortWay, sortWaySet] = useState(2);
    const [sortIndex, setSortIndex] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);
    const [sortList, setSortList] = useState([{title:intl.get('GoodsRankingBlogger')['粉丝数'],value:'fans_count'}, {title:intl.get('GoodsRankingBlogger')['视频数'],value:'video_count'}]);

    const sortChange = e => {
        setPageIndex(1)
        setSortIndex(e)
        if (e === orderField) {
            sortWaySet(sortWay === 1 ? 2 : 1)
        } else {
            sortWaySet(2)
            orderFieldSet(e)
        }
    }
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);


    const [relatedBlogger,setRelatedBlogger] = useState({})
    const getRelatedBlogger = ()=>{
        Api.getGoodsRelatedBlogger({
            productId: id,
            startTime: moment(moment().subtract(dayList[dayIndex].value, 'days')).format('YYYY-MM-DD'),
            endTime: moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')
        }).then(res=>{
            if(res.code === 200){
                setRelatedBlogger(res.data)
            }
        })
    }
    useEffect(() => {
        // getRelatedBlogger()
    },[dayIndex])


    const [widths, setWidths] = useState(0)
    useEffect(() => {
        // console.log(props)
        // getCountry()
        // window.scrollTo(0, 0)
        heightFun()
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
            notification.destroy()
        }
    }, [])

    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(height)
        let num = state.collapsed + (width > 1890 ? 6 : width > 1650 ? 5 : 4)
        setWidths(num)
    }
    useEffect(() => {
        heightFun()
    }, [state.collapsed])

    const [tabList, setTabList] = useState([
        '全部', '英国', '印度尼西亚', '美国', '日本', '沙特阿拉伯', '德国', '越南', '巴西', '泰国',
        '俄罗斯联邦', '马来西亚', '菲律宾', '阿联酋', '墨西哥', '沙特阿拉伯'
    ])

    const [isBig, setBig] = useState(false)


    const [sortNumList, setItems] = useState([
        { start: 0, key: '<', end: 10000 },
        { start: 10000, key: '~', end: 100000 },
        { start: 100000, key: '~', end: 500000 },
        { start: 500000, key: '~', end: 1000000 },
        { start: 1000000, key: '~', end: 5000000 },
        { start: 5000000, key: '~', end: 10000000 },
        { start: 10000000, key: '~', end: 50000000 },
        { start: 50000000, key: '<', end: 0 },
        { start: '', key: '~', end: '' },
        { start: '', key: '~', end: '' },
        { start: '', key: '~', end: '' }
    ]);

    useEffect(() => {
        loadMoreData()
    }, [sortIndex, orderField, sortWay])


    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [nowLength, setNowLength] = useState(0);
    const [data, setData] = useState([]);

    const [shareData, setShareData] = useState([]);

    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            current:pageIndex,
            size:10,
            [sortWay == 2 ? 'descColumn' : 'ascColumn']: sortIndex > -1 ? sortList[sortIndex].value : ''
        }
        Api.getShopBloggerList(shop_id, obj).then(res => {
            if (res.code == 200) {
                setData(pageIndex == 1 ? res.data.records : [...data, ...res.data.records]);
                setTotal(res.data.total)
                setPageIndex(pageIndex + 1)
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    };

    const addCollection = (e, item, index) => {
        e.stopPropagation()
        Api.shopCollect(item.id).then(res => {
            if (res.code === 200) {
                openNotificationWithIcon('success', intl.get('GoodsRanking')[item.collect ? '取消成功！' : '收藏成功！'])
                let t = JSON.parse(JSON.stringify(data))
                t[index].collect = !t[index].collect
                setData(t)
            }
        })
        // if (item.collect) {
        //     Api.shopCollect(item).then(res => {
        //         if (res.code === 200) {
        //             message.success(intl.get('GoodsRanking')['取消成功！'])
        //             let t = JSON.parse(JSON.stringify(data))
        //             t[index].collect = !t[index].collect
        //             setData(t)
        //         }
        //     })
        // } else {
        //     Api.addBloggerCollect(item).then(res => {
        //         if (res.code === 200) {
        //             message.success(intl.get('GoodsRanking')['收藏成功！'])
        //             let t = JSON.parse(JSON.stringify(data))
        //             t[index].collect = !t[index].collect
        //             setData(t)
        //         }
        //     })
        // }
    }

    // 别表
    const SmallDiv = (item, index) => {
        return <div key={index} style={{
            width: '100%', height: '150px', display: 'flex', alignItems: 'center',
            backgroundColor: index % 2 == 0 ? '#FFFFFF' : '#F6F6F6'
        }}
        >

            <div style={{ width: '10%', paddingLeft: '19px' }}>
                {index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png'} alt=""
                                  style={{}} /> :
                    <div style={{ width: '32px', textAlign: 'center', fontWeight: 500 }}>{index + 1}</div>
                }
            </div>
            <div style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                <Image onClick={e => e.stopPropagation()} src={item.avatarMedium} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                       style={{ width: '60px', height: '60px', borderRadius: '30px', marginRight: '16px' }} />
                <div onClick={() => detail(item, index)} style={{ color: '#888888', cursor: 'pointer' }}>
                    <div style={{
                        color: '#333333', fontWeight: 'bold', fontSize: '16px',
                        display: 'flex', alignItems: 'center'
                    }}>
                        <div className='over' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item.nickname}</div>
                        {/*{item.verified ? <img style={{ marginLeft: '3px', width: '14px', height: '14px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} /> : ''}*/}
                        {/*{item.bioLink.link != null ? <img style={{ marginLeft: '3px', width: '13px', height: '13px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/lianjie.png'} /> : ''}*/}
                    </div>
                    TikTok: {item.uniqueId}
                </div>
            </div>
            <div style={{ width: '20%' }}>{toKMB(item.fansCount, 2)}</div>
            <div style={{ width: '20%' }}>{toKMB(item.videoCount, 2)}</div>
            {/*<div style={{ width: '10%' }}>{toKMB(item.ptvRatio_speculate, 2)}</div>*/}
            {/*<div style={{ width: '25%', display: 'flex' }}>*/}
            {/*    {*/}
            {/*        item.shopVideos?.map((item2, index) => index < 2 ?*/}
            {/*            <div key={index} style={{ marginRight: '10px', position: 'relative' }}*/}
            {/*                 onClick={(e) => {*/}
            {/*                     e.stopPropagation();*/}
            {/*                     window.open(`https://www.tiktok.com/@${item2.author}/video/${item2.id}`)*/}
            {/*                 }}>*/}
            {/*                <Image src={item2.cover} preview={false} width={80} height={111} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'} />*/}
            {/*                <div style={{ position: 'absolute', bottom: '0', display: 'flex', alignItems: 'center', width: '80px', textAlign: 'center' }}>*/}
            {/*                    <div style={{ display: 'flex', alignItems: 'center', width: '80px', justifyContent: 'center' }}>*/}
            {/*                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/playCircle.png'} style={{ width: 12, height: 12, marginRight: '3px' }} alt="" />*/}
            {/*                        <div style={{ color: '#fff', fontSize: '13px' }}>{toHMS(item2.duration || 0)}</div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div> : '')*/}
            {/*    }*/}
            {/*</div>*/}
            <div style={{ width: '20%' }}>
                {
                    item.collect ?
                        <img
                            style={{cursor:'pointer'}}
                            onClick={(e) => addCollection(e, item, index)}
                            src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/scs.png'}
                            alt=""
                        />
                        : <img
                            style={{cursor:'pointer'}}
                            onClick={(e) => addCollection(e, item, index)}
                            src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc.png'}
                            alt=""
                        />
                }
            </div>
        </div>
    }

    const detail = (item, index) => {
        window.open(`/index/bloggerDetail/${item.uniqueId}`)
    }

    // 生成图片自动下载为png格式（将dom转为二进制再编译下载）
    const [isShare, setIsShare] = useState(false)
    const getBlobPng = () => {
        setIsShare(!isShare)
    }


    return <div id="BloggerRanking" style={{ paddingBottom: '50px' }}>
        {/*关联博主概述*/}
        {/*<div style={{width: '100%', backgroundColor: '#FFF'}}>*/}
        {/*    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',padding:'24px 24px 0'}}>*/}
        {/*        <div className='sd-title'>{intl.get('GoodsRankingBlogger')['关联博主概述']}</div>*/}
        {/*        <div style={{display:'flex',justifyContent:'space-between'}}>*/}
        {/*            <div style={{display: 'flex',borderRadius:'4px',overflow:'hidden'}}>*/}
        {/*                {*/}
        {/*                    dayList.map((item, index) => (*/}
        {/*                        <span*/}
        {/*                            key={index}*/}
        {/*                            className='tabItem'*/}
        {/*                            style={{*/}
        {/*                                padding: '6px 10px',*/}
        {/*                                marginBottom: '5px',*/}
        {/*                                cursor: 'pointer',*/}
        {/*                                color: index == dayIndex ? '#E94753' : '#CCCCCC',*/}
        {/*                                background: index == dayIndex ? '#FFE7E9' : '#F8F8F8',*/}
        {/*                            }}*/}
        {/*                            onClick={() => setDayIndex(index)}*/}
        {/*                        >{item.text}</span>*/}
        {/*                    ))*/}
        {/*                }*/}
        {/*            </div>*/}
        {/*            <div style={{*/}
        {/*                width: 240,*/}
        {/*                height: 30,*/}
        {/*                display: 'flex',*/}
        {/*                alignItems: 'center',*/}
        {/*                paddingLeft: '10px',*/}
        {/*                border: '1px solid #EEEEEE',*/}
        {/*                color: '#CCCCCC',*/}
        {/*                marginLeft: '13px',*/}
        {/*                fontSize: '12px'*/}
        {/*            }}>*/}
        {/*                <CalendarOutlined style={{ marginRight: '17px' }} />*/}
        {/*                <div style={{ marginRight: '17px' }}>{moment(moment().subtract(dayList[dayIndex].value, 'days')).format('YYYY-MM-DD')}</div>*/}
        {/*                <div style={{ marginRight: '17px' }}>~</div>*/}
        {/*                <div style={{ marginRight: '17px' }}>{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')}</div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div style={{width:'100%', height:'114px', backgroundColor:'#FFF', textAlign:'center', display:'flex', justifyContent:'space-around', alignItems:'center',*/}
        {/*        padding:'0 35px' }}>*/}
        {/*        <div>*/}
        {/*            <div style={{fontSize:'16px', fontWeight:'bold'}}>{relatedBlogger.bloggerCount}</div>*/}
        {/*            <div style={{display:'flex', alignItems:'center', color:'#888888'}}>*/}
        {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/RelatedBlogger.png'} alt=""*/}
        {/*                     style={{width:'14px', marginRight: '4px',}}/> {intl.get('GoodsRankingBlogger')['关联博主数']}*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div>*/}
        {/*            <div style={{fontSize:'16px', fontWeight:'bold'}}>{relatedBlogger.bloggerCount}</div>*/}
        {/*            <div style={{display:'flex', alignItems:'center', color:'#888888'}}>*/}
        {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/AverageFansNum.png'} alt=""*/}
        {/*                     style={{width:'14px', marginRight: '4px',}}/> {intl.get('GoodsRankingBlogger')['平均粉丝数']}*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div>*/}
        {/*            <div style={{fontSize:'16px', fontWeight:'bold'}}></div>*/}
        {/*            <div style={{display:'flex', alignItems:'center', color:'#888888'}}>*/}
        {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/RelatedVideos.png'} alt=""*/}
        {/*                     style={{width:'14px', marginRight: '4px',}}/> {intl.get('GoodsRankingBlogger')['关联视频数']}*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div>*/}
        {/*            <div style={{fontSize:'16px', fontWeight:'bold'}}></div>*/}
        {/*            <div style={{display:'flex', alignItems:'center', color:'#888888'}}>*/}
        {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/AveragePlaysNum.png'} alt=""*/}
        {/*                     style={{width:'14px', marginRight: '4px',}}/> {intl.get('GoodsRankingBlogger')['平均播放数']}*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/* 排序 */}
        <div style={{
            width: '100%', height: '60px', backgroundColor: '#FFF', marginTop: '10px',
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '47px',
            paddingRight: '24px'
        }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {
                    sortList?.map((item, index) => {
                        return <div key={index} className='active' style={{
                            display: 'flex', alignItems: 'center', marginRight: '36px',
                            color: sortIndex == index ? '#F7385B' : '#888888', cursor: 'pointer'
                        }} onClick={() => sortChange(index)}>
                            {item.title}
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index && sortWay == 1 ? 's' : '') + '.png'} alt=""
                                 style={{ marginLeft: '6px', marginTop: '2px', transform: 'rotate(180deg)' }} />
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index && sortWay == 2 ? 's' : '') + '.png'} alt=""
                                 style={{ marginLeft: '2px', marginTop: '2px' }} />
                        </div>
                    })
                }
            </div>
        </div>

        {/* 列表 */}
        <div style={{
            width: '100%', backgroundColor: isBig ? '' : '#FFFFFF', padding: isBig ? '0 10px' : '24px 24px 24px 30px',
            marginTop: isBig ? 0 : '10px'
        }}>
            {isBig ? '' : <div style={{
                width: '100%', height: '50px', display: 'flex', alignItems: 'center', backgroundColor: '#F6F6F6',
                fontWeight: 500
            }}>
                <div style={{ width: '10%', paddingLeft: '19px' }}>{intl.get('BloggerRanking').Ranking}</div>
                <div style={{ width: '30%', paddingLeft: '14px' }}>{intl.get('SmallShop')['小店']}</div>
                <div style={{ width: '20%', paddingRight: '10px' }}>{intl.get('BloggerRanking').FansNum}</div>
                <div style={{ width: '20%', paddingRight: '10px' }}>{intl.get('BloggerRanking').NumberVideos}</div>
                {/*<div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('GoodsRankingBlogger')['关联视频数']}</div>*/}
                {/*<div style={{ width: '20%', paddingRight: '10px' }}>{intl.get('GoodsRankingBlogger')['最热关联视频']}</div>*/}
                <div style={{ width: '20%' }}>{intl.get('BloggerRanking').Operation}</div>
            </div>}
            <Spin tip="数据加载中..." spinning={loading}>
                <InfiniteScroll
                    dataLength={data.length} scrollThreshold={'20px'}
                    next={loadMoreData}
                    hasMore={data.length < total}
                    // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                    endMessage={<Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={data}
                        grid={{
                            gutter: 0,
                            column: isBig ? widths : 1
                        }}
                        renderItem={
                            (item, index) =>
                                <div style={{ paddingTop: isBig ? '10px' : 0 }}>{SmallDiv(item, index)}</div>
                        }
                    />
                </InfiniteScroll>
            </Spin>
        </div>
    </div>

}
export default withRouter(BloggerRanking);