import React, { useState, useEffect } from 'react'
import { Checkbox, message, Modal } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom'
import { getOrderCode, getOrderStatus } from '../../../service/https'
import QRCode from 'qrcode.react';
import withRouter from '../../../utils/withRouter'
import intl from 'react-intl-universal';
import '../../../asset/css/BuyVip.scss'
import * as Api from '../../../service/https'
import store from '../../../utils/redux/index'
let interval = null

const BuyVip = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [textJson, setTextJson] = useState({})
    const [timeType, setTimeType] = useState(1)
    const [payType, setPayType] = useState(1)
    const [checked, setChecked] = useState(false)
    const [lng, setLng] = useState()
    const [data, setData] = useState({})
    const [url, setUrl] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false)

    useEffect(() => {
        setTextJson(intl.get('buyVip'))
        setLng(localStorage.getItem('locale'))
        setData(location.state.data)
        if (!location.state?.vipType) {
            navigate('/index/member')
        }
        return () => {
            clearInterval(interval);
        }
    }, [])

    const buy = e => {
        if (checked) {
            getOrderCode({
                membershipType: data.id,
                paymentMode: payType === 1 ? 'WeChat' : 'Alipay',
                orderType: timeType === 0 ? 'HALFYEAR' : 'ONEYEAR',
            }).then(res => {
                if (res.code === 200) {
                    setUrl(res.data.url)
                    setIsModalVisible(true)
                    interval = setInterval(() => {
                        getOrderStatus({ outTradeNo: res.data.order.outTradeNo }).then(res => {
                            if (res.code === 200) {
                                if (res.data === 1 || res.data === 22) {
                                    Api.getUserInfo().then(res => {
                                        if (res.code === 200) {
                                            store.dispatch({ type: 'userInfo', value: res.data })
                                            message.success('恭喜您，开通成功！')
                                            props.navigate('/index/bloggerRanking')
                                            clearInterval(interval);
                                        }
                                    })
                                }
                            }
                        })
                    }, 1000)
                }
            })
        } else {
            message.warn("请先查看并同意服务协议~")
        }
    }

    const user = (e) => {
        e.preventDefault();
        props.navigate('/userAgreement')
    }

    return (
        <div id='BuyVip'>
            <div className='top'>
                <div>{
                    location.state.vipType === 1 ? lng === 'en' ? 'Buy ' + textJson.vip : '开通' + textJson.vip + '会员' :
                        location.state.vipType === 2 ? lng === 'en' ? 'Buy ' + textJson.vipPor : '开通' + textJson.vipPor + '会员' :
                            lng === 'en' ? 'Buy ' + textJson.vipPorMax : '开通' + textJson.vipPorMax + '会员'
                }</div>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={e => { navigate('/index/member') }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/fanhui.png'} style={{ marginRight: '6px' }} alt='' />{textJson.back}
                </div>
            </div>
            <div className='middle'>
                <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px dashed #eee', paddingBottom: '40px' }}>
                    <div style={{ color: '#666', marginRight: '40px' }}>{textJson.account}</div>
                    <div style={{ fontFamily: 'neue', fontWeight: '800', fontSize: '28px', marginRight: '20px' }}>{location.state.phone}</div>
                    <div style={{ height: '20px' }}></div>
                    {/* <div style={{ borderRight: '1px solid #eee',color: '#666', marginLeft: '20px' }}>{textJson.notVip}</div> */}
                </div>
                <div style={{ marginTop: '34px', display: 'flex', alignItems: 'center' }}>
                    <div style={{ color: '#666', marginRight: '40px' }}>{textJson.version}</div>
                    <img src={`https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/v${location.state.vipType}.png`} alt='' />
                    <div style={{ fontWeight: 800, fontSize: '18px', marginLeft: '9px' }}>{
                        location.state.vipType === 1 ? textJson.vip :
                            location.state.vipType === 2 ? textJson.vipPor :
                                textJson.vipPorMax
                    }</div>
                </div>
                <div style={{ marginTop: '34px', display: 'flex' }}>
                    <div style={{ color: '#666', marginRight: '40px' }}>{textJson.time}</div>
                    {/* <div style={{ borderRadius: '5px', border: timeType === 0 ? '1px solid #F7385B' : '1px solid #eee', background: timeType === 0 ? '#FFEEF1' : '#fff', display: 'flex', cursor: 'pointer', overflow: 'hidden', position: 'relative' }} onClick={e => { setTimeType(0) }}>
                        <div style={{ width: '76px', height: '78px', lineHeight: '78px', textAlign: 'center', background: timeType === 0 ? 'linear-gradient(180deg, #F7385B, #FF716E)' : '#eee', color: timeType === 0 ? '#FFF' : '#666' }}>{textJson.hYear}</div>
                        <div style={{ width: '200px', height: '78px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '32px', fontFamily: 'neue', fontWeight: '800', color: timeType === 0 ? '#F7385B' : '#333' }}><span style={{ fontFamily: 'neue', fontWeight: 600, fontSize: '18px', paddingTop: '4px' }}>￥</span>{data.membershipHalfYearPrice}</div>
                        {timeType === 0 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} alt='' style={{ position: 'absolute', bottom: '0', right: '0' }} /> : ''}
                    </div> */}
                    <div style={{ borderRadius: '5px', border: timeType === 1 ? '1px solid #F7385B' : '1px solid #eee', background: timeType === 1 ? '#FFEEF1' : '#fff', display: 'flex', cursor: 'pointer', overflow: 'hidden', position: 'relative' }} onClick={e => { setTimeType(1) }}>
                        <div style={{ width: '76px', height: '78px', lineHeight: '78px', textAlign: 'center', background: timeType === 1 ? 'linear-gradient(180deg, #F7385B, #FF716E)' : '#eee', color: timeType === 1 ? '#FFF' : '#666' }}>{textJson.oYear}</div>
                        <div style={{ width: '200px', height: '78px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '32px', fontFamily: 'neue', fontWeight: '800', color: timeType === 1 ? '#F7385B' : '#333' }}><span style={{ fontFamily: 'neue', fontWeight: 600, fontSize: '18px', paddingTop: '4px' }}>￥</span>{data.membershipYearPrice}
                            {/* <s style={{ color: '#888', fontSize: '16px', fontFamily: 'neue', fontWeight: 600, marginLeft: '5px', paddingTop: '4px' }}>{data.membershipYearPrice}</s> */}
                        </div>
                        {/* <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/off.png'} alt='' style={{ position: 'absolute', top: '0', right: '0' }} /> */}
                        {timeType === 1 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} alt='' style={{ position: 'absolute', bottom: '0', right: '0' }} /> : ''}
                    </div>
                </div>
                <div style={{ marginTop: '34px', display: 'flex', borderBottom: '1px dashed #eee', paddingBottom: '50px' }}>
                    <div style={{ color: '#666', marginRight: '40px' }}>{textJson.payment}</div>
                    <div style={{ width: '280px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: payType === 1 ? '1px solid #F7385B' : '1px solid #EEEEEE', borderRadius: '5px', position: 'relative', cursor: 'pointer' }} onClick={e => { setPayType(1) }}><img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/wx.png'} style={{ marginRight: '11px' }} alt="" />{textJson.wx}{payType === 1 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} alt='' style={{ position: 'absolute', bottom: '0', right: '0' }} /> : ''}</div>
                    <div style={{ width: '280px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: payType === 2 ? '1px solid #F7385B' : '1px solid #EEEEEE', borderRadius: '5px', position: 'relative', cursor: 'pointer', marginLeft: '34px' }} onClick={e => { setPayType(2) }}><img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/zfb.png'} style={{ marginRight: '11px' }} alt="" />{textJson.ali}{payType === 2 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} alt='' style={{ position: 'absolute', bottom: '0', right: '0' }} /> : ''}</div>
                </div>
                <div style={{ marginTop: '40px', display: 'flex', alignItems: 'center' }}>
                    <div style={{ color: '#666', marginRight: '40px' }}>{textJson.cost}</div>
                    <div>
                        <span style={{ color: '#F7385B', fontSize: '28px', fontFamily: 'neue', fontWeight: '800' }}>{timeType === 0 ? data.membershipHalfYearPrice : data.membershipYearPrice}</span>
                        <span style={{ color: '#333' }}>{textJson.RMB}</span>
                        <span style={{ color: '#CCCCCC' }}>{textJson.tips}</span>
                    </div>
                </div>
            </div>
            <div className='bottom'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ color: '#fff' }}>{textJson.pia}</div>
                    <div style={{ color: '#fff', marginLeft: '41px' }}>
                        <span style={{ color: '#F7385B', fontSize: '28px', fontFamily: 'neue', fontWeight: '800' }}>{timeType === 0 ? data.membershipHalfYearPrice : data.membershipYearPrice}</span>
                        <span>{textJson.RMB}</span>
                        <span>（{intl.get('member').expiryDate}<span style={{ color: '#F7385B' }}>{timeType === 0 ? location.state.time.halfYear.end_time : location.state.time.oneYear.end_time}</span>）</span>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={checked} onChange={e => { setChecked(e.target.checked) }} style={{ color: '#fff' }}>{intl.get('ReleaseDemand')['我已阅读并同意']}BRANDGOGO<span style={{ color: '#F7385B' }} onClick={user}>《{intl.get('ReleaseDemand')['服务协议']}》</span></Checkbox>
                    <div style={{ width: '150px', height: '50px', borderRadius: '50px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', lineHeight: '50px', textAlign: 'center', cursor: 'pointer' }} onClick={e => buy()}>{intl.get('ReleaseDemand')['确定支付']}</div>
                </div>
            </div>


            <Modal visible={isModalVisible} centered={true} footer={null} onCancel={e => { setIsModalVisible(false); clearInterval(interval); }}
                getContainer={() => document.getElementById('BuyVip')}>
                <div style={{ padding: '36px', width: '400px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '20px' }}>{intl.get('ReleaseDemand')['请使用']}{payType === 1 ? textJson.wx : textJson.ali}{intl.get('ReleaseDemand')['扫码支付']}</div>
                    {
                        url ?
                            <QRCode
                                id="bill_qr_code_url"
                                value={url} //value参数为生成二维码的链接 我这里是由后端返回
                                size={200} //二维码的宽高尺寸
                                fgColor="#000000"  //二维码的颜色
                            />
                            :
                            ''
                    }
                </div>
            </Modal>
        </div>
    )
}

export default withRouter(BuyVip);