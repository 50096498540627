import React, { useState, useEffect } from 'react'
import { DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import { Select, Input, List, notification, Avatar, Skeleton, Divider, Image, Spin } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom'
import * as Api from '../../../service/https';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../../utils/redux/index'
import withRouter from '../../../utils/withRouter'
import intl from 'react-intl-universal';
import '../../../asset/css/TopicList.scss'
import PkModel from '../../../components/PkModel'
import ShareTopicModel from '../../../components/Share/ShareTopicModel'
import toKMB from '../../../utils/KMB'
import toHMS from '../../../utils/toHMS'
import moment from 'moment';
import openNotificationWithIcon from '../../../components/Notification'

const { Option } = Select;

const MyTopic = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const navigate = useNavigate();
    const [state, setSate] = useState(store.getState());
    const [textJson, setTextJson] = useState(intl.get('TopicList'))
    const [sortList, setSortList] = useState([]);
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [widths, setWidths] = useState(0)
    useEffect(() => {
        // console.log(props)
        notification.destroy()
        setSortList([
            textJson.PlaysNum,
            textJson.VideosNum
        ])
        getCountry()
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
            notification.destroy()
        }
    }, [])

    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(height)
        let num = state.collapsed + (width > 1890 ? 6 : width > 1650 ? 5 : 4)
        setWidths(num)
    }
    useEffect(() => {
        heightFun()
    }, [state.collapsed])

    const [tabList, setTabList] = useState([
        '全部', '英国', '印度尼西亚', '美国', '日本', '沙特阿拉伯', '德国', '越南', '巴西', '泰国',
        '俄罗斯联邦', '马来西亚', '菲律宾', '阿联酋', '墨西哥', '沙特阿拉伯'
    ])
    const [tabIndex, setTabIndex] = useState(0)
    const [tabShow, setTabShow] = useState(false)
    const changeTab = (index) => {
        setTabIndex(index)
    }
    const [geolocation, setGeolocation] = useState([])
    const getCountry = ()=>{
        Api.getCountry().then(res=>{
            if(res.code == 200){
                let all = res.data
                all.unshift({id: 0, name: '全部', enName: 'All'})
                setGeolocation(all)
            }
        })
    }

    const [sortNumList, setItems] = useState([
        {start: 0, key: '<', end: 10000},
        {start: 10000, key: '~', end: 100000},
        {start: 100000, key: '~', end: 500000},
        {start: 500000, key: '~', end: 1000000},
        {start: 1000000, key: '~', end: 5000000},
        {start: 5000000, key: '~', end: 10000000},
        {start: 10000000, key: '~', end: 50000000},
        {start: 50000000, key: '<', end: 0},
        {start: '', key: '~', end: ''},
        {start: '', key: '~', end: ''}
    ]);
    const [sortVideoIndex, setRangeVideoNumDto] = useState(-1)
    const [sortPlayIndex, setRangePlayNumDto] = useState(-1)
    const changeMinInput = (index, value)=>{
        let v = JSON.parse(JSON.stringify(sortNumList))
        v[index].start = value
        setItems(v)
    }
    const changeMaxInput = (index, value)=>{
        let v = JSON.parse(JSON.stringify(sortNumList))
        v[index].end = value
        setItems(v)
    }
    const changeRangeVideoNumDto = (index)=>{
        setPageIndex(0)
        setRangeVideoNumDto(index == undefined ? -2 : index)
        if(index==undefined){
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[8] = {start: '', key: '~', end: ''}
            setItems(v)
        }
    }
    const changeRangePlayNumDto = (index)=>{
        setPageIndex(0)
        setRangePlayNumDto(index == undefined ? -2 : index)
        if(index==undefined){
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[9] = {start: '', key: '~', end: ''}
            setItems(v)
        }
    }

    const [personal, setPersonal] = useState(false);
    const [enterprise, setEnterprise] = useState(false);
    const [social, setSocial] = useState(false);
    const [link, setLink] = useState(false);
    const [contact, setContact] = useState(false);
    const [sortIndex, setSortIndex] = useState(0);
    const sortMethod = (index) => {
        setData([])
        setSortIndex(index)
    }
    useEffect(() => {
        loadMoreData();
    }, [sortIndex])
    useEffect(()=>{
        if(sortVideoIndex != -1 || sortPlayIndex != -1)loadMoreData()
    },[sortVideoIndex, sortPlayIndex])

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0)
    const [page, setPageIndex] = useState(1)

    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let postValue = {
            bloggerTitleSortRuler: sortIndex + 1,
            from: page,
            size: 10,
            rangeViewNumDto: sortVideoIndex== (-2 || -1) ? {"start":0 , "end": 0} : sortNumList[sortVideoIndex] ,
            rangeVideoNumDto: sortPlayIndex== (-2 || -1) ? {"start":0 , "end": 0} : sortNumList[sortPlayIndex] ,
        }
        Api.getTipCollect(postValue).then(res => {
            if(res.code == 200){
                setData(page == 1 ? res.data.records : [...data, ...res.data.records]);
                setTotal(res.data.total)
                setPageIndex(page + 1)
                setLoading(false);
            }
        })
    };

    const [collectList, setCollectList] = useState([])

    // 别表
    const SmallDiv = (item, index) => {
        return <div key={index} style={{
            width: '100%', height: '151px', display: 'flex', alignItems: 'center',
            backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F6F6F6', cursor: 'pointer'
        }}
            onClick={() => detail(item, index)}>

            <div style={{ width: '8%', paddingLeft: '19px' }}>
                <div style={{ width: '32px', textAlign: 'center', fontWeight: 500 }}>{index + 1}</div>
            </div>
            <div style={{ width: '25%', display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '14px' }}>
                    <Image src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/topic.png'} width={34} preview={false} />
                </div>
                <div>
                    <div style={{ fontFamily: 'neue', fontWeight: '800', fontSize: '16px' }}>{item.title}</div>
                    <div style={{ color: '#888888', fontFamily: 'neue' }}>更新时间：{moment(item.updateTime * 1000).format('YYYY-MM-DD')}</div>
                </div>
            </div>
            <div style={{ width: '8%', fontFamily: 'neue' }}>{toKMB(item.viewCount,2)}</div>
            <div style={{ width: '8%', fontFamily: 'neue' }}>{toKMB(item.videoCount,2)}</div>
            <div style={{ width: '8%', fontFamily: 'neue' }}>{toKMB(item.diggCount,2)}</div>
            <div style={{ width: '8%', fontFamily: 'neue' }}>{toKMB(item.commentCount,2)}</div>
            <div style={{ width: '8%', fontFamily: 'neue' }}>{toKMB(item.shareCount,2)}</div>
            <div style={{ width: '20%', display: 'flex' }}>
                {
                    item.titleCollectHotVideos?.map((item2, index) => index<2?<div key={index} style={{ marginRight: '10px', position: 'relative' }}
                        onClick={()=>window.open(item2.playAddr)}>
                        <Image src={item2.cover} preview={false} width={80} height={111} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'} />
                        <div style={{ position: 'absolute', bottom: '0', display: 'flex', alignItems: 'center', width: '80px', textAlign: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', width: '80px', justifyContent: 'center' }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/playCircle.png'} style={{ width: 12, height: 12, marginRight: '3px' }} alt="" />
                                <div style={{ color: '#fff', fontSize: '13px' }}>{toHMS(item2.duration || 0)}</div>
                            </div>
                        </div>
                    </div>:'')
                }
            </div>
            <div style={{ width: '' }}>
                        <img
                            onClick={(e) => delCollect(e, item, index, collectList.indexOf(index))}
                            src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/scs.png'}
                            alt=""
                        />
                </div>
        </div>
    }

    const detail = (item, index) => {
        window.open(`/index/TopicDetail/${item.titleId}/${item.title}`)
    }
    const addPK = (e, item, index) => {
        e.stopPropagation();
        console.log('pk', index)
    }
    const delCollect = (e,item,index) => {
        e.stopPropagation();
        Api.delTipCollect({titleCollectId: item.id}).then(res => {
            // console.log(res)
            if (res.code == 200) {
                openNotificationWithIcon('success', intl.get('Common')['取消成功'])
                let v = JSON.parse(JSON.stringify(data))
                v.splice(index, 1)
                // console.log(v)
                setTotal(total-1)
                setData(v)
            }
        })
    }

    // 生成图片自动下载为png格式（将dom转为二进制再编译下载）
    const [isShare, setIsShare] = useState(false)
    const getBlobPng = () => {
        setIsShare(true)
        // const node = document.getElementById("asd");
        // domtoimage.toBlob(node).then((blob) => {
        //     // 调用file-save方法 直接保存图片
        //     saveAs(blob, '自动保存.png'
        // })
    }

    const onChange = (e,index) =>{

    }

    return (
        <div id='TopicList'>
            {/* <nav style={{ width: '100%', position: 'relative', fontWeight: 500 }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt=""
                    style={{ width: '100%' }} />
                <div style={{
                    width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt="" 
                    style={{width:'190px'}}/>
                    <div style={{
                        width: '10px', height: '10px', backgroundColor: 'black', borderRadius: '5px',
                        margin: '0 20px'
                    }}></div>
                    <span style={{ fontSize: '20px' }}>话题排行榜</span>
                    <div style={{
                        width: '166px', height: '48px', textAlign: 'center', lineHeight: '48px',
                        backgroundImage: 'url(' + 'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/back.png' + ')', backgroundSize: '100% 100%',
                        color: '#FFF', marginLeft: '34px', paddingRight: '10px', cursor: 'pointer'
                    }}
                        onClick={getBlobPng}>分享话题榜单</div>
                </div>
            </nav> */}

            <div style={{
                width: '100%', backgroundColor: '#FFF', paddingTop: '24px', paddingLeft: '48px', paddingBottom: '25px',
                fontWeight: 500
            }}>
                {/* 国家 */}
                {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <label style={{ width: state.language === 'zh' ? '70px' : '90px', marginTop: '6px', textAlign: 'right', paddingRight: '26px' }}>{textJson.Country}：</label>
                    <div style={{
                        width: 'calc(100% - 70px)', height: tabShow ? 'auto' : '39px', display: 'flex', flexWrap: 'wrap',
                        overflow: tabShow ? '' : 'hidden'
                    }}>
                        {
                            geolocation?.map((item, index) => {
                                return <span key={index} className='tabItem' style={{
                                    padding: '6px 10px',
                                    background: index == tabIndex ? '#FFE7E9' : 'none', marginBottom: '5px',
                                    color: index == tabIndex ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => changeTab(index)}>{lang == 'zh' ? item.name: item.enName}</span>
                            })
                        }
                    </div>
                    <div style={{ width: '45px' }}>
                        {!tabShow ? <DownSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                            onClick={() => setTabShow(true)} /> :
                            <UpSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                                onClick={() => setTabShow(false)} />}
                    </div>
                </div> */}

                {/* 筛选 */}
                <div style={{ width: '100%', height: '30px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <label style={{ width: state.language == 'zh' ? '70px' : '100px', textAlign: 'right', paddingRight: state.language == 'zh' ? '28px' : '32px' }}>
                        {textJson.Screen}：</label>
                    <div id='fans'>
                        <span>{textJson.PlaysNum}</span>
                        <Select
                            onClear={e=>changeRangeVideoNumDto(undefined)} 
                            allowClear={true}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <div id='login' style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0 10px', paddingBottom:'5px'}}>
                                        <Input value={sortNumList[8].start}
                                            onChange={e=>changeMinInput(8, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="min" /> ~ 
                                        <Input value={sortNumList[8].end} 
                                            onChange={e=>changeMaxInput(8, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="max" /> 
                                        <span style={{border: sortNumList[8].start>=0&&parseInt(sortNumList[8].end)>parseInt(sortNumList[8].start)?'':'1px solid #EEEEEE', borderRadius:'4px', padding:'5.5px 11px', marginLeft:'5px', cursor:'pointer',
                                            color: sortNumList[8].start>=0&&parseInt(sortNumList[8].end)>parseInt(sortNumList[8].start)?'#FFF':'#CCCCCC', 
                                            background: sortNumList[8].start>=0&&parseInt(sortNumList[8].end)>parseInt(sortNumList[8].start)?'#F7385B':'#FFF', fontSize:'12px'}} 
                                            onClick={()=>sortNumList[8].start>=0&&parseInt(sortNumList[8].end)>parseInt(sortNumList[8].start)?changeRangeVideoNumDto(8):null}>确定</span>
                                    </div>
                                </>
                            )}
                            value={(sortVideoIndex == -1||sortVideoIndex ==-2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : ((sortNumList[sortVideoIndex].start>0?toKMB(sortNumList[sortVideoIndex].start):'') + ' '+sortNumList[sortVideoIndex].key+' ' + (sortNumList[sortVideoIndex].end>0?toKMB(sortNumList[sortVideoIndex].end):''))}
                            style={{ width: 200, borderRadius:'26px', marginLeft:'10px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={intl.get('TopicList').PleaseSelect}>
                            {sortNumList?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeRangeVideoNumDto(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                            ))}
                        </Select>
                    </div>
                    <div id='fans'>
                        <span style={{ marginLeft: state.language == 'zh' ? '40px' : '20px' }}>{textJson.VideosNum}</span>
                        <Select
                            onClear={e=>changeRangePlayNumDto(undefined)} 
                            allowClear={true}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <div id='login' style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0 10px', paddingBottom:'5px'}}>
                                        <Input value={sortNumList[9].start}
                                            onChange={e=>changeMinInput(9, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="min" /> ~ 
                                        <Input value={sortNumList[9].end} 
                                            onChange={e=>changeMaxInput(9, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="max" /> 
                                        <span style={{border: sortNumList[9].start>=0&&parseInt(sortNumList[9].end)>parseInt(sortNumList[9].start)?'':'1px solid #EEEEEE', borderRadius:'4px', padding:'5.5px 11px', marginLeft:'5px', cursor:'pointer',
                                            color: sortNumList[9].start>=0&&parseInt(sortNumList[9].end)>parseInt(sortNumList[9].start)?'#FFF':'#CCCCCC', 
                                            background: sortNumList[9].start>=0&&parseInt(sortNumList[9].end)>parseInt(sortNumList[9].start)?'#F7385B':'#FFF', fontSize:'12px'}} 
                                            onClick={()=>sortNumList[9].start>=0&&parseInt(sortNumList[9].end)>parseInt(sortNumList[9].start)?changeRangePlayNumDto(9):null}>确定</span>
                                    </div>
                                </>
                            )}
                            value={(sortPlayIndex == -1||sortPlayIndex ==-2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : ((sortNumList[sortPlayIndex].start>0?toKMB(sortNumList[sortPlayIndex].start):'') + ' '+sortNumList[sortPlayIndex].key+' ' + (sortNumList[sortPlayIndex].end>0?toKMB(sortNumList[sortPlayIndex].end):''))}
                            style={{ width: 200, borderRadius:'26px', marginLeft:'10px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={intl.get('TopicList').PleaseSelect}>
                            {sortNumList?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeRangePlayNumDto(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                            ))}
                        </Select>
                    </div>
                </div>

            </div>

            {/* 排序 */}
            <div style={{
                width: '100%', height: '60px', backgroundColor: '#FFF', marginTop: '10px',
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '47px',
                paddingRight: '24px'
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        sortList?.map((item, index) => {
                            return <div key={index} className='active' style={{
                                display: 'flex', alignItems: 'center', marginRight: '36px',
                                color: sortIndex == index ? '#F7385B' : '#888888', cursor: 'pointer'
                            }}
                                onClick={() => sortMethod(index)}>
                                {item}
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index ? 's' : '') + '.png'} alt=""
                                    style={{ marginLeft: '6px', marginTop: '2px' }} />
                            </div>
                        })
                    }
                </div>
            </div>

            {/* 列表 */}
            <div style={{
                width: '100%', backgroundColor: '#FFFFFF', padding: '24px 24px 24px 30px',
                marginTop: '10px'
            }}>
                <div style={{
                    width: '100%', height: '50px', display: 'flex', alignItems: 'center', backgroundColor: '#F6F6F6',
                    fontWeight: 500
                }}>
                    <div style={{ width: '8%', paddingLeft: '19px' }}>{textJson.Ranking}</div>
                    <div style={{ width: '25%', paddingLeft: '14px' }}>{textJson.Topics}</div>
                    <div style={{ width: '8%', paddingRight: '10px' }}>{textJson.PlaysNum}</div>
                    <div style={{ width: '8%', paddingRight: '10px' }}>{textJson.VideosNum}</div>
                    <div style={{ width: '8%', paddingRight: '10px' }}>{textJson.LikesNum}</div>
                    <div style={{ width: '8%', paddingRight: '10px' }}>{textJson.CommentsNum}</div>
                    <div style={{ width: '8%', paddingRight: '10px' }}>{textJson.SharesNum}</div>
                    <div style={{ width: '20%', paddingRight: '10px' }}>{textJson.HotsNum}</div>
                    <div style={{ width: '' }}>{textJson.Operation}</div>
                </div>
                {data.length > 0 ? <Spin tip="数据加载中..." spinning={loading}>
                <InfiniteScroll
                    dataLength={data.length}
                    next={loadMoreData}
                    hasMore={data.length < total}
                    loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                    endMessage={<Divider plain>{textJson.Nothing} 🤐</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={data} grid={{ gutter: 0, column: 1 }}
                        renderItem={(item, index) => <div key={index} style={{ paddingTop: 0 }}>
                            {SmallDiv(item, index)}
                        </div>}
                    />
                </InfiniteScroll>
                </Spin> :
                <div style={{width:'345px', height:'200px', textAlign:'center', margin:'0 auto', marginTop:'100px' }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                    <div style={{marginTop:'24px', textAlign: 'center', color:'#333' }}>{intl.get('Common')['暂无数据']}</div>
                </div>}
            </div>



            {isShare ? <ShareTopicModel clickFun={() => getBlobPng(false)} /> : ''}
        </div>
    )
}

export default withRouter(MyTopic);