import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import { Modal, Input, message, Select, Checkbox, Image, Spin, Pagination, Divider, DatePicker, Button } from 'antd';
import * as Api from '../../../service/https';
import '../../../asset/css/MyGoods.scss'
import intl from 'react-intl-universal';
import store from '../../../utils/redux/index'
import { debounce } from '../../../utils/debounce'
import {
    editMyGoods,
    getSupplyOrderData,
    getSupplyOrderList,
    getSupplyOrderTrend,
    getSupplyShopLog, getSupplyShopModule, infoP
} from "../../../service/https";
import openNotificationWithIcon from "../../../components/Notification";
import { color } from 'echarts';
import moment from 'moment';
const { RangePicker } = DatePicker;

const { Option } = Select

const MyOrder = (props) => {
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);
    const [searchParams, searchParamsSet] = useState({
        size: 10,
        accountName: null,
        moduleName: null,
    })
    const [accountSelect,setAccountSelect] = useState([])
    const [moduleSelect,setModuleSelect] = useState([])
    const [list, listSet] = useState([])
    const [selectList, selectListSet] = useState([])
    const [id_list, id_listSet] = useState([])
    const [loading, setLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [total, totalSet] = useState(-1)

    useEffect(() => {
        getUserInfo()
        getModuleList()
    }, [])

    useEffect(() => {
        getList()
    }, [searchParams, pageIndex])

    const getUserInfo = () => {
        Api.infoP().then(res=>{
            getAccountList(res.data)
        })
    }
    const getAccountList = userInfo => {
        Api.accountList().then(res=>{
            if(res.code == 200){
                console.log(state);
                setAccountSelect([{ label: userInfo?.account, accountName: '主账号' },...res.data.filter(item=>item.mainAccount == false)])
            }
        })
    }

    const getModuleList = () => {
        Api.getSupplyShopModule({}).then(res => {
            if(res.code === 200){
                console.log(res);
                setModuleSelect(res.data)
            }
        })
    }

    const getList = e => {
        setLoading(true)
        let data = { current: pageIndex,}
        Api.getSupplyShopLog({ ...data, ...searchParams }).then(res => {
            if (res.code === 200) {
                let t = res.data.records.map(item => {
                    return item.tkOrderId
                })
                setIndeterminate(false)
                setCheckAll(false)
                selectListSet([])
                setCheckedList([])
                id_listSet(t)
                listSet(res.data.records)
                totalSet(res.data.total)
                setLoading(false)
            }
        })
    }

    const searchChange = (e, id) => {
        let t = JSON.parse(JSON.stringify(searchParams))
        t[id] = e
        searchParamsSet(t)
    }

    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const onChange = (e) => {
        console.log(e)
        setCheckedList(e);
        setIndeterminate(!!e?.length && e?.length < list?.length);
        setCheckAll(e?.length === list?.length);
        let s = []
        list.map((item, index) => {
            if (e.indexOf(item?.tkOrderId) !== -1) {
                s.push(item.tkOrderId)
            }
        })
        selectListSet(s)
    };

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? id_list : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const copyFun = (value, e) => {
        e.stopPropagation()
        // for ie
        if (window.clipboardData) {
            window.clipboardData.clearData();
            window.clipboardData.setData('text', value);
            message.success("复制成功~")
        }
        // for modern browser
        else if (document.execCommand) {
            var element = document.createElement('SPAN');
            element.textContent = value;
            document.body.appendChild(element);
            if (document.selection) {
                var range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (window.getSelection) {
                var range = document.createRange();
                range.selectNode(element);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
            document.execCommand('copy');
            element.remove ? element.remove() : element.removeNode(true);
            message.success("复制成功~")
        } else {
            message.warn("您所用的浏览器不支持快捷复制，请手动复制！")
        }
    }


    return (
        <div id='MyGoods'>
            <div style={{ display: 'flex', alignItems:'center' }}>
                <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/before.png" alt=""
                style={{width:'4px'}}/>
                <div style={{fontSize:'20px', fontWeight:600, marginLeft:'9px'}}>店铺日志</div>
            </div>
            <div id='fans' className='search'>
                <Select onChange={e => searchChange(e, 'accountName')} allowClear value={searchParams.accountName} placeholder={'选择账号'} style={{ width: 160 }}>
                    {
                        accountSelect?.map((item,index) =>{
                            return <Option value={item.label}>{item.label}</Option>
                        })
                    }
                </Select>
                <div style={{ margin: '0 10px' }}></div>
                <Select onChange={e => searchChange(e, 'moduleName')} allowClear value={searchParams.moduleName} placeholder={'模块'} style={{ width: 120 }}>
                    {
                        moduleSelect?.map((item,index) =>{
                            return <Option value={item}>{item}</Option>
                        })
                    }
                </Select>
            </div>
            <div style={{width:'100%', background:'#FFF', padding:'20px 24px', marginTop:'10px'}}>
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div style={{fontSize:'20px', fontWeight:600}}>店铺操作记录</div>
                </div>
                <div className='goods_main' style={{background:'none'}}>
                    <div style={{padding:'5px 0'}}></div>
                    <div style={{fontWeight:500, fontSize:'13px', color:'#666'}}>
                        {/*<Checkbox style={{ width: '18px' }} indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}></Checkbox>*/}
                        <div style={{ width: '200px'}}>账号</div>
                        <div style={{ width: '200px' }}>操作模块</div>
                        <div style={{ width: '400px' }}>操作内容</div>
                        <div style={{ width: '200px' }}>时间</div>
                    </div>
                </div>
                <Spin tip="数据加载中..." spinning={loading}>
                    <div className='list'>
                        {
                            list?.length > 0 ?
                                list.map((item, index) => {
                                    return (
                                        <div key={index} style={{fontWeight:500,background:'#FFF', borderRadius:'5px', width:'100%', height:'100px', padding:'20px 24px', display:'flex', alignItems:'center'}}>
                                            {/*<Checkbox style={{ width: '18px' }}  value={item?.tkOrderId} ></Checkbox>*/}
                                            <div style={{ width: '200px' }}>
                                                <div>{item?.userAccountName}</div>
                                                {item?.userAccountDesc}
                                            </div>
                                            <div style={{ width: '200px' }}>{item?.module}</div>
                                            <div style={{ width: '400px' }}>{item?.content}</div>
                                            <div style={{ width: '200px' }}>{item?.createTime}</div>
                                        </div>

                                    )
                                })
                                :
                                <div className='flex-acjc' style={{ width: '100%', flexDirection: 'column', paddingTop: '100px' }}>
                                    <Image width={105} height={100} preview={false} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png' alt='' />
                                    <div style={{ marginTop: '23px' }}>暂无数据</div>
                                </div>
                        }
                    </div>
                </Spin>
            </div>
            <Pagination style={{ float: 'right', margin: '20px 0' }} current={pageIndex} total={total} onChange={e => { setPageIndex(e) }} />
        </div>
    )
}
export default withRouter(MyOrder);
