import React, {useState, useEffect} from 'react'
import withRouter from '../../utils/withRouter'
import intl from "react-intl-universal";
import moment from "moment/moment";
import {Area,Pie,WordCloud} from '@ant-design/plots'
import {CalendarOutlined} from "@ant-design/icons";
import * as Api from "../../service/https";
import toKMB from "../../utils/KMB";
import three from "../../utils/three";
import {Spin} from "antd";

const GoodsRankingData = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const id = props.id
    const goods_detail = props.goods_detail
    const [dayList] = useState(intl.get('GoodsRankingDetail').DaysArr)
    const [dayIndex, setDayIndex] = useState(0)
    const [dataTypeList] = useState([intl.get('GoodsRankingData')['销量'],intl.get('GoodsRankingData')['销售额'],intl.get('GoodsRankingData')['关联博主'],/*intl.get('GoodsRankingData')['关联视频']*/])
    const [dataTypeIndex, setDataTypeIndex] = useState(0)
    const [analysisObj,setAnalysisObj] = useState(null)

    // 商品趋势分析图表配置
    const trendOption = {
        data:[],
        padding:[20,60],
        autoFit: true,
        xField: 'date',
        yField: 'count',
        xAxis: {
            range: [0, 1],
        },
        color: '#F7385B',
        areaStyle: {
            fill: 'l(90) 0:#F7385B  1:#FFFFFF',
        },
        sum:0,
    }
    const [trendOptions,setTrendOptions] = useState(trendOption)

    // 关联博主带货分类分析图表配置
    const PieOption = {
        appendPadding: 10,
        height:240,
        data:[
            {
                type: '分类一',
                value: 27,
            },
            {
                type: '分类二',
                value: 25,
            },
            {
                type: '分类三',
                value: 18,
            },
            {
                type: '分类四',
                value: 15,
            },
            {
                type: '分类五',
                value: 10,
            },
            {
                type: '其他',
                value: 5,
            },
        ],
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-50%',
            content: '{value}',
            style: {
                textAlign: 'center',
                fontSize: 14,
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: '',
            },
        },
    }
    const [pieOptions] = useState(PieOption)

    // 关联视频话题分析图表配置
    const wordsCloudOption = {
        data:[
            {
                "value": 9,
                "name": "AntV"
            },
            {
                "value": 8,
                "name": "F2"
            },
            {
                "value": 8,
                "name": "G2"
            },
            {
                "value": 8,
                "name": "G6"
            },
            {
                "value": 8,
                "name": "DataSet"
            },
            {
                "value": 8,
                "name": "墨者学院"
            },
            {
                "value": 6,
                "name": "Analysis"
            },
        ],
        wordField: 'name',
        weightField: 'value',
        colorField: 'name',
        wordStyle: {
            fontFamily: 'Verdana',
            fontSize: [8, 32],
            rotation: 0,
        },
        // 返回值设置成一个 [0, 1) 区间内的值，
        // 可以让每次渲染的位置相同（前提是每次的宽高一致）。
        random: () => 0.5,
    }
    const [wordsCloudOptions] = useState(wordsCloudOption)

    useEffect(()=>{
        getAnalysis()
    },[dayIndex])


    useEffect(()=>{
        refreshAnalysis(analysisObj)
    },[dataTypeIndex])

    const refreshAnalysis = (analysisObj) =>{
        let trend = Object.assign({},trendOption)
        let sum = 0
        if(dataTypeIndex == 0){
            trend.data = analysisObj?.soldCountMap?.soldCountList || []
            sum = analysisObj?.soldCountMap?.['sum'] || 0
            trend.tooltip =  {
                formatter: (datum) => {
                    return {
                        name: intl.get('GoodsRankingData')['销量'],
                        value: toKMB(datum.count, 2)
                    };
                },
            }
        }
        else if(dataTypeIndex == 1){
            trend.data = analysisObj?.soldTotalMap?.soldTotalList || []
            sum = analysisObj?.soldTotalMap?.['sum'] || 0
            trend.tooltip =  {
                formatter: (datum) => {
                    return {
                        name: intl.get('GoodsRankingData')['销售额'],
                        value: toKMB(datum.count, 2)
                    };
                },
            }
        }
        else if(dataTypeIndex == 2){
            trend.data = analysisObj?.bloggerCountMap?.bloggerList || []
            sum = analysisObj?.bloggerCountMap?.['sum'] || 0
            trend.tooltip =  {
                formatter: (datum) => {
                    return {
                        name: intl.get('GoodsRankingData')['关联博主'],
                        value: toKMB(datum.count, 2)
                    };
                },
            }
        }
        trend.sum = sum
        setTrendOptions(trend)
    }

    const getAnalysis = e => {
        Api.getGoodsRankingAnalysis({
            productId:id,
            startTime: moment(moment().subtract(dayList[dayIndex].value, 'days')).format('YYYY-MM-DD'),
            // endTime: moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')
            endTime: ''
        }).then(res=>{
            if (res.code === 200) {
                setAnalysisObj(res.data)
                refreshAnalysis(res.data)
                // let trend = Object.assign({},trendOption)
                // let sum = 0
                // if(dataTypeIndex == 0){
                //     trend.data = res.data?.soldCountMap?.soldCountList || []
                //     sum = res.data?.soldCountMap?.['sum'] || 0
                //     trend.tooltip =  {
                //         formatter: (datum) => {
                //             return {
                //                 name: intl.get('GoodsRankingData')['销量'],
                //                 value: toKMB(datum.count, 2)
                //             };
                //         },
                //     }
                // }
                // else if(dataTypeIndex == 1){
                //     trend.data = res.data?.soldTotalMap?.soldTotalList || []
                //     sum = res.data?.soldTotalMap?.['sum'] || 0
                //     trend.tooltip =  {
                //         formatter: (datum) => {
                //             return {
                //                 name: intl.get('GoodsRankingData')['销售额'],
                //                 value: toKMB(datum.count, 2)
                //             };
                //         },
                //     }
                // }
                // else if(dataTypeIndex == 2){
                //     trend.data = res.data?.bloggerCountMap?.bloggerList || []
                //     sum = res.data?.bloggerCountMap?.['sum'] || 0
                //     trend.tooltip =  {
                //         formatter: (datum) => {
                //             return {
                //                 name: intl.get('GoodsRankingData')['关联博主'],
                //                 value: toKMB(datum.count, 2)
                //             };
                //         },
                //     }
                // }
                // trend.sum = sum
                // setTrendOptions(trend)
            }
        })
    }

    return <div id='GoodsRankingData' style={{paddingBottom: '50px'}}>
        {/*商品概述*/}
        <div style={{width: '100%', backgroundColor: '#FFF'}}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '24px 24px 0'
            }}>
                <div className='sd-title'>{intl.get('GoodsRankingData')['商品概述']}</div>
            </div>
            <div style={{
                width: '100%',
                height: '114px',
                backgroundColor: '#FFF',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                padding: '0 35px'
            }}>
                <div>
                    <div style={{fontSize: '16px', fontWeight: 'bold'}}>{toKMB(goods_detail?.productBase?.soldCount,2)}</div>
                    <div style={{display: 'flex', alignItems: 'center', color: '#888888'}}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/xiaoliang.png'} alt=""
                             style={{width: '14px', marginRight: '4px',}}/> {intl.get('GoodsRankingData')['总销量']}
                    </div>
                </div>
                <div>
                    <div style={{fontSize: '16px', fontWeight: 'bold'}}>{three(goods_detail?.soldTotal)}</div>
                    <div style={{display: 'flex', alignItems: 'center', color: '#888888'}}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/xiaoshoue.png'} alt=""
                             style={{width: '14px', marginRight: '4px',}}/> {intl.get('GoodsRankingData')['总销售额']}
                    </div>
                </div>
                {/*<div>*/}
                {/*    <div style={{fontSize: '16px', fontWeight: 'bold'}}>{goods_detail.bloggerCount}</div>*/}
                {/*    <div style={{display: 'flex', alignItems: 'center', color: '#888888'}}>*/}
                {/*        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/RelatedBlogger.png'} alt=""*/}
                {/*             style={{*/}
                {/*                 width: '14px',*/}
                {/*                 marginRight: '4px',*/}
                {/*             }}/> {intl.get('GoodsRankingData')['总关联博主数']}*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <div style={{fontSize: '16px', fontWeight: 'bold'}}></div>*/}
                {/*    <div style={{display: 'flex', alignItems: 'center', color: '#888888'}}>*/}
                {/*        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/RelatedVideos.png'} alt=""*/}
                {/*             style={{*/}
                {/*                 width: '14px',*/}
                {/*                 marginRight: '4px',*/}
                {/*             }}/> {intl.get('GoodsRankingData')['总关联视频数']}*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
        {/*商品数据趋势分析*/}
        <div style={{width: '100%', backgroundColor: '#FFF',padding:'24px 24px 28px',marginTop:'10px'}}>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <div className='sd-title'>{intl.get('GoodsRankingData')['商品数据趋势分析']}</div>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={{display: 'flex',borderRadius:'4px',overflow:'hidden'}}>
                        {
                            dayList.map((item, index) => (
                                <span
                                    key={index}
                                    className='tabItem'
                                    style={{
                                        padding: '6px 10px',
                                        marginBottom: '5px',
                                        cursor: 'pointer',
                                        color: index === dayIndex ? '#E94753' : '#CCCCCC',
                                        background: index === dayIndex ? '#FFE7E9' : '#F8F8F8',
                                    }}
                                    onClick={() => setDayIndex(index)}
                                >{item.text}</span>
                            ))
                        }
                    </div>
                    <div style={{
                        width: 240,
                        height: 30,
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '10px',
                        border: '1px solid #EEEEEE',
                        color: '#CCCCCC',
                        marginLeft: '13px',
                        fontSize: '12px'
                    }}>
                        <CalendarOutlined style={{ marginRight: '17px' }} />
                        <div style={{ marginRight: '17px' }}>{moment(moment().subtract(dayList[dayIndex].value, 'days')).format('YYYY-MM-DD')}</div>
                        <div style={{ marginRight: '17px' }}>~</div>
                        <div style={{ marginRight: '17px' }}>{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')}</div>
                    </div>
                </div>
            </div>
            {

                lang === 'zh' ? <div style={{position:'relative',display: 'flex',alignItems:'center', width: 270,height:28,margin:'20px 0',border:'1px solid #EEEEEE',borderRadius:'20px'}}>
                    <div style={{ width: 90, height: '100%', background: 'linear-gradient(180deg, #F7385B, #FF716E)', position: 'absolute', left: `${dataTypeIndex* 33}%`, top: 0, zIndex: 1, borderRadius: '30px', transition: 'all 0.5s' }}></div>
                    {
                        dataTypeList.map((item, index) => (
                            <div
                                key={index}
                                style={{
                                    width:'33%',textAlign:'center', zIndex: 2, cursor: 'pointer',
                                    color: dataTypeIndex === index ? '#fff' : '#333', transition: 'all 0.5s',
                                }}
                                onClick={() => setDataTypeIndex(index)}
                            >{item}</div>
                        ))
                    }
                </div>
                :
                    <div style={{position:'relative',display: 'flex',alignItems:'center', width: 420,height:28,margin:'20px 0',border:'1px solid #EEEEEE',borderRadius:'20px'}}>
                        <div style={{ width: dataTypeIndex == 2 ? '50%' : '25%', height: '100%', background: 'linear-gradient(180deg, #F7385B, #FF716E)', position: 'absolute', left: `${dataTypeIndex * 25}%`, top: 0, zIndex: 1, borderRadius: '30px', transition: 'all 0.5s' }}></div>
                        {
                            dataTypeList.map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        width: index<dataTypeList.length -1 ? '25%' : '50%',textAlign:'center', zIndex: 2, cursor: 'pointer',
                                        color: dataTypeIndex === index ? '#fff' : '#333', transition: 'all 0.5s',
                                    }}
                                    onClick={() => setDataTypeIndex(index)}
                                >{item}</div>
                            ))
                        }
                    </div>
            }
            <div className='flex-acjb' style={{background:'#F6F6F6',padding:'8px 20px',borderRadius:'4px',marginTop:'20px'}}>
                <div>{moment(moment().subtract(dayList[dayIndex].value, 'days')).format('YYYY-MM-DD')} ～ {moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')}{intl.get('GoodsRankingData')['总']}{intl.get('GoodsRankingData')[dataTypeList[dataTypeIndex]]}{intl.get('GoodsRankingData')['为']}<span style={{color:'#F7385B'}}>{trendOptions.sum}</span></div>
                <div>{intl.get('GoodsRankingData')['更新时间']}：{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD hh:mm:ss')}</div>
            </div>
            <div  style={{ marginTop: 40 }}>
                {
                    trendOptions.data?.length > 0 ? <Area {...trendOptions}/> :
                        <div style={{ width: '345px', height: '200px', textAlign: 'center', margin: '0 auto', marginTop: '100px' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                            <div style={{ marginTop: '24px', textAlign: 'center', color: '#333' }}>{intl.get('Common')['暂无数据']}</div>
                        </div>
                }
            </div>
        </div>
        {/*关联博主带货分类分析*/}
        {/*<div style={{width: '100%', backgroundColor: '#FFF',padding:'24px 24px 0'}}>*/}
        {/*    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>*/}
        {/*        <div className='sd-title'>{intl.get('GoodsRankingData')['关联博主带货分类分析']}</div>*/}
        {/*    </div>*/}
        {/*    <div className='flex-acjb' style={{background:'#F6F6F6',padding:'8px 20px',borderRadius:'4px',marginTop:'20px'}}>*/}
        {/*        <div>分类一{intl.get('GoodsRankingData')['分类占比最多']}，{intl.get('GoodsRankingData')['占比为']}<span style={{color:'#F7385B'}}>45.75%</span></div>*/}
        {/*        <div>{intl.get('GoodsRankingData')['更新时间']}：{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD hh:mm:ss')}</div>*/}
        {/*    </div>*/}
        {/*    <div style={{padding:'50px 44px'}}>*/}
        {/*        <Pie {...pieOptions} />*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*关联视频话题分析*/}
        {/*<div style={{width: '100%', backgroundColor: '#FFF',padding:'24px 24px 0'}}>*/}
        {/*    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>*/}
        {/*        <div className='sd-title'>{intl.get('GoodsRankingData')['关联视频话题分析']}</div>*/}
        {/*    </div>*/}
        {/*    <div style={{padding:'50px 44px'}}>*/}
        {/*        <WordCloud {...wordsCloudOptions} />*/}
        {/*    </div>*/}
        {/*</div>*/}
    </div>
}
export default withRouter(GoodsRankingData);