import React, {useState, useEffect, useRef} from 'react'
import withRouter from '../../../utils/withRouter'
import {message, Checkbox, Image, Spin, Pagination, Modal, InputNumber} from 'antd';
import * as Api from '../../../service/https';
import '../../../asset/css/MyExcellentWorks.scss'
import intl from 'react-intl-universal';
import AddMyWorks from '../../../components/Personal/AddMyWorks'
import {commitNiceWork, deleteNiceWork, getNiceWorkList} from "../../../service/https";

const MyExcellentWorks = (props) => {
    const fileTypeList = ['video/mp4','video/ogg','video/avi', 'video/webm','video/quicktime','video/x-matroska', 'video/x-ms-wmv','video/mpeg','video/flv']
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [active_index, active_indexSet] = useState(-1)
    const [searchParams, searchParamsSet] = useState({})
    const [list, listSet] = useState([])
    const [selectList, selectListSet] = useState([])
    const [id_list, id_listSet] = useState([])
    const [open_status, open_statusSet] = useState(-1)
    const [isSpinning, isSpinningSet] = useState(false)
    const [focus, focusSet] = useState(-1)
    const [loading, setLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [total, totalSet] = useState(-1)
    const [showForm, setShowForm] = useState(false)
    const [formData, setFormData] = useState({fileAddress: '', cargoClassificationId: '', mediaEnum: '', targetEnum: ''})
    const [typeList,setTypeList] = useState([])
    const [purposeList,setPurposeList] = useState([
        {val: '商品销售', enVal:'merchandise sales', id:4,icon:''},
        {val: '品牌传播', enVal:'brand communication', id:2, icon:''},
        {val: '商品种草', enVal:'commodity grass', id:3, icon:''},
        {val: '商品好评', enVal:'goods high praise', id:5,icon:''},
        {val: '开箱视频', enVal:'out of the video', id:6,icon:''},
    ])
    const [mediaList,setMediaList] = useState([
        {val: 'TikTok', id:1,icon:''},
        {val: 'Youtube', id:2, icon:''},
        {val: 'Facebook', id:3, icon:''},
        {val: 'Instagram', id:4,icon:''},
    ])
    const getCargo = ()=>{
        Api.getCargo().then(res=>{
            if(res.code == 200){
                let all = res.data
                setTypeList(all)
            }
        })
    }
    useEffect(()=>{
        getCargo()
    },[])

    // 修改表单数据
    const changeFormData = (data) => {
        setFormData(data)
    }

    // 关闭表单弹出
    const closeShowForm = () =>{
        if((formData.fileId && !formData.updateTime) || (formData.oldId && formData.updateTime)) Api.deleteNiceWork({ids:[formData.fileId]}).then()
        setFormData({fileAddress: '', cargoClassificationId: '', mediaEnum: '', targetEnum: ''})
        setShowForm(false)
    }

    const [uid,setUid] = useState(0)
    const getUserInfo = () => {
        Api.getUserInfo().then(res => {
            if(res.code === 200) {
                setUid(res.data.uid)
            }
        })
    }
    useEffect(() => {
        getUserInfo()
    },[])

    useEffect(() => {
        if(uid) getMyWorks()
    }, [uid,searchParams, pageIndex, active_index])

    const getMyWorks = e => {
        setLoading(true)
        let data = {
            uid
        }
        Api.getNiceWorkList({...data, ...searchParams}).then(res => {
            if (res.code === 200) {
                let t = res.data.map((item,index) => {
                    return item.fileId
                })
                if(res.data.length == 0) listSet([])
                let data = [...res.data]
                for(let i in res.data){
                    getVideoDuration(data[i].fileAddress).then(result=>{
                        data[i].duration = result
                        listSet([...data])
                    }).catch(()=>{
                        data[i].duration = 0
                        listSet([...data])
                    })
                }
                setIndeterminate(false)
                setCheckAll(false)
                selectListSet([])
                setCheckedList([])
                id_listSet(t)
                // listSet(data)
                totalSet(res.data.length)
                setLoading(false)
            }
        })
    }


    const [showVideo,setShowVideo] = useState(false)
    const [playVideoUrl,setPlayVideoUrl] = useState('')
    const openVideoModal = (url) =>{
        setPlayVideoUrl(url)
        setShowVideo(!showVideo)
    }

    const getVideoTime = (event,index) =>{
        let arr = [...list]
        arr[index].duration = event.target.duration
        listSet(arr)
    }

    // 获取视频时长
    const getVideoDuration = (url)=>{
        return new Promise(resolve => {
            let audio = new Audio(url)
            audio.addEventListener("loadedmetadata", function (e) {
                resolve(audio.duration)
            });
        })
    }


    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const onChange = (e) => {
        setCheckedList(e);
        setIndeterminate(!!e.length && e.length < list.length);
        setCheckAll(e.length === list.length);
        let s = []
        list.map((item, index) => {
            if (e.indexOf(item?.fileId) !== -1) {
                s.push(item.isUP)
            }
        })
        selectListSet(s)
    };


    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? id_list : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const [goods_var, goods_varSet] = useState([])

    const goods_varChange = (e, i, id) => {
        let t = JSON.parse(JSON.stringify(goods_var))
        t[i][id] = e
        goods_varSet(t)
    }

    const save = e => {
        let data = {
            productId: e,
            variationList: goods_var
        }
        Api.variation_listSubmit(data).then(res => {
            if (res.code === 200) {
                if (pageIndex === 1) {
                    getMyWorks()
                } else {
                    setPageIndex(1)
                }
                open_statusSet(-1)
                message.success('保存成功！')
            }
        })
    }

    const cancel = e => {
        focusSet(-1)
    }

    const openFormModal = (productId = false) => {
        setFormData({fileAddress: '', cargoClassificationId: '', mediaEnum: '', targetEnum: ''})
        setShowForm(true)
    }

    const openEditWork = data => {
        data.cargoClassificationId = data.cargoClassification.id
        setFormData(data)
        setShowForm(true)
    }

    const delMyWork = (e, status) => {
        let data = {
            ids:e || checkedList
        }
        Api.deleteNiceWork(data).then(res => {
            if (res.code === 200) {
                if (pageIndex === 1) {
                    getMyWorks()
                } else {
                    setPageIndex(1)
                }
            }
        })
    }

    const toDetail = (item, e) => {
        e.stopPropagation()
        // window.open(`/index/GoodsDetail/${item.fileId}`)
    }

    const submitForm = () => {
        if(!formData.fileId){
            message.warning(intl.get('MyExcellentWorks')['未上传视频'])
            return
        }
        if(formData.fileId && formData.oldId) Api.deleteNiceWork({ids:[formData.oldId]}).then()
        Api[formData.fileId ? 'editNiceWork' : 'commitNiceWork']({uid,...formData}).then(res=>{
            if(res.code === 200){
                setShowForm(false)
                getMyWorks()
                message.success(intl.get('MyExcellentWorks')[formData.updateTime ? '修改成功' : '添加成功'])
            }
        })
    }

    return (
        <div id='MyExcellentWorks'>
            <div className='tabs'>
                <div className='flex-ac'>
                    <div style={{marginRight: '30px'}}>{intl.get('MyExcellentWorks')['已选']}({checkedList.length})</div>
                    <div className={checkedList.length > 0 ? 'btn_active' : 'btn'}
                         style={{marginRight: '16px', display: active_index === 4 ? 'none' : ''}}
                         onClick={e => checkedList.length > 0 ? delMyWork('', 4) : ''}>{intl.get('MyExcellentWorks')['删除']}
                    </div>
                </div>
                <div onClick={() => openFormModal()} className='btn_top' style={{
                    width: '110px',
                    height: '38px',
                    lineHeight: '38px',
                    position: 'absolute',
                    right: '30px',
                    top: '12px',
                    borderRadius: '5px'
                }}>{intl.get('MyExcellentWorks')['上传作品']}
                </div>
            </div>
            <div className='goods_main'>
                <div>
                    <Checkbox style={{width: '3%'}} indeterminate={indeterminate} onChange={onCheckAllChange}
                              checked={checkAll}></Checkbox>
                    <div style={{width: '22%'}}>{intl.get('MyExcellentWorks')['作品']}</div>
                    <div style={{width: '23%', paddingRight: '15px'}}>{intl.get('MyExcellentWorks')['所属分类']}</div>
                    <div style={{width: '14%', paddingRight: '15px'}}>{intl.get('MyExcellentWorks')['视频用途']}</div>
                    <div style={{width: '14%', paddingRight: '15px'}}>{intl.get('MyExcellentWorks')['传播媒体']}</div>
                    <div style={{width: '12%', paddingRight: '15px'}}>{intl.get('MyExcellentWorks')['更新时间']}</div>
                    <div style={{width: '10%'}}>{intl.get('MyExcellentWorks')['操作']}</div>
                </div>
            </div>
            <Spin tip="数据加载中..." spinning={loading}>
                <Checkbox.Group value={checkedList} style={{width: '100%'}} onChange={onChange}>
                    <div className='list'>
                        {
                            list.length > 0 ?
                                list.map((item, index) => {
                                    return (
                                        <div key={index} style={{marginBottom: '10px', background: '#fff'}}>
                                            <div className='line'>
                                                <Checkbox style={{width: '3%'}}
                                                          value={item.fileId}></Checkbox>
                                                <div style={{
                                                    width: '22%',
                                                    display: 'flex',
                                                    paddingRight: '15px',
                                                    cursor: item.productStatus === 1 ? 'pointer' : ''
                                                }} onClick={e => openVideoModal(item.fileAddress)}>
                                                    <div style={{width: '80px',height:'110px',position:'relative',borderRadius:'4px',overflow:'hidden',cursor:'pointer'}} >
                                                        {/*<video style={{width: '80px',height:'110px',objectFit:'cover'}} src={item.fileAddress}></video>*/}
                                                        <Image src={item.fileAddress + '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,m_fast'}
                                                               style={{width: '80px',height:'110px',objectFit:'cover'}} preview={false}/>
                                                        <div style={{width: '100%',height:'100%',background:'rgba(0,0,0,.22)'}} className='pos-center'>
                                                            {/*<video style={{position:'absolute',left:'100%',width: '100%',height:'100%',objectFit:'cover',visibility:'hidden'}} src={item.fileAddress} onLoadedMetadata={(event)=>{getVideoTime(event,index)}}></video>*/}
                                                            <div className='pos-bot' style={{width: '100%', color: '#FFF', textAlign: 'center', fontSize: '13px',bottom:'9px'}}>{item?.duration ? Number(item.duration).toFixed(2) : 0}s</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{width: '23%', paddingRight: '15px'}}>
                                                    {(lang=='zh' ? item?.cargoClassification?.goodsName :item?.cargoClassification?.goodsEnglishName) || '-'}
                                                </div>
                                                <div style={{width: '14%', paddingRight: '15px'}}>{item?.targetEnum ? purposeList[item.targetEnum - 1][lang == 'zh' ? 'val' : 'enVal'] || '' : ''}</div>
                                                <div style={{width: '14%', paddingRight: '15px'}}>
                                                    {/*<img src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/mygoods/shanchu.png' style={{width: 14, height: 14, marginRight: 5,}}/>*/}
                                                    {item?.mediaEnum ? mediaList[item.mediaEnum - 1].val || '-' : ''}</div>
                                                <div style={{width: '12%', paddingRight: '15px'}}>{item?.updateTime.replaceAll('T',' ') || '-'}</div>
                                                <div style={{width: '10%', color: '#F7385B', display: 'flex'}}>
                                                    <div className='flex-ac' style={{
                                                        display: active_index === 4 ? 'none' : '',
                                                        cursor: 'pointer',
                                                        marginRight: '20px'
                                                    }} onClick={e => openEditWork(item)}>
                                                        <img
                                                            src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/mygoods/bianji.png'
                                                            style={{
                                                                width: 14,
                                                                height: 14,
                                                                marginRight: 5,
                                                                cursor: 'pointer'
                                                            }}/>
                                                        <div style={{flexShrink: '0'}}>{intl.get('MyExcellentWorks')['编辑']}</div>
                                                    </div>
                                                    <div className='flex-ac' style={{
                                                        display: active_index === 4 ? 'none' : '',
                                                        cursor: 'pointer'
                                                    }}
                                                         onClick={e => delMyWork([item.fileId], 4)}>
                                                        <img
                                                            src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/mygoods/shanchu.png'
                                                            style={{
                                                                width: 14,
                                                                height: 14,
                                                                marginRight: 5,
                                                                cursor: 'pointer'
                                                            }}/>
                                                        <div style={{flexShrink: '0'}}>{intl.get('MyExcellentWorks')['删除']}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{paddingLeft: '42px'}}>
                                                <div style={{
                                                    width: '100%',
                                                    borderTop: '1px #eee solid',
                                                    margin: '15px 0'
                                                }}></div>
                                            </div>
                                            <div style={{
                                                maxHeight: open_status === index ? '1000px' : '0',
                                                transition: 'all 0.5s',
                                                overflow: 'hidden',
                                                position: 'relative'
                                            }}>
                                                <div style={{
                                                    display: focus === index ? 'flex' : 'none',
                                                    position: 'absolute',
                                                    right: 0,
                                                    top: '10px',
                                                    zIndex: '1'
                                                }}>
                                                    <div className='btn' style={{cursor: 'pointer'}}
                                                         onClick={e => cancel()}>取消
                                                    </div>
                                                    <div className='btn_active' style={{marginLeft: '10px'}}
                                                         onClick={e => save(item.fileId)}>保存
                                                    </div>
                                                </div>
                                                <Spin spinning={isSpinning}>
                                                    {
                                                        goods_var.map((item2, index2) => (
                                                            <div key={index2}
                                                                 style={{display: 'flex', marginTop: '7px'}}>
                                                                <div style={{
                                                                    width: '44%',
                                                                    textAlign: 'right',
                                                                    paddingRight: '15px',
                                                                    lineHeight: '32px'
                                                                }}>
                                                                    {item2.var1 + (item2.var2 ? '-' + item2.var2 : '') + (item2.var3 ? '-' + item2.var3 : '')}
                                                                </div>
                                                                <div style={{width: '10%', paddingRight: '15px'}}>
                                                                    <InputNumber controls={false} min={1}
                                                                                 onFocus={e => focusSet(index)}
                                                                                 value={item2.quantity}
                                                                                 placeholder={intl.get('MyGoods')['请输入']}
                                                                                 onChange={e => goods_varChange(e, index2, 'quantity')}/>
                                                                </div>
                                                                <div style={{width: '10%', paddingRight: '15px'}}>
                                                                    <InputNumber controls={false} min={0}
                                                                                 onFocus={e => focusSet(index)}
                                                                                 value={item2.price}
                                                                                 placeholder={intl.get('MyGoods')['请输入']}
                                                                                 onChange={e => goods_varChange(e, index2, 'price')}/>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </Spin>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className='flex-acjc'
                                     style={{width: '100%', flexDirection: 'column', paddingTop: '100px'}}>
                                    <Image width={105} height={100} preview={false}
                                           src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png' alt=''/>
                                    <div style={{marginTop: '23px'}}>{intl.get('MyExcellentWorks')['暂无作品信息']}</div>
                                </div>
                        }
                    </div>
                </Checkbox.Group>
            </Spin>
            {/*<Pagination style={{float: 'right', margin: '20px 0'}} current={pageIndex} total={total} onChange={e => {*/}
            {/*    setPageIndex(e)*/}
            {/*}}/>*/}
            {/*上传作品*/}
            <Modal  destroyOnClose={true} centered visible={showForm} footer={null} closable={null} onCancel={closeShowForm}
                   getContainer={() => document.getElementById('MyExcellentWorks')}
                   bodyStyle={{
                       padding: '56px 75px 98px',
                       backgroundColor: '#FFFFFF',
                       width: 602,
                       minHeight: 563,
                       overflow: 'hidden'
                   }}>
                <AddMyWorks subData={formData} callbackParent={changeFormData} callBackSetShowForm={closeShowForm} callBackSubForm={submitForm}/>
            </Modal>

            <Modal centered visible={showVideo} footer={null} closable={null} onCancel={()=>{setShowVideo(false)}}
                   destroyOnClose={true}
                   getContainer={() => document.getElementById('MyExcellentWorks')}
                   bodyStyle={{
                       padding: '0', backgroundColor: '#FFFFFF', width: 826, height: 475, overflow: 'hidden'
                   }}>
                <video width='100%' height='100%' controls autoPlay={true} loop>
                    <source src={playVideoUrl} type="video/mp4" />
                    <source src={playVideoUrl} type="video/ogg" />
                    <source src={playVideoUrl} type="video/webm" />
                    您的浏览器不支持 video 标签。
                </video>
            </Modal>
        </div>
    )
}
export default withRouter(MyExcellentWorks);