import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import intl from 'react-intl-universal';
import * as Api from '../../../service/https'
import {Modal, Input, List, notification, Button, Image, Divider, Spin, message, Popover} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import store from '../../../utils/redux/index'

const Withdrawal = () => {
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [textJson, setTextJson] = useState(intl.get('Wallet'))
    const [amount, setAmount] = useState('')
    useEffect(() => {
        getWithdrawalAmount()
        getWithdrawalList()
        // getWallet()
        // loadMoreData()
        // console.log(state.MCN)
    }, [])

    const [withdrawalAmount, setWithdrawalAmount] = useState({})
    const getWithdrawalAmount = ()=>{
        Api.getWithdrawalAmount().then(res=>{
            if(res.code == 200){
                setWithdrawalAmount(res.data)
            }
        })
    }
    const status = {
        1:'审核', 2:'到账', 3:'未通过'
    }
    const [withdrawalList, setWithdrawalList] = useState([])
    const getWithdrawalList = ()=>{
        Api.getWithdrawalList().then(res=>{
            if(res.code == 200){
                setWithdrawalList(res.data)
            }
        })
    }



    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [isSuccess, setScucess] = useState(false)
    const [isApply, setApply] = useState(false)

    const [rate, setRate] = useState()
    const [DOLLAR, setDOLLAR] = useState()
    const [RMB, setRMB] = useState()

    useEffect(() => {
        // susdcny()
        // loadMoreData()
    }, [DOLLAR])

    const susdcny = e => {
        Api.susdcny({
            money: DOLLAR * rate,
            type: 1
        }).then(res => {
            if (res.code === 200) {
                setRate(res.data.RATE)
                // setDOLLAR(res.data.DOLLAR)
                setRMB(res.data.RMB)
            }
        })
    }

    const changeDollar =  (value) =>{
        setDOLLAR(value > withdrawalAmount.withdrawableAmount ? withdrawalAmount.withdrawableAmount :value)
    }

    const postWithdrawal = () => {
        if(DOLLAR > withdrawalAmount.withdrawableAmount){
            message.warning('可提现余额不足')
            return
        }
        if(DOLLAR >= 100) {
            let obj = {
                amount: DOLLAR
            }
            Api.postWithdrawal(obj).then(res => {
                // console.log(res)
                if (res.code === 200) {
                    setScucess(true)
                    getWithdrawalList()
                    setApply(false)
                }
            })
        }else{
            message.warning('提现金额不足$100')
        }
    }

    useEffect(()=>{
        if(!isApply || !isSuccess){
            setName('')
            setEmail('')
            setRMB('')
            setDOLLAR('')
        }
    },[isApply, isSuccess])


    return <div id='wallet' style={{ paddingBottom: '43px' }}>
        <div style={{ display: 'flex', alignItems:'center' }}>
            <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/before.png" alt=""
            style={{width:'4px'}}/>
            <div style={{fontSize:'20px', fontWeight:600, marginLeft:'9px'}}>提现</div>
        </div>
        <div style={{
            width: '100%', height: '180px', backgroundColor: '#FFF', textAlign: 'center', marginTop:'10px',
            display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', padding: '0 30px'
        }}>
            <div>
                <span style={{ color: '#666666' }}>可提现金额</span>
                <div style={{ fontWeight: 500, fontSize: '22px' }}>${withdrawalAmount.withdrawableAmount}</div>
                <div style={{
                    width: '160px', height: '38px', borderRadius: '4px', cursor: 'pointer',
                    textAlign: 'center', lineHeight: '38px', color: '#FFF', margin: '16px auto',
                    background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                }}
                    onClick={() => setApply(true)}>
                    {textJson['去提现']}
                </div>
            </div>
            <div>
                <span style={{ color: '#666666' }}>{textJson['已提现金额']}</span>
                <div style={{ fontWeight: 500, fontSize: '22px' }}>${withdrawalAmount.withdrawalAmount}</div>
                <div style={{
                    width: '160px', height: '38px', borderRadius: '4px',
                    textAlign: 'center', lineHeight: '34px', color: '#666', margin: '16px auto',
                    // border: '1px solid #EEEEEE', fontWeight: 'blod', cursor: 'pointer'
                }}
                    // onClick={() => getTixian()}
                    >
                    {/* {textJson['查看提现记录']} */}
                </div>
            </div>
            <div style={{ width: '1px', height: '130px', background: '#EEEEEE' }}></div>
            <div style={{width:'600px'}}>
                <div style={{background:'#FFF',padding:'0 30px', fontWeight:500, textAlign:'left'}}>
                    提现规则<br/>
                    1、每个商家店铺每个月有1次提现机会，请需要提现的商铺于当月15号之前提交提现申请，平台会在10个工作日内审核后操作打款。<br/>
                    2、每次提现金额不能低于$100。<br/>
                    3、平台抽取每笔提现金额1%作为平台佣金。
                </div>
            </div>
        </div>
        <div id='MyGoods' style={{marginTop:'10px'}}>
            <div style={{background:'#FFF', height:'60px', padding:'15px 30px', fontSize:'16px', fontWeight:500}}>
                提现记录
            </div>

            <div style={{ width: '100%', background: '#FFF',}}>
                <div className='goods_main' style={{marginBottom:'20px'}}>
                    <div style={{padding:0}}>
                    </div>
                    <div style={{fontWeight:500, fontSize:'13px', color:'#666'}}>
                        <div style={{ width: '200px' }}>提现日期</div>
                        <div style={{ width: '170px', marginLeft: '15px' }}>提现金额</div>
                        <div style={{ width: '120px', marginLeft: '25px'}}>平台服务费</div>
                        <div style={{ width: '170px', marginLeft: '15px' }}>到账金额</div>
                        <div style={{ width: '90px' }}>状态</div>
                    </div>
                </div>
                {
                    withdrawalList.length > 0 ? withdrawalList.map((item,index)=>{
                        return <div key={index} style={{fontWeight:500,background:'#FFF', borderRadius:'5px',
                        width:'100%', maxHeight:'80px', padding:'20px 24px', display:'flex', alignItems:'center'}}>
                            <div style={{ width: '200px'}}>{item.applicationTime}</div>
                            <div style={{ width: '170px', marginLeft: '15px'}}>${item.amount}</div>
                            <div style={{ width: '120px', marginLeft: '25px'}}>${item.chargeAmount}</div>
                            <div style={{ width: '170px', marginLeft: '15px'}}>${item.receivedAmount}</div>
                            <div style={{ width: '90px' }}><span style={{background:'#DBFFEB', fontSize:'12px', borderRadius:'6px',
                            color:'#40D782', padding:'1px 6px'}}>{status[item.status]}</span></div>
                        </div>
                    }) : <div style={{width:'345px', height:'200px', textAlign:'center', margin:'0 auto', marginTop:'100px' }}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                        <div style={{marginTop:'24px', textAlign: 'center', color:'#333' }}>{'暂无数据'}</div>
                    </div>
                }
                {/* <div style={{fontWeight:500,background:'#FFF', borderRadius:'5px',
                width:'100%', maxHeight:'80px', padding:'20px 24px', display:'flex', alignItems:'center'}}>
                    <div style={{ width: '200px'}}>2022-06-27 18:06</div>
                    <div style={{ width: '170px', marginLeft: '15px'}}>$31.9</div>
                    <div style={{ width: '120px', marginLeft: '25px'}}>$3.9</div>
                    <div style={{ width: '170px', marginLeft: '15px'}}>$13.9</div>
                    <div style={{ width: '90px' }}><span style={{background:'#DBFFEB', fontSize:'12px', borderRadius:'6px',
                    color:'#40D782', padding:'1px 6px'}}>未完成</span></div>
                </div> */}
            </div>
        </div>




        <Modal centered visible={isSuccess} footer={null} closable={false}
            getContainer={() => document.getElementById('wallet')}
            bodyStyle={{
                padding: '0', backgroundColor: '#FFFFFF',
                width: 443, height: 466,
            }}
        >
            <div style={{ textAlign: 'center', paddingTop: '55px' }}>
                <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{textJson['提现申请提交成功']}</h3>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suc.png'} alt=""
                    style={{ width: '173px', marginTop: '40px' }} />
                <div style={{width:'70%', margin:'0 auto'}}>{intl.get('ReleaseDemand')['我们已收到你的提现申请，提现金额将在1～3个工作日到账，请耐心等待']}</div>
                <div style={{
                    width: '210px', height: '40px', borderRadius: '4px', cursor: 'pointer',
                    textAlign: 'center', lineHeight: '40px', color: '#FFF', margin: '38px auto',
                    background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                }}
                    onClick={() => setScucess(false)} >
                    关闭
                </div>
            </div>
        </Modal>


        <Modal centered visible={isApply} footer={null} closable={false}
            getContainer={() => document.getElementById('wallet')}
            bodyStyle={{
                padding: '0', backgroundColor: '#FFFFFF',
                // width: 554,
            }}
        >
            <div style={{ textAlign: 'center', padding: '57px 55px' }}>
                <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{textJson['提现申请']}</h3>
                <div style={{
                    color: '#666666', display: 'flex', alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginTop: '66px', fontWeight: 500
                }}>
                    {textJson['提现金额']}<span style={{ fontSize: '12px', color: '#888' }}>（{textJson['当前可提现金额']}
                        <span style={{ color: '#F7385B', fontSize: '14px', fontWeight: 'bold' }}>${withdrawalAmount.withdrawableAmount || 0}</span>
                        {/* {
                            rate ?
                                <span>，{textJson['当前汇率']}<span style={{ color: '#F7385B', fontSize: '14px', fontWeight: 'bold' }}>{rate}</span></span>
                                :
                                ''
                        } */}
                        ）</span>
                </div>
                <div style={{ marginTop: '12px' }}>
                    <Input prefix="$" style={{ width:'410px', height: '48px', borderRadius: '4px' }} placeholder={textJson['请输入你想要提现的金额']}
                           onChange={e => changeDollar(e.target.value.replace(/[^\d.]/, ''))} value={DOLLAR} />
                    {/* {RMB ? <div style={{ color: '#CCCCCC', textAlign: 'left', fontSize: '12px' }}>{textJson['实际提现金额']}：￥{RMB}</div> : ''} */}
                </div>
                {/* <div style={{
                    color: '#666666', display: 'flex', alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginTop: '30px', fontWeight: 500
                }}>
                    {textJson['收款人真实姓名']}<span style={{ fontSize: '12px', color: '#888' }}>（{textJson['请输入注册Payoneer时的真实姓名']}）</span>
                </div>
                <div style={{ marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Input style={{ height: '48px', borderRadius: '4px' }} placeholder={textJson['请输入真实姓名']}
                        onChange={e => setName(e.target.value)} value={name} />
                </div>
                <div style={{
                    color: '#666666', display: 'flex', alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginTop: '30px', fontWeight: 500
                }}>
                    {textJson['收款的账号']}<span style={{ fontSize: '12px', color: '#888' }}>（{textJson['请输入注册Payoneer时的邮箱号']}）</span>
                </div>
                <div style={{ marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Input style={{ height: '48px', borderRadius: '4px' }} placeholder={textJson['请输入邮箱号']}
                        onChange={e => setEmail(e.target.value)} value={email} />
                </div> */}
                <Button disabled={DOLLAR == ''} style={{
                    width: '210px', height: '40px', borderRadius: '4px', border: 0,
                    textAlign: 'center', color: '#FFF', margin: '0 auto', marginBottom: '12px',
                    background: DOLLAR == ''? '#EEEEEE' : 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod', marginTop: '48px'
                }}
                    onClick={() => postWithdrawal()} >
                    {textJson['确定提现']}
                </Button>
                {/* <div style={{ fontSize: '12px', color: '#888888', marginTop: '10px' }}>
                    {textJson['本平台转账统一使用Payoneer，提现请先开通Payoneer']}
                </div> */}
                <CloseCircleOutlined style={{
                    position: 'absolute', right: '29px', top: '29px', fontSize: '20px', color: '#888888',
                    cursor: 'pointer'
                }}
                    onClick={() => setApply(false)} />
            </div>
        </Modal>

    </div>
}
export default withRouter(Withdrawal);
