import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'

const en = ()=>{
    return <div style={{padding:'20px'}}>
        <h2 style={{textAlign: 'center'}}>BrandGoGo privacy policy</h2>

<h3>Prompt clause</h3>

Your trust is very important to us. We are fully aware of the importance of personal information to you. We will take corresponding security measures according to the requirements of laws and regulations, and try our best to protect your personal information. We are committed to maintaining your trust in US and strictly abide by the following principles to protect your personal information: the principle of consistent rights and responsibilities, the principle of clear purpose, the principle of choice and consent, the principle of minimum sufficiency, the principle of ensuring security, the principle of subject participation, the principle of openness and transparency, etc. At the same time, we promise that we will take corresponding security measures to protect your personal information according to the mature security standards of the industry<br/>

In view of this, the [BrandGoGo] service provider (hereinafter referred to as "we") formulates this Privacy Policy (hereinafter referred to as "this policy / this Privacy Policy") and reminds you:

This policy applies to all products and services provided by BrandGoGo. If the products or services provided by BrandGoGo are used in the products or services of us and our affiliated companies (see the definition for details), but there is no independent privacy policy, this policy also applies to those products or services. If we and our affiliated companies have separate privacy policies for the products or services provided to you, the corresponding privacy policies shall apply to the corresponding products or services<br/>

Before using various products or services of BrandGoGo, please read and thoroughly understand this policy, especially the terms marked with bold / bold underline. You should focus on reading them and use them after confirming your full understanding and agreement. If you have any questions, comments or suggestions on the content of this policy, you can contact us through various contact information provided by BrandGoGo.<br/>

<h3>The first part is the definition of</h3> <br/>

BrandGoGo: BrandGoGo Platform<br/>

<h4> service provider: </h4 > refers to Suzhou ZhenChuang Technology Co., Ltd., BrandGoGo's Internet information and software technology service provider<br/>

Affiliated company: refers to the company directly or indirectly controlled or invested by Zhenquan Technology Co., Ltd<br/>

Personal information: refers to all kinds of information recorded by electronic or other means that can identify the identity of a specific natural person or reflect the activities of a specific natural person individually or in combination with other information<br/>

Personal sensitive information: refers to personal information including ID card number, personal biometric information, bank account number, property information, whereabouts track, transaction information, children under 14 years old (including) information, etc. (we will mark specific personal sensitive information in bold in this Privacy Policy)<br/>

Personal information deletion: refers to the behavior of removing personal information in the system involved in the realization of daily business functions, so that it can not be retrieved and accessed<br/>

Unless otherwise agreed, the definitions used in this policy have the same meaning as those in the BrandGoGo user agreement<br/>

<h3>Part two</h3>  
Privacy policy<br/>

<h4>This privacy policy section will help you understand the following：</h4><br/>

1、 How do we collect and use your information<br/>

2、 How do we use cookies and similar technologies<br/>

3、 How can we share, transfer and publicly disclose your information<br/>

4、 How do we protect your information<br/>

5、 How do you manage your information<br/>

6、 How do we deal with minors' information

<br/>

7、 How does your information move around the world

<br/>

8、 How to update this privacy policy

<br/>

9、 How to contact us

<br/>

<h4>1、 How do we collect and use your personal information

</h4>

Our products and / or services include some core functions. We may collect, save and use the following information about you to achieve these functions. If we are unable to collect and use your relevant information, you will not be able to enjoy our products and / or services.

<br/>

<strong>How do we collect your personal information

：</strong><br/>

1、The information you actively provide when using our services <br/>

(1) The information you fill in or upload when you register your account: such as the mobile phone number you use when you register your account, or the authorized wechat and apple account number, as well as your nickname, avatar and other information<br/>

(2) Information you actively record and upload when using the service: for example, basic information and health file information (including gender, height, birthday, diet, exercise, weight, circumference, menstruation, etc.) of your personal and family members that you actively record in BrandGoGo, and information provided during health assessment<br/>

(3) Other information you provide when using our products or services<br/>

2. The information that we actively collect when you use the service <br/>

(1) Device information: we will receive and record the equipment related information (such as device name, device model, operating system and application version, language setting, unique device identifier, operator network type and other software and hardware characteristics information) according to the specific permissions you grant in software installation and use Information about the location of the device (such as IP address, GPS location, WLAN access point, Bluetooth and base station that can provide relevant information)<br/>

(2) Log information: when you use the products or services provided by our client or website, we will automatically collect your detailed usage of our services and save them as relevant network logs. For example, your search content, browser type, browsing record, order information, IP address, access date and time, and the web record you visit<br/>

(3) Location information: when you use location related services, we may record the location information of your device in order to provide you with relevant services. When you use the service, we may get your geographic location information through IP address, GPS, WiFi or base station. At any time, you can cancel the permission through the relevant function settings. When you cancel this authorization, we will no longer collect the information and provide you with the above corresponding services; However, unless you delete your information according to the law, your cancellation will not affect the processing and storage of information based on your prior authorization<br/>

(4) IP address: information about mobile applications (APPs) and other software you have used, and information about those mobile applications and software you used<br/>

(5) Order information: when you are ready to settle the goods in your shopping cart / shopping bag, BrandGoGo system will generate your order for purchasing the goods. We will collect your order information for your identity verification, transaction confirmation, payment and settlement, delivery completion, order inquiry, customer service consultation and after-sales service; We will also use your order information to judge whether your transaction is abnormal, so as to protect your transaction security<br/>

(6) payment information: when you place the order in the store, you can choose the third party payment institutions that cooperate with BrandGoGo (including WeChat payment and Alipay payment channels, hereinafter referred to as the "payment institution") to provide the payment service. When payment is made, we will collect your third party payment account. <br/>

(7) Your eating habits: when you use the food calorie query, upload custom dishes and other food related functions, or collect your eating habits according to your product information browsing records and residence time in the store<br/>

(8) Pictures and videos in the device: with your authorization, our client will encrypt and upload the videos and pictures you upload to the server for storage; The software name and version number in your device may be extracted and uploaded to the server for processing and storage through encryption. At the same time, you can also refuse / cancel the permission at any time through the relevant function settings. Refusing to provide this permission will only make you unable to use the above functions, but will not affect the normal use of other functions and services of BrandGoGo; However, after you cancel the authorization, we will no longer collect the information, nor can we provide you with the above corresponding services; However, unless you delete your information in accordance with the law, your cancellation will not affect the storage or processing of our information based on your previous authorization<br/>

(9) Mobile camera information: with your authorization, our client may obtain your mobile camera information when you upload pictures, videos and other content using the photo taking function<br/>

(10) Your sports training habits: when you use sports training and other related functions, or collect your sports training habits according to your search and stay time in sports courses<br/>

3. With your authorization, we can obtain your personal information through a third party <br/>

(1) In order to facilitate users to record / synchronize health data and steps, we also provide the function of synchronizing health data of third-party software, such as apple health, Huawei sports health, and other third-party software developed in the future<br/>

(2) For the purpose of security and fraud prevention, verify your information (such as telephone number) from legal sources under the premise of your authorization for specific account number, financial transaction and other services<br/>

(3) Provide advertising service for you through specified unique identifier (such as IMEI number or IDFA code obtained from Advertiser)<br/>

(4) We may get some information from third-party social network services, such as avatars and nicknames (for example, when you log in to BrandGoGo client with a social network account)<br/>

(5) With your authorization, we will obtain your genetic test report from a third party testing agency<br/>

(6) Other information of you obtained through a third party with your authorization and consent<br/>

4. The information we get from the smart device you bind <br/> 

If you have related hardware devices (including smart watches, body fat scales and other smart devices) and want to synchronize the information recorded by hardware products (including but not limited to personal information and personal body data that have been agreed to be uploaded, such as steps, exercise data, weight, body fat rate, etc.) to BrandGoGo, you can do so by turning on the Bluetooth function. If you refuse to open it, you will not be able to use this function, but it will not affect your normal use of other functions of BrandGoGo. At the same time, you can cancel the permission at any time through the relevant function settings. However, after you cancel the authorization, we will no longer collect the information, nor can we provide you with the above corresponding services; However, unless you delete your information in accordance with the law, your cancellation will not affect our processing and storage of information based on your previous authorization<br/>

5. Information obtained by the third party through software and other means <br/> 

In order to provide better services for you, when you use some of the functions of BrandGoGo, the third party who has a cooperative relationship with BrandGoGo may obtain some of your information (including: your device platform, device manufacturer, device brand, device ID (such as IMEI, MAC, IDFA, etc.), audio recording permission, application list information, etc.) through SDK interface, etc Network information (such as IP address, WiFi information, etc.) and location related information, etc.)<br/>

B. How do we use your personal information: <br/>

We strictly abide by the provisions of laws and regulations and the agreement with users, and use the collected information for the following purposes. If we use your information beyond the following purposes, we will explain it to you again with your consent<br/>

1. Provide services to you, such as health assessment, food information identification and analysis, diet analysis, health program, health records management of you and your family, product sales, advertising, etc<br/>

2. To meet your personalized needs, in order to recommend higher quality service content that you may be more interested in and give you a better experience, we will also carry out personalized recommendation / directional push according to your relevant personal information and user profile. For example, "BrandGoGoapp" may have a special module "recommend for you" and other functions, To recommend products you may be interested in<br/>

3. To provide you with consistent services, we may associate the information collected from your various devices. We may combine information from one service with information from other services to provide you with services, personalized content and advice. The above practices may have an impact on your product browsing page display, product search page sorting, and product recommendation page display<br/>

4. For example, we will use your information for authentication, security, anti fraud monitoring, archive backup, customer security services and other purposes<br/>

5. Evaluate and improve the effectiveness of our advertising and other promotional activities<br/>

6. Help us design new services and improve our existing services. For example, we will analyze data, research and develop statistics related to the use of our products or services<br/>

7. Store and maintain information related to you for our business operations (e.g. business statistics) or legal obligations<br/>

8. Other purposes with your consent<br/>

9. Other situations that do not violate any mandatory laws and regulations<br/>

<strong>Our commitment</strong><br/>

1. Please understand that the functions and services we provide to you are constantly updated and developed. If a certain function or service is not included in the above description and your information is collected, we will separately explain the content, scope and purpose of information collection to you through page prompt, interactive process, website announcement, etc., so as to obtain your consent<br/>

2. Please note that before obtaining your authorization, we will not take the initiative to obtain your personal information from a third party outside BrandGoGo. If you need to obtain your personal information indirectly from a third party for business development in the future, we will make clear to you the source, type and scope of use of your personal information before obtaining it. For example, the personal information processing activities that BrandGoGo needs to carry out business exceed the scope of your original authorization and consent when providing personal information to a third party. Before processing your personal information, we will, With your express consent; In addition, we will strictly abide by the relevant laws and regulations, and require the third party to guarantee the legality of the information provided<br/>

3. After collecting your personal information, we will anonymize the data in time through technical means. Without disclosing your personal information, we have the right to mine, analyze and utilize the anonymous user database (including commercial use), and have the right to make statistics on the use of products / services and share desensitized statistical information with the public / third parties<br/><h4>Two、How do we use cookies and similar technologies</h4>

<strong>（One）Cookie</strong>

To ensure that the site is working properly, we store small data files called cookies on your computer or mobile device. Cookies usually contain identifiers, site names, and some numbers and characters. With cookies, the website can store data such as your preferences or items in your shopping basket<br/>

We will not use cookies for any purpose other than those described in this policy. You can manage or delete cookies based on your preferences. You can clear all cookies saved on BrandGoGo, and most web browsers have the ability to block cookies. But if you do, you need to change the user settings yourself every time you visit our website. For more information on how to change browser settings, visit the following links: Internet Explorer, Google Chrome, Mozilla Firefox, Safari, and opera.<br/>

<strong>（Two）Site beacon and pixel label</strong>

In addition to cookies, we will also use site beacons, pixel tags and other similar technologies on the website. For example, the email we send you may contain a click URL that links to the content of our website. If you click this link, we will track this click to help us understand your product or service preferences and improve customer service. A website beacon is usually a transparent image embedded in a website or email. With the help of pixel labels in e-mail, we can know whether the e-mail has been opened or not. If you don't want your activities to be tracked in this way, you can unsubscribe from our mailing list at any time<br/>
<strong>（Three）Do Not Track</strong>


Many web browsers are equipped with do not track function, which can publish do not track requests to websites. At present, major Internet standards organizations have not yet set up relevant policies to specify how websites should respond to such requests. But if do not track is enabled in your browser, all our websites will respect your choice<br/>
<h4>Three、How do we share, transfer and publicly disclose your personal information</h4>

<strong>（One）share</strong><br/>

We will not share your personal information with any other companies, organizations or individuals, except for the following circumstances: <br/> we will not share your personal information with any other companies, organizations or individuals

1. Sharing with explicit consent: with your explicit consent, we will share your personal information with other parties<br/>

2. Sharing under legal circumstances: we may share your personal information according to laws and regulations, litigation, dispute resolution needs, or according to the requirements of administrative and judicial organs<br/>

3. Only by sharing your information can we realize the core functions of our products and / or services or provide the services you need<br/>

4. Share with our affiliated companies: your personal information may be shared with our affiliated companies. We will only share necessary personal information and are bound by the purposes stated in this privacy agreement. If the affiliated company wants to change the purpose of personal information processing, it will seek your authorization again<br/>

5. Sharing with authorized partners: for the purposes stated in this privacy agreement only, some of our services will be provided by authorized partners. We may share some of your personal information with our partners to provide better customer service and user experience. For example, the company we hire to provide third party logistics may need to collect and use your receiving address, consignee and contact information. In this case, these companies must comply with our data privacy and security requirements. We will only share your personal information for legitimate, legitimate, necessary, specific and definite purposes, and only share the personal information necessary for providing services<br/>

<strong> at present, our partners include the following types: </strong> <br/>

（1) Supplier of goods or technical services. We may share your personal information with third parties that support our capabilities. These support includes providing or providing infrastructure technical services, logistics and distribution services, payment services, data processing, etc. The purpose of sharing this information is to realize the core shopping function of our products and / or services, for example, we must share your order information with logistics service providers to arrange delivery; Or we need to share your order number and order amount with a third-party payment agency to realize its confirmation of your payment order and complete payment<br/>

(2) Third party merchants. We must share the necessary information about your order and transactions with third-party merchants to realize your demand for goods or services from them and enable them to complete subsequent after-sales services<br/>

(3) The partners entrusted to us for promotion. Sometimes we offer promotional services on behalf of other businesses to the user base that uses our products and / or services. We may share your personal information and indirect user portraits formed by your non personal information collection with the partners (the "client") who entrust us to promote, but we will only provide the clients with the coverage and effectiveness of the promotion, not your personal identity information, or we will summarize the information, So that it doesn't recognize you personally. For example, we can tell how many people of the client have read their promotion information or purchased the goods of the client after seeing the information, or provide them with statistical information that can not identify their personal identity, so as to help them understand their audience or customers<br/>

(4) User portrait service provider: Zhejiang daily interactive network technology Co., Ltd. provides user portrait service. We may use equipment information such as equipment platform, equipment manufacturer, equipment brand, equipment identification code (such as IMEI, MAC, IDFA, etc.) to list information and network information (such as IP address WiFi information, etc.) and location related information are provided to Zhejiang daily interactive network technology Co., Ltd. to provide you with a portrait service for users. Please refer to the privacy policy of individual users <br/>

(5) Application Statistics Service Provider: Zhejiang daily interactive network technology Co., Ltd. provides application statistics service. We may apply relevant information and network information (such as IP address, IP address, etc.) to equipment platform, equipment manufacturer, equipment brand, equipment identification code (such as IMEI, MAC, IDFA, etc.) WiFi information) is provided to Zhejiang daily interactive network technology Co., Ltd. to provide you with several application statistics services. Please visit the privacy policy of individual users <br/>

(6) Customer service system supplier: Hangzhou Netease cloud Technology Co., Ltd. Our product integrates the seven fish SDK. The seven fish SDK needs to collect your Mac address, unique device identification code (imei/mac) to analyze your access track on the device to provide more accurate and more customer service that is more consistent with your consulting scene; During the communication with customer service, if you need to feedback your problems in voice, the seven fish SDK will also apply for audio recording permission from you<br/>

7. Exchange information with other companies and organizations in order to comply with the law, implement or apply our conditions of use and other agreements, or to protect the rights and property or security of BrandGoGo, you or other BrandGoGo customers, such as preventing fraud and other illegal activities and reducing credit risk. However, this does not include personal information that is sold, leased, shared or otherwise disclosed for profit in violation of the commitments made in this privacy policy<br/>

8. If you are using apple mobile phone, in order to simplify your recording process and provide better service for you, after obtaining your authorization, we will receive and send your exercise and diet, including weight and body fat, sleep and other related data from Heathkit of apple. At the same time, the relevant data of exercise and diet, weight and body fat, sleep and so on generated in BrandGoGo records will be synchronously shared with Apple's Heathkit. Any information obtained through Heathkit, such as exercise and diet, weight and body fat, sleep data, etc., will only provide you with health, fitness and / or health services when you use BrandGoGo. We will not share or sell the data obtained from Heathkit to any third party, including advertisers and other agents, nor use Heathkit's information for marketing, advertising Data mining or other similar purposes<br/>
<strong>（Two）
transfer the possession of</strong><br/>

We will not transfer your personal information to any company, organization or individual, except for the following circumstances: <br/> we will not transfer your personal information to any company, organization or individual

1. Transfer with your express consent<br/>

2. In case of merger, acquisition or bankruptcy liquidation, if the transfer of personal information is involved, we will require the new company or organization holding your personal information to continue to be bound by this privacy agreement. Otherwise, we will require the company or organization to seek your authorization again<br/>

<strong>（Three）Public disclosure</strong><br/>

We will only publicly disclose your personal information under the following circumstances：<br/>
1. We may publicly disclose your personal information with your explicit consent or based on your active choice<br/>

2. Law based disclosure: we may disclose your personal information to the public when required by law, legal proceedings, litigation or government authorities<br/>

<h4>How do we protect your personal information</h4>

（1） We have used industry standard security measures to protect your personal information from unauthorized access, public disclosure, use, modification, damage or loss. We will take all reasonable and feasible measures to protect your personal information. For example, the exchange of data (such as credit card information) between your browser and the service is protected by SSL encryption; At the same time, we provide our website with Safe Browsing mode of HTTPS; We will use encryption technology to ensure the confidentiality of data; We will use trusted protection mechanism to prevent data from being attacked maliciously; We will deploy access control mechanisms to ensure that only authorized personnel can access personal information; And we will hold security and privacy training courses to enhance employees' awareness of the importance of protecting personal information<br/>

（2） We will take all reasonable and practicable measures to ensure that irrelevant personal information is not collected. We will only retain your personal information for the period necessary to achieve the purpose stated in this policy, unless the retention period needs to be extended or permitted by law<br/>

（3） We will take measures to ensure the security of your information, but please understand that due to the limitations of technology and various malicious means that may exist, even if we do our best to strengthen the security measures in the Internet industry, it is impossible to guarantee the 100% security of information all the time. If you access the system and communication network used by BrandGoGo, there may be problems due to factors beyond the control of BrandGoGo. Therefore, you should take positive measures to protect the security of personal information, such as using complex passwords, changing passwords regularly, and not disclosing your own account password and other personal information to others<br/>

（4） The Internet environment is not 100% secure. We will try our best to ensure or guarantee the security of any information you send to us. If our physical, technical, or management protection facilities are damaged, resulting in unauthorized access, public disclosure, tampering, or destruction of information, resulting in damage to your legitimate rights and interests, we will bear the corresponding legal responsibility<br/>

（5） After the unfortunate personal information security incident, we will timely inform you of the basic situation and possible impact of the security incident, the disposal measures we have taken or will take, the suggestions that you can prevent and reduce the risk on your own, and the remedial measures for you in accordance with the requirements of laws and regulations. We will timely inform you of the relevant information of the event by mail, letter, telephone, push notice, etc. when it is difficult to inform the personal information subject one by one, we will take a reasonable and effective way to issue an announcement. At the same time, we will actively report the disposal of personal information security incidents in accordance with the requirements of regulatory authorities<br/>

<h4>How do you manage your information</h4>

In accordance with the relevant laws, regulations and standards of China, as well as the common practices of other countries and regions, we guarantee that you can exercise the following rights to your personal information: <br/> we guarantee that you can exercise the following rights to your personal information

<strong> (1) access to your personal information</strong>

You have the right to access your personal information, except for exceptions stipulated by laws and regulations. If you want to exercise the data access right, you can access it by yourself in the following ways: <br/> if you want to exercise the data access right, you can access it by yourself

Account information - if you want to access or edit the personal data and payment information in your account, change your password, add security information or close your account, you can perform such operations by visiting account settings<br/>

Health information - you can record, view, modify and manage your health information and other data in the home page<br/>

Consumption information - you can view and manage your order and consumption information in I<br/>

If you are unable to access these personal information through the above link, you can contact us at any time by using our web form or by email to 398837287@qq.com . We will reply to your request within 30 days<br/>

As long as we don't need too much investment, we will provide you with other personal information generated in the process of using our products or services. If you want to exercise data access, please send an email to 398837287@qq.com 。<br/>

<strong>（Two）Correct your personal information

</strong><br/>

You have the right to ask us to make corrections when you find that we are dealing with errors in your personal information. You can apply for correction through the ways listed in "(I) access to your personal information"<br/>

If you cannot correct these personal information through the above links, you can contact us at any time using our web form or send an email to 398837287@qq.com . We will reply to your request for correction within 30 days.<br/>

<strong>（Three）Delete your personal information

</strong><br/>

In the following cases, you can request us to delete your personal information:  you can delete your personal information<br/>

1. If we deal with personal information in violation of laws and regulations<br/>

2. If we collect and use your personal information without your consent<br/>

3. If our handling of personal information violates the agreement with you<br/>

4. If you no longer use our products or services, or you cancel your account<br/>

5. If we no longer provide you with products or services<br/>

If we decide to respond to your request for deletion, we will also inform the entities who have obtained your personal information from us and require them to delete them in a timely manner, unless otherwise stipulated by laws and regulations, or these entities have obtained your independent authorization<br/>

When you delete information from our service, we may not delete the corresponding information from the backup system immediately, but we will delete the information when the backup is updated<br/>

<strong>（Four）Change the scope of your authorization

</strong><br/>

Each business function requires some basic personal information to be completed. You may give or withdraw your authorization consent at any time for the collection and use of additional personal information<br/>

You can change the scope of your authorization consent by modifying the authorization items in your personal settings<br/>

When you withdraw your consent, we will no longer process the corresponding personal information. However, your decision to withdraw your consent will not affect the personal information processing previously conducted based on your authorization<br/>

<strong>（Five）Cancellation of account by personal information subject

</strong><br/>

BrandGoGo provides you with the right to cancel your user account. You can cancel your previously registered account at any time. You can operate by yourself through account setting (except otherwise stipulated by laws and regulations or agreed in this Agreement). Once you cancel your account, you will not be able to use the full line user product services provided by BrandGoGo and will automatically give up your existing rights and interests. Therefore, please operate carefully. Unless otherwise stipulated by laws and regulations, after the account is cancelled, BrandGoGo will stop providing you with all BrandGoGo products and services, and all content, information, data and records of BrandGoGo products and services that you have used through the account will be deleted or anonymized<br/>
<strong>（Six）In response to your above request

</strong><br/>

For security, you may need to provide a written request or otherwise prove your identity. We may ask you to verify your identity before processing your request. The processing time limit is generally about 15 days. In case of special circumstances, we may extend the processing time limit<br/>

For your reasonable request, we do not charge in principle, but for repeated requests that exceed the reasonable limit, we will charge a certain cost according to the situation. We may refuse requests that are groundless, require too many technical means (e.g., need to develop new systems or fundamentally change current practices), pose risks to the legitimate rights and interests of others, or are very impractical (e.g., involving information stored on backup tapes)<br/>

In the following cases, we will not be able to respond to your request according to the requirements of laws and regulations: <br/> we will not be able to respond to your request in the following cases:

1. Related to national security and national defense security<br/>

2. Related to public safety, public health and major public interests<br/>

3. Related to criminal investigation, prosecution and trial<br/>

4. There is sufficient evidence to show that you have subjective malice or abuse of rights<br/>

5. Responding to your request will seriously damage the legitimate rights and interests of you or other individuals or organizations<br/>

<h4>Six、How do we deal with the personal information of minors</h4><br/>

Our products, websites and services are mainly for adults. Minors under the age of 18 are not allowed to create their own user accounts without the consent of their parents or guardians. If you are a minor, it is recommended that you ask your parents or guardians to read this privacy policy carefully and use our services or provide us with information with the consent of your parents or guardians<br/>

In the case of collecting personal information of minors with the consent of parents, we will only use or publicly disclose this information when it is permitted by law, explicitly agreed by parents or guardians, or necessary for the protection of minors<br/>

If we find that we have collected personal information of minors without the prior consent of verifiable parents, we will try to delete the relevant data as soon as possible<br/>

<h4>Seven、How does your personal information move around the world

</h4>

In principle, personal information collected and generated by us in the people's Republic of China will be stored in the people's Republic of China, except for the following cases: <br/> the

1. The applicable laws have clear provisions<br/>

2. Obtain your explicit authorization<br/>

3. You take the initiative to conduct cross-border transactions and other personal activities through the Internet<br/>

In view of the above situation, we will ensure that we provide sufficient protection for your personal information in accordance with this privacy policy<br/>

<h4>Eight、How is this privacy agreement updated

</h4>

We may adjust or change this privacy agreement from time to time. Any update of this privacy agreement will be announced by means of online announcement, system notification, etc. except for other mandatory provisions in laws, regulations or regulatory provisions, once the adjusted or changed content is announced, it will take effect immediately or at a specific time specified in the announcement. If you continue to use any of our services or visit our relevant websites after the adjustment or change of the privacy agreement, we believe that this means that you have fully read, understood and accepted the revised privacy agreement and are bound by it. If you do not agree to the adjusted or changed privacy agreement, please stop using our services or visit our relevant websites<br/>
<h4>Nine、How to contact us

</h4>

If you have any questions, comments or suggestions on this privacy agreement, please contact us through the following ways: <br/> please contact us at any time

Email: 398837287@qq.com ； Tel: 18117483303

We have set up a full-time Department (or personal information protection specialist) for personal information protection. You can contact it through the following ways: 398837287@qq.com <br/>

Normally, we will reply within 15 days<br/>
    </div>
}
const zh = ()=>{
    return <div style={{padding:'20px'}}>
        <h2 style={{textAlign: 'center'}}>BrandGoGo 隐私政策</h2>
        提示条款<br />
您的信任对我们非常重要。我们充分意识到个人信息对您的重要性。我们将根据法律法规的要求采取相应的安全措施，尽最大努力保护您的个人信息。我们致力于维护您对美国的信任，并严格遵守以下原则来保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最低限度充分原则、保证安全原则、主体参与原则、公开透明原则等。同时，我们承诺按照行业成熟的安全标准，采取相应的安全措施保护您的个人信息。鉴于此，[BrandGoGo]服务商（以下简称“我们”）制定本隐私政策（以下简称“本政策/本隐私政策”）并提醒您：本政策适用于所有产品和服务由 BrandGoGo 提供。如果 BrandGoGo 提供的产品或服务用于我们和我们关联公司的产品或服务（详见定义），但没有独立的隐私政策，本政策也适用于这些产品或服务。如果我们和我们的关联公司对提供给您的产品或服务有单独的隐私政策，则相应的隐私政策应适用于相应的产品或服务。
在使用 BrandGoGo 的各种产品或服务之前，请仔细阅读并彻底理解本政策，尤其是带有粗体/粗体下划线的条款。您应在确认完全理解并同意后，重点阅读和使用。如果您对本政策的内容有任何疑问、意见或建议，您可以通过 BrandGoGo 提供的各种联系方式与我们联系。
<br />
第一部分 定义<br />
BrandGoGo：BrandGoGo 平台<br />
服务提供者：苏州箴创科技有限公司，BrandGoGo的互联网信息和软件技术服务商<br />
关联公司：箴全科技有限公司直接或间接控制或投资的公司<br />
个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动的各种信息。<br />
个人敏感信息：指个人信息包括身份证号、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童信息等（我们会标注具体的个人敏感信息）在本隐私政策中以粗体显示）<br />
个人信息删除：指在实现日常业务功能所涉及的系统中删除个人信息，使其无法被检索和访问的行为。除非另有约定，本政策中使用的定义与 BrandGoGo 用户协议中的定义具有相同含义<br />

第二部分<br />
隐私政策<br />
本隐私政策部分将帮助您了解以下内容：<br />

一、我们如何收集和使用您的信息<br />
二、我们如何使用cookies和类似技术<br />
三、我们如何共享、转让和公开披露您的信息<br />
四、我们如何保护您的信息<br />
五、您如何管理您的信息<br />
六、未成年人信息如何处理<br />
七、您的信息如何在全球范围内移动<br />
八、如何更新本隐私政策<br />
九、联系方式<br />

一、我们如何收集和使用您的个人信息<br />
我们的产品和/或服务包括一些核心功能。我们可能会收集、保存和使用您的以下信息以实现这些功能。如果我们无法收集和使用您的相关信息，您将无法享受我们的产品和/或服务。<br />
A.我们如何收集您的个人信息：<br />
1、您在使用我们服务时主动提供的信息<br />
(1) 您在注册账号时填写或上传的信息：如您注册账号时使用的手机号码，或授权的微信和苹果账号，以及您的昵称、头像等信息<br />
(2) 您在使用服务时主动记录和上传的信息：例如您的个人和家庭成员的基本信息和健康档案信息（包括性别、身高、生日、饮食、运动、体重、周长、月经等）您在 BrandGoGo 中主动记录的信息，以及在健康评估期间提供的信息<br />
(3) 您在使用我们的产品或服务时提供的其他信息<br />
2、我们在您使用服务时主动收集的信息<br />
(1) 设备信息：我们会根据以下规定接收并记录设备相关信息（如设备名称、设备型号、操作系统和应用程序版本、语言设置、设备唯一标识符、运营商网络类型等软硬件特征信息）您在软件安装和使用中授予的具体权限 设备所在位置的信息（如IP地址、GPS位置、WLAN接入点、蓝牙和可提供相关信息的基站）<br />
(2) 日志信息：当您使用我们的客户端或网站提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，并将其保存为相关的网络日志。例如，您的搜索内容、浏览器类型、浏览记录、订单信息、IP地址、访问日期和时间、您访问的网页记录<br />
(3) 位置信息：当您使用位置相关服务时，我们可能会记录您设备的位置信息，以便为您提供相关服务。在您使用服务时，我们可能会通过 IP 地址、GPS、WiFi 或基站获取您的地理位置信息。您可以随时通过相关功能设置取消权限。当您取消本次授权时，我们将不再收集信息并向您提供上述相应服务；但是，除非您依法删除您的信息，否则您的取消不会影响基于您事先授权的信息处理和存储<br />
(4) IP地址：您使用过的移动应用程序（APP）和其他软件的信息，以及您使用的那些移动应用程序和软件的信息<br />
(5) 订单信息：当您准备好在您的购物车/购物袋中结算商品时，BrandGoGo 系统将生成您购买商品的订单。我们将收集您的订单信息，用于您的身份验证、交易确认、支付结算、发货完成、订单查询、客服咨询和售后服务；我们也会根据您的订单信息判断您的交易是否异常，以保障您的交易安全<br />
(6) 支付信息：您在门店下单时，可选择与BrandGoGo合作的第三方支付机构（包括微信支付和支付宝支付渠道，以下简称“支付机构”）提供支付服务。付款后，我们将收取您的第三方付款账户。<br />
(7) 您的饮食习惯：当您使用食物卡路里查询、上传定制菜品等食物相关功能，或根据您的商品信息浏览记录和在店内停留时间收集您的饮食习惯时<br />
(8) 设备中的图片和视频：经过您的授权，我们的客户端会对您上传的视频和图片进行加密上传到服务器存储；您的设备中的软件名称和版本号可能会被提取出来并通过加密上传到服务器进行处理和存储。同时，您也可以通过相关功能设置随时拒绝/取消权限。拒绝提供此权限只会使您无法使用上述功能，但不会影响BrandGoGo其他功能和服务的正常使用；但是，在您取消授权后，我们将不再收集信息，也无法为您提供上述相应服务；但是，除非您依法删除您的信息，否则您的取消不会影响我们基于您之前的授权对信息的存储或处理<br />
(9) 手机摄像头信息：经您授权，当您使用拍照功能上传图片、视频等内容时，我们的客户端可能会获取您的手机摄像头信息<br />
(10)您的运动训练习惯：当您使用运动训练等相关功能时，或根据您在运动课程中的搜索和停留时间收集您的运动训练习惯<br />
3. 经您授权，我们可以通过第三方获取您的个人信息<br />
(1) 为了方便用户记录/同步健康数据和步数，我们还提供了第三方软件的健康数据同步功能，如苹果健康、华为运动健康等第三方软件开发的健康数据。未来<br />
(2) 为安全起见，防止欺诈，在您授权特定账号、金融交易等服务的前提下，从合法来源验证您的信息（如电话号码）<br />
(3) 通过指定的唯一标识符（如从 Advertiser 获取的 IMEI 号或 IDFA 码）为您提供广告服务<br />
(4) 我们可能会从第三方社交网络服务中获取一些信息，例如头像和昵称（例如，当您使用社交网络帐户登录 BrandGoGo 客户端时）<br />
(5) 经您授权，我们会从第三方检测机构获取您的基因检测报告<br />
(6) 经您授权同意通过第三方获取的您的其他信息<br />
4.我们从您绑定的智能设备获取的信息<br />
如果您有相关的硬件设备（包括智能手表、体脂秤等智能设备），并希望同步硬件产品记录的信息（包括但不限于已同意上传的个人信息和个人身体数据，如如步数、运动数据、体重、体脂率等）到 BrandGoGo，您可以通过打开蓝牙功能来实现。如果您拒绝打开，您将无法使用该功能，但不会影响您正常使用 BrandGoGo 的其他功能。同时，您可以通过相关功能设置随时取消权限。但是，在您取消授权后，我们将不再收集信息，也无法为您提供上述相应服务；但是，除非您依法删除您的信息，否则您的取消不会影响我们根据您之前的授权处理和存储信息<br />
5.第三方通过软件等方式获取的信息<br />
为了给您提供更好的服务，在您使用BrandGoGo的部分功能时，与BrandGoGo有合作关系的第三方可能会获取您的部分信息（包括：您的设备平台、设备制造商、设备品牌、设备ID） （如IMEI、MAC、IDFA等）、录音权限、应用列表信息等）通过SDK接口等网络信息（如IP地址、WiFi信息等）和位置相关信息等。 )<br />

B. 我们如何使用您的个人信息：<br />
我们严格遵守法律法规的规定以及与用户的约定，将收集到的信息用于以下目的。如果我们超出以下目的使用您的信息，我们会在征得您同意的情况下再次向您说明<br />
1.为您提供健康评估、食品信息识别与分析、饮食分析、健康计划、您及家人的健康档案管理、产品销售、广告等服务<br />
2.为满足您的个性化需求，为了推荐您可能更感兴趣的更高质量的服务内容，给您更好的体验，我们还将根据您的相关个人信息和用户资料进行个性化推荐/定向推送。例如，“BrandGoGoapp”可能有专门的模块“为您推荐”等功能，推荐您可能感兴趣的产品<br />
3.为向您提供一致的服务，我们可能会关联从您的各种设备收集的信息。我们可能会将来自一项服务的信息与来自其他服务的信息结合起来，为您提供服务、个性化内容和建议。以上做法可能会对您的产品浏览页面显示、产品搜索页面排序、产品推荐页面显示产生影响<br />
4.例如，我们会将您的信息用于身份验证、安全性、反欺诈监控、存档备份、客户安全服务等用途<br />
5. 评估和改进我们的广告和其他促销活动的有效性<br />
6. 帮助我们设计新服务并改进我们现有的服务。例如，我们将分析数据、研究和开发与使用我们的产品或服务相关的统计数据<br />
7. 为我们的业务运营（例如业务统计）或法律义务存储和维护与您相关的信息<br />
8. 经您同意的其他用途<br />
9、其他不违反强制性法律法规的情形<br />

我们的承诺<br />
1、请您理解，我们为您提供的功能和服务是不断更新和发展的。如上述说明中未包含某项功能或服务而收集您的信息的，我们将通过页面提示、互动流程、网站公告等方式分别向您说明信息收集的内容、范围和目的，以便征得您的同意<br />
2、请注意，在获得您的授权之前，我们不会主动从BrandGoGo以外的第三方获取您的个人信息。如果您日后需要从第三方间接获取您的个人信息用于业务发展，我们会在获取前向您说明您的个人信息的来源、类型和使用范围。例如，BrandGoGo开展业务所需的个人信息处理活动，在向第三方提供个人信息时，超出了您原授权同意的范围。在处理您的个人信息之前，我们会在您明确同意的情况下；此外，我们将严格遵守相关法律法规，并要求第三方保证所提供信息的合法性<br />
3、在收集到您的个人信息后，我们会及时通过技术手段对数据进行匿名化处理。在不披露您的个人信息的情况下，我们有权对匿名用户数据库进行挖掘、分析和利用（包括商业用途），并有权对产品/服务的使用情况进行统计并与公众/第三方共享脱敏统计信息派对<br />

二、我们如何使用cookies和类似技术<br />
1. Cookie 为确保网站正常运行，我们在您的计算机或移动设备上存储称为 cookie 的小型数据文件。 Cookie 通常包含标识符、站点名称以及一些数字和字符。借助 cookie，网站可以存储您的偏好或购物篮中的商品等数据<br />
我们不会将 cookie 用于本政策所述目的以外的任何目的。您可以根据自己的喜好管理或删除 cookie。您可以清除 BrandGoGo 上保存的所有 cookie，大多数网络浏览器都具有阻止 cookie 的能力。但如果您这样做，则每次访问我们的网站时都需要自己更改用户设置。有关如何更改浏览器设置的更多信息，请访问以下链接：Internet Explorer、Google Chrome、Mozilla Firefox、Safari 和 opera。<br />
2.网站信标和像素标签除了cookies，我们还会在网站上使用网站信标、像素标签等类似技术。例如，我们发送给您的电子邮件可能包含链接到我们网站内容的点击 URL。如果您点击此链接，我们将跟踪此点击以帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是嵌入网站或电子邮件中的透明图像。借助电子邮件中的像素标签，我们可以知道电子邮件是否被打开。如果您不希望以这种方式跟踪您的活动，您可以随时退订我们的邮件列表<br />
3.很多网页浏览器都配备了不追踪功能，可以向网站发布不追踪请求。目前，主要互联网标准组织尚未制定相关政策，明确网站应如何响应此类请求。但是如果您的浏览器中启用了不跟踪，我们所有的网站都会尊重您的选择<br />

三、我们如何共享、转让和公开披露您的个人信息<br />
（一）分享<br />
我们不会与任何其他公司、组织或个人共享您的个人信息，但以下情况除外：<br />
1、在明确同意的情况下共享：在您明确同意的情况下，我们将与其他方共享您的个人信息<br />
2、合法情况下的共享：我们可能会根据法律法规、诉讼、争议解决的需要，或根据行政、司法机关的要求，共享您的个人信息<br />
3. 只有共享您的信息，我们才能实现我们产品和/或服务的核心功能或提供您需要的服务<br />
4. 与我们的关联公司共享：您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息，并受本隐私协议所述目的的约束。关联公司如要改变个人信息处理的目的，将再次征求您的授权<br />
5、与授权合作伙伴共享：仅出于本隐私协议所述目的，我们的部分服务将由授权合作伙伴提供。我们可能会与我们的合作伙伴共享您的一些个人信息，以提供更好的客户服务和用户体验。例如，我们聘请提供第三方物流的公司可能需要收集和使用您的收货地址、收货人和联系信息。在这种情况下，这些公司必须遵守我们的数据隐私和安全要求。我们只会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且仅共享目前提供服务所必需的个人信息，我们的合作伙伴包括以下类型：<br />
(1)商品或技术服务的供应商。我们可能会与支持我们能力的第三方共享您的个人信息。这些支持包括提供或提供基础设施技术服务、物流配送服务、支付服务、数据处理等。共享这些信息的目的是为了实现我们产品和/或服务的核心购物功能，例如，我们必须共享您与物流服务提供商的订单信息以安排交货；或者我们需要将您的订单号和订单金额分享给第三方支付机构，以实现其对您的支付订单的确认并完成支付<br />
(2) 第三方商家。我们必须与第三方商家分享您的订单和交易的必要信息，以实现您对其商品或服务的需求，并使其能够完成后续的售后服务<br />
(3) 委托我们进行推广的合作伙伴。有时，我们代表其他企业向使用我们产品和/或服务的用户群提供促销服务。我们可能会将您的个人信息和您的非个人信息收集形成的间接用户画像分享给委托我们推广的合作伙伴（“客户”），但我们只会向客户提供推广的覆盖范围和有效性，而不是您的个人身份信息，否则我们会将信息汇总，使其无法识别您的个人身份。例如，我们可以告诉客户有多少人在看到信息后阅读了他们的促销信息或购买了客户的商品，或者向他们提供无法识别其个人身份的统计信息，以帮助他们了解他们的个人身份。观众或顾客<br />
(4)用户画像服务商：浙江天天互动网络科技有限公司提供用户画像服务。我们可能会使用设备平台、设备制造商、设备品牌、设备识别码（如IMEI、MAC、IDFA等）等设备信息列出信息和网络信息（如IP地址WiFi信息等）和位置相关信息提供给浙江天天互动网络科技有限公司，为您提供用户画像服务。请参阅个人用户的隐私政策<br />
(5)应用统计服务商：浙江天天互动网络科技有限公司提供应用统计服务。我们可能会将相关信息和网络信息（如IP地址、IP地址等）应用于设备平台、设备制造商、设备品牌、设备识别码（如IMEI、MAC、IDFA等WiFi信息）提供浙江天天互动网络科技有限公司为您提供多项应用统计服务。请访问个人用户的隐私政策<br />
6、客服系统供应商：杭州网易云科技有限公司。我们的产品集成了七鱼SDK。七鱼SDK需要采集您的Mac地址、唯一设备识别码（imei/mac），分析您在设备上的访问轨迹，提供更精准、更贴合您咨询场景的客服；在与客服沟通的过程中，如果您需要语音反馈您的问题，七鱼SDK也会向您申请录音权限<br />
7、与其他公司和组织交换信息以遵守法律、执行或适用我们的使用条件和其他协议，或保护 BrandGoGo、您或其他 BrandGoGo 客户的权利和财产或安全，例如防止欺诈和其他非法活动，降低信用风险。但是，这不包括违反本隐私政策中的承诺而出售、出租、共享或以其他方式披露以获取利润的个人信息<br />
8、如果您使用的是苹果手机，为了简化您的记录流程，为您提供更好的服务，在获得您的授权后，我们将接收和发送您的运动和饮食，包括体重和体脂、睡眠等相关数据 来自苹果的 Heathkit。 同时，BrandGoGo记录中产生的运动与饮食、体重与体脂、睡眠等相关数据将与苹果的Heathkit同步共享。 通过 Heathkit 获得的任何信息，例如运动和饮食、体重和体脂、睡眠数据等，只会在您使用 BrandGoGo 时为您提供健康、健身和/或健康服务。 我们不会将从 Heathkit 获得的数据共享或出售给任何第三方，包括广告商和其他代理，也不会将 Heathkit 的信息用于营销、广告数据挖掘或其他类似目的<br />
（二）转让占有<br />
我们不会将您的个人信息转让给任何公司、组织或个人，但以下情况除外：<br />
我们不会将您的个人信息转让给任何公司、组织或个人 <br />
1. 在您明确同意的情况下转让<br />
2、在合并、收购或破产清算的情况下，如涉及个人信息的转让，我们将要求持有您个人信息的新公司或组织继续受本隐私协议的约束。否则，我们将要求公司或组织再次征求您的授权<br />
（三）公开披露<br />
我们只会在以下情况下公开披露您的个人信息：<br />
1、我们可能会在您明确同意或根据您的主动选择公开披露您的个人信息<br />
2. 依法披露：在法律、法律程序、诉讼或政府机关要求时，我们可能会向公众披露您的个人信息<br />
我们如何保护您的个人信息<br />
（1）我们采用行业标准的安全措施来保护您的个人信息免遭未经授权的访问、公开披露、使用、修改、损坏或丢失。我们将采取一切合理可行的措施保护您的个人信息。例如，您的浏览器与服务之间的数据交换（如信用卡信息）受 SSL 加密保护；同时，我们为我们的网站提供HTTPS的安全浏览模式；我们将使用加密技术来确保数据的机密性；我们将采用可信保护机制，防止数据被恶意攻击；我们将部署访问控制机制，确保只有授权人员才能访问个人信息；我们将举办安全和隐私培训课程，以增强员工对保护个人信息重要性的认识<br />
（2）我们将采取一切合理可行的措施，确保不收集无关的个人信息。我们只会在实现本政策所述目的所必需的期限内保留您的个人信息，除非保留期限需要延长或法律允许<br />
（3）我们将采取措施确保您的信息安全，但请理解，由于技术的限制和可能存在的各种恶意手段，即使我们尽最大努力加强互联网行业的安全措施，不可能一直保证信息的100%安全。如果您访问 BrandGoGo 使用的系统和通讯网络，可能会因 BrandGoGo 无法控制的因素而出现问题。因此，您应采取积极措施保护个人信息的安全，如使用复杂密码、定期更换密码、不向他人泄露自己的账户密码等个人信息。<br />
（4）互联网环境不是100%安全的。我们将尽最大努力确保或保证您发送给我们的任何信息的安全性。如果我们的物理、技术或管理保护设施遭到破坏，导致未经授权的访问、公开披露、篡改或破坏信息，导致您的合法权益受到损害，我们将承担相应的法律责任<br />
（5） 不幸的个人信息安全事件发生后，我们会及时告知您安全事件的基本情况和可能产生的影响，我们已经或将要采取的处置措施，您可以预防和降低您的风险的建议并根据法律法规的要求为您采取补救措施。我们将通过邮件、信函、电话、推送通知等方式及时将事件的相关信息告知您，当个人信息主体难以一一告知时，我们将采取合理有效的方式发布公告.同时，我们将按照监管部门的要求，积极上报个人信息安全事件的处置情况。<br />
您如何管理您的信息<br />
根据中国相关法律法规和标准，以及其他国家和地区的通行做法，我们保证您可以对您的个人信息行使以下权利：<br />
我们保证您可以对您的个人信息行使以下权利： (1) 访问您的个人信息您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，您可以通过以下方式自行访问：<br />
如果您想行使数据访问权，您可以自行访问账户信息——如果您想访问或编辑您账户中的个人数据和支付信息、更改您的密码、添加安全信息或关闭您的账户，您可以通过访问帐户设置执行此类操作<br />
健康信息——您可以在首页记录、查看、修改和管理您的健康信息等数据<br />
消费信息——您可以在I中查看和管理您的订单和消费信息<br />
如果您无法通过上述链接访问这些个人信息，您可以随时使用我们的网络表格或发送电子邮件至 398837287@qq.com 与我们联系。我们将在 30 天内回复您的请求<br />
只要我们不需要太多的投资，我们就会向您提供在使用我们的产品或服务过程中产生的其他个人信息。如果您想行使数据访问权限，请发送电子邮件至 398837287@qq.com 。<br />
（二）更正您的个人信息<br />
当您发现我们正在处理您的个人信息中的错误时，您有权要求我们进行更正。您可以通过“访问您的个人信息”中列出的方式申请更正。如果您无法通过上述链接更正这些个人信息，您可以随时使用我们的网络表格联系我们或发送电子邮件至398837287@qq.com。我们将在 30 天内回复您的更正请求。<br />
（三）删除您的个人信息<br />
在以下情况下，您可以要求我们删除您的个人信息：您可以删除您的个人信息<br />
1.如果我们违反法律法规处理个人信息<br />
2.如果我们在未经您同意的情况下收集和使用您的个人信息<br />
3.如果我们对个人信息的处理违反与您的约定<br />
4.如果您不再使用我们的产品或服务，或者您注销了您的帐户<br />
5. 如果我们不再为您提供产品或服务<br />
如果我们决定回应您的删除请求，我们还将通知从我们这里获取您个人信息的实体，并要求其及时删除，除非法律法规另有规定，或者这些实体已经获取了您的独立授权<br />
当您从我们的服务中删除信息时，我们可能不会立即从备份系统中删除相应的信息，但我们会在备份更新时删除这些信息<br />
（四）变更您的授权范围<br />
每个业务功能都需要一些基本的个人信息才能完成。您可以随时给予或撤回您对收集和使用额外个人信息的授权同意<br />
您可以通过修改个人设置中的授权项来更改您的授权同意范围<br />
当您撤回同意时，我们将不再处理相应的个人信息。但是，您撤回同意的决定不会影响之前根据您的授权进行的个人信息处理<br />
（五）个人信息主体注销账户<br />
BrandGoGo 为您提供取消您的用户帐户的权利。您可以随时取消您之前注册的帐户。您可以通过账户设置自行操作（法律法规另有规定或本协议另有约定的除外）。一旦您注销账号，您将无法使用BrandGoGo提供的全线用户产品服务，并自动放弃您现有的权益。因此，请谨慎操作。除非法律法规另有规定，注销账户后，BrandGoGo将停止向您提供所有BrandGoGo产品和服务，您通过该账户使用的BrandGoGo产品和服务的所有内容、信息、数据和记录将被删除或匿名<br />
（六）应您的上述要求<br />
为了安全起见，您可能需要提供书面请求或以其他方式证明您的身份。在处理您的请求之前，我们可能会要求您验证您的身份。处理时限一般为15天左右。如遇特殊情况，我们可能会延长处理时限<br />
对于您的合理要求，我们原则上不收费，但对于超过合理限度的重复要求，我们将视情况收取一定的费用。我们可能会拒绝毫无根据、需要太多技术手段（例如，需要开发新系统或从根本上改变现行做法）、对他人合法权益构成风险或非常不切实际（例如，涉及存储在备份磁带）<br />
在下列情况下，我们将无法按照法律法规的要求回复您的请求：<br />
在下列情况下，我们将无法回复您的请求： 1、与国家安全和国防安全相关的<br />
2、与公共安全、公共卫生和重大公共利益有关的<br />
3、与刑事侦查、起诉、审判有关的<br />
4、有充分证据表明您存在主观恶意或滥用权利<br />
5、响应您的请求将严重损害您或其他个人或组织的合法权益<br />
六、我们如何处理未成年人的个人信息<br />

我们的产品、网站和服务主要面向成人。未经父母或监护人同意，未满 18 岁的未成年人不得创建自己的用户帐户。如果您是未成年人，建议您请您的父母或监护人仔细阅读本隐私政策并在您的父母或监护人同意的情况下使用我们的服务或向我们提供信息<br />
在征得父母同意的情况下收集未成年人的个人信息，我们只会在法律允许、父母或监护人明确同意或保护未成年人所必需的情况下使用或公开披露这些信息<br />
如果我们发现我们在未经可验证父母事先同意的情况下收集了未成年人的个人信息，我们将尽快删除相关数据<br />
七、您的个人信息如何在全球范围内移动<br />
我们在中华人民共和国境内收集和产生的个人信息原则上将存储在中华人民共和国境内，但以下情况除外：<br />
1. 适用法律有明确规定<br />
2. 获得您的明确授权<br />
3、您主动通过互联网进行跨境交易等个人活动<br />
针对上述情况，我们将确保按照本隐私政策为您的个人信息提供充分的保护<br />
八、本隐私协议如何更新<br />
我们可能会不时调整或更改本隐私协议。本隐私协议的任何更新将通过在线公告、系统通知等方式进行公告，除法律、法规或监管规定的其他强制性规定外，一经调整或变更的内容一经公告，即刻生效或不定期生效。公告规定的具体时间。如果您在隐私协议调整或变更后继续使用我们的任何服务或访问我们的相关网站，我们认为这意味着您已充分阅读、理解并接受修改后的隐私协议并受其约束。如果您不同意调整或变更后的隐私协议，请停止使用我们的服务或访问我们的相关网站<br />
九、如何联系我们<br />
如果您对本隐私协议有任何问题、意见或建议，请通过以下方式与我们联系：<br />
请随时联系我们 邮箱：398837287@qq.com ；电话：18117483303 我们设有专职的个人信息保护部门（或个人信息保护专员）。您可以通过以下方式联系：398837287@qq.com<br />
通常，我们会在 15 天内回复
    </div>
}
const PrivacyAgreement = ()=>{
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    return lang == 'zh' ? zh() : en()
}
export default withRouter(PrivacyAgreement);