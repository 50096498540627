import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import intl from 'react-intl-universal';
import * as Api from '../../../service/https'
import {Modal, Input, List, notification, Button, Image, Divider, Spin, message} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CloseCircleOutlined } from '@ant-design/icons';
import store from '../../../utils/redux/index'

const McnWallet = () => {
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [textJson, setTextJson] = useState(intl.get('Wallet'))
    const [amount, setAmount] = useState('')
    useEffect(() => {
        getWallet()
        loadMoreData()
        // console.log(state.MCN)
    }, [])
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [walletObj, setWallet] = useState([])
    const [isSuccess, setScucess] = useState(false)
    const [isApply, setApply] = useState(false)
    const [isTixian, setTixian] = useState(false)
    const [loadingTi, setLoadingTi] = useState(false);
    const [totalTi, setTotalTi] = useState(0);
    const [dataTi, setDataTi] = useState([]);
    const [pageIndexTi, setPageIndexTi] = useState(1);
    const getTixian = () => {
        setTixian(true)
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            current: pageIndexTi, size: 10,
        }
        Api.getMcn_withdrawallog(obj).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setTotalTi(res.data.total)
                setDataTi(pageIndexTi == 1 ? res.data.records : [...dataTi, ...res.data.records]);
                setPageIndexTi(pageIndexTi + 1)
                setLoading(false);
                setLoadingTi(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    }

    const [rate, setRate] = useState()
    const [DOLLAR, setDOLLAR] = useState()
    const [RMB, setRMB] = useState()

    useEffect(() => {
        susdcny()
        // loadMoreData()
    }, [DOLLAR])

    const susdcny = e => {
        Api.susdcny({
            money: DOLLAR * rate,
            type: 1
        }).then(res => {
            if (res.code === 200) {
                setRate(res.data.RATE)
                // setDOLLAR(res.data.DOLLAR)
                setRMB(res.data.RMB)
            }
        })
    }

    const changeDollar =  (value) =>{
        setDOLLAR(value > walletObj.walletBalanceDollar ? walletObj.walletBalanceDollar :value)
    }

    const closeTixian = () => {
        setPageIndexTi(0)
        setTixian(false)
        setDataTi([])
    }

    const getWallet = () => {
        Api.getWallet({}).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setWallet(res.data)
            }
        })
    }

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            current: pageIndex, size: 10,
        }
        Api.getMcn_wallet(obj).then(res => {
            // console.log(res)
            setLoading(false);
            if (res.code == 200) {
                setTotal(res.data.total)
                setData(pageIndex == 1 ? res.data.walletInfos : [...data, ...res.data.walletInfos]);
                setPageIndex(pageIndex + 1)
            }
        })
    };

    const sub = () => {
        if(DOLLAR > walletObj.walletBalanceDollar){
            message.warning('可提现余额不足')
            return
        }
        if(DOLLAR > 0) {
            let obj = {
                payeeName: name,
                payeeAccount: email,
                withdrawalAmountDollar: DOLLAR
            }
            Api.getMcn_insert(obj).then(res => {
                // console.log(res)
                if (res.code === 20012) {
                    setScucess(true)
                    getWallet()
                    setApply(false)
                }
            })
        }
    }

    useEffect(()=>{
        if(!isApply || !isSuccess){
            setName('')
            setEmail('')
            setRMB('')
            setDOLLAR('')
        }
    },[isApply, isSuccess])

    return <div id='wallet' style={{ paddingBottom: '43px' }}>
        <nav style={{ width: '100%', position: 'relative', fontWeight: 500 }}>
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt=""
                style={{ width: '100%' }} />
            <div style={{
                width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt=""
                    style={{ width: '190px' }} />
                <div style={{
                    width: '10px', height: '10px', backgroundColor: 'black', borderRadius: '5px',
                    margin: '0 20px'
                }}></div>
                <span style={{ fontSize: '20px' }}>{intl.get('Workbench').RevenueManagement}</span>
            </div>
        </nav>

        <div style={{
            width: '100%', height: '180px', backgroundColor: '#FFF', textAlign: 'center',
            display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', padding: '0 30px'
        }}>
            <div>
                <span style={{ color: '#666666' }}>{intl.get('AccountRecharge')['当前收益总额']}</span>
                <div style={{ fontWeight: 500, fontSize: '22px' }}>${walletObj.walletBalanceDollar}</div>
                <div style={{
                    width: '160px', height: '38px', borderRadius: '4px', cursor: 'pointer',
                    textAlign: 'center', lineHeight: '38px', color: '#FFF', margin: '16px auto',
                    background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                }}
                    onClick={() => setApply(true)}>
                    {textJson['去提现']}
                </div>
            </div>
            <div style={{ width: '1px', height: '130px', background: '#EEEEEE' }}></div>
            <div>
                <span style={{ color: '#666666' }}>{textJson['已提现金额']}</span>
                <div style={{ fontWeight: 500, fontSize: '22px' }}>${walletObj.withdrawalAmountDollar}</div>
                <div style={{
                    width: '160px', height: '38px', borderRadius: '4px', cursor: 'pointer',
                    textAlign: 'center', lineHeight: '34px', color: '#666', margin: '16px auto',
                    border: '1px solid #EEEEEE', fontWeight: 'blod'
                }}
                    onClick={() => getTixian()}>
                    {textJson['查看提现记录']}
                </div>
            </div>
        </div>

        <div style={{ width: '100%', background: '#FFF', marginTop: '20px', padding: '26px 30px' }}>
            <div style={{ width: '100%', height: '50px', background: '#F6F6F6' }}>
                <div style={{
                    width: '100%', height: '100%', display: 'flex', alignItems: 'center'
                }}>
                    <div className='over' style={{ width: '30%', paddingLeft: '45px', paddingRight: '45px' }}>{intl.get('ReleaseDemand')['商品/品牌']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['需求目标']}</div>
                    <div style={{ width: '20%' }}>{intl.get('AccountRecharge')['合作红人']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['佣金']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['内容形式']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['媒体传播']}</div>
                    <div style={{ width: '15%' }}>{intl.get('AccountRecharge')['到账时间']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['平台抽佣']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['红人收入']}</div>
                </div>
            </div>
            
            {data?.length > 0 ? <Spin tip="数据加载中..." spinning={loading}>
                <InfiniteScroll
                    dataLength={data?.length} scrollThreshold={'20px'}
                    next={loadMoreData}
                    hasMore={data?.length < total}
                    // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                    endMessage={<Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>}
                    // scrollableTarget="wallet"
                >
                    <List
                        dataSource={data} grid={{ gutter: 0, column: 1 }}
                        renderItem={(item, index) => <div key={index} style={{
                            width: '100%', height: '100px', display: 'flex', alignItems: 'center',
                            background: (index + 1) % 2 == 0 ? '#F6F6F6' : '#FFF'
                        }}>
                            <div className='over' style={{ width: '30%', paddingLeft: '45px', paddingRight: '45px' }}>{item.listName}</div>
                            <div style={{ width: '10%' }}>{item.target == 1 ? intl.get('ReleaseDemand')['电商卖货'] : item.target == 3 ? intl.get('ReleaseDemand')['商品种草'] : intl.get('ReleaseDemand')['品牌传播']}</div>
                            <div style={{ width: '20%', display: 'flex', alignItems: 'center' }}>
                                <Image onClick={e => e.stopPropagation()} src={item.bloggerBriefInfo.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                                    style={{ width: '48px', height: '48px', borderRadius: '24px', marginRight: '16px' }} />
                                <div style={{ color: '#888888' }}>
                                    <div style={{
                                        color: '#333333', fontWeight: 'bold', fontSize: '14px',
                                        display: 'flex', alignItems: 'center'
                                    }}>
                                        <div className='over1' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item.bloggerBriefInfo.nickname}</div>
                                    </div>
                                    <span style={{fontSize:'12px'}}>TikTok: {item.bloggerBriefInfo.tiktok}</span>
                                </div>
                            </div>
                            <div style={{ width: '10%' }}>${item.costDollar}</div>
                            <div style={{ width: '10%' }}>{item.cooperationWay == 1 ? intl.get('ReleaseDemand')['短视频'] : intl.get('ReleaseDemand')['直播']}</div>
                            <div style={{ width: '10%' }}>{item.media == 1 ? 'TikTok' : '--'}</div>
                            <div style={{ width: '15%' }}>{item.payFinishTime}</div>
                            <div style={{ width: '10%', color: '#F7385B' }}>- ${item.costTipDollar}</div>
                            <div style={{ width: '10%', color: '#68C33B' }}>+ ${item.costEarningDollar}</div>
                        </div>}
                    />
                </InfiniteScroll>
            </Spin> :
            <div style={{width:'345px', height:'200px', textAlign:'center', margin:'0 auto', marginTop:'100px' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                <div style={{marginTop:'24px', textAlign: 'center', color:'#333' }}>{'No Data'}</div>
            </div>}
        </div>




        <Modal centered visible={isTixian} footer={null} closable={false}
            getContainer={() => document.getElementById('wallet')}
            bodyStyle={{
                padding: '0', backgroundColor: '#FFFFFF',
                width: 1347, borderRadius: '10px'
            }}
        >
            <div style={{ padding: '55px 30px 30px 30px', borderRadius: '10px' }}>
                <h3 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>{textJson['提现记录']}</h3>
                <div style={{
                    width: '100%', height: '50px', display: 'flex', alignItems: 'center',
                    background: '#F6F6F6', marginTop: '40px', fontWeight: 500
                }}>
                    <div style={{ width: '17%', paddingLeft: '45px', paddingRight: '40px' }}>{textJson['收款人真实姓名']}</div>
                    <div style={{ width: '16%' }}>{textJson['收款账号']}</div>
                    <div style={{ width: '15%' }}>{textJson['提现时间']}</div>
                    <div style={{ width: '10%' }}>{textJson['提现金额']}</div>
                    {/* <div style={{ width: '10%' }}>{textJson['提现美金']}</div> */}
                    <div style={{ width: '10%' }}>{textJson['状态']}</div>
                    <div style={{ width: '15%' }}>{textJson['到账时间']}</div>
                    <div style={{ width: '20%' }}>{textJson['备注']}</div>
                </div>
                <div id='scrollableDiv' style={{ width: '100%', height: '60vh', overflowY: 'auto' }}>
                    <Spin tip="数据加载中..." spinning={loading}>
                        <InfiniteScroll
                            dataLength={dataTi.length} scrollThreshold={'20px'}
                            next={getTixian}
                            hasMore={dataTi.length < totalTi}
                            // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                            endMessage={dataTi.length > 10 ? <Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider> : ''}
                            scrollableTarget="scrollableDiv"
                        >
                            <List
                                dataSource={dataTi} grid={{ gutter: 0, column: 1 }}
                                renderItem={(item, index) => <div key={index} style={{
                                    width: '100%', height: '100px', display: 'flex', alignItems: 'center',
                                    background: (index + 1) % 2 == 0 ? '#F6F6F6' : '#FFF'
                                }}>
                                    <div className='over' style={{ width: '17%', paddingLeft: '45px', fontWeight: 'bold', fontSize: '16px' }}>{item.payeeName}</div>
                                    <div style={{ width: '16%' }}>{item.payeeAccount}</div>
                                    <div style={{ width: '15%' }}>{item.createTime}</div>
                                    <div style={{ width: '10%' }}>-${item.withdrawalAmountDollar}</div>
                                    {/* <div style={{ width: '10%' }}>{item.withdrawalAmountDollar?'-$':''}{item.withdrawalAmountDollar || '--'}</div> */}
                                    <div style={{ width: '10%', color: item.withdrawalStatus == 1 ? '#FFC146' : item.withdrawalStatus == 2 ? '#F7385B' : item.withdrawalStatus == 3 ? '#68C33B' : '#333' }}>{item.withdrawalStatus == 1 ? textJson['处理中'] : item.withdrawalStatus == 2 ? textJson['已驳回'] : item.withdrawalStatus == 3 ? textJson['已到账'] : '--'}</div>
                                    <div style={{ width: '15%' }}>{item.transferAccountTime ? item.transferAccountTime : '--'}</div>
                                    <div style={{ width: '20%', color:'#F7385B' }}>{item.remark || '--'}</div>
                                </div>}
                            />
                        </InfiniteScroll>
                    </Spin>
                </div>

                <CloseCircleOutlined style={{
                    position: 'absolute', right: '29px', top: '29px', fontSize: '20px', color: '#888888',
                    cursor: 'pointer'
                }}
                    onClick={() => closeTixian()} />
            </div>
        </Modal>






        <Modal centered visible={isSuccess} footer={null} closable={false}
            getContainer={() => document.getElementById('wallet')}
            bodyStyle={{
                padding: '0', backgroundColor: '#FFFFFF',
                width: 443, height: 466,
            }}
        >
            <div style={{ textAlign: 'center', paddingTop: '55px' }}>
                <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{textJson['提现申请提交成功']}</h3>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suc.png'} alt=""
                    style={{ width: '173px', marginTop: '40px' }} />
                <div style={{width:'70%', margin:'0 auto'}}>{intl.get('ReleaseDemand')['我们已收到你的提现申请，提现金额将在1～3个工作日到账，请耐心等待']}</div>
                <div style={{
                    width: '210px', height: '40px', borderRadius: '4px', cursor: 'pointer',
                    textAlign: 'center', lineHeight: '40px', color: '#FFF', margin: '38px auto',
                    background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                }}
                    onClick={() => setScucess(false)} >
                    {intl.get('Wallet')['前往我的钱包']}
                </div>
            </div>
        </Modal>


        <Modal centered visible={isApply} footer={null} closable={false}
            getContainer={() => document.getElementById('wallet')}
            bodyStyle={{
                padding: '0', backgroundColor: '#FFFFFF',
                // width: 554, 
            }}
        >
            <div style={{ textAlign: 'center', padding: '57px 105px' }}>
                <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{textJson['提现申请']}</h3>
                <div style={{
                    color: '#666666', display: 'flex', alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginTop: '66px', fontWeight: 500
                }}>
                    {textJson['提现金额']}<span style={{ fontSize: '12px', color: '#888' }}>（{textJson['当前可提现金额']}
                        <span style={{ color: '#F7385B', fontSize: '14px', fontWeight: 'bold' }}>${walletObj.walletBalanceDollar || 0}</span>
                        {
                            rate ?
                                <span>，{textJson['当前汇率']}<span style={{ color: '#F7385B', fontSize: '14px', fontWeight: 'bold' }}>{rate}</span></span>
                                :
                                ''
                        }
                        ）</span>
                </div>
                <div style={{ marginTop: '12px' }}>
                    <Input prefix="$" style={{ height: '48px', borderRadius: '4px' }} placeholder={textJson['请输入你想要提现的金额']}
                           onChange={e => changeDollar(e.target.value.replace(/^[\d.]/g,''))} value={DOLLAR} />
                    {RMB ? <div style={{ color: '#CCCCCC', textAlign: 'left', fontSize: '12px' }}>{textJson['实际提现金额']}：￥{RMB}</div> : ''}
                </div>
                <div style={{
                    color: '#666666', display: 'flex', alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginTop: '30px', fontWeight: 500
                }}>
                    {textJson['收款人真实姓名']}<span style={{ fontSize: '12px', color: '#888' }}>（{textJson['请输入注册Payoneer时的真实姓名']}）</span>
                </div>
                <div style={{ marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Input style={{ height: '48px', borderRadius: '4px' }} placeholder={textJson['请输入真实姓名']}
                        onChange={e => setName(e.target.value)} value={name} />
                </div>
                <div style={{
                    color: '#666666', display: 'flex', alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginTop: '30px', fontWeight: 500
                }}>
                    {textJson['收款的账号']}<span style={{ fontSize: '12px', color: '#888' }}>（{textJson['请输入注册Payoneer时的邮箱号']}）</span>
                </div>
                <div style={{ marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Input style={{ height: '48px', borderRadius: '4px' }} placeholder={textJson['请输入邮箱号']}
                        onChange={e => setEmail(e.target.value)} value={email} />
                </div>
                <Button disabled={DOLLAR == '' || name == '' || email == ''} style={{
                    width: '210px', height: '40px', borderRadius: '4px', border: 0,
                    textAlign: 'center', color: '#FFF', margin: '0 auto', marginBottom: '12px',
                    background: DOLLAR == '' || name == '' || email == '' ? '#EEEEEE' : 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod', marginTop: '48px'
                }}
                    onClick={() => sub()} >
                    {textJson['确定提现']}
                </Button>
                <div style={{ fontSize: '12px', color: '#888888', marginTop: '10px' }}>
                    {textJson['本平台转账统一使用Payoneer，提现请先开通Payoneer']}
                </div>
                <CloseCircleOutlined style={{
                    position: 'absolute', right: '29px', top: '29px', fontSize: '20px', color: '#888888',
                    cursor: 'pointer'
                }}
                    onClick={() => setApply(false)} />
            </div>
        </Modal>

    </div>
}
export default withRouter(McnWallet);