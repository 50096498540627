import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../utils/withRouter'
import { Image, Input, Checkbox, Select, Spin, notification, Form, Button } from 'antd';
import intl from 'react-intl-universal';
import { CheckOutlined, ReloadOutlined } from '@ant-design/icons';
import * as Api from '../../service/https'
import store from '../../utils/redux/index'
import BloggerSigning from '../../components/Login/BloggerSigning'
import McnSigning from '../../components/Login/McnSigning'
import QRCode from 'qrcode.react';
const { Option } = Select;

const Login = (props)=>{
    const [textJson, setTextJson] = useState(intl.get('NewLogin'))
    const intervalRef = useRef(null);
    const timeOutRef = useRef(null);
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [isBind, setIsBind] = useState(false)
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);
    useEffect(()=>{
        // console.log(props)
        IsPC()
        window.addEventListener('resize', IsPC)
        return () => {
            window.removeEventListener('resize', IsPC)
            if(intervalRef) clearInterval(intervalRef?.current)
            if(timeOutRef) clearTimeout(timeOutRef?.current)
        }
    },[])
    const IsPC = ()=> {
        let width = document.documentElement.clientWidth
        console.log(width, props.location.pathname.split('/')[1])
        if(width > 850 && props.location.pathname.split('/')[1] == 'H5Login'){
            window.location.href = '/login'
        }
    }

    const [isData, setIsData] = useState(true)
    const [loadingText, setLoadingText] = useState('')
    const [isForget, changeIsForget] = useState(false)
    const [isBlogger, changeBlogger] = useState(0)
    const [phone, setPhone] = useState('')
    const [isShowM, changeShowM] = useState(false)
    const [phoneType, changePhoneType] = useState('86')
    const [password, setPassword] = useState('')
    const [isCheckedR, changeR] = useState(true)
    const [isEmail, changeIsEmail] = useState(false)
    const [isEmailLogin, changeIsEmailLogin] = useState(true)
    const [isLogin, changeIsLogin] = useState(true)
    const [isVerification, setIsVerification] = useState(false)
    const [name, setName] = useState('')
    const [phoneBind, setPhoneBind] = useState('')
    const [email, setEmail] = useState('')
    const [qrCode, setQrCode] = useState('')
    const [isFly, setIsFly] = useState(false)

    useEffect(()=>{
        setPassword('')
        setCode('')
        if(isLogin || isBlogger==1) {
            if(props.location.state?.isBind){
                setIsBind(true)
            }else{
                setIsBind(false)
            }
            changeIsForget(false)
            if(isBlogger==1 && !isEmailLogin){
                clearInterval(timeOutRef?.current)
                getQrCode()
            }
        }
        if(!isLogin) changeIsForget(false)
        if(isBlogger != 1) clearTimeout(timeOutRef?.current)
    },[isLogin, isBlogger])
    useEffect(()=>{
        clearTimeout(timeOutRef?.current)
        if(isBlogger == 1 && !isEmailLogin){
            getQrCode()
        }
    },[isEmailLogin])

    const getQrCode = ()=>{
        clearInterval(timeOutRef?.current)
        setLoadingText('loading')
        Api.getQrCode().then(res=>{
            setLoadingText('')
            seIsNewCode(false)
            if(res.code == 200){
                setQrCode(res.data.scan_qrcode_url)
                let timeOut = setInterval(() => {
                    getCheckCode(res.data.token, res.data.client_ticket)
                }, 3000);
                timeOutRef.current = timeOut
            }
        })
    }
    const [users, setUser] = useState(null)
    useEffect(()=>{
        if(users) setIsSigning(true)
    },[users])
    const [isNewCode, seIsNewCode] = useState(false)
    const getCheckCode = (token, client_ticket)=>{
        Api.checkCode({token, client_ticket}).then(res=>{
            if(res.code == 200){
                clearTimeout(timeOutRef?.current)
                setLoadingText('')
                if(res.data.sign){
                    openNotificationWithIcon('success', '登录成功！')
                    localStorage.setItem("token", res.data.token)
                    store.dispatch({ type: 'token', value: res.data.token })
                    props.navigate('/index/bloggerRanking', {state: '/rangKing'})
                }else{
                    setUser(res.data.user)
                }
            }else if(res.code == 40008){
                clearTimeout(timeOutRef?.current)
                seIsNewCode(true)
            }else if(res.code == 40009 || res.code == 40010){
                // let timeOut = setTimeout(() => {
                //     console.log(isEmailLogin,isBlogger)
                //     if(!isEmailLogin && isBlogger)getCheckCode(token, client_ticket)
                // }, 1000);
                // timeOutRef.current = timeOut
                if(res.code == 40010){
                    setLoadingText('Scanned,loading')
                }
            }else{
                clearTimeout(timeOutRef?.current)
            }
        })
    }
    useEffect(()=>{
        setIsData(loadingText != '')
    },[loadingText])

    
    const changeForget = (isBoolLogin)=>{
        changeIsLogin(isBoolLogin)
        changeIsForget(false)
    }

    useEffect(()=>{
        setPassword('')
        setCode('')
    },[isVerification])

    const prefixSelector = (
        <Select style={{ width: 100 }} value={phoneType}
            getPopupContainer={() => document.getElementById('login')}
            onChange={(e)=>changePhoneType(e)}>
            <Option value="86">CN +86</Option>
        </Select>
    );
    
    const [codes, setCode] = useState('')
    const [isCode, getCodes] = useState(true)
    const [setIntervals, setIntervalMethod] = useState()
    let nums = 60
    const [time, setTime] = useState(nums)

    const openNotificationWithIcon = (type, message = '')=> {
        notification[type]({
          description: message, top: 100, duration: 3
        });
    };


    // 获取验证码
    let timeAll = nums
    const getCode = ()=>{
        if(isCode && phone!=''){
            getCodes(false)
            setTime(nums)
            Api.getCode(phone.trim()).then((res)=>{
                // console.log(res.data)
                if(res.code == 200){
                    openNotificationWithIcon('success', '发送成功！')
                    getTime()
                }else{
                    getCodes(true)
                }
            })
        }
    }
    // 获取验证码
    const getEmailCode = ()=>{
        if(isCode && email!=''){
            getCodes(false)
            setTime(nums)
            Api.getEmailCode({toEmail: email.trim()}).then((res)=>{
                // console.log(res.data)
                if(res.code == 200){
                    openNotificationWithIcon('success', '发送成功！')
                    getTime()
                }else{
                    getCodes(true)
                }
            })
        }
    }
    const getTime = (valT = -1)=>{
        let times = JSON.parse(JSON.stringify(time))
        let downTime = setInterval(()=>{
            if(valT == 0 || times == 0){
                getCodes(true)
                clearInterval(intervalRef.current)
                setTime(nums)
                timeAll = nums
            }else{
                timeAll -= 1
                setTime(valT > 0 ? valT -- : times --)
            }
        },1000)
        document.addEventListener('visibilitychange', checkBrowserChange);
        intervalRef.current = downTime
    }
    const checkBrowserChange = () => {
        if (!document.hidden) {
            // console.log(timeAll)
            clearInterval(intervalRef.current)
            getCodes(timeAll == nums)
            setTime(timeAll)
            if(timeAll < nums) getTime(timeAll)
        }
    }

    // 登录/注册
    const postLogin = ()=>{
        let obj = {
            authType: isBlogger == 0 ? (isLogin ? (isVerification ? 2 : 1) : 2) : (isLogin ? 3 : 4), phone: phone, userType: isBlogger==1 ? 'Blogger' : isBlogger==0?'Business' :'MCN',
            password: password, verifyCode: codes, email: email.trim(), registerType: 2
        }
        Api.login(obj).then((res)=>{
            // console.log(res.data)
            if(res.code == 200){
                localStorage.setItem("token", res.data)
                store.dispatch({ type: 'token', value: res.data })
                if(isBlogger==1){
                    getRedSign()
                }else if(isBlogger==0){
                    getSign()
                }else{
                    getMCNSing()
                }
            }
        })
    }
    const getSign = ()=>{
        Api.getSign().then((res)=>{
            // console.log(res.data)
            if(res.code == 200){
                if(res.data.sign){
                    openNotificationWithIcon('success', '登录成功！')
                    props.navigate('/index/bloggerRanking', {state: '/rangKing'})
                }else{
                    onInfoChange('uid', res.data.user?.uid)
                    setIsBind(true)
                }
            }
        })
    }
    const getRedSign = ()=>{
        Api.getRedSign().then((res)=>{
            // console.log(res.data)
            if(res.code == 200){
                if(res.data.sign){
                    openNotificationWithIcon('success', '登录成功！')
                    props.navigate('/index/bloggerRanking', {state: '/rangKing'})
                }else{
                    setUser(res.data.user)
                }
            }
        })
    }
    const getMCNSing = ()=>{
        Api.getMCNSign().then((res)=>{
            // console.log(res.data)
            if(res.code == 200){
                store.dispatch({ type: 'MCN', value: res.data })
                openNotificationWithIcon('success', '登录成功！')
                props.navigate('/index/mcnMySensation', {state: '/mcnMySensation'})
            }else if(res.code == 40021){
                setIsMCNSign(true)
            }
        })
    }
    const resetPassword = ()=>{
        let obj = {
            phone: phone,
            password: password, verifyCode: codes
        }
        Api.resetPassword(obj).then((res)=>{
            // console.log(res.data)
            if(res.code == 200){
                openNotificationWithIcon('success', '修改成功！')
                changeIsLogin(true)
                setPassword('')
                setTimeout(()=>{
                    window.location.reload()
                },300)
            }
        })
    }
    const resetEmailPassword = ()=>{
        let obj = {
            email: email.trim(),
            password: password, verifyCode: codes
        }
        Api.resetEmailPassword(obj).then((res)=>{
            // console.log(res.data)
            if(res.code == 200){
                openNotificationWithIcon('success', '修改成功！')
                changeIsLogin(true)
                setPassword('')
                setTimeout(()=>{
                    window.location.reload()
                },300)
            }
        })
    }

    const infoSub = ()=>{
        let obj = infos
        obj.market = obj.market.length == 0 ? '' : obj.market.toString().split(',').join(',')
        // console.log(obj)
        Api.infoSub(obj).then((res)=>{
            // console.log(res.data)
            if(res.code == 200){
                openNotificationWithIcon('success', '提交成功！')
                setIsFly(true)
            }
        })
    }

    const isSub = ()=>{
        return name==''||phoneBind.length!=11||email.indexOf('@') < 0
    }
    const isSubLR = ()=>{
        return isLogin?(phone.length!=11||password.length<6):
            ((phone.length!=11||codes.length!=6||password.length<6||!isCheckedR ||password.length>16))
    }
    const isSubY = ()=>{
        return phone.length!=11||codes.length!=6
    }
    const isSubR = ()=>{
        return phone.length!=11||password.length<6||codes.length!=6||password.length>16
    }
    const isSubER = ()=>{
        return email.indexOf('@') < 0||password.length<6||codes.length!=6||password.length>16
    }

    const isBSubY = ()=>{
        return email.indexOf('@') < 0||codes.length!=6
    }
    const isBSub = ()=>{
        return isLogin?(email.indexOf('@') < 0||password.length<6):
            (email.indexOf('@') < 0||password.length<6||codes.length!=6||!isCheckedR || password.length>16)
    }


    const [optionList, setOptionList] = useState([])
    const [marketList, setMarketList] = useState([])
    useEffect(()=>{
        if(isBind){
            // Api.searchIndustry().then(res => {
            //     if (res.code === 200) {
            //         setOptionList(res.data)
            //     }
            // })
            Api.marketSearch().then(res => {
                if (res.code === 200) {
                    setMarketList(res.data)
                }
            })
            Api.getCargo().then(res=>{
                if(res.code == 200){
                    let all = res.data
                    setOptionList(all)
                }
            })
        }
    },[isBind])
    const [infos, setInfos] = useState({
        name:'', industryType: undefined, market: [], linkman:'', phone:'', uid: ''
    })
    const onInfoChange = (key, val)=>{
        let t = JSON.parse(JSON.stringify(infos))
        t[key] = val
        setInfos(t)
    }
    const isInfoSub = ()=>{
        return infos.name!=''&&infos.industryType!=undefined&& infos.market.length > 0&& infos.linkman!=''&&infos.phone.length==11
    }

    const user = (e)=>{
        e.stopPropagation()
        window.open('/userAgreement')
    }
    const privacy = (e)=>{
        e.stopPropagation()
        window.open('/privacyAgreement')
    }


    const [isMCNSign, setIsMCNSign] = useState(false)
    const [isSigning, setIsSigning] = useState(false)
    const closeModel = ()=>{
        setIsSigning(false)
        setIsMCNSign(false)
    }

    return <div style={{width:'100%', paddingBottom:'150px', paddingTop:'130px',
        backgroundImage:'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/loginH5Back.png)',
        backgroundSize:'100% 100%',
        }}>
        <div style={{width:'100%', boxShadow: '0px 4px 8px 0px rgba(13,4,9,0.08)', position:'fixed', top:0,left:0, zIndex:11}}>
            <img style={{width:'100%'}} src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/loginH5.png" />
        </div>
        {isBind ? <div style={{width:'100%', padding:'40px 40px'}}>
            <h2 style={{color:'#E3E3E3', fontSize:'28px', fontWeight:'bold', lineHeight:'28px', marginBottom:'12px',
                letterSpacing:'2px'}}>HELLO</h2>
            <h1 style={{color:'#333333', fontSize:'34px', fontWeight: 1750, lineHeight:'34px', letterSpacing:'4px'}}>
                {textJson['请完善信息']}
            </h1>
            <div style={{width:'44px', height:'4px', backgroundColor:'#F7385B'}}></div>
            <div id='login' style={{marginTop:'30px'}}>
                <Form name="basic" autoComplete="off">
                    <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginBottom:'5px'}}>
                        {textJson['企业名称']}
                    </div>
                    <Form.Item name="name" label="" rules={[ { required: true }]} >
                        <div id='phones'>
                            <Input onChange={e=>onInfoChange('name', e.target.value)} placeholder={textJson['请输入企业名称（请保证您填写的信息与营业执照信息一致）']} />
                        </div>
                    </Form.Item>
                    <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginBottom:'5px'}}>
                        {textJson['主营行业类型']}
                    </div>
                    <div id='guojia'>
                        <Select
                            size='large' showSearch
                            style={{ width: '100%', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px', backgroundColor:'#FFF' }}
                            placeholder={<span style={{fontSize:'14px'}}>{textJson['请选择主营行业类型']}</span>} allowClear={true}
                            onChange={(value)=>onInfoChange('industryType', value)} value={infos.industryType}
                            optionLabelProp="label" getPopupContainer={() => document.getElementById('guojia')}
                        >
                            {
                                optionList?.map((item,index)=>{
                                    return <Option key={index} id={item.id} value={item.goodsEnglishName} label={item.goodsEnglishName}>
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            {item.goodsEnglishName}({item.goodsName})
                                        </div>
                                    </Option>
                                })
                            }
                        </Select>
                    </div>
                    <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginBottom:'5px', marginTop:'23px'}}>
                        {textJson['主要市场']}
                    </div>
                    <div id='flei'>
                        <Select
                            mode="multiple" size='large' maxTagCount={3} disabled={false}
                            style={{ width: '100%', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px', backgroundColor:'#FFF' }}
                            placeholder={<span style={{fontSize:'14px'}}>{textJson['请选择主营市场，可多选']}</span>} allowClear={true} showArrow={true}
                            onChange={(value)=>onInfoChange('market', value)} value={infos.market}
                            optionLabelProp="label" getPopupContainer={() => document.getElementById('flei')}
                        >
                            {
                                marketList?.length > 0 ? marketList?.map((item,index)=>{
                                    return <Option key={index} id={item.id} value={item.marketName} label={item.marketName}>
                                        <div style={{textAlign:'left'}}>
                                            {item.marketName}
                                        </div>
                                    </Option>
                                }):''
                            }
                        </Select>
                    </div>
                    <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginBottom:'5px', marginTop:'23px'}}>
                        {textJson['企业联系人']}
                    </div>
                    <Form.Item name="linkman" label="" rules={[ { required: true }]} >
                        <div id='phones'>
                            <Input onChange={e=>onInfoChange('linkman', e.target.value)} placeholder={textJson['请输入企业联系人的姓名']} />
                        </div>
                    </Form.Item>
                    <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginBottom:'5px'}}>
                        {intl.get('Login').Phone}
                    </div>
                    <Form.Item name="phone" label="" rules={[ { required: true,
                        validator: (_, value) =>{
                            if(value.length == 11) {
                                return Promise.resolve()
                            }else{
                                return Promise.reject(intl.get('Login').PhoneV)
                            }
                        }
                        } ]} >
                        <div id='phones'>
                            <Input onChange={e=>onInfoChange('phone', e.target.value)} placeholder={intl.get('Login').PhoneT} />
                        </div>
                    </Form.Item>
                    <Button className='sub' onClick={()=>infoSub()} disabled={!isInfoSub()} style={{ width:'100%', height:'45px', borderRadius:'4px', border:0,
                        background: !isInfoSub()?'#EEEEEE':'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:500, letterSpacing:'2px',
                        color:'#FFF'}}>{textJson['提交信息']}
                    </Button>
                </Form>
            </div>
        </div>
        : <div style={{width:'100%', padding:'40px 40px'}}>
            <h2 style={{color:'#E3E3E3', fontSize:'28px', fontWeight:'bold', lineHeight:'28px', marginBottom:'12px',
                letterSpacing:'2px'}}>HELLO</h2>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <h1 style={{color:'#333333', fontSize:'34px', fontWeight: 750, lineHeight:'34px', letterSpacing:'4px'}}>
                    {isForget?intl.get('Login').ChangePassword:isLogin?intl.get('Login').WelcomeToLogin:intl.get('Login').WelcomeToRegister}
                </h1>
                <div style={{textAlign:'center', marginTop:'7px', color:'#888888', fontWeight:500}}>
                    {isLogin?intl.get('Login').NoAccount:intl.get('Login').ExistingAccount}
                    <span style={{color:'#F7385B', cursor:'pointer'}}
                    onClick={()=>changeIsLogin(!isLogin)}>{isLogin?intl.get('Login').RegisterNow:intl.get('Login').LogInNow}{'>'}</span>
                </div>
            </div>
            <div style={{width:'44px', height:'4px', backgroundColor:'#F7385B'}}></div>
            {!isForget ? 
                // 商家
                isBlogger == 0 ? <div id='login' style={{marginTop:'40px'}}>
                    <Form name="basic" autoComplete="off">
                        <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginBottom:'5px'}}>
                            {intl.get('Login').Phone}
                        </div>
                        <Form.Item name="phone" label="" rules={[ { required: true,
                            validator: (_, value) =>{
                                if(value.length == 11) {
                                    return Promise.resolve()
                                }else{
                                    return Promise.reject(intl.get('Login').PhoneV)
                                }
                            }
                            } ]} >
                            <div id='phones'>
                                <Input onChange={e=>setPhone(e.target.value)} addonBefore={prefixSelector} placeholder={intl.get('Login').PhoneT} />
                            </div>
                        </Form.Item>
                        {isVerification || !isLogin ? <div>
                            <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginTop:'5px', marginBottom:'5px'}}>
                                {intl.get('Login').PhoneVerificationCode}
                            </div>
                            <Form.Item label="" name="code" rules={[ { required: true, 
                                validator: (_, value) =>{
                                    if(value.length ==6 ) {
                                        return Promise.resolve()
                                    }else{
                                        return Promise.reject(intl.get('Login').VerificationCodeV)
                                    }
                                }
                                } ]} >
                                <div>
                                    <Input onChange={(e)=>{setCode(e.target.value)}} value={codes} placeholder={intl.get('Login').PhoneVerificationCodeT} 
                                        style={{height:'45px', paddingRight:'130px' }}/>
                                    <section onClick={getCode} style={{position:'absolute', top:'1px', right:'1px', width:'130px', height:'45px', 
                                        textAlign:'center', cursor:'pointer', color: isCode?'#333333':'#F7385B', 
                                        lineHeight:'45px', fontSize:'14px', letterSpacing:'0.05px', 
                                        }}>{isCode?intl.get('Login').SendVerificationCode: time+'s后可重新发送'}</section>
                                </div>
                            </Form.Item>
                        </div>:''}
                        {!isVerification || !isLogin ? <div>
                            <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginTop:'5px', marginBottom:'5px'}}>{intl.get('Login').Password}</div>
                            <Form.Item label="" name="password" rules={[ { required: true, 
                                validator: (_, value) =>{
                                    if(value.length >= 6 && value.length<=16) {
                                        return Promise.resolve()
                                    }else{
                                        return Promise.reject(intl.get('Login').PasswordV)
                                    }
                                }
                                } ]} >
                                <div>
                                    <Input.Password onChange={(e)=>{setPassword(e.target.value)}} value={password} placeholder={intl.get('Login').PasswordT} style={{height:'45px' }}
                                        iconRender={visible => (visible ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/kejian.png'} alt="" 
                                        style={{width:'18px', cursor:'pointer'}}/> : <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/kejians.png'} alt="" 
                                        style={{width:'18px', cursor:'pointer'}}/>)}/>
                                </div>
                            </Form.Item>
                        </div>:''}
                        {isLogin ? <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', 
                            color:'#888888'}}>
                            <span style={{cursor:'pointer', letterSpacing:'1px'}} onClick={()=>setIsVerification(!isVerification)}>
                                {isVerification?intl.get('Login').PhonePasswordLogin:intl.get('Login').PhoneCodeLogin}
                            </span>
                            <span onClick={()=>changeIsForget(true)} style={{cursor:'pointer'}}>{intl.get('Login').ForgetPassword}</span>
                        </div> :
                        <div style={{marginTop:'25px', fontWeight:500, letterSpacing:'0px'}}>
                            <Checkbox checked={isCheckedR} onClick={()=>changeR(!isCheckedR)}>
                                <span style={{fontSize:'12px', color:'#888888'}}>{intl.get('Login').Read}
                                <span style={{color:'#F7385B'}} onClick={(e)=>user(e)}>
                                    《{intl.get('Login').agreement}》</span>{intl.get('Login').and}<span style={{color:'#F7385B'}} 
                                    onClick={(e)=>privacy(e)}>
                                        《{intl.get('Login').PrivacyPolicy}》</span>
                                </span>
                            </Checkbox>
                        </div>}
                        <div style={{width:'100%', height:'80px', background:'#FFF', position:'fixed', bottom:0, left:0,
                        padding:'0 40px', display:'flex', alignItems:'center', justifyContent:'center',
                        boxShadow:' 0px 4px 8px 0px rgba(13,4,9,0.08)'}}>
                            {isVerification?<Button className='sub' onClick={()=>postLogin()} disabled={isSubY()} style={{ width:'100%', height:'55px', borderRadius:'34px', border:0,
                                background: isSubY()?'#EEEEEE':'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:500, letterSpacing:'2px', fontSize:'18px',
                                color:'#FFF'}}>{isLogin?intl.get('Login').ConfirmLogin: intl.get('Login').ConfirmRegistration}
                            </Button> :
                            <Button className='sub' onClick={()=>postLogin()} disabled={isSubLR()} style={{ width:'100%', height:'55px', borderRadius:'34px', border:0,
                                background: isSubLR()?'#EEEEEE':'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:500, letterSpacing:'2px', fontSize:'18px',
                                color:'#FFF'}}>{isLogin?intl.get('Login').ConfirmLogin: intl.get('Login').ConfirmRegistration}
                            </Button>}
                        </div>
                    </Form>
                </div> : ''
            :
            // 修改密码
            <div id='login' style={{marginTop:'30px'}}>
                {isBlogger == 0 ? <Form name="basic" autoComplete="off">
                    <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginBottom:'5px'}}>
                        {intl.get('Login').Phone}
                    </div>
                    <Form.Item name="phone" label="" rules={[ { required: true,
                        validator: (_, value) =>{
                            if(value.length == 11) {
                                return Promise.resolve()
                            }else{
                                return Promise.reject(intl.get('Login').PhoneV)
                            }
                        }
                        } ]} >
                        <div id='phones'>
                            <Input onChange={e=>setPhone(e.target.value)} addonBefore={prefixSelector} placeholder={intl.get('Login').PhoneT} />
                        </div>
                    </Form.Item>
                    <div>
                        <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginTop:'5px', marginBottom:'5px'}}>
                            {isEmail?intl.get('Login').EmailVerificationCode:intl.get('Login').PhoneVerificationCode}
                        </div>
                        
                        <Form.Item label="" name="code" rules={[ { required: true, 
                            validator: (_, value) =>{
                                if(value.length ==6 ) {
                                    return Promise.resolve()
                                }else{
                                    return Promise.reject(intl.get('Login').VerificationCodeV)
                                }
                            }
                            } ]} >
                            <div>
                                <Input onChange={(e)=>{setCode(e.target.value)}} value={codes} placeholder={intl.get('Login').PhoneVerificationCodeT} 
                                    style={{height:'45px', paddingRight:'130px' }}/>
                                <section onClick={getCode} style={{position:'absolute', top:'1px', right:'1px', width:'130px', height:'45px', 
                                    textAlign:'center', cursor:'pointer', color: isCode?'#333333':'#F7385B', 
                                    lineHeight:'45px', fontSize:'14px', letterSpacing:'0.05px', 
                                    }}>{isCode?intl.get('Login').SendVerificationCode: time+'s后可重新发送'}</section>
                            </div>
                        </Form.Item>
                    </div>
                    <div>
                        <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginTop:'5px', marginBottom:'5px'}}>{intl.get('Login').NewPassword}</div>
                        <Form.Item label="" name="password" rules={[ { required: true, 
                            validator: (_, value) =>{
                                if(value.length >= 6 && value.length<=16) {
                                    return Promise.resolve()
                                }else{
                                    return Promise.reject(intl.get('Login').PasswordV)
                                }
                            }
                            } ]} >
                            <div>
                                <Input.Password onChange={(e)=>{setPassword(e.target.value)}} value={password} placeholder={intl.get('Login').PasswordT} style={{height:'45px' }}
                                    iconRender={visible => (visible ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/kejian.png'} alt="" 
                                    style={{width:'18px', cursor:'pointer'}}/> : <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/kejians.png'} alt="" 
                                    style={{width:'18px', cursor:'pointer'}}/>)}/>
                            </div>
                        </Form.Item>
                    </div>
                    <Button className='sub' onClick={()=>resetPassword()} disabled={isSubR()} style={{ width:'100%', height:'45px', borderRadius:'4px', border:0, marginTop:'14px',
                        background: isSubR()?'#EEEEEE':'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:500, letterSpacing:'2px',
                        color:'#FFF'}}>{intl.get('Login').ConfirmModification}
                    </Button>
                    <div style={{textAlign:'center', marginTop:'27px', color:'#888888', fontWeight:500}}>
                        <span onClick={()=>changeForget(true)} style={{cursor:'pointer', color:'rgb(247, 56, 91)'}}>{intl.get('Header').Login}</span>
                         ｜ {intl.get('Login').NoAccount}
                        <span style={{color:'#F7385B', cursor:'pointer'}}
                        onClick={()=>changeForget(false)}>{intl.get('Login').RegisterNow}</span>
                    </div>
                </Form> :
                // 
                <Form name="basic" autoComplete="off">
                    <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginBottom:'5px'}}>
                        {intl.get('Login').MailboxNumber}
                    </div>
                    <Form.Item name="email" label="" >
                        <div>
                            <Input onChange={(e)=>{setEmail(e.target.value)}} style={{height:'45px' }} placeholder={intl.get('Login').MailboxNumberT} />
                        </div>
                    </Form.Item>
                    <div>
                        <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginTop:'5px', marginBottom:'5px'}}>
                            {intl.get('Login').EmailVerificationCode}
                        </div>
                        
                        <Form.Item label="" name="code" rules={[ { required: true, 
                            validator: (_, value) =>{
                                if(value.length ==6 ) {
                                    return Promise.resolve()
                                }else{
                                    return Promise.reject(intl.get('Login').VerificationCodeV)
                                }
                            }
                            } ]} >
                            <div>
                                <Input onChange={(e)=>{setCode(e.target.value)}} value={codes} placeholder={intl.get('Login').EmailVerificationCodeT} 
                                    style={{height:'45px', paddingRight:'130px' }}/>
                                <section onClick={getEmailCode} style={{position:'absolute', top:'1px', right:'1px', width:'130px', height:'45px', 
                                    textAlign:'center', cursor:'pointer', color: isCode?'#333333':'#F7385B', 
                                    lineHeight:'45px', fontSize:'14px', letterSpacing:'0.05px', 
                                    }}>{isCode?intl.get('Login').SendVerificationCode: time+'s后可重新发送'}</section>
                            </div>
                        </Form.Item>
                    </div>
                    <div>
                        <div style={{color:'#888888', fontSize:'16px', fontWeight:500, marginTop:'5px', marginBottom:'5px'}}>{intl.get('Login').NewPassword}</div>
                        <Form.Item label="" name="password" rules={[ { required: true, 
                            validator: (_, value) =>{
                                if(value.length >= 6 && value.length<=16) {
                                    return Promise.resolve()
                                }else{
                                    return Promise.reject(intl.get('Login').PasswordV)
                                }
                            }
                            } ]} >
                            <div>
                                <Input.Password onChange={(e)=>{setPassword(e.target.value)}} value={password} placeholder={intl.get('Login').PasswordT} style={{height:'45px' }}
                                    iconRender={visible => (visible ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/kejian.png'} alt="" 
                                    style={{width:'18px', cursor:'pointer'}}/> : <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/kejians.png'} alt="" 
                                    style={{width:'18px', cursor:'pointer'}}/>)}/>
                            </div>
                        </Form.Item>
                    </div>
                    <Button className='sub' onClick={()=>resetEmailPassword()} disabled={isSubER()} style={{ width:'100%', height:'45px', borderRadius:'4px', border:0, marginTop:'14px',
                        background: isSubER()?'#EEEEEE':'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:500, letterSpacing:'2px',
                        color:'#FFF'}}>{intl.get('Login').ConfirmModification}
                    </Button>
                    <div style={{textAlign:'center', marginTop:'27px', color:'#888888', fontWeight:500}}>
                        <span onClick={()=>changeForget(true)} style={{cursor:'pointer', color:'rgb(247, 56, 91)'}}>{intl.get('Header').Login}</span>
                        ｜ {intl.get('Login').NoAccount}
                        <span style={{color:'#F7385B', cursor:'pointer'}}
                        onClick={()=>changeForget(false)}>{intl.get('Login').RegisterNow}</span>
                    </div>
                </Form>
                }
            </div>}
        </div>}


        {isSigning ? <BloggerSigning closeModel={closeModel} users={users} isEmailLogin={isEmailLogin} /> : ''}
        {isMCNSign ? <McnSigning closeModel={closeModel} /> : ''}

    </div>
}
export default withRouter(Login);