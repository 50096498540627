import React, { useState, useEffect } from 'react'
import { Input, Select, DatePicker, Radio, Modal, Image, message, Upload } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom'
import * as Api from '../../../service/https'
import { baseUrl } from '../../../service/request'
import withRouter from '../../../utils/withRouter'
import intl from 'react-intl-universal';
import moment from 'moment';
import '../../../asset/css/EnterpriseCertification.scss'

const { Option } = Select;

const EnterpriseCertification = (props) => {
    const [textJson, setTextJson] = useState(intl.get('EnterpriseCertification'))
    const navigate = useNavigate();
    const location = useLocation();
    const [params, setParams] = useState({})
    const [videoList, setVideoList] = useState([])
    const [photoList, setPhotoList] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [status, setStatus] = useState(-1)
    const [optionList, setOptionList] = useState([])
    const [marketList, setMarketList] = useState([])

    useEffect(() => {
        searchEnterprise()
        Api.searchIndustry().then(res => {
            if (res.code === 200) {
                setOptionList(res.data)
            }
        })
        Api.marketSearch().then(res => {
            if (res.code === 200) {
                setMarketList(res.data)
            }
        })
    }, [])

    const searchEnterprise = e => {
        Api.searchEnterprise().then(res => {
            if (res.code === 200) {
                if (res.data) {
                    // res.data.examineType = 3
                    let t = JSON.parse(JSON.stringify(res.data))
                    if (t?.market) {
                        t.market = t.market.split(',')
                    }
                    setParams(t || {})
                    let v = []
                    let p = []
                    res.data.enterpriseSourceList?.map((item, index) => {
                        let z = {
                            sourceType: item.sourceType,
                            sourceUrl: item.sourceUrl
                        }
                        if (item.sourceType === 1) {
                            p.push(z)
                        } else {
                            v.push(z)
                        }
                    })
                    setPhotoList(p)
                    setVideoList(v)
                    if (res.data.licenseDate === textJson['长期有效']) {
                        setLicenseStatus(2)
                    }
                    if (res.data.examineType) setStatus(res.data.examineType)
                }
            }
        })
    }

    const uploadProps = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/enterprise/logo/upload',
        showUploadList: false,
        headers: {
            'x-token': localStorage.getItem('token')
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error(textJson['请上传正确的图片！']);
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error(textJson['图片过大，请上传10MB以下的图片!']);
            }
            return isJpgOrPng && isLt2M;
        },
        onChange(info) {
            if (info.file.status === 'done') {
                setTimeout(() => {
                    let t = JSON.parse(JSON.stringify(params))
                    t.logo = info.file.response.data
                    setParams(t)
                }, 1000)
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const uploadProps4 = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/enterprise/license/upload',
        showUploadList: false,
        headers: {
            'x-token': localStorage.getItem('token')
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error(textJson['请上传正确的图片！']);
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error(textJson['图片过大，请上传10MB以下的图片!']);
            }
            return isJpgOrPng && isLt2M;
        },
        onChange(info) {
            if (info.file.status === 'done') {
                setTimeout(() => {
                    let t = JSON.parse(JSON.stringify(params))
                    t.businessLicense = info.file.response.data
                    setParams(t)
                }, 1000)
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const uploadProps2 = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/enterprise/img/upload',
        showUploadList: false,
        headers: {
            'x-token': localStorage.getItem('token')
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error(textJson['请上传正确的图片！']);
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error(textJson['图片过大，请上传10MB以下的图片!']);
            }
            return isJpgOrPng && isLt2M;
        },
        onChange(info) {
            if (info.file.status === 'done') {
                setTimeout(() => {
                    let t = JSON.parse(JSON.stringify(photoList))
                    t.push({
                        sourceUrl: info.file.response.data,
                        sourceType: 1
                    })
                    setPhotoList(t)
                }, 1000)
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };
    const uploadProps3 = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/enterprise/video/upload',
        showUploadList: false,
        headers: {
            'x-token': localStorage.getItem('token')
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'video/mp4'
            if (!isJpgOrPng) {
                message.error(textJson['请上传正确的视频！']);
            }
            const isLt2M = file.size / 1024 / 1024 < 50;
            if (!isLt2M) {
                message.error(textJson['视频过大，请上传50MB以下的视频!']);
            }
            return isJpgOrPng && isLt2M;
        },
        onChange(info) {
            if (info.file.status === 'done') {
                let t = JSON.parse(JSON.stringify(videoList))
                t.push({
                    sourceUrl: info.file.response.data,
                    sourceType: 2
                })
                setTimeout(() => {
                    setVideoList(t)
                    message.success(`上传成功！`);
                }, 1000);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const [licenseStatus, setLicenseStatus] = useState(1)

    const handleChange = e => {
        onChange(e === 1 ? '' : textJson['长期有效'], 'licenseDate')
        setLicenseStatus(e)
    }

    const licenseDateChange = e => {
        if (licenseStatus === 1)
            onChange(e ? moment(e).format('YYYY-MM-DD') : '', 'licenseDate')
    }

    const onChange = (e, id) => {
        let t = JSON.parse(JSON.stringify(params))
        t[id] = e
        setParams(t)
    }

    const submit = e => {
        let data = JSON.parse(JSON.stringify(params))
        let t = videoList.concat(photoList)
        data.enterpriseSourceList = t
        data.examineType = params.examineType || 1
        data.market = data.market.join(',')
        console.log(data)
        if (data.name && data.logo && data.industryType && data.market && data.linkman && data.phone && data.businessLicense && data.creditCode && data.addressArea && data.specificAddress && data.creationDate && data.licenseDate) {
            Api.commitEnterprise(data).then(res => {
                if (res.code === 200) {
                    setIsModalVisible(true)
                    searchEnterprise()
                }
            })
        } else {
            message.warn(`有必填项未填，请检查！`);
        }
    }

    const reSubmit = e => {
        let t = {}
        t['id'] = params.id
        t['examineNum'] = params.examineNum
        t['examineType'] = params.examineType
        t['creationDate'] = ''
        t['licenseDate'] = ''
        setLicenseStatus(1)
        setParams(t)
        setVideoList([])
        setPhotoList([])
        setStatus(-1)
    }

    return (
        <div id='EnterpriseCertification'>
            <nav style={{ width: '100%', position: 'relative', fontWeight: 500 }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt=""
                    style={{ width: '100%' }} />
                <div style={{
                    width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt=""
                        style={{ width: '190px' }} />
                    <div style={{
                        width: '10px', height: '10px', backgroundColor: 'black', borderRadius: '5px',
                        margin: '0 20px'
                    }}></div>
                    <span style={{ fontSize: '20px' }}>{textJson["企业认证"]}</span>
                </div>
            </nav>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0 10px 0' }}>
                {
                    status === 1 ?
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseCertification/newshz.png'} alt="" style={{ marginRight: '18px' }} /> :
                        status === 2 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseCertification/newshcg.png'} alt="" style={{ marginRight: '18px' }} /> :
                            status === 3 ?
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseCertification/newshsb.png'} alt="" style={{ marginRight: '18px' }} /> :
                                ''
                }
                {
                    status === 1 ?
                        <div style={{ color: '#666666' }}>{textJson["感谢您的企业认证申请，我们预计将在1天内完成资料的审核，请耐心等待......"]}</div> :
                        status === 2 ?
                            <div style={{ color: '#666666' }}>{textJson["恭喜您的企业已认证成功！"]}</div> :
                            status === 3 ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ marginRight: '22px', color: '#666666' }}>{textJson["抱歉，您的资料未通过审核，请重新填写申请"]}</div>
                                    <div style={{
                                        width: '183px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                        display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                                        borderRadius: '4px', cursor: 'pointer', marginRight: '10px'
                                    }} onClick={e => reSubmit()}>
                                        {textJson["重新填写"]}
                                    </div>
                                </div> :
                                ''
                }

            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '60px', }}>
                <div style={{ width: '49%', marginRight: '18px', backgroundColor: '#fff' }}>
                    <div style={{ backgroundColor: '#FFEEF1', paddingLeft: '40px', lineHeight: '60px', fontSize: '18px', fontWeight: 'bold' }}>
                        <span style={{ width: '4px', height: '20px', backgroundColor: '#F7385B', marginRight: '13px' }}>&nbsp;</span>{textJson["企业信息"]}
                    </div>
                    <div style={{ padding: '30px 40px' }}>
                        <div style={{ marginBottom: '30px' }}>
                            <div className='title'>{textJson["企业名称"]}</div>
                            <Input disabled={status !== -1} onChange={e => onChange(e.target.value, 'name')} value={params.name} placeholder={textJson["请输入企业名称（请保证您填写的信息与营业执照信息一致）"]} style={{ height: 48 }} />
                        </div>
                        <div style={{ marginBottom: '30px' }}>
                            <div className='title'>{textJson["企业Logo"]}</div>
                            <div style={{ paddingLeft: '16px', display: 'flex', alignItems: 'center' }}>
                                {
                                    params.logo ?
                                        <div style={{ position: 'relative' }}>
                                            <div style={{ width: 16, height: 16, fontSize: '12px', cursor: 'pointer', lineHeight: '16px', textAlign: 'center', backgroundColor: '#FFEEF1', color: '#F7385B', position: 'absolute', right: -8, top: -8, borderRadius: '16px', zIndex: '1', boxShadow: '0px 0px 6px 0px #A9A9A9', display: status ? 'none' : '' }} onClick={e => {
                                                let t = JSON.parse(JSON.stringify(params))
                                                t.logo = ''
                                                setParams(t)
                                            }}><CloseOutlined /></div>
                                            <Image src={params.logo} width={130} />
                                        </div>
                                        :
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Upload {...uploadProps}>
                                                <div style={{
                                                    width: '114px', height: '38px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                                    display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                                                    borderRadius: '4px', cursor: 'pointer', marginRight: '10px'
                                                }}>
                                                    {textJson["上传LOGO"]}
                                                </div>
                                            </Upload>
                                            <div style={{ fontSize: '12px', fontWeight: "#B3B3B3" }}>
                                                {textJson["（LOGO的尺寸为1:1，大小不超过1M，格式为JPG或PNG）"]}
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div style={{ marginBottom: '30px' }}>
                            <div className='title'>{textJson["主营行业类型"]}</div>
                            <Select
                                disabled={status !== -1}
                                style={{ width: '100%' }}
                                value={params.industryType || undefined}
                                onChange={e => onChange(e, 'industryType')}
                                allowClear
                                showSearch
                                placeholder={textJson["请选择主营行业类型"]}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {optionList?.map((item, index) =>
                                    <Option key={index} value={item.name}>{item.name}</Option>
                                )}
                            </Select>
                        </div>
                        <div style={{ marginBottom: '30px' }}>
                            <div className='title'>{textJson["主要市场"]}</div>
                            <Select
                                mode="multiple"
                                disabled={status !== -1}
                                maxTagCount={3}
                                style={{ width: '100%' }}
                                placeholder={textJson['请选择主营市场，可多选']}
                                allowClear={true}
                                showArrow={true}
                                onChange={e => onChange(e, 'market')}
                                value={params.market}
                                optionLabelProp="label"
                            >
                                {
                                    marketList?.length > 0 ? marketList?.map((item, index) => {
                                        return <Option key={index} id={item.id} value={item.marketName} label={item.marketName}>
                                            <div style={{ textAlign: 'left' }}>
                                                {item.marketName}
                                            </div>
                                        </Option>
                                    }) : ''
                                }
                            </Select>
                        </div>
                        <div style={{ marginBottom: '30px' }}>
                            <div style={{ fontWeight: 'bold', paddingLeft: '16px', marginBottom: '10px' }}>{textJson["企业照片和视频"]}</div>
                            <div style={{ display: status === -1 ? 'flex' : 'none', paddingLeft: '16px' }}>
                                <Upload {...uploadProps2}>
                                    <div style={{
                                        width: '114px', height: '38px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                        display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                                        borderRadius: '4px', cursor: 'pointer', marginRight: '10px'
                                    }}>
                                        {textJson["上传照片"]}
                                    </div>
                                </Upload>
                                <Upload {...uploadProps3}>
                                    <div style={{
                                        width: '114px', height: '38px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                        display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                                        borderRadius: '4px', cursor: 'pointer'
                                    }}>
                                        {textJson["上传视频"]}
                                    </div>
                                </Upload>
                            </div>
                            <div style={{ display: 'flex', marginTop: '14px', paddingLeft: '16px' }} key={videoList.length + photoList.length}>
                                {videoList?.map((item, index) => (
                                    <div key={index} style={{ marginRight: '10px', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div style={{ width: 16, height: 16, fontSize: '12px', cursor: 'pointer', lineHeight: '16px', textAlign: 'center', backgroundColor: '#FFEEF1', color: '#F7385B', position: 'absolute', right: -8, top: -8, borderRadius: '16px', zIndex: '1', boxShadow: '0px 0px 6px 0px #A9A9A9', display: status === -1 ? '' : 'none' }} onClick={e => {
                                            let t = JSON.parse(JSON.stringify(videoList))
                                            t.splice(index, 1)
                                            setVideoList(t)
                                        }}><CloseOutlined /></div>
                                        <img style={{ width: 130, height: 180 }} src={item.sourceUrl + '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,m_fast'} alt="" />
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/player_red.png'} style={{ position: 'absolute' }} alt="" />
                                        {/* <div style={{ position: 'absolute', bottom: '12px', color: '#fff' }}>0:59</div> */}
                                    </div>
                                ))}
                                {photoList?.map((item, index) => (
                                    <div key={index} style={{ marginRight: '10px', position: 'relative' }}>
                                        <div style={{ width: 16, height: 16, fontSize: '12px', cursor: 'pointer', lineHeight: '16px', textAlign: 'center', backgroundColor: '#FFEEF1', color: '#F7385B', position: 'absolute', right: -8, top: -8, borderRadius: '16px', zIndex: '1', boxShadow: '0px 0px 6px 0px #A9A9A9', display: status === -1 ? '' : 'none' }} onClick={e => {
                                            let t = JSON.parse(JSON.stringify(photoList))
                                            t.splice(index, 1)
                                            setPhotoList(t)
                                        }}><CloseOutlined /></div>
                                        <img style={{ width: 130, height: 180 }} src={item.sourceUrl} alt="" />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ marginBottom: '30px' }}>
                            <div className='title'>{textJson["企业联系人姓名和电话"]}</div>
                            <Input disabled={status !== -1} onChange={e => onChange(e.target.value, 'linkman')} value={params.linkman} placeholder={textJson["请输入企业联系人的姓名"]} style={{ height: 48 }} />
                            <Input disabled={status !== -1} onChange={e => onChange(e.target.value, 'phone')} type="number" value={params.phone} placeholder={textJson["请输入联系人的电话"]} style={{ height: 48, marginTop: '10px' }} />
                        </div>
                    </div>
                </div>

                <div style={{ width: '49%', backgroundColor: '#fff' }}>
                    <div style={{ backgroundColor: '#FFEEF1', paddingLeft: '40px', lineHeight: '60px', fontSize: '18px', fontWeight: 'bold' }}>
                        <span style={{ width: '4px', height: '20px', backgroundColor: '#F7385B', marginRight: '13px' }}>&nbsp;</span>{textJson["资质认证"]}
                    </div>
                    <div style={{ padding: '30px 40px' }}>
                        <div style={{ marginBottom: '30px' }}>
                            <div className='title'>{textJson["企业营业执照"]}</div>
                            <div style={{ display: 'flex', paddingLeft: '16px' }}>
                                {
                                    params.businessLicense ?
                                        <div style={{ position: 'relative' }}>
                                            <div style={{ width: 16, height: 16, fontSize: '12px', cursor: 'pointer', lineHeight: '16px', textAlign: 'center', backgroundColor: '#FFEEF1', color: '#F7385B', position: 'absolute', right: -8, top: -8, borderRadius: '16px', zIndex: '1', boxShadow: '0px 0px 6px 0px #A9A9A9', display: status === -1 ? '' : 'none' }} onClick={e => {
                                                let t = JSON.parse(JSON.stringify(params))
                                                t.businessLicense = ''
                                                setParams(t)
                                            }}><CloseOutlined /></div>
                                            <Image src={params.businessLicense} width={270} />
                                        </div>
                                        :
                                        <div style={{ display: 'flex' }}>
                                            <Upload {...uploadProps4}>
                                                <div style={{
                                                    width: '114px', height: '38px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                                    display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                                                    borderRadius: '4px', cursor: 'pointer', marginRight: '10px'
                                                }}>
                                                    {textJson["上传营业执照"]}
                                                </div>
                                            </Upload>
                                            <div style={{ fontSize: '12px', color: '#B3B3B3' }}>
                                                {textJson["请确保图片大小小于10M，格式为JPG，PNG或PDF；最新的营业执照正本或副本的彩色照片或扫描件；内容清晰完整，确保证书的所有边界可见"]}
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div style={{ marginBottom: '30px' }}>
                            <div className='title'>{textJson["企业营业执照号码（统一社会信用代码）"]}</div>
                            <Input disabled={status !== -1} onChange={e => onChange(e.target.value, 'creditCode')} value={params.creditCode} placeholder={textJson["请输入企业统一社会信用代码（请保证您填写的信息与营业执照信息一致）"]} style={{ height: 48 }} />
                        </div>
                        <div style={{ marginBottom: '30px' }}>
                            <div className='title'>{textJson["企业所在地址"]}</div>
                            <Input disabled={status !== -1} onChange={e => onChange(e.target.value, 'addressArea')} value={params.addressArea} placeholder={textJson["请输入省-市-区"]} style={{ height: 48, marginTop: '10px' }} />
                            <Input disabled={status !== -1} onChange={e => onChange(e.target.value, 'specificAddress')} value={params.specificAddress} placeholder={textJson["请输入具体的街道门牌"]} style={{ height: 48, marginTop: '10px' }} />
                        </div>
                        <div style={{ marginBottom: '30px' }}>
                            <div className='title'>{textJson["企业注册日期"]}</div>
                            <DatePicker disabled={status !== -1} onChange={e => onChange(e ? moment(e).format('YYYY-MM-DD') : '', 'creationDate')} value={params.creationDate ? moment(params.creationDate) : ''} style={{ height: 48, lineHeight: 46, width: '100%' }} />
                        </div>
                        <div style={{ marginBottom: '30px' }}>
                            <div className='title'>{textJson["营业执照有效期"]}</div>
                            <Radio.Group disabled={status !== -1} onChange={e => handleChange(e.target.value)} value={licenseStatus} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center', width: '48%' }}>
                                    <Radio value={1}></Radio>
                                    <DatePicker disabled={status !== -1} onChange={licenseDateChange} value={params.licenseDate === textJson["长期有效"] ? '' : params.licenseDate ? moment(params.licenseDate) : ''} style={{ height: 48, lineHeight: 46, width: '100%' }} />
                                    {/* onChange(moment(e).format('YYYY-MM-DD'), 'licenseDate') */}
                                </div>
                                <div style={{ width: '48%' }}>
                                    <Radio value={2}>{textJson["长期有效"]}</Radio>
                                </div>
                            </Radio.Group>
                        </div>
                    </div>
                </div>
            </div>
            {
                status === 1 || status === 2 || status === 3 ?
                    ''
                    :
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '60px' }}>
                        <div style={{
                            width: '210px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                            display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                            borderRadius: '4px', cursor: 'pointer', marginRight: '10px'
                        }} onClick={e => submit()}>
                            {textJson["提交审核"]}
                        </div>
                        <div style={{ marginTop: '28px', color: '#666666', fontSize: '13px' }}>{textJson["请如实填写企业认证信息，BRANDGOGO保留核实公司信息并拒绝申请的权利"]}</div>
                    </div>
            }
            <Modal visible={isModalVisible} centered={true} footer={null} closable={false}
                getContainer={() => document.getElementById('EnterpriseCertification')}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '36px', width: '443px' }}>
                    <div style={{ fontWeight: '800', fontSize: '18px', marginBottom: '43px' }}>{textJson["提交成功"]}</div>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none.png'} style={{ marginBottom: '14px' }} alt="" />
                    <div style={{ width: '279px', textAlign: 'center', marginBottom: '38px' }}>
                        {textJson["感谢您的企业认证申请，我们预计将在1天内完成资料的审核，请耐心等待......"]}
                    </div>
                    <div style={{
                        width: '210px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                        display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                        borderRadius: '4px', cursor: 'pointer', marginRight: '10px'
                    }} onClick={e => { setIsModalVisible(false) }}>
                        {textJson["关闭"]}
                    </div>
                </div>
            </Modal>
            {/* <video width="320" height="240" controls>
                <source src="https://brandgogo.oss-cn-beijing.aliyuncs.com/enterprise/video5_5cc5b5f8345543dfab63fb5ef1d139d9" />
            </video> */}
        </div>
    )
}

export default withRouter(EnterpriseCertification);