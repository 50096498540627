import React, {useState, useEffect} from 'react'
import intl from 'react-intl-universal';
import {Modal, message, List, Divider} from 'antd';
import withRouter from '../../utils/withRouter'
import * as Api from '../../service/https'
import {getNiceWorkList} from "../../service/https";

const ExcellentWorks = (props) => {
    const [playVideoUrl,setPlayVideoUrl] = useState('')
    const [data,dataSet] = useState([])
    const [showVideo,setShowVideo] = useState(false)
    const [isPlay, setIsPlay] = useState(false)
    const onChange = (link) => {
        if(link && link!='') {
            setIsPlay(true)
        }else{
            setIsPlay(false)
        }
        // setMajorIndex(majorIndex)
    };
    const [purposeList,setPurposeList] = useState([
        {val: '商品销售', id:4,icon:''},
        {val: '品牌传播', id:2, icon:''},
        {val: '商品种草', id:3, icon:''},
        {val: '商品好评', id:5,icon:''},
        {val: '开箱视频', id:6,icon:''},
    ])
    const [mediaList,setMediaList] = useState([
        {val: 'TikTok', id:1,icon:''},
        {val: 'Youtube', id:2, icon:''},
        {val: 'Facebook', id:3, icon:''},
        {val: 'Instagram', id:4,icon:''},
    ])
    const getWorksList = () => {
        Api.getNiceWorkList({uid: props.uid}).then(res=>{
            if(res.code === 200){
                dataSet(res.data)
            }
        })
    }

    // 截取视频的第一帧
    const getVideoBase64 = url => {
        return new Promise(function (resolve) {
            let dataURL = '';
            let video = document.createElement("video");
            video.setAttribute('crossOrigin', 'anonymous');//处理跨域
            video.setAttribute('src', url);
            video.setAttribute('width', 400);
            video.setAttribute('height', 240);
            video.setAttribute('autoplay', 'none');
            video.addEventListener('canplaythrough', function () {
                let canvas = document.createElement("canvas"),
                    width = video.width, //canvas的尺寸和图片一样
                    height = video.height;
                canvas.width = width;
                canvas.height = height;
                canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
                dataURL = canvas.toDataURL('image/jpeg'); //转换为base64
                resolve(dataURL);
            });
        })
    }

    const getVideoTime = (event,index) =>{
       let arr = [...data]
        arr[index].duration = event.target.duration
        dataSet(arr)
    }

    const openVideoModal = (url) =>{
        setPlayVideoUrl(url)
        setShowVideo(!showVideo)
    }

    useEffect(()=>{
        getWorksList()
    },[])


    return <div id='ExcellentWorks' style={{width: '100%', backgroundColor: '#FFF', marginTop: '10px', textAlign: 'center', padding: '24px 22px'}}>
        {
            data.length > 0 ? <div className='flex'>
                {
                    data?.map((item, index) => {
                        return <div key={index} style={{width:'25%',border:'1px solid #EEEEEE',borderRadius:'4px',margin:'0 20px'}} onClick={()=>{openVideoModal(item.fileAddress)}}>
                            <div style={{height:360,position:'relative',borderRadius:'4px',overflow:'hidden',cursor:'pointer'}}>
                                <video style={{position:'absolute',left:'100%',width: '100%',height:'100%',objectFit:'cover',visibility:'hidden'}} src={item.fileAddress} onLoadedMetadata={(event)=>{getVideoTime(event,index)}}></video>
                                <img style={{width: '100%',height:'100%',objectFit:'cover'}} src={item.fileAddress + '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,m_fast'} />
                                <div style={{width: '100%',height:'100%',background:'rgba(0,0,0,.22)'}} className='pos-center'></div>
                                <img style={{width:38,height:38}} className='pos-center' src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/start.png" alt=""/>
                                <div style={{bottom:'19px',color:'#FFFFFF'}} className='pos-bot'>{intl.get('SensationExcellentWorks').Time}: {item?.duration ? Number(item.duration).toFixed(2) : 0}s</div>
                            </div>
                            <div style={{padding:'15px 13px',color:'#888888',fontSize:'12px',textAlign:'left',lineHeight:'20px'}}>
                                <div>{intl.get('SensationExcellentWorks').Type}：<span style={{color:'#333333'}}>{item?.cargoClassification?.goodsName}</span></div>
                                <div>{intl.get('SensationExcellentWorks').Purpose}：<span style={{color:'#333333'}}>{item?.targetEnum ? purposeList[item.targetEnum - 1].val || '' : ''}</span></div>
                                <div>{intl.get('SensationExcellentWorks').Media}：<span style={{color:'#333333'}}>{item?.mediaEnum ? mediaList[item.mediaEnum - 1].val || '-' : ''}</span></div>
                            </div>
                        </div>
                    })
                }
            </div> : <Divider plain>{intl.get('SensationExcellentWorks')['暂无作品信息~']} 🤐</Divider>
        }
        <Modal centered visible={showVideo} footer={null} closable={null} onCancel={()=>{setShowVideo(false)}}
               destroyOnClose={true}
            getContainer={() => document.getElementById('SensationDetail')}
            bodyStyle={{
            padding: '0', backgroundColor: '#FFFFFF', width: 826, height: 475, overflow: 'hidden'
        }}>
            <video width='100%' height='100%' controls autoPlay={true} loop>
            <source src={playVideoUrl} type="video/mp4" />
            <source src={playVideoUrl} type="video/ogg" />
            <source src={playVideoUrl} type="video/webm" />
            您的浏览器不支持 video 标签。
            </video>
        </Modal>
    </div>
}
export default withRouter(ExcellentWorks);