import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import { Image, Tag } from 'antd';
import '../../asset/css/LivingDemand.scss'
import Slider from "react-slick";
import intl from 'react-intl-universal';
import store from '../../utils/redux/index'
import Enum from '../../utils/Enum/Enum'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div style={{ backgroundColor: '#EEEEEE', borderRadius: '100px', width: '22px', height: '22px', textAlign: 'center', lineHeight: '18px', cursor: 'pointer', position: 'absolute', top: 'calc(50% - 11px)', left: '-25px' }} onClick={onClick}><img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou3.png'} alt="" /></div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div style={{ backgroundColor: '#EEEEEE', borderRadius: '100px', width: '22px', height: '22px', textAlign: 'center', lineHeight: '18px', cursor: 'pointer', position: 'absolute', top: 'calc(50% - 11px)', right: '-25px' }} onClick={onClick}><img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou2.png'} alt="" /></div>
    );
}

const StatusLive = (props) => {
    const [textJson, setTextJson] = useState(intl.get('DemandHall'))
    const [textJsonL, setTextJsonL] = useState(intl.get('LivingDemand'))
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SampleNextArrow />,
        nextArrow: <SamplePrevArrow />
    };
    const [item, setItem] = useState({})

    useEffect(() => {
        if (props.item) {
            setItem(props.item)
        }
    }, [props.item])

    const toDetail = e => {
        let obj = {
            target: e.target,
            media: e.media,
            cooperationWay: 2,
            listId: e.listId,
            demandId: e.demandId,
        }
        props.navigate('/index/videoDemandDetail', { state: obj })
    }

    return (
        <div id='Status1'>
            {
                item.demandId ?
                    <div key={item.demandId} style={{ marginTop: '12px', backgroundColor: '#fff' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', width: '100%', padding: '30px 0px 30px 30px', overflow:'hidden' }}>
                                <div style={{
                                    display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '200px', marginRight: '28px',
                                    backgroundImage: 'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png)', backgroundSize: 'cover',
                                    backgroundPosition: '50% 50%'
                                }}>
                                    <Slider {...settings} style={{ width: '130px', height: '180px' }}>
                                        {
                                            item?.target === 2 ?
                                                <div>
                                                    <div style={{ width: '130px', height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', position: 'relative' }}>
                                                        <Image
                                                            width={130}
                                                            src={item?.marketList?.logo || ''}
                                                            fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                                                            alt='' />
                                                    </div>
                                                </div> : ''
                                        }
                                        {
                                            item?.marketList?.videos?.map((item2, index) => (
                                                <div key={index}>
                                                    <div style={{ width: '130px', height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', position: 'relative', cursor: 'pointer' }} onClick={e => props.playVideo(item2)}>
                                                        <Image
                                                            width={130}
                                                            src={item2 + '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,m_fast'}
                                                            fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                                                            preview={false} alt='' />
                                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/player_red.png'} style={{ position: 'absolute' }} alt="" />
                                                        {/* <div style={{ position: 'absolute', bottom: '12px', color: '#fff' }}>0:59</div> */}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {
                                            item?.marketList?.imgs?.map((item2, index) => (
                                                <div key={index}>
                                                    <div style={{ width: '130px', height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', position: 'relative' }}>
                                                        <Image
                                                            width={130}
                                                            src={item2 || ''}
                                                            fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                                                            alt='' />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                </div>
                                <div style={{ paddingRight: '40px', width: '100%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ fontSize: '16px', fontWeight: '800', marginRight: '14px' }}>
                                            {item?.target === 2 ? item?.marketList?.brand : item?.marketList?.title}
                                        </div>
                                        <div>
                                            {
                                                item?.live?.sendSample === 1 ?
                                                    item?.asettleWay === 1 ?
                                                        <Tag color="green">{textJsonL['支持样品寄送（寄样抵佣金）']}</Tag>
                                                        :
                                                        <Tag color="green">{textJsonL['支持样品寄送']}</Tag>
                                                    :
                                                    <Tag color="error">{textJsonL['不支持样品寄送']}</Tag>
                                            }
                                        </div>
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        {
                                            item?.marketList?.intro 
                                        }
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <div className='ant-tag' style={{ borderColor: '#F7385B', color: '#F7385B', backgroundColor: '#FFE7E9' }}>
                                            {
                                                intl.get('ReleaseDemand')[Enum.propertiesTarget[item?.target].val]
                                            }
                                            &nbsp;&nbsp;|&nbsp;&nbsp;
                                            {
                                                item?.cooperationWay === 1 ?
                                                    intl.get('ReleaseDemand')['短视频']
                                                    :
                                                    intl.get('ReleaseDemand')['直播']
                                            }
                                        </div>
                                        {
                                            <Tag>{textJsonL['品牌名称：']}{item?.marketList?.brand}</Tag> 
                                        }
                                        <Tag>{textJsonL['所属行业：']}{item?.marketList?.biz?.val}</Tag>
                                        <Tag>{textJsonL['媒体传播：']}<span><img style={{ width: 13, height: 13 }} src={Enum.propertiesMedia[item.media].icon} alt="" /><span>{Enum.propertiesMedia[item.media].val}</span></span></Tag>
                                    </div>
                                    <div style={{ marginTop: '10px', color: '#888888' }}>{intl.get('ReleaseDemand')['需求有效日期']}：<span style={{ fontFamily: 'neue' }}>{intl.get('ReleaseDemand')['截止到']}{item?.live?.bloggerApplicationTimeTo?.slice(0,10) || intl.get('SmallShop')['永久有效']}</span></div>
                                    <div style={{ marginTop: '10px', color: '#888888' }}>{textJsonL['直播需求时长：']}<span style={{ fontFamily: 'neue', color: '#F7385B', fontWeight: '800' }}>
                                        {store.getState().language === 'en' ? ' in' + item.live?.durationH + 'h' : item.live?.durationH + 'h内'}
                                    </span></div>
                                </div>
                            </div >
                            <div style={{padding:'30px 0'}}>
                                <div style={{ minWidth:'320px', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '30px', borderLeft: '1px solid #eee' }}>
                                    <div style={{ color: '#666666' }}>{textJsonL['期望开播日期']}</div>
                                    <div style={{ color: '#F7385B' }}>{item?.live?.bloggerPubTime?.slice(0,10)}</div>
                                    <div style={{ width: '160px', height: '38px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '18px' }}
                                        onClick={() => {
                                            props.setID({
                                                target: item?.target,
                                                listId: item?.listId,
                                                cooperationWay: item?.cooperationWay,
                                                demandId: item?.demandId,
                                                sendSample: item?.live?.sendSample,
                                                durationH: item?.live?.durationH,
                                                asettleWay: item?.asettleWay,
                                                media: item?.media
                                            })
                                            props.postApply(true);
                                        }}
                                    >{textJsonL['申请与TA合作']}</div>
                                    <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '18px', border: '1px solid #eee' }}
                                        onClick={() => toDetail(item)}>{textJsonL['查看需求详情']}</div>
                                </div>
                            </div>
                        </div>
                    </div >
                    :
                    ''
            }
        </div>
    )
}
export default withRouter(StatusLive);