import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import '../../../asset/css/BloggerRanking.scss'
import { Select, Checkbox, List, notification, Image, Skeleton, Divider, Spin, Input, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../../utils/redux/index'
import domtoimage from 'dom-to-image';
import intl from 'react-intl-universal';
import PkModel from '../../../components/PkModel'
import * as Api from '../../../service/https'
import toKMB from '../../../utils/KMB'
import ShareCommonModel from "../../../components/Share/ShareCommonModel";
// import CommonCondition from "../../../components/CommonCondition";
// import {MyContext} from "../../../utils/contextManager";

const { Option } = Select;

const BloggerRanking = (props) => {
    const ThemeContext = React.createContext('light');
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh'
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);
    const shareObj = {
        shareName:'BloggerRanking',
        topTitle: intl.get('ShareModel')['粉丝数'],
        topDayType: intl.get('ShareModel')['日榜'],
        itemImg:'avatarMedium',
        itemTitle:'nickname',
        itemSubTitlePre:'TikTok: ',
        itemSubTitle:'uniqueId',
        itemLeftTag: intl.get('ShareModel')['粉丝数'],
        itemLeftValue: 'followerCount',
        itemLeftType:'number',
        itemLeftImg:'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/fensiguanli.png?1111',
        itemRightTag:intl.get('ShareModel')['视频数'],
        itemRightValue:'videoCount',
        itemRightType:'number',
        itemRightImg:'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/shipin.png?1111',
    }

    const [widths, setWidths] = useState(0)
    useEffect(() => {
        // console.log(props)
        insertHistory()
        // getCountry()
        // window.scrollTo(0, 0)
        heightFun()
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
            notification.destroy()
        }
    }, [])

    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(height)
        let num = state.collapsed + (width > 1890 ? 6 : width > 1650 ? 5 : 4)
        setWidths(num)
    }
    useEffect(() => {
        heightFun()
    }, [state.collapsed])

    const [tabList, setTabList] = useState([
        '全部', '英国', '印度尼西亚', '美国', '日本', '沙特阿拉伯', '德国', '越南', '巴西', '泰国',
        '俄罗斯联邦', '马来西亚', '菲律宾', '阿联酋', '墨西哥', '沙特阿拉伯'
    ])
    const [tabIndex, setTabIndex] = useState(0)
    const [tabShow, setTabShow] = useState(false)
    const changeTab = (index) => {
        if (state.userInfo?.memberStatus || state.userInfo?.userType === 2)
            setTabIndex(index)
    }
    const [geolocation, setGeolocation] = useState([])
    const getCountry = () => {
        Api.getCountry().then(res => {
            if (res.code == 200) {
                let all = res.data
                all.unshift({ id: 0, name: '全部', enName: 'All' })
                setGeolocation(all)
            }
        })
    }


    const [isBig, setBig] = useState(false)

    const [sortNumList, setItems] = useState([
        { start: 0, key: '<', end: 10000 },
        { start: 10000, key: '~', end: 100000 },
        { start: 100000, key: '~', end: 500000 },
        { start: 500000, key: '~', end: 1000000 },
        { start: 1000000, key: '~', end: 5000000 },
        { start: 5000000, key: '~', end: 10000000 },
        { start: 10000000, key: '~', end: 50000000 },
        { start: 50000000, key: '<', end: 0 },
        { start: '', key: '~', end: '' },
        { start: '', key: '~', end: '' },
        { start: '', key: '~', end: '' }
    ])
    const [fansInputObj,setFansInputObj] = useState(null)
    const [videoInputObj,setVideoInputObj] = useState(null)
    const [playInputObj,setPlayInputObj] = useState(null)
    // 通用筛选的参数和方法
    const vmSelectList = [
        {
            type:'select',
            title:'筛选',
            list:[
                {label:intl.get('Common')['粉丝数'], params:'sortFansIndex', sortIndex:-1, valueList:sortNumList, selectConfirmStatus:0,  start:'',end:'' },
                {label:intl.get('Common')['视频数'], params:'sortFollowIndex', sortIndex:-1, valueList:sortNumList, selectConfirmStatus:0, start:'',end:''  },
                {label:intl.get('Common')['平均播放数'], params:'sortPlayIndex', sortIndex:-1, valueList:sortNumList, selectConfirmStatus:0, start:'',end:''  },
            ]
        },
        {
            type:'checked',
            title:'分类',
            list:[
                {label:'',params:'classify',classify:[],valueList:[{label:intl.get('Index')['有认证'],rightImg:'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'},
                        {label:intl.get('Index')['有主页链接'],rightImg:'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/lianjie.png'}]
                }
            ]
        }
    ]
    const [selectList,setSelectList] = useState(vmSelectList)
    const changeSelectList = data =>{
        setSelectList(data)
        data.map(item=>{
            item.list.map(item2=>{
                if(item.type === 'select'){
                    if(item2.params == 'sortFansIndex') {
                        if(item2.selectConfirmStatus && (item2.start || item2.end) ) {
                            setFansInputObj({ start: item2.start || 0, key: '~', end: item2.end || ''})
                        }else {
                            setFansInputObj(null)
                            setRangeFansNumDto(item2.sortIndex)
                        }
                    }
                    else if(item2.params == 'sortFollowIndex') {
                        if(item2.selectConfirmStatus && (item2.start || item2.end) ) {
                            setVideoInputObj({ start: item2.start || 0, key: '~', end: item2.end || ''})
                        }else {
                            setVideoInputObj(null)
                            setRangeFollowNumDto(item2.sortIndex)
                        }
                    }
                    else if(item2.params == 'sortPlayIndex') {
                        if(item2.selectConfirmStatus && (item2.start || item2.end) ) {
                            setPlayInputObj({ start: item2.start || 0, key: '~', end: item2.end || ''})
                        }else {
                            setPlayInputObj(null)
                            setRangePlayNumDto(item2.sortIndex)
                        }
                    }
                }else if(item.type === 'checked'){
                    // setClassify(item2.classify)
                }
            })
        })
    }

    const [selectConfirmStatus, setSelectConfirmStatus] = useState(1)
    const [sortFansIndex, setRangeFansNumDto] = useState(-1)
    const [sortFollowIndex, setRangeFollowNumDto] = useState(-1)
    const [sortPlayIndex, setRangePlayNumDto] = useState(-1)
    const changeMinInput = (index, value) => {
        let v = JSON.parse(JSON.stringify(sortNumList))
        v[index].start = value
        setItems(v)
    }
    const changeMaxInput = (index, value) => {
        let v = JSON.parse(JSON.stringify(sortNumList))
        v[index].end = value
        setItems(v)
    }
    const changeRangeFansNumDto = (index) => {
        setPageIndex(0)
        setRangeFansNumDto(index == undefined ? -2 : index)
        if (index == undefined) {
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[8] = { start: '', key: '~', end: '' }
            setItems(v)
        }else{
            setSelectConfirmStatus(selectConfirmStatus == 1 ? 2 : 1)
        }
    }
    const changeRangeFolloNumDto = (index) => {
        setPageIndex(0)
        setRangeFollowNumDto(index == undefined ? -2 : index)
        if (index == undefined) {
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[9] = { start: '', key: '~', end: '' }
            setItems(v)
        }else{
            setSelectConfirmStatus(selectConfirmStatus == 1 ? 2 : 1)
        }
    }
    const changeRangePlayNumDto = (index) => {
        setPageIndex(0)
        setRangePlayNumDto(index == undefined ? -2 : index)
        if (index == undefined) {
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[10] = { start: '', key: '~', end: '' }
            setItems(v)
        }else{
            setSelectConfirmStatus(selectConfirmStatus == 1 ? 2 : 1)
        }
    }


    const [classify, setClassify] = useState([]);
    const [enterprise, setEnterprise] = useState(false);
    const [social, setSocial] = useState(false);
    const [link, setLink] = useState(false);
    const [contact, setContact] = useState(false);

    const checkedC = (bool, num) => {
        setPageIndex(0)
        if (bool) {
            let list = JSON.parse(JSON.stringify(classify))
            list.push(num)
            setClassify(list)
        } else {
            let list = JSON.parse(JSON.stringify(classify))
            for (var i = 0; i < list.length; i++) {
                var ele = list[i];
                var index = list.indexOf(ele);
                if (index > -1) {
                    list.splice(index, 1);
                }
            }
            setClassify(list)
        }
    }
    const isCheckeds = (num) => {
        let list = JSON.parse(JSON.stringify(classify))
        return list.indexOf(num) > -1
    }


    const [sortList, setSortList] = useState([intl.get('BloggerRanking').FansNum, intl.get('BloggerRanking').NumberVideos,
    intl.get('BloggerRanking').LikesNum, intl.get('BloggerRanking').AveragePlayback]);
    const [sortIndex, setSortIndex] = useState(0);
    const sortMethod = (index) => {
        setPageIndex(0)
        setSortIndex(index)
    }

    useEffect(() => {
        loadMoreData()
    }, [sortIndex, classify, fansInputObj, videoInputObj, playInputObj])
    useEffect(() => {
        if (sortFansIndex != -1 || sortFollowIndex != -1 || sortPlayIndex != -1) loadMoreData()
    }, [sortFansIndex, sortFollowIndex, sortPlayIndex, selectConfirmStatus])





    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);

    const [shareData, setShareData] = useState([]);

    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            bloggerSortRuler: sortIndex + 1,
            from: pageIndex * 20, size: 20,
            rangeFansNumDto: sortFansIndex == (-2 || -1) ? { "start": 0, "end": 0 } : sortNumList[sortFansIndex],
            // rangeFansNumDto: fansInputObj ? { "start": fansInputObj.start, "end": fansInputObj.end } : sortNumList[sortFansIndex],
            rangeFollowNumDto: sortFollowIndex == (-2 || -1) ? { "start": 0, "end": 0 } : sortNumList[sortFollowIndex],
            // rangeFollowNumDto: videoInputObj ? { "start": videoInputObj.start, "end": videoInputObj.end } : sortNumList[sortFollowIndex],
            rangePlayNumDto: sortPlayIndex == (-2 || -1) ? { "start": 0, "end": 0 } : sortNumList[sortPlayIndex],
            // rangePlayNumDto: playInputObj ? { "start": playInputObj.start, "end": playInputObj.end } : sortNumList[sortPlayIndex],
            classify: classify
        }
        Api.getBloggerRanking(obj).then(res => {
            if (res.code == 200) {
                if (shareData.length == 0) setShareData(res.data.data)
                setTotal(res.data.total)
                setData(pageIndex == 0 ? res.data.data : [...data, ...res.data.data]);
                setPageIndex(pageIndex + 1)
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    };

    // 别表
    const SmallDiv = (item, index) => {
        return <div key={index} style={{
            width: '100%', height: '100px', display: 'flex', alignItems: 'center',
            backgroundColor: index % 2 == 0 ? '#FFFFFF' : '#F6F6F6'
        }}
            >

            <div style={{ width: '10.8%', paddingLeft: '19px' }}>
                {index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png'} alt=""
                    style={{}} /> :
                    <div style={{ width: '32px', textAlign: 'center', fontWeight: 500 }}>{index + 1}</div>
                }
            </div>
            <div style={{ width: '26.2%', display: 'flex', alignItems: 'center' }}>
                <Image onClick={e => e.stopPropagation()} src={item.avatarMedium} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                    style={{ width: '60px', height: '60px', borderRadius: '30px', marginRight: '16px' }} />
                <div onClick={() => detail(item, index)} style={{ color: '#888888', cursor: 'pointer' }}>
                    <div style={{
                        color: '#333333', fontWeight: 'bold', fontSize: '16px',
                        display: 'flex', alignItems: 'center'
                    }}>
                        <div className='over' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item.nickname}</div>
                        {item.verified ? <img style={{ marginLeft: '3px', width: '14px', height: '14px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} /> : ''}
                        {item.bioLink.link != null ? <img style={{ marginLeft: '3px', width: '13px', height: '13px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/lianjie.png'} /> : ''}
                    </div>
                    TikTok: {item.uniqueId}
                </div>
            </div>
            <div style={{ width: '10%' }}>{toKMB(item.followerCount, 2)}</div>
            <div style={{ width: '9%' }}>{toKMB(item.videoCount, 2)}</div>
            <div style={{ width: '11%' }}>{toKMB(item.ptvRatio_speculate, 2)}</div>
            <div style={{ width: '10%' }}>{toKMB(item.heartCount, 2)}</div>
            <div style={{ width: '10.5%' }}>{item.htfRatio}</div>
            <div style={{ width: '' }}>
                <Tooltip placement="top" title={<span style={{ fontSize: '12px' }}>添加PK</span>}>
                    <img onClick={(e) => isChecked(item) ? delPkItem(e, item, index) : addPK(e, item, index)}
                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pk' + (isChecked(item) ? 's' : '') + '.png'} style={{ marginRight: '9px', cursor: 'pointer' }} alt="" />
                </Tooltip>
                {/* <img onClick={(e)=>addCollection(e, item,index)} 
                    src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc.png'} alt="" /> */}
            </div>
        </div>
    }
    // 大图列表
    const BigDiv = (item, index) => {
        return <div className='BigDivs' style={{
            width: '240px', height: '300px', backgroundColor: '#FFF', margin: '0 auto',
            marginBottom: widths == 6 ? '8px' : widths == 5 ? '12px' : widths == 4 ? '18px' : '', borderRadius: '4px',
            cursor: 'pointer'
        }}
            onClick={() => detail(item, index)}>

            <div style={{
                width: '100%', height: '240px', position: 'relative', backgroundColor: 'rgb(246,246,246)',
                borderTopLeftRadius: '4px', borderTopRightRadius: '4px',
            }}>
                <Image src={item.avatarMedium} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                    style={{ width: '240px', height: '240px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} />
                <div style={{
                    width: '100%', height: '100%', backgroundColor: 'rgb(0,0,0,0.22)',
                    display: 'flex', flexFlow: 'column', justifyContent: 'space-between', position: 'absolute', top: 0,
                    borderTopLeftRadius: '4px', borderTopRightRadius: '4px',
                }}>
                    <div style={{ textAlign: 'right', paddingTop: '14px', paddingRight: '10px' }}>
                        <Tooltip placement="top" title={<span style={{ fontSize: '12px' }}>添加PK</span>}>
                            <img onClick={(e) => isChecked(item) ? delPkItem(e, item, index) : addPK(e, item, index)} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/PKB' + (isChecked(item) ? 'S' : '') + '.png'} />
                        </Tooltip>
                        {/* <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/PKBS.png'} />
                        <img style={{marginLeft:'9px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/SCB.png'} /> */}
                        {/* <img onClick={(e)=>addCollection(e, item,index)} style={{marginLeft:'9px'}} 
                            src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/SCBS.png'} /> */}
                    </div>
                    <div style={{ width: '100%', padding: '20px 14px' }}>
                        <div style={{ width: '100%', display: 'flex', color: '#FFF', alignItems: 'center' }}>
                            <div style={{ height: '30px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item.nickname}</div>
                            <img style={{ marginLeft: '3px', width: '14px', height: '14px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} />
                        </div>
                        <div style={{ color: '#F6F6F6', fontWeight: 400 }}>TikTok: {item.uniqueId}</div>
                    </div>
                </div>
            </div>
            <div style={{
                width: '100%', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                textAlign: 'center'
            }}>
                <div style={{ width: '100px' }}>
                    <strong style={{ fontSize: '16px' }}>{toKMB(item.followerCount, 2)}</strong>
                    <p style={{ fontSize: '12px', color: '#888888', marginBottom: '4px' }}>{intl.get('BloggerRanking').FansNum}</p>
                </div>
                <div style={{ width: '100px' }}>
                    <strong style={{ fontSize: '16px' }}>{toKMB(item.ptvRatio_speculate, 2)}</strong>
                    <p style={{ fontSize: '12px', color: '#888888', marginBottom: '4px' }}>{intl.get('BloggerRanking').AveragePlayback}</p>
                </div>
            </div>

        </div>
    }

    const isChecked = (item) => {
        let bools = false
        for (let index = 0; index < imgList.length; index++) {
            const e = imgList[index]
            if (item.id == e.bloggerId) {
                bools = true
                break
            }
        }
        return bools
    }

    const detail = (item, index) => {
        window.open(`/index/bloggerDetail/${item.uniqueId}`)
    }
    const addPK = (e, item, index) => {
        e.stopPropagation();
        if (imgList.length < 6) {
            let obj = {
                bloggerId: item.id, uniqueId: item.uniqueId, nickname: item.nickname,
                avatarMedium: item.avatarMedium
            }
            Api.insertPk(obj).then(res => {
                if (res.code == 200) {
                    insertHistory()
                }
            })
        }
    }
    const delPkItem = (e, item) => {
        e.stopPropagation()
        let delIndex = -1
        for (let index = 0; index < imgList.length; index++) {
            const e = imgList[index]
            if (item.id == e.bloggerId) {
                delIndex = index
                break
            }
        }
        if (delIndex > -1) {
            Api.delItemPK({ inventoryId: imgList[delIndex].id }).then(res => {
                if (res.code == 200) {
                    insertHistory()
                }
            })
        }
    }





    // 生成图片自动下载为png格式（将dom转为二进制再编译下载）
    const [isShare, setIsShare] = useState(false)
    const getBlobPng = () => {
        setIsShare(!isShare)
    }



    // 获取PK
    const [imgList, setImgList] = useState([])
    const insertHistory = () => {
        Api.insertHistory().then(res => {
            if (res.code == 200) {
                notification.destroy()
                setImgList(res.data)
            }
        })
    }
    useEffect(() => {
        notification.destroy()
        if (imgList.length > 0) {
            openNotification()
        }
    }, [imgList])



    const [isPk, setIsPk] = useState(false)
    useEffect(() => {
        if (isShare) {
            notification.destroy()
        } else {
            if (imgList.length > 0) openNotification()
        }
    }, [isShare])
    useEffect(() => {
        if (imgList.length > 0 && !isPk) {
            openNotification()
        }
    }, [isPk])
    const openNotification = () => {
        notification.open({
            duration: null,
            getContainer: () => document.getElementById('BloggerRanking'),
            placement: 'bottomLeft',
            message: <div style={{ fontWeight: 500 }}>{intl.get('BloggerComparison').BloggerComparison} <span style={{ color: '#888888' }}>{imgList.length}/6</span> </div>,
            description: <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0' }}>
                <div style={{ display: 'flex' }}>
                    {
                        imgList?.map((item, index) => {
                            return <img key={index} src={item.avatarMedium} alt=""
                                style={{
                                    width: '40px', height: '40px', borderRadius: '20px', marginRight: '-12px',
                                    border: '3px solid #FFF', position: 'relative', zIndex: 10 - index
                                }} />
                        })
                    }
                </div>
                <div style={{
                    width: '80px', height: '30px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                    borderRadius: '4px', cursor: 'pointer'
                }}
                    onClick={() => pkResult()}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pkdb.png'} alt=""
                        style={{ width: '14px', marginRight: '4px', }} />{intl.get('BloggerComparison').Contrast}
                </div>
            </div>,
            className: 'custom-class',
            style: {
                width: 350,
            },
            closeIcon: <div>{intl.get('BloggerComparison').Empty}</div>,
            onClose: () => {
                notification.destroy()
                Api.delAllPK().then(res => {
                    if (res.code == 200) {
                        insertHistory()
                    }
                })
            }
        });
    };

    const [pkResultList, setPkResultList] = useState([])
    useEffect(() => {
        if (pkResultList.length > 0) setIsPk(true)
    }, [pkResultList])
    const pkResult = () => {
        let obj = {
            bloggerList: imgList, type: 1
        }
        Api.bloggerCompare(obj).then(res => {
            if (res.code == 200) {
                setPkResultList(res.data)
            }
        })
    }



    return <div id="BloggerRanking" style={{ paddingBottom: '50px' }}>
        <nav style={{ width: '100%', position: 'relative', fontWeight: 500 }}>
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt=""
                style={{ width: '100%' }} />
            <div style={{
                width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt=""
                    style={{ width: '190px' }} />
                <div style={{
                    width: '10px', height: '10px', backgroundColor: 'black', borderRadius: '5px',
                    margin: '0 20px'
                }}></div>
                <span style={{ fontSize: '20px' }}>{intl.get('Workbench').BloggerRanking}</span>
                <div style={{
                    width: '166px', height: '48px', textAlign: 'center', lineHeight: '48px',
                    backgroundImage: 'url(' + 'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/back.png' + ')', backgroundSize: '100% 100%',
                    color: '#FFF', marginLeft: '34px', paddingRight: '10px', cursor: 'pointer'
                }}
                    onClick={getBlobPng}>{intl.get('BloggerRanking').ShareBlogger}</div>
            </div>
        </nav>

        <div style={{
            width: '100%', backgroundColor: '#FFF', paddingTop: '24px', paddingLeft: '48px', paddingBottom: '25px',
            fontWeight: 500
        }}>
            {/* 国家 */}
            {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ width: state.language == 'zh' ? '70px' : '90px', marginTop: '6px', textAlign: 'right', paddingRight: '26px' }}>{intl.get('BloggerRanking').Country}：</label>
                <div style={{
                    width: 'calc(100% - 70px)', height: tabShow ? 'auto' : '39px', display: 'flex', flexWrap: 'wrap',
                    overflow: tabShow ? '' : 'hidden'
                }}>
                    {
                        geolocation?.map((item, index) => {
                            return <span key={index} className='tabItem' style={{
                                padding: '6px 10px',
                                background: index == tabIndex ? '#FFE7E9' : 'none', marginBottom: '5px',
                                color: index == tabIndex ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                                cursor: state.userInfo?.memberStatus || state.userInfo?.userType === 2 ? 'pointer' : 'not-allowed'
                            }}
                                onClick={() => changeTab(index)}>{lang == 'zh' ? item.name: item.enName}</span>
                        })
                    }
                </div>
                <div style={{ width: '45px' }}>
                    {!tabShow ?
                        <DownSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                            onClick={() => { setTabShow(true) }} />
                        :
                        <UpSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                            onClick={() => setTabShow(false)} />}
                </div>
            </div> */}
            {/* 筛选 */}
            {/*<MyContext.Provider value={{selectList,defaultPageIndex:0,changePageIndex:(data)=>{setPageIndex(data)},changeSelectList:data=>{changeSelectList(data)} }}>*/}
            {/*    <CommonCondition />*/}
            {/*</MyContext.Provider>*/}
            <div style={{ width: '100%', height: '30px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                <label style={{ width: state.language == 'zh' ? '70px' : '100px', textAlign: 'right', paddingRight: state.language == 'zh' ? '28px' : '32px' }}>
                    {intl.get('BloggerRanking').Screen}：</label>
                <div id='fans'>
                    <span>{intl.get('BloggerRanking').FansNum}</span>
                    <Select
                        onClear={e => changeRangeFansNumDto(undefined)}
                        allowClear={true}
                        dropdownRender={menu => (
                            <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <div id='login' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 10px', paddingBottom: '5px' }}>
                                    <Input value={sortNumList[8].start}
                                        onChange={e => changeMinInput(8, e.target.value)}
                                        style={{ width: '50px', paddingLeft: '4px', paddingRight: '4px', textAlign: 'center' }} placeholder="min" /> ~
                                    <Input value={sortNumList[8].end}
                                        onChange={e => changeMaxInput(8, e.target.value)}
                                        style={{ width: '50px', paddingLeft: '4px', paddingRight: '4px', textAlign: 'center' }} placeholder="max" />
                                    <span style={{
                                        border: sortNumList[8].start >= 0 && parseInt(sortNumList[8].end) > parseInt(sortNumList[8].start) ? '' : '1px solid #EEEEEE', borderRadius: '4px', padding: '5.5px 11px', marginLeft: '5px', cursor: 'pointer',
                                        color: sortNumList[8].start >= 0 && parseInt(sortNumList[8].end) > parseInt(sortNumList[8].start) ? '#FFF' : '#CCCCCC',
                                        background: sortNumList[8].start >= 0 && parseInt(sortNumList[8].end) > parseInt(sortNumList[8].start) ? '#F7385B' : '#FFF', fontSize: '12px'
                                    }}
                                        onClick={() => sortNumList[8].start >= 0 && parseInt(sortNumList[8].end) > parseInt(sortNumList[8].start) ? changeRangeFansNumDto(8) : null}>确定</span>
                                </div>
                            </>
                        )}
                        value={(sortFansIndex == -1 || sortFansIndex == -2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : ((sortNumList[sortFansIndex].start > 0 ? toKMB(sortNumList[sortFansIndex].start) : '') + ' ' + sortNumList[sortFansIndex].key + ' ' + (sortNumList[sortFansIndex].end > 0 ? toKMB(sortNumList[sortFansIndex].end) : ''))}
                        style={{ width: 200, borderRadius: '26px', marginLeft: '10px' }} getPopupContainer={() => document.getElementById('fans')}
                        placeholder={intl.get('TopicList').PleaseSelect}>
                        {sortNumList?.map((item, index) => (
                            index < 8 ? <Option key={index}><div onClick={e=>changeRangeFansNumDto(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                        ))}
                    </Select>
                </div>
                <div id='fans'>
                    <span style={{ marginLeft: state.language == 'zh' ? '40px' : '20px' }}>{intl.get('BloggerRanking').LikesNum}</span>
                    <Select
                        onClear={e => changeRangeFolloNumDto(undefined)}
                        allowClear={true}
                        dropdownRender={menu => (
                            <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <div id='login' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 10px', paddingBottom: '5px' }}>
                                    <Input value={sortNumList[9].start}
                                        onChange={e => changeMinInput(9, e.target.value)}
                                        style={{ width: '50px', paddingLeft: '4px', paddingRight: '4px', textAlign: 'center' }} placeholder="min" /> ~
                                    <Input value={sortNumList[9].end}
                                        onChange={e => changeMaxInput(9, e.target.value)}
                                        style={{ width: '50px', paddingLeft: '4px', paddingRight: '4px', textAlign: 'center' }} placeholder="max" />
                                    <span style={{
                                        border: sortNumList[9].start >= 0 && parseInt(sortNumList[9].end) > parseInt(sortNumList[9].start) ? '' : '1px solid #EEEEEE', borderRadius: '4px', padding: '5.5px 11px', marginLeft: '5px', cursor: 'pointer',
                                        color: sortNumList[9].start >= 0 && parseInt(sortNumList[9].end) > parseInt(sortNumList[9].start) ? '#FFF' : '#CCCCCC',
                                        background: sortNumList[9].start >= 0 && parseInt(sortNumList[9].end) > parseInt(sortNumList[9].start) ? '#F7385B' : '#FFF', fontSize: '12px'
                                    }}
                                        onClick={() => sortNumList[9].start >= 0 && parseInt(sortNumList[9].end) > parseInt(sortNumList[9].start) ? changeRangeFolloNumDto(9) : null}>确定</span>
                                </div>
                            </>
                        )}
                        value={(sortFollowIndex == -1 || sortFollowIndex == -2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : ((sortNumList[sortFollowIndex].start > 0 ? toKMB(sortNumList[sortFollowIndex].start) : '') + ' ' + sortNumList[sortFollowIndex].key + ' ' + (sortNumList[sortFollowIndex].end > 0 ? toKMB(sortNumList[sortFollowIndex].end) : ''))}
                        style={{ width: 200, borderRadius: '26px', marginLeft: '10px' }} getPopupContainer={() => document.getElementById('fans')}
                        placeholder={intl.get('TopicList').PleaseSelect}>
                        {sortNumList?.map((item, index) => (
                            index < 8 ? <Option key={index}><div onClick={e=>changeRangeFolloNumDto(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                        ))}
                    </Select>
                </div>
                <div id='fans'>
                    <span style={{ marginLeft: state.language == 'zh' ? '40px' : '20px' }}>{intl.get('BloggerRanking').AveragePlayback}</span>
                    <Select
                        onClear={e => changeRangePlayNumDto(undefined)}
                        allowClear={true}
                        dropdownRender={menu => (
                            <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <div id='login' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 10px', paddingBottom: '5px' }}>
                                    <Input value={sortNumList[10].start}
                                        onChange={e => changeMinInput(10, e.target.value)}
                                        style={{ width: '50px', paddingLeft: '4px', paddingRight: '4px', textAlign: 'center' }} placeholder="min" /> ~
                                    <Input value={sortNumList[10].end}
                                        onChange={e => changeMaxInput(10, e.target.value)}
                                        style={{ width: '50px', paddingLeft: '4px', paddingRight: '4px', textAlign: 'center' }} placeholder="max" />
                                    <span style={{
                                        border: sortNumList[10].start >= 0 && parseInt(sortNumList[10].end) > parseInt(sortNumList[10].start) ? '' : '1px solid #EEEEEE', borderRadius: '4px', padding: '5.5px 11px', marginLeft: '5px', cursor: 'pointer',
                                        color: sortNumList[10].start >= 0 && parseInt(sortNumList[10].end) > parseInt(sortNumList[10].start) ? '#FFF' : '#CCCCCC',
                                        background: sortNumList[10].start >= 0 && parseInt(sortNumList[10].end) > parseInt(sortNumList[10].start) ? '#F7385B' : '#FFF', fontSize: '12px'
                                    }}
                                        onClick={() => sortNumList[10].start >= 0 && parseInt(sortNumList[10].end) > parseInt(sortNumList[10].start) ? changeRangePlayNumDto(10) : null}>确定</span>
                                </div>
                            </>
                        )}
                        value={(sortPlayIndex == -1 || sortPlayIndex == -2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : ((sortNumList[sortPlayIndex].start > 0 ? toKMB(sortNumList[sortPlayIndex].start) : '') + ' ' + sortNumList[sortPlayIndex].key + ' ' + (sortNumList[sortPlayIndex].end > 0 ? toKMB(sortNumList[sortPlayIndex].end) : ''))}
                        style={{ width: 200, borderRadius: '26px', marginLeft: '10px' }} getPopupContainer={() => document.getElementById('fans')}
                        placeholder={intl.get('TopicList').PleaseSelect}>
                        {sortNumList?.map((item, index) => (
                            index < 8 ? <Option key={index}><div onClick={e=>changeRangePlayNumDto(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                        ))}
                    </Select>
                </div>
            </div>

            {/* 分类 */}
            <div style={{ width: '100%', marginTop: '19px', display: 'flex' }}>
                <label style={{
                    width: state.language == 'zh' ? '75px' : '100px', textAlign: 'right',
                    position: 'relative', left: '-33px'
                }}>{intl.get('BloggerRanking').Classification}：</label>
                <div style={{ width: state.language == 'zh' ? 'calc(100% - 70px)' : 'calc(100% - 90px)', display: 'flex', flexFlow: 'wrap' }}>
                    <div style={{ marginRight: '50px', marginBottom: '10px' }}>
                        <Checkbox onChange={e => checkedC(e.target.checked, 2)} className='active'>
                            <span style={{ color: isCheckeds(2) ? '#F7385B' : '' }}>{intl.get('Index')['有认证']}</span>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} alt=""
                                style={{ width: '14px', marginLeft: '3px', marginTop: '-2px' }} />
                        </Checkbox>
                    </div>
                    {/* <div style={{marginRight:'50px'}}>
                        <Checkbox onChange={e=>setEnterprise(e.target.checked)} className='active'>
                            <span style={{color: enterprise?'#F7385B':''}}>{intl.get('BloggerRanking').EnterpriseCertification}</span>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/renrenzheng.png'} alt="" 
                                style={{width:'14px', marginLeft:'3px', marginTop:'-2px'}}/>
                        </Checkbox>
                    </div>
                    <div style={{marginRight:'50px'}}>
                        <Checkbox onChange={e=>setSocial(e.target.checked)} className='active'>
                            <span style={{color: social?'#F7385B':''}}>{intl.get('BloggerRanking').AocialAccount}</span>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/shejiao.png'} alt="" 
                                style={{width:'15px', marginLeft:'3px', marginTop:'-2px'}}/>
                        </Checkbox>
                    </div> */}
                    <div style={{ marginRight: '50px' }}>
                        <Checkbox onChange={e => checkedC(e.target.checked, 1)} className='active'>
                            <span style={{ color: isCheckeds(1) ? '#F7385B' : '' }}>{intl.get('BloggerRanking').PageLinks}</span>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/lianjie.png'} alt=""
                                style={{ width: '13px', marginLeft: '3px', marginTop: '-2px' }} />
                        </Checkbox>
                    </div>
                    {/* <div>
                        <Checkbox onChange={e=>setContact(e.target.checked)} className='active'>
                            <span style={{color: contact?'#F7385B':''}}>{intl.get('BloggerRanking').ContactInformation}</span>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/phone.png'} alt="" 
                                style={{width:'12px', marginLeft:'3px', marginTop:'-2px'}}/>
                        </Checkbox>
                    </div> */}
                </div>
            </div>
        </div>

        {/* 排序 */}
        <div style={{
            width: '100%', height: '60px', backgroundColor: '#FFF', marginTop: '10px',
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '47px',
            paddingRight: '24px'
        }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {
                    sortList?.map((item, index) => {
                        return <div key={index} className='active' style={{
                            display: 'flex', alignItems: 'center', marginRight: '36px',
                            color: sortIndex == index ? '#F7385B' : '#888888', cursor: 'pointer'
                        }}
                            onClick={() => sortMethod(index)}>
                            {item}
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index ? 's' : '') + '.png'} alt=""
                                style={{ marginLeft: '6px', marginTop: '2px' }} />
                        </div>
                    })
                }
            </div>
            <div>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/datu' + (isBig ? 's' : '') + '.png'} alt=""
                    style={{ cursor: 'pointer' }} onClick={() => setBig(true)} />
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/lb' + (!isBig ? 's' : '') + '.png'} alt=""
                    style={{ marginLeft: '6px', cursor: 'pointer' }}
                    onClick={() => setBig(false)} />
            </div>
        </div>

        {/* 列表 */}
        <div style={{
            width: '100%', backgroundColor: isBig ? '' : '#FFFFFF', padding: isBig ? '0 10px' : '24px 24px 24px 30px',
            marginTop: isBig ? 0 : '10px'
        }}>
            {isBig ? '' : <div style={{
                width: '100%', height: '50px', display: 'flex', alignItems: 'center', backgroundColor: '#F6F6F6',
                fontWeight: 500
            }}>
                <div style={{ width: '10.8%', paddingLeft: '19px' }}>{intl.get('BloggerRanking').Ranking}</div>
                <div style={{ width: '26.2%', paddingLeft: '14px' }}>{intl.get('BloggerRanking').Blogger}</div>
                <div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('BloggerRanking').FansNum}</div>
                <div style={{ width: '9%', paddingRight: '10px' }}>{intl.get('BloggerRanking').NumberVideos}</div>
                <div style={{ width: '11%', paddingRight: '10px' }}>{intl.get('BloggerRanking').AveragePlayback}</div>
                <div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('BloggerRanking').LikesNum}</div>
                <div style={{ width: '10.5%', paddingRight: '10px' }}>{intl.get('BloggerRanking').AveragePpowderRatio}</div>
                <div style={{ width: '' }}>{intl.get('BloggerRanking').Operation}</div>
            </div>}
            <Spin tip="数据加载中..." spinning={loading}>
                <InfiniteScroll
                    dataLength={data.length} scrollThreshold={'20px'}
                    next={loadMoreData}
                    hasMore={data.length < total}
                    // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                    endMessage={state.userInfo?.userType == 1 && state.userInfo?.memberDetail?.memberType === '领航版' ? <Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider> : ''}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={data}
                        grid={{
                            gutter: 0,
                            column: isBig ? widths : 1
                        }}
                        renderItem={
                            (item, index) =>
                                <div style={{ paddingTop: isBig ? '10px' : 0 }}>
                                    {isBig ? BigDiv(item, index) : SmallDiv(item, index)}
                                </div>
                        }
                    />
                </InfiniteScroll>
            </Spin>
            {
                state.userInfo?.userType == 1 && state.userInfo?.memberStatus != 0 ?
                    state.userInfo?.memberDetail?.memberType === '起航版' && total <= data.length ?
                        <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                style={{ width: '24px', marginRight: '7px' }} />
                            {intl.get('TopicList')['起航版员目前仅支持查看前200条数据']}
                            {/* <span style={{ color: '#F7385B', cursor: 'pointer' }}>{intl.get('BloggerRanking').OpenMember}</span>
                            {intl.get('BloggerRanking').ViewMoreData} */}
                        </div>
                        :
                        state.userInfo?.memberDetail?.memberType === '远航版' && total <= data.length ?
                            <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                    style={{ width: '24px', marginRight: '7px' }} />
                                {intl.get('TopicList')['远航版员目前仅支持查看前500条数据']}
                                {/* <span style={{ color: '#F7385B', cursor: 'pointer' }}>{intl.get('BloggerRanking').OpenMember}</span>
                                {intl.get('BloggerRanking').ViewMoreData} */}
                            </div>
                            :
                            ""
                    :
                    state.userInfo?.userType == 1 ?
                        <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                style={{ width: '24px', marginRight: '7px' }} />
                            {intl.get('BloggerRanking').NonMembers} <span style={{ color: '#F7385B', cursor: 'pointer' }} onClick={() => props.navigate('/index/member')}>{intl.get('BloggerRanking').OpenMember}</span>
                            {intl.get('BloggerRanking').ViewMoreData}
                        </div> : ''
            }
        </div>

        {isPk ? <PkModel clickFun={() => setIsPk(false)} pkResultList={pkResultList} /> : ''}
        {isShare ? <ShareCommonModel clickFun={() => getBlobPng(false)} dataList={shareData} shareObj={shareObj} /> : ''}
    </div>

}
export default withRouter(BloggerRanking);