import React, {useState, useEffect} from 'react'
import withRouter from '../../../utils/withRouter'
import * as Api from "../../../service/https";
import {Divider, Modal} from "antd";
import {CalendarOutlined} from "@ant-design/icons"
import intl from "react-intl-universal";
import '../../../asset/css/MusicRankingDetail.scss'
import toKMB from "../../../utils/KMB";
import store from "../../../utils/redux";
import openNotificationWithIcon from '../../../components/Notification'
import MusicRankingVideo from "../../../components/music/MusicRankingVideo";
import moment from "moment";
import {Area} from "@ant-design/plots";
import {getMusicRankingAnalysis, getMusicRankingDetail} from "../../../service/https";

const MusicRankingDetail = (props) => {
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);
    const [ranking_detail, ranking_detailSet] = useState({})
    const [shop_detail, shop_detailSet] = useState({})
    const [dayList] = useState(intl.get('MusicRanking').DaysArr)
    const [dayIndex, setDayIndex] = useState(0)
    const [analysisObj,setAnalysisObj] = useState(null)
    const [showAudio, setShowAudio] = useState(false)
    const [playAudioUrl,setPlayAudioUrl] = useState('')

    // 商品趋势分析图表配置
    const trendOption = {
        data:[],
        padding:[20,80],
        autoFit: true,
        xField: 'date',
        yField: 'count',
        xAxis: {
            range: [0, 1],
        },
        color: '#F7385B',
        areaStyle: {
            fill: 'l(90) 0:#F7385B  1:#FFFFFF',
        },
        sum:0,
    }
    const [trendOptions,setTrendOptions] = useState(trendOption)

    useEffect(()=>{
        getAnalysis()
    },[dayIndex])


    const openAudioModal = () => {
        setPlayAudioUrl(ranking_detail.musicUrl)
        setShowAudio(true)
    }

    const refreshAnalysis = (analysisObj) =>{
        let trend = Object.assign({},trendOption)
        let sum = 0
        trend.data = analysisObj?.userCountMap?.userCountList || []
        sum = analysisObj?.userCountMap?.['sum'] || 0
        trend.tooltip =  {
            formatter: (datum) => {
                return {
                    name: intl.get('MusicRanking')['视频数'],
                    value: toKMB(datum.count, 2)
                };
            },
        }
        trend.maxNum = analysisObj?.userCountMap?.max
        trend.minNum = analysisObj?.userCountMap?.min
        trend.aveNum = analysisObj?.userCountMap?.userCountList.length > 0 ? sum / analysisObj?.userCountMap?.userCountList.length : 0
        setTrendOptions(trend)
    }

    const getAnalysis = e => {
        Api.getMusicRankingAnalysis({
            musicId:props.params.id,
            startTime: moment(moment().subtract(dayList[dayIndex].value, 'days')).format('YYYY-MM-DD'),
            // endTime: moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')
        }).then(res=>{
            if (res.code === 200) {
                setAnalysisObj(res.data)
                refreshAnalysis(res.data)
            }
        })
    }

    useEffect(() => {
        getProduct()
    }, [])


    const getProduct = () => {
        Api.getMusicRankingDetail({id: props.params.id}).then(res => {
            if (res.code === 200) {
                ranking_detailSet(res.data)
            }
        })
    }

    const addCollection = (e) => {
        e.stopPropagation()
        Api.collectMusicRanking({id: props.params.id || ''}).then(res => {
            if (res.code === 200) {
                openNotificationWithIcon('success', intl.get('GoodsRanking')[ranking_detail.collect ? '取消成功！' : '收藏成功！'])
                let obj = Object.assign({}, ranking_detail)
                obj.collect = !obj.collect
                ranking_detailSet(obj)
            }
        })
    }

    return <div id='MusicRankingDetail'>
        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', letterSpacing: '0.6px'}}>
            <div style={{width: '330px'}}>
                <div style={{width: '330px', position:'fixed', left: '250px', top: '86px', backgroundColor: '#FFF', borderRadius: '4px'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div className='com-info-tips'>{intl.get('MusicRanking')['音乐信息']}</div>
                        {
                            <div style={{paddingTop: '10px', paddingRight: '23px', cursor: 'pointer'}}>
                                <img onClick={(e) => addCollection(e, ranking_detail)}
                                     src={`https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc${ranking_detail.collect ? 's' : ''}.png`}
                                     alt=""
                                />
                            </div>
                        }
                    </div>
                    <div className='top'>
                        <div style={{position:'relative'}}>
                            <div style={{
                                width: 180,
                                height: 180,
                                borderRadius: '50%',
                                backgroundImage: `url(${ranking_detail.cover})` ,
                                backgroundSize:'100% 100%',
                                backgroundRepeat:'no-repeat',
                                margin: '0 auto',
                            }}></div>
                            <img onClick={openAudioModal} style={{width: 38, height: 38, cursor:'pointer'}} className='pos-center'
                                 src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/start.png" alt=""/>
                        </div>
                        <div style={{textAlign: 'center', margin: '30px 0', fontSize: 18, fontWeight: "bold"}}>{ranking_detail.title}</div>
                    </div>
                    <Divider dashed/>
                    <div className='bottom' style={{textAlign: 'left', color: '#888888', fontSize: 14}}>
                        <div className='title'>{intl.get('MusicRanking')['作者']}：<span style={{color: '#333333', fontWeight: "bold"}}>{ranking_detail.author}</span>
                        </div>
                        {/*<div className='title' style={{*/}
                        {/*    display: 'flex',*/}
                        {/*    alignItems: 'center'*/}
                        {/*}}>{intl.get('MusicRanking')['国家']}：<img*/}
                        {/*    style={{width: '20px', height: '14px', marginRight: '6px'}} src={ranking_detail.countryFlag}*/}
                        {/*    alt=""/><span style={{color: '#333333'}}>{ranking_detail.countryName}</span></div>*/}
                        <div className='title'>{intl.get('MusicRanking')['更新时间']}：<span>{moment(ranking_detail.updateTime).format('YYYY-MM-DD HH:mm:ss')}</span></div>
                        <div className='title'
                             style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div>{intl.get('MusicRanking')['时长']}：<span>{ranking_detail.duration}s</span></div>
                            <div>{intl.get('MusicRanking')['视频数']}：<span>{toKMB(ranking_detail.userCount,2)}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{width: 'calc(100% - 350px)', paddingBottom: '90px', position: 'relative'}}>
                {/*音乐使用趋势分析*/}
                <div style={{width: '100%', backgroundColor: '#FFF',padding:'24px 24px 28px'}}>
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <div className='sd-title'>{intl.get('MusicRanking')['音乐使用趋势分析']}</div>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div style={{display: 'flex',borderRadius:'4px',overflow:'hidden'}}>
                                {
                                    dayList.map((item, index) => (
                                        <span
                                            key={index}
                                            className='tabItem'
                                            style={{
                                                padding: '6px 10px',
                                                marginBottom: '5px',
                                                cursor: 'pointer',
                                                color: index === dayIndex ? '#E94753' : '#CCCCCC',
                                                background: index === dayIndex ? '#FFE7E9' : '#F8F8F8',
                                            }}
                                            onClick={() => setDayIndex(index)}
                                        >{item.text}</span>
                                    ))
                                }
                            </div>
                            <div style={{
                                width: 240,
                                height: 30,
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '10px',
                                border: '1px solid #EEEEEE',
                                color: '#CCCCCC',
                                marginLeft: '13px',
                                fontSize: '12px'
                            }}>
                                <CalendarOutlined style={{ marginRight: '17px' }} />
                                <div style={{ marginRight: '17px' }}>{moment(moment().subtract(dayList[dayIndex].value, 'days')).format('YYYY-MM-DD')}</div>
                                <div style={{ marginRight: '17px' }}>~</div>
                                <div style={{ marginRight: '17px' }}>{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')}</div>
                            </div>
                        </div>
                    </div>
                    <div className='flex-acjb' style={{background:'#F6F6F6',padding:'8px 20px',borderRadius:'4px',marginTop:'20px'}}>
                        <div>{moment(moment().subtract(dayList[dayIndex].value, 'days')).format('YYYY-MM-DD')} ～ {moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')}{intl.get('MusicRanking')['视频数']}{intl.get('MusicRanking')['最高为']}<span style={{color:'#F7385B'}}>{toKMB(trendOptions.maxNum,2)}</span> {intl.get('MusicRanking')['最低为']}<span style={{color:'#F7385B'}}>{toKMB(trendOptions.minNum,2)}</span> {intl.get('MusicRanking')['平均值为']}<span style={{color:'#F7385B'}}>{toKMB(trendOptions.aveNum,2)}</span></div>
                        <div>{intl.get('MusicRanking')['更新时间']}：{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD HH:mm:ss')}</div>
                    </div>
                    <div  style={{ marginTop: 40 }}>
                        {
                            trendOptions.data?.length > 0 ? <Area {...trendOptions}/> :
                                <div style={{ width: '345px', height: '200px', textAlign: 'center', margin: '0 auto', marginTop: '100px' }}>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                                    <div style={{ marginTop: '24px', textAlign: 'center', color: '#333' }}>{intl.get('Common')['暂无数据']}</div>
                                </div>
                        }
                    </div>
                </div>
                {/*相关视频列表*/}
                <div style={{width: '100%', backgroundColor: '#FFF',padding:'24px 24px 28px'}}>
                    <div className='sd-title'>{intl.get('MusicRanking')['相关视频列表']}</div>
                    <MusicRankingVideo id={props.params.id}/>
                </div>
            </div>
        </div>
        <Modal centered visible={showAudio} footer={null} closable={null} onCancel={()=>{setShowAudio(false)}}
               destroyOnClose={true} width={300}
               getContainer={false}
               bodyStyle={{
                   padding: '0', backgroundColor: 'transparent', overflow: 'hidden',textAlign:'center'
               }}>
            <audio controls autoPlay={true} loop>
                <source src={playAudioUrl} type="video/mpeg" />
                <source src={playAudioUrl} type="video/ogg" />
                您的浏览器不支持 audio 标签。
            </audio>
        </Modal>
    </div>
}
export default withRouter(MusicRankingDetail);