import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import { Rate , Input, message, Select, Checkbox, Image, Spin, Pagination, DatePicker, Button } from 'antd';
import * as Api from '../../../service/https';
import '../../../asset/css/MyGoods.scss'
import intl from 'react-intl-universal';
import store from '../../../utils/redux/index'
import { debounce } from '../../../utils/debounce'
import {editMyGoods, getSupplyOrderData, getSupplyOrderList, getSupplyOrderTrend} from "../../../service/https";
import openNotificationWithIcon from "../../../components/Notification";
import { color } from 'echarts';
import moment from 'moment';
const { RangePicker } = DatePicker;

const { Option } = Select

const MyOrder = (props) => {
    const [inputSearchType] = useState(['tkOrderId','productName','accountName'])
    const [searchParams, searchParamsSet] = useState({
        size: 10,
        tkOrderId: null,
        productName: null,
        accountName: null,
        grade: null,
        date: null,
        status: null,
        replyStatus: null,

        timeFilter:{start:null,end:null},
        timeFilterFieldFlag: 1,
        sortFlag: 1,
        orderByFlag: 1
    })
    const [list, listSet] = useState([])
    const [selectList, selectListSet] = useState([])
    const [id_list, id_listSet] = useState([])
    const [loading, setLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [total, totalSet] = useState(0)
    const [dateRanges] = useState({
        '近一周': [moment().startOf('day').subtract(1, 'weeks'), moment()],
        '近一月': [moment().startOf('day').subtract(1, 'month'), moment()],
        '近三月': [moment().startOf('day').subtract(3, 'month'), moment()],
    })
    const [orderStatusValue,orderStatusValueSet] = useState(1)
    const [orderStatusList] = useState([{label:'订单ID',value:1, params:'tkOrderId'},{label:'商品名',value:2, params:'productName'},{label:'用户名',value:3, params:'accountName'}])
    const [commentGradeList] = useState([{ label: '1星', value: 1},{ label: '2星', value: 2},{ label: '3星', value: 3},
        { label: '4星', value: 4},{ label: '5星', value: 5},])
    const [commentStatusList] = useState([{label:'追评',value:1},{label:'有图',value:2},{label:'有评论',value:3}])
    const [commentReplyStatusList] = useState([{label:'未回复',value:1},{label:'已回复',value:2},{label:'已屏蔽',value:3}])
    useEffect(() => {
        // getOrderList()
    }, [searchParams, pageIndex])


    const getOrderList = e => {
        setLoading(true)
        let data = { current: pageIndex,}
        Api.getSupplyOrderList({...data, ...searchParams}).then(res => {
            if (res.code === 200) {
                let t = res.data.records.map(item => {
                    return item.tkOrderId
                })
                let vmList = res.data.records
                setIndeterminate(false)
                setCheckAll(false)
                selectListSet([])
                setCheckedList([])
                id_listSet(t)
                listSet(pageIndex <= 1 ? vmList : [...vmList, ...list])
                totalSet(res.data.total)
                setLoading(false)
            }
        })
    }

    const searchChange = (e, id) => {
        let t = JSON.parse(JSON.stringify(searchParams))
        if(id === 'commentDate'){
            t.timeFilter = { start: e ? moment(e[0]).format('YYYY-MM-DD HH:mm:ss') : null, end: e ? moment(e[1]).format('YYYY-MM-DD HH:mm:ss') : null }
        }else{
            t[id] = e
        }
        searchParamsSet(t)

    }

    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const onChange = (e) => {
        console.log(e)
        setCheckedList(e);
        setIndeterminate(!!e?.length && e?.length < list?.length);
        setCheckAll(e?.length === list?.length);
        let s = []
        list.map((item, index) => {
            if (e.indexOf(item?.tkOrderId) !== -1) {
                s.push(item.tkOrderId)
            }
        })
        selectListSet(s)
    };

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? id_list : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const copyFun = (value, e) => {
        e.stopPropagation()
        // for ie
        if (window.clipboardData) {
            window.clipboardData.clearData();
            window.clipboardData.setData('text', value);
            message.success("复制成功~")
        }
        // for modern browser
        else if (document.execCommand) {
            var element = document.createElement('SPAN');
            element.textContent = value;
            document.body.appendChild(element);
            if (document.selection) {
                var range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (window.getSelection) {
                var range = document.createRange();
                range.selectNode(element);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
            document.execCommand('copy');
            element.remove ? element.remove() : element.removeNode(true);
            message.success("复制成功~")
        } else {
            message.warn("您所用的浏览器不支持快捷复制，请手动复制！")
        }
    }

    const changeInputSearchType = value => {
        let t = JSON.parse(JSON.stringify(searchParams))
        inputSearchType.map(item => t[item] = null)
        orderStatusValueSet(value)
        searchParamsSet(t)
    }

    return (
        <div id='MyGoods'>
            <div style={{ display: 'flex', alignItems:'center' }}>
                <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/before.png" alt=""
                style={{width:'4px'}}/>
                <div style={{fontSize:'20px', fontWeight:600, marginLeft:'9px'}}>管理评价</div>
            </div>
            <div style={{width:'100%', background:'#FFF', padding:'20px', marginTop:'10px'}}>
                <div style={{position:'relative'}}>
                    <div id='login'>
                        <Input onChange={e => searchChange(e.target.value, orderStatusList[orderStatusValue - 1].params)} value={searchParams[orderStatusList[orderStatusValue - 1].params]}
                        style={{ width: '313px', paddingLeft:'100px' }} placeholder={`按照${orderStatusList[orderStatusValue - 1].label}搜索`}
                        suffix={<img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/search.png'} style={{ width: '20px', cursor:'pointer' }} alt="" />} allowClear />
                    </div>
                    <Select aria-readonly={true} value={orderStatusValue} style={{width: 100, position:'absolute', top:0,left:0 ,zIndex:11}}
                            bordered={false} options={orderStatusList} onChange={changeInputSearchType}
                    />
                </div>
                <div className='flex-start' style={{marginTop:20}}>
                    <div className='flex-acjs' style={{width:'50%'}}>
                        <div style={{marginRight:20}}>评价等级</div>
                        <Checkbox.Group options={commentGradeList} onChange={e => searchChange(e, 'commentGrade')} />
                    </div>
                    <div className='flex-acjs' style={{width:'50%'}}>
                        <div style={{marginRight:20}}>评价时间</div>
                        <RangePicker value={searchParams?.commentDate} allowClear format="YYYY-MM-DD"
                                     ranges={dateRanges}
                            onChange={e => searchChange(e, 'commentDate')} placeholder={intl.get('SmallShop')['请选择评价时间']}  />
                    </div>
                </div>
                <div className='flex-start' style={{marginTop:20}}>
                    <div className='flex-acjs' style={{width:'50%'}}>
                        <div style={{marginRight:20}}>商家回复状态</div>
                        <Select allowClear style={{width:100}} options={commentReplyStatusList} value={searchParams.commentReplyStatus} placeholder='请选择'
                                onChange={e => searchChange(e, 'commentReplyStatus')} />
                    </div>
                    <div className='flex-acjs' style={{width:'50%'}}>
                        <div style={{marginRight:20}}>评价状态</div>
                        <Select allowClear style={{width:100}} options={commentStatusList} value={searchParams.commentStatus} placeholder='请选择'
                                onChange={e => searchChange(e, 'commentStatus')}/>
                    </div>
                </div>
            </div>
            <div className='goods_main'>
                <div style={{padding:'5px 0'}}></div>
                <div style={{fontWeight:500, fontSize:'13px', color:'#666'}}>
                    <Checkbox style={{ width: '18px' }} indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}></Checkbox>
                    <div style={{ width: '220px', marginLeft: '15px' }}>商品</div>
                    <div style={{ width: '220px', marginLeft: '15px' }}>评价内容</div>
                    <div style={{ width: '150px' }}>评价等级</div>
                    <div style={{ width: '150px' }}>用户</div>
                    <div style={{ width: '160px' }}>时间</div>
                    <div style={{ width: '100px' }}>状态</div>
                    <div style={{ flex:1 }}>操作</div>
                </div>
            </div>
            <Spin tip="数据加载中..." spinning={loading}>
                <Checkbox.Group value={checkedList} style={{ width: '100%' }} onChange={onChange}>
                    <div className='list'>
                        {
                            list?.length > 0 ?
                                list.map((item, index) => {
                                    return (
                                    <div key={index} style={{fontWeight:500,background:'#FFF', borderRadius:'5px', width:'100%', height:'100px', padding:'20px 24px', display:'flex', alignItems:'center'}}>
                                        <Checkbox style={{ width: '18px' }}  value={item?.tkOrderId} ></Checkbox>
                                        <div style={{ width: '220px', marginLeft: '15px' }}>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <Image src=''
                                                       style={{width:'40px', height:'40px', borderRadius:'20px'}}
                                                       fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}/>
                                                <div style={{marginLeft:'5px'}}>
                                                    <div className='over1'>odifndsfsdfsdfdsfsd</div>
                                                    <div className='over-word' style={{ marginTop:5 }}>ID:12312321312312312</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ width: '220px', marginLeft: '15px', paddingRight:20 }}>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <Image src=''
                                                       style={{width:'40px', height:'40px', borderRadius:'20px'}}
                                                       fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}/>
                                                <div style={{marginLeft:'5px', flex:1}}>
                                                    <div className='over1'>odifndsfsdfsdfdsfsd</div>
                                                    <div style={{ marginTop:5, textAlign:'right', color:'#1B9EFF', cursor:'pointer'}}>查看回复</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ width: '150px' }}><Rate disabled value={5} /></div>

                                        <div style={{ width: '150px' }}>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <Image src=''
                                                       style={{width:'40px', height:'40px', borderRadius:'20px'}}
                                                       fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}/>
                                                <div style={{marginLeft:'5px'}}>
                                                    <div className='over1'>odifndsfsdf</div>
                                                    <div className='over-word' style={{ marginTop:5 }}>菲律宾</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ width: '160px' }}>{item?.createTime}</div>
                                        <div style={{ width: '100px' }}>未回复</div>
                                        <div style={{ flex:1 }}>
                                            <div style={{ marginTop:5, color:'#1B9EFF' }}>回复</div>
                                            <div style={{ marginTop:5, color: '#F7385B' }}>屏蔽</div>
                                        </div>
                                    </div>

                                )
                                })
                                :
                                <div className='flex-acjc' style={{ width: '100%', flexDirection: 'column', paddingTop: '100px' }}>
                                    <Image width={105} height={100} preview={false} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png' alt='' />
                                    <div style={{ marginTop: '23px' }}>暂无数据</div>
                                </div>
                        }
                    </div>
                </Checkbox.Group>
            </Spin>
            <Pagination style={{ float: 'right', margin: '20px 0' }} current={pageIndex} total={total} showTotal={(total) => `共 ${total} 条`} onChange={e => { setPageIndex(e) }} />
        </div>
    )
}
export default withRouter(MyOrder);
