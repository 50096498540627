import React, { useState, useEffect } from 'react'
import { useRoutes } from "react-router-dom";
import Home from '../pages/home/Home'
import StarSelection from '../pages/home/StarSelection'
import Drainage from '../pages/home/Drainage'
import Perfect from '../pages/home/Perfect'
import UserAgreement from '../pages/home/UserAgreement'
import PrivacyAgreement from '../pages/home/PrivacyAgreement'
import Index from '../pages/workbench/Index'
import Workbench from '../pages/workbench/Workbench'
import BloggerRanking from '../pages/workbench/blogger/BloggerRanking'
import BloggerDetail from '../pages/workbench/blogger/BloggerDetail'
import BloggerComparison from '../pages/workbench/BloggerComparison'
import VideoRanking from '../pages/workbench/video/VideoRanking'
import VideoDetail from '../pages/workbench/video/VideoDetail'
import Login from '../pages/home/Login'
import H5Login from '../pages/home/H5Login'
import Member from '../pages/workbench/personal/Member'
import InfluencerAccount from '../pages/workbench/personal/InfluencerAccount'
import EnterpriseAccount from '../pages/workbench/personal/EnterpriseAccount'
import MCNAccount from '../pages/workbench/personal/MCNAccount'
import BuyVip from '../pages/workbench/personal/BuyVip'
import TopicList from '../pages/workbench/topic/TopicList'
import TopicDetail from '../pages/workbench/topic/TopicDetail'
import MyBlogger from '../pages/workbench/collection/MyBlogger'
import MyVideos from '../pages/workbench/collection/MyVideos'
import MyTopic from '../pages/workbench/collection/MyTopic'
import Account from '../pages/workbench/personal/Account'
import VideoDemand from '../pages/workbench/collabration/VideoDemand'
import EnterpriseCertification from '../pages/workbench/personal/EnterpriseCertification'
import LivingDemand from '../pages/workbench/collabration/LivingDemand'
import ReleaseDemand from '../pages/workbench/collabration/ReleaseDemand'
import ReleaseProducts from '../pages/workbench/collabration/ReleaseProducts'
import DemandHall from '../pages/workbench/collabration/DemandHallNew'
import VideoDemandDetail from '../pages/workbench/collabration/VideoDemandDetail'
import MyWallet from '../pages/workbench/revenueManagement/MyWallet'
import MyMcnWallet from '../pages/workbench/revenueManagement/MyMcnWallet'
import McnWallet from '../pages/workbench/revenueManagement/McnWallet'
import AccountRecharge from '../pages/workbench/revenueManagement/AccountRecharge'
import MyCollection from '../pages/workbench/collection/MyCollection'
import Sensation from '../pages/workbench/sensation/Sensation'
import McnMySensation from '../pages/workbench/sensation/McnMySensation'
import SensationDetail from '../pages/workbench/sensation/SensationDetail'
import SubscribedTopics from '../pages/workbench/collection/SubscribedTopics'
import MyGoods from '../pages/workbench/goods/MyGoods'
import GoodsDetail from '../pages/workbench/goods/GoodsDetail'
import GoodsLists from '../pages/workbench/goods/GoodsLists'
import GoodsCollectLists from '../pages/workbench/goods/GoodsCollectLists'
import BusinessDetail from '../pages/workbench/goods/BusinessDetail'
import SmallShopRanking from '../pages/workbench/smallShop/SmallShopRanking'
import SmallShopDetail from '../pages/workbench/smallShop/SmallShopDetail'
import MySmallShop from '../pages/workbench/collection/MySmallShop'
import GoodsRanking from '../pages/workbench/goods/GoodsRanking'
import GoodsRankingDetail from '../pages/workbench/goods/GoodsRankingDetail'
import MyExcellentWorks from '../pages/workbench/personal/MyExcellentWorks'
import MusicRanking from '../pages/workbench/music/MusicRanking'
import MusicRankingDetail from '../pages/workbench/music/MusicRankingDetail'
import WithCargoList from '../pages/workbench/goods/WithCargoList'

import HomeGoodsRanking from '../pages/home/GoodsRanking'
import HomeSmallShopRanking from '../pages/home/SmallShopRanking'
import LoginTab from '../pages/home/LoginTab';
import MyOrder from '../pages/workbench/goods/MyOrder';
import ShopWallet from '../pages/workbench/goods/ShopWallet';
import Withdrawal from '../pages/workbench/goods/Withdrawal';
import DataOverview from '../pages/workbench/goods/DataOverview';
import ShopAccount from '../pages/workbench/goods/ShopAccount';
import SubAccount from '../pages/workbench/goods/SubAccount';
import ShopLogs from '../pages/workbench/goods/ShopLogs';
import ShopInquiry from '../pages/workbench/goods/ShopInquiry';
import GoodsComment from '../pages/workbench/goods/GoodsComment';



const RoutersList = ()=> {
  // const ReleaseProducts = React.lazy(() => import('../pages/workbench/collabration/ReleaseProducts'));
    let routes = useRoutes([
      { path: '/', element: <Home/>,
        children: [
          { path: 'drainage', element: <Drainage/> },
          { path: 'perfect', element: <Perfect/> },
          { path: 'starSelection', element: <StarSelection/> },
          { path: 'login', element: <Login/> },
          { path: 'userAgreement', element: <UserAgreement/> },
          { path: 'privacyAgreement', element: <PrivacyAgreement/> },
          { path: 'homeGoodsRanking', element: <HomeGoodsRanking/> },
          { path: 'homeSmallShopRanking', element: <HomeSmallShopRanking/> },
          { path: 'loginTab', element: <LoginTab/> },
        ]
      },
      { path: 'H5Login', element: <H5Login/>},
      { path: 'index', element: <Index /> ,
        children: [
          { path: 'workbench', element: <Workbench/> },
          { path: 'subAccount', element: <SubAccount/> },
          { path: 'shopAccount', element: <ShopAccount/> },
          { path: 'dataOverview', element: <DataOverview/> },
          { path: 'withdrawal', element: <Withdrawal/> },
          { path: 'myOrder', element: <MyOrder/> },
          { path: 'shopWallet', element: <ShopWallet/> },
          { path: 'bloggerRanking', element: <BloggerRanking/> },
          { path: 'bloggerDetail/:bloggerId', element: <BloggerDetail/> },
          { path: 'myCollection', element: <MyCollection/> },
          { path: 'myBlogger', element: <MyBlogger/> },
          { path: 'myVideos', element: <MyVideos/> },
          { path: 'myTopic', element: <MyTopic/> },
          { path: 'videoDemand', element: <VideoDemand/> },
          { path: 'bloggerComparison', element: <BloggerComparison/> },
          { path: 'videoRanking', element: <VideoRanking/> },
          { path: 'videoDetail/:videoId/:bloggerId', element: <VideoDetail/> },
          { path: 'member', element: <Member />, },
          { path: 'InfluencerAccount', element: <InfluencerAccount />, },
          { path: 'EnterpriseAccount', element: <EnterpriseAccount />, },
          { path: 'member/buyVip', element: <BuyVip/> },
          { path: 'topicList', element: <TopicList />, },
          { path: 'topicDetail/:id/:title', element: <TopicDetail/> },
          { path: 'Account', element:<Account/> },
          { path: 'influencerAccount', element:<InfluencerAccount/> },
          { path: 'EnterpriseCertification', element: <EnterpriseCertification/> },
          { path: 'MCNAccount', element: <MCNAccount/> },
          { path: 'LivingDemand', element: <LivingDemand/> },
          { path: 'releaseDemand', element: <ReleaseDemand/> },
          { path: 'releaseProducts', element: <ReleaseProducts/> },
          { path: 'demandHall', element: <DemandHall/> },
          { path: 'videoDemandDetail', element: <VideoDemandDetail/> },
          { path: 'myWallet', element: <MyWallet/> },
          { path: 'myMcnWallet', element: <MyMcnWallet/> },
          { path: 'mcnWallet', element: <McnWallet/> },
          { path: 'accountRecharge', element: <AccountRecharge/> },
          { path: 'sensation', element: <Sensation/> },
          { path: 'mcnMySensation', element: <McnMySensation/> },
          { path: 'sensationDetail/:bloggerId/:tiktok', element: <SensationDetail/> },
          { path: 'subscribedTopics', element: <SubscribedTopics/> },
          { path: 'MyGoods', element: <MyGoods/> },
          { path: 'GoodsDetail/:id', element: <GoodsDetail/> },
          { path: 'GoodsLists', element: <GoodsLists/> },
          { path: 'GoodsCollectLists', element: <GoodsCollectLists/> },
          { path: 'BusinessDetail/:id', element: <BusinessDetail/> },
          { path: 'SmallShopRanking', element: <SmallShopRanking/> },
          { path: 'SmallShopDetail/:shopId', element: <SmallShopDetail/> },
          { path: 'MySmallShop', element: <MySmallShop/> },
          { path: 'MyExcellentWorks', element: <MyExcellentWorks/> },
          { path: 'GoodsRanking', element: <GoodsRanking/> },
          { path: 'GoodsRankingDetail/:id/:productId', element: <GoodsRankingDetail/> },
          { path: 'MyExcellentWorks', element: <MyExcellentWorks/> },
          { path: 'MusicRanking', element: <MusicRanking/> },
          { path: 'MusicRankingDetail/:id', element: <MusicRankingDetail/> },
          { path: 'WithCargoList', element: <WithCargoList/> },
          { path: 'shopLogs', element: <ShopLogs/> },
          { path: 'shopInquiry', element: <ShopInquiry/> },
          { path: 'goodsComment', element: <GoodsComment/> },
        ]
      },
      // 重定向
      { path: 'home', redirectTo: '/' },
      // 404找不到
      // { path: '*', element: <NotFound /> }
    ]);
    return routes;
}
export default RoutersList
