import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import '../../../asset/css/BloggerRanking.scss'
import { DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import { Select, Checkbox, List, Input, Spin, Skeleton, Divider, Image } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../../utils/redux/index'
import domtoimage from 'dom-to-image';
import intl from 'react-intl-universal';
import { saveAs } from 'file-saver';
import PkModel from '../../../components/PkModel'
import ShareVideoModel from '../../../components/Share/ShareVideoModel'
import * as Api from '../../../service/https'
import toKMB from '../../../utils/KMB'
import moment from 'moment';
import openNotificationWithIcon from '../../../components/Notification'

const { Option } = Select;

const VideoRanking = (props)=>{
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [textJson, setTextJson] = useState(intl.get('BloggerDetail'))
    const [textJsonV, setTextJsonV] = useState(intl.get('VideoRanking'))

    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [widths, setWidths] = useState(0)
    useEffect(()=>{
        // console.log(props)
        getTopData()
        getCountry()
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
        }
    },[])
    
    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(height)
        let num = state.collapsed + (width > 1890 ? 6 : width > 1650 ? 5 : 4)
        setWidths(num)
    }
    useEffect(()=>{
        heightFun()
    },[state.collapsed])

    const [tabList, setTabList] = useState([
        '全部', '英国', '印度尼西亚','美国' ,'日本', '沙特阿拉伯','德国', '越南' ,'巴西' ,'泰国',
        '俄罗斯联邦', '马来西亚', '菲律宾', '阿联酋', '墨西哥', '沙特阿拉伯'
    ])
    const [tabIndex, setTabIndex] = useState(0)
    const [tabShow, setTabShow] = useState(false)
    const changeTab = (index)=>{
        setTabIndex(index)
    }
    const [geolocation, setGeolocation] = useState([])
    const getCountry = ()=>{
        Api.getCountry().then(res=>{
            if(res.code == 200){
                let all = res.data
                all.unshift({id: 0, name: '全部', enName: 'All'})
                setGeolocation(all)
            }
        })
    }


    const [isBig, setBig] = useState(false)
    const changeBig = ()=>{
        
    }


    const [sortNumList, setItems] = useState([
        {start: 0, key: '<', end: 10000},
        {start: 10000, key: '~', end: 100000},
        {start: 100000, key: '~', end: 500000},
        {start: 500000, key: '~', end: 1000000},
        {start: 1000000, key: '~', end: 5000000},
        {start: 5000000, key: '~', end: 10000000},
        {start: 10000000, key: '~', end: 50000000},
        {start: 50000000, key: '<', end: 0},
        {start: '', key: '~', end: ''},
        {start: '', key: '~', end: ''},
        {start: '', key: '~', end: ''},
        {start: '', key: '~', end: ''}
    ]);
    const videoTime = [
        {start: 0, key: '<', end: 15},
        {start: 15, key: '~', end: 30},
        {start: 30, key: '~', end: 60},
        {start: 60, key: '<', end: 0}
    ]
    const [sortPlayIndex, setRangePlayNumDto] = useState(-1)
    const [sortFollowIndex, setRangeFollowNumDto] = useState(-1)
    const [sortCommentIndex, setRangeCommentNumDto] = useState(-1)
    const [sortShareIndex, setRangeShareNumDto] = useState(-1)
    const [sortTimeIndex, setRangeTimeNumDto] = useState(-1)
    const changeMinInput = (index, value)=>{
        let v = JSON.parse(JSON.stringify(sortNumList))
        v[index].start = value
        setItems(v)
    }
    const changeMaxInput = (index, value)=>{
        let v = JSON.parse(JSON.stringify(sortNumList))
        v[index].end = value
        setItems(v)
    }
    const changeRangePlayNumDto = (index)=>{
        setPageIndex(0)
        setRangePlayNumDto(index == undefined ? -2 : index)
        if(index==undefined){
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[8] = {start: '', key: '~', end: ''}
            setItems(v)
        }
    }
    const changeRangeFolloNumDto = (index)=>{
        setPageIndex(0)
        setRangeFollowNumDto(index == undefined ? -2 : index)
        if(index==undefined){
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[9] = {start: '', key: '~', end: ''}
            setItems(v)
        }
    }
    const changeRangeCommentNumDto = (index)=>{
        setPageIndex(0)
        setRangeCommentNumDto(index == undefined ? -2 : index)
        if(index==undefined){
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[10] = {start: '', key: '~', end: ''}
            setItems(v)
        }
    }
    const changeRangeShareNumDto = (index)=>{
        setPageIndex(0)
        setRangeShareNumDto(index == undefined ? -2 : index)
        if(index==undefined){
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[11] = {start: '', key: '~', end: ''}
            setItems(v)
        }
    }
    const changeRangeTimeNumDto = (index)=>{
        setPageIndex(0)
        setRangeTimeNumDto(index == undefined ? -2 : index)
    }

    useEffect(()=>{
        if(sortCommentIndex != -1 || sortFollowIndex != -1 || sortPlayIndex != -1 || sortShareIndex != -1 || sortTimeIndex != -1)loadMoreData()
    },[sortCommentIndex, sortFollowIndex, sortPlayIndex, sortShareIndex, sortTimeIndex])


    const [timeIndex, setTimeIndex] = useState(-1);
    const [timesList, setTimes] = useState([
        textJsonV['近6小时'], textJsonV['近12小时'], textJsonV['近24小时'], textJsonV['近3天'], textJsonV['近7天'], textJsonV['近15天'], textJsonV['近30天'], textJsonV['近90天']
    ]);
    const changeTimes = (checked, index)=>{
        setPageIndex(0)
        setTimeIndex(checked ? index : -1)
    }



    const [personal, setPersonal] = useState(false);
    const [enterprise, setEnterprise] = useState(false);
    const [social, setSocial] = useState(false);
    const [link, setLink] = useState(false);
    const [contact, setContact] = useState(false);


    const [sortList, setSortList] = useState([textJson['发布时间'], intl.get('TopicList').PlaysNum, intl.get('BloggerRanking').LikesNum,
    intl.get('TopicList').CommentsNum, intl.get('TopicList').SharesNum]);
    const [sortIndex, setSortIndex] = useState(0);
    const sortMethod = (index) =>{
        setPageIndex(0)
        setSortIndex(index)
    }


    useEffect(()=>{
        loadMoreData()
    },[sortIndex, timeIndex])



    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const loadMoreData = (page) => {
        if (loading) {
          return;
        }
        setLoading(true);
        let obj = {
            bloggerVideoSortRuler: sortIndex + 1,
            from: page || pageIndex * 10, size: 10, 
            rangePlayDto: sortPlayIndex== (-2 || -1) ? {"start":0 , "end": 0} : sortNumList[sortPlayIndex] , 
            rangeFollowNumDto: sortFollowIndex== (-2 || -1) ? {"start":0 , "end": 0} : sortNumList[sortFollowIndex], 
            rangeCommentNumDto: sortCommentIndex== (-2 || -1) ? {"start":0 , "end": 0} : sortNumList[sortCommentIndex], 
            rangeShareNumDto: sortShareIndex== (-2 || -1) ? {"start":0 , "end": 0} : sortNumList[sortShareIndex], 
            rangeDurationDto: sortTimeIndex== (-2 || -1) ? {"start":0 , "end": 0} : videoTime[sortTimeIndex]
        }
        if(timeIndex != -1){
            obj['videoTimeScope'] = timeIndex + 1
        }
        Api.videoRanking(obj).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setTotal(res.data.total)
                setData(page == 0 || pageIndex == 0 ? res.data.records : [...data, ...res.data.records]);
                setPageIndex(pageIndex+1)
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    };
    const [topData, setTopData] = useState([])
    const getTopData = () => {
        let obj = {
            bloggerVideoSortRuler: 2,
            from: 0, size: 10,
        }
        Api.videoRanking(obj).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setTopData(res.data.data)
            }
        })
    };
    





    // 别表
    const SmallDiv = (item, index) =>{
        return <div key={index} style={{width:'100%', height:'150px', display:'flex', alignItems:'center', 
            backgroundColor: index%2==0 ?'#FFFFFF':'#F6F6F6', cursor:'pointer'}}
            onClick={()=>detail(item,index)}>
            
            <div style={{width:'7.8%', paddingLeft:'19px'}}>
                <div style={{width:'32px', textAlign:'center', fontWeight:500}}>{index+1}</div>
            </div>
            <div style={{width:'38%', display:'flex'}}>
                <div style={{width:'80px', height:'110px', backgroundImage:'url('+item.cover+')',
                    backgroundSize:'cover', borderRadius:'4px', backgroundRepeat:'no-repeat'
                    }}>
                    <div style={{width:'100%', height:'100%', background:'rgba(0,0,0,0.2)', color:'#FFF',
                        textAlign:'center', paddingTop:'86px', borderRadius:'4px', fontSize:'13px'}}>
                        <span>{Math.floor(item.videoDuration/60)}:{(item.videoDuration%60)<10?'0':''}{item.videoDuration%60}</span>
                    </div>
                </div>
                <div className='videoList' style={{width:'calc(100% - 80px - 85px)', paddingLeft:'15px',
                    color:'#888888'}}>
                    <div className='over' style={{fontSize:'16px', fontWeight:'bold', lineHeight:'20px',
                        color:'#333', paddingTop:'2px'}}>{item.videoDesc}</div>
                    <div className='over1' style={{height:'20px', marginTop:'8px'}}>
                        <span style={{marginRight:'5px'}}>#{item.title}</span>
                    </div>
                    <div style={{marginTop:'13px'}}>发布时间：{moment(item.videoCreateTime*1000).format('YYYY-MM-DD hh:mm:ss')}</div>
                </div>
            </div>
            <div style={{width:'23%', display:'flex', alignItems:'center'}}>
                <img src={item.avatarMedium} alt="" 
                    style={{width:'60px', height:'60px', borderRadius:'30px', marginRight:'16px'}}/>
                <div style={{color:'#888888'}}>
                    <div style={{color:'#333333', fontWeight:'bold', fontSize:'16px',
                        display:'flex', alignItems:'center'}}>
                        <div className='over1' style={{height:'25px', fontWeight:'bold', fontSize:'16px', lineHeight:'25px'}}>{item.nickname}</div>
                    </div>
                    TikTok: {item.uniqueId}
                </div>
            </div>
            <div style={{width:'6%'}}>{toKMB(item.playCount,2)}</div>
            <div style={{width:'6%'}}>{toKMB(item.diggCount,2)}</div>
            <div style={{width:'6%'}}>{toKMB(item.commentCount,2)}</div>
            <div style={{width:'6%'}}>{toKMB(item.shareCount,2)}</div>
            <div style={{width:''}}>
                {/* <img onClick={(e)=>palyVideo(e, item,index)} 
                    src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/play_grey.png'} style={{marginRight:'9px'}} alt="" /> */}
                <img onClick={e=>delVideoCollect(e, item, index)}
                    src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/scs.png'} alt="" />
            </div>
        </div>
    }
    // 大图列表
    const BigDiv = (item, index) =>{
        return <div className='BigDivs' style={{width:'240px', height:'382px', backgroundColor:'#FFF', margin:'0 auto', 
            marginBottom: widths==6?'8px':widths==5?'12px':widths==4?'18px':'', borderRadius:'4px',
            cursor:'pointer'}}
            onClick={()=>detail(item,index)}>

            <div style={{width:'100%', height:'240px', backgroundImage:'url('+item.cover+')',
                backgroundSize:'cover', backgroundRepeat:'no-repeat', borderTopLeftRadius:'4px', borderTopRightRadius:'4px',
                }}>
                <div style={{width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.22)',
                    display:'flex', flexFlow:'column', justifyContent:'space-between',
                    borderTopLeftRadius:'4px', borderTopRightRadius:'4px',}}>
                    <div style={{textAlign:'right', paddingTop:'14px', paddingRight:'14px'}}>
                        {/* <img style={{marginLeft:'9px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/SCB.png'} /> */}
                        <img onClick={(e)=>delVideoCollect(e, item,index)} style={{marginLeft:'9px'}} 
                            src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/SCBS.png'} />
                    </div>
                    <div style={{width:'100%', height:'65px', display:'flex', justifyContent:'space-evenly', alignItems:'center',
                        textAlign:'center', color:'#FFF', fontWeight:500, }}>
                        <div>
                            <img style={{width:'18px'}} 
                                src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/playCircleB.png'} />
                            <div style={{fontSize:'13px'}}>{toKMB(item.playCount,2)}</div>
                        </div>
                        <div>
                            <img style={{width:'18px'}} 
                                src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hanhanB.png'} />
                            <div style={{fontSize:'13px'}}>{toKMB(item.diggCount,2)}</div>
                        </div>
                        <div>
                            <img style={{width:'18px'}} 
                                src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pinglunB.png'} />
                            <div style={{fontSize:'13px'}}>{toKMB(item.commentCount,2)}</div>
                        </div>
                        <div>
                            <img style={{width:'18px'}} 
                                src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/fenxiangB.png'} />
                            <div style={{fontSize:'13px'}}>{toKMB(item.shareCount,2)}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{width:'100%', padding:'0 12px'}}>
                <div className='over' style={{height:'40px', fontSize:'16px', fontWeight:'bold', lineHeight:'20px',
                    marginTop:'10px'}}>
                        {item.videoDesc}
                </div>
                <div style={{fontSize:'12px', color:'#888888', marginTop:'5px'}}>{item.createTime}</div>
                <div style={{width:'100%', height:'56px', borderTop:'1px dashed #EEEEEE',
                    display:'flex', alignItems:'center', marginTop:'10px'}}>
                    <Image style={{width:'32px', height:'32px', borderRadius:'16px',}} src={item.avatarMedium} 
                    fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'} />
                    <div style={{display:'flex', alignItems:'center', marginLeft:'9px', flexWrap:'wrap'}}>
                        <span>{item.nickname}
                        </span>
                    </div>
                </div>
            </div>

        </div>
    }

    const detail = (item,index)=>{
        // console.log('detail',index)
        // props.navigate('/index/videoDetail')
        window.open(`/index/videoDetail/${item.videoId}/${item.author}`)
    }
    const addPK = (e, item,index)=>{
        e.stopPropagation();
        console.log('pk',index)
    }
    const palyVideo = (e, item,index)=>{
        e.stopPropagation();
        console.log('collection',index)
        window.open(`https://www.tiktok.com/@${item.elasticSearchUser.nickname}/video/${item.video.id}`)
    }


    const delVideoCollect = (e, item, index) => {
        e.stopPropagation();
        Api.delVideoCollect({videoCollectId	: item.id}).then(res => {
            // console.log(res)
            if (res.code == 200) {
                openNotificationWithIcon('success', intl.get('Common')['取消成功'])
                loadMoreData(0)
            }
        })
    }





    // 生成图片自动下载为png格式（将dom转为二进制再编译下载）
    const [isShare, setIsShare] = useState(false)
    const getBlobPng = () => {
        setIsShare(!isShare)
    }


    const [imgList, setImgList] = useState([1,2,3,4,5,6])
    const [isPk, setIsPk] = useState(false)



    return <div id="BloggerRanking" style={{paddingBottom:'50px'}}>
        {/* <nav style={{width:'100%', position:'relative', fontWeight: 500}}>
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt="" 
                style={{width:'100%'}}/>
            <div style={{width:'100%', height:'100%', position:'absolute', top:0, left:0,
                display:'flex', alignItems:'center', justifyContent:'center'}}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt="" 
                    style={{width:'190px'}}/>
                <div style={{width:'10px', height:'10px', backgroundColor:'black', borderRadius:'5px',
                    margin:'0 20px'}}></div>
                <span style={{fontSize:'20px'}}>我收藏的视频</span>
            </div>
        </nav> */}

        <div style={{width:'100%', backgroundColor:'#FFF', paddingTop:'24px', paddingLeft:'48px', paddingBottom:'25px', 
            fontWeight:500}}>
            {/* 国家 */}
            {/* <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                <label style={{width:state.language == 'zh'?'70px':'90px', marginTop:'6px', textAlign:'right', paddingRight:'26px'}}>{intl.get('BloggerRanking').Country}：</label>
                <div style={{width:'calc(100% - 70px)', height: tabShow?'auto':'39px', display:'flex', flexWrap:'wrap',
                    overflow: tabShow?'':'hidden'}}>
                    {
                        geolocation?.map((item, index)=>{
                            return <span key={index} className='tabItem' style={{padding:'6px 10px', 
                                background: index==tabIndex?'#FFE7E9':'none', marginBottom:'5px',
                                color: index==tabIndex?'#E94753':'#333', borderRadius:'4px', marginRight:'10px',
                                cursor:'pointer'}}
                                onClick={()=>changeTab(index)}>{lang == 'zh' ? item.name: item.enName}</span>
                        })
                    }
                </div>
                <div style={{width:'45px'}}>
                    {!tabShow ? <DownSquareTwoTone style={{fontSize:'18px', marginTop:'8px', cursor:'pointer'}} twoToneColor='#CCCCCC'
                        onClick={()=>setTabShow(true)} /> :
                    <UpSquareTwoTone style={{fontSize:'18px', marginTop:'8px', cursor:'pointer'}} twoToneColor='#CCCCCC'
                        onClick={()=>setTabShow(false)} />}
                </div>
            </div> */}
            
            {/* 筛选 */}
            <div style={{width:'100%', marginTop: '10px', display:'flex'}}>
                <label style={{width:state.language == 'zh'?'70px':'100px', textAlign:'right', paddingRight:state.language == 'zh'?'28px':'32px',
                    marginTop:'5px'}}>
                    {intl.get('BloggerRanking').Screen}：</label>
                <div style={{width:state.language == 'zh'?'calc(100% - 70px)':'calc(100% - 100px)', display:'flex', alignItems:'center', flexWrap:'wrap'}}>
                    <div id='fans' style={{marginBottom:'15px'}}>
                        <span style={{marginRight:'10px'}}>{intl.get('TopicList').PlaysNum}</span> 
                        <Select
                            onClear={e=>changeRangePlayNumDto(undefined)} 
                            allowClear={true}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <div id='login' style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0 10px', paddingBottom:'5px'}}>
                                        <Input value={sortNumList[8].start} 
                                            onChange={e=>changeMinInput(8, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="min" /> ~ 
                                        <Input value={sortNumList[8].end} 
                                            onChange={e=>changeMaxInput(8, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="max" /> 
                                        <span style={{border: sortNumList[8].start>=0&&parseInt(sortNumList[8].end)>parseInt(sortNumList[8].start)?'':'1px solid #EEEEEE', borderRadius:'4px', padding:'5.5px 11px', marginLeft:'5px', cursor:'pointer',
                                            color: sortNumList[8].start>=0&&parseInt(sortNumList[8].end)>parseInt(sortNumList[8].start)?'#FFF':'#CCCCCC', 
                                            background: sortNumList[8].start>=0&&parseInt(sortNumList[8].end)>parseInt(sortNumList[8].start)?'#F7385B':'#FFF', fontSize:'12px'}} 
                                            onClick={()=>sortNumList[8].start>=0&&parseInt(sortNumList[8].end)>parseInt(sortNumList[8].start)?changeRangePlayNumDto(8):null}>确定</span>
                                    </div>
                                </>
                            )}
                            value={(sortPlayIndex == -1||sortPlayIndex ==-2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : ((sortNumList[sortPlayIndex].start>0?toKMB(sortNumList[sortPlayIndex].start):'') + ' '+sortNumList[sortPlayIndex].key+' ' + (sortNumList[sortPlayIndex].end>0?toKMB(sortNumList[sortPlayIndex].end):''))}
                            style={{ width: 200, borderRadius:'26px', marginRight:'40px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={intl.get('TopicList').PleaseSelect}>
                            {sortNumList?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeRangePlayNumDto(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                            ))}
                        </Select>
                    </div>
                    <div id='fans' style={{marginBottom:'15px'}}>
                        <span style={{marginRight:'10px'}}>{intl.get('BloggerRanking').LikesNum}</span> 
                        <Select
                            onClear={e=>changeRangeFolloNumDto(undefined)} 
                            allowClear={true}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <div id='login' style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0 10px', paddingBottom:'5px'}}>
                                        <Input value={sortNumList[9].start}
                                            onChange={e=>changeMinInput(9, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="min" /> ~ 
                                        <Input value={sortNumList[9].end} 
                                            onChange={e=>changeMaxInput(9, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="max" /> 
                                        <span style={{border: sortNumList[9].start>=0&&parseInt(sortNumList[9].end)>parseInt(sortNumList[9].start)?'':'1px solid #EEEEEE', borderRadius:'4px', padding:'5.5px 11px', marginLeft:'5px', cursor:'pointer',
                                            color: sortNumList[9].start>=0&&parseInt(sortNumList[9].end)>parseInt(sortNumList[9].start)?'#FFF':'#CCCCCC', 
                                            background: sortNumList[9].start>=0&&parseInt(sortNumList[9].end)>parseInt(sortNumList[9].start)?'#F7385B':'#FFF', fontSize:'12px'}} 
                                            onClick={()=>sortNumList[9].start>=0&&parseInt(sortNumList[9].end)>parseInt(sortNumList[9].start)?changeRangeFolloNumDto(9):null}>确定</span>
                                    </div>
                                </>
                            )}
                            value={(sortFollowIndex == -1||sortFollowIndex ==-2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : ((sortNumList[sortFollowIndex].start>0?toKMB(sortNumList[sortFollowIndex].start):'') + ' '+sortNumList[sortFollowIndex].key+' ' + (sortNumList[sortFollowIndex].end>0?toKMB(sortNumList[sortFollowIndex].end):''))}
                            style={{ width: 200, borderRadius:'26px', marginRight:'40px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={intl.get('TopicList').PleaseSelect}>
                            {sortNumList?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeRangeFolloNumDto(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                            ))}
                        </Select>
                    </div>
                    <div id='fans' style={{marginBottom:'15px'}}>
                        <span style={{marginRight:'10px'}}>{intl.get('TopicList').CommentsNum}</span> 
                        <Select
                            onClear={e=>changeRangeCommentNumDto(undefined)} 
                            allowClear={true}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <div id='login' style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0 10px', paddingBottom:'5px'}}>
                                        <Input value={sortNumList[10].start} 
                                            onChange={e=>changeMinInput(10, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="min" /> ~ 
                                        <Input value={sortNumList[10].end} 
                                            onChange={e=>changeMaxInput(10, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="max" /> 
                                        <span style={{border: sortNumList[10].start>=0&&parseInt(sortNumList[10].end)>parseInt(sortNumList[10].start)?'':'1px solid #EEEEEE', borderRadius:'4px', padding:'5.5px 11px', marginLeft:'5px', cursor:'pointer',
                                            color: sortNumList[10].start>=0&&parseInt(sortNumList[10].end)>parseInt(sortNumList[10].start)?'#FFF':'#CCCCCC', 
                                            background: sortNumList[10].start>=0&&parseInt(sortNumList[10].end)>parseInt(sortNumList[10].start)?'#F7385B':'#FFF', fontSize:'12px'}} 
                                            onClick={()=>sortNumList[10].start>=0&&parseInt(sortNumList[10].end)>parseInt(sortNumList[10].start)?changeRangeCommentNumDto(10):null}>确定</span>
                                    </div>
                                </>
                            )}
                            value={(sortCommentIndex == -1||sortCommentIndex ==-2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : ((sortNumList[sortCommentIndex].start>0?toKMB(sortNumList[sortCommentIndex].start):'') + ' '+sortNumList[sortCommentIndex].key+' ' + (sortNumList[sortCommentIndex].end>0?toKMB(sortNumList[sortCommentIndex].end):''))}
                            style={{ width: 200, borderRadius:'26px', marginRight:'40px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={intl.get('TopicList').PleaseSelect}>
                            {sortNumList?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeRangeCommentNumDto(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                            ))}
                        </Select>
                    </div>
                    <div id='fans' style={{marginBottom:'15px'}}>
                        <span style={{marginRight:'10px'}}>{intl.get('TopicList').SharesNum}</span> 
                        <Select
                            onClear={e=>changeRangeShareNumDto(undefined)} 
                            allowClear={true}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <div id='login' style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0 10px', paddingBottom:'5px'}}>
                                        <Input value={sortNumList[11].start} 
                                            onChange={e=>changeMinInput(11, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="min" /> ~ 
                                        <Input value={sortNumList[11].end} 
                                            onChange={e=>changeMaxInput(11, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="max" /> 
                                        <span style={{border: sortNumList[11].start>=0&&parseInt(sortNumList[11].end)>parseInt(sortNumList[11].start)?'':'1px solid #EEEEEE', borderRadius:'4px', padding:'5.5px 11px', marginLeft:'5px', cursor:'pointer',
                                            color: sortNumList[11].start>=0&&parseInt(sortNumList[11].end)>parseInt(sortNumList[11].start)?'#FFF':'#CCCCCC', 
                                            background: sortNumList[11].start>=0&&parseInt(sortNumList[11].end)>parseInt(sortNumList[11].start)?'#F7385B':'#FFF', fontSize:'12px'}} 
                                            onClick={()=>sortNumList[11].start>=0&&parseInt(sortNumList[11].end)>parseInt(sortNumList[11].start)?changeRangeShareNumDto(11):null}>确定</span>
                                    </div>
                                </>
                            )}
                            value={(sortShareIndex == -1||sortShareIndex ==-2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : ((sortNumList[sortShareIndex].start>0?toKMB(sortNumList[sortShareIndex].start):'') + ' '+sortNumList[sortShareIndex].key+' ' + (sortNumList[sortShareIndex].end>0?toKMB(sortNumList[sortShareIndex].end):''))}
                            style={{ width: 200, borderRadius:'26px', marginRight:'40px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={intl.get('TopicList').PleaseSelect}>
                            {sortNumList?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeRangeShareNumDto(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                            ))}
                        </Select>
                    </div>
                    <div id='fans' style={{marginBottom:'15px'}}>
                        <span style={{marginRight:'10px'}}>{intl.get('TopicList').duration}</span> 
                        <Select
                            onChange={e=>changeRangeTimeNumDto(e)} allowClear={true}
                            value={(sortTimeIndex == -1||sortTimeIndex ==-2) ? <span>{intl.get('TopicList').PleaseSelect}</span> : sortTimeIndex}
                            style={{ width: 180, borderRadius:'26px', marginRight:'40px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={intl.get('TopicList').PleaseSelect}>
                            {videoTime?.map((item, index) => (
                                <Option key={index}>{item.start>0?item.start+'s':''} {item.key} {item.end>0?item.end+'s':''}</Option>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>

            {/* 时间 */}
            <div style={{width:'100%', marginTop: '15px', display:'flex'}}>
                <label style={{width:state.language == 'zh'?'75px':'100px', textAlign:'right',
                position:'relative', left:'-33px'}}>{intl.get('TopicList').Time}：</label>
                <div style={{width:state.language == 'zh'?'calc(100% - 70px)':'calc(100% - 90px)', display:'flex', flexFlow:'wrap'}}>
                    {
                        timesList?.map((item,index)=>{
                            return <div key={index} style={{marginRight:'30px', marginBottom:'10px'}}>
                                <Checkbox checked={timeIndex==index} onChange={e=>changeTimes(e.target.checked,index)} className='active'>
                                    <span style={{color: personal?'#F7385B':''}}>{item}</span>
                                </Checkbox>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>

        {/* 排序 */}
        <div style={{width:'100%', height:'60px', backgroundColor:'#FFF', marginTop:'10px',
            display:'flex', alignItems:'center', justifyContent:'space-between', paddingLeft: '47px',
            paddingRight: '24px'}}>
            <div style={{display:'flex', alignItems:'center'}}>
            {
                sortList?.map((item,index)=>{
                    return <div key={index} className='active' style={{display:'flex', alignItems:'center', marginRight:'36px',
                        color: sortIndex == index ? '#F7385B' : '#888888', cursor:'pointer'}}
                        onClick={()=> sortMethod(index)}>
                        {item}
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou'+(sortIndex == index ?'s':'')+'.png'} alt="" 
                            style={{marginLeft:'6px', marginTop:'2px'}}/>
                    </div>
                })
            }
            </div>
            <div>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/datu'+(isBig?'s':'')+'.png'} alt="" 
                    style={{cursor:'pointer'}} onClick={()=>setBig(true)}/>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/lb'+(!isBig?'s':'')+'.png'} alt="" 
                    style={{marginLeft:'6px', cursor:'pointer'}}
                    onClick={()=>setBig(false)}/>
            </div>
        </div>

        {/* 列表 */}
        <div style={{width:'100%', backgroundColor: isBig?'':'#FFFFFF', padding: isBig?'0 10px':'24px 24px 24px 30px',
            marginTop: isBig?0:'10px'}}>
            {isBig ? '' : <div style={{width:'100%', height:'50px', display:'flex', alignItems:'center', backgroundColor:'#F6F6F6',
                fontWeight:500}}>
                <div style={{width:'7.8%', paddingLeft:'19px'}}>{intl.get('BloggerRanking').Ranking}</div>
                <div style={{width:'38%', paddingLeft:'24px'}}>{intl.get('TopicList').Video}</div>
                <div style={{width:'23%', paddingLeft:'14px'}}>{intl.get('BloggerRanking').Blogger}</div>
                <div style={{width:'6%', paddingRight:'10px'}}>{intl.get('TopicList').PlaysNum}</div>
                <div style={{width:'6%', paddingRight:'10px'}}>{intl.get('BloggerRanking').LikesNum}</div>
                <div style={{width:'7%', paddingRight:'10px'}}>{intl.get('TopicList').CommentsNum}</div>
                <div style={{width:'6%', paddingRight:'10px'}}>{intl.get('TopicList').SharesNum}</div>
                <div style={{width:''}}>{intl.get('BloggerRanking').Operation}</div>
            </div>}
            {data.length > 0 ? <Spin tip="数据加载中..." spinning={loading}>
            <InfiniteScroll
                dataLength={data.length}
                next={loadMoreData}
                hasMore={data.length < total}
                loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                endMessage={<Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                    dataSource={data} grid={{ gutter: 0, column: isBig ? widths : 1 }}
                    renderItem={(item, index) => <div style={{paddingTop: isBig ? '10px' : 0}}>
                        {isBig ? BigDiv(item, index) : SmallDiv(item, index)}
                    </div>}
                />
            </InfiniteScroll>
            </Spin> : 
            <div style={{width:'345px', height:'200px', textAlign:'center', margin:'0 auto', marginTop:'100px' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                <div style={{marginTop:'24px', textAlign: 'center', color:'#333' }}>{intl.get('Common')['暂无数据']}</div>
            </div>}
            
        </div>



        {isShare ? <ShareVideoModel clickFun={()=>getBlobPng(false)} topData={topData}/> : ''}
    </div>
                        
}
export default withRouter(VideoRanking);