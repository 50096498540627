import {DatePicker, Divider, Input, Select, Space} from "antd";
import intl from "react-intl-universal";
import {Option} from "antd/es/mentions";
import moment from "moment/moment";
import React, {useEffect, useState} from "react";
import withRouter from "../../utils/withRouter";
import store from "../../utils/redux";

const GoodsSelect = (props) => {
    const [pageIndex] = useState(props.pageIndex);
    const [state, setSate] = useState(store.getState());
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [textJson, textJsonSet] = useState(intl.get('GoodsLists'))

    // 佣金率
    const [sortCommissionIndex, setCommissionIndex] = useState(-1)
    const [commissionList, setCommissionInput] = useState([
        { start: 0, key: '',vmEnd:'0.000001'},
        { start: 1, key: '~', end: 10 },
        { start: 10, key: '~', end: 20 },
        { start: 20, key: '~', end: 30 },
        { start: 30, key: '~', end: 40 },
        { start: 40, key: '~', end: 50 },
        { start: 50, key: '~', end: 60 },
        { start: 60, key: '<', end: 70 },
        { start: 70, key: '<', end: 80 },
        { start: '', key: '~', end: '' },
        { start: '', key: '~', end: '' },
        { start: '', key: '~', end: '' }
    ]);
    const [commissionConfirmStatus, setCommissionConfirmStatus] = useState(1)
    const changeCommissionMinInput = (index, value, param) => {
        let v = JSON.parse(JSON.stringify(commissionList))
        v[index].start = value > 80 ? 80 : value
        setCommissionInput(v)
        props.changeCommissionInput(v)
    }
    const changeCommissionMaxInput = (index, value, param) => {
        let v = JSON.parse(JSON.stringify(commissionList))
        v[index].end = value > 80 ? 80 : value
        setCommissionInput(v)
        props.changeCommissionInput(v)
    }
    const changeCommissionSearch = (index) => {
        props.changePageIndex(1)
        if (index == undefined || index < 9) {
            let v = JSON.parse(JSON.stringify(commissionList))
            v[9] = { start: '', key: '~', end: '' }
            setCommissionInput(v)
            props.changeCommissionInput(v)
        }else{
            setCommissionConfirmStatus(commissionConfirmStatus == 1 ? 2 : 1)
            props.changeCommissionConfirmStatus(commissionConfirmStatus == 1 ? 2 : 1)
        }
        setCommissionIndex(index == undefined ? -2 : index)
        props.changeCommissionIndex(index == undefined ? -2 : index)
        if(document.getElementById('SelectCommission')) document.getElementById('SelectCommission').blur()
    }

    // 上架时间
    const [sortTimeIndex, setTimeIndex] = useState(-1)
    const [timeStart, setTimeStart] = useState('')
    const [timeEnd, setTimeEnd] = useState('')
    const [timeConfirmStatus, setTimeConfirmStatus] = useState(0)
    const [showSelectTime, setShowSelectTime] = useState(false)
    const [dateFocus, setDateFocus] = useState(false)
    const timeList = [
        { start: 1, key: '', name: intl.get('GoodsLists')['≤1个月']},
        { start: 1, key: '~', end: 3, name: intl.get('GoodsLists')['1~3个月'] },
        { start: 3, key: '~', end: 6, name: intl.get('GoodsLists')['3~6个月'] },
        { start: 6, key: '~', end: 12, name: intl.get('GoodsLists')['6~12个月'] },
        { start: 12, key: '', name: intl.get('GoodsLists')['≥12个月']}
    ]
    const changeStartDate = (value) => {
        setTimeStart(value ? moment(value).format('yyyy-MM-DD') : '')
        props.changeTimeStart(value ? moment(value).format('yyyy-MM-DD') : '')
    }
    const changeEndDate = (value) => {
        setTimeEnd(value ? moment(value).format('yyyy-MM-DD') : '')
        props.changeTimeEnd(value ? moment(value).format('yyyy-MM-DD') : '')
    }
    const changeTimeSearch = (index) => {
        setDateFocus(false)
        props.changePageIndex(1)
        setTimeIndex(index)
        props.changeTimeIndex(index)
        setTimeConfirmStatus(index == -1 ? timeConfirmStatus == 1 ? 2 : 1 : 0)
        props.changeTimeConfirmStatus(index == -1 ? timeConfirmStatus == 1 ? 2 : 1 : 0)
        if(index >= 0 || !index) {
            setTimeConfirmStatus(0)
            props.changeTimeConfirmStatus(0)
            setTimeStart('')
            props.changeTimeStart('')
            setTimeEnd('')
            props.changeTimeEnd('')
        }
        setShowSelectTime(false)
    }

    const closeTimeSelect = e =>{
        setShowSelectTime(false)
    }

    const changeShowSelectTime = value =>{
        if(dateFocus) setShowSelectTime(true)
        else setShowSelectTime(value)
    }

    useEffect(() => {
        window.addEventListener('click', closeTimeSelect)
        return () => {
            window.removeEventListener('click', closeTimeSelect)
        }
    }, [])

    useEffect(()=>{
        if(!dateFocus) setShowSelectTime(false)
    },[dateFocus])


    // 商品价格
    const [priceConfirmStatus, setPriceConfirmStatus] = useState(1)
    const [sortPriceIndex,setPriceIndex] = useState(props.sortPriceIndex)
    const [priceList,setPriceInput] = useState(props.priceList)
    const changePriceMin = (index, value) => {
        let v = JSON.parse(JSON.stringify(priceList))
        v[index].start = value ? value : ''
        setPriceInput(v)
        props.changePriceInput(v)
    }
    const changePriceMax = (index, value) => {
        let v = JSON.parse(JSON.stringify(priceList))
        v[index].end = value ? value : ''
        setPriceInput(v)
        props.changePriceInput(v)
    }
    const changePriceSearch = (index) => {
        props.changePageIndex(1)
        if (index == undefined || index < 5) {
            let v = JSON.parse(JSON.stringify(priceList))
            v[5] = { start: '', key: '~', end: '', pre:'' }
            setPriceInput(v)
            props.changePriceInput(v)
        }else{
            setPriceConfirmStatus(priceConfirmStatus == 1 ? 2 : 1)
            props.changePriceConfirmStatus(priceConfirmStatus == 1 ? 2 : 1)
        }
        setPriceIndex(index == undefined ? -2 : index)
        props.changePriceIndex(index == undefined ? -2 : index)
        if(document.getElementById('SelectPrice')) document.getElementById('SelectPrice').blur()
    }


    return <div id="GoodsSelect">
        <div style={{width: '100%', marginTop:26}} className='flex-ac'>
        <label style={{ width: 70, marginRight:10, textAlign:'right'}}>{textJson['筛选：']}</label>
        <div id='PriceSelectBox' style={{marginRight:20}}>
            <span>{textJson['商品价格']}</span>
            <Select
                id='SelectPrice'
                onClear={e => changePriceSearch(undefined)}
                allowClear={true}
                listHeight={500}
                dropdownRender={menu => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <div id='login' style={{padding: '0 10px', paddingBottom: '5px' }}>
                            <div style={{marginBottom:10}}>{textJson['自定义']}</div>
                            <div style={{position:'relative'}}>
                                <Input value={priceList[5].start}
                                       onChange={e => changePriceMin(5, e.target.value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1'),'commission')}
                                       style={{ paddingLeft: '16px', paddingRight: '4px', textAlign: 'left', marginBottom:8,display:'block' }} placeholder="min" />
                                {priceList[5].start ? <div className='pos-left flex-ac' style={{left:5}}>$</div> : ''}
                            </div>
                            <div style={{position:'relative'}}>
                                <Input value={priceList[5].end}
                                       onChange={e => changePriceMax(5, e.target.value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1'),'commission')}
                                       style={{ paddingLeft: '16px', paddingRight: '4px', textAlign: 'left', marginBottom:8,display:'block' }} placeholder="max" />
                                {priceList[5].end ?<div className='pos-left flex-ac' style={{left:5}}>$</div> : ''}
                            </div>
                            <div style={{
                                textAlign:'center',
                                border: (priceList[5].start && priceList[5].end && Number(priceList[5].end) >= Number(priceList[5].start)) || (priceList[5].start && !priceList[5].end) || (!priceList[5].start && priceList[5].end) ? '' : '1px solid #EEEEEE', borderRadius: '4px', padding: '5.5px 11px', cursor: 'pointer',
                                color: (priceList[5].start && priceList[5].end && Number(priceList[5].end) >= Number(priceList[5].start)) || (priceList[5].start && !priceList[5].end) || (!priceList[5].start && priceList[5].end) ? '#FFF' : '#CCCCCC',
                                background: (priceList[5].start && priceList[5].end && Number(priceList[5].end) >= Number(priceList[5].start)) || (priceList[5].start && !priceList[5].end) || (!priceList[5].start && priceList[5].end) ? '#F7385B' : '#FFF', fontSize: '12px'
                            }}
                                 onClick={() => (priceList[5].start && priceList[5].end && Number(priceList[5].end) >= Number(priceList[5].start)) || (priceList[5].start && !priceList[5].end) || (!priceList[5].start && priceList[5].end)  ? changePriceSearch(5) : null}>{textJson['确定']}</div>
                        </div>
                    </>
                )}
                value={(sortPriceIndex == -1 || sortPriceIndex == -2) ? <span>{intl.get('MusicRanking')['请选择']}</span> : ((priceList[sortPriceIndex].start ? (priceList[sortPriceIndex].pre) +  '$' + priceList[sortPriceIndex].start : '')  + ' ' + priceList[sortPriceIndex].key + ' ' + (priceList[sortPriceIndex].end > 0 ? (priceList[sortPriceIndex].pre) + '$' + priceList[sortPriceIndex].end : ''))}
                style={{ width: 200, borderRadius: '26px', marginLeft: '10px' }} getPopupContainer={() => document.getElementById('PriceSelectBox')}
                placeholder={intl.get('MusicRanking')['请选择']}>
                {priceList?.map((item, index) => (
                    index < 5 ? <Option key={index}><div onClick={e=>changePriceSearch(index)}>{item.pre}{item.start>=0? '$' + item.start:''} {item.key} {item.end>0? '$' + item.end :''}</div></Option> : ''
                ))}
            </Select>
        </div>
        <div id='TimeSelectBox' style={{marginRight:20}} onClick={e =>{e.stopPropagation()}}>
            <span>{textJson['上架时间']}</span>
            <Select
                id='SelectTime'
                open={showSelectTime}
                onClear={e => changeTimeSearch(undefined)}
                onDropdownVisibleChange={(visible) => {changeShowSelectTime(visible)}}
                allowClear={true}
                listHeight={500}
                dropdownRender={menu => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <div id='login' style={{padding: '0 10px', paddingBottom: '5px' }}>
                            <div style={{marginBottom:10}}>{textJson['自定义']}</div>
                            <Space direction="vertical" style={{marginBottom:10}}>
                                <DatePicker onFocus={()=>{setDateFocus(true)}} onChange={changeStartDate} value={timeStart ? moment(timeStart) : ''} />
                                <DatePicker onFocus={()=>{setDateFocus(true)}} onChange={changeEndDate} value={timeEnd ? moment(timeEnd) : ''} />
                            </Space>
                            <div style={{
                                textAlign:'center',
                                border: (timeStart && timeEnd && new Date(timeEnd).getTime() >= new Date(timeStart).getTime()) || (timeStart && !timeEnd) || (!timeStart && timeEnd)   ? '' : '1px solid #EEEEEE', borderRadius: '4px', padding: '5.5px 11px', cursor: 'pointer',
                                color: (timeStart && timeEnd && new Date(timeEnd).getTime() >= new Date(timeStart).getTime()) || (timeStart && !timeEnd) || (!timeStart && timeEnd)   ? '#FFF' : '#CCCCCC',
                                background: timeStart && timeEnd && new Date(timeEnd).getTime() >= new Date(timeStart).getTime() || (timeStart && !timeEnd) || (!timeStart && timeEnd)   ? '#F7385B' : '#FFF', fontSize: '12px'
                            }}
                                 onClick={() =>  (timeStart && timeEnd && new Date(timeEnd).getTime() >= new Date(timeStart).getTime()) || (timeStart && !timeEnd) || (!timeStart && timeEnd)  ? changeTimeSearch(-1) : null}>{textJson['确定']}</div>
                        </div>
                    </>
                )}
                value={sortTimeIndex < 0 && !timeConfirmStatus ? <span>{intl.get('MusicRanking')['请选择']}</span> : ( timeConfirmStatus ? (timeEnd ? '' : '>') + timeStart + (timeStart && timeEnd ? '-' : '') + (timeStart ? '' : '<') +  timeEnd : sortTimeIndex >= 0 ? timeList[sortTimeIndex].name : '' || '')}
                style={{ width: 200, borderRadius: '26px', marginLeft: '10px' }} getPopupContainer={() => document.getElementById('TimeSelectBox')}
                placeholder={intl.get('MusicRanking')['请选择']}>
                {timeList.map((item, index) => (
                    <Option key={index}><div onClick={e=>changeTimeSearch(index)}>{timeList[index].name}</div></Option>
                ))}
            </Select>
        </div>
        <div id='CommissionSelectBox'>
            <span>{textJson['佣金率']}</span>
            <Select
                id='SelectCommission'
                onClear={e => changeCommissionSearch(undefined)}
                allowClear={true}
                listHeight={500}
                dropdownRender={menu => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <div id='login' style={{padding: '0 10px', paddingBottom: '5px' }}>
                            <div style={{marginBottom:10}}>{textJson['自定义']}</div>
                            <div style={{position:'relative'}}>
                                <Input value={commissionList[9].start}
                                       onChange={e => changeCommissionMinInput(9, e.target.value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1'),'commission')}
                                       style={{ paddingLeft: '10px', paddingRight: '4px', textAlign: 'left', marginBottom:8,display:'block' }} placeholder="min" />
                                {commissionList[9].start ? <div className='pos-left flex-ac' style={{left:commissionList[9].start.length < 2 ? 20 : commissionList[9].start.length == 2 ? 30 : commissionList[9].start.length * 10}}>%</div> : ''}
                            </div>
                            <div style={{position:'relative'}}>
                                <Input value={commissionList[9].end}
                                       onChange={e => changeCommissionMaxInput(9, e.target.value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1'),'commission')}
                                       style={{ paddingLeft: '10px', paddingRight: '4px', textAlign: 'left', marginBottom:8,display:'block' }} placeholder="max" />
                                {commissionList[9].end ?<div className='pos-left flex-ac' style={{left:commissionList[9].end.length < 2 ? 20 : commissionList[9].end.length == 2 ? 30 : commissionList[9].end.length * 10}}>%</div> : ''}
                            </div>
                            <div style={{
                                textAlign:'center',
                                border: (commissionList[9].start && commissionList[9].end && Number(commissionList[9].end) >= Number(commissionList[9].start)) || (commissionList[9].start && !commissionList[9].end) || (!commissionList[9].start && commissionList[9].end) ? '' : '1px solid #EEEEEE', borderRadius: '4px', padding: '5.5px 11px', cursor: 'pointer',
                                color: (commissionList[9].start && commissionList[9].end && Number(commissionList[9].end) >= Number(commissionList[9].start)) || (commissionList[9].start && !commissionList[9].end) || (!commissionList[9].start && commissionList[9].end) ? '#FFF' : '#CCCCCC',
                                background: (commissionList[9].start && commissionList[9].end && Number(commissionList[9].end) >= Number(commissionList[9].start)) || (commissionList[9].start && !commissionList[9].end) || (!commissionList[9].start && commissionList[9].end) ? '#F7385B' : '#FFF', fontSize: '12px'
                            }}
                                 onClick={() => (commissionList[9].start && commissionList[9].end && Number(commissionList[9].end) >= Number(commissionList[9].start)) || (commissionList[9].start && !commissionList[9].end) || (!commissionList[9].start && commissionList[9].end)  ? changeCommissionSearch(9) : null}>{textJson['确定']}</div>
                        </div>
                    </>
                )}
                value={(sortCommissionIndex == -1 || sortCommissionIndex == -2) ? <span>{intl.get('MusicRanking')['请选择']}</span> : ((commissionList[sortCommissionIndex].start >= 0 ? (commissionList[sortCommissionIndex].start || 0) + '%' : '') + ' ' + commissionList[sortCommissionIndex].key + ' ' + (commissionList[sortCommissionIndex].end > 0 ? commissionList[sortCommissionIndex].end + '%' : ''))}
                style={{ width: 200, borderRadius: '26px', marginLeft: '10px' }} getPopupContainer={() => document.getElementById('CommissionSelectBox')}
                placeholder={intl.get('MusicRanking')['请选择']}>
                {commissionList?.map((item, index) => (
                    index < 9 ? <Option key={index}><div onClick={e=>changeCommissionSearch(index)}>{item.start>=0?item.start + '%':''} {item.key} {item.end>0?item.end + '%':''}</div></Option> : ''
                ))}
            </Select>
        </div>
    </div>
    </div>
}
export default withRouter(GoodsSelect);