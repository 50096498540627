import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import '../../asset/css/BloggerRanking.scss'
import { DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import { Select, Checkbox, List, notification, Image, Skeleton, Divider, Spin, Input, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../utils/redux/index'
import domtoimage from 'dom-to-image';
import intl from 'react-intl-universal';
import { saveAs } from 'file-saver';
import PkModel from '../../components/PkModel'
import ShareCommonModel from "../../components/Share/ShareCommonModel";
import * as Api from '../../service/https'
import toKMB from '../../utils/KMB'
import moment from 'moment';
import three from '../../utils/three';

const { Option } = Select;

const SmallShopRanking = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh'
    const [textJsonV, setTextJsonV] = useState(intl.get('VideoRanking'))
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);
    const shareObj = {
        shareName:'SmallShopRanking',
        topTitle: intl.get('ShareModel')['销量'],
        topDayType: intl.get('ShareModel')['日榜'],
        itemImg:'avatarMedium',
        itemTitle:'nickname',
        itemSubTitlePre:'TikTok',
        itemSubTitle:'uniqueId',
        itemLeftTag: intl.get('ShareModel')['销量'],
        itemLeftValue: 'soldCount',
        itemLeftType:'number',
        itemLeftImg:'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/xiaoliang.png?1111',
        itemRightTag:intl.get('ShareModel')['销售额'],
        itemRightValue:'soldTotal',
        itemRightType:'number',
        itemRightImg:'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/xiaoshoue.png?1111',
    }
    const [widths, setWidths] = useState(0)
    useEffect(() => {
        // console.log(props)
        // insertHistory()
        getCargo()
        getCountry()
        // window.scrollTo(0, 0)
        heightFun()
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
            notification.destroy()
        }
    }, [])
    const openNotificationWithIcon = (type, message = '')=> {
        notification[type]({
          description: message, top: 100, duration: 3
        });
    };

    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(height)
        let num = state.collapsed + (width > 1890 ? 6 : width > 1650 ? 5 : 4)
        setWidths(num)
    }
    useEffect(() => {
        heightFun()
    }, [state.collapsed])

    const [tabIndex, setTabIndex] = useState(-1)
    const [tabShow, setTabShow] = useState(false)
    const changeTab = (index) => {
        setPageIndex(1)
        setTabIndex(index)
    }
    const [classList, setClassList] = useState([])
    const [classIndex, setClassIndex] = useState(0)
    const [classShow, setClassShow] = useState(false)
    const sortClassIndex = (index) => {
        setPageIndex(1)
        setClassIndex(index)
    }
    const getCargo = ()=>{
        Api.getCargo().then(res=>{
            if(res.code == 200){
                let all = res.data
                all.unshift({id: 0, goodsName: '全部', goodsEnglishName: 'All'})
                setClassList(all)
            }
        })
    }

    const [geolocation, setGeolocation] = useState([])
    const getCountry = ()=>{
        Api.getShopCountry().then(res=>{
            if(res.code == 200){
                let all = res.data
                setGeolocation(all)
            }
        })
    }


    const [isBig, setBig] = useState(false)
    const changeBig = () => {

    }


    const [timeIndex, setTimeIndex] = useState(-1);
    const [timesList, setTimes] = useState([
        {name:textJsonV['近6小时'], val:-6, type:'h'},
        {name:textJsonV['近12小时'], val:-12, type:'h'},
        {name:textJsonV['近24小时'], val:-24, type:'h'},
        {name:textJsonV['近3天'], val:-3, type:'d'},
        {name:textJsonV['近7天'], val:-7, type:'d'},
        {name:textJsonV['近15天'], val:-15, type:'d'},
        {name:textJsonV['近30天'], val:-30, type:'d'},
        {name:textJsonV['近90天'], val:-90, type:'d'}
    ]);
    const changeTimes = (checked, index) => {
        setPageIndex(1)
        setTimeIndex(checked ? index : -1)
    }

    const [personal, setPersonal] = useState(false);
    const [sortList, setSortList] = useState([
        {name:intl.get('SmallShop')['销量'], val:'sold_count', type:'orderByDesc'},
        {name:intl.get('SmallShop')['销售额'], val:'sold_total', type:'orderByAsc'},
        {name:intl.get('SmallShop')['关联商品数'], val:'product_count', type:'orderByAsc'},
        {name:intl.get('SmallShop')['关联博主数'], val:'blogger_count', type:'orderByAsc'}
    ]);
    const [sortIndex, setSortIndex] = useState(0);
    const sortMethod = (index) => {
        let obj = JSON.parse(JSON.stringify(sortList))
        obj[index].type = sortList[index].type == 'orderByDesc' ? 'orderByAsc' : 'orderByDesc'
        setSortList(obj)
        setPageIndex(1)
        setSortIndex(index)
    }

    useEffect(() => {
        loadMoreData()
    }, [sortIndex, classIndex, tabIndex, timeIndex, sortList])





    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);

    const [shareData, setShareData] = useState([]);

    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            current: pageIndex, size: 20
        }, objs = {
            countryId: tabIndex > -1 ? geolocation[tabIndex].id :'',
            time: timeIndex > -1 ? moment().add(timesList[timeIndex].val, timesList[timeIndex].type).format("YYYY-MM-DD HH:mm:ss") :'',
            [sortList[sortIndex].type]: sortList[sortIndex].val
        }
        Api.getShopList(obj, objs).then(res => {
            console.log(res,666);
            if (res.code == 200) {
                if (shareData.length == 0) setShareData(res.data.records)
                setTotal(res.data.total)
                setData(pageIndex == 1 ? res.data.records : [...data, ...res.data.records]);
                setPageIndex(pageIndex + 1)
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    };

    // 别表
    const SmallDiv = (item, index) => {
        return <div key={index} style={{
            width: '100%', height: '100px', display: 'flex', alignItems: 'center',
            backgroundColor: index % 2 == 0 ? '#FFFFFF' : '#F6F6F6'
        }}>
            <div style={{ width: '9%', paddingLeft: '19px' }}>
                {index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png'} alt=""
                    style={{}} /> :
                    <div style={{ width: '32px', textAlign: 'center', fontWeight: 500 }}>{index + 1}</div>
                }
            </div>
            <div style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                <Image onClick={e => e.stopPropagation()} src={item.avatarMedium} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                    preview={false}
                    style={{ width: '60px', height: '60px', borderRadius: '30px', marginRight: '16px' }} />
                <div style={{ color: '#888888' }}>
                    <div style={{
                        color: '#333333', fontWeight: 'bold', fontSize: '16px',
                        display: 'flex', alignItems: 'center'
                    }}>
                        <div className='over' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item.nickname}</div>
                        {item.verified ? <img style={{ marginLeft: '3px', width: '14px', height: '14px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} /> : ''}
                        {item.bioLink?.link != null ? <img style={{ marginLeft: '3px', width: '13px', height: '13px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/lianjie.png'} /> : ''}
                    </div>
                    TikTok: {item.uniqueId}
                    {/* <div style={{fontSize:'12px', color:'#F7385B', display:'flex', marginTop:'3px'}}>
                        {
                            (lang=='zh'?item.cargoClassificationInterpretation:item.cargoClassificationInterpretationEn)?.split(',')?.map((val,index)=>{
                                return <span key={index} style={{background:'#FFE7E9', padding:'1px 10px', borderRadius:'15px', marginRight:'6px'}}>{val}</span>
                            })
                        }
                    </div> */}
                </div>
            </div>
            <div style={{ width: '12%', float:'left', display:'flex', alignItems:'center' }}>
                <img src={item.countryFlag} style={{width:'14px', marginRight:'3px'}} />{lang=='zh'?item.countryName:item.countryEnName}
            </div>
            <div style={{ width: '10%' }}>{three(item.soldCount)}</div>
            <div style={{ width: '12%' }}>{item.currencyStr} {three(item.soldTotal)}</div>
            <div style={{ width: '11%' }}>{item.productCount}</div>
            <div style={{ width: '9%' }}>{item.bloggerCount}</div>
            {/* <div style={{ width: '10.5%' }}>{item.shopVideos}</div> */}
            {/*<div style={{ width: '' }}>*/}
            {/*    /!* <Tooltip placement="top" title={<span style={{ fontSize: '12px' }}>添加PK</span>}>*/}
            {/*        <img onClick={(e) => isChecked(item) ? delPkItem(e, item, index) : addPK(e, item, index)}*/}
            {/*            src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pk' + (isChecked(item) ? 's' : '') + '.png'} style={{ marginRight: '9px', cursor: 'pointer' }} alt="" />*/}
            {/*    </Tooltip> *!/*/}
            {/*    <img onClick={(e)=>addCollection(e, item,index)} style={{ cursor:'pointer' }}*/}
            {/*        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc'+(item.collect?'s':'')+'.png'} alt="" />*/}
            {/*</div>*/}
        </div>
    }

    const addCollection = (e, item,index)=>{
        e.stopPropagation();
        // console.log('collection',index)
        Api.shopCollect(item.id).then(res=>{
            if(res.code == 200){
                let obj = JSON.parse(JSON.stringify(data))
                obj[index].collect = !data[index].collect
                setData(obj)
                openNotificationWithIcon('success', intl.get('Common')[obj[index].collect?'收藏成功':'取消成功'])
            }
        })
    }

    const isChecked = (item) => {
        let bools = false
        for (let index = 0; index < imgList.length; index++) {
            const e = imgList[index]
            if (item.id == e.bloggerId) {
                bools = true
                break
            }
        }
        return bools
    }

    const detail = (item, index) => {
        window.open(`/index/SmallShopDetail/${item.id}`)
    }
    const addPK = (e, item, index) => {
        e.stopPropagation();
        if (imgList.length < 6) {
            let obj = {
                bloggerId: item.id, uniqueId: item.uniqueId, nickname: item.nickname,
                avatarMedium: item.avatarMedium
            }
            Api.insertPk(obj).then(res => {
                if (res.code == 200) {
                    insertHistory()
                }
            })
        }
    }
    const delPkItem = (e, item) => {
        e.stopPropagation()
        let delIndex = -1
        for (let index = 0; index < imgList.length; index++) {
            const e = imgList[index]
            if (item.id == e.bloggerId) {
                delIndex = index
                break
            }
        }
        if (delIndex > -1) {
            Api.delItemPK({ inventoryId: imgList[delIndex].id }).then(res => {
                if (res.code == 200) {
                    insertHistory()
                }
            })
        }
    }





    // 生成图片自动下载为png格式（将dom转为二进制再编译下载）
    const [isShare, setIsShare] = useState(false)
    const getBlobPng = () => {
        setIsShare(!isShare)
    }



    // 获取PK
    const [imgList, setImgList] = useState([])
    const insertHistory = () => {
        Api.insertHistory().then(res => {
            if (res.code == 200) {
                notification.destroy()
                setImgList(res.data)
            }
        })
    }
    useEffect(() => {
        notification.destroy()
        if (imgList.length > 0) {
            openNotification()
        }
    }, [imgList])



    const [isPk, setIsPk] = useState(false)
    useEffect(() => {
        if (isShare) {
            notification.destroy()
        } else {
            if (imgList.length > 0) openNotification()
        }
    }, [isShare])
    useEffect(() => {
        if (imgList.length > 0 && !isPk) {
            openNotification()
        }
    }, [isPk])
    const openNotification = () => {
        notification.open({
            duration: null,
            getContainer: () => document.getElementById('BloggerRanking'),
            placement: 'bottomLeft',
            message: <div style={{ fontWeight: 500 }}>{intl.get('BloggerComparison').BloggerComparison} <span style={{ color: '#888888' }}>{imgList.length}/6</span> </div>,
            description: <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0' }}>
                <div style={{ display: 'flex' }}>
                    {
                        imgList?.map((item, index) => {
                            return <img key={index} src={item.avatarMedium} alt=""
                                style={{
                                    width: '40px', height: '40px', borderRadius: '20px', marginRight: '-12px',
                                    border: '3px solid #FFF', position: 'relative', zIndex: 10 - index
                                }} />
                        })
                    }
                </div>
                <div style={{
                    width: '80px', height: '30px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                    borderRadius: '4px', cursor: 'pointer'
                }}
                    onClick={() => pkResult()}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pkdb.png'} alt=""
                        style={{ width: '14px', marginRight: '4px', }} />{intl.get('BloggerComparison').Contrast}
                </div>
            </div>,
            className: 'custom-class',
            style: {
                width: 350,
            },
            closeIcon: <div>{intl.get('BloggerComparison').Empty}</div>,
            onClose: () => {
                notification.destroy()
                Api.delAllPK().then(res => {
                    if (res.code == 200) {
                        insertHistory()
                    }
                })
            }
        });
    };

    const [pkResultList, setPkResultList] = useState([])
    useEffect(() => {
        if (pkResultList.length > 0) setIsPk(true)
    }, [pkResultList])
    const pkResult = () => {
        let obj = {
            bloggerList: imgList, type: 1
        }
        Api.bloggerCompare(obj).then(res => {
            if (res.code == 200) {
                setPkResultList(res.data)
            }
        })
    }



    return <div id="SmallShopRanking" style={{ paddingBottom: '50px' }}>
        <nav style={{ width: '100%', position: 'relative', fontWeight: 500 }}>
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt=""
                style={{ width: '100%' }} />
            <div style={{
                width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt=""
                    style={{ width: '190px' }} />
                <div style={{
                    width: '10px', height: '10px', backgroundColor: 'black', borderRadius: '5px',
                    margin: '0 20px'
                }}></div>
                <span style={{ fontSize: '20px' }}>{intl.get('SmallShop')['小店排行榜']}</span>
                <div style={{
                    width: '166px', height: '48px', textAlign: 'center', lineHeight: '48px',
                    backgroundImage: 'url(' + 'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/back.png' + ')', backgroundSize: '100% 100%',
                    color: '#FFF', marginLeft: '34px', paddingRight: '10px', cursor: 'pointer'
                }}
                    onClick={getBlobPng}>{intl.get('SmallShop')['分享小店榜单']}</div>
            </div>
        </nav>

        <div style={{
            width: '100%', backgroundColor: '#FFF', paddingTop: '24px', paddingLeft: '48px', paddingBottom: '25px',
            fontWeight: 500
        }}>
            {/* 国家 */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ width: state.language == 'zh' ? '102px' : '90px', marginTop: '6px', textAlign: 'right', paddingRight: '26px' }}>{intl.get('BloggerRanking').Country}：</label>
                <div style={{
                    width: 'calc(100% - 70px)', height: tabShow ? 'auto' : '39px', display: 'flex', flexWrap: 'wrap',
                    overflow: tabShow ? '' : 'hidden'
                }}>
                    <span className='tabItem' style={{
                        padding: '6px 10px',
                        background: -1 == tabIndex ? '#FFE7E9' : 'none', marginBottom: '5px',
                        color: -1 == tabIndex ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                        cursor: 'pointer'
                    }}
                                onClick={() => changeTab(-1)}>{lang=='zh'?'全部':'All'}</span>
                    {
                        geolocation?.map((item, index) => {
                            return <span key={index} className='tabItem' style={{
                                padding: '6px 10px',
                                background: index == tabIndex ? '#FFE7E9' : 'none', marginBottom: '5px',
                                color: index == tabIndex ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                                cursor: 'pointer'
                            }}
                                onClick={() => changeTab(index)}>{lang == 'zh' ?item.name:item.enName}</span>
                        })
                    }
                </div>
                <div style={{ width: '45px' }}>
                    {!tabShow ?
                        <DownSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                            onClick={() => { setTabShow(true) }} />
                        :
                        <UpSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                            onClick={() => setTabShow(false)} />}
                </div>
            </div>
            {/* 带货分类 */}
            {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ width: state.language == 'zh' ? '102px' : '90px', marginTop: '6px', textAlign: 'right', paddingRight: '26px' }}>{intl.get('SmallShop')['小店类型']}：</label>
                <div style={{
                    width: 'calc(100% - 70px)', height: classShow ? 'auto' : '39px', display: 'flex', flexWrap: 'wrap',
                    overflow: classShow ? '' : 'hidden'
                }}>
                    {
                        classList?.map((item, index) => {
                            return <span key={index} className='tabItem' style={{
                                padding: '6px 10px',
                                background: index == classIndex ? '#FFE7E9' : 'none', marginBottom: '5px',
                                color: index == classIndex ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                                cursor: 'pointer'
                            }}
                                onClick={() => sortClassIndex(index)}>{lang == 'zh' ?item.goodsName:item.goodsEnglishName}</span>
                        })
                    }
                </div>
                <div style={{ width: '45px' }}>
                    {!classShow ?
                        <DownSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                            onClick={() => { setClassShow(true) }} />
                        :
                        <UpSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                            onClick={() => setClassShow(false)} />}
                </div>
            </div> */}



            {/* 时间 */}
            <div style={{ width: '100%', marginTop: '15px', display: 'flex' }}>
                <label style={{
                    width: state.language == 'zh' ? '102px' : '90px', textAlign: 'right', paddingRight:'29px'
                    // position: 'relative', left: '-13px'
                }}>{intl.get('TopicList').Time}：</label>
                <div style={{ width: state.language == 'zh' ? 'calc(100% - 70px)' : 'calc(100% - 90px)', display: 'flex', flexFlow: 'wrap' }}>
                    {
                        timesList?.map((item, index) => {
                            return <div key={index} style={{ marginRight: '30px', marginBottom: '10px' }}>
                                <Checkbox checked={timeIndex == index} onChange={e => changeTimes(e.target.checked, index)} className='active'>
                                    <span style={{ color: personal ? '#F7385B' : '' }}>{item.name}</span>
                                </Checkbox>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>

        {/* 排序 */}
        <div style={{
            width: '100%', height: '60px', backgroundColor: '#FFF', marginTop: '10px',
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '47px',
            paddingRight: '24px'
        }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {
                    sortList?.map((item, index) => {
                        return <div key={index} className='active' style={{
                            display: 'flex', alignItems: 'center', marginRight: '36px',
                            color: sortIndex == index ? '#F7385B' : '#888888', cursor: 'pointer'
                        }}
                            onClick={() => sortMethod(index)}>
                            {item.name}
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index && item.type == 'orderByAsc' ? 's' : '') + '.png'} alt=""
                                style={{ marginLeft: '2px' ,transform: 'rotate(180deg)' }} />
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index && item.type == 'orderByDesc' ? 's' : '') + '.png'} alt=""
                                style={{ marginLeft: '2px', }} />
                        </div>
                    })
                }
            </div>
        </div>

        {/* 列表 */}
        <div style={{
            width: '100%', backgroundColor: isBig ? '' : '#FFFFFF', padding: isBig ? '0 10px' : '24px 24px 24px 30px',
            marginTop: isBig ? 0 : '10px'
        }}>
            {isBig ? '' : <div style={{
                width: '100%', height: '50px', display: 'flex', alignItems: 'center', backgroundColor: '#F6F6F6',
                fontWeight: 500
            }}>
                <div style={{ width: '9%', paddingLeft: '19px' }}>{intl.get('BloggerRanking').Ranking}</div>
                <div style={{ width: '30%', paddingLeft: '14px' }}>{intl.get('SmallShop')['小店']}</div>
                <div style={{ width: '12%', paddingRight: '10px' }}>{intl.get('Index')['国籍']}</div>
                <div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('SmallShop')['销量']}</div>
                <div style={{ width: '12%', paddingRight: '10px' }}>{intl.get('SmallShop')['销售额']}</div>
                <div style={{ width: '11%', paddingRight: '10px' }}>{intl.get('SmallShop')['关联商品数']}</div>
                <div style={{ width: '9%', paddingRight: '10px' }}>{intl.get('SmallShop')['关联博主数']}</div>
                {/* <div style={{ width: '10.5%', paddingRight: '10px' }}>{intl.get('SmallShop')['关联视频数']}</div> */}
                {/*<div style={{ width: '' }}>{intl.get('BloggerRanking').Operation}</div>*/}
            </div>}
            <Spin tip="数据加载中..." spinning={loading}>
                <InfiniteScroll
                    dataLength={data.length} scrollThreshold={'20px'}
                    next={loadMoreData}
                    hasMore={data.length < total}
                    // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                    endMessage={state.userInfo?.userType == 1 && state.userInfo?.memberDetail?.memberType === '领航版' ? <Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider> : ''}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={data}
                        grid={{
                            gutter: 0,
                            column: isBig ? widths : 1
                        }}
                        renderItem={
                            (item, index) =>
                                <div style={{ paddingTop: isBig ? '10px' : 0 }}>
                                    {SmallDiv(item, index)}
                                </div>
                        }
                    />
                </InfiniteScroll>
            </Spin>
            {
                state.userInfo?.userType == 1 && state.userInfo?.memberStatus != 0 ?
                    state.userInfo?.memberDetail?.memberType === '起航版' && total <= data.length ?
                        <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                style={{ width: '24px', marginRight: '7px' }} />
                            {intl.get('TopicList')['起航版员目前仅支持查看前200条数据']}
                            {/* <span style={{ color: '#F7385B', cursor: 'pointer' }}>{intl.get('BloggerRanking').OpenMember}</span>
                            {intl.get('BloggerRanking').ViewMoreData} */}
                        </div>
                        :
                        state.userInfo?.memberDetail?.memberType === '远航版' && total <= data.length ?
                            <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                    style={{ width: '24px', marginRight: '7px' }} />
                                {intl.get('TopicList')['远航版员目前仅支持查看前500条数据']}
                                {/* <span style={{ color: '#F7385B', cursor: 'pointer' }}>{intl.get('BloggerRanking').OpenMember}</span>
                                {intl.get('BloggerRanking').ViewMoreData} */}
                            </div>
                            :
                            ""
                    :
                    state.userInfo?.userType == 1 ?
                        <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                style={{ width: '24px', marginRight: '7px' }} />
                            {intl.get('BloggerRanking').NonMembers} <span style={{ color: '#F7385B', cursor: 'pointer' }} onClick={() => props.navigate('/index/member')}>{intl.get('BloggerRanking').OpenMember}</span>
                            {intl.get('BloggerRanking').ViewMoreData}
                        </div> : ''
            }
        </div>

        {isPk ? <PkModel clickFun={() => setIsPk(false)} pkResultList={pkResultList} /> : ''}
        {isShare ? <ShareCommonModel clickFun={() => getBlobPng(false)} dataList={shareData} shareObj={shareObj} /> : ''}
    </div>

}
export default withRouter(SmallShopRanking);
