import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import intl from 'react-intl-universal';
import { DualAxes,Line } from '@ant-design/plots';
import { Progress, Image } from 'antd';
import * as Api from "../../service/https";
import {getSupplyIndexAnnouncement, getSupplyIndexData, infoP, infoShop} from "../../service/https";
import moment from 'moment';
import * as echarts from "echarts";

const Workbench = (props)=>{
    const config = {
        xField: 'date',
        yField: 'value',
        seriesField: 'category',
        color: ['#FC9A49', '#1B9EFF'],
    };
    const [dataUpdateTime,dataUpdateTimeSet] = useState(null)
    const [chartConfig,setChartConfig] = useState(config)
    const [orderData,orderDataSet] = useState({todayOrderSale:0,monthOrderSale:0})
    const getChartData = () =>{
        Api.getSupplyOrderTrend().then(res =>{
            if(res.code === 200){
                dataUpdateTimeSet(moment(new Date).format('YYYY-MM-DD HH:mm:ss'))
                let vmData = [], ordersMonthTrack = res.data.ordersMonthTrack, salesMonthTrack = res.data.salesMonthTrack,
                    salesMonthTrackArr = Object.keys(res.data.salesMonthTrack)
                let monthSaleTotal = 0
                for(let key in ordersMonthTrack) vmData.push({ category:'订单量', date: key, value: ordersMonthTrack[key] })
                for(let key in salesMonthTrack) {
                    monthSaleTotal+= Number(salesMonthTrack[key])
                    vmData.push({ category:'销售额', date: key,value: salesMonthTrack[key]})
                }
                if(salesMonthTrackArr.length) orderDataSet({todayOrderSale: salesMonthTrack[salesMonthTrackArr[salesMonthTrackArr.length - 1]],monthOrderSale:monthSaleTotal,})
                setChartConfig({...config, data: vmData})
            }
        })
    }

    const [numData,numDataSet] = useState(null)
    const getSupplyIndexData = () => {
        Api.getSupplyIndexData().then(res => {
            if(res.code === 200){
                numDataSet(res.data)
            }
        })
    }

    const [topTips,topTipsSet] = useState(false)
    const [goodsTotal,goodsTotalSet] = useState(1)
    const getMyProductLength = e => {
        let data = {
            current: 1,
            size: 10,
            priceMin: 0,
            priceMax: 0,
            monthMin: 0,
            monthMax: 0,
            orderField: 3,
            sortWay: 2
        }
        Api.getMyProduct({ ...data}).then(res => {
            if (res.code === 200) {
                goodsTotalSet(res.data.total)
            }
        })
    }
    const [shopInfo,shopInfoSet] = useState(null)
    const getShopInfo = e => {
        Api.infoShop().then(res => {
            if (res.code === 200) {
                shopInfoSet(res.data)
            }
        })
    }

    const [announcement,announcementSet] = useState([])
    const getAnnouncement = e => {
        Api.getSupplyIndexAnnouncement({ current: 1, size: 7 }).then(res => {
            if (res.code === 200) {
                announcementSet(res.data.records)
            }
        })
    }
    const [gaugeChart, setGaugeChart] = useState()
    const setgauge = () => {
        var chartDom = document.getElementById('gauge');
        var myChart
        if (gaugeChart) {
            myChart = gaugeChart
        } else {
            myChart = echarts.init(chartDom);
            setGaugeChart(myChart)
        }
        let option = {
            grid:{
              top:10,
              bottom:10,
              left:10,
              right:10,
            },
            series: [{
                type: 'gauge',
                startAngle: 180,
                endAngle: 0,
                min: 0,
                max: 100,
                center: ['50%', '94%'],
                splitNumber: 4,
                radius: '150%',
                pointer: {
                    show: false
                },
                progress: {
                    show: false,
                    overlap: false,
                    roundCap: false,
                    clip: false,
                    itemStyle: {
                        borderWidth: 20,
                        borderColor: 'rgba(0,0,0,0.2)',
                        color: '#FAE27C'
                    }
                },
                axisLine: {
                    roundCap: true,
                    lineStyle: {
                        width: 37,
                        opacity: .6,
                        // color: [
                        //     [0.25, '#F0FFF0'],
                        //     [0.5, '#FFF8DC'],
                        //     [0.75, '#FFF8DC'],
                        //     [1, 'rgba(250,230,230)']
                        // ]
                        color:[
                            [1, new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                offset: 0,
                                opacity: .6,
                                color: "#C1FFC1"
                            },
                                {
                                    offset: 0.3,
                                    color: "#FFF8DC"
                                },
                                {
                                    offset: 0.6,
                                    color: "#FFF8DC"
                                },
                                {
                                    offset: 1,
                                    color: "rgba(250,230,230)"
                                }
                            ])]
                        ]
                    }
                },
                axisTick: {
                    show: false,
                    splitNumber: 1
                },
                splitLine: {
                    distance: -40,
                    length: 2,
                    lineStyle: {
                        width: 3,
                        color: '#666666'
                    }
                },
                axisLabel: {
                    show: true,
                    distance: 18,
                    fontSize: 10,
                    color:'#666666'
                },
                data: [{
                    value: 10,
                    title: {
                        show: false
                    },
                    detail: {
                        show: false
                    }
                }],
                title: {
                    fontSize: 32,
                    color: '#fff',
                    fontWeight: 'bold'
                },
                detail: {
                    fontSize: 30,
                    offsetCenter: [0, '-10%'],
                    valueAnimation: true,
                    formatter: function (value) {
                        return Math.round(value * 100) + '';
                    },
                    color: 'auto'
                }
            },
                {
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    center: ['50%', '94%'],
                    min: 0,
                    max: 100,
                    radius: '90%',
                    splitNumber: 4,
                    pointer: {
                        show: true,
                        itemStyle: {
                            color: 'rgba(106,207,147)'
                        },
                        width:4
                    },
                    progress: {
                        show: false,
                        overlap: false,
                        roundCap: false,
                        clip: false,
                        itemStyle: {
                            color: '#FFFFFF',
                            borderWidth: 1,
                            borderColor: '#EEEEEE'
                        }
                    },
                    axisLine: {
                        roundCap: true,
                        lineStyle: {
                            width: 20,
                            opacity: .6,
                            color: [[1, '#EEEEEE']],
                            // shadowColor: '#000000', //默认透明
                            // shadowBlur: 4,
                            // shadowOffsetX: 0,
                            // shadowOffsetY: 20,
                        },
                    },
                    splitLine: {
                        show: false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false,
                        splitNumber: 1
                    },
                    axisLabel: {
                        show: false,
                    },
                    data: [{
                        value: 0,
                        title: {
                            show: false
                        },
                        detail: {
                            show: false
                        }
                    }],
                    title: {
                        fontSize: 32,
                        color: '#fff',
                        fontWeight: 'bold'
                    },
                    detail: {
                        width: 20,
                        height: 14,
                        fontSize: 40,
                        color: '#2785FE',
                        formatter: '{value}%'
                    }
                }
            ]
        };
        myChart.setOption(option);
    }

    useEffect(()=>{
        getSupplyIndexData()
        getChartData()
        getMyProductLength()
        getAnnouncement()
        getShopInfo()
        setgauge()
    },[])

    useEffect(()=>{
        topTipsSet(!goodsTotal || !shopInfo|| !shopInfo?.phone || !shopInfo?.email || !shopInfo?.name || !shopInfo?.avatar)
    },[goodsTotal,shopInfo])


    return <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>

        <div style={{width:'910px'}}>
            { topTips ?  <div style={{width:'100%', height:'128px', background:'#FFEEF1', border:'1px solid #F7385B', borderRadius:'5px',
            paddingTop:'19px', position:'relative', marginBottom:'12px'}}>
                <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/cha.png" alt=""
                style={{width:'13px', position:'absolute', right:'15px', top:'12px', cursor:'pointer'}}  onClick={()=>{topTipsSet(false)}} />
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/yl.png" alt=""
                    style={{width:'26px', marginRight:'5px'}}/>
                    <span style={{fontSize:'16px', fontWeight:500, color:'#111'}}>欢迎来到BrandGoGo商家管理中心！2步开启您的跨境赚钱之旅吧！您现在需要做：</span>
                </div>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'17px'}}>
                    <div style={{width:'287px', height:'43px', background:'linear-gradient(90deg, #FB742C, #FFE985)', cursor:'pointer',
                    borderRadius:'5px', position:'relative',display:'flex', justifyContent:'center', alignItems:'center'}}
                         onClick={()=>{props.navigate('/index/shopAccount')}}>
                        <span style={{fontWeight:600, color:'#FFF'}}>1、店铺信息设置</span>
                        <span style={{fontWeight:600, color:'#FC8539', fontSize:'12px', background:'#FFF', borderRadius:'3px',
                        padding:'0 5px', marginLeft:'40px'}}>去设置</span>
                        <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/upShop.png" alt=""
                        style={{width:'54px', position:'absolute', left:'-27px', top:'-15px'}}/>
                    </div>
                    <div style={{width:'287px', height:'43px', background:'linear-gradient(90deg, #F1B8EE, #EE73E1)', cursor:'pointer',
                    borderRadius:'5px', position:'relative',display:'flex', justifyContent:'center', alignItems:'center',
                    marginLeft:'57px'}} onClick={()=>{props.navigate('/index/MyGoods')}}>
                        <span style={{fontWeight:600, color:'#FFF'}}>2、上传店铺商品</span>
                        <span style={{fontWeight:600, color:'#EF7FE3', fontSize:'12px', background:'#FFF', borderRadius:'3px',
                        padding:'0 5px', marginLeft:'40px'}}>去上传</span>
                        <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/shopSet.png" alt=""
                        style={{width:'54px', position:'absolute', left:'-27px', top:'-15px'}}/>
                    </div>
                </div>
            </div> : ''}

            <div style={{width:'100%', height: '140px', padding: '20px 25px', backgroundColor: '#fff'}}>
                <div style={{ display: 'flex', alignItems:'center' }}>
                    <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/before.png" alt=""
                    style={{width:'4px'}}/>
                    <div style={{fontSize:'16px', fontWeight:600, marginLeft:'9px'}}>待办事项</div>
                </div>
                <div style={{
                    width: '100%', backgroundColor: '#FFF', marginTop: '20px',
                    textAlign: 'center', display: 'flex', justifyContent: 'space-around', alignItems: 'center',
                }}>
                    <div style={{ flexDirection: 'column', fontWeight: 'bold', cursor:'pointer' }}
                    onClick={()=>{props.navigate('/index/myOrder', { state:{ orderStatus:2 } })}}>
                        <div style={{ display: 'flex', alignItems: 'center', color: '#666666' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/daifahuo.png'} alt=""
                                style={{ width: '27px', marginRight: '12px', }} /> {'待发货订单  >'}
                        </div>
                        <div style={{ fontSize: '24px', lineHeight:'28px' }}>{numData?.orderTotalToShip || 0}</div>
                    </div>
                    <div style={{ flexDirection: 'column', fontWeight: 'bold', cursor:'pointer' }}
                         onClick={()=>{props.navigate('/index/myOrder', { state:{ orderStatus:5 } })}}>
                        <div style={{ display: 'flex', alignItems: 'center', color: '#666666' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/tuihuodan.png'} alt=""
                                style={{ width: '27px', marginRight: '12px', }} /> {'退货单  >'}
                        </div>
                        <div style={{ fontSize: '24px', lineHeight:'28px' }}>{numData?.orderTotalCanceled || 0}</div>
                    </div>
                    <div style={{ flexDirection: 'column', fontWeight: 'bold', cursor:'pointer' }}
                         onClick={()=>{props.navigate('/index/MyGoods')}}>
                        <div style={{ display: 'flex', alignItems: 'center', color: '#666666' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/wukucun.png'} alt=""
                                style={{ width: '27px', marginRight: '12px', }} /> {'无库存SUK  >'}
                        </div>
                        <div style={{ fontSize: '24px', lineHeight:'28px' }}>{numData?.totalOutSKU || 0}</div>
                    </div>
                    <div style={{ flexDirection: 'column', fontWeight: 'bold', cursor:'pointer' }}
                         onClick={()=>{props.navigate('/index/MyGoods')}}>
                        <div style={{ display: 'flex', alignItems: 'center', color: '#666666' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/shibai.png'} alt=""
                                style={{ width: '27px', marginRight: '12px', }} /> {'审核失败商品  >'}
                        </div>
                        <div style={{ fontSize: '24px', lineHeight:'28px' }}>0</div>
                    </div>
                </div>
            </div>

            <div style={{width:'100%', padding: '20px 25px', backgroundColor: '#fff', marginTop: '12px' }}>
                <div style={{ display: 'flex', alignItems:'center' }}>
                    <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/before.png" alt=""
                    style={{width:'4px'}}/>
                    <div style={{fontSize:'16px', fontWeight:600, marginLeft:'9px'}}>数据罗盘</div>
                    <span style={{color:'#888888', fontWeight:500, marginLeft:'15px', fontSize:'14px'}}>数据更新于 {dataUpdateTime}</span>
                </div>
                <div style={{
                    width: '100%', marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                    padding:'0 13px'
                }}>
                    <div style={{width:'268px', height:'70px', backgroundImage:'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/dayOrder.png)',
                    backgroundSize:'100% 100%', color:'#FFF', paddingLeft:'88px', paddingTop:'10px', fontWeight:600,}}>
                        {'今日订单金额 ($)  >'}
                        <div style={{fontSize:'24px', lineHeight:'28px'}}>{orderData.todayOrderSale}</div>
                    </div>
                    <div style={{width:'268px', height:'70px', backgroundImage:'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/yueOrder.png)',
                    backgroundSize:'100% 100%', color:'#FFF', paddingLeft:'88px', paddingTop:'10px', fontWeight:600,}}>
                        {'本月订单金额 ($)  >'}
                        <div style={{fontSize:'24px', lineHeight:'28px'}}>{orderData.monthOrderSale}</div>
                    </div>
                    <div style={{width:'268px', height:'70px', backgroundImage:'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/renshu.png)',
                    backgroundSize:'100% 100%', color:'#FFF', paddingLeft:'88px', paddingTop:'10px', fontWeight:600,}}>
                        {'当前带货达人数  >'}
                        <div style={{fontSize:'24px', lineHeight:'28px'}}>0</div>
                    </div>
                </div>

                <div style={{display:'flex', alignItems:'center', paddingLeft:'40px', marginTop:'40px'}}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/xiaoliangzoushi.png'} alt=""
                    style={{ width: '30px', marginRight: '5px', }} />
                    <span style={{fontWeight:500, color:'#888888'}}>订单量</span>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/dindanzoushi.png'} alt=""
                    style={{ width: '30px', marginRight: '5px', marginLeft:'42px'}} />
                    <span style={{fontWeight:500, color:'#888888'}}>销售额</span>
                </div>
                <div style={{padding:'40px 13px', position:'relative'}}>
                    { chartConfig?.data ? <Line {...chartConfig} /> : '' }
                    <div style={{ width:'200px', height:'30px',
                    background:'#FFF', position:'absolute', top:'40px', left:0,}}></div>
                </div>
            </div>

        </div>

        <div style={{width:'260px'}}>
            <div style={{width:'100%', height:'368px', background:'#FFF', padding:'25px 15px', borderRadius:'5px'}}>
                <div style={{width:'100%', display:'flex', alignItems:'center'}}>
                    <Image src={shopInfo?.avatar || 'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} preview={false}
                    style={{ width: '38px', height: '38px', borderRadius: '19px', marginRight: '10px' }} />
                    <span className='over1' style={{fontSize:'16px', fontWeight:600}}>{ shopInfo?.name || 'BrandGoGo Shop' }</span>
                </div>
                <div style={{width:'100%', height:'180px', marginTop:'6', position:'relative'}}>
                    <div id="gauge" style={{ width: '100%', height: '125px', margin:'0' }}></div>
                    {/*<Progress type="dashboard" strokeWidth={10} percent={10} gapDegree={125} strokeLinecap="butt"*/}
                    {/*strokeColor={{ '0%': '#F7385B', '100%': '#FF7B79', }} showInfo={false} width={180}*/}
                    {/*// success={{ percent: 30, strokeColor: 'red' }}*/}
                    {/*trailColor={'#F6F6F6'} />*/}
                    {/* <div style={{width:'108px', height:'108px', borderRadius:'50%', background:'linear-gradient(0deg, #bbb, #FFF)',
                    position:'absolute', top:'36px', left:'51px'}}></div>
                    <div style={{width:'58px', height:'58px', borderRadius:'50%', background:'#FFF',
                    position:'absolute', top:'61px', left:'76px'}}></div>
                    <div style={{width:'100%', height:'60px', background:'#FFF',
                    position:'absolute', bottom:'31px', left:0}}></div> */}
                    <div style={{textAlign:'center'}}>
                        <div><span style={{color:'#40D782', fontSize:'16px', fontWeight:600}}>0</span></div>
                        <span style={{color:'#888888', fontWeight:500, marginRight: 4 }}>店铺诊断</span>
                        <span style={{color:'#40D782', fontSize:'16px', fontWeight:600}}>低风险</span>
                    </div>
                    <div style={{width:'100%', height:'98px', background:'#F6F6F6', marginTop:'10px', fontWeight:500, padding:'12px 20px'}}>
                        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'3px'}}><span>订单取消率</span><span style={{color:'#888'}}>{numData?.orderCancelPercent || '0.00'}%</span></div>
                        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'3px'}}><span>延迟履约率</span><span style={{color:'#888'}}>0.00%</span></div>
                        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}><span>好评率</span><span style={{color:'#888'}}>100.00%</span></div>
                    </div>
                </div>
            </div>

            <div style={{width:'100%', background:'#FFF', marginTop:'12px', borderRadius:'5px', padding:'25px', height:470}}>
                <div style={{ display: 'flex', alignItems:'center' }}>
                    <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/before.png" alt=""
                    style={{width:'4px'}}/>
                    <div style={{fontSize:'16px', fontWeight:600, marginLeft:'9px'}}>公告</div>
                </div>
                <div style={{width:'calc(100% - 5px)', marginTop:'10px'}}>
                    <ul style={{position:'relative', left:'13px'}}>
                        {
                            announcement?.map((item,index) => {
                                return <li key={index} className='ulLi'>
                                    <div style={{fontWeight:500, lineHeight:'15px'}}><span>{item?.title}</span>
                                        <p style={{fontSize:'12px', color:'#888', lineHeight:'17px'}}>{item?.updateTime}</p></div>
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    </div>
}
export default withRouter(Workbench);
