import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import '../../../asset/css/BloggerRanking.scss'
import { DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import { Select, Checkbox, List, notification, Image, Skeleton, Divider, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../../utils/redux/index'
import domtoimage from 'dom-to-image';
import intl from 'react-intl-universal';
import { saveAs } from 'file-saver';
import PkModel from '../../../components/PkModel'
import ShareBloggerModel from '../../../components/Share/ShareBloggerModel'
import * as Api from '../../../service/https'
import toKMB from '../../../utils/KMB'
import MyBlogger from './MyBlogger'
import MyVideos from './MyVideos'
import MyTopic from './MyTopic'
import MySmallShop from './MySmallShop'
import MyGoodsRanking from './MyGoodsRanking'
import MyGoodsCollectLists from './MyGoodsCollectLists'
import MyMusicRanking from './MyMusicRanking'

const { Option } = Select;

const MyCollection = (props)=>{

    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [widths, setWidths] = useState(0)
    useEffect(()=>{
        
    },[])

    const [statusFList, setStatusFList] = useState([
        intl.get('Workbench').MyBlogger, intl.get('Workbench').MyVideos, intl.get('Workbench').MyTopic,
        intl.get('Workbench').MyGoods, intl.get('Workbench').MyShops, intl.get('Workbench').MyMusics
    ])
    const [statusF, setStatusF] = useState(1)
    



    return <div id="BloggerRanking" style={{paddingBottom:'50px'}}>
        <nav style={{width:'100%', position:'relative', fontWeight: 500}}>
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt="" 
                style={{width:'100%'}}/>
            <div style={{width:'100%', height:'100%', position:'absolute', top:0, left:0,
                display:'flex', alignItems:'center', justifyContent:'center'}}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt="" 
                    style={{width:'190px'}}/>
                <div style={{width:'10px', height:'10px', backgroundColor:'black', borderRadius:'5px',
                    margin:'0 20px'}}></div>
                <span style={{fontSize:'20px'}}>{intl.get('Workbench').Collection}</span>
            </div>
        </nav>

        <div style={{ display: 'flex', padding: '20px', paddingTop: '25px', justifyContent: 'center' }}>
            {
                statusFList?.map((item, index) =>
                    <div key={index} style={{
                        padding: '0 35px', cursor: 'pointer', borderRadius: '5px',
                        color: statusF === index + 1 ? '#F7385B' : '#888888', fontWeight: 500
                    }}
                        onClick={e => { setStatusF(index + 1) }}>{item}
                        <div style={{
                            width: '100%', height: '4px', backgroundColor: statusF === index + 1 ? '#F7385B' : '#F6F6F6',
                            marginTop: '5px'
                        }}></div>
                    </div>
                )
            }
        </div>

        {statusF == 1 ? <MyBlogger /> :
            statusF == 2 ? <MyVideos /> :
                statusF == 3 ? <MyTopic /> :
                statusF == 4 ? (state?.userInfo?.userType == 2 ? <MyGoodsCollectLists /> : <MyGoodsRanking/> ) :
                    statusF == 5 ? <MySmallShop /> :
                        statusF == 6 ? <MyMusicRanking /> : ''}


    </div>
                        
}
export default withRouter(MyCollection);