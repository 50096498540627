import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import { DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import { Modal, message, List, notification, Avatar, Skeleton, Divider } from 'antd';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import intl from 'react-intl-universal';
import { upload } from '@testing-library/user-event/dist/upload';
import toKMB from '../../utils/KMB';
import moment from 'moment';
import html2canvas from 'html2canvas'
import three from "../../utils/three";

const ShareBloggerModel = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh'
    const dataList = props.dataList
    const [widths, setWidths] = useState(0)
    useEffect(() => {
        console.log(props)
    }, [])

    const uploads = () => {
        message.loading({ content: 'Loading...', key: 'updatable' });
        canvasImgFn()
        // const node = document.getElementById("share");
        // domtoimage.toBlob(node).then((blob) => {
        //     // 调用file-save方法 直接保存图片
        //     // saveAs(blob, '小店排行榜分享.png'
        //     // message.success({ content: 'Success!', key:'updatable', duration: 2 });
        // })
    }

    const dataURLToBlob = (dataurl) => {
        let arr = dataurl.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    const canvasImgFn = () => {
        let a = document.createElement('a');
        html2canvas(document.getElementById("share"), {
            width: 695, //设置canvas的宽度
            scale: 1,//缩放
            allowTaint: true,
            useCORS: true,
            height: 3190, //设置截图的长度
        }).then((canvas) => {
            let dom = document.body.appendChild(canvas);
            dom.style.display = 'none';
            a.style.display = 'none';
            document.body.removeChild(dom);
            let blob = dataURLToBlob(dom.toDataURL('image/png'));
            a.setAttribute('href', URL.createObjectURL(blob));
            //这块是保存图片操作  可以设置保存的图片的信息
            a.setAttribute('download', `${intl.get('ShareModel')['小店排行榜']}.png`);
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(blob);
            document.body.removeChild(a);
        })
    }


    const ShareDiv = ()=>{
        return <div style={{ width: 695 }}>
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share1.png?1111'} alt="" crossOrigin="anonymous"
                style={{ width: '100%', display: 'inline-block' }} />
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share2.png?1111'} alt="" crossOrigin="anonymous"
                style={{ width: '100%', display: 'inline-block', marginTop: '-1px' }} />
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share3.png?1111'} alt="" crossOrigin="anonymous"
                style={{ width: '100%', display: 'inline-block', marginTop: '-1px' }} />
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share4.png?1111'} alt="" crossOrigin="anonymous"
                style={{ width: '100%', display: 'inline-block', marginTop: '-1px' }} />
            <div style={{ position: 'absolute', top: 0, left: 0 }}>
                <div style={{
                    marginTop: '256px', marginLeft: '85px', fontSize: '35px',
                    color: '#444444', fontWeight: 'bold'
                }}>{intl.get('ShareModel')['小店排行榜']}</div>
                <div style={{
                    textAlign: 'center', color: '#FFF', fontSize: '26px', fontWeight: 'bold',
                    marginTop: '30px', marginLeft: '77px'
                }}>
                    {intl.get('ShareModel')['销量']}TOP10 <div style={{ fontSize: '26px' }}>{moment().format("YYYY-MM-DD")}{intl.get('ShareModel')['日榜']}</div>
                </div>
            </div>
            <div style={{ width: '100%', position: 'absolute', top: '555px', left: 0, padding: '0 30px' }}>
                {
                    dataList?.map((item, index) => {
                        return index < 10 ? <div key={index} style={{
                            width: '100%', height: '210px', backgroundColor: '#FFF',
                            borderRadius: '10px', display: 'flex', alignItems: 'center', padding: '35px 34px',
                            marginBottom: '16px', position: 'relative'
                        }}>
                            <img src={item.avatarMedium + '?1111'} alt="" crossOrigin="anonymous"
                                style={{ width: '140px', height: '140px', borderRadius: '70px' }} />
                            <div style={{ marginLeft: '20px', flex:1 }}>
                                <div style={{ height: '30px', fontWeight: 'bold', fontSize: '20px', lineHeight: '25px' }}>{item.nickname}</div>
                                <div style={{ color: '#888888', fontWeight: 500, fontSize: '16px', marginTop: '3px' }}>TikTok: {item.uniqueId}</div>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', justifyContent:'space-between' }}>
                                    <div style={{ fontSize: '16px', color: '#888888', display: 'flex', alignItems: 'center' }}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/xiaoliang.png?1111'} alt="" crossOrigin="anonymous"
                                            style={{ width: '18px', height: '18px' }} />
                                        <span style={{ marginLeft: '5px', marginRight: '8px' }}>{intl.get('ShareModel')['销量']}</span>
                                        <span style={{ fontSize: '18px', color: '#333333', fontWeight: 'bold' }}>{toKMB(item.soldCount, 2)}</span>
                                    </div>
                                    <div style={{ fontSize: '16px', color: '#888888', display: 'flex', alignItems: 'center'}}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/xiaoshoue.png?1111'} alt="" crossOrigin="anonymous"
                                            style={{ width: '18px', height: '18px' }} />
                                        <span style={{ marginLeft: '5px', marginRight: '8px' }}>{intl.get('ShareModel')['销售额']}</span>
                                        <span style={{ fontSize: '18px', color: '#333333', fontWeight: 'bold' }}>{item.currencyStr} {toKMB(item.soldTotal,2)}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ position: 'absolute', top: '18px', right: '18px' }}>
                                {index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png?1111'} alt="" crossOrigin="anonymous" /> :
                                    <div style={{
                                        width: '30px', height: '30px', borderRadius: '15px',
                                        lineHeight: '28px', textAlign: 'center', fontWeight: 500,
                                        backgroundColor: '#EEEEEE', fontSize: '20px', color: '#888888'
                                    }}>{index + 1}</div>
                                }
                            </div>
                        </div> : ''
                    })
                }
            </div>
        </div>
    }

    return <div>
        <Modal centered visible={true} footer={null} closable={false}
            getContainer={() => document.getElementById('BloggerRanking')}
            bodyStyle={{
                padding: '0', backgroundColor: '#FFFFFF',
                width: 350, height: 1698, margin: '130px 0', overflow: 'hidden'
            }}
        >
            <div id='share' style={{ width: 695, position: 'fixed', left: 10000}}>
                <ShareDiv />
            </div>
            <div style={{ width: 695, transform: 'scale(0.5)', position: 'absolute', top: -849, left: -172 }}>
                <ShareDiv />
            </div>
            
            <div onClick={uploads} style={{
                width: lang == 'zh' ? '118px' : '168px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                textAlign: 'center', lineHeight: '40px', color: '#FFF', borderRadius: '4px', margin: '0 auto',
                cursor: 'pointer', marginTop: '26px', position: 'absolute', left: 'calc(100% + 24px)', top: '240px'
            }}>{intl.get('ShareModel')['下载图片']}</div>
            <div onClick={() => props.clickFun()} style={{
                width: '118px', height: '40px', background: '#FFF',
                textAlign: 'center', lineHeight: '40px', color: '#666', borderRadius: '4px', margin: '0 auto',
                cursor: 'pointer', marginTop: '26px', position: 'absolute', left: 'calc(100% + 24px)', top: '300px'
            }}>{intl.get('ShareModel')['取消分享']}</div>
        </Modal>
    </div>
}
export default withRouter(ShareBloggerModel);