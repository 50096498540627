import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import '../../asset/css/BloggerRanking.scss'
import {Skeleton,List, Image, Divider, Spin, message, DatePicker, Space, Tooltip} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../utils/redux/index'
import intl from 'react-intl-universal';
import * as Api from '../../service/https'
import toKMB from '../../utils/KMB'
import moment from 'moment'
import {CalendarOutlined} from "@ant-design/icons";
import openNotificationWithIcon from '../../components/Notification'

const GoodsRankingVideo = (props) => {
    const { RangePicker } = DatePicker;
    const [state, setSate] = useState(store.getState());
    const [dayList] = useState(intl.get('GoodsRankingDetail').DaysArr)
    const [dayIndex, setDayIndex] = useState(0)
    const [orderField, orderFieldSet] = useState(0);
    const [sortWay, sortWaySet] = useState(2);
    const [sortIndex, setSortIndex] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const sortChange = e => {
        setPageIndex(0)
        setSortIndex(e)
        if (e === orderField) {
            sortWaySet(sortWay === 1 ? 2 : 1)
        } else {
            sortWaySet(2)
            orderFieldSet(e)
        }
    }
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [widths, setWidths] = useState(0)
    useEffect(() => {
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
        }
    }, [])

    const heightFun = () => {
        let width = document.documentElement.clientWidth
        let num = state.collapsed + (width > 1890 ? 6 : width > 1650 ? 5 : 4)
        setWidths(num)
    }
    useEffect(() => {
        heightFun()
    }, [state.collapsed])

    const [isBig] = useState(false)

    const [sortList] = useState([intl.get('GoodsRankingVideo')['播放数'],intl.get('GoodsRankingVideo')['点赞数'],
        intl.get('GoodsRankingVideo')['评论数'], intl.get('GoodsRankingVideo')['发布时间']]);

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);

    useEffect(() => {
        loadMoreData()
    }, [sortIndex, orderField, sortWay])

    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            bloggerVideoSortRuler: sortIndex + 1,
            from: pageIndex * 20, size: 20,
            orderField: orderField,
            sortWay: sortWay
        }
        Api.getVideoRanking(obj).then(res => {
            if (res.code === 200) {
                setTotal(res.data.total)
                setData(pageIndex == 0 ? res.data.data : [...data, ...res.data.data]);
                setPageIndex(pageIndex + 1)
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    };

    const addCollection = (e, item, index) => {
        e.stopPropagation()
        let data = {
            productId: item.productDetail.productId
        }
        if (item.isCollect) {
            Api.cancelCollectProduct(data).then(res => {
                if (res.code === 200) {
                    openNotificationWithIcon('success', intl.get('GoodsLists')['取消成功！'])
                    let t = JSON.parse(JSON.stringify(data))
                    t[index].isCollect = !t[index].isCollect
                    setData(t)
                }
            })
        } else {
            Api.collectProduct(data).then(res => {
                if (res.code === 200) {
                    openNotificationWithIcon('success', intl.get('GoodsRanking')['收藏成功！'])
                    let t = JSON.parse(JSON.stringify(data))
                    t[index].isCollect = !t[index].isCollect
                    setData(t)
                }
            })
        }
    }

    // 别表
    const SmallDiv = (item, index) => {
        return <div key={index} style={{
            width: '100%', height: '150px', display: 'flex', alignItems: 'center',
            backgroundColor: index % 2 == 0 ? '#FFFFFF' : '#F6F6F6', cursor: 'pointer'
        }}
                    onClick={() => detail(item, index)}>

            <div style={{ width: '7.8%', paddingLeft: '19px' }}>
                {index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png'} alt=""
                                  style={{}} /> :
                    <div style={{ width: '32px', textAlign: 'center', fontWeight: 500 }}>{index + 1}</div>
                }
            </div>
            <div style={{ width: '38%', display: 'flex' }}>
                <div style={{
                    width: '80px', height: '110px', backgroundImage: 'url(' + item.video.cover + ')',
                    backgroundSize: 'cover', borderRadius: '4px', backgroundRepeat: 'no-repeat'
                }}>
                    <div style={{
                        width: '100%', height: '100%', background: 'rgba(0,0,0,0.2)', color: '#FFF',
                        textAlign: 'center', paddingTop: '86px', borderRadius: '4px', fontSize: '13px'
                    }}>
                        <span>{Math.floor(item.video.duration / 60)}:{(item.video.duration % 60) < 10 ? '0' : ''}{item.video.duration % 60}</span>
                    </div>
                </div>
                <div className='videoList' style={{
                    width: 'calc(100% - 80px - 85px)', paddingLeft: '15px',
                    color: '#888888'
                }}>
                    <div className='over' style={{
                        fontSize: '16px', fontWeight: 'bold', lineHeight: '20px',
                        color: '#333', paddingTop: '2px'
                    }}>{item.desc}</div>
                    <div className='over1' style={{ height: '20px', marginTop: '8px' }}>
                        {
                            item.challenges?.map((item, index) => {
                                return <span key={index} style={{ marginRight: '5px' }}>#{item.title}</span>
                            })
                        }
                    </div>
                    <div style={{ marginTop: '13px' }}>{intl.get('GoodsRankingVideo')['发布时间']}：{moment(item.createTime * 1000).format('YYYY-MM-DD hh:mm:ss')}</div>
                </div>
            </div>
            <div style={{ width: '23%', display: 'flex', alignItems: 'center' }}>
                <Image preview={false} src={item.elasticSearchUser.avatarMedium} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                       style={{ width: '60px', height: '60px', borderRadius: '30px', marginRight: '16px' }} />
                <div style={{ color: '#888888' }}>
                    <div style={{
                        color: '#333333', fontWeight: 'bold', fontSize: '16px',
                        display: 'flex', alignItems: 'center'
                    }}>
                        <div className='over1' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item.elasticSearchUser.nickname}</div>
                        {item.elasticSearchUser.verified ? <img alt='' style={{ marginLeft: '3px', width: '14px', height: '14px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} /> : ''}
                    </div>
                    TikTok: {item.author}
                </div>
            </div>
            <div style={{ width: '6%' }}>{toKMB(item.playCount,2)}</div>
            <div style={{ width: '7%' }}>{toKMB(item.commentCount,2)}</div>
            <div style={{ width: '6%' }}>{toKMB(item.diggCount,2)}</div>
            <div style={{ width: '' }}>
                <Tooltip placement="top" title={<span style={{fontSize:'12px'}}>{intl.get('GoodsRankingVideo')['播放视频']}</span>}>
                    <img onClick={(e) => playVideo(e, item, index)}
                         src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/play_grey.png'} style={{ marginRight: '9px' }} alt="" />
                </Tooltip>
                <img onClick={(e)=>addCollection(e, item,index)}
                    src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc.png'} alt="" />
            </div>
        </div>
    }

    const detail = (item) => {
        window.open(`/index/bloggerDetail/${item.uniqueId}`)
    }
    const playVideo = (e, item) => {
        e.stopPropagation();
        window.open(`https://www.tiktok.com/@${item.elasticSearchUser.nickname}/video/${item.video.id}`)
    }

    return <div id="BloggerRanking" style={{ paddingBottom: '50px' }}>
        <div style={{width: '100%', backgroundColor: '#FFF'}}>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',padding:'24px 24px 0'}}>
                <div className='sd-title'>{intl.get('GoodsRankingVideo')['关联视频概述']}</div>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={{display: 'flex',borderRadius:'4px',overflow:'hidden'}}>
                        {
                            dayList.map((item, index) => (
                                <span
                                    key={index}
                                    className='tabItem'
                                    style={{
                                        padding: '6px 10px',
                                        marginBottom: '5px',
                                        cursor: 'pointer',
                                        color: index == dayIndex ? '#E94753' : '#CCCCCC',
                                        background: index == dayIndex ? '#FFE7E9' : '#F8F8F8',
                                    }}
                                    onClick={() => setDayIndex(index)}
                                >{item.text}</span>
                            ))
                        }
                    </div>
                    <div style={{
                        width: 240,
                        height: 30,
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '10px',
                        border: '1px solid #EEEEEE',
                        color: '#CCCCCC',
                        marginLeft: '13px',
                        fontSize: '12px'
                    }}>
                        <CalendarOutlined style={{ marginRight: '17px' }} />
                        <div style={{ marginRight: '17px' }}>{moment(moment().subtract(dayList[dayIndex].value, 'days')).format('YYYY-MM-DD')}</div>
                        <div style={{ marginRight: '17px' }}>~</div>
                        <div style={{ marginRight: '17px' }}>{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')}</div>
                    </div>
                </div>
            </div>
            <div style={{width:'100%', height:'114px', backgroundColor:'#FFF', textAlign:'center', display:'flex', justifyContent:'space-around', alignItems:'center',
                padding:'0 35px' }}>
                <div>
                    <div style={{fontSize:'16px', fontWeight:'bold'}}></div>
                    <div style={{display:'flex', alignItems:'center', color:'#888888'}}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/RelatedVideos.png'} alt=""
                             style={{width:'14px', marginRight: '4px',}}/> {intl.get('GoodsRankingVideo')['关联视频数']}
                    </div>
                </div>
                <div>
                    <div style={{fontSize:'16px', fontWeight:'bold'}}></div>
                    <div style={{display:'flex', alignItems:'center', color:'#888888'}}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/AveragePlaysNum.png'} alt=""
                             style={{width:'14px', marginRight: '4px',}}/> {intl.get('GoodsRankingVideo')['平均播放数']}
                    </div>
                </div>
                <div>
                    <div style={{fontSize:'16px', fontWeight:'bold'}}></div>
                    <div style={{display:'flex', alignItems:'center', color:'#888888'}}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/AverageLikesNum.png'} alt=""
                             style={{width:'14px', marginRight: '4px',}}/> {intl.get('GoodsRankingVideo')['平均点赞数']}
                    </div>
                </div>
                <div>
                    <div style={{fontSize:'16px', fontWeight:'bold'}}></div>
                    <div style={{display:'flex', alignItems:'center', color:'#888888'}}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/AverageComment.png'} alt=""
                             style={{width:'14px', marginRight: '4px',}}/> {intl.get('GoodsRankingVideo')['平均评论数']}
                    </div>
                </div>
            </div>
        </div>
        {/* 排序 */}
        <div style={{
            width: '100%', height: '60px', backgroundColor: '#FFF', marginTop: '10px',
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '47px',
            paddingRight: '24px'
        }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {
                    sortList?.map((item, index) => {
                        return <div key={index} className='active' style={{
                            display: 'flex', alignItems: 'center', marginRight: '36px',
                            color: sortIndex == index ? '#F7385B' : '#888888', cursor: 'pointer'
                        }}
                                    onClick={() => sortChange(index)}>
                            {item}
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index && sortWay == 1 ? 's' : '') + '.png'} alt=""
                                 style={{ marginLeft: '6px', marginTop: '2px', transform: 'rotate(180deg)' }} />
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index && sortWay == 2 ? 's' : '') + '.png'} alt=""
                                 style={{ marginLeft: '2px', marginTop: '2px' }} />
                        </div>
                    })
                }
            </div>
        </div>

        {/* 列表 */}
        <div style={{
            width: '100%', backgroundColor: isBig ? '' : '#FFFFFF', padding: isBig ? '0 10px' : '24px 24px 24px 30px',
            marginTop: isBig ? 0 : '10px'
        }}>
            {isBig ? '' : <div style={{
                width: '100%', height: '50px', display: 'flex', alignItems: 'center', backgroundColor: '#F6F6F6',
                fontWeight: 500
            }}>
                <div style={{ width: '7.8%', paddingLeft: '19px' }}>{intl.get('BloggerRanking').Ranking}</div>
                <div style={{ width: '38%', paddingLeft: '24px' }}>{intl.get('TopicList').Video}</div>
                <div style={{ width: '23%', paddingLeft: '14px' }}>{intl.get('BloggerRanking').Blogger}</div>
                <div style={{ width: '6%', paddingRight: '10px' }}>{intl.get('TopicList').PlaysNum}</div>
                <div style={{ width: '6%', paddingRight: '10px' }}>{intl.get('BloggerRanking').LikesNum}</div>
                <div style={{ width: '7%', paddingRight: '10px' }}>{intl.get('TopicList').CommentsNum}</div>
                <div style={{ width: '' }}>{intl.get('BloggerRanking').Operation}</div>
            </div>}
            <Spin tip="数据加载中..." spinning={loading}>
                <InfiniteScroll
                    dataLength={data.length} scrollThreshold={'20px'}
                    next={loadMoreData}
                    hasMore={data.length < total}
                    loader={data.length>0?<Skeleton avatar paragraph={{ rows: 2 }} active />:''}
                    endMessage={state.userInfo?.userType == 1 && state.userInfo?.memberDetail?.memberType === '领航版' ? <Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider> : ''}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={data} grid={{ gutter: 0, column: isBig ? widths : 1 }}
                        renderItem={(item, index) => state.userInfo?.userType == 2 || (state.userInfo?.userType == 1 && state.userInfo?.memberStatus == 0 && index < 200) || (state.userInfo?.userType == 1 && state.userInfo?.memberStatus == 1) ? <div style={{ paddingTop: isBig ? '10px' : 0 }}>
                            {isBig ? '' : SmallDiv(item, index)}
                        </div> : ''}
                    />
                </InfiniteScroll>
            </Spin>

            {
                state.userInfo?.userType == 1 && state.userInfo?.memberStatus!=0 ?
                    state.userInfo?.memberDetail?.memberType === '起航版' && total <= data.length ?
                        <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                 style={{ width: '24px', marginRight: '7px' }} />
                            {intl.get('TopicList')['起航版员目前仅支持查看前200条数据']}
                            {/* <span style={{ color: '#F7385B', cursor: 'pointer' }}>{intl.get('BloggerRanking').OpenMember}</span>
                            {intl.get('BloggerRanking').ViewMoreData} */}
                        </div>
                        :
                        state.userInfo?.memberDetail?.memberType === '远航版' && total <= data.length ?
                            <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                     style={{ width: '24px', marginRight: '7px' }} />
                                {intl.get('TopicList')['远航版员目前仅支持查看前500条数据']}
                                {/* <span style={{ color: '#F7385B', cursor: 'pointer' }}>{intl.get('BloggerRanking').OpenMember}</span>
                                {intl.get('BloggerRanking').ViewMoreData} */}
                            </div>
                            :
                            ""
                    :
                    state.userInfo?.userType == 1 ?
                        <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                 style={{ width: '24px', marginRight: '7px' }} />
                            {intl.get('BloggerRanking').NonMembers} <span style={{ color: '#F7385B', cursor: 'pointer' }} onClick={() => props.navigate('/index/member')}>{intl.get('BloggerRanking').OpenMember}</span>
                            {intl.get('BloggerRanking').ViewMoreData}
                        </div>
                        :''
            }
        </div>
    </div>

}
export default withRouter(GoodsRankingVideo);