import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import { Anchor, Input, Checkbox, notification, DatePicker, AutoComplete, Upload, Image, message, Cascader, Button, Select, Modal, Spin} from 'antd';
import { DeleteOutlined, PlusCircleFilled, CloseOutlined, CloseCircleOutlined, DownOutlined, UpOutlined, PlusOutlined, CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import ImgCrop from 'antd-img-crop';
import * as Api from '../../../service/https'
import intl from 'react-intl-universal';
import store from '../../../utils/redux/index'
import {baseUrl, del} from '../../../service/request'
import three from '../../../utils/three'
import {debounce} from '../../../utils/debounce'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import '@wangeditor/editor/dist/css/style.css'
import { data } from 'dom7';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {getCategoryAll, getCategoryAttribute, getCountry, getCountryPage, handleProduct} from "../../../service/https";

const { Option } = Select;
const { Link } = Anchor;
const { TextArea } = Input;

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const ReleaseProducts = (props)=>{
    const [textJson, setTextJson] = useState(intl.get('ReleaseDemand'))
    const [textJsonE, setTextJsonE] = useState(intl.get('EnterpriseCertification'))
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [isDetail, setIsDetail] = useState(props.location?.state?.productId)
    const [productId, setProductId] = useState(props.location?.state?.productId)
    const [isSubFrist, setIsSubFrist] = useState(false)
    useEffect(()=>{
        // console.log(props)
        getCategory()
        getCountryList()
        getProductConfig()
        if(props.location?.state?.productId) {
            getProduct()
        }
        return ()=>{
            // subPost(3)
        }
    },[])



    const [links, setLinks] = useState([intl.get('ReleaseProducts')['添加商品基础信息'], intl.get('ReleaseProducts')['完善商品信息详情'], intl.get('ReleaseProducts')['添加商品销售属性']])
    const [linkId, setLinkId] = useState('')
    const onChangeLink = e =>{
        setLinkId(e)
    }


    const [loading, setLoading] = useState(true)



    const [tipList, setTipList] = useState([
        {"title":"商品名称", "requirement":"用英文填写，勿用URL，符号，和特殊字符。商品名称不能超过255个字符且不能包含违禁词。", "tips":"需清晰描述商品，包含品牌信息、商品品名、品类、基本属性(材质/功能/特征)和规格参数(型号/颜色/尺寸/规格/用途)等，不应包含其他无关品牌及无关信息。"},
        {"title":"商品所属类目", "requirement":"请准确选择至第三级类目。", "tips":"需清晰描述商品，包含品牌信息、请准确选择商品类目。若调整类目会导致商品属性数据重置。请勿上传“禁售/限售商品”，类目错放可能导致商品被下架或者其他违规处罚。"},
        {"title":"商品库存地", "requirement":"请选择商品库存地"},
        {"title":"商品可售地区", "requirement":"请选择商品可售地区"},
        {"title":"商品属性", "requirement":"用英文填写，商品品牌必填，其他属性选填。", "tips":"其他属性根据需求选择。"},
        {"title":"商品图片", "requirement":"最多可传9张图片，分辨率建议大于100*100px。图片大小不超过10MB，宽高比为1:1。", "tips":"产品的主图将决定消费者对您产品的第一印象。请选择清晰和贴合您产品的商品图片。请尽可能全方位地展示您的产品。建议在9张范围内尽量多的上传商品图片。","img":"https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/tip1.png"},
        {"title":"商品描述", "requirement":"用英文填写，可编辑文案或上传图片，分辨率建议大于100*100px。", "tips":"请提供清晰且全面的商品描述，列举您的商品关键信息，有助于帮助用户做出有利于您的购买决策。在商品描述里添加图片更有利于售出商品。"},
        {"title":"商品尺码表", "requirement":"请上传尺寸图来说明商品尺寸。图片尺寸建议600*600，且不超过10MB。", "tips":"请上传尺码表图片，以便客户准确找到适合自己的尺码。","img":"https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/tip2.png"},
        {"title":"商品视频", "requirement":"上传视频时，建议长宽比为1:1，大小不超过200MB，视频格式支持mp4。", "tips":"建议突出产品的1～2个核心卖点，这部分将在商品详情中展示。"},
        {"title":"商品重量", "requirement":"商品重量会影响运费计算，需在承运限定范围内。", "tips":"商品重量信息将影响跨境物流成本，从而影响商品的本地展示价。","help":"如果商品实际重量大于体积重，预估运费将根据实际重量计算。计算方式为实际重量*重量单价。"},
        {"title":"商品尺寸", "requirement":"商品尺寸会影响运费计算，需在承运限定范围内。", "tips":"商品尺寸信息将影响跨境物流成本，从而影响商品的本地展示价。","help":"如果商品体积重大于实际重量，预估运费将根据商品体积重计算。","img":"https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/tip3.png"},
        {"title":"售后服务期限", "requirement":"售后条款请使用英文填写", "tips":"设置商品的售后保障信息，让消费者更加放心购买。"},
        {"title":"销售属性名称", "requirement":"用英文填写，最多3种销售属性(如颜色、尺寸、长度或织物类型)。", "tips":"选择商品属性名称后，您可以在下方自行编辑相应的选项值，并可以在第一个商品属性名称下添加相应的图片。如果添加图片，用户将在选择产品规格时会看到它。"},
        {"title":"商品建议零售价", "requirement":"请填写商品建议零售价", "tips":"只能是数字"}
    ])
    const [tipIndex, changeActive] = useState(0)
    const getProductConfig = ()=>{
        Api.getProductConfig().then(res=>{
            if(res.code == 200){
                // 将接受的字符串转换成js表达式并且立即执行该表达式
                if(res.data) setTipList( eval(res.data))
            }
        })
    }

    const [countryList,setCountryList] = useState([])
    const getCountryList = ()=>{
        Api.getCountryPage().then(res=>{
            if(res.code == 200){
                setCountryList(res.data.records)
            }
        })
    }

    const [categoryAttribute, setCategoryAttribute] = useState()
    const [subData, setSubDta] = useState({
        categoryIds:[],
        isAddVariationImg: false, productAttributes: [], productImgUrls:[]
    })
    const changeSubData = (key, val, index = -1)=>{
        // console.log(key, val)
        let data = JSON.parse(JSON.stringify(subData))
        // console.log(data)
        if(key == 'productAttributes'){
            if(index == -1){
                data.productAttributes = []
                data.brandId = null
            }else{
                data.productAttributes[index] = val
            }
        }else{
            if(key == 'repertoryCountry') data[key] = { id:val }
            else if(key == 'salesCountry') data[key] = { id:val }
            else if(key == 'categoryIds' && val?.length > 0){
                // 必须放在前面赋值
                data[key] = val
                data.productAttributes = []
                data.brandId = null
                Api.getCategoryAttribute(val[val.length -1]).then(res=>{
                    if(res.code == 200){
                        setCategoryAttribute(res.data)
                    }
                })
            }else{
                data[key] = val
            }
        }
        setSubDta(data)
    }

    // 商品图片
    const [digImgList, setDigImgList] = useState([]);
    const isSize = (file, width, height)=> {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function() {
              if (reader.readyState == 2) {
                let img = document.createElement('img');
                img.style.display = 'none';
                img.src = reader.result;
                img.onload = function() {
                  const bool = this.height >= height || this.width >= width || this.width/this.height != 1;
                  if (bool) {
                    resolve(false);
                  }
                  resolve(true);
                };
              }
            };
        });
    }
    const fileLists = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/img/upload',
        headers: {
            'x-token': localStorage.getItem('token'),
        },
        async beforeUpload(file) {
            const isJpgOrPng = file.type.indexOf('image') > -1
            if (!isJpgOrPng) {
                message.error('请上传正确的图片！');
            }
            const isLt2M = file.size / 1024 / 1024 <= 10;
            if (!isLt2M) {
                message.error('图片过大，请上传10MB以下的图片!');
            }
            // const isProportion = await isSize(file,100,100).then(res=> res)
            // if(!isProportion){
            //     message.error('图片比例不等于1:1或分辨率小于100px!');
            //     return false
            // }
            return isJpgOrPng && isLt2M;
        },
        onChange({file, fileList}) {
            if (file.status === 'uploading') {
                if(loading){
                    message.loading(`上传中...`, 0);
                }
                setLoading(false)
            }
            if (file.status === 'done') {
                // console.log(file, fileList);
                var list = digImgList
                list.push({url: file.response.data, id: moment().format()})

                // setTimeout(() => {
                    setDigImgList(list)
                    message.destroy()
                    setLoading(true)
                    message.success(`${file.name}上传成功！`);
                // }, 1000);/
            } else if (file.status === 'error') {
                message.destroy()
                setLoading(true)
                message.error(`${file.name}上传失败！`);
            }
        }
    };
    const delImg = (e,index)=>{
        e.stopPropagation();
        let obj = JSON.parse(JSON.stringify(digImgList))
        // console.log(obj)
        obj.splice(index, 1)
        setDigImgList(obj)
    }

    // 拖拽调整顺序
    const onDragEnd = (result) => {
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;
        if (sourceIndex === destinationIndex) {
            return;
        }
        const userList = JSON.parse(JSON.stringify(digImgList));
        const [draggedItem] = userList.splice(sourceIndex, 1);
        userList.splice(destinationIndex, 0, draggedItem);
        setDigImgList(userList)
    };



    const [isShow, setIsShow] = useState(false)
    // 富文本
    const [editor, setEditor] = useState(null) // 存储 editor 实例
    const [html, setHtml] = useState(null)
    // useEffect(()=>{
    //     if(html){
    //         console.log(html)
    //         setEditor(editor)
    //     }
    // },[html])
    const toolbarConfig = {
        excludeKeys: [
            // editor.getAllMenuKeys() 排除菜单组，写菜单组 key 的值即可
            'insertImage',
            'insertVideo',
        ]
    }
    const editorConfig = {
        placeholder: `${intl.get('ReleaseProducts')['请输入内容']}...`, MENU_CONF:{}
    }
    // 及时销毁 editor ，重要！
    useEffect(() => {
        if(editor) editor.blur()
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])
    function insertText() {
        if (editor == null) return
        // editor.insertText('22')
        editor.clear()
        setHtml(null)
    }

    function printHtml() {
        if (editor == null) return
        console.log(editor.getHtml())
        console.log(editor.getText())
    }

    editorConfig.MENU_CONF['uploadImage'] = {
        // 上传图片的配置]
        fieldName: 'multipartFiles',
        server: baseUrl + '/basic/img/upload',
        headers: {
            'x-token': localStorage.getItem('token'),
        },
        // 单个文件的最大体积限制，默认为 2M
        maxFileSize: 10 * 1024 * 1024, // 1M
        // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
        allowedFileTypes: ['image/jpg','image/jpeg','image/png'],
        // 上传之前触发
        onBeforeUpload(file) {
            // file 选中的文件，格式如 { key: file }
            message.loading(`上传中...`, 0);
            return file
        },
        // 自定义插入图片
        customInsert: (res, insertFn)=> {
            // res 即服务端的返回结果
            // 从 res 中找到 url alt href ，然后插图图片
            message.destroy()
            if(res.code == 200){
                message.success(`上传成功！`);
                insertFn(res.data, '1', '2')
            }else{
                message.error(`上传失败！`);
            }
        },
    }
    editorConfig.MENU_CONF['uploadVideo'] = {
        // 上传视频的配置]
        fieldName: 'multipartFiles',
        server: baseUrl + '/basic/video/upload',
        headers: {
            'x-token': localStorage.getItem('token'),
        },
        // 单个文件的最大体积限制，默认为 2M
        maxFileSize: 200 * 1024 * 1024, // 1M
        allowedFileTypes: ['video/mp4'],
        // 上传之前触发
        onBeforeUpload(file) {
            // file 选中的文件，格式如 { key: file }
            message.loading(`上传中...`, 0);
            return file
        },
        // 自定义插入视频
        customInsert: (res, insertFn)=> {
            // res 即服务端的返回结果
            // 从 res 中找到 url poster ，然后插入视频
            message.destroy()
            if(res.code == 200){
                message.success(`上传成功！`);
                insertFn(res.data, '1')
            }else{
                message.error(`上传失败！`);
            }
        },
    }




    const [defaultFileList, setDefaultFileList] = useState(null)
    const fileOption = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/file/upload',
        headers: {
            'x-token': localStorage.getItem('token'),
        },
        defaultFileList: defaultFileList,
    };
    // 尺码表
    const fileSizeFun = (file)=>{
        if (file.status === 'uploading') {
            if(loading){
                message.loading(`上传中...`, 0);
                // console.log(file, fileList);
            }
            setLoading(false)
        }
        if (file.status === 'done') {
            // {name:'', url:''}
            // console.log(file)
            if(file.response.code == 200){
                changeSubData('sizeSpecificationImgUrl', file.response.data)
                message.destroy()
                setLoading(true)
                message.success(`${file.name}上传成功！`);
            }else{
                message.error(`上传失败！`);
            }
        }
    }
    // 视频
    const fileVideoLists = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/video/upload',
        headers: {
            'x-token': localStorage.getItem('token'),
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type == 'video/mp4'
            console.log(isJpgOrPng)
            if (!isJpgOrPng) {
                message.error(intl.get('UploadVideo')['请上传正确的视频！']);
            }
            const isLt2M = file.size / 1024 / 1024 < 200;
            if (!isLt2M) {
                message.error(intl.get('UploadVideo')['视频过大，请上传200MB以下的视频！']);
            }
            return isLt2M && isJpgOrPng;
        },
        onChange(e) {
            let { file, fileList } = e
            if (file.status === 'uploading') {
                if(loading){
                    message.loading(`上传中...`, 0);
                }
                setLoading(false)
            }else if (file.status === 'done') {
                if(file.response.code == 200){
                    changeSubData('videoUrl', file.response.data)
                    message.destroy()
                    setLoading(true)
                    message.success(`${file.name}上传成功！`);
                }else{
                    message.destroy()
                    message.error(`上传失败！`);
                }
            } else if (file.status === 'error') {
                message.destroy()
                setLoading(true)
                if(file.response.status == 'error'){
                    message.warning(`取消上传！`);
                }else{
                    message.error(`${file.name}上传失败！`);
                }
            }
        }
    };
    // 销售属性图片
    const fileFun = (index, indexC, key, file)=>{
        if (file.status === 'uploading') {
            if(loading){
                message.loading(`上传中...`, 0);
                // console.log(file, fileList);
            }
            setLoading(false)
        }
        if (file.status === 'done') {
            // {name:'', url:''}
            console.log(file)
            if(file.response.code == 200){
                changeDataAttribute(index, indexC, 'img', file.response.data)
                message.destroy()
                setLoading(true)
                message.success(`${file.name}上传成功！`);
            }else{
                message.error(`上传失败！`);
            }
        }
    }


    const [categoryList, setCategoryList] = useState([])
    const getCategory =()=>{
        Api.getCategory().then(res=>{
            if(res.code == 200){
                let list = res.data
                list.forEach(item1=>{
                    item1.value = item1.id
                    item1.label = lang == 'zh' ? item1.name : item1.enName
                    item1.children.forEach(item2=>{
                        item2.value = item2.id
                        item2.label = lang == 'zh' ? item2.name : item2.enName
                        item2.children.forEach(item3=>{
                            item3.value = item3.id
                            item3.label = lang == 'zh' ? item3.name : item3.enName
                        })
                    })
                })
                setCategoryList(list)
            }
        })
    }


    const [attributeClass, setAttribute] = useState(['Color', 'Size', 'Length'])
    let attribute = {
        name: '',
        options:[{str:''}]
    }
    // 销售属性列表
    const [dataAttributeList, setDataAttributeList] = useState([{name:'', options:[{str:''}]}])
    // 编辑销售属性
    const changeDataAttribute = (index, indexC, key, val)=>{
        let data = JSON.parse(JSON.stringify(dataAttributeList))
        if(indexC == -1){ //切换销售属性类目
            data[index][key] = val
            // let dataClass = JSON.parse(JSON.stringify(attributeClass))
            // if(data.length == 1){
            //     var classIndex = dataClass.indexOf(data[0].name);
            //     dataClass.splice(classIndex, 1)
            //     if(dataAttributeList[index].name!='') dataClass.push(dataAttributeList[index].name)
            //     setAttribute(dataClass)
            // }
            // if(data.length == 2){
            //     // 删除选择的类目
            //     var classIndex = dataClass.indexOf(data[1].name);
            //     dataClass.splice(classIndex, 1)
            //     // 恢复被切换的类目
            //     if(dataAttributeList[index].name!='') dataClass.push(dataAttributeList[index].name)
            //     setAttribute(dataClass)
            // }
            // if(data.length == 3){
            //     var classIndex = dataClass.indexOf(data[2].name);
            //     dataClass.splice(classIndex, 1)
            //     if(dataAttributeList[index].name!='') dataClass.push(dataAttributeList[index].name)
            //     setAttribute(dataClass)
            // }
        }else{//编辑销售属性选项
            data[index].options[indexC][key] = val
        }
        setDataAttributeList(data)
    }
    // 添加销售属性
    const addAttribute = ()=>{
        let data = JSON.parse(JSON.stringify(dataAttributeList))
        data.push(attribute)
        setDataAttributeList(data)
    }
    const delAttribute = (index)=>{
        let data = JSON.parse(JSON.stringify(dataAttributeList))
        // let dataClass = JSON.parse(JSON.stringify(attributeClass))
        // 恢复删除的销售属性
        // if(data[index].name){
        //     dataClass.push(data[index].name)
        //     setAttribute(dataClass)
        // }
        // 清空记录删除的属性列表项
        setDelList([])
        // 删除销售属性
        data.splice(index, 1)
        setDataAttributeList(data)
    }
    // 添加选项
    const addOption = (index)=>{
        let data = JSON.parse(JSON.stringify(dataAttributeList))
        data[index].options.push(index == 0 && subData.isAddVariationImg ? {
            str:'', img:''
        } : {str:''})
        setDataAttributeList(data)
    }
    // 删除选项
    const delOption = (index, indexC)=>{
        let data = JSON.parse(JSON.stringify(dataAttributeList))
        data[index].options.splice(indexC, 1)
        setDataAttributeList(data)
        if(delList?.length > 0){ // 如果被删除的选项，已经存在被删除的属性列表中，则删除该条Id
            delList.forEach((item, delIndex)=>{
                if(item.indexOf(`${data[index]?.name}=${data[index].options[indexC]?.str}`) > -1){
                    let delData = JSON.parse(JSON.stringify(delList))
                    delData.splice(delIndex, 1)
                    setDelList(delData)
                }
            })
        }
    }
    // 删除选项
    const delOptionImg = (index, optionIndex)=>{
        let data = JSON.parse(JSON.stringify(dataAttributeList))
        data[index].options[optionIndex].img = ''
        setDataAttributeList(data)
    }

    // 属性列表
    const [attributeList, setAttributeList] = useState([])
    const [attributeDetailList, setAttributeDetailList] = useState([])
    // 删除的属性列表Id
    const [delList, setDelList] = useState([])
    const price = {
        PreTaxPrice:'', price:'', quantity:''
    }
    useEffect(()=>{ // 实时生成销售属性列表
        let list = []
        if(dataAttributeList.length > 0){
            // console.log(dataAttributeList)
            // console.log(delList)
            // 初始化批量编辑筛选条件
            setFilterData({
                [dataAttributeList[0]?.name]:'全部', [dataAttributeList[1]?.name]:'全部', [dataAttributeList[2]?.name]:'全部', PreTaxPrice:'', price:'', quantity:''
            })
            dataAttributeList[0]?.options.forEach((item1, index1)=>{
                // 根据Id过滤被删除的属性
                var id1 = `${dataAttributeList[0]?.name}=${item1.str}`
                if(dataAttributeList.length == 1 && delList.indexOf(id1) == -1 && dataAttributeList[0]?.name) list.push({
                    frontKey: id1, // 根据属性类目值生成Id
                    [dataAttributeList[0]?.name]: item1.str,
                    var1: item1.str,
                    ...price
                })
                dataAttributeList[1]?.options.forEach((item2, index2)=>{
                    var id2 = `${dataAttributeList[0]?.name}=${item1.str}&${dataAttributeList[1]?.name}=${item2.str}`
                    if(dataAttributeList.length == 2 && delList.indexOf(id2) == -1 && dataAttributeList[0]?.name && dataAttributeList[1]?.name) list.push({
                        frontKey: id2,
                        [dataAttributeList[0]?.name]: item1.str,
                        [dataAttributeList[1]?.name]: item2.str,
                        var1: item1.str,
                        var2: item2.str,
                        ...price
                    })
                    dataAttributeList[2]?.options.forEach((item3, index3)=>{
                        var id3 = `${dataAttributeList[0]?.name}=${item1.str}&${dataAttributeList[1]?.name}=${item2.str}&${dataAttributeList[2]?.name}=${item3.str}`
                        if(delList.indexOf(id3) == -1 && dataAttributeList[0]?.name && dataAttributeList[1]?.name && dataAttributeList[2]?.name) list.push({
                            frontKey: id3,
                            [dataAttributeList[0]?.name]: item1.str,
                            [dataAttributeList[1]?.name]: item2.str,
                            [dataAttributeList[2]?.name]: item3.str,
                            var1: item1.str,
                            var2: item2.str,
                            var3: item3.str,
                            ...price
                        })
                    })
                })
            })
            list.map((item, index)=>{
                attributeList.map(itemS=> {
                    if(item.frontKey === itemS.frontKey){
                        list[index] = itemS
                    }
                })
            })
            // console.log(list)
            if(isDetail){
                setAttributeDetailList(list)
            }else{
                setAttributeList(list)
            }
        }else{
            setAttributeList([])
        }
    },[dataAttributeList])
    // 编辑属性列表项(输入框)
    const changeAttributeList = (index, key, val)=>{
        let data = JSON.parse(JSON.stringify(attributeList))
        data[index][key] = val
        // console.log(data)
        setAttributeList(data)
    }
    // 删除属性列表项
    const delAttributeList = (index)=>{
        let data = JSON.parse(JSON.stringify(attributeList))
        let delData = JSON.parse(JSON.stringify(delList))
        delData.push(data[index].frontKey)
        // 保存删除的列表项Id
        setDelList(delData)
        data.splice(index, 1)
        setAttributeList(data)
    }


    // 待添加的属性列表项
    const [addCanOPtion, setAddCanOPtion] = useState(null)
    // 可添加的属性列表项的类目
    const [canAddCalss, setCanAddCalss] = useState({
        var1:[], var2:[], var3:[]
    })
    useEffect(()=>{
        // console.log(delList)
        if(delList.length > 0){ // 删除属性列表项时，生成可添加的属性列表项的类目
            let var1 = [], var2 = [], var3 = []
            delList.forEach(item=>{
                if(item.split('&')[0]){
                    var classItem = item.split('&')[0].split('=')[1]
                    if(item.split('&')[0].split('=')[0] == dataAttributeList[0]?.name && var1.indexOf(classItem) == -1) var1.push(classItem)
                    if(item.split('&')[0].split('=')[0] == dataAttributeList[1]?.name && var2.indexOf(classItem) == -1) var1.push(classItem)
                    if(item.split('&')[0].split('=')[0] == dataAttributeList[2]?.name && var3.indexOf(classItem) == -1) var3.push(classItem)
                }
                if(item.split('&')[1]){
                    var classItem = item.split('&')[1].split('=')[1]
                    if(item.split('&')[1].split('=')[0] == dataAttributeList[0]?.name && var1.indexOf(classItem) == -1) var1.push(classItem)
                    if(item.split('&')[1].split('=')[0] == dataAttributeList[1]?.name && var2.indexOf(classItem) == -1) var2.push(classItem)
                    if(item.split('&')[1].split('=')[0] == dataAttributeList[2]?.name) var3.push(classItem)
                }
                if(item.split('&')[2]){
                    var classItem = item.split('&')[2].split('=')[1]
                    if(item.split('&')[2].split('=')[0] == dataAttributeList[0]?.name && var1.indexOf(classItem) == -1) var1.push(classItem)
                    if(item.split('&')[2].split('=')[0] == dataAttributeList[1]?.name && var2.indexOf(classItem) == -1) var2.push(classItem)
                    if(item.split('&')[2].split('=')[0] == dataAttributeList[2]?.name && var3.indexOf(classItem) == -1) var3.push(classItem)
                }
            })
            setCanAddCalss({var1, var2, var3})
            // 删除属性列表项时，如果正在添加属性列表项，则重置填写
            if(addCanOPtion) addAttributeOption()
        }
    },[delList])
    // 添加属性列表项
    const addAttributeOption = ()=>{
        setAddCanOPtion({[dataAttributeList[0]?.name]:'', [dataAttributeList[1]?.name]:'', [dataAttributeList[2]?.name]:'',
        var1:'', var2:'', var3:'', PreTaxPrice:'', price:'', quantity:''})
    }
    // 编辑待添加的属性列表项(输入框)
    const changeCanAdd = (key, val, keys = false)=>{
        let data = JSON.parse(JSON.stringify(addCanOPtion))
        data[key] = val
        if(keys) data[keys] = val
        // console.log(data)
        setAddCanOPtion(data)
    }
    // 确定添加属性列表项
    const preservation = ()=>{
        let data= JSON.parse(JSON.stringify(attributeList))
        // 恢复，生成id，应和属于被删除的属性列表中的id相同
        var id1 = addCanOPtion[dataAttributeList[0]?.name] ? `${dataAttributeList[0]?.name}=${addCanOPtion[dataAttributeList[0]?.name]}` : ''
        var id2 = addCanOPtion[dataAttributeList[1]?.name] ? `&${dataAttributeList[1]?.name}=${addCanOPtion[dataAttributeList[1]?.name]}` : ''
        var id3 = addCanOPtion[dataAttributeList[2]?.name] ? `&${dataAttributeList[2]?.name}=${addCanOPtion[dataAttributeList[2]?.name]}` : ''
        // console.log(id1 + id2 + id3)
        if(delList.indexOf(id1 + id2 + id3) > -1){
            addCanOPtion.frontKey = id1 + id2 + id3
            data.push(addCanOPtion)
            setAttributeList(data)
            setAddCanOPtion(null)
            // 从被删除的列表中移除当前添加的属性id，恢复到正常属性列表
            let delData= JSON.parse(JSON.stringify(delList))
            var delIndex = delData.indexOf(id1 + id2 + id3)
            delData.splice(delIndex, 1)
            setDelList(delData)
        }else{
            message.warning('已经存在啦，请勿重复添加！')
        }
    }

    // 是否批量编辑
    const [isAddSub, setIsAddSub] = useState(false)
    const [filterData, setFilterData] = useState()
    const filterChange = (key, val)=>{
        let data = JSON.parse(JSON.stringify(filterData))
        data[key] = val
        setFilterData(data)
    }
    // 批量编辑
    const addAllFilter = ()=>{
        let data = JSON.parse(JSON.stringify(attributeList))
        const var1 = 'var1', var2 = 'var2', var3 = 'var3'
        data.map(item=>{
            if(item[var1] == filterData[var1]
                && item[var2] == filterData[var2]
                && item[var3] == filterData[var3]){
                // item.PreTaxPrice = filterData.PreTaxPrice
                if(filterData.price) item.price = filterData.price
                if(filterData.quantity) item.quantity = filterData.quantity
            }else if(item[var1] == filterData[var1]
                && item[var2] == filterData[var2]
                && (!filterData[var3] || filterData[var3] == '全部')){
                // item.PreTaxPrice = filterData.PreTaxPrice
                if(filterData.price) item.price = filterData.price
                if(filterData.quantity) item.quantity = filterData.quantity
            }else if(item[var1] == filterData[var1]
                && item[var3] == filterData[var3]
                && (!filterData[var2] || filterData[var2] == '全部')){
                // item.PreTaxPrice = filterData.PreTaxPrice
                if(filterData.price) item.price = filterData.price
                if(filterData.quantity) item.quantity = filterData.quantity
            }else if(item[var2] == filterData[var2]
                && item[var3] == filterData[var3]
                && (!filterData[var1] || filterData[var1] == '全部')){
                // item.PreTaxPrice = filterData.PreTaxPrice
                if(filterData.price) item.price = filterData.price
                if(filterData.quantity) item.quantity = filterData.quantity
            }else if(item[var1] == filterData[var1]
                && (!filterData[var2] || filterData[var2] == '全部')
                && (!filterData[var3] || filterData[var3] == '全部')){
                // item.PreTaxPrice = filterData.PreTaxPrice
                if(filterData.price) item.price = filterData.price
                if(filterData.quantity) item.quantity = filterData.quantity
            }else if(item[var2] == filterData[var2]
                && (!filterData[var1] || filterData[var1] == '全部')
                && (!filterData[var3] || filterData[var3] == '全部')){
                // item.PreTaxPrice = filterData.PreTaxPrice
                if(filterData.price) item.price = filterData.price
                if(filterData.quantity) item.quantity = filterData.quantity
            }else if(item[var3] == filterData[var3]
                && (!filterData[var1] || filterData[var1] == '全部')
                && (!filterData[var2] || filterData[var2] == '全部')){
                // item.PreTaxPrice = filterData.PreTaxPrice
                if(filterData.price) item.price = filterData.price
                if(filterData.quantity) item.quantity = filterData.quantity
            }else if((!filterData[var1] || filterData[var1] == '全部')
                && (!filterData[var2] || filterData[var2] == '全部')
                && (!filterData[var3] || filterData[var3] == '全部')){
                // item.PreTaxPrice = filterData.PreTaxPrice
                if(filterData.price) item.price = filterData.price
                if(filterData.quantity) item.quantity = filterData.quantity
            }
        })
        setAttributeList(data)
        setFilterData({
            [var1]:'', [var2]:'', [var3]:'', PreTaxPrice:'', price:'', quantity:''
        })
        setIsAddSub(false)
    }


    // 获取草稿、详情
    const [isDetailLoding, setDetailLoding] = useState(false)
    const getProduct = ()=>{
        setDetailLoding(true)
        Api.getSupplyProductDetail({productId: props.location?.state?.productId}).then(res=>{
            setDetailLoding(false)
            if(res.code == 200){
                if(res.data.productDetail.categoryIds?.length > 0){
                    Api.getCategoryAttribute(res.data.productDetail.categoryIds[res.data.productDetail.categoryIds.length -1]).then(res=>{
                        if(res.code == 200){
                            setCategoryAttribute(res.data)
                        }
                    })
                }
                if(res.data.productDetail.productAttributes.length > 0) setIsShow(true)
                setSubDta(res.data.productDetail)
                let list = []
                res.data.productDetail.productImgUrls.forEach((item,index)=>{
                    list.push({url: item, id: moment().format()+`id${index}`})
                })
                setDigImgList(list)
                setHtml(res.data.productDetail.productDescription)
                setDataAttributeList(res.data.productDetail.variations)
                setAttributeList(res.data.productDetail.variationList)
                setIsDetail(false)
            }
        })
    }
    useEffect(()=>{
        if(isDetail && attributeDetailList?.length > 0){
            var list = [], del = []
            for (let index = 0; index < attributeDetailList.length; index++) {
                const item = attributeDetailList[index];
                for (let indexS = 0; indexS < subData.variationList.length; indexS++) {
                    const itemS = subData.variationList[indexS];
                    if(itemS.frontKey === item.frontKey){
                        list.push(itemS)
                        break
                    }
                    if(itemS.frontKey !== item.frontKey && indexS == subData.variationList.length - 1){
                        // console.log(indexS,itemS.frontKey, index, item.frontKey)
                        del.push(item.frontKey)
                        break
                    }
                }
            }
            setDelList(del)
            setAttributeList(list)
        }
    },[attributeDetailList])
    // 判断是否有重复项
    const isEqualP = (val, myI) =>{
        if(val){
            for (let index = 0; index < dataAttributeList.length; index++) {
                const item = dataAttributeList[index];
                if(item.name == val && index != myI){
                    return true
                }
            }
        }
        return false
    }
    const isEqualC = (val, i, myI) =>{
        const item = dataAttributeList[i];
        if(val){
            for (let index = 0; index < item.options.length; index++) {
                const element = item.options[index];
                if(element.str == val && index != myI) {
                    return true
                }
            }
        }
        return false
    }
    const isSub = ()=>{
        if(dataAttributeList.length > 0){
            for (let index = 0; index < dataAttributeList.length; index++) {
                const item = dataAttributeList[index];
                if(!item.name || item.options.length == 0){
                    return false
                }
                if(subData.isAddVariationImg){
                    for (let index = 0; index < item.options.length; index++) {
                        const element = item.options[index];
                        if(element.img=='') {
                            return false
                        }
                    }
                }
                // 判断是否有重复项
                let arrs = item.options.map(item=> item.str)
                if(Array.from(new Set(arrs)).length != arrs.length){
                    return false
                }
            }
            let arrs = dataAttributeList.map(item=> item.name)
            if(Array.from(new Set(arrs)).length != arrs.length){
                return false
            }
        }
        if(attributeList.length > 0){
            for (let index = 0; index < attributeList.length; index++) {
                const item = attributeList[index];
                if(item.price =='' || item.quantity=='' || item.quantity == 0 || item.price == 0){
                    return false
                }
            }
        }
        return subData.productName && subData.retailPrice && subData.categoryIds && subData?.repertoryCountry?.id && subData?.salesCountry?.id && subData.productBrandName && digImgList.length > 0
            && editor?.getText() !='' && attributeList.length > 0 && dataAttributeList.length > 0
    }
    const openNotificationWithIcon = (type, message = '')=> {
        notification[type]({
          description: message, top: 100, duration: 3
        });
    };
    const [isSuccess, setIsSuc] = useState(false)
    const subPost =(productStatus = 3)=>{
        // console.log(dataAttributeList)
        // console.log(attributeList)
        setDetailLoding(true)
        try {
            let obj = JSON.parse(JSON.stringify(subData))
            obj.productName = obj.productName?.trim()
            obj.productBrandName = obj.productBrandName?.trim()
            obj.productStatus = productStatus
            //商品Id(更新时传)
            if(productId) obj.productId = productId
            // obj.categoryId = subData.categoryId[subData.categoryId.length -1]
            let digImg = JSON.parse(JSON.stringify(digImgList))
            let imgList = []
            digImg.forEach(item=> imgList.push(item.url))
            obj.productImgUrls = imgList
            let attributesArr = []
            if(productStatus = 3){
                categoryAttribute?.specList?.map((item, index)=> {
                    if(item.id == obj.productAttributes[index]?.specId){
                        attributesArr.push(obj.productAttributes[index])
                    }else{
                        let obj = {}
                        obj.select = []
                        obj.specId = item.id
                        attributesArr.push(obj)
                    }
                })
                obj.productAttributes = attributesArr
            }
            obj.productDescription = html
            obj.variationList = attributeList
            obj.variations = dataAttributeList
            if(!obj.commissionPercent) obj.commissionPercent = 0 // 默认需要传佣金
            // console.log(obj)
            Api.handleProduct(obj).then(res=>{
                setDetailLoding(false)
                if(res.code == 200){
                    if(res.data.productDetail.productStatus == 1){ //清空
                        setIsSuc(true)
                        setSubDta({
                            categoryIds:[],
                            isAddVariationImg: false, productAttributes: [], productImgUrls:[]
                        })
                        setAttributeDetailList([])
                        setDataAttributeList([{name:'', options:[]}])
                        setAttributeList([])
                        editor?.clear()
                        setHtml(null)
                        setDigImgList([])
                        setDelList([])
                        setCategoryAttribute(null)
                        store.dispatch({ type: 'changeRouter', value: `/releaseProducts&&/products` })
                    }else{
                        setProductId(res.data.productDetail.productId)
                        openNotificationWithIcon('success', '草稿保存成功！')
                    }
                }
            })
        } catch (error) {
            setDetailLoding(false)
        }
    }


    const verification = (value)=>{
        return isSubFrist ? {
            borderColor: value && value.length > 0 ? '#d9d9d9' : '#F7385B'
        } : {}
    }

    return <div id='videoDemand'>
        <div style={{width:'100%', display:'flex', letterSpacing:'0.6px'}}>
            <div style={{width:'250px'}}>
                <div style={{width:'234px', backgroundColor:'#FFF', padding:'30px 30px 16px 30px',
                    position: linkId!='' ? 'fixed' : '', top: '66px'}}>
                    <Anchor affix={false} offsetTop={66} onChange={e=>onChangeLink(e)}>
                        {
                            links.map((item, index)=>{
                                return <div className='over1' key={index} style={{width:'100%', height:'34px',
                                    background: (linkId == ("#id"+index)) || (index==0 && linkId=='') ?'linear-gradient(180deg, #F7385B, #FF716E)' : '#FFF',
                                    marginBottom:'8px', position:'relative', textAlign:'center', lineHeight:'34px',
                                    color: (linkId == ("#id"+index)) || (index==0 && linkId=='') ? '#FFF' : '#666',
                                    fontWeight: (linkId == ("#id"+index)) || (index==0 && linkId=='') ? 'bold':500, borderRadius:'4px', fontSize:'16px'}}>
                                    {item}
                                    <div style={{width:'calc(100% + 14px)', height:'100%', position:'absolute', top:0, left: '-14px',
                                        opacity:0}}>
                                        <Link href={"#id"+index} title={item} />
                                    </div>
                                </div>
                            })
                        }
                    </Anchor>
                    <div style={{width:'100%', marginTop:'10px', background:'#fff'}}>
                        <div style={{width:'234px', height:'18px', background:'rgb(246, 246, 246)', position:'relative', left:'-30px'}}></div>
                        <div style={{width:'234px', height:'50px', background:'#FFEEF1', fontSize:'16px', fontWeight:'bold',
                        backgroundImage:'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/titleBack.png)', backgroundSize:'100% 100%',
                        lineHeight:'50px', paddingLeft:'30px', color:'#333', position:'relative', left:'-30px'}}>{tipList[tipIndex]?.title}</div>
                        <div style={{marginTop:'16px'}}>
                            <div style={{fontWeight:'bold'}}>{intl.get('ReleaseProducts')['要求']}</div>
                            <div style={{color:'#666', fontSize:'12px', marginTop:'5px', lineHeight:'16px'}}>
                                {tipList[tipIndex]?.requirement}
                            </div>
                        </div>
                        <div style={{marginTop:'12px'}}>
                            <div style={{fontWeight:'bold'}}>{intl.get('ReleaseProducts')['提示']}</div>
                            <div style={{color:'#666', fontSize:'12px', marginTop:'5px', lineHeight:'16px'}}>
                                {tipList[tipIndex]?.tips}
                            </div>
                        </div>
                        {tipList[tipIndex]?.help ? <div style={{marginTop:'12px'}}>
                            <div style={{fontWeight:'bold'}}>{intl.get('ReleaseProducts')['如何计算预估运费']}</div>
                            <div style={{color:'#666', fontSize:'12px', marginTop:'5px', lineHeight:'16px'}}>
                                {tipList[tipIndex]?.help}
                            </div>
                        </div>:''}
                        {tipList[tipIndex]?.img ? <div style={{marginTop:'12px'}}>
                            <div style={{fontWeight:'bold'}}>{intl.get('ReleaseProducts')['示例']}</div>
                            <div style={{color:'#666', fontSize:'12px', marginTop:'5px', textAlign:'center'}}>
                                <img src={tipList[tipIndex]?.img} style={{width:'160px', height:'160px'}} />
                            </div>
                        </div>:''}
                    </div>
                </div>
            </div>
            <div style={{width:'calc(100% - 250px)', backgroundColor:'#FFF', paddingBottom:'90px', position:'relative'}}>
                <div id='id0' style={{width:'100%', padding: '30px 70px'}}>
                    <div style={{display:'flex'}}>
                        <div style={{minWidth:'200px', background:'linear-gradient(90deg, #F7385B, #FFFFFF)',
                            fontSize:'16px', fontWeight:'bold', color:'#fff', padding:'5px 20px 5px 13px', borderRadius:'4px'}}>
                            {intl.get('ReleaseProducts')['商品基础信息']}
                        </div>
                    </div>
                    <div style={{marginTop:'23px'}}>
                        <div style={{fontWeight: 600, marginBottom:'10px'}}><span style={{color:'#F7385B', marginRight:'3px', fontWeight:'bold', fontSize:'17px'}}>*</span>{textJson['商品名称']}</div>
                        <Input  type="text" onChange={e=>changeSubData('productName', e.target.value.replace(/[^A-Za-z\s0-9&%]/g,''))} value={subData?.productName}
                            style={{width:'100%', height:'40px', borderRadius:'4px', ...verification(subData.productName)}}
                            placeholder={intl.get('ReleaseProducts')['请输入商品名称，不超过255个字符']}
                            maxLength={255} showCount required
                            onFocus={()=>changeActive(0)}/>
                    </div>
                    <div id='products' style={{marginTop:'23px'}}>
                        <div style={{fontWeight: 600, marginBottom:'10px'}}><span style={{color:'#F7385B', marginRight:'3px', fontWeight:'bold', fontSize:'17px'}}>*</span>{intl.get('ReleaseProducts')['商品所属类目']}</div>
                        <Cascader
                            style={{ width:'100%', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px', ...verification(subData?.categoryIds) }}
                            onFocus={()=>changeActive(1)}
                            placeholder={<span style={{fontSize:'14px'}}>{intl.get('ReleaseProducts')['请选择商品所属的类目']}</span>}
                            // defaultValue={subData?.categoryId ? [subData?.categoryId] : undefined}
                            value={subData?.categoryIds}
                            onChange={(val, e)=>changeSubData('categoryIds', val)}
                            options={categoryList} />
                    </div>
                    <div id='products'  style={{marginTop:'23px'}}>
                        <div style={{height:'26px', lineHeight:'26px', fontWeight: 600, marginBottom:'6px'}}><span style={{color:'#F7385B', marginRight:'3px', fontWeight:'bold', fontSize:'17px'}}>*</span>商品库存地</div>
                        {<Select
                                        onFocus={()=>changeActive(2)}
                                        mode={''} size='large'
                                        style={{ width:'100%', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px', background:'#FFF' }}
                                        placeholder={<span style={{fontSize:'14px'}}>请选择商品库存地</span>} allowClear={true} showArrow={true}
                                        onChange={(val, e)=>changeSubData('repertoryCountry',val)} value={subData?.repertoryCountry?.id || undefined}
                                        optionLabelProp="label" getPopupContainer={() => document.getElementById('products')}
                                    >
                                        {
                                            countryList?.map((item,index)=>{
                                                return <Option key={index} id={item.id} value={item.id} label={item.nameCn}>
                                                    <div style={{textAlign:'left'}}>
                                                        {item.nameCn}
                                                    </div>
                                                </Option>
                                            })
                                        }
                                    </Select> }
                    </div>
                    <div id='products'  style={{marginTop:'23px'}}>
                        <div style={{height:'26px', lineHeight:'26px', fontWeight: 600, marginBottom:'6px'}}><span style={{color:'#F7385B', marginRight:'3px', fontWeight:'bold', fontSize:'17px'}}>*</span>商品可售地区</div>
                        {<Select
                            onFocus={()=>changeActive(3)}
                            mode={''} size='large'
                            style={{ width:'100%', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px', background:'#FFF' }}
                            placeholder={<span style={{fontSize:'14px'}}>请选择商品可售地区</span>} allowClear={true} showArrow={true}
                            onChange={(val, e)=>changeSubData('salesCountry', val)} value={subData?.salesCountry?.id || undefined}
                            optionLabelProp="label" getPopupContainer={() => document.getElementById('products')}
                        >
                            {
                                countryList?.map((item,index)=>{
                                    return <Option key={index} id={item.id} value={item.id} label={item.nameCn}>
                                        <div style={{textAlign:'left'}}>
                                            {item.nameCn}
                                        </div>
                                    </Option>
                                })
                            }
                        </Select> }
                    </div>
                    <div style={{marginTop:'23px'}}>
                        <div style={{fontWeight: 600, marginBottom:'10px'}}>{intl.get('ReleaseProducts')['商品属性']}</div>
                        <div onClick={()=>changeActive(2)} style={{width:'100%', background:'#f6f6f6', padding:'25px 30px', borderRadius:'4px'}}>
                            <div style={{display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
                                <div id='products' style={{width:'48%', marginBottom:'25px'}}>
                                    <div style={{height:'26px', lineHeight:'26px', fontWeight: 600, marginBottom:'6px'}}><span style={{color:'#F7385B', marginRight:'3px', fontWeight:'bold', fontSize:'17px'}}>*</span>{intl.get('ReleaseProducts')['商品品牌']}</div>
                                    <Input onChange={e=>changeSubData('productBrandName', e.target.value.replace(/[^A-Za-z\s0-9&%]/g,''))} value={subData?.productBrandName}
                                    style={{width:'100%', height:'40px', borderRadius:'4px', ...verification(subData?.productBrandName)}} placeholder={intl.get('ReleaseProducts')['请输入商品的品牌名称']}
                                    required />
                                    {/* <Select
                                        mode={''} size='large'
                                        style={{ width:'100%', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px', background:'#FFF' }}
                                        placeholder={<span style={{fontSize:'14px'}}>{intl.get('ReleaseProducts')['请选择商品的品牌名称']}</span>} allowClear={true} showArrow={true}
                                        onChange={(val, e)=>changeSubData('brandId', e.id)} value={subData?.brandId || undefined}
                                        optionLabelProp="label" getPopupContainer={() => document.getElementById('products')}
                                    >
                                        {
                                            categoryAttribute?.brandsList.map((item,index)=>{
                                                return <Option key={index} id={item.id} value={item.id} label={item.name}>
                                                    <div style={{textAlign:'left'}}>
                                                        {item.name}
                                                    </div>
                                                </Option>
                                            })
                                        }
                                    </Select> */}
                                </div>
                                {
                                    categoryAttribute?.specList?.map((item,index)=>{
                                        var showIndex = isShow ? index : 0
                                        return index <= showIndex ? <div key={index} style={{width:'48%', marginBottom:'25px'}}>
                                            <div style={{height:'26px', lineHeight:'26px', fontWeight: 600, marginBottom:'6px'}}>{item.name}<span style={{color:'#888', fontSize:'14px', fontWeight:'500'}}>（{intl.get('ReleaseProducts')['选填']}）</span></div>
                                            <div id='products' style={{background:'#FFF'}}>
                                                <Select
                                                    mode={item.isMultiChoice?'multiple':''} size='large' maxTagCount={item.name == 'Feature'?1:2}
                                                    style={{ width: '100%', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px' }}
                                                    placeholder={<span style={{fontSize:'14px'}}>{intl.get('ReleaseProducts')['请选择商品所属的类目']}</span>} allowClear={true} showArrow={true}
                                                    onChange={(val,e)=>changeSubData('productAttributes', {select: val ? (item.isMultiChoice?val:[val]) : null, specId: item.id}, index)}
                                                    value={subData?.productAttributes[index]?.select || undefined}
                                                    optionLabelProp="label" getPopupContainer={() => document.getElementById('products')}
                                                >
                                                    {
                                                        item.values?.map((itemS,index)=>{
                                                            return <Option key={index} id={itemS.id} value={itemS.id} label={itemS.val}>
                                                                <div style={{textAlign:'left'}}>
                                                                    {itemS.val}
                                                                </div>
                                                            </Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div> : ''
                                    })
                                }
                            </div>
                            {categoryAttribute?.specList ? <div style={{width:'100%', textAlign:'center', color:'#F7385B'}}>
                                <span onClick={()=>setIsShow(!isShow)} style={{cursor:'pointer'}}>
                                    {isShow?intl.get('ReleaseProducts')['收起']:intl.get('ReleaseProducts')['展开更多']} {isShow?<UpOutlined />:<DownOutlined />}
                                </span>
                            </div>:''}
                        </div>
                    </div>

                </div>

                <div style={{width:'100%', height:'16px', background:'#f6f6f6'}}></div>

                <div id='id1' style={{width:'100%', padding: '30px 70px'}}>
                    <div style={{display:'flex'}}>
                        <div style={{minWidth:'200px', background:'linear-gradient(90deg, #F7385B, #FFFFFF)',
                            fontSize:'16px', fontWeight:'bold', color:'#fff', padding:'5px 20px 5px 13px', borderRadius:'4px'}}>
                            {intl.get('ReleaseProducts')['商品信息详情']}
                        </div>
                    </div>
                    <div style={{marginTop:'23px'}}>
                        <div style={{fontWeight: 600, marginBottom:'10px'}}>
                            <span style={{color:'#F7385B', marginRight:'3px', fontWeight:'bold', fontSize:'17px'}}>*</span>{intl.get('ReleaseProducts')['商品图片']}
                            <span style={{color:'#CCCCCC', fontSize:'14px', fontWeight:'500'}}>（<span style={{color:'#F7385B'}}>{digImgList?.length || 0}</span>/9张）{intl.get('ReleaseProducts')['拖动图片可更换产品主图']}</span>
                        </div>
                        <div onClick={()=>changeActive(3)} style={{width:'100%', display:'flex', flexWrap:'wrap'}}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="mymodal" direction="horizontal">
                                    {(provided) => (
                                        <div style={{display:'flex'}} ref={provided.innerRef} {...provided.droppableProps}>
                                            {digImgList.map((item, index) => (
                                                <Draggable draggableId={item.id} index={index} key={item.id}>
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <div style={{width:'104px', height:'104px', borderRadius:'4px', marginRight:'12px', position:'relative'}}>
                                                                <div style={{width:'104px', height:'104px', borderRadius:'4px',
                                                                backgroundImage:'url('+item.url+')', backgroundRepeat:'no-repeat', backgroundSize:'cover'}}></div>
                                                                {/* <Image src={item.url}
                                                                style={{width:'104px', height:'104px', borderRadius:'4px'}} preview={false} /> */}
                                                                {index == 0 ? <div style={{width:'100%', height:'24px', background:'rgba(0,0,0,0.38)', textAlign:'center',
                                                                    color:'#FFF',fontSize:'12px', lineHeight:'24px', position:'absolute', bottom:'2px', left:0,
                                                                    borderBottomLeftRadius:'4px', borderBottomRightRadius:'4px'}}>
                                                                    {intl.get('ReleaseProducts')['产品主图']}
                                                                </div>:''}
                                                                <div style={{width:'16px', height:'16px', borderRadius:'50%', fontSize:'8px',
                                                                background:'#FFEEF1', boxShadow:'0px 0px 6px 0px #A9A9A9', color:'#F7385B', cursor:'pointer',
                                                                textAlign:'center', lineHeight:'12px', position:'absolute', top:'-6px', right:'-6px'}}>
                                                                    <CloseOutlined onClick={(e)=>delImg(e,index)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {digImgList.length < 9 ? <div style={{width:'104px', height:'104px', border:'1px solid #EEEEEE', textAlign:'center', paddingTop:'28px',
                            cursor:'pointer', borderRadius:'4px', overflow:'hidden', ...verification(digImgList)}}>
                                <PlusOutlined style={{color:'#888888', fontSize:'20px'}} />
                                <div style={{color:'#F7385B', marginTop:'3px'}}>
                                    {intl.get('ReleaseProducts')['添加图片']}
                                    <ImgCrop aspect={1/1} rotate >
                                        <Upload {...fileLists} showUploadList={false}>
                                            <div style={{width:'104px', height:'104px', position:'relative', top:'-79px', opacity:0 }} >
                                            </div>
                                        </Upload>
                                    </ImgCrop>
                                </div>
                            </div>:''}
                        </div>
                    </div>
                    <div style={{marginTop:'23px'}}>
                        <div style={{fontWeight: 600, marginBottom:'10px'}}>
                            <span style={{color:'#F7385B', marginRight:'3px', fontWeight:'bold', fontSize:'17px'}}>*</span>{intl.get('ReleaseProducts')['商品描述']}
                        </div>
                        <div>
                            {/* <div>
                                <button onClick={insertText}>insert text</button>
                                <button onClick={printHtml}>print html</button>
                            </div> */}
                            <div onClick={()=>changeActive(4)} style={{ border: '1px solid #ccc', zIndex: 100, ...verification(html=='<p><br></p>'?false:html)}}>
                                <Toolbar
                                    editor={editor}
                                    defaultConfig={toolbarConfig}
                                    mode="default"
                                    style={{ borderBottom: '1px solid #ccc' }}
                                />
                                <Editor
                                    defaultConfig={editorConfig}
                                    value={html || undefined}
                                    onCreated={setEditor}
                                    onChange={editors => {
                                        if(editors.getHtml().length < 10000){
                                            // if(/.*[\u4e00-\u9fa5]+.*$/.test(editors.getHtml())){
                                            //     message.info( "请以英文填写！" );
                                            // }
                                            setHtml(editors.getHtml())
                                            // editor.setHtml(editor.getHtml()?.replace(/[\u4E00-\u9FA5]/g,''))
                                        }else{
                                            setHtml(html)
                                        }
                                    }}
                                    mode="default"
                                    style={{ height: '300px' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop:'23px'}}>
                        <div style={{fontWeight: 600, marginBottom:'10px'}}>
                            {intl.get('ReleaseProducts')['尺码表']}
                            <span style={{color:'#CCCCCC', fontSize:'14px', fontWeight:'500'}}>（{intl.get('ReleaseProducts')['选填']}）</span>
                        </div>
                        <div onClick={()=>changeActive(5)} style={{width:'100%', display:'flex', flexWrap:'wrap'}}>
                            {
                                subData.sizeSpecificationImgUrl ? <div style={{width:'104px', height:'104px', cursor:'pointer', borderRadius:'4px', marginRight:'12px', position:'relative'}}>
                                    <Image src={subData.sizeSpecificationImgUrl}
                                    style={{width:'104px', height:'104px', borderRadius:'4px'}} preview={false} />
                                    <div onClick={()=>changeSubData('sizeSpecificationImgUrl', null)} style={{width:'16px', height:'16px', borderRadius:'50%', fontSize:'8px',
                                    background:'#FFEEF1', boxShadow:'0px 0px 6px 0px #A9A9A9', color:'#F7385B', cursor:'pointer',
                                    textAlign:'center', lineHeight:'12px', position:'absolute', top:'-6px', right:'-6px'}}>
                                        <CloseOutlined />
                                    </div>
                                </div> :
                                <div style={{width:'104px', height:'104px', border:'1px solid #EEEEEE', textAlign:'center', paddingTop:'28px',
                                cursor:'pointer', borderRadius:'4px', overflow:'hidden'}}>
                                    <PlusOutlined style={{color:'#888888', fontSize:'20px'}} />
                                    <div style={{color:'#F7385B', marginTop:'3px'}}>
                                        {intl.get('ReleaseProducts')['添加图片']}
                                        <Upload {...fileLists} showUploadList={false}
                                        beforeUpload={(file)=> {
                                            const isJpgOrPng = file.type.indexOf('image') > -1
                                            if (!isJpgOrPng) {
                                                message.error('请上传正确的图片！');
                                            }
                                            const isLt2M = file.size / 1024 / 1024 <= 10;
                                            if (!isLt2M) {
                                                message.error('图片过大，请上传10MB以下的图片!');
                                            }
                                            return isJpgOrPng && isLt2M;
                                        }}
                                        onChange={e=>fileSizeFun(e.file)}>
                                            <div style={{width:'104px', height:'104px', position:'relative', top:'-79px', opacity:0 }} >
                                                {textJson['上传照片']}
                                            </div>
                                        </Upload>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{marginTop:'23px'}}>
                        <div style={{fontWeight: 600, marginBottom:'10px'}}>
                            {intl.get('ReleaseProducts')['视频']}
                            <span style={{color:'#CCCCCC', fontSize:'14px', fontWeight:'500'}}>（{intl.get('ReleaseProducts')['选填']}）</span>
                        </div>
                        <div onClick={()=>changeActive(6)} style={{width:'100%', display:'flex', flexWrap:'wrap'}}>
                            {
                                subData.videoUrl ? <div style={{width:'104px', height:'104px', cursor:'pointer', borderRadius:'4px', marginRight:'12px', position:'relative'}}>
                                    <Image src={subData.videoUrl+'?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,m_fast'}
                                    style={{width:'104px', height:'104px', borderRadius:'4px'}} preview={false} />
                                    <div onClick={()=>changeSubData('videoUrl', null)} style={{width:'16px', height:'16px', borderRadius:'50%', fontSize:'8px',
                                    background:'#FFEEF1', boxShadow:'0px 0px 6px 0px #A9A9A9', color:'#F7385B', cursor:'pointer',
                                    textAlign:'center', lineHeight:'12px', position:'absolute', top:'-6px', right:'-6px'}}>
                                        <CloseOutlined />
                                    </div>
                                </div> :
                                <div style={{width:'104px', height:'104px', border:'1px solid #EEEEEE', textAlign:'center', paddingTop:'28px',
                                cursor:'pointer', borderRadius:'4px', overflow:'hidden'}}>
                                    <PlusOutlined style={{color:'#888888', fontSize:'20px'}} />
                                    <div style={{color:'#F7385B', marginTop:'3px'}}>
                                        {intl.get('ReleaseProducts')['添加视频']}
                                        <Upload {...fileVideoLists} showUploadList={false}>
                                            <div style={{width:'104px', height:'104px', position:'relative', top:'-79px', opacity:0 }} >
                                                {textJson['上传照片']}
                                            </div>
                                        </Upload>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {/* <div style={{width:'100%', height:'16px', background:'#f6f6f6'}}></div> */}

                {/* <div id='id2' style={{width:'100%', padding: '30px 70px'}}>
                    <div style={{width:'200px', background:'linear-gradient(90deg, #F7385B, #FFFFFF)',
                        fontSize:'16px', fontWeight:'bold', color:'#fff', padding:'5px 0 5px 13px', borderRadius:'4px'}}>
                        {['物流和售后保修']}
                    </div>
                    <div style={{marginTop:'23px'}}>
                        <div style={{fontWeight: 600, marginBottom:'10px'}}>
                            <span style={{color:'#F7385B', marginRight:'3px', fontWeight:'bold', fontSize:'17px'}}>*</span>{['商品重量']}
                        </div>
                        <Input onFocus={()=>changeActive(7)} addonBefore={
                            <Select defaultValue="克(g)" style={{width:'113px'}}>
                                <Option value="http://">http://</Option>
                                <Option value="https://">https://</Option>
                            </Select>
                            }
                            placeholder={["请输入商品重量"]} style={{width:'526px'}}
                        />
                    </div>
                    <div style={{marginTop:'23px'}}>
                        <div style={{fontWeight: 600, marginBottom:'10px'}}>
                            <span style={{color:'#F7385B', marginRight:'3px', fontWeight:'bold', fontSize:'17px'}}>*</span>{['商品尺寸']}
                        </div>
                        <div style={{display:'flex', flexWrap:'wrap'}}>
                            <div style={{marginRight:'25px', marginBottom:'10px'}}>
                                <Input onFocus={()=>changeActive(8)} addonBefore={
                                    <Select defaultValue="长度(cm)" style={{width:'113px'}}>
                                        <Option value="http://">http://</Option>
                                        <Option value="https://">https://</Option>
                                    </Select>
                                    }
                                    placeholder={["请输入商品长度"]} style={{width:'325px'}}
                                />
                            </div>
                            <div style={{marginRight:'25px', marginBottom:'10px'}}>
                                <Input onFocus={()=>changeActive(8)} addonBefore={
                                    <Select defaultValue="宽度(cm)" style={{width:'113px'}}>
                                        <Option value="http://">http://</Option>
                                        <Option value="https://">https://</Option>
                                    </Select>
                                    }
                                    placeholder={["请输入商品宽度"]} style={{width:'325px'}}
                                />
                            </div>
                            <div style={{marginBottom:'10px'}}>
                                <Input onFocus={()=>changeActive(8)} addonBefore={
                                    <Select defaultValue="高度(cm)" style={{width:'113px'}}>
                                        <Option value="http://">http://</Option>
                                        <Option value="https://">https://</Option>
                                    </Select>
                                    }
                                    placeholder={["请输入商品高度"]} style={{width:'325px'}}
                                />
                            </div>
                        </div>
                        <div style={{fontSize:'12px', color:'#CCCCCC'}}>请确保包裹重量和尺寸准确，以便系统正确计算运费和选择运输方式</div>
                    </div>
                    <div style={{marginTop:'23px'}}>
                        <div style={{fontWeight: 600, marginBottom:'10px'}}><span style={{marginRight:'25px'}}>跨境物流成本：--</span> 预估买家运费：--</div>
                        <div style={{fontSize:'12px', color:'#CCCCCC'}}>预估运费将根据您输入的商品重量和体积计算</div>
                    </div>
                    <div style={{marginTop:'23px'}}>
                        <div style={{fontWeight: 600, marginBottom:'10px'}}>售后服务期限<span style={{color:'#CCCCCC', fontSize:'14px', fontWeight:'500'}}>（选填）</span></div>
                        <Input onFocus={()=>changeActive(9)} style={{width:'100%', height:'40px', borderRadius:'4px'}} placeholder={['请选择服务期限']}/>
                    </div>
                    <div style={{marginTop:'23px'}}>
                        <div style={{fontWeight: 600, marginBottom:'10px'}}>服务条款<span style={{color:'#CCCCCC', fontSize:'14px', fontWeight:'500'}}>（选填）</span></div>
                        <Input onFocus={()=>changeActive(9)} style={{width:'100%', height:'40px', borderRadius:'4px'}} placeholder={['请用英文输入服务条款的内容']}/>
                    </div>
                </div> */}

                <div style={{width:'100%', height:'16px', background:'#f6f6f6'}}></div>

                <div id='id2' style={{width:'100%', padding: '30px 70px'}}>
                    <div style={{display:'flex'}}>
                        <div style={{minWidth:'200px', background:'linear-gradient(90deg, #F7385B, #FFFFFF)',
                            fontSize:'16px', fontWeight:'bold', color:'#fff', padding:'5px 20px 5px 13px', borderRadius:'4px'}}>
                            {intl.get('ReleaseProducts')['商品销售属性']}
                        </div>
                    </div>
                    {
                        dataAttributeList.map((item,index)=>{
                            return <div key={index} style={{width:'100%', background:'#f6f6f6', padding:'25px 30px', marginTop:'20px', borderRadius:'4px', position:'relative'}}>
                                <CloseOutlined onClick={()=>delAttribute(index)} style={{position:'absolute', top:'27px', right:'34px', color:'#888888', fontWeight:'bold', fontSize:'16px', cursor:'pointer'}} />
                                <div id='products' style={{width:'calc(100% - 80px)', marginBottom:'20px'}}>
                                    <div style={{fontWeight: 600, marginBottom:'10px', display:'flex', justifyContent:'space-between'}}>
                                        <div><span style={{color:'#F7385B', marginRight:'3px', fontWeight:'bold', fontSize:'17px'}}>*</span>{intl.get('ReleaseProducts')['销售属性名称']}</div>
                                        {index==0?<Checkbox style={{transform: 'scale(0.88)', position:'relative', left:'10px'}}
                                            checked={subData.isAddVariationImg} onChange={e=>changeSubData('isAddVariationImg', e.target.checked)}>
                                            <span style={{fontSize:'14px'}}>{intl.get('ReleaseProducts')['添加图片']}</span>
                                        </Checkbox>:''}
                                    </div>
                                    <div style={{background:'#FFF'}}>
                                        <AutoComplete
                                            style={{width:'100%', height:'38px', border: isEqualP(item.name, index)?'1px solid #F7385B':'1px solid #d9d9d9', borderRadius:'4px',
                                            ...verification(item.name)}}
                                            placeholder={<span style={{fontSize:'14px'}}>{intl.get('ReleaseProducts')['请选择或输入销售属性名称']}</span>}
                                            onChange={(e)=>changeDataAttribute(index, -1, 'name', e?.replace(/[\u4E00-\u9FA5]/g,''))} value={item.name || undefined}
                                            onFocus={()=>changeActive(10)} suffix={<CloseOutlined />}
                                            options={
                                                attributeClass.map((itemP,indexP)=>{
                                                    return {value: itemP}
                                                })
                                            }
                                        />
                                        {/* <Select
                                            size='large'
                                            style={{ width:'100%', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px' }}
                                            placeholder={<span style={{fontSize:'14px'}}>{['请选择或输入销售属性名称']}</span>} allowClear={true} showArrow={true}
                                            onChange={(e)=>changeDataAttribute(index, -1, 'name', e)} value={item.name || undefined}
                                            optionLabelProp="label" getPopupContainer={() => document.getElementById('products')}
                                            onFocus={()=>changeActive(10)}
                                        >
                                            {
                                                attributeClass.map((itemP,indexP)=>{
                                                    return <Option key={indexP} id={itemP} value={itemP} label={itemP}>
                                                        <div style={{textAlign:'left'}}>
                                                            {itemP}
                                                        </div>
                                                    </Option>
                                                })
                                            }
                                        </Select> */}
                                    </div>
                                </div>
                                {
                                    item.options.map((optionItem, optionIndex)=>{
                                        return <div key={optionIndex} style={{marginTop: index==0 && subData.isAddVariationImg ? 0:'14px'}}>
                                            <div style={{fontWeight: 600, marginBottom:'10px'}}><span style={{color:'#F7385B', marginRight:'3px', fontWeight:'bold', fontSize:'17px'}}>*</span>{intl.get('ReleaseProducts')['选项']}</div>
                                            <div style={{display:'flex'}}>
                                                <Input onChange={(e)=>changeDataAttribute(index, optionIndex, 'str', e.target.value.replace(/[\u4E00-\u9FA5]/g,''))} value={optionItem.str||undefined}
                                                style={{width:'100%', height:'40px', borderRadius:'4px', border: isEqualC(optionItem.str, index, optionIndex)?'1px solid #F7385B':'1px solid #d9d9d9',
                                                ...verification(optionItem.str)}} placeholder={intl.get('ReleaseProducts')['请输入选项名称']}/>
                                                {index == 0 && subData.isAddVariationImg ? <div>
                                                    {optionItem.img ? <div style={{minWidth:'76px', width:'76px', height:'76px', cursor:'pointer', borderRadius:'4px',
                                                        position:'relative', marginLeft:'20px', marginTop:'-18px'}}>
                                                        <Image src={optionItem.img}
                                                        style={{width:'76px', height:'76px', borderRadius:'4px'}} preview={false} />
                                                        <div onClick={()=>delOptionImg(index, optionIndex)} style={{width:'16px', height:'16px', borderRadius:'50%', fontSize:'8px',
                                                        background:'#FFEEF1', boxShadow:'0px 0px 6px 0px #A9A9A9', color:'#F7385B', cursor:'pointer',
                                                        textAlign:'center', lineHeight:'12px', position:'absolute', top:'-6px', right:'-6px'}}>
                                                            <CloseOutlined />
                                                        </div>
                                                    </div> :
                                                    <div style={{minWidth:'76px', width:'76px', height:'76px', border:'1px solid #EEEEEE', textAlign:'center', paddingTop:'16px',
                                                    cursor:'pointer', borderRadius:'4px', overflow:'hidden', background:'#FFF', marginLeft:'20px', marginTop:'-18px'}}>
                                                        <PlusOutlined style={{color:'#888888', fontSize:'16px'}} />
                                                        <div style={{color:'#F7385B', marginTop:'3px', fontSize:'12px'}}>
                                                            {intl.get('ReleaseProducts')['添加图片']}
                                                            <Upload {...fileOption} showUploadList={false}
                                                            beforeUpload={(file)=> {
                                                                const isJpgOrPng = file.type.indexOf('image') > -1
                                                                if (!isJpgOrPng) {
                                                                    message.error('请上传正确的图片！');
                                                                }
                                                                const isLt2M = file.size / 1024 / 1024 < 10;
                                                                if (!isLt2M) {
                                                                    message.error('图片过大，请上传10MB以下的图片!');
                                                                }
                                                                return isJpgOrPng && isLt2M;
                                                            }}
                                                            onChange={(e) => fileFun(index, optionIndex, 'img', e.file) }>
                                                                <div style={{width:'76px', height:'76px', position:'relative', top:'-79px', opacity:0 }} >
                                                                    {textJson['上传照片']}
                                                                </div>
                                                            </Upload>
                                                        </div>
                                                    </div>}
                                                </div>:''}
                                                <div style={{minWidth:'80px', width:'80px', marginTop:'10px'}}>
                                                    <div onClick={()=>delOption(index, optionIndex)}
                                                        style={{width:'100%', textAlign:'right', color:'#F7385B', cursor:'pointer'}}>
                                                        <DeleteOutlined style={{fontSize:'16px', marginRight:'6px'}} />
                                                        <span>{textJson['删除']}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                                <div style={{marginTop:index==0 && subData.isAddVariationImg ? 0:'10px'}}>
                                    <span style={{color:'#F7385B', cursor:'pointer'}} onClick={()=>addOption(index)}><PlusCircleFilled style={{marginRight:'7px'}} />{intl.get('ReleaseProducts')['添加选项']}</span>
                                </div>
                            </div>
                        })
                    }
                    {dataAttributeList.length < 3 ? <div onClick={addAttribute} style={{
                        width: '117px', height: '36px', border:'1px solid #F7385B',
                        textAlign: 'center', lineHeight: '36px', color: '#F7385B', borderRadius: '4px',
                        cursor: 'pointer', letterSpacing: '1px', marginTop:'16px'
                    }}>{intl.get('ReleaseProducts')['添加销售属性']}</div>:''}
                    {

                        attributeList.length > 0 ? <div style={{marginTop:'23px'}}>
                            <div style={{fontWeight: 600, marginBottom:'10px'}}><span style={{color:'#F7385B', marginRight:'3px', fontWeight:'bold', fontSize:'17px'}}>*</span>商品建议零售价</div>
                            <Input  onClick={()=>changeActive(tipList.length - 1)} type="text" onChange={e=>changeSubData('retailPrice', e.target.value.replace(/[^\d.]/, ''))} value={subData?.retailPrice}
                                    style={{width:'100%', height:'40px', borderRadius:'4px', ...verification(subData.productName)}}
                                    placeholder="请输入商品建议零售价" showCount required/>
                        </div> : ''
                    }
                    {attributeList.length > 0 ? <div style={{width:'100%', marginTop:'58px'}}>
                        <div style={{fontWeight: 600, marginBottom:'10px', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                            <div><span style={{color:'#F7385B', marginRight:'3px', fontWeight:'bold', fontSize:'17px'}}>*</span>{intl.get('ReleaseProducts')['销售属性列表']}</div>
                            {!isAddSub ? <div onClick={()=>setIsAddSub(true)} style={{
                                width: '105px', height: '36px', border:'1px solid #F7385B',
                                textAlign: 'center', lineHeight: '36px', color: '#F7385B', borderRadius: '4px',
                                cursor: 'pointer', letterSpacing: '1px',
                            }}>{intl.get('ReleaseProducts')['批量编辑']} <UpOutlined /></div>:''}
                        </div>
                        {
                            isAddSub ? <div style={{width:'100%', height:'60px', padding:'0 40px',
                            display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                <div id='products' style={{width:'119px'}}>
                                    <div id='canClass' style={{marginLeft:'-11%'}}>
                                        <Select
                                            size='large'
                                            style={{ width:'80%', border: '1px solid #d9d9d9', textAlign:'left', borderRadius:'4px' }}
                                            allowClear={true} showArrow={true}
                                            onChange={(e)=> filterChange('var1', e)} value={filterData['var1'] || '全部'}
                                            optionLabelProp="label" getPopupContainer={() => document.getElementById('products')}
                                        >
                                            <Option key={-1} id={'全部'} value={'全部'} label={'全部'}>
                                                <div style={{textAlign:'left'}}>
                                                {intl.get('ReleaseProducts')['全部']}
                                                </div>
                                            </Option>
                                            {
                                                dataAttributeList.filter(item=> item.name == dataAttributeList[0]?.name)?.map(item=>
                                                    item.options.map((item,index)=>{
                                                        return <Option key={index} id={item.str} value={item.str} label={item.str}>
                                                            <div style={{textAlign:'left'}}>
                                                                {item.str}
                                                            </div>
                                                        </Option>
                                                    })
                                                )
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div id='products' style={{width:'119px'}}>
                                    <div id='canClass' style={{marginLeft:'-11%'}}>
                                        <Select
                                            size='large'
                                            style={{ width:'80%', border: '1px solid #d9d9d9', textAlign:'left', borderRadius:'4px' }}
                                            allowClear={true} showArrow={true}
                                            onChange={(e)=> filterChange('var2', e)} value={filterData['var2'] || '全部'}
                                            optionLabelProp="label" getPopupContainer={() => document.getElementById('products')}
                                        >
                                            <Option key={-1} id={'全部'} value={'全部'} label={'全部'}>
                                                <div style={{textAlign:'left'}}>
                                                {intl.get('ReleaseProducts')['全部']}
                                                </div>
                                            </Option>
                                            {
                                                dataAttributeList.filter(item=> item.name == dataAttributeList[1]?.name)?.map(item=>
                                                    item.options.map((item,index)=>{
                                                        return <Option key={index} id={item.str} value={item.str} label={item.str}>
                                                            <div style={{textAlign:'left'}}>
                                                                {item.str}
                                                            </div>
                                                        </Option>
                                                    })
                                                )
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div id='products' style={{width:'119px'}}>
                                    <div id='canClass' style={{marginLeft:'-11%'}}>
                                        <Select
                                            size='large'
                                            style={{ width:'80%', border: '1px solid #d9d9d9', textAlign:'left', borderRadius:'4px' }}
                                            allowClear={true} showArrow={true}
                                            onChange={(e)=> filterChange('var3', e)} value={filterData['var3'] || '全部'}
                                            optionLabelProp="label" getPopupContainer={() => document.getElementById('products')}
                                        >
                                            <Option key={-1} id={'全部'} value={'全部'} label={'全部'}>
                                                <div style={{textAlign:'left'}}>
                                                {intl.get('ReleaseProducts')['全部']}
                                                </div>
                                            </Option>
                                            {
                                                dataAttributeList.filter(item=> item.name == dataAttributeList[2]?.name)?.map(item=>
                                                    item.options.map((item,index)=>{
                                                        return <Option key={index} id={item.str} value={item.str} label={item.str}>
                                                            <div style={{textAlign:'left'}}>
                                                                {item.str}
                                                            </div>
                                                        </Option>
                                                    })
                                                )
                                            }
                                        </Select>
                                    </div>
                                </div>
                                {/* <div style={{width:'119px'}}>
                                    <Input onChange={e=>filterChange('PreTaxPrice', e.target.value.replace(/[^\d.]/, ''))} value={filterData?.PreTaxPrice} prefix={'$'}
                                    style={{width:'90%', height:'30px', borderRadius:'4px', marginLeft:'11%'}} placeholder={['请输入']} />
                                </div> */}
                                <div style={{width:'119px'}}>
                                    <Input onChange={e=>filterChange('price', e.target.value.replace(/[^\d.]/, ''))} value={filterData?.price} prefix={'$'}
                                    style={{width:'90%', height:'30px', borderRadius:'4px'}} placeholder={intl.get('ReleaseProducts')['请输入']} />
                                </div>
                                <div style={{width:'119px'}}>
                                    <Input onChange={e=>filterChange('quantity', e.target.value.replace(/[^\d.]/, ''))} value={filterData?.quantity} suffix={<span style={{fontSize:'13px'}}>{intl.get('ReleaseProducts')['件']}</span>}
                                    style={{width:'92%', height:'30px', borderRadius:'4px'}} placeholder={intl.get('ReleaseProducts')['请输入']} />
                                </div>
                                <div style={{width:'86px'}}>
                                    <Button style={{width:'104px', height:'30px', borderRadius:'4px', position:'relative', right:'-22px',
                                        textAlign:'center', color: '#FFF', border:0,
                                        background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontSize:'14px'}}
                                        onClick={()=>addAllFilter()}>
                                        {intl.get('ReleaseProducts')['确定应用']}
                                    </Button>
                                </div>
                            </div> :''
                        }
                        <div style={{width:'100%', border:'1px solid #EEEEEE'}}>
                            <div style={{width:'100%', height:'40px', background:'#F6F6F6', padding:'0 40px',
                            display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                <div style={{width:'119px'}}>{dataAttributeList[0]?.name || '--'}</div>
                                <div style={{width:'119px'}}>{dataAttributeList[1]?.name || '--'}</div>
                                <div style={{width:'119px'}}>{dataAttributeList[2]?.name || '--'}</div>
                                {/* <div style={{width:'119px'}}>税前价</div> */}
                                <div style={{width:'119px'}}>{intl.get('ReleaseProducts')['商品展示价']}</div>
                                <div style={{width:'119px'}}>{intl.get('ReleaseProducts')['库存数量']}</div>
                                <div style={{width:'86px', paddingLeft:'18px'}}>{intl.get('ReleaseProducts')['操作']}</div>
                            </div>
                            {
                                attributeList.map((item,index)=>{
                                    return <div key={index} style={{width:'100%', height:'60px', padding:'0 40px', borderTop:'1px solid #EEEEEE',
                                    display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                        <div style={{width:'119px'}}>{item.var1 || '--'}</div>
                                        <div style={{width:'119px'}}>{item.var2 || '--'}</div>
                                        <div style={{width:'119px'}}>{item.var3 || '--'}</div>
                                        {/* <div style={{width:'119px'}}>
                                            <Input onChange={e=>changeAttributeList(index, 'PreTaxPrice', e.target.value.replace(/[^\d.]/, ''))} value={item.PreTaxPrice} prefix={'$'}
                                            style={{width:'90%', height:'30px', borderRadius:'4px'}} placeholder={['请输入']} />
                                        </div> */}
                                        <div style={{width:'119px'}}>
                                            <Input onChange={e=>changeAttributeList(index, 'price', e.target.value.replace(/[^\d.]/, ''))} value={item.price} prefix={'$'}
                                            style={{width:'90%', height:'30px', borderRadius:'4px', ...verification(item.price)}} placeholder={intl.get('ReleaseProducts')['请输入']} />
                                        </div>
                                        <div style={{width:'119px'}}>
                                            <Input onChange={e=>changeAttributeList(index, 'quantity', e.target.value.replace(/[^\d]/, ''))} value={item.quantity} suffix={<span style={{fontSize:'13px'}}>件</span>}
                                            style={{width:'90%', height:'30px', borderRadius:'4px', ...verification(item.quantity)}} placeholder={intl.get('ReleaseProducts')['请输入']} />
                                        </div>
                                        <div style={{width:'86px'}}>
                                            <div onClick={()=>delAttributeList(index)}
                                                style={{width:'100%', color:'#F7385B', cursor:'pointer', paddingLeft:'18px'}}>
                                                <DeleteOutlined style={{fontSize:'16px', marginRight:'6px'}} />
                                                <span>{textJson['删除']}</span>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                            {
                                addCanOPtion ? <div style={{width:'100%', height:'60px', padding:'0 40px', borderTop:'1px solid #EEEEEE',
                                display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                    <div id='products' style={{width:'119px'}}>
                                        <div id='canClass' style={{marginLeft:'-11%'}}>
                                            <Select
                                                size='large'
                                                style={{ width:'80%', border: (addCanOPtion[dataAttributeList[0]?.name] || !dataAttributeList[0]?.name)?'1px solid #d9d9d9':'1px solid #F7385B', textAlign:'left', borderRadius:'4px' }}
                                                allowClear={true} showArrow={true}
                                                onChange={(e)=>changeCanAdd(dataAttributeList[0]?.name, e, 'var1')} value={addCanOPtion[dataAttributeList[0]?.name] || undefined}
                                                optionLabelProp="label" getPopupContainer={() => document.getElementById('products')}
                                            >
                                                {
                                                    canAddCalss.var1.map((item,index)=>{
                                                        return <Option key={index} id={item} value={item} label={item}>
                                                            <div style={{textAlign:'left'}}>
                                                                {item}
                                                            </div>
                                                        </Option>
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                    <div id='products' style={{width:'119px'}}>
                                        <div id='canClass' style={{marginLeft:'-11%'}}>
                                            <Select
                                                size='large'
                                                style={{ width:'80%', border: (addCanOPtion[dataAttributeList[1]?.name] || !dataAttributeList[1]?.name)?'1px solid #d9d9d9':'1px solid #F7385B', textAlign:'left', borderRadius:'4px' }}
                                                allowClear={true} showArrow={true}
                                                onChange={(e)=>changeCanAdd(dataAttributeList[1]?.name, e, 'var2')} value={addCanOPtion[dataAttributeList[1]?.name] || undefined}
                                                optionLabelProp="label" getPopupContainer={() => document.getElementById('products')}
                                            >
                                                {
                                                    canAddCalss.var2.map((item,index)=>{
                                                        return <Option key={index} id={item} value={item} label={item}>
                                                            <div style={{textAlign:'left'}}>
                                                                {item}
                                                            </div>
                                                        </Option>
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                    <div id='products' style={{width:'119px'}}>
                                        <div id='canClass' style={{marginLeft:'-11%'}}>
                                            <Select
                                                size='large'
                                                style={{ width:'80%', border: (addCanOPtion[dataAttributeList[2]?.name] || !dataAttributeList[2]?.name)?'1px solid #d9d9d9':'1px solid #F7385B', textAlign:'left', borderRadius:'4px' }}
                                                allowClear={true} showArrow={true}
                                                onChange={(e)=>changeCanAdd(dataAttributeList[2]?.name, e, 'var3')} value={addCanOPtion[dataAttributeList[2]?.name] || undefined}
                                                optionLabelProp="label" getPopupContainer={() => document.getElementById('products')}
                                            >
                                                {
                                                    canAddCalss.var3.map((item,index)=>{
                                                        return <Option key={index} id={item} value={item} label={item}>
                                                            <div style={{textAlign:'left'}}>
                                                                {item}
                                                            </div>
                                                        </Option>
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                    {/* <div style={{width:'119px'}}>
                                        <Input onChange={e=>changeCanAdd('PreTaxPrice', e.target.value.replace(/[^\d.]/, ''))} value={addCanOPtion.PreTaxPrice} prefix={'$'}
                                        style={{width:'90%', height:'30px', borderRadius:'4px'}} placeholder={['请输入']} />
                                    </div> */}
                                    <div style={{width:'119px'}}>
                                        <Input onChange={e=>changeCanAdd('price', e.target.value.replace(/[^\d.]/, ''))} value={addCanOPtion.price} prefix={'$'}
                                        style={{width:'90%', height:'30px', borderRadius:'4px'}} placeholder={intl.get('ReleaseProducts')['请输入']} />
                                    </div>
                                    <div style={{width:'119px'}}>
                                        <Input onChange={e=>changeCanAdd('quantity', e.target.value.replace(/[^\d.]/, ''))} value={addCanOPtion.quantity} suffix={<span style={{fontSize:'13px'}}>件</span>}
                                        style={{width:'90%', height:'30px', borderRadius:'4px'}} placeholder={intl.get('ReleaseProducts')['请输入']} />
                                    </div>
                                    <div style={{width:'86px'}}>
                                        <div onClick={()=>setAddCanOPtion(null)}
                                            style={{width:'100%', color:'#F7385B', cursor:'pointer', paddingLeft:'18px'}}>
                                            <DeleteOutlined style={{fontSize:'16px', marginRight:'6px'}} />
                                            <span>{textJson['删除']}</span>
                                        </div>
                                    </div>
                                </div> :''
                            }
                        </div>
                        {delList.length > 0 && !addCanOPtion ? <div style={{color:'#F7385B', marginTop:'10px'}}>
                            <span onClick={addAttributeOption} style={{cursor:'pointer'}}><PlusCircleFilled style={{marginRight:'7px'}} />{intl.get('ReleaseProducts')['添加选项']}</span>
                        </div>: addCanOPtion ? <div style={{color:'#68C33B', marginTop:'10px'}}>
                            <span onClick={preservation} style={{cursor:'pointer'}}><CheckCircleOutlined style={{marginRight:'7px'}} />{intl.get('ReleaseProducts')['确定添加此项']}</span>
                        </div> : ''}
                    </div> :''}
                </div>

            </div>
        </div>



        <div style={{width:'1182px', height:'60px', background:'#333333', position:'fixed', bottom:'0',
        display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Button onClick={()=>props.navigate(-1)} style={{width:'100px', height:'36px', borderRadius:'4px',
                textAlign:'center', color: '#666666', border:0,
                background: '#FFF', fontSize:'14px' }}
                >
                放弃
            </Button>
            <Button style={{width:'100px', height:'36px', borderRadius:'4px',
                textAlign:'center', color: '#666666', border:0,
                background: '#FFF', fontSize:'14px', margin:'0 15px' }}
                onClick={()=>subPost(3)}>
                {intl.get('ReleaseProducts')['保存为草稿']}
            </Button>
            <Button style={{width:'100px', height:'36px', borderRadius:'4px',
                color: '#FFF', border:0, textAlign:'center',
                background: !isSub()?'#EEE':'linear-gradient(180deg, #F7385B, #FF716E)', fontSize:'14px'}}
                onClick={()=>{
                    if(isSub()){
                        subPost(1)
                    }else{
                        message.error('信息未填写完整！')
                        setIsSubFrist(true)
                    }
                }}>
                {intl.get('ReleaseProducts')['确认发布']}
            </Button>
        </div>

        {isDetailLoding ?<div style={{width:'100%', height:'100vh', position:'fixed', top:0, left:0, zIndex:100,
        display:'flex', alignItems:'center', justifyContent:'center', background:'rgba(255,255,255,0.6)'}}>
            <Spin spinning={true} tip={'加载中...'} />
        </div>:''}


        <Modal centered visible={isSuccess} footer={null} closable={false}
            getContainer={() => document.getElementById('videoDemand')}
            bodyStyle={{
                padding: '0', backgroundColor: '#FFFFFF',
                height:430,
            }}
        >
            <div style={{textAlign:'center', paddingTop:'55px'}}>
                <h3 style={{fontSize:'18px', fontWeight:'bold'}}>{intl.get('ReleaseProducts')['商品发布成功']}</h3>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suc.png'} alt=""
                    style={{width:'173px', marginTop:'40px'}} />
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'38px'}}>
                    <div onClick={()=>setIsSuc(false)} style={{
                        width: '168px', height: '40px', border:'1px solid #F7385B',
                        textAlign: 'center', lineHeight: '40px', color: '#F7385B', borderRadius: '4px',
                        cursor: 'pointer', letterSpacing: '1px', marginRight:'11px'
                    }}>{intl.get('ReleaseProducts')['继续发布商品']}</div>
                    <Button onClick={()=>store.dispatch({ type: 'changeRouter', value: `/MyGoods&&/products` })} style={{
                        width: '168px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                        color: '#FFF', borderRadius: '4px', borderRight:'4px', letterSpacing: '0px', border:0
                    }}>{intl.get('ReleaseProducts')['前往我的店铺商品']}</Button>
                </div>
            </div>
        </Modal>


    </div>
}
export default withRouter(ReleaseProducts);


