import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import '../../../asset/css/BloggerRanking.scss'
import { CaretRightFilled, StarFilled } from '@ant-design/icons';
import { Select, Checkbox, List, notification, Avatar, Skeleton, Divider } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../../utils/redux/index'
import domtoimage from 'dom-to-image';
import intl, { get, init } from 'react-intl-universal';
import * as Api from '../../../service/https'
import toKMB from '../../../utils/KMB'
import moment from 'moment';

const { Option } = Select;

const VideoDetail = (props)=>{
    const [textJson, setTextJson] = useState(intl.get('VideoDetail'))
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [widths, setWidths] = useState(0)
    const [videoDetail, setVideoDetal] = useState()
    const [bloggerDetail, setBloggerDetal] = useState()
    const openNotificationWithIcon = (type, message = '')=> {
        notification[type]({
          description: message, top: 100, duration: 3
        });
    };

    useEffect(()=>{
        // console.log(props)

        getVideoDetail()
        getBloggerDetail()
        loadMoreData()

        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
        }
    },[])


    const getVideoDetail = ()=>{
        Api.getVideoDetail({ videoId: props.params.videoId }).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setVideoDetal(res.data.data[0])
                getIsCollect(res.data.data[0].id)
            }
        })
    }
    const getBloggerDetail = ()=>{
        Api.getBloggerDetail({ bloggerId: props.params.bloggerId }).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setBloggerDetal(res.data.data[0])
                getIsBloggerCollect(res.data.data[0].id)
            }
        })
    }
    const [isCollect,setIsCollect] = useState(false)
    const getIsCollect = (id)=>{
        Api.getIsVideoCollect({ videoUserId: id }).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setIsCollect(res.data)
            }
        })
    }
    const [isBloggerCollect,setIsBloggerCollect] = useState(false)
    const getIsBloggerCollect = (id)=>{
        Api.getIsBloggerCollect({ bloggerUserId: id }).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setIsBloggerCollect(res.data)
            }
        })
    }
    
    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(width)
        let num = width > 1836 ? 3 : 2
        setWidths(num)
    }
    useEffect(()=>{
        heightFun()
    },[state.collapsed])

    const [tabList, setTabList] = useState([
        '全部', '英国', '印度尼西亚','美国' ,'日本', '沙特阿拉伯','德国', '越南' ,'巴西' ,'泰国',
        '俄罗斯联邦', '马来西亚', '菲律宾', '阿联酋', '墨西哥', '沙特阿拉伯'
    ])
    const [tabIndex, setTabIndex] = useState(0)
    const [tabShow, setTabShow] = useState(false)
    const changeTab = (index)=>{
        setTabIndex(index)
    }


    const [isBig, setBig] = useState(false)
    const changeBig = ()=>{
        
    }


    const [items, setItems] = useState(['< 10K',
    '10k ~ 100K',
    '100K ~ 500K',
    '500K ~ 1M',
    '1M ~ 5M',
    '5M ~ 10M',
    '10M ~ 50M',
    '> 50M']);



    const [personal, setPersonal] = useState(false);
    const [enterprise, setEnterprise] = useState(false);
    const [social, setSocial] = useState(false);
    const [link, setLink] = useState(false);
    const [contact, setContact] = useState(false);


    const [sortList, setSortList] = useState(['发布时间', '播放数',
    intl.get('BloggerRanking').LikesNum, '评论数', '分享数']);
    const [sortIndex, setSortIndex] = useState(0);
    const sortMethod = (index) =>{
        setSortIndex(index)
    }





    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const loadMoreData = () => {
        if (loading) {
          return;
        }
        setLoading(true);
        let obj = {
            authorId: props.params.bloggerId,
            bloggerVideoSortRuler: 2,
            from: pageIndex * 10, size: 10
        }
        Api.detailVideoList(obj).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setTotal(res.data.total)
                setData(pageIndex == 0 ? res.data.data : [...data, ...res.data.data]);
                setPageIndex(pageIndex+1)
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    };
    





    // 别表
    const BigDiv = (item, index) =>{
        return <div style={{width:'100%', height:'208px', backgroundColor:'#FFF', margin:'0 auto', 
            marginBottom: '16px', paddingRight:'16px',
            }}
            onClick={()=>detail(item,index)}>

            <div style={{width:'100%', height:'100%', padding:'14px', border:'1px solid #EEEEEE', borderRadius:'4px', 
                display:'flex', cursor:'pointer'}}>
                <div style={{width:'130px', height:'180px', backgroundImage:'url('+item.video.cover+')',
                    backgroundSize:'cover', borderRadius:'4px', backgroundRepeat:'no-repeat',
                    }}>
                    <div style={{width:'100%', height:'100%', background:'rgba(0,0,0,0.2)', color:'#FFF',
                        textAlign:'center', paddingTop:'150px', borderRadius:'4px'}}>
                        <span>{Math.floor(item.video.duration/60)}:{(item.video.duration%60)<10?'0':''}{item.video.duration%60}</span>
                    </div>
                </div>
                <div style={{width:'calc(100% - 130px)', paddingLeft:'13px', paddingTop:'6px',display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <div className='over' style={{fontSize:'16px', fontWeight:'bold', lineHeight:'20px'}}>
                        {item.desc}
                    </div>
                    <div className='over1' style={{color:'#888888', lineHeight:'20px'}}>
                    {
                        item.challenges?.map((item,index)=>{
                            return <span key={index} style={{marginRight:'5px'}}>#{item.title}</span>
                        })
                    }
                    </div>
                    <div style={{width:'100%', color:'#888888', textAlign:'center', display:'flex', alignItems:'center',}}>
                        <div style={{display:'flex', alignItems:'center', marginRight:'15px'}}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/playCircleL.png'} alt="" 
                                style={{width:'14px', marginRight: '4px',}}/> {toKMB(item.playCount,2)}
                        </div>
                        <div style={{display:'flex', alignItems:'center', marginRight:'15px'}}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hanhan.png'} alt="" 
                                style={{width:'14px', marginRight: '4px',}}/> {toKMB(item.commentCount,2)}
                        </div>
                        <div style={{display:'flex', alignItems:'center', marginRight:'15px'}}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pinglun.png'} alt="" 
                                style={{width:'14px', marginRight: '4px',}}/> {toKMB(item.diggCount,2)}
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/fenxiang.png'} alt="" 
                                style={{width:'14px', marginRight: '4px',}}/> {toKMB(item.shareCount,2)}
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <span style={{color:'#888888'}}>{moment(item.createTime*1000).format('YYYY-MM-DD hh:mm:ss')}</span>
                        <div style={{display:'flex'}}>
                            <div onClick={e=>palyVideos(e, item)} style={{width:'30px', height:'30px', fontSize:'22px', border:'1px solid #EEEEEE',
                                textAlign:'center', lineHeight:'26px', borderRadius:'3px', color:'#888888',
                                marginRight:'9px', fontWeight:'blod'}}>
                                <CaretRightFilled />
                            </div>
                            {/* <img onClick={(e)=>addCollection(e, item,index)} 
                                src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc.png'} alt="" /> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    }

    const detail = (item,index)=>{
        // console.log('detail',index)
        window.open(`/index/videoDetail/${item.video.id}/${item.author}`)
    }
    const palyVideo = (e)=>{
        e.stopPropagation();
        // console.log(videoDetail)
        window.open(`https://www.tiktok.com/@${bloggerDetail.nickname}/video/${videoDetail.video?.id}`)
    }
    const palyVideos = (e, item)=>{
        e.stopPropagation();
        window.open(`https://www.tiktok.com/@${item.author}/video/${item.video.id}`)
    }
    const addCollection = (e, item,index)=>{
        e.stopPropagation();
        
        if(isCollect){
            Api.delVideoCollect({videoCollectId	: isCollect.id}).then(res => {
                // console.log(res)
                if (res.code == 200) {
                    openNotificationWithIcon('success', intl.get('Common')['取消成功'])
                    setIsCollect(false)
                    getIsCollect(videoDetail.id)
                }
            })
        }else{
            var obj = videoDetail
            obj.videoId = obj.id
            obj.videoCreateTime = obj.createTime
            obj.videoDesc = obj.desc
            obj.videoDuration = obj.video.duration
            obj.playAddr = obj.video.playAddr
            obj.format = obj.video.format
            obj.cover = obj.video.cover
            obj.title = obj.challenges[0]?.title
            obj.uniqueId = bloggerDetail.uniqueId
            obj.nickname = bloggerDetail.nickname
            obj.avatarMedium = bloggerDetail.avatarMedium
            Api.addVideoCollect(videoDetail).then(res => {
                // console.log(res)
                if (res.code == 200) {
                    openNotificationWithIcon('success', intl.get('Common')['收藏成功'])
                    getIsCollect(videoDetail.id)
                }
            })
        }
    }

    const addBloggerCollect = () => {
        if(isBloggerCollect){
            Api.delBloggerCollect({bloggerCollectId: isBloggerCollect.id}).then(res => {
                // console.log(res)
                if (res.code == 200) {
                    openNotificationWithIcon('success', intl.get('Common')['取消成功'])
                    setIsBloggerCollect(false)
                    getIsBloggerCollect(bloggerDetail.id)
                }
            })
        }else{
            var obj = bloggerDetail
            obj.userId = obj.id
            obj.accountCreateTime = obj.createTime
            obj.bioLink = obj.bioLink.link ? obj.bioLink.link : ''
            obj.ptvRatioSpeculate = obj.ptvRatio_speculate
            Api.addBloggerCollect(bloggerDetail).then(res => {
                // console.log(res)
                if (res.code == 200) {
                    openNotificationWithIcon('success', intl.get('Common')['收藏成功'])
                    getIsBloggerCollect(bloggerDetail.id)
                }
            })
        }
    }




    const [isAll, setIsAll] = useState(false)
    const changeAll = () => {
        setIsAll(!isAll)
    }


    const [isPk, setIsPk] = useState(false)

    const detailBlogger = ()=>{
        window.open(`/index/bloggerDetail/${bloggerDetail.uniqueId}`)
    }




    return videoDetail && bloggerDetail ? <div id="VideoDetail" style={{paddingBottom:'50px'}}>

        <div className='scales' style={{display:'flex'}}>
            <div style={{width:'260px', minWidth:'260px', height:'360px', backgroundImage:'url('+videoDetail.video.cover+')',
                backgroundSize:'cover', backgroundRepeat:'no-repeat', borderRadius:'4px'
            }}></div>
            <div style={{width:'490px', marginLeft:'24px', display:'flex', flexFlow:'column', justifyContent:'space-between'}}>
                <div>
                    <div className='over3' style={{fontSize:'16px', fontWeight:'bold', lineHeight:'20px', marginTop:'15px',
                        }}>{videoDetail.desc}</div>
                    <div className='over' style={{marginTop:'15px'}}>
                    {
                        videoDetail.challenges?.map((item,index)=>{
                            return <span key={index} style={{marginRight:'5px'}}>#{item.title}</span>
                        })
                    }
                    </div>
                </div>
                <div style={{paddingBottom:'15px'}}>
                    <div style={{color:'#888888'}}>{textJson['视频时长']}：{Math.floor(videoDetail.video.duration/60)}:{(videoDetail.video.duration%60)<10?'0':''}{videoDetail.video.duration%60}s</div>
                    <div style={{color:'#888888'}}>{intl.get('BloggerDetail')['发布时间']}：{moment(videoDetail.createTime*1000).format('YYYY.MM.DD hh:mm:ss')}</div>
                    <div style={{borderBottom:'1.5px dashed #bbb', margin:'24px 0' }} />
                    <div>
                        <div style={{width:'160px', height:'38px', background: true?'linear-gradient(180deg, #F7385B, #FF716E)':'#FFF',
                            display:'flex', justifyContent:'center', alignItems:'center', color: true?'#FFF':'#888888', fontSize:'12px',
                            borderRadius:'4px', cursor:'pointer', border:true?0:'1px solid #EEEEEE'}}
                            onClick={(e)=>palyVideo(e)}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/bofang.png'} alt="" 
                                style={{width:'11px', marginRight: '4px',}}/>{textJson['播放视频']}
                        </div>
                        <div style={{width:'160px', height:'38px', background: isCollect?'linear-gradient(180deg, #F7385B, #FF716E)':'#FFF',
                            display:'flex', justifyContent:'center', alignItems:'center', color: isCollect?'#FFF':'#888888', fontSize:'12px',
                            borderRadius:'4px', cursor:'pointer', border: isCollect?0:'1px solid #EEEEEE', marginTop:'10px'}}
                            onClick={(e)=>addCollection(e)}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/shoucang'+(isCollect?'':'s')+'.png'} alt="" 
                                style={{width:'14px', marginRight: '4px',}}/>{isCollect?intl.get('BloggerDetail')['取消收藏']:textJson['收藏视频']}
                        </div>
                    </div>
                </div>
            </div>

            <div className='numPadding' style={{width:'383px', marginLeft:'40px' }}>
                <div style={{display:'flex', justifyContent:'center', marginRight:'15px', padding:'0 30px',
                    width:'100%', height:'78px', backgroundColor:'#FFF', flexFlow:'column', borderRadius:'4px',
                    marginBottom:'9px'}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', 
                        fontSize:'16px', fontWeight:'bold'}}>
                        <div style={{display:'flex', alignItems:'center', marginRight:'15px' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/playCircleL.png'} alt="" 
                                style={{width:'18px', marginRight: '4px',}}/> 
                            <span style={{fontSize:'16px'}}>{textJson['该视频播放数']}</span>
                        </div>
                        <span>{toKMB(videoDetail.playCount,2)}</span>
                    </div>
                    <div style={{color:'#888888', marginTop:'6px'}}>{textJson['博主的平均播放数']}={toKMB(bloggerDetail.ptvRatio_speculate,2)}</div>
                </div>
                <div style={{display:'flex', justifyContent:'center', marginRight:'15px', padding:'0 30px',
                    width:'100%', height:'78px', backgroundColor:'#FFF', flexFlow:'column', borderRadius:'4px',
                    marginBottom:'9px'}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', 
                        fontSize:'16px', fontWeight:'bold'}}>
                        <div style={{display:'flex', alignItems:'center', marginRight:'15px' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hanhan.png'} alt="" 
                                style={{width:'18px', marginRight: '4px',}}/> 
                            <span style={{fontSize:'16px'}}>{textJson['该视频点赞数']}</span>
                        </div>
                        <span>{toKMB(videoDetail.diggCount,2)}</span>
                    </div>
                    <div style={{color:'#888888', marginTop:'6px'}}>{textJson['博主的平均点赞数']}={toKMB(bloggerDetail.htvRatio,2)}</div>
                </div>
                <div style={{display:'flex', justifyContent:'center', marginRight:'15px', padding:'0 30px',
                    width:'100%', height:'78px', backgroundColor:'#FFF', flexFlow:'column', borderRadius:'4px',
                    marginBottom:'9px'}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', 
                        fontSize:'16px', fontWeight:'bold'}}>
                        <div style={{display:'flex', alignItems:'center', marginRight:'15px' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pinglun.png'} alt="" 
                                style={{width:'18px', marginRight: '4px',}}/> 
                            <span style={{fontSize:'16px'}}>{textJson['该视频评论数']}</span>
                        </div>
                        <span>{toKMB(videoDetail.commentCount,2)}</span>
                    </div>
                    <div style={{color:'#888888', marginTop:'6px'}}>{textJson['博主的平均评论数']}={toKMB(bloggerDetail.commentCount,2)}</div>
                </div>
                <div style={{display:'flex', justifyContent:'center', marginRight:'15px', padding:'0 30px',
                    width:'100%', height:'78px', backgroundColor:'#FFF', flexFlow:'column', borderRadius:'4px'}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', 
                        fontSize:'16px', fontWeight:'bold'}}>
                        <div style={{display:'flex', alignItems:'center', marginRight:'15px' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/fenxiang.png'} alt="" 
                                style={{width:'18px', marginRight: '4px',}}/> 
                            <span style={{fontSize:'16px'}}>{textJson['该视频分享数']}</span>
                        </div>
                        <span>{toKMB(videoDetail.shareCount,2)}</span>
                    </div>
                    <div style={{color:'#888888', marginTop:'6px'}}>{textJson['博主的平均分享数']}={toKMB(bloggerDetail.shareCount,2)}</div>
                </div>
            </div>
            <div className='numPaddings' style={{width:'383px', marginLeft:'20px' }}>
                <div style={{display:'flex', justifyContent:'center', marginRight:'15px', padding:'0 30px',
                    width:'100%', height:'78px', backgroundColor:'#FFF', flexFlow:'column', borderRadius:'4px',
                    marginBottom:'9px'}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', 
                        fontSize:'16px', fontWeight:'bold'}}>
                        <div style={{display:'flex', alignItems:'center', marginRight:'15px' }}>
                            <span style={{fontSize:'16px'}}>{textJson['该视频点赞数/播放数']}</span>
                        </div>
                        <span>{(videoDetail.diggCount/videoDetail.playCount*100).toFixed(2)}%</span>
                    </div>
                    <div style={{color:'#888888', marginTop:'6px'}}>{textJson['博主的总点赞数/总播放数']}={bloggerDetail.playCountSum?(bloggerDetail.heartCount/bloggerDetail.playCountSum*100).toFixed(2)+'%':''}</div>
                </div>
                <div style={{display:'flex', justifyContent:'center', marginRight:'15px', padding:'0 30px',
                    width:'100%', height:'78px', backgroundColor:'#FFF', flexFlow:'column', borderRadius:'4px',
                    marginBottom:'9px'}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', 
                        fontSize:'16px', fontWeight:'bold'}}>
                        <div style={{display:'flex', alignItems:'center', marginRight:'15px' }}>
                            <span style={{fontSize:'16px'}}>{textJson['该视频评论数/播放数']}</span>
                        </div>
                        <span>{(videoDetail.commentCount/videoDetail.playCount*100).toFixed(2)}%</span>
                    </div>
                    <div style={{color:'#888888', marginTop:'6px'}}>{textJson['博主的总评论数/总播放数']}={bloggerDetail.playCountSum?(bloggerDetail.commentCountSum/bloggerDetail.playCountSum*100).toFixed(2)+'%':''}</div>
                </div>
                <div style={{display:'flex', justifyContent:'center', marginRight:'15px', padding:'0 30px',
                    width:'100%', height:'78px', backgroundColor:'#FFF', flexFlow:'column', borderRadius:'4px',
                    marginBottom:'9px'}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', 
                        fontSize:'16px', fontWeight:'bold'}}>
                        <div style={{display:'flex', alignItems:'center', marginRight:'15px' }}>
                            <span style={{fontSize:'16px'}}>{textJson['该视频分享数/播放数']}</span>
                        </div>
                        <span>{(videoDetail.shareCount/videoDetail.playCount*100).toFixed(2)}%</span>
                    </div>
                    <div style={{color:'#888888', marginTop:'6px'}}>{textJson['博主的总分享数/总播放数']}={bloggerDetail.playCountSum?(bloggerDetail.shareCountSum/bloggerDetail.playCountSum*100).toFixed(2)+'%':''}</div>
                </div>
            </div>
        </div>

        <div style={{marginTop:'20px', backgroundColor:'#FFF', paddingTop:'30px'}}>
            <div style={{display:'flex', alignItems:'center', marginLeft:'24px',
                fontSize:'16px', fontWeight:'bold'}}>
                <div style={{display:'inline-block', width:'4px', height:'20px', backgroundColor:'#F7385B',
                    marginRight:'9px'}}></div> 
                {textJson['博主信息']}
            </div>                
            <div style={{width:'100%', padding:'10px 30px 25px 38px', fontWeight:500, 
                display:'flex', alignItems:'center',justifyContent:'space-between'}}>
                <div style={{width:'300px', minWidth:'270px', display:'flex', alignItems:'center', cursor:'pointer'}}
                    onClick={()=>detailBlogger()}>
                    <img src={bloggerDetail.avatarMedium} alt="" 
                        style={{width:'70px', height:'70px', borderRadius:'50px', marginRight:'16px'}}/>
                    <div style={{color:'#888888'}}>
                        <div style={{color:'#333333', fontWeight:'bold', fontSize:'16px',
                            display:'flex', alignItems:'center'}}>
                            <div className='over1' style={{height:'25px', fontWeight:'bold', fontSize:'16px', lineHeight:'25px'}}>{bloggerDetail.nickname}</div>
                            {bloggerDetail.verified?<img style={{marginLeft:'3px', width:'14px', height:'14px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} />:''}
                        </div>
                        TikTok: {bloggerDetail.uniqueId}
                    </div>
                </div>
                <div style={{width:'1px', height:'70px', backgroundColor:'#EEEEEE'}}></div>
                <div style={{width:'100%', height:'114px', cursor:'pointer', 
                    textAlign:'center', display:'flex', justifyContent:'space-around', alignItems:'center',
                    padding:'0 35px' }}
                    onClick={()=>detailBlogger()}>
                    <div>
                        <div style={{fontSize:'16px', fontWeight:'bold'}}>{toKMB(bloggerDetail.followerCount,2)}</div>
                        <div style={{color:'#888888', fontWeight:400}}>
                            {intl.get('BloggerRanking').FansNum}
                        </div>
                    </div>
                    <div>
                        <div style={{fontSize:'16px', fontWeight:'bold'}}>{toKMB(bloggerDetail.videoCount,2)}</div>
                        <div style={{color:'#888888', fontWeight:400}}>
                            {intl.get('BloggerRanking').NumberVideos}
                        </div>
                    </div>
                    <div>
                        <div style={{fontSize:'16px', fontWeight:'bold'}}>{toKMB(bloggerDetail.ptvRatio_speculate,2)}</div>
                        <div style={{color:'#888888', fontWeight:400}}>
                            {intl.get('BloggerRanking').AveragePlayback}
                        </div>
                    </div>
                    <div>
                        <div style={{fontSize:'16px', fontWeight:'bold'}}>{toKMB(bloggerDetail.heartCount,2)}</div>
                        <div style={{color:'#888888', fontWeight:400}}>
                            {intl.get('BloggerRanking').LikesNum}
                        </div>
                    </div>
                    <div>
                        <div style={{fontSize:'16px', fontWeight:'bold'}}>{toKMB(bloggerDetail.htfRatio,2)}</div>
                        <div style={{color:'#888888', fontWeight:400}}>
                            {intl.get('BloggerRanking').AveragePpowderRatio}
                        </div>
                    </div>
                </div>
                <div style={{width:'1px', height:'70px', backgroundColor:'#EEEEEE'}}></div>
                <div className='detailDC' style={{display:'flex', marginLeft:'70px'}}>
                    <div style={{width:'160px', height:'38px', background: false?'linear-gradient(180deg, #F7385B, #FF716E)':'',
                        display:'flex', justifyContent:'center', alignItems:'center', color: false?'#FFF':'#888888', fontSize:'12px',
                        borderRadius:'4px', cursor:'pointer', border:false?0:'1px solid #EEEEEE'}}
                        onClick={()=>bloggerDetail.bioLink.link?window.open(bloggerDetail.bioLink.link):''}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/zhuye.png'} alt="" 
                            style={{width:'14px', marginRight: '4px',}}/>{textJson['查看主页']}
                    </div>
                    <div style={{width:'160px', height:'38px', background: isBloggerCollect?'linear-gradient(180deg, #F7385B, #FF716E)':'',
                        display:'flex', justifyContent:'center', alignItems:'center', color: isBloggerCollect?'#FFF':'#888888', fontSize:'12px',
                        borderRadius:'4px', cursor:'pointer', border: isBloggerCollect?0:'1px solid #EEEEEE', marginLeft:'23px'}}
                        onClick={(e)=>addBloggerCollect(e)}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/shoucang'+(isBloggerCollect?'':'s')+'.png'} alt="" 
                            style={{width:'14px', marginRight: '4px',}}/>{isBloggerCollect ? intl.get('BloggerDetail')['取消收藏']:textJson['收藏视频']}
                    </div>
                </div>
            </div>
        </div>

        {/* 列表 */}
        <div style={{width:'100%', backgroundColor: '#FFFFFF', padding: '36px 24px 18px 38px',
            boxShadow: '0px 0px 15px 15px #F6F6F6', marginTop:'-10px'}}>
            <div style={{display:'flex', alignItems:'center', marginBottom:'30px', marginLeft:'-13px',
                fontSize:'16px', fontWeight:'bold'}}>
                <div style={{display:'inline-block', width:'4px', height:'20px', backgroundColor:'#F7385B',
                    marginRight:'9px'}}></div> 
                {textJson['博主最热视频']}
            </div>
            <InfiniteScroll
                dataLength={data.length}
                next={loadMoreData}
                hasMore={data.length < total}
                loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                endMessage={<Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                    dataSource={data} grid={{ gutter: 0, column: widths}}
                    renderItem={(item, index) => <div style={{}}>
                        {BigDiv(item, index)}
                    </div>}
                />
            </InfiniteScroll>
            
        </div>


    </div> : ''
                        
}
export default withRouter(VideoDetail);