import React, { useState, useEffect, useRef } from 'react'
import { NavLink, Outlet } from "react-router-dom";
import Header from '../../components/Header'
import withRouter from '../../utils/withRouter'
import { Image, Input, Checkbox, Select, Button, notification, Form, Anchor } from 'antd';
import Slider from "react-slick";
import moment from 'moment';
import intl, { init } from 'react-intl-universal';
import * as Api from '../../service/https'
import store from '../../utils/redux/index'

const { Link } = Anchor;
const { Option } = Select;

const Drainage = (props)=>{
    const [textJson, setTextJson] = useState(intl.get('Index'))
    const intervalRef = useRef(null);
    const videos = useRef(null);
    const imgUrl = 'https://zc-tk.oss-cn-beijing.aliyuncs.com/front'
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    useEffect(()=>{
        // document.title = 'BrandGoGo-红人营销|短视频创作|内容电商 一站式解决方案平台'
        // console.log(props)
        // changeTime(0)
        getRequirement()
        return () => {
            if(intervalRef) clearInterval(intervalRef?.current)
        }
    },[])

    const [shiIndex, setShiIndex] = useState(-1)
    const [demandListIndex, setDemandListIndex] = useState(-1)
    const [demandList, setDemandList] = useState([])
    const getRequirement = ()=>{
        Api.getRequirement().then(res=>{
            if(res.code == 200){
                setDemandList(res.data)
            }
        })
    }

    const [tabListIndex, setTabIndex] = useState(-1)
    const tabList = [
        {title: textJson['海量TK红人资源'], tip:textJson['智能匹配优质海外红人']},
        {title: textJson['热门短视频'], tip:textJson['发现TK热门爆款内容趋势']},
        {title:textJson['短视频创作与营销'], tip:textJson['品牌商家短视频创作撮合与推广']},
        {title:textJson['海外直播带货'], tip:textJson['海外直播代播，享受出海红利']},
    ]
    const [highlightsIndex, sethighlightsIndex] = useState(0)
    const highlightsList = [
        {title: 'AI数据分析', tip: '通过大数据智能筛选、推荐，品牌商&红人可以更高效、精准地相互匹配！'},
        {title: '平台权益', tip: '红人库内现有红人已突破300万，品牌商可以自定义免费发布短视频&直播创作需求！'},
        {title: '带货权益', tip: 'TikTok开店指导、没有三方平台高昂流量成本，短视频&直播带货按效果返佣，不花冤枉钱！'},
        {title: '运营权益 ', tip: 'TikTok账户搭建、原创、混剪视频任你选，无坑位费直播场免费拿！'},
        {title: '增值权益 ', tip: '1v1商家服务、直播辅导、商业变现辅导、国际物流和海外仓对接服务、Tiktok实操培训和运营指导！'},
    ]
    const changeTime = (index)=>{
        intervalRef.current.slickGoTo(index)
        sethighlightsIndex(index)
    }
    const [painPoint, setPainPoint] = useState(false)

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        beforeChange: function(currentSlide, nextSlide) {
            //   console.log("before change", currentSlide, nextSlide);
            sethighlightsIndex(nextSlide)
        },
        afterChange: function(currentSlide) {
            //   console.log("after change", currentSlide);
        }
    };


    const [hotIndex, setHotIndex] = useState(0)
    const [hotItemIndex, setHotItemIndex] = useState(0)
    useEffect(()=>{
        setHotItemIndex(0)
    },[hotIndex])
    const hotList = [
        {title: intl.get('Workbench').BloggerRanking, list: [
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/tos-useast5-avt-0068-tx/5919c31d26416a1a795bb264549a3e14~c5_720x720.jpeg.jpg', name:'charli d’amelio', tikId: 'charlidamelio', fans: '140.40M', videos: '2.17K', dig: '10.90B'},
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/tos-maliva-avt-0068/30107337091e90a33da10d6ce1815bb1~c5_720x720.jpeg.jpg', name:'Khabane lame', tikId: 'khaby.lame', fans: '137.40M', videos: '1.05K', dig: '2.20B'},
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/tos-maliva-avt-0068/86fdd9ca882015440001a66fe397a572~c5_720x720.jpeg.jpg', name:'Bella Poarch', tikId: 'bellapoarch', fans: '89.50M', videos: 465, dig: '2.10B'},
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/tos-useast5-avt-0068-tx/96b163655816acaca78b0d70a6de7939~c5_720x720.jpeg.jpg', name:'Addison Rae', tikId: 'addisonre', fans: '87.50M', videos: '1.66K', dig: '1.40B'},
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/musically-maliva-obj/1646315618666501~c5_720x720.jpeg.jpg', name:'Will Smith', tikId: 'willsmith', fans: '71.80M', videos: 136, dig: '487.60M'},
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/aweme/720x720/tos-alisg-avt-0068/smgf5f369c884044a8df770614bbfd64717.jpeg.jpg', name:'Zach King', tikId: 'zachking', fans: '68.40M', videos: 334, dig: '867.90M'},
        ]},
        {title: intl.get('Workbench').VideoLeaderboard, list: [
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/obj/tos-alisg-p-0037/7294cf8bc1d6487f852a1dfc248eed7b_1652015600.jpg', name:"I'm trying new things❤ Lisa💖 ib@_taekookiey_ #blink #blackpink #bp #blackpink_blinke #lisa #lalisamanoban #la", palys: '6.29K', dig: '1.17K', comments: 44, time:'0:26' },
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/obj/tos-maliva-p-0068/17276996b934456f8622eec495c02740.jpg', name:'😳This is one of my best drawings #dlaciebie #tiktokart #drawing #fyp', palys: '277.70M', dig: '50.00M', comments: '834.90K', time:'0:31' },
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/obj/tos-maliva-p-0068/a796b541712c44fb987c119250cb7db1.jpg', name:'Ig:@khaby00', palys: '275.10M', dig: '37.80M', comments: '408.60K', time:'0:25' },
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/obj/tos-maliva-p-0068/0f1b16b79efa43a8993872a50415a715_1625066209.jpg', name:'Cm on bro 🤲🏾🤲🏾🤲🏾🤲🏾🤲🏾!! #learnfromkhaby #learnwithtiktok #imparacontiktok #khabylame', palys: '350.00M', dig: '36.10M', comments: '338.70K', time:'0:17' },
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/obj/tos-maliva-p-0068/abe2b9fc9e7b459a96ac5ce857e8d81d_1623247635.jpg', name:'Lol 🤦🏿‍♂️ #learnfromkhaby #LearnWithTikTok #ImparaConTikTok', palys: '297.70M', dig: '35.40M', comments: '220.30K', time:'0:26' },
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/tos-maliva-p-0068/aabf9583b2d54de088dcd6daafce35a2~tplv-dmt-logom:tos-maliva-p-0000/aa3948f3b5594186a782b3ab1301c48b.image.jpg', name:'Spirited Away', palys: '292.90M', dig: '34.90M', comments: '230.30K', time:'0:25' },
        ]},
        {title: intl.get('Workbench').TopicRanking, list: [
            {logo: '', name:'foryou', upDate:'2022-05-05 09:05:15', videos: '1.20B', palys: '15196.80B', dig: '30.75K' },
            {logo: '', name:'foryoupage', upDate:'2022-04-27 04:53:45', videos: '897.20M', palys: '9623.00B', dig: '20.08K' },
            {logo: '', name:'fypシ', upDate:'2022-04-24 06:00:59', videos: '592.90M', palys: '7358.00B', dig: '9.68K' },
            {logo: '', name:'parati', upDate:'2022-05-05 09:39:51', videos: '342.90M', palys: '3537.60B', dig: '27.62K' },
            {logo: '', name:'tiktok', upDate:'2022.03.22 18:19:30', videos: '330.90M', palys: '2950.70B', dig: '11.54K'},
            {logo: '', name:'fy', upDate:'2022-04-26 01:17:39', videos: '216.20M', palys: '2899.40B', dig: '23.33K' },
        ]},
    ]


    const [tikAllIndex, setTikALlIndex] = useState(-1)
    const tikALlList = [
        {title: textJson['TikTok网红收录'], tip:textJson['强大的数据采集能力，覆盖图文、视频、直播等有效数据反馈及实时存储与查询']},
        {title: textJson['网红数据对比分析'], tip:textJson['10万+博主收录，一键对比查看各维度数据分析，高效筛选你心仪的带货达人']},
        {title: textJson['视频话题内容趋势'], tip:textJson['针对流量红利视频和话题进行深度挖掘梳理，形成多维透视体系，同步进行排序']},
        {title: textJson['创意短视频营销'], tip:textJson['针对商家提出的需求，博主基于流量历史趋势、 营销模型、品牌渗透力等进行短视频创作']},
        {title: textJson['直播带货助力推广'], tip:textJson['基于在库品牌和商品，博主利用直播高效转化私域流量，助力品牌商品内容推广']},
        {title: textJson['1对1专属服务'], tip:textJson['为每一位商家或博主配置专属客服，进行精准的服务，针对给出可落地的解决方案']},
    ]



    const [majorIndex, setMajorIndex] = useState(0)
    const majorList = [
        {title: textJson['电子数码'], imgB: imgUrl+'/img/homeImg/dianzishumas.jpg',
            video: imgUrl+'/img/videos/dianzishuma.mp4'},
        {title: textJson['游戏电竞'], imgB: imgUrl+'/img/homeImg/youxidianjins.jpg',
            video: imgUrl+'/img/videos/youxidianjin.mp4'},
        {title: textJson['时尚生活'], imgB: imgUrl+'/img/homeImg/shishangshenhuos.jpg',
            video: imgUrl+'/img/videos/shishangshenhuo.mp4'},
        {title: textJson['美妆首饰'], imgB: imgUrl+'/img/homeImg/meizhuangshoushis.jpg',
            video: imgUrl+'/img/videos/meizhuangshoushi.mp4'},
        {title: textJson['服装服饰'], imgB: imgUrl+'/img/homeImg/fuzhuangfushis.jpg',
            video: imgUrl+'/img/videos/fuzhuangfushi.mp4'},
        {title: textJson['运动健康'], imgB: imgUrl+'/img/homeImg/yundongjiankangs.jpg',
            video: imgUrl+'/img/videos/yundongjiankang.mp4'},
    ]


    const gotoIndex = (index)=>{
        if (localStorage.getItem('token') != 'null' && localStorage.getItem('token') != null) {
            if(index==0)props.navigate('/index/bloggerRanking', {state: '/rangKing'})
            if(index==1)props.navigate('/index/videoRanking', {state: '/rangKing'})
            if(index==2)props.navigate('/index/topicList', {state: '/rangKing'})
        }else{
            props.navigate('/login')
        }
    }



    const [isPlay, setIsPlay] = useState(false)
    const onChange = (link) => {
        // console.log('Anchor:OnChange', link);
        if(link && link!='') {
            setIsPlay(true)
        }else{
            setIsPlay(false)
        }
        // setMajorIndex(majorIndex)
    };


    const [information, setInfor] = useState({
        "authType": 2,
        "phone": "",
        "userType": "Business",
        "verifyCode": "",
        "name":"",
        "enterpriseName":"",
        "position": "",
        "requirementId":"",
        "registerType":1
    })
    const handleChange = (value, e)=>{
        setInforM('requirementId', value==undefined? '' : e.id)
    }

    const setInforM = (key, val)=>{
        let obj = JSON.parse(JSON.stringify(information))
        obj[key] = val
        setInfor(obj)
    }



    const openNotificationWithIcon = (type, message = '')=> {
        notification[type]({
          description: message, top: 100, duration: 3
        });
    };

    const [isCode, getCodes] = useState(true)
    const [time, setTime] = useState(60)
    // 获取验证码
    const getCode = ()=>{
        if(isCode && information.phone!=''){
            getCodes(false)
            setTime(60)
            let times = 60
            Api.getCode(information.phone).then((res)=>{
                // console.log(res.data)
                if(res.code == 200){
                    openNotificationWithIcon('success', '发送成功！')
                    let downTime = setInterval(()=>{
                        if(times == 0){
                            getCodes(true)
                            clearInterval(intervalRef.current)
                        }
                        setTime(times --)
                    },1000)
                    intervalRef.current = downTime
                }
            })
        }
    }


    // 登录/注册
    const postLogin = ()=>{
        if(isSub()){
            Api.login(information).then((res)=>{
                // console.log(res.data)
                if(res.code == 200){
                    openNotificationWithIcon('success', '入驻成功！')
                    localStorage.setItem("token", res.data)
                    store.dispatch({ type: 'token', value: res.data })
                    props.navigate('/index/bloggerRanking', {state: '/rangKing'})
                }
            })
        }
    }

    const isSub = ()=>{
        return information.phone!=''&&information.enterpriseName!=''&&information.verifyCode!=''&&information.requirementId!=''
            && information.name!=''
    }




    return <div style={{minWidth:'1420px', letterSpacing:'1px', paddingTop:'66px'}}>

        <div style={{width:'100%', height:'66px', letterSpacing:'0.5px', userSelect:'none', backgroundColor: '#333333', boxShadow: '0px 0px 10px 0px #F8F8F8',
            position: 'fixed', zIndex: 2, top: 0, left: 0, color:'#FFF', }}>

            <div style={{
                minWidth:'1280px', width: '1280px', height: '66px', display: 'flex', alignItems: 'center', margin:'0 auto',
                justifyContent:'space-between', padding:'0 10px'
            }}>
                <div style={{ width: '190px', textAlign:'center'}}>
                    <img style={{width:'100%'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logo.png'} alt="" />
                </div>
                <div onClick={()=>props.navigate('/login')} style={{width:'118px', height:'34px',
                    background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                    borderRadius:'17px', color: '#FFFFFF', textAlign:'center', lineHeight:'34px', cursor:'pointer'}}>
                    免费注册
                </div>

            </div>


        </div>


        <div id='home'>

            <nav id='xuqiu' style={{width:'100%', position:'relative'}}>
                <img style={{width:'100%'}} src={imgUrl+'/img/drainage/back.png'} />
                {/* <img style={{width:'100%', height:'510px'}} src={imgUrl+'/img/drainage/back.png'} /> */}
                <img style={{width:'614px', position:'absolute', top:'30%', left: 'calc((100% - 1280px) / 2)'}} src={imgUrl+'/img/drainage/backT.png'} />
                <div id='login' className='DrainageNav' style={{width:'614px', position:'absolute', top:'18%', right: 'calc((100% - 1280px) / 2)'}}>
                    <h2 style={{fontSize:'24px', fontWeight:800}}>立即填写入驻，免费发布需求</h2>
                    <Input onChange={e=>setInforM('enterpriseName', e.target.value)} value={information.enterpriseName} style={{width:'334px', height:'40px', borderRadius:'4px'}} placeholder={['请输入公司名称']} />
                    <Input onChange={e=>setInforM('name', e.target.value)} value={information.name} style={{width:'334px', height:'40px', borderRadius:'4px', marginTop:'8px'}} placeholder={['请输入您的姓名']} />
                    <Select
                        style={{ width: '334px', height:'40px', border:'1px solid #d9d9d9', background:'#FFF', textAlign:'left', borderRadius:'4px', marginTop:'8px' }}
                        placeholder={<span style={{fontSize:'14px'}}>请选择您的需求</span>} allowClear={true}
                        onChange={handleChange} value={information.requirementId || undefined}
                        getPopupContainer={() => document.getElementById('xuqiu')}
                    >
                        {
                            demandList?.map((item,index)=>{
                                return <Option key={index} id={item.id} value={item.id}>{item.requirementName}</Option>
                            })
                        }
                    </Select>
                    <Input onChange={e=>setInforM('phone', e.target.value)} value={information.phone} style={{width:'334px', height:'40px', borderRadius:'4px', marginTop:'8px'}} placeholder={['请输入您的手机号']} />
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'334px', height:'40px', marginTop:'8px'}}>
                        <Input onChange={e=>setInforM('verifyCode', e.target.value)} value={information.verifyCode} style={{width:'212px', height:'40px', borderRadius:'4px'}} placeholder={['请输入验证码']} />
                        <div onClick={getCode} style={{width:'116px', height:'38px',
                            background: isCode?'linear-gradient(180deg, #F7385B, #FF716E)':'',
                            borderRadius:'4px', color: isCode?'#FFF':'#F7385B', textAlign:'center', lineHeight:'38px', cursor:'pointer'}}>
                            {isCode?intl.get('Login').SendVerificationCode: time+'s后可重新发送'}
                        </div>
                    </div>
                    <Button disabled={!isSub()} onClick={()=>postLogin()} style={{width:'334px', height:'40px',
                        background: 'linear-gradient(180deg, #F7385B, #FF716E)', marginTop:'16px', border:0,
                        borderRadius:'4px', color: '#FFFFFF', textAlign:'center',}}>
                        立即入驻使用
                    </Button>
                </div>
            </nav>

            <div style={{width:'1280px', margin:'0 auto', marginTop:'-30px', display:'flex', justifyContent:'space-between'}}
            onMouseLeave={()=>setTabIndex(-1)}>
                {
                    tabList?.map((item,index)=>{
                        return <div key={index} onMouseEnter={()=>setTabIndex(index)}
                            style={{width:'310px', height:'90px', backgroundColor:'#FFF', borderRadius:'8px', transition:'0.01s top', cursor:'pointer',
                            display:'flex', boxShadow: '0px 4px 10px 0px #EEEEEE', position:'relative', top: tabListIndex==index?'-10px':0}}>
                            <div style={{width:'100px', height:'100%', backgroundColor:'#EEEEEE', borderTopLeftRadius:'8px',
                                borderBottomLeftRadius:'8px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <img src={imgUrl+'/img/homeImg/bozhuguanli'+index+'.png'}
                                    style={{width:'49px'}}/>
                            </div>
                            <div style={{paddingLeft:'18px', display:'flex', flexFlow:'column', justifyContent:'center'}}>
                                <div style={{fontWeight:700, fontSize:'17px', color:'#333', lineHeight:'18px'}}>{item.title}</div>
                                <div style={{fontWeight:400, fontSize:'12px', color:'#666', lineHeight:'15px'}}>{item.tip}</div>
                            </div>
                        </div>
                    })
                }
            </div>


            <div style={{textAlign:'center', marginTop:'90px'}}>
                <div style={{fontSize:'40px', color:'#F2F2F2', fontWeight:600, letterSpacing:'4px'}}>DIFFICULT POINT</div>
                <div style={{fontSize:'36px', color:'#333', fontWeight:600, marginTop:'-40px', letterSpacing:'6px'}}>{textJson['行业痛点']}</div>
                <div style={{width:'72px', height:'4px', backgroundColor:'#F7385B', margin:'0 auto', marginTop:'5px'}}></div>
            </div>


            <div style={{width:'100%', height:'460px', marginTop:'134px', position:'relative',
                backgroundImage:'url('+imgUrl+'/img/homeImg/tongdian.png'+')', backgroundSize:'cover'}}>
                <div style={{width:'1280px', height:'345px', display: 'flex', justifyContent:'space-between', color:'#FFF',
                    position:'relative', top:'-91px', margin:'0 auto', overflow:'hidden', borderBottomLeftRadius:'10px', borderTopLeftRadius:'10px'}}>
                    <div style={{width: '940px', height:'100%', position:'relative', cursor:'pointer', transition:'0.5s all',
                        backgroundImage:'url('+imgUrl+'/img/homeImg/lefts.png'+')', backgroundSize:'cover', marginLeft:painPoint?'0':'-472px',
                        backgroundRepeat:'no-repeat'}}
                        onMouseEnter={()=>setPainPoint(true)}>
                        <div style={{width:'940px', height:'100%',marginLeft:painPoint?'0':'472px',transition:'0.5s all',
                            }}>
                            <div style={{fontSize:'40px', fontWeight:800, paddingLeft:'117px', paddingTop:'100px', letterSpacing:'4px'}}>{textJson['品牌商家']}</div>
                            {painPoint?<div style={{width:'80%', fontSize:'18px', paddingLeft:'117px'}}>{textJson['TikTok专业人才稀缺，团队搭建成本高，网络环境设置复杂']}； <br/>{textJson['跨国文化价值观差异大，团队人员流动性大，跨境三方平台流']}</div>:''}
                        </div>
                    </div>
                    <div style={{width: !painPoint?'940px':'468px', height:'100%', position:'relative', cursor:'pointer', transition:'0.5s all',
                        backgroundImage:'url('+imgUrl+'/img/homeImg/rights.png'+')', backgroundSize:'cover',
                        backgroundRepeat:'no-repeat', marginLeft:'-130px', borderTopRightRadius:'10px', borderBottomRightRadius:'10px'}}
                        onMouseEnter={()=>setPainPoint(false)}>
                        <div style={{width:!painPoint?'1040px':'468px', height:'100%', }}>
                            <div style={{fontSize:'40px', fontWeight:800, paddingLeft:'200px', paddingTop:'100px', letterSpacing:'4px'}}>{textJson['海外红人']}</div>
                            {!painPoint?<div style={{width:'80%', fontSize:'18px', paddingLeft:'200px'}}>{textJson['缺乏产品，缺乏供应链支撑；没有团队，只能单打独斗']}；<br/>{textJson['缺乏变现路径，没有数据，缺乏决策依据，有粉丝，流量无法变现']}</div>:''}
                        </div>
                    </div>
                </div>
                <div style={{width:'840px', textAlign:'center', color:'#FFF', fontSize:'20px', fontWeight:'500',
                    margin:'0 auto', marginTop:'-30px', letterSpacing:'2px'}}>{textJson['BrandGoGo旨在全球短视频经济时代到来之际，专注在短视频内容创作&海外直播电商领域，链接全球优质短视频创作者，帮助中国品牌货通全球！']}</div>
            </div>



            <div style={{width:'100%', paddingBottom:'100px',
                backgroundImage:'url('+imgUrl+'/img/homeImg/lighB.png'+')', backgroundSize:'100%'}}>
                <div style={{textAlign:'center', marginTop:'90px'}}>
                    <div style={{fontSize:'40px', color:'#F2F2F2', fontWeight:600, letterSpacing:'6px'}}>AGENT OPERATION SERVICE</div>
                    <div style={{fontSize:'36px', color:'#333', fontWeight:600, marginTop:'-40px', letterSpacing:lang=='en'?'0px':'4px'}}>代运营服务汇总</div>
                    <div style={{width:'72px', height:'4px', backgroundColor:'#F7385B', margin:'0 auto', marginTop:'5px'}}></div>
                </div>

                <div style={{width:'1280px', margin:'0 auto', paddingTop:'95px', display:'flex', justifyContent:'space-between',
                    }}>
                    <div style={{width:0, height:0, overflow:'hidden'}}>
                        <Slider {...settings} ref={slider => (intervalRef.current = slider)}>
                            {
                                highlightsList?.map((item,index)=>{
                                    return <div key={index}>

                                    </div>
                                })
                            }
                        </Slider>
                    </div>
                    {
                        highlightsList?.map((item,index)=>{
                            return <div key={index} onMouseEnter={()=>changeTime(index)}
                                style={{width:'calc((100% - 20px) / 5)', height:'80px', display:'flex', cursor:'pointer',
                                alignItems:'center', justifyContent:'center', borderBottomLeftRadius: index== 0 ?'40px':'',
                                borderTopLeftRadius: index== 0 ?'40px':'',borderTopRightRadius: index== highlightsList.length-1 ?'40px':'',
                                borderBottomRightRadius: index== highlightsList.length-1 ?'40px':'',
                                background: index==highlightsIndex?'linear-gradient(180deg, #F7395B, #FF706E)':'rgb(238,238,238)'}}>
                                <div style={{fontSize:'24px', fontWeight:'bold', color:index==highlightsIndex?'#FFF':'#333333'}}>{item.title}</div>
                            </div>
                        })
                    }
                </div>
                <div style={{width:'1280px', margin:'0 auto', paddingTop:'28px', position:'relative'
                    }}>
                    <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/drainage/dai.png" style={{width:'100%'}} />
                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center',
                        position:'absolute', top:'180px', left:0}}>
                        <div style={{width:'450px', textAlign:'center'}}>
                            <img src={imgUrl+'/img/drainage/ligh'+highlightsIndex+'.png'} style={{width:'73px', height:'80px'}}/>
                            <div style={{fontSize:'38px', fontWeight:'bold', color: '#FFF', marginTop:'20px'}}>{highlightsList[highlightsIndex]?.title}</div>
                        </div>
                        <div style={{width:'calc(100% - 500px)', textAlign:'center', padding:'0 100px', marginTop:'-20px'}}>
                            <div style={{fontSize:'24px', fontWeight:'bold', color: '#FFF', marginBottom:'30px',}}>{highlightsList[highlightsIndex]?.tip}</div>
                            <div style={{width:'426px', height:'80px', border:'5px solid rgba(255, 255, 255, 0.28)', borderRadius:'40px', margin:'0 auto',}}>
                                <div onClick={()=>props.navigate('/login')} style={{width:'416px', height:'70px',
                                    background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                    borderRadius:'35px', color: '#FFFFFF', textAlign:'center', lineHeight:'70px', cursor:'pointer',
                                    fontSize:'29px', fontWeight:600}}>
                                    立即入驻，免费发布需求
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div style={{textAlign:'center', marginTop:'90px', marginBottom:'-40px'}}>
                <div style={{fontSize:'36px', color:'#333', fontWeight:600, marginTop:'-40px', letterSpacing:'6px'}}>适合人群</div>
                <div style={{width:'72px', height:'4px', backgroundColor:'#F7385B', margin:'0 auto', marginTop:'5px'}}></div>
            </div>
            <div style={{width:'1280px', margin:'0px auto', display:'flex', justifyContent:'space-between', position:'relative', bottom:'-70px'}}
                onMouseLeave={()=>setShiIndex(-1)}>
                <div style={{width:'333px', height:'153px', display:'flex', alignItems:'center', position:'relative',
                    backgroundImage:'url('+imgUrl+'/img/drainage/lianjieB1.png'+')', backgroundSize:'cover',
                    transform: shiIndex==1?'scale(1.1)':'scale(1)', cursor:'pointer', zIndex:shiIndex==1?2:1 }}
                    onMouseEnter={()=>setShiIndex(1)}>
                    <div style={{width:'100%', marginLeft:'20px', paddingRight:'50px'}}>
                        <div style={{fontSize:'20px', fontWeight:800, lineHeight:'25px', textAlign:'center',}}>外贸企业&工厂商家</div>
                    </div>
                </div>
                <div style={{width:'391px', height:'153px', display:'flex', alignItems:'center', position:'relative',
                    backgroundImage:'url('+imgUrl+'/img/drainage/lianjieB2.png'+')', backgroundSize:'cover', marginLeft:'-55px',
                    transform: shiIndex==2?'scale(1.1)':'scale(1)', cursor:'pointer', zIndex:shiIndex==2?2:1 }}
                    onMouseEnter={()=>setShiIndex(2)}>
                    <div style={{width:'100%', marginLeft:'20px', padding:'0 40px'}}>
                        <div style={{fontSize:'20px', fontWeight:800, lineHeight:'25px', textAlign:'center',}}>跨境电商卖家（亚马逊，eBay，国际站，独立站等）</div>
                    </div>
                </div>
                <div style={{width:'391px', height:'153px', display:'flex', alignItems:'center', position:'relative',
                    backgroundImage:'url('+imgUrl+'/img/drainage/lianjieB3.png'+')', backgroundSize:'cover', marginLeft:'-57px',
                    transform: shiIndex==3?'scale(1.1)':'scale(1)', cursor:'pointer', zIndex:shiIndex==3?2:1 }}
                    onMouseEnter={()=>setShiIndex(3)}>
                    <div style={{width:'100%', marginLeft:'20px', padding:'0 50px'}}>
                        <div style={{fontSize:'20px', fontWeight:800, lineHeight:'25px', textAlign:'center',}}>国内电商玩家（直播带货平台，微商，天淘系等）</div>
                    </div>
                </div>
                <div style={{width:'398px', height:'153px', display:'flex', alignItems:'center', position:'relative',
                    backgroundImage:'url('+imgUrl+'/img/drainage/lianjieB4.png'+')', backgroundSize:'cover', marginLeft:'-57px',
                    transform: shiIndex==4?'scale(1.1)':'scale(1)', cursor:'pointer', zIndex:shiIndex==4?2:1 }}
                    onMouseEnter={()=>setShiIndex(4)}>
                    <div style={{width:'100%', marginLeft:'20px', padding:'0 50px'}}>
                        <div style={{fontSize:'20px', fontWeight:800, lineHeight:'25px', textAlign:'center',}}>外贸SOHO以及从业者，品牌出海先行者</div>
                    </div>
                </div>
            </div>
            <div style={{width:'100%', height:'460px', textAlign:'center', color:'#FFF', paddingTop:'130px',
                backgroundImage:'url('+imgUrl+'/img/drainage/zhinen.png'+')', backgroundSize:'cover'}}>
                <div onClick={()=>props.navigate('/login')} style={{width:'333px', height:'50px', border:'1px solid #FFF',
                    background: 'rgba(255,255,255,0.25)', margin:'0 auto', position:'relative', top:'-10%',
                    borderRadius:'35px', color: '#FFFFFF', textAlign:'center', lineHeight:'50px', cursor:'pointer',
                    fontSize:'22px', fontWeight:600}}>
                    立即注册，免费发布需求
                </div>
                <div style={{fontSize:'36px', fontWeight:'bold', letterSpacing:lang=='en'?'2px':'6px'}}>我们的优势：{textJson['智能挖掘匹配数据']}&nbsp;&nbsp;&nbsp;{textJson['发现爆款内容趋势']}</div>
                <div style={{width:'1280px', margin:'0 auto', display:'flex', justifyContent:'space-between', marginTop:'40px'}}>
                    <div>
                        <img style={{width:'58px'}} src={imgUrl+'/img/homeImg/zhi0.png'} />
                        <div style={{background: 'linear-gradient(180deg, #FFFFFF 0%, #FFC8C2 100%)', WebkitBackgroundClip:'text',
                            WebkitTextFillColor:'transparent', fontSize:'43px', fontWeight:'bold'}}>3,000,000+</div>
                        <span style={{fontSize:'16px', fontWeight:500}}>{textJson['收录TK博主']}</span>
                    </div>
                    <div>
                        <img style={{width:'58px'}} src={imgUrl+'/img/homeImg/zhi1.png'} />
                        <div style={{background: 'linear-gradient(180deg, #FFFFFF 0%, #FFC8C2 100%)', WebkitBackgroundClip:'text',
                            WebkitTextFillColor:'transparent', fontSize:'43px', fontWeight:'bold'}}>2,000,000+</div>
                        <span style={{fontSize:'16px', fontWeight:500}}>{textJson['爆款短视频数量']}</span>
                    </div>
                    <div>
                        <img style={{width:'58px'}} src={imgUrl+'/img/homeImg/zhi2.png'} />
                        <div style={{background: 'linear-gradient(180deg, #FFFFFF 0%, #FFC8C2 100%)', WebkitBackgroundClip:'text',
                            WebkitTextFillColor:'transparent', fontSize:'43px', fontWeight:'bold'}}>5,000,000+</div>
                        <span style={{fontSize:'16px', fontWeight:500}}>{textJson['覆盖话题数量']}</span>
                    </div>
                    <div>
                        <img style={{width:'58px'}} src={imgUrl+'/img/homeImg/zhi3.png'} />
                        <div style={{background: 'linear-gradient(180deg, #FFFFFF 0%, #FFC8C2 100%)', WebkitBackgroundClip:'text',
                            WebkitTextFillColor:'transparent', fontSize:'43px', fontWeight:'bold'}}>2,000,000+</div>
                        <span style={{fontSize:'16px', fontWeight:500}}>{textJson['各行业商家的选择']}</span>
                    </div>
                </div>
            </div>


            <div className='hot' style={{width:'100%', padding:'100px 0', backgroundPositionY:'-30px',
                backgroundImage:'url('+imgUrl+'/img/homeImg/hotB.png'+')', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
                <div style={{width:'1280px', height:'530px', margin:'0 auto', paddingTop:'60px'}}>
                    <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <div style={{fontSize:'26px', fontWeight:'bold', display:'flex', alignItems:'center', letterSpacing:'0.5px'}}>
                            BrandGoGo{textJson['热门榜单']}<span style={{fontSize:'14px', fontWeight:500, marginLeft:'24px'}}>{moment().format("MM/DD")} {textJson['日榜']}</span>
                        </div>
                        <div style={{width:'300px', display:'flex', justifyContent:'space-evenly', paddingTop:'10px'}}>
                            {
                                hotList?.map((item,index)=>{
                                    return <div key={index} style={{fontSize:'16px', fontWeight:'bold', position:'relative', top:'3px',
                                        color: index==hotIndex?'#F7385B':'#333', cursor:'pointer'}}
                                        onClick={()=>setHotIndex(index)}>{item.title}
                                        <div style={{width:'100%', height:'4px', background: index==hotIndex?'#F7385B':'', marginTop:'10px'}}></div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', marginTop:'30px'}}>
                        {
                            hotIndex == 0 ? hotList[hotIndex].list?.map((item,index)=>{
                                return <div key={index} style={{width: index==hotItemIndex?'368px':'164px', height:'250px', background:'#FFF', borderRadius:'8px',
                                    boxShadow: index==hotItemIndex?'0px 0px 20px 0px #EEEEEE':'0px 0px 10px 0px #EEEEEE', display:'flex', overflow: 'hidden', cursor:'pointer',
                                    transition: '0.1s width'}}
                                    onMouseEnter={()=>setHotItemIndex(index)}>
                                    <div style={{width:'164px', minWidth:'164px', height:'100%', textAlign:'center'}}>
                                        <img src={item.logo}
                                            style={{width:'60px', borderRadius:'30px', marginTop:'50px'}}/>
                                        <div className='over1' style={{fontWeight:'bold', marginTop:'15px'}}>{item.name}</div>
                                        <div style={{color:'#888888'}}>TikTok: {item.tikId}</div>
                                        <div style={{marginTop:'20px'}}>
                                        {
                                            index<3?<img style={{width:'20px'}} src={imgUrl+'/img/'+(index+1)+'.png'} />:
                                            <div style={{fontSize:'16px', color:'#F7385B', fontWeight:'bold'}}>NO.{index+1}</div>
                                        }
                                        </div>
                                    </div>
                                    <div style={{paddingLeft:'60px', paddingTop:'45px'}}>
                                        <div style={{fontSize:'12px', color:'#666666', fontWeight:500}}>
                                            <div>{intl.get('BloggerRanking').FansNum}</div>
                                            <div style={{fontSize:'20px', fontWeight:'bold', color:'#F7385B', lineHeight:'27px'}}>{item.fans}</div>
                                        </div>
                                        <div style={{fontSize:'12px', color:'#666666', fontWeight:500, marginTop:'15px'}}>
                                            <div>{intl.get('BloggerRanking').NumberVideos}</div>
                                            <div style={{fontSize:'20px', fontWeight:'bold', color:'#333', lineHeight:'27px'}}>{item.videos}</div>
                                        </div>
                                        <div style={{fontSize:'12px', color:'#666666', fontWeight:500, marginTop:'15px'}}>
                                            <div>{intl.get('BloggerRanking').LikesNum}</div>
                                            <div style={{fontSize:'20px', fontWeight:'bold', color:'#333', lineHeight:'27px'}}>{item.dig}</div>
                                        </div>
                                    </div>
                                </div>
                            }) :
                            hotIndex == 1 ? hotList[hotIndex].list?.map((item,index)=>{
                                return <div key={index} style={{width: index==hotItemIndex?'368px':'164px', height:'250px', background:'#FFF', borderRadius:'8px',
                                    boxShadow: index==hotItemIndex?'0px 0px 20px 0px #EEEEEE':'0px 0px 10px 0px #EEEEEE', display:'flex', overflow: 'hidden', cursor:'pointer',
                                    transition: '0.1s width'}}
                                    onMouseEnter={()=>setHotItemIndex(index)}>
                                    <div style={{width:'164px', minWidth:'164px', height:'100%', textAlign:'center'}}>
                                        <div style={{width:'80px', height:'110px', borderRadius:'4px', margin:'0 auto', marginTop:'34px', position:'relative',
                                            backgroundImage:'url('+item.logo+')', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
                                            <div style={{width:'100%', height:'100%', borderRadius:'4px', background:'rgba(0,0,0,0.38)',
                                                position:'absolute', top:0, left:0, color:'#fff', paddingTop:'83px'}}>{item.time}</div>
                                        </div>
                                        <div className='over' style={{fontWeight:'bold', marginTop:'5px', lineHeight:'17px', height:'35px', padding:'0 15px'}}>{item.name}</div>
                                        <div style={{marginTop:'13px'}}>
                                        {
                                            index<3?<img style={{width:'20px'}} src={imgUrl+'/img/'+(index+1)+'.png'} />:
                                            <div style={{fontSize:'16px', color:'#F7385B', fontWeight:'bold'}}>NO.{index+1}</div>
                                        }
                                        </div>
                                    </div>
                                    <div style={{paddingLeft:'60px', paddingTop:'45px'}}>
                                        <div style={{fontSize:'12px', color:'#666666', fontWeight:500}}>
                                            <div>{intl.get('TopicList').VideosNum}</div>
                                            <div style={{fontSize:'20px', fontWeight:'bold', color:'#F7385B', lineHeight:'27px'}}>{item.palys}</div>
                                        </div>
                                        <div style={{fontSize:'12px', color:'#666666', fontWeight:500, marginTop:'15px'}}>
                                            <div>{intl.get('BloggerRanking').LikesNum}</div>
                                            <div style={{fontSize:'20px', fontWeight:'bold', color:'#333', lineHeight:'27px'}}>{item.dig}</div>
                                        </div>
                                        <div style={{fontSize:'12px', color:'#666666', fontWeight:500, marginTop:'15px'}}>
                                            <div>{intl.get('TopicList').CommentsNum}</div>
                                            <div style={{fontSize:'20px', fontWeight:'bold', color:'#333', lineHeight:'27px'}}>{item.comments}</div>
                                        </div>
                                    </div>
                                </div>
                            }) : hotList[hotIndex].list?.map((item,index)=>{
                                return <div key={index} style={{width: index==hotItemIndex?'368px':'164px', height:'250px', background:'#FFF', borderRadius:'8px',
                                    boxShadow: index==hotItemIndex?'0px 0px 20px 0px #EEEEEE':'0px 0px 10px 0px #EEEEEE', display:'flex', overflow: 'hidden', cursor:'pointer',
                                    transition: '0.1s width'}}
                                    onMouseEnter={()=>setHotItemIndex(index)}>
                                    <div style={{width:'164px', minWidth:'164px', height:'100%', textAlign:'center'}}>
                                        <img src={imgUrl+'/img/topic.png'}
                                            style={{width:'34px', marginTop:'50px'}}/>
                                        <div className='over1' style={{fontWeight:'bold', marginTop:'10px'}}>{item.name}</div>
                                        <div style={{color:'#888888', fontSize:'12px', marginTop:'17px', fontWeight:500}}>{textJson['更新时间']}</div>
                                        <div style={{color:'#888888', fontSize:'12px', fontWeight:500}}>{item.upDate}</div>
                                        <div style={{marginTop:'18px'}}>
                                        {
                                            index<3?<img style={{width:'20px'}} src={imgUrl+'/img/'+(index+1)+'.png'} />:
                                            <div style={{fontSize:'16px', color:'#F7385B', fontWeight:'bold'}}>NO.{index+1}</div>
                                        }
                                        </div>
                                    </div>
                                    <div style={{paddingLeft:'60px', paddingTop:'45px'}}>
                                        <div style={{fontSize:'12px', color:'#666666', fontWeight:500}}>
                                            <div>{intl.get('TopicList').VideosNum}</div>
                                            <div style={{fontSize:'20px', fontWeight:'bold', color:'#F7385B', lineHeight:'27px'}}>{item.videos}</div>
                                        </div>
                                        <div style={{fontSize:'12px', color:'#666666', fontWeight:500, marginTop:'15px'}}>
                                            <div>{intl.get('TopicList').PlaysNum}</div>
                                            <div style={{fontSize:'20px', fontWeight:'bold', color:'#333', lineHeight:'27px'}}>{item.palys}</div>
                                        </div>
                                        <div style={{fontSize:'12px', color:'#666666', fontWeight:500, marginTop:'15px'}}>
                                            <div>{intl.get('LikesNum').PlaysNum}</div>
                                            <div style={{fontSize:'20px', fontWeight:'bold', color:'#333', lineHeight:'27px'}}>{item.dig}</div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }

                    </div>
                    <div style={{color:'#666666', marginTop:'24px', fontWeight: 500, display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        {textJson['全面的榜单数据，了解TikTok平台流量趋势，定位账号内容，寻找优质博主，视频爆款元素等']}
                        <div style={{width:'82px', height:'28px', border:'1px solid #F7385B', lineHeight:'26px', color:'#F7385B',
                            textAlign:'center', fontSize:'12px', borderRadius:'4px', cursor:'pointer'}}
                            onClick={()=>props.navigate('/login')}>{textJson['查看更多']}</div>
                    </div>
                </div>
            </div>



            <div style={{width:'100%', textAlign:'center', paddingTop:'100px', paddingBottom:'110px',
                backgroundImage:'url('+imgUrl+'/img/homeImg/yinxiao.png'+')', backgroundSize:'cover'}}>
                <div style={{fontSize:'36px', fontWeight:'bold', letterSpacing:'4px'}}>{textJson['TikTok全场景营销数字化赋能']}</div>
                <div style={{width:'1280px', margin:'0 auto', display:'flex', justifyContent:'space-between', flexWrap:'wrap',
                    marginTop:'60px'}}
                    onMouseLeave={()=>setTikALlIndex(-1)}>
                    {
                        tikALlList?.map((item,index)=>{
                            return <div key={index} style={{width:'400px', height:'230px', background:'#FFF', marginBottom:'30px',
                                padding:'0 50px', cursor:'pointer', boxShadow: index==tikAllIndex?'0px 4px 10px 0px #EEEEEE':'',
                                transform: index==tikAllIndex?'scale(1.1)':'scale(1)'}}
                                onMouseEnter={()=>setTikALlIndex(index)}>
                                <img style={{width:'80px', marginTop:'15px'}} src={imgUrl+'/img/homeImg/tik'+index+'.png'} />
                                <div style={{fontSize:'22px', fontWeight:'bold', lineHeight:'20px'}}>{item.title}</div>
                                <div className='over3' style={{color:'#666666', marginTop:'15px', lineHeight:'15px'}}>{item.tip}</div>
                            </div>
                        })
                    }
                </div>
            </div>



            <div style={{display:'none'}}>
            <Anchor affix={false} onChange={onChange}>
                <Link href="#playVideo" title="playVideo" />
            </Anchor>
            </div>
            <div id='playVideo' style={{width:'100%', height:'867px',
                backgroundImage:'url('+majorList[majorIndex].imgB+')', backgroundSize:'cover',
                }}>
                <div style={{textAlign:'center', color:'#FFF', paddingTop:'100px'}}>
                    <div style={{fontSize:'32px', fontWeight:'bold', letterSpacing:'6px'}}>{textJson['专业实力，深耕垂直领域']}</div>
                    <span style={{fontSize:'18px', lineHeight:'30px',letterSpacing:'2px'}}>{textJson['让全球消费者享受中国好产品']}</span>
                </div>
                <div style={{width:'1110px', height:'475px', margin:'0 auto', display:'flex', justifyContent:'space-between',
                    marginTop:'80px'}}>
                    <div style={{width:'224px'}}>
                    {
                        majorList?.map((item,index)=>{
                            return <div key={index} style={{width:'224px', height:'50px', background: index==majorIndex?'rgba(255,255,255, 0.25)':'',
                                marginBottom:'30px', fontSize:'18px', color:'#FFF', fontWeight:500, textAlign:'center', lineHeight:'50px',
                                cursor:'pointer'}}
                                onClick={()=>setMajorIndex(index)}>
                                {item.title}
                            </div>
                        })
                    }
                    </div>
                    {
                        majorList?.map((item,index)=>{
                            return index == majorIndex ? <div key={index} style={{width:'826px', height:'475px', background: 'rgba(0,0,0, 0.6)',
                                display:'flex', justifyContent:'center'}}>
                                <video width={826} height="475px" controls autoPlay={isPlay} loop>
                                    <source src={item.video} type="video/mp4" />
                                    <source src={item.video} type="video/ogg" />
                                    您的浏览器不支持 video 标签。
                                </video>
                            </div> : ''
                        })
                    }
                </div>
            </div>


            <div style={{textAlign:'center', marginTop:'90px'}}>
                <div style={{fontSize:'40px', color:'#F2F2F2', fontWeight:600, letterSpacing:'4px'}}>COOPERATIVE BRAND</div>
                <div style={{fontSize:'36px', color:'#333', fontWeight:600, marginTop:'-40px', letterSpacing:'6px'}}>{textJson['合作客户 品牌发声']}</div>
                <div style={{fontSize:'15px', color:'#333', fontWeight:500, marginTop:'0px'}}>{textJson['BrandGoGo已帮助1000家中国品牌在全球发声']}</div>
                <div style={{width:'72px', height:'4px', backgroundColor:'#F7385B', margin:'0 auto', marginTop:'15px'}}></div>
            </div>

            <div style={{width:'1280px', margin:'50px auto', marginBottom:'80px'}}>
                <img style={{width:'100%'}} src={imgUrl+'/img/homeImg/hezuo.png'} />
            </div>


            <div style={{width:'100%'}}>
                <img style={{width:'100%'}} src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/drainage/about.png" />
            </div>






            <div style={{width:'100%', height:'448px', background:'#333'}}>
                <div id='login' style={{width:'1280px', margin:'0 auto', paddingTop:'60px', color:"#FFF"}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <img style={{width:'256px'}} src={imgUrl+'/img/logo.png'} />
                        <div style={{fontSize:'26px', fontWeight:500, marginTop:'8px'}}><span style={{marginRight:'15px', marginLeft:'25px'}}>|</span> TikTok出海一站式代运营平台</div>
                    </div>
                    <div style={{display:'flex', justifyContent:'space-between', marginTop:'75px', padding:'0 70px'}}>
                        <Input onChange={e=>setInforM('enterpriseName', e.target.value)} value={information.enterpriseName} style={{width:'334px', height:'40px', borderRadius:'4px'}} placeholder={['请输入公司名称']} />
                        <Input onChange={e=>setInforM('name', e.target.value)} value={information.name} style={{width:'334px', height:'40px', borderRadius:'4px'}} placeholder={['请输入您的姓名']} />
                        <Input onChange={e=>setInforM('position', e.target.value)} style={{width:'334px', height:'40px', borderRadius:'4px'}} placeholder={['请输入您的职位']} />
                    </div>
                    <div id='xuqiu' style={{display:'flex', justifyContent:'space-between', marginTop:'15px', padding:'0 70px'}}>
                        <Select
                            style={{ width: '334px', height:'40px', border:'1px solid #d9d9d9', background:'#FFF', textAlign:'left', borderRadius:'4px', marginTop:'8px' }}
                            placeholder={<span style={{fontSize:'14px'}}>请选择您的需求</span>} allowClear={true}
                            onChange={handleChange} value={information.requirementId || undefined}
                            getPopupContainer={() => document.getElementById('xuqiu')}
                        >
                            {
                                demandList?.map((item,index)=>{
                                    return <Option key={index} id={item.id} value={item.id}>{item.requirementName}</Option>
                                })
                            }
                        </Select>
                        <Input onChange={e=>setInforM('phone', e.target.value)} value={information.phone} style={{width:'334px', height:'40px', borderRadius:'4px'}} placeholder={['请输入您的手机号']} />
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'334px', height:'40px'}}>
                            <Input onChange={e=>setInforM('verifyCode', e.target.value)} value={information.verifyCode} style={{width:'212px', height:'40px', borderRadius:'4px'}} placeholder={['请输入验证码']} />
                            <div onClick={getCode} style={{width:'116px', height:'38px',
                                background: isCode?'linear-gradient(180deg, #F7385B, #FF716E)':'',
                                borderRadius:'4px', color: isCode?'#FFF':'#F7385B', textAlign:'center', lineHeight:'38px', cursor:'pointer'}}>
                                {isCode?intl.get('Login').SendVerificationCode: time+'s后可重新发送'}
                            </div>
                        </div>
                    </div>
                    <div style={{width:'334px',margin:'16px auto', background:'none'}}>
                        <Button disabled={!isSub()} onClick={()=>postLogin()} style={{width:'334px', height:'40px',
                            background: 'linear-gradient(180deg, #F7385B, #FF716E)', border:0,
                            borderRadius:'4px', color: '#FFFFFF', textAlign:'center'}}>
                            立即入驻使用
                        </Button>
                    </div>
                </div>
            </div>



            <div style={{width:'100%', height:'100px', backgroundRepeat:'no-repeat', padding:'30px 0', textAlign:'center', color:'rgba(255,255,255,0.4)',
                backgroundImage:'url('+imgUrl+'/img/homeImg/yejiao.png'+')', backgroundSize:'100% 101%',}}>
                Copyright ©2021 <a style={{color:'rgba(255,255,255,0.4)'}} href={'https://beian.miit.gov.cn/#/Integrated/index'} target='_blank'>苏ICP备19001576号</a>
                苏州箴创信息科技有限公司版权所有
                <br/>增值电信业务经营许可证编号：<a style={{color:'rgba(255,255,255,0.4)'}} href={'https://beian.miit.gov.cn/#/Integrated/index'} target='_blank'>苏B2-20211086 苏公网安备 32050111125255号</a>
            </div>




        </div>
    </div>
}

export default withRouter(Drainage);
