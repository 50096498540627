import React, { useState, useEffect } from 'react'
import { } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom'
import { List, notification, Skeleton, Divider, Image } from 'antd';
import { searchTitleByTitleId, getTopicsDetail } from '../../../service/https'
import InfiniteScroll from 'react-infinite-scroll-component';
import withRouter from '../../../utils/withRouter'
import store from '../../../utils/redux/index'
import intl from 'react-intl-universal';
import '../../../asset/css/TopicDetail.scss'
import toKMB from '../../../utils/KMB'
import toHMS from '../../../utils/toHMS'
import moment from 'moment'
import * as Api from '../../../service/https'

const TopicDetail = (props) => {
    const [state, setSate] = useState(store.getState());
    const location = useLocation();
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [widths, setWidths] = useState(0)
    useEffect(() => {
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
            notification.destroy()
        }
    }, [])
    const openNotificationWithIcon = (type, message = '') => {
        notification[type]({
            description: message, top: 100, duration: 3
        });
    };

    const [topicDetail, setTopicDetail] = useState({})

    useEffect(() => {
        searchTitleByTitleId(null, { titleId: props.params.id }).then(res => {
            if (res.code === 200) {
                setTopicDetail(res.data.data[0])
                getIsCollect(res.data.data[0].id)
            }
        })
    }, [])
    const [isCollect, setIsCollect] = useState(false)
    const getIsCollect = (id) => {
        Api.getIsTipCollect({ titleUserId: id }).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setIsCollect(res.data)
            }
        })
    }

    const addCollection = () => {
        if (isCollect) {
            Api.delTipCollect({ titleCollectId: isCollect.id }).then(res => {
                // console.log(res)
                if (res.code == 200) {
                    openNotificationWithIcon('success', intl.get('Common')['取消成功'])
                    setIsCollect(false)
                    getIsCollect(topicDetail.id)
                }
            })
        } else {
            var obj = topicDetail
            obj.titleId = obj.id
            obj.title = obj.title
            obj.splitTitle = obj.splitTitle
            obj.desc = obj.desc
            obj.profileMedium = obj.profileMedium
            obj.videoCount = obj.videoCount
            obj.viewCount = obj.viewCount
            obj.playCount = obj.playCount
            let list = []
            obj.hotVideoList?.map(item => {
                return list.push({
                    titleId: obj.id,
                    cover: item.cover, duration: item.duration, videoId: item.id, playAddr: item.playAddr
                })
            })
            obj.titleCollectHotVideos = list
            // console.log(obj)
            Api.addTipCollect(obj).then(res => {
                // console.log(res)
                if (res.code == 200) {
                    openNotificationWithIcon('success', intl.get('Common')['收藏成功'])
                    getIsCollect(topicDetail.id)
                }
            })
        }
    }


    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(width)
        let num = width > 1836 ? 3 : 2
        setWidths(num)
    }

    useEffect(() => {
        heightFun()
    }, [state.collapsed])

    const [sortList, setSortList] = useState([intl.get('BloggerDetail')['发布时间'], intl.get('TopicList').PlaysNum,
        intl.get('BloggerRanking').LikesNum, intl.get('TopicList').CommentsNum, intl.get('TopicList').SharesNum]);
    const [sortIndex, setSortIndex] = useState(1);

    const sortMethod = (index) => {
        setSortIndex(index)
        setData([]);
        setTotal(0)
        setPage(0)
    }

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        loadMoreData();
    }, [sortIndex])

    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let json = {
            bloggerVideoSortRuler: sortIndex,
            from: page * 10,
            size: 10,
            title: props.params.title
        }
        getTopicsDetail(json).then(res => {
            if (res.code === 200) {
                setData([...data, ...res.data.data]);
                setTotal(res.data.total);
                setPage(page + 1);
                setLoading(false);
            }
        })
    };

    const palyVideo = (e, item, index) => {
        e.stopPropagation();
        // console.log('collection', index)
        window.open(`https://www.tiktok.com/@${item.elasticSearchUser.nickname}/video/${item.video.id}`)
    }

    // 别表
    const SmallDiv = (item, index) => {
        return <div key={index} style={{
            width: '100%', height: '150px', display: 'flex', alignItems: 'center',
            backgroundColor: index % 2 == 0 ? '#FFFFFF' : '#F6F6F6', cursor: 'pointer'
        }}
            onClick={() => detail(item, index)}>

            <div style={{ width: '7.8%', paddingLeft: '19px' }}>
                {index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png'} alt=""
                    style={{}} /> :
                    <div style={{ width: '32px', textAlign: 'center', fontWeight: 500 }}>{index + 1}</div>
                }
            </div>
            <div style={{ width: '38%', display: 'flex' }}>
                <div style={{
                    width: '80px', height: '110px', backgroundImage: 'url(' + item.video.cover + ')',
                    backgroundSize: 'cover', borderRadius: '4px', backgroundRepeat: 'no-repeat'
                }}>
                    <div style={{
                        width: '100%', height: '100%', background: 'rgba(0,0,0,0.2)', color: '#FFF',
                        textAlign: 'center', paddingTop: '86px', borderRadius: '4px', fontSize: '13px'
                    }}>
                        <span>{Math.floor(item.video.duration / 60)}:{(item.video.duration % 60) < 10 ? '0' : ''}{item.video.duration % 60}</span>
                    </div>
                </div>
                <div className='videoList' style={{
                    width: 'calc(100% - 80px - 85px)', paddingLeft: '15px',
                    color: '#888888',display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
                }}>
                    <div className='over' style={{
                        fontSize: '16px', fontWeight: 'bold', lineHeight: '20px',
                        color: '#333', paddingTop: '2px'
                    }}>{item.desc}</div>
                    <div className='over1'>
                        {
                            item.challenges?.map((item, index) => {
                                return <span key={index} style={{ marginRight: '5px' }}>#{item.title}</span>
                            })
                        }
                    </div>
                    <div>{intl.get('BloggerDetail')['发布时间']}：{moment(item.createTime * 1000).format('YYYY-MM-DD hh:mm:ss')}</div>
                </div>
            </div>
            <div style={{ width: '23%', display: 'flex', alignItems: 'center' }}>
                <Image src={item.elasticSearchUser.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                    style={{ width: '60px', height: '60px', borderRadius: '30px', marginRight: '16px' }} />
                <div style={{ color: '#888888' }}>
                    <div style={{
                        color: '#333333', fontWeight: 'bold', fontSize: '16px',
                        display: 'flex', alignItems: 'center'
                    }}>
                        <div className='over1' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item.elasticSearchUser.nickname}</div>
                        {item.elasticSearchUser.verified ? <img style={{ marginLeft: '3px', width: '14px', height: '14px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} /> : ''}
                    </div>
                    TikTok: {item.author}
                </div>
            </div>
            <div style={{ width: '6%' }}>{toKMB(item.playCount,2)}</div>
            <div style={{ width: '6%' }}>{toKMB(item.diggCount,2)}</div>
            <div style={{ width: '6%' }}>{toKMB(item.commentCount,2)}</div>
            <div style={{ width: '6%' }}>{toKMB(item.shareCount,2)}</div>
            <div style={{ width: '' }}>
                <img onClick={(e) => palyVideo(e, item, index)}
                    src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/play_grey.png'} style={{ marginRight: '9px' }} alt="" />
                {/* <img onClick={(e)=>addCollection(e, item,index)} 
                    src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc.png'} alt="" /> */}
            </div>
        </div>
    }

    const detail = (item, index) => {
        window.open(`/index/videoDetail/${item.video.id}/${item.author}`)
    }


    return (
        <div id='TopicDetail' style={{ paddingBottom: '50px' }}>
            {topicDetail ? <div style={{
                width: '100%', backgroundColor: '#FFF', padding: '38px', fontWeight: 500,
                display: 'flex', alignItems: 'center', justifyContent: 'space-between'
            }}>
                <div style={{ width: '505px', display: 'flex', alignItems: 'center' }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/topic_logo.png'} alt=""
                        style={{ width: '100px', height: '100px', marginRight: '16px' }} />
                    <div>
                        <div style={{ fontWeight: 'bold', fontSize: '18px' }}>{topicDetail.title}</div>
                        <div style={{ color: '#888888' }}>{intl.get('Index')['更新时间']}：{moment(topicDetail.updateTime*1000).format('YYYY-MM-DD hh:mm:ss')}</div>
                    </div>
                </div>
                <div style={{ width: '1px', height: '70px', backgroundColor: '#EEEEEE' }}></div>
                <div style={{ width: '70%', padding: '0 52px' }}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        textAlign: 'center'
                    }}>
                        <div>
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(topicDetail.viewCount, 2)}</div>
                            <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/topic/playCircle.png'} alt=""
                                    style={{ width: '14px', marginRight: '4px', }} /> {intl.get('TopicList').PlaysNum}
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(topicDetail.videoCount, 2)}</div>
                            <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/topic/shipin.png'} alt=""
                                    style={{ width: '14px', marginRight: '4px', }} /> {intl.get('TopicList').VideosNum}
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(topicDetail.diggCount, 2)}</div>
                            <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/topic/dianzan.png'} alt=""
                                    style={{ width: '14px', marginRight: '4px', }} /> {intl.get('TopicList').LikesNum}
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(topicDetail.commentCount, 2)}</div>
                            <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/topic/pinglun.png'} alt=""
                                    style={{ width: '14px', marginRight: '4px', }} /> {intl.get('TopicList').CommentsNum}
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(topicDetail.shareCount, 2)}</div>
                            <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/topic/fenxiang.png'} alt=""
                                    style={{ width: '14px', marginRight: '4px', }} /> {intl.get('TopicList').SharesNum}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '1px', height: '70px', backgroundColor: '#EEEEEE' }}></div>
                <div className='detailDC' style={{ display: 'flex', marginLeft: '60px' }}
                    onClick={() => addCollection()}>
                    <div style={{
                        width: '160px', height: '38px', background: isCollect ? 'linear-gradient(180deg, #F7385B, #FF716E)' : '',
                        display: 'flex', justifyContent: 'center', alignItems: 'center', color: isCollect ? '#FFF' : '#888888', fontSize: '12px',
                        borderRadius: '4px', cursor: 'pointer', border: isCollect ? 0 : '1px solid #EEEEEE', marginLeft: '23px'
                    }}>
                        <img
                            src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/shoucang' + (isCollect ? '' : 's') + '.png'} alt=""
                            style={{ width: '14px', marginRight: '4px', }} />{isCollect?intl.get('BloggerDetail')['取消收藏']:intl.get('Workbench').Collection}
                    </div>
                </div>
            </div> : ''}

            {/* 排序 */}
            <div style={{
                width: '100%', height: '60px', backgroundColor: '#FFF', marginTop: '10px',
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '37px',
            }}>
                <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                    <div style={{
                        backgroundColor: '#FFEEF1', padding: '9px 27px', borderRadius: '4px',
                        color: '#F7385B', cursor: 'pointer'
                    }}>{intl.get('BloggerDetail')['全部视频']}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        sortList?.map((item, index) => {
                            return <div key={index} className='active' style={{
                                display: 'flex', alignItems: 'center', marginRight: '36px',
                                color: sortIndex === index + 1 ? '#F7385B' : '#888888', cursor: 'pointer'
                            }}
                                onClick={() => sortMethod(index + 1)}>
                                {item}
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex === index + 1 ? 's' : '') + '.png'} alt=""
                                    style={{ marginLeft: '6px', marginTop: '2px' }} />
                            </div>
                        })
                    }
                </div>
            </div>

            {/* 列表 */}
            <div style={{
                width: '100%', backgroundColor: '#FFFFFF', padding: '24px 24px 24px 30px', marginTop: '10px'
            }}>
                <div style={{
                    width: '100%', height: '50px', display: 'flex', alignItems: 'center', backgroundColor: '#F6F6F6',
                    fontWeight: 500
                }}>
                    <div style={{ width: '7.8%', paddingLeft: '19px' }}>{intl.get('BloggerRanking').Ranking}</div>
                    <div style={{ width: '38%', paddingLeft: '14px' }}>{['视频']}</div>
                    <div style={{ width: '22%', paddingRight: '10px', marginLeft: '20px', paddingLeft: '20px' }}>{intl.get('Header')['博主']}</div>
                    <div style={{ width: '6%', paddingRight: '10px' }}>{intl.get('TopicList').PlaysNum}</div>
                    <div style={{ width: '6%', paddingRight: '10px' }}>{intl.get('TopicList').LikesNum}</div>
                    <div style={{ width: '6%', paddingRight: '10px' }}>{intl.get('TopicList').CommentsNum}</div>
                    <div style={{ width: '6%', paddingRight: '10px' }}>{intl.get('TopicList').SharesNum}</div>
                    <div style={{ width: '' }}>{intl.get('BloggerRanking').Operation}</div>
                </div>
                <InfiniteScroll
                    dataLength={data.length}
                    next={loadMoreData}
                    hasMore={data.length < total}
                    loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                    endMessage={<Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={data}
                        grid={{ gutter: 0, column: 1 }}
                        renderItem={
                            (item, index) => <div key={index} style={{}}>
                                {SmallDiv(item, index)}
                            </div>
                        }
                    />
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default withRouter(TopicDetail);