import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import {Image, Spin, Divider, List, Select, Input, Modal} from 'antd';
import { DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import * as Api from '../../../service/https';
import '../../../asset/css/MyGoods.scss'
import intl from 'react-intl-universal';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../../utils/redux/index'
import toKMB from "../../../utils/KMB";
import ShareCommonModel from "../../../components/Share/ShareCommonModel";
import three from "../../../utils/three";
import openNotificationWithIcon from '../../../components/Notification'
import toHMS from "../../../utils/toHMS";
import {collectMusicRanking} from "../../../service/https";
import moment from "moment/moment";
const { Option } = Select;

const MusicRanking = () => {
    const videoUrl = 'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/videos/dianzishuma.mp4'
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [state, setSate] = useState(store.getState())
    const storeChange = () => setSate(store.getState())
    store.subscribe(storeChange)
    const [rankingList, rankingListSet] = useState([])
    const [tabShow, setTabShow] = useState(false)
    const [tabIndex, setTabIndex] = useState(-1)
    const [sortList] = useState([{title:intl.get('MusicRanking')['视频数'],value:'userCount'},{title:intl.get('MusicRanking')['音乐时长'],value:'duration'}]);
    const [sortIndex, setSortIndex] = useState(0)
    const [pageIndex, setPageIndex] = useState(1)
    const [total, setTotal] = useState(0)
    const [sortWay, sortWaySet] = useState(2)
    const [loading, setLoading] = useState(false)
    const [isBig] = useState(false)
    const [widths, setWidths] = useState(0)
    const [showAudio, setShowAudio] = useState(false)
    const [playAudioUrl,setPlayAudioUrl] = useState('')
    const shareObj = {
        shareName:'MusicRanking',
        topTitle: intl.get('ShareModel')['视频数'],
        topDayType: intl.get('ShareModel')['日榜'],
        itemImg:'cover',
        itemTitle:'title',
        itemLeftTag: intl.get('ShareModel')['视频数'],
        itemLeftValue: 'userCount',
        itemRightTag:intl.get('ShareModel')['时长'],
        itemRightValue:'duration'
    }

    const [sortNumList, setItems] = useState([
        { start: 0, key: '<', end: 10000 },
        { start: 10000, key: '~', end: 100000 },
        { start: 100000, key: '~', end: 500000 },
        { start: 500000, key: '~', end: 1000000 },
        { start: 1000000, key: '~', end: 5000000 },
        { start: 5000000, key: '~', end: 10000000 },
        { start: 10000000, key: '~', end: 50000000 },
        { start: 50000000, key: '<', end: 0 },
        { start: '', key: '~', end: '' },
        { start: '', key: '~', end: '' },
        { start: '', key: '~', end: '' }
    ]);
    const videoTime = [
        {start: 0, key: '<', end: 15},
        {start: 15, key: '~', end: 30},
        {start: 30, key: '~', end: 60},
        {start: 60, key: '<', end: 0}
    ]
    const [selectConfirmStatus, setSelectConfirmStatus] = useState(1)
    const [sortVideoCountIndex, setVideoCountIndex] = useState(-1)
    const [sortDurationIndex, setDurationIndex] = useState(-1)
    const changeMinInput = (index, value) => {
        let v = JSON.parse(JSON.stringify(sortNumList))
        v[index].start = value
        setItems(v)
    }
    const changeMaxInput = (index, value) => {
        let v = JSON.parse(JSON.stringify(sortNumList))
        v[index].end = value
        setItems(v)
    }
    const changeVideoNumSearch = (index) => {
        setPageIndex(1)
        setVideoCountIndex(index == undefined ? -2 : index)
        if (index == undefined) {
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[8] = { start: '', key: '~', end: '' }
            setItems(v)
        }else{
            setSelectConfirmStatus(selectConfirmStatus == 1 ? 2 : 1)
        }
    }
    const changeDurationSearch = (index) => {
        setPageIndex(1)
        setDurationIndex(index == undefined ? -2 : index)
        if (index == undefined) {
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[9] = { start: '', key: '~', end: '' }
            setItems(v)
        }else{
            setSelectConfirmStatus(selectConfirmStatus == 1 ? 2 : 1)
        }
    }

    // 生成图片自动下载为png格式（将dom转为二进制再编译下载）
    const [isShare, setIsShare] = useState(false)
    const getBlobPng = () => {
        setIsShare(!isShare)
    }

    useEffect(() => {
        getCountry()
        // getCategoryAll()
        heightFun()
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
        }
    }, [])

    useEffect(() => {
        getRankingList()
    }, [selectConfirmStatus,sortVideoCountIndex,sortDurationIndex, tabIndex, sortIndex, sortWay])

    const heightFun = () => {
        let width = document.documentElement.clientWidth
        let num = Number(state.collapsed) + (width > 1890 ? 6 : width > 1650 ? 5 : 4)
        setWidths(num)
    }

    // soldCount soldTotal minPrice
    const getRankingList = () => {
        if(loading) return
        setLoading(true)
        let data = {
            current: pageIndex,
            size: 20,
            [sortWay == 2 ? 'descColumn' : 'ascColumn']: sortIndex > -1 ? sortList[sortIndex].value : '',
        }
        let params = {
            minUserCount: sortVideoCountIndex == (-2 || -1) ? 0 : sortNumList[sortVideoCountIndex]?.start || '',
            maxUserCount: sortVideoCountIndex == (-2 || -1) ? '' : sortNumList[sortVideoCountIndex]?.end || '',
            minDuration: sortDurationIndex == (-2 || -1) ? 0 : videoTime[sortDurationIndex]?.start || '',
            maxDuration: sortDurationIndex == (-2 || -1) ? '' : videoTime[sortDurationIndex]?.end || '',
            filterCollect:true
        }
        Api.getMusicRanking(data,params).then(res => {
            if (res.code === 200) {
                setTotal(res.data.total)
                rankingListSet(pageIndex <= 1 ? res.data.records : [...rankingList, ...res.data.records])
                if (shareData.length === 0) setShareData(res.data.records)
                setPageIndex(pageIndex + 1)
            }
            setLoading(false)
        })
    }
    const [geolocation, setGeolocation] = useState([])
    const getCountry = ()=>{
        Api.getShopCountry().then(res=>{
            if(res.code == 200){
                let all = res.data
                setGeolocation(all)
            }
        })
    }
    const changeTab = (index) => {
        setPageIndex(1)
        setTabIndex(index)
    }

    const sortChange = e => {
        setPageIndex(1)
        setSortIndex(e);
        if(e === sortIndex) sortWaySet(sortWay === 1 ? 2 : 1)
    }

    const toDetail = item => {
        window.open(`/index/MusicRankingDetail/${item.id}`)
    }

    const [shareData, setShareData] = useState([]);

    const addCollection = (e, item, index) => {
        e.stopPropagation()
        Api.collectMusicRanking({id: item.id || ''}).then(res => {
            if (res.code === 200) {
                openNotificationWithIcon('success', intl.get('MusicRanking')[item.collect ? '取消成功！' : '收藏成功！'])
                let t = JSON.parse(JSON.stringify(rankingList))
                t.splice(index, 1)
                rankingListSet(t)
            }
        })
    }

    const openAudioModal = (e, item, index) => {
        e.stopPropagation()
        setPlayAudioUrl(item.musicUrl)
        setShowAudio(true)
    }

    const loadMoreData = () => {
        getRankingList()
    }

    // 别表
    const SmallDiv = (item, index) => {
        return (
            <div key={index} style={{
                width: '100%', height: '151px', display: 'flex', alignItems: 'center',
                backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F6F6F6', cursor: 'pointer'
            }} onClick={() => toDetail(item)}>
                <div style={{ width: '8%', paddingLeft: '19px' }}>
                    {
                        index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png'} alt="" />
                            : <div style={{ width: '32px', textAlign: 'center', fontWeight: 500 }}>{index + 1}</div>
                    }
                </div>
                <div style={{ width: '22%', display: 'flex', alignItems: 'center', paddingRight: '40px' }}>
                    <Image src={item.cover || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                           style={{ width: '60px', height: '60px', marginRight: '16px' }} preview={false} />
                    <div className='over' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item.title}</div>
                </div>
                <div style={{ width: '10%', paddingRight: '10px'  }}>{item.author}{three(item.minPrice)}</div>
                {/*<div className="flex-ac" style={{ width: '10%' }}><img src={item.countryUrl} alt="" style={{width:'20px',height:'14px',marginRight:'6px'}} />{item.country}</div>*/}
                <div style={{ width: '8%' }}>{moment(item.duration * 1000).format('mm:ss')}</div>
                <div style={{ width: '8%' }}>{toKMB(item.userCount,2)}</div>
                <div style={{ width: '24%', display: 'flex'}}>
                    {
                        item.videoList?.map((item2, index) => index < 3 ?
                            <div key={index} style={{ marginRight: '10px', position: 'relative' }}
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     window.open(`https://www.tiktok.com/@${item2.uniqueId}/video/${item2.id}`)
                                 }}>
                                <Image src={item2.cover} preview={false} width={80} height={111} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'} />
                                <div style={{ position: 'absolute', bottom: '0', display: 'flex', alignItems: 'center', width: '80px', textAlign: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '80px', justifyContent: 'center' }}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/playCircle.png'} style={{ width: 12, height: 12, marginRight: '3px' }} alt="" />
                                        <div style={{ color: '#fff', fontSize: '13px' }}>{toHMS(item2.duration || 0)}</div>
                                    </div>
                                </div>
                            </div> : '')
                    }
                </div>
                <div style={{width: '10%'}}>
                    <img onClick={(e) => addCollection(e, item, index)}
                         src={`https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc${item.collect ? 's' : ''}.png`}
                         alt=""
                    />
                    <img onClick={(e) => openAudioModal(e, item, index)} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/play_grey.png'} style={{marginLeft:'17px'}} alt="" />
                </div>
            </div>
        )
    }

    const BigDiv = (item, index) => {
        return (
            <div className='BigDivs' style={{
                width: '240px', backgroundColor: '#FFF', margin: '0 auto',
                marginBottom: widths === 6 ? '8px' : widths === 5 ? '12px' : widths === 4 ? '18px' : '', borderRadius: '4px',
                cursor: 'pointer'
            }}>
                <div onClick={() => toDetail(item)} style={{
                    width: '100%', position: 'relative', backgroundColor: 'rgb(246,246,246)',
                    borderTopLeftRadius: '4px', borderTopRightRadius: '4px',
                }}>
                    <Image src={item.cover} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                           style={{ width: '240px', height: '240px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} onClick={e => e.stopPropagation()} />
                    <div style={{
                        width: '100%', height: '100%', backgroundColor: 'rgb(0,0,0,0.22)',
                        display: 'flex', flexFlow: 'column', justifyContent: 'space-between', position: 'absolute', top: 0,
                        borderTopLeftRadius: '4px', borderTopRightRadius: '4px',
                    }}>
                        <div style={{ textAlign: 'right', paddingTop: '14px', paddingRight: '10px' }}>
                            {
                                item.collect ?
                                    <img
                                        onClick={(e) => addCollection(e, item, index)}
                                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/SCBS.png'}
                                        alt=""
                                    />
                                    : <img
                                        onClick={(e) => addCollection(e, item, index)}
                                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/SCB.png'}
                                        alt=""
                                    />
                            }
                        </div>
                        <div style={{ width: '100%', padding: '0 14px 38px' }}>
                            <div style={{ width: '100%', display: 'flex', color: '#FFF', alignItems: 'center' }}>
                                <div className='over1' style={{fontWeight: 'bold', fontSize: '14px', }}>{item.productName}</div>
                            </div>
                            {/*<div style={{ color: '#F6F6F6', opacity: '0.7', fontWeight: 400 }}>{item.productName}</div>*/}
                        </div>
                    </div>
                </div>
                <div className="flex-acjb"  style={{ width: '100%', height: '44px', padding: '16px 14px' }}>
                    <div style={{ color: '#F7385B', fontWeight: '800', fontSize: '14px' }}>{item.currencyStr}{three(item.minPrice)}</div>
                    <div className="flex-ac"><img src={item.countryUrl} alt="" style={{width:'20px',height:'14px',marginRight:'6px'}}/>{item.country}</div>
                </div>
                <div style={{
                    width: '100%', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                    textAlign: 'center',borderTop: '1px dashed #EEEEEE',
                }}>
                    {/*<div style={{ width: '100px' }}>*/}
                    {/*    <strong style={{ fontSize: '16px' }}>{toKMB(item.minPrice, 2)}</strong>*/}
                    {/*    <p style={{ fontSize: '12px', color: '#888888', marginBottom: '4px' }}>{intl.get('MusicRanking')['佣金率']}</p>*/}
                    {/*</div>*/}
                    <div style={{ width: '100px' }}>
                        <strong style={{ fontSize: '16px' }}>{toKMB(item.soldCount, 2)}</strong>
                        <p style={{ fontSize: '12px', color: '#888888', marginBottom: '4px' }}>{intl.get('MusicRanking')['销量']}</p>
                    </div>
                    <div style={{ width: '100px' }}>
                        <strong style={{ fontSize: '16px' }}>{item.currencyStr}{three(item.soldTotal, 2)}</strong>
                        <p style={{ fontSize: '12px', color: '#888888', marginBottom: '4px' }}>{intl.get('MusicRanking')['销售额']}</p>
                    </div>
                </div>
            </div >
        )
    }

    return (
        <div id='MusicRanking'>
            <div style={{
                width: '100%', backgroundColor: '#FFF', paddingTop: '24px', paddingLeft: '48px', paddingBottom: '25px',
                fontWeight: 500
            }}>
                {/* 筛选国家 */}
                {/*<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>*/}
                {/*    <label style={{ width: state.language == 'zh' ? '102px' : '90px', marginTop: '6px', textAlign: 'right',paddingRight:'26px'}}>{intl.get('BloggerRanking').Country}：</label>*/}
                {/*    <div style={{*/}
                {/*        flex:1, height: tabShow ? 'auto' : '39px', display: 'flex', flexWrap: 'wrap',*/}
                {/*        overflow: tabShow ? '' : 'hidden'*/}
                {/*    }}>*/}
                {/*    <span className='tabItem' style={{*/}
                {/*        padding: '6px 10px',*/}
                {/*        background: -1 == tabIndex ? '#FFE7E9' : 'none', marginBottom: '5px',*/}
                {/*        color: -1 == tabIndex ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',*/}
                {/*        cursor: 'pointer'*/}
                {/*    }}*/}
                {/*          onClick={() => changeTab(-1)}>{lang=='zh'?'全部':'All'}</span>*/}
                {/*        {*/}
                {/*            geolocation?.map((item, index) => {*/}
                {/*                return <span key={index} className='tabItem' style={{*/}
                {/*                    padding: '6px 10px',*/}
                {/*                    background: index == tabIndex ? '#FFE7E9' : 'none', marginBottom: '5px',*/}
                {/*                    color: index == tabIndex ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',*/}
                {/*                    cursor: 'pointer'*/}
                {/*                }}*/}
                {/*                             onClick={() => changeTab(index)}>{lang == 'zh' ?item.name:item.enName}</span>*/}
                {/*            })*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*    <div style={{ width: '45px' }}>*/}
                {/*        {!tabShow ?*/}
                {/*            <DownSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'*/}
                {/*                               onClick={() => { setTabShow(true) }} />*/}
                {/*            :*/}
                {/*            <UpSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'*/}
                {/*                             onClick={() => setTabShow(false)} />}*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/* 筛选 */}
                <div style={{ width: '100%', height: '30px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <label style={{ width: state.language == 'zh' ? '102px' : '90px', textAlign: 'right',paddingRight:'26px' }}>
                        {intl.get('BloggerRanking').Screen}：</label>
                    <div id='fans'>
                        <span>{intl.get('MusicRanking')['视频数']}</span>
                        <Select
                            onClear={e => changeVideoNumSearch(undefined)}
                            allowClear={true}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <div id='login' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 10px', paddingBottom: '5px' }}>
                                        <Input value={sortNumList[8].start}
                                               onChange={e => changeMinInput(8, e.target.value)}
                                               style={{ width: '50px', paddingLeft: '4px', paddingRight: '4px', textAlign: 'center' }} placeholder="min" /> ~
                                        <Input value={sortNumList[8].end}
                                               onChange={e => changeMaxInput(8, e.target.value)}
                                               style={{ width: '50px', paddingLeft: '4px', paddingRight: '4px', textAlign: 'center' }} placeholder="max" />
                                        <span style={{
                                            border: sortNumList[8].start >= 0 && parseInt(sortNumList[8].end) > parseInt(sortNumList[8].start) ? '' : '1px solid #EEEEEE', borderRadius: '4px', padding: '5.5px 11px', marginLeft: '5px', cursor: 'pointer',
                                            color: sortNumList[8].start >= 0 && parseInt(sortNumList[8].end) > parseInt(sortNumList[8].start) ? '#FFF' : '#CCCCCC',
                                            background: sortNumList[8].start >= 0 && parseInt(sortNumList[8].end) > parseInt(sortNumList[8].start) ? '#F7385B' : '#FFF', fontSize: '12px'
                                        }}
                                              onClick={() => sortNumList[8].start >= 0 && parseInt(sortNumList[8].end) > parseInt(sortNumList[8].start) ? changeVideoNumSearch(8) : null}>确定</span>
                                    </div>
                                </>
                            )}
                            value={(sortVideoCountIndex == -1 || sortVideoCountIndex == -2) ? <span>{intl.get('MusicRanking')['请选择']}</span> : ((sortNumList[sortVideoCountIndex].start > 0 ? toKMB(sortNumList[sortVideoCountIndex].start) : '') + ' ' + sortNumList[sortVideoCountIndex].key + ' ' + (sortNumList[sortVideoCountIndex].end > 0 ? toKMB(sortNumList[sortVideoCountIndex].end) : ''))}
                            style={{ width: 200, borderRadius: '26px', marginLeft: '10px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={intl.get('MusicRanking')['请选择']}>
                            {sortNumList?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeVideoNumSearch(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                            ))}
                        </Select>
                    </div>
                    <div id='fans'>
                        <span style={{ marginLeft: state.language == 'zh' ? '40px' : '20px' }}>{intl.get('MusicRanking')['音乐时长']}</span>
                        <Select
                            onClear={e => changeDurationSearch(undefined)}
                            allowClear={true}
                            value={(sortDurationIndex == -1 || sortDurationIndex == -2) ? <span>{intl.get('MusicRanking')['请选择']}</span> : ((videoTime[sortDurationIndex].start > 0 ? videoTime[sortDurationIndex].start + 's' : '') + videoTime[sortDurationIndex].key + ' ' + (videoTime[sortDurationIndex].end > 0 ? videoTime[sortDurationIndex].end + 's' : ''))}
                            style={{ width: 200, borderRadius: '26px', marginLeft: '10px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={intl.get('MusicRanking')['请选择']}>
                            {videoTime?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeDurationSearch(index)}>{item.start>0?item.start + 's' :''} {item.key} {item.end>0?item.end + 's' :''}</div></Option> : ''
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
            {/*排序*/}
            <div style={{
                width: '100%', height: '60px', backgroundColor: '#FFF', marginTop: '10px',
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '47px',
                paddingRight: '24px'
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {

                        sortList?.map((item, index) => {
                            return <div key={index} className='active' style={{
                                display: 'flex', alignItems: 'center', marginRight: '36px',
                                color: sortIndex === index ? '#F7385B' : '#888888', cursor: 'pointer'
                            }} onClick={() => sortChange(index)}>
                                {item.title}
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex === index && sortWay === 1 ? 's' : '') + '.png'} alt=""
                                     style={{ marginLeft: '6px', marginTop: '2px', transform: 'rotate(180deg)' }} />
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex === index && sortWay === 2 ? 's' : '') + '.png'} alt=""
                                     style={{ marginLeft: '2px', marginTop: '2px' }} />
                            </div>
                        })
                    }
                </div>
            </div>
            {/*列表*/}
            <div style={{ width: '100%', backgroundColor: isBig ? '' : '#FFFFFF', padding: isBig ? '0 10px' : '24px 24px 24px 30px', marginTop: isBig ? 0 : '10px' }}>
                <div style={{ width: '100%', height: '50px', display: isBig ? 'none' : 'flex', alignItems: 'center', backgroundColor: '#F6F6F6', fontWeight: 500 }}>
                    <div style={{ width: '8%', paddingLeft: '19px' }}>{intl.get('MusicRanking')['排名']}</div>
                    <div style={{ width: '22%', paddingLeft: '14px',paddingRight: '40px' }}>{intl.get('MusicRanking')['音乐']}</div>
                    <div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('MusicRanking')['作者']}</div>
                    {/*<div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('MusicRanking')['国家']}</div>*/}
                    <div style={{ width: '8%', paddingRight: '10px' }}>{intl.get('MusicRanking')['时长']}</div>
                    <div style={{ width: '8%', paddingRight: '10px' }}>{intl.get('MusicRanking')['视频数']}</div>
                    <div style={{ width: '24%', paddingRight: '10px' }}>{intl.get('MusicRanking')['最热视频']}</div>
                    <div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('MusicRanking')['操作']}</div>
                </div>
                <Spin tip="数据加载中..." spinning={loading}>
                    <InfiniteScroll
                        dataLength={rankingList.length}
                        scrollThreshold={'20px'}
                        next={loadMoreData}
                        hasMore={total > 0 && rankingList.length < total}
                        endMessage={<Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                        <List
                            dataSource={rankingList}
                            grid={{gutter: 0, column: isBig ? widths : 1}}
                            renderItem={
                                (item, index) =>
                                    <div style={{ paddingTop: isBig ? '10px' : 0 }}>
                                        {isBig ? BigDiv(item, index) : SmallDiv(item, index)}
                                    </div>
                            }
                        />
                    </InfiniteScroll>
                </Spin>
            </div>
            {isShare ? <ShareCommonModel clickFun={() => getBlobPng(false)} dataList={shareData} shareObj={shareObj} /> : ''}
            <Modal centered visible={showAudio} footer={null} closable={null} onCancel={()=>{setShowAudio(false)}}
                   destroyOnClose={true} width={300}
                   getContainer={false}
                   bodyStyle={{
                       padding: '0', backgroundColor: 'transparent', overflow: 'hidden',textAlign:'center'
                   }}>
                <audio controls autoPlay={true} loop>
                    <source src={playAudioUrl} type="video/mpeg" />
                    <source src={playAudioUrl} type="video/ogg" />
                    您的浏览器不支持 audio 标签。
                </audio>
            </Modal>
        </div>
    )
}

export default withRouter(MusicRanking);