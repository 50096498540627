const defalutState = {
    collapsed: 0,
    language: 'zh',
    userInfo: null,
    token: null,
    MCN: null,
    changeRouter: null,
    isWeb: true,
    news:{unreadCount: -1}
}

export default (state = defalutState, action) =>{
    switch (action.type) {
        case 'token':
            return {
                ...state,
                token: action.value,
            };
        case 'userInfo':
            return {
                ...state,
                userInfo: action.value,
            };
        case 'collapsed':
            return {
                ...state,
                collapsed: action.value,
            };
        case 'language':
            return {
                ...state,
                language: action.value,
            };
        case 'MCN':
            return {
                ...state,
                MCN: action.value,
            };
        case 'changeRouter':
            return {
                ...state,
                changeRouter: action.value,
            };
        case 'isWeb':
            return {
                ...state,
                isWeb: action.value,
            };
        case 'news':
            return {
                ...state,
                news: action.value,
            };
        default:
            return state
    }
}