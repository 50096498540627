import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import intl from 'react-intl-universal';
import * as Api from '../../../service/https'
import { Modal, Input, List, notification, Button, Skeleton, Divider, Spin, DatePicker, Image } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import QRCode from 'qrcode.react';
import { CloseCircleOutlined } from '@ant-design/icons';
import '../../../asset/css/VideoDemand.scss'
import moment from 'moment';
const { RangePicker } = DatePicker;

const AccountRecharge = () => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [textJson, setTextJson] = useState(intl.get('Wallet'))
    const [amount, setAmount] = useState('')
    const payMethodObj = {
        0:'',
        1:'支付宝',
        2:'微信',
        3:'支付宝app支付',
        4:'微信app支付',
    }
    useEffect(() => {
        getWallet()
        pTotal()
        return ()=>{
            clearInterval(interval.current)
        }
    }, [])

    const [status, setStatus] = useState(1)
    const [statusList, setStatusList] = useState([
        {
            id: 1,
            text: intl.get('AccountRecharge')['合作付款记录'],
        },
        {
            id: 2,
            text: intl.get('AccountRecharge')['充值记录'],
        },
        {
            id: 3,
            text: intl.get('AccountRecharge')['退款记录'],
        },
    ])



   
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [walletObj, setWallet] = useState([])
    const [isSuccess, setScucess] = useState(false)
    const [isApply, setApply] = useState(false)
    
    const getWallet = () => {
        Api.getWallet({}).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setWallet(res.data)
            }
        })
    }
    const [pTotals, setPTotal] = useState(0)
    const pTotal = () => {
        Api.pTotal({}).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setPTotal(res.data.totalESum)
            }
        })
    }
    
    const changeTab = (item) => {
        setPageIndex(1)
        setStatus(item.id)
    }
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            current: pageIndex, size: 10, 
        }
        if(startEndTime.length > 0){
            obj.timeFilter = {
                start: startEndTime[0],
                end: startEndTime[1]
            }
        }
        if(status == 1){
            Api.getBusiness_merchantRecord(obj).then(res => {
                // console.log(res)
                setLoading(false);
                if (res.code == 200) {
                    setTotal(res.data.total)
                    setData(pageIndex == 1 ? res.data.walletInfos : [...data, ...res.data.walletInfos]);
                    setPageIndex(pageIndex + 1)
                }
            }).catch(() => {
                setLoading(false);
            });
        }else if(status == 2){
            Api.getBusiness_rechargeRecord(obj).then(res => {
                // console.log(res)
                setLoading(false);
                if (res.code == 200) {
                    setTotal(res.data.total)
                    setData(pageIndex == 1 ? res.data.records : [...data, ...res.data.records]);
                    setPageIndex(pageIndex + 1)
                }
            }).catch(() => {
                setLoading(false);
            });
        }else if(status == 3){
            Api.getBusiness_withdrawallog(obj).then(res => {
                // console.log(res)
                setLoading(false);
                if (res.code == 200) {
                    setTotal(res.data.total)
                    setData(pageIndex == 1 ? res.data.records : [...data, ...res.data.records]);
                    setPageIndex(pageIndex + 1)
                }
            }).catch(() => {
                setLoading(false);
            });
        }
    };

    const sub = () => {
        let obj = {
            payeeName: name,
            payeeAccount: email,
            withdrawalAmount: amount,
        }
        Api.addWalletInsert(obj).then(res => {
            // console.log(res)
            if (res.code === 20012) {
                setScucess(true)
                getWallet()
                setApply(false)
            }
        })
    }

    useEffect(()=>{
        if(!isApply || !isSuccess){
            setAmount('')
            setEmail('')
            setName('')
        }
    },[isApply, isSuccess])


    const [startEndTime, setStartEndTime] = useState([])
    const changeTime = value=>{
        // console.log(value)
        setPageIndex(1)
        if(value){
            var from = moment(value[0]._d).format("YYYY-MM-DD") + ' 00:00:00'
            var to = moment(value[1]._d).format("YYYY-MM-DD") + ' 23:59:59'
            // console.log(from, to)
            setStartEndTime([from, to])
        }else{
            setStartEndTime([])
        }
    }


    useEffect(()=>{
        loadMoreData()
    },[status, startEndTime])



    const [amountPay, setAmountPay] = useState(0)
    const [payType, setPayType] = useState(0)
    useEffect(()=>{
        if(isPay){
            setBusiness_recharge()
        }
    },[payType])
    const [payload, setPayload] = useState(false)
    const interval = useRef()
    const [payUrl, setPayurl] = useState('')
    const [isPay, setIsPay] = useState(false)
    const [successVisible, setSuccessVisible] = useState(false)
    const setBusiness_recharge = ()=>{
        if(amountPay > 0){
            clearInterval(interval.current);
            setPayload(true)
            let obj = {
                paymentMode: payType == 1 ? 'Alipay' : 'WeChat', rechargePrice: amountPay
            }
            Api.setBusiness_recharge(obj).then(res=>{
                setPayload(false)
                if(res.code == 200){
                    setPayurl(res.data.url)
                    interval.current = setInterval(() => {
                        Api.getBusinessOrderStatus(res.data.order.outTradeNo).then(res => {
                            if(res.code == 200){
                                if (res.data == 1 || res.data == 22) {
                                    clearInterval(interval.current);
                                    setIsPay(false)
                                    getWallet()
                                    setAmountPay(0)
                                }
                            }else{
                                clearInterval(interval.current);
                            }
                        })
                    }, 1000)
                }
            })
        }
    }
    useEffect(()=>{
        setPayurl('')
        if(!isPay || !isSuccess) clearInterval(interval.current)
    },[isPay, isSuccess])

    const closeds = ()=>{
        setSuccessVisible(false)
        getWallet()
    }

    const [purposeList,setPurposeList] = useState([
        {val: '商品销售', id:4,icon:''},
        {val: '品牌传播', id:2, icon:''},
        {val: '商品种草', id:3, icon:''},
        {val: '商品好评', id:5,icon:''},
        {val: '开箱视频', id:6,icon:''},
    ])

    const [mediaList,setMediaList] = useState([
        {val: 'TikTok', id:1,icon:''},
        {val: 'Youtube', id:2, icon:''},
        {val: 'Facebook', id:3, icon:''},
        {val: 'Instagram', id:4,icon:''},
    ])

    return <div id='wallet' style={{ paddingBottom: '43px' }}>
        <nav style={{ width: '100%', position: 'relative', fontWeight: 500 }}>
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt=""
                style={{ width: '100%' }} />
            <div style={{
                width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt=""
                    style={{ width: '190px' }} />
                <div style={{
                    width: '10px', height: '10px', backgroundColor: 'black', borderRadius: '5px',
                    margin: '0 20px'
                }}></div>
                <span style={{ fontSize: '20px' }}>{intl.get('AccountRecharge')['账户充值']}</span>
            </div>
        </nav>

        <div style={{
            width: '100%', height: '180px', backgroundColor: '#FFF', textAlign: 'center',
            display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', padding: '0 30px'
        }}>
            <div style={{display:'flex', justifyContent:'center'}}>
                <div>
                    <span style={{ color: '#666666' }}>{intl.get('AccountRecharge')['当前账户余额']}</span>
                    <div style={{ fontWeight: 500, fontSize: '22px' }}>￥{walletObj.walletBalance || walletObj.walletBalanceCNY || 0}</div>
                </div>
                <div style={{marginLeft:'100px'}}>
                    <span style={{ color: '#666666' }}>{intl.get('AccountRecharge')['账户总支出']}</span>
                    <div style={{ fontWeight: 500, fontSize: '22px' }}>-￥{pTotals}</div>
                </div>
            </div>
            <div style={{ width: '1px', height: '130px', background: '#EEEEEE' }}></div>
            <div style={{display:'flex', justifyContent:'center'}}>
                <div style={{
                    width: '160px', height: '38px', borderRadius: '4px', cursor: 'pointer',
                    textAlign: 'center', lineHeight: '38px', color: '#FFF', margin: '16px auto',
                    background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                }}
                    onClick={() => setIsPay(true)}>
                    {intl.get('AccountRecharge')['充值']}
                </div>
                <div style={{
                    width: '160px', height: '38px', borderRadius: '4px', cursor: 'pointer',
                    textAlign: 'center', lineHeight: '34px', color: '#666', margin: '16px auto',
                    border: '1px solid #EEEEEE', fontWeight: 'blod', marginLeft:'27px'
                }}
                    onClick={() => setApply(true)}>
                    {intl.get('AccountRecharge')['申请退款']}
                </div>
            </div>
        </div>

        <div style={{ backgroundColor: '#fff', padding: '0px 31px', display: 'flex', justifyContent: 'space-between', 
        marginTop:'10px', alignItems:'center' }}>
            <div style={{ display: 'flex', padding: '11px 0' }}>
                {
                    statusList.map((item, index) =>
                        <div key={index} style={{
                            cursor: 'pointer', borderRadius: '5px', marginRight: lang=='zh'?'':'25px', padding:lang=='zh'?'':'0 10px',
                            height: '45px', lineHeight: '45px', width: lang=='zh'?'120px': '', minWidth:'50px', textAlign:'center',
                            color: status == item.id ? '#F7385B':'#888', background: status == item.id ? '#FFEEF1':'#fff'
                        }} onClick={e => changeTab(item)}>{item.text}</div>
                    )
                }
            </div>
            <div>
                <RangePicker onChange={changeTime} value={startEndTime.length>0?[moment(startEndTime[0], 'YYYY-MM-DD'), moment(startEndTime[1], 'YYYY/MM/DD HH:mm:ss')]:[]}
                    format="YYYY-MM-DD"
                    style={{width:'240px',height:'30px', borderRadius:'4px'}} />
            </div>
        </div>
        
        {/* 合作付款记录 */}
        {status == 1 ? <div style={{ width: '100%', minHeight:'400px', background: '#FFF', marginTop: '10px', padding: '26px 30px' }}>
            <div style={{ width: '100%', height: '50px', background: '#F6F6F6' }}>
                <div style={{
                    width: '100%', height: '100%', display: 'flex', alignItems: 'center'
                }}>
                    <div className='over' style={{ width: '25%', paddingLeft: '45px', paddingRight: '45px' }}>{intl.get('ReleaseDemand')['商品/品牌']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['需求目标']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['内容形式']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['媒体传播']}</div>
                    <div style={{ width: '20%' }}>{intl.get('AccountRecharge')['合作红人']}</div>
                    <div style={{ width: '15%' }}>{intl.get('AccountRecharge')['支付时间']}</div>
                    <div style={{ width: '10%' }}>{intl.get('ReleaseDemand')['佣金']}</div>
                </div>
            </div>
            
            {data?.length > 0 ? <Spin tip="数据加载中..." spinning={loading}>
                <InfiniteScroll
                    dataLength={data?.length} scrollThreshold={'20px'}
                    next={loadMoreData}
                    hasMore={data?.length < total}
                    // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                    endMessage={data.length > 10 ? <Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>:''}
                    // scrollableTarget="wallet"
                >
                    <List
                        dataSource={data} grid={{ gutter: 0, column: 1 }}
                        renderItem={(item, index) => <div key={index} style={{
                            width: '100%', height: '100px', display: 'flex', alignItems: 'center',
                            background: (index + 1) % 2 == 0 ? '#F6F6F6' : '#FFF'
                        }}>
                            <div className='over' style={{ width: '25%', paddingLeft: '45px', paddingRight: '45px' }}>{item.listName}</div>
                            <div style={{ width: '10%' }}>{purposeList.find(vm=>vm.id == item.target) ? purposeList.find(vm=>vm.id == item.target).val : '--'}</div>
                            <div style={{ width: '10%' }}>{item.cooperationWay == 1 ? intl.get('ReleaseDemand')['短视频'] : intl.get('ReleaseDemand')['直播']}</div>
                            <div style={{ width: '10%' }}>{mediaList.find(vm=>vm.id == item.media) ? mediaList.find(vm=>vm.id == item.media).val : '--'}</div>
                            <div style={{ width: '20%', display: 'flex', alignItems: 'center' }}>
                                <Image onClick={e => e.stopPropagation()} src={item.bloggerBriefInfo?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                                    style={{ width: '48px', height: '48px', borderRadius: '24px', marginRight: '16px' }} />
                                <div style={{ color: '#888888' }}>
                                    <div style={{
                                        color: '#333333', fontWeight: 'bold', fontSize: '14px',
                                        display: 'flex', alignItems: 'center'
                                    }}>
                                        <div className='over' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item.bloggerBriefInfo?.nickname}</div>
                                    </div>
                                    <span style={{fontSize:'12px'}}>TikTok: {item.bloggerBriefInfo?.tiktok}</span>
                                </div>
                            </div>
                            <div style={{ width: '15%' }}>{item.payFinishTime}</div>
                            <div style={{ width: '10%', color: '#F7385B' }}>- ￥{item.cost}</div>
                        </div>}
                    />
                </InfiniteScroll>
            </Spin> :
            <div style={{width:'345px', height:'200px', textAlign:'center', margin:'0 auto', marginTop:'100px' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                <div style={{marginTop:'24px', textAlign: 'center', color:'#333' }}>{'No Data'}</div>
            </div>}
        </div> : 
        // 充值记录
        status == 2 ? <div style={{ width: '100%', minHeight:'400px', background: '#FFF', marginTop: '10px', padding: '26px 30px' }}>
            <div style={{ width: '100%', height: '50px', background: '#F6F6F6' }}>
                <div style={{
                    width: '100%', height: '100%', display: 'flex', alignItems: 'center'
                }}>
                    <div className='over' style={{ width: '30%', paddingLeft: '45px', paddingRight: '45px' }}>{intl.get('AccountRecharge')['日期']}</div>
                    <div style={{ width: '30%' }}>{intl.get('AccountRecharge')['充值金额']}</div>
                    <div style={{ width: '30%' }}>{intl.get('AccountRecharge')['付款方式']}</div>
                </div>
            </div>
            
            {data?.length > 0 ? <Spin tip="数据加载中..." spinning={loading}>
                <InfiniteScroll
                    dataLength={data?.length} scrollThreshold={'20px'}
                    next={loadMoreData}
                    hasMore={data?.length < total}
                    // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                    endMessage={data.length > 10 ? <Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>:''}
                    // scrollableTarget="wallet"
                >
                    <List
                        dataSource={data} grid={{ gutter: 0, column: 1 }}
                        renderItem={(item, index) => <div key={index} style={{
                            width: '100%', height: '100px', display: 'flex', alignItems: 'center',
                            background: (index + 1) % 2 == 0 ? '#F6F6F6' : '#FFF'
                        }}>
                            <div className='over' style={{ width: '30%', paddingLeft: '45px', paddingRight: '45px' }}>{item.createTime}</div>
                            <div style={{ width: '30%', color:'#68C33B' }}>+ ￥{item.price}</div>
                            <div style={{ width: '30%' }}>{item.paymentMode > 0 ? payMethodObj[item.paymentMode] : '余额'}</div>
                        </div>}
                    />
                </InfiniteScroll>
            </Spin> :
            <div style={{width:'345px', height:'200px', textAlign:'center', margin:'0 auto', marginTop:'100px' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                <div style={{marginTop:'24px', textAlign: 'center', color:'#333' }}>{'No Data'}</div>
            </div>}
        </div> : 
        // 退款记录
        <div style={{ width: '100%', minHeight:'400px', background: '#FFF', marginTop: '10px', padding: '26px 30px' }}>
            <div style={{ width: '100%', height: '50px', background: '#F6F6F6' }}>
                <div style={{
                    width: '100%', height: '100%', display: 'flex', alignItems: 'center'
                }}>
                    <div className='over' style={{ width: '22%', paddingLeft: '45px', paddingRight: '45px' }}>{intl.get('AccountRecharge')['退款申请日期']}</div>
                    <div style={{ width: '10%' }}>{intl.get('AccountRecharge')['退款金额']}</div>
                    <div style={{ width: '15%' }}>{intl.get('AccountRecharge')['退款支付宝账户姓名']}</div>
                    <div style={{ width: '25%' }}>{intl.get('AccountRecharge')['退款支付宝账号']}</div>
                    <div style={{ width: '15%' }}>{intl.get('AccountRecharge')['退款进度']}</div>
                    <div style={{ width: '10%' }}>{intl.get('AccountRecharge')['备注']}</div>
                </div>
            </div>
            
            {data?.length > 0 ? <Spin tip="数据加载中..." spinning={loading}>
                <InfiniteScroll
                    dataLength={data?.length} scrollThreshold={'20px'}
                    next={loadMoreData}
                    hasMore={data?.length < total}
                    // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                    endMessage={data.length > 10 ? <Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>:''}
                    // scrollableTarget="wallet"
                >
                    <List
                        dataSource={data} grid={{ gutter: 0, column: 1 }}
                        renderItem={(item, index) => <div key={index} style={{
                            width: '100%', height: '100px', display: 'flex', alignItems: 'center',
                            background: (index + 1) % 2 == 0 ? '#F6F6F6' : '#FFF'
                        }}>
                            <div className='over' style={{ width: '22%', paddingLeft: '45px', paddingRight: '45px' }}>{item.createTime}</div>
                            <div style={{ width: '10%', color: '#F7385B' }}>￥{item.withdrawalAmount}</div>
                            <div style={{ width: '15%' }}>{item.payeeName}</div>
                            <div style={{ width: '25%' }}>{item.payeeAccount}</div>
                            <div style={{ width: '15%', color: item.withdrawalStatus == 1 ? '#FFC146' : item.withdrawalStatus == 2 ? '#F7385B' : item.withdrawalStatus == 3 ? '#68C33B' : '#333' }}>{item.withdrawalStatus == 1 ? textJson['处理中'] : item.withdrawalStatus == 2 ? textJson['已驳回'] : item.withdrawalStatus == 3 ? textJson['已到账'] : '--'}</div>
                            <div style={{ width: '10%' }}>{item.remark || '--'}</div>
                        </div>}
                    />
                </InfiniteScroll>
            </Spin> :
            <div style={{width:'345px', height:'200px', textAlign:'center', margin:'0 auto', marginTop:'100px' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                <div style={{marginTop:'24px', textAlign: 'center', color:'#333' }}>{'No Data'}</div>
            </div>}
        </div> }





        <Modal centered visible={isSuccess} footer={null} closable={false}
            getContainer={() => document.getElementById('wallet')}
            bodyStyle={{
                padding: '0', backgroundColor: '#FFFFFF',
                width: 443, height: 466,
            }}
        >
            <div style={{ textAlign: 'center', paddingTop: '55px' }}>
                <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{intl.get('Wallet')['退款申请提交成功']}</h3>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suc.png'} alt=""
                    style={{ width: '173px', marginTop: '40px' }} />
                <div style={{width:'70%', margin:'0 auto'}}>{intl.get('Wallet')['我们已收到你的退款申请，提现金额将在1～3个工作日到账，请耐心等待']}</div>
                <div style={{
                    width: '210px', height: '40px', borderRadius: '4px', cursor: 'pointer',
                    textAlign: 'center', lineHeight: '40px', color: '#FFF', margin: '38px auto',
                    background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                }}
                    onClick={() => setScucess(false)} >
                    {intl.get('LivingDemand')["我知道啦"]}
                </div>
            </div>
        </Modal>


        <Modal centered visible={isApply} footer={null} closable={false}
            getContainer={() => document.getElementById('wallet')}
            bodyStyle={{
                padding: '0', backgroundColor: '#FFFFFF',
                width: 554, 
            }}
        >
            <div style={{ textAlign: 'center', padding: '57px 105px' }}>
                <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{['申请退款']}</h3>
                <div style={{
                    color: '#666666', display: 'flex', alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginTop: '66px', fontWeight: 500
                }}>
                    {intl.get('AccountRecharge')['退款金额']}<span style={{ fontSize: '12px', color: '#888' }}>（{intl.get('AccountRecharge')['当前账户余额']}<span style={{ color: '#F7385B', fontSize: '14px', fontWeight: 'bold' }}>￥{walletObj.walletBalance || walletObj.walletBalanceCNY || 0}</span>）</span>
                </div>
                <div style={{ marginTop: '12px' }}>
                    <Input prefix="￥" value={amount} style={{ height: '48px', borderRadius: '4px' }} placeholder={intl.get('AccountRecharge')['请输入退款的金额']}
                        onChange={e => setAmount(e.target.value)} />
                </div>
                <div style={{
                    color: '#666666', display: 'flex', alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginTop: '30px', fontWeight: 500
                }}>
                    {intl.get('AccountRecharge')['退款支付宝账号']}
                </div>
                <div style={{ marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Input value={email} style={{ height: '48px', borderRadius: '4px' }} placeholder={intl.get('AccountRecharge')['请输入支付宝账号']}
                        onChange={e => setEmail(e.target.value)} />
                </div>
                <div style={{
                    color: '#666666', display: 'flex', alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginTop: '30px', fontWeight: 500
                }}>
                    {intl.get('AccountRecharge')['支付宝账号姓名']}
                </div>
                <div style={{ marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Input value={name} style={{ height: '48px', borderRadius: '4px' }} placeholder={intl.get('AccountRecharge')['请输入支付宝账号对应的真实姓名']}
                        onChange={e => setName(e.target.value)} />
                </div>
                <Button disabled={amount == '' || name == '' || email == ''} style={{
                    width: '210px', height: '40px', borderRadius: '4px', border: 0,
                    textAlign: 'center', color: '#FFF', margin: '0 auto', marginBottom: '12px',
                    background: amount == '' || name == '' || email == '' ? '#EEEEEE' : 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod', marginTop: '48px'
                }}
                    onClick={() => sub()} >
                    {intl.get('AccountRecharge')['确定申请']}
                </Button>
                <div style={{ fontSize: '12px', color: '#888888', marginTop: '10px' }}>
                    {intl.get('AccountRecharge')['本平台审核退款申请需1～3个工作日，请耐心等待']}
                </div>
                <CloseCircleOutlined style={{
                    position: 'absolute', right: '29px', top: '29px', fontSize: '20px', color: '#888888',
                    cursor: 'pointer'
                }}
                    onClick={() => setApply(false)} />
            </div>
        </Modal>




        <Modal visible={isPay} onCancel={e => { clearInterval(interval.current); setIsPay(false); setAmountPay(0) }} centered={true} footer={null} destroyOnClose={true}
        getContainer={() => document.getElementById('wallet')}>
            <Spin spinning={payload}>
                <div style={{ padding: '36px 70px', width: '642px' }}>
                    <div style={{ fontWeight: '800', fontSize: '18px', marginBottom: '43px', textAlign: 'center' }}>{intl.get('AccountRecharge')["账户充值"]}</div>
                    <div style={{flexShrink: '0', color: '#666666' }}>{intl.get('AccountRecharge')['充值金额']}</div>
                    <div style={{ marginTop: '12px' }}>
                        <Input prefix="￥" value={amountPay > 0 ? amountPay : ''} style={{ width:'244px', height: '48px', borderRadius: '4px' }} placeholder={intl.get('AccountRecharge')['请输入充值的金额']}
                            onChange={e => setAmountPay(e.target.value)} />
                    </div>
                    <div style={{flexShrink: '0', color: '#666666', marginTop:'30px' }}>{intl.get('AccountRecharge')['选择充值支付方式']}</div>
                    <div style={{ display: 'flex', justifyContent:'space-between', marginTop: '14px' }}>
                        <div style={{ width: '240px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: payType === 0 ? '1px solid #F7385B' : '1px solid #EEEEEE', borderRadius: '5px', position: 'relative', cursor: 'pointer' }} onClick={e => { setPayType(0) }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/wx.png'} style={{ marginRight: '11px' }} alt="" />
                            {intl.get('LivingDemand')["微信支付"]}
                            {payType === 0 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} alt='' style={{ position: 'absolute', bottom: '0', right: '0' }} /> : ''}
                        </div>
                        <div style={{ width: '240px', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: payType === 1 ? '1px solid #F7385B' : '1px solid #EEEEEE', borderRadius: '5px', position: 'relative', cursor: 'pointer' }} onClick={e => { setPayType(1) }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/zfb.png'} style={{ marginRight: '11px' }} alt="" />
                            {intl.get('LivingDemand')["支付宝支付"]}
                            {payType === 1 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} alt='' style={{ position: 'absolute', bottom: '0', right: '0' }} /> : ''}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '30px 0' }}>
                        {
                            payUrl ?
                                <QRCode
                                    id="bill_qr_code_url"
                                    value={payUrl} //value参数为生成二维码的链接 我这里是由后端返回
                                    size={200} //二维码的宽高尺寸
                                    fgColor="#000000"  //二维码的颜色
                                />
                                :
                                ''
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: payUrl?0:'70px', }}>
                        {!payUrl ? <div style={{
                            width: '210px', height: '40px', background: amountPay > 0 ? 'linear-gradient(180deg, #F7385B, #FF716E)' : '#EEE',
                            display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '14px',
                            borderRadius: '4px', cursor: 'pointer',
                        }} onClick={setBusiness_recharge}>
                            {intl.get('AccountRecharge')["确定充值"]}
                        </div>:''}
                        <div style={{ textAlign: 'center', width: '440px', fontSize: '12px', color: '#888', marginTop: '12px' }}>{intl.get('AccountRecharge')['如需开票请支付成功后联系客服，如遇到付款问题请联系客服']}</div>
                    </div>
                </div>
            </Spin>
        </Modal>



        <Modal visible={successVisible} onCancel={e => { setSuccessVisible(false) }} centered={true} footer={null} closable={false}
            getContainer={() => document.getElementById('wallet')}>
            <div style={{ padding: '16px', width: '443px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <div style={{ textAlign:'center', marginTop:'30px' }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suc.png'} style={{ width: 152 }} alt="" />
                    <div style={{ fontSize: '18px', fontWeight: '800' }}>{intl.get('AccountRecharge')['充值成功']}！</div>
                </div>
                
                <div style={{
                    width: '210px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '14px',
                    borderRadius: '4px', cursor: 'pointer', marginTop: '50px'
                }} onClick={closeds}>
                    {intl.get('LivingDemand')["我知道啦"]}
                </div>
            </div>
        </Modal>

    </div>
}
export default withRouter(AccountRecharge);