import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import { Image } from 'antd';
import { } from '@ant-design/icons';
import { baseUrl } from '../../../service/request'
import * as Api from '../../../service/https';
import '../../../asset/css/GoodsDetail.scss'
import intl from 'react-intl-universal';
import { useNavigate } from "react-router-dom";
import ImageMagnifier from "../../../components/ImageMagnifier"
import { } from '@datobs/react-image-magnifiers'
import openNotificationWithIcon from "../../../components/Notification";
import store from "../../../utils/redux";

const GoodsDetail = (props) => {
    let imglist = [
        'https://m.360buyimg.com/babel/jfs/t1/27264/39/17664/383575/62b95f49Eee4e5f58/56061f2231153326.jpg.webp',
        'https://m.360buyimg.com/babel/jfs/t1/201943/19/24986/382194/62d4cfb6E3c7a032d/bd7cd15f794c9565.jpg.webp'
    ]

    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);
    const [active_index, active_indexSet] = useState(0)
    const [shop_index, shop_indexSet] = useState(0)
    const [size_index, size_indexSet] = useState(0)
    const [goods_detail, goods_detailSet] = useState({})
    const [variations, variationsSet] = useState([])
    const [variation_list_index, variation_list_indexSet] = useState(-1)
    const [price, priceSet] = useState(0)
    const [quantity, quantitySet] = useState(-1)
    const [show_img, show_imgSet] = useState()

    useEffect(() => {
        getProduct()
    }, [])
    const [as, setAs] = useState()
    // const [thre, setThre] = useState()
    // useEffect(()=>{
    //     if(as){
    //         let list = []
    //         for (let i = 0; i < Object.keys(as).length; i++) {
    //             const element = Object.keys(as)[i];
    //             console.log(element)
    //             let obj = {}
    //             for (let index = 0; index < as[element].length; index++) {
    //                 const item = as[element][index];
    //                 if(!obj[item.var2]){
    //                     obj[item.var2] = [item.var3]
    //                 }else{
    //                     obj[item.var2].push(item.var3)
    //                 }
    //             }
    //             list[i] = obj
    //         }
    //         console.log(list)
    //         setThre(list)
    //     }
    // },[as])
    const getProduct = e => {
        Api.getProduct({ productId: props.params.id }).then(res => {
            if (res.code === 200) {
                // let obj = {}, list = []
                // for (let index = 0; index < res.data?.productDetail.variationList.length; index++) {
                //     const item = res.data?.productDetail.variationList[index];
                //     if(!obj[item.var1]){
                //         obj[item.var1] = [item]
                //     }else{
                //         obj[item.var1].push(item)
                //     }
                //     if(!obj[item.var2]){
                //         obj[item.var2] = [item]
                //     }else{
                //         obj[item.var2].push(item)
                //     }
                // }
                // console.log(obj)
                // setAs(obj)
                // setKeys(res.data?.productDetail.variationList[0].var1)
                goods_detailSet(res.data)
                let t = res.data?.productDetail?.variations.map((item) => { return 0 })
                console.log(t)
                variationsSet(t)
            }
        })
    }

    const indexChange = e => {
        active_indexSet(e)
    }
    const [idKeys, setIdKeys] = useState()
    const [keys, setKeys] = useState('m')
    const [var1Index, setVar1Index] = useState(0)
    const [var2Index, setVar2Index] = useState(0)
    const variationsChange = (i, i1, img) => {
        // setKeys(item.str)
        // console.log(as[item.str], var2Index)
        // quantitySet(as[item.str][0].quantity)
        // setVar1Index(i1)
        // setVar2Index(0)
        if (img) {
            show_imgSet(img)
            active_indexSet(0)
        }
        let t = JSON.parse(JSON.stringify(variations))
        t[i] = i1
        let s = t.map((item, index) => { return `${goods_detail?.productDetail?.variations[index].name}=${goods_detail?.productDetail?.variations[index].options[item].str}` }).join('&')
        // console.log(s)
        setIdKeys(s)
        let price = 0, quantity = 0
        goods_detail?.productDetail?.variationList.some((item, index) => {
            if (item.frontKey === s) {
                price = item.price
                quantity = item.quantity
                return true
            }
        })
        priceSet(price)
        quantitySet(quantity)
        variationsSet(t)
    }
    // const variationsChange2 = (i, i2, item) => {
    //     // setKeys(item.str)
    //     // quantitySet(as[item.str][i2].quantity)
    //     setVar2Index(i2)
    // }
    // const [var3Index, setVar3Index] = useState(0)
    // const variationsChange3 = (i, i3, item) => {
    //     setKeys(item.str)
    //     quantitySet(item.quantity)
    //     setVar3Index(i3)
    // }

    const [tabIndex,setTabIndex] = useState(0)
    const scrollToAnchor = (anchorName,index) => {
        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            // // 如果对应id的锚点存在，就跳转到锚点
            // if (anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth' }); }
            if (anchorElement) window.scrollTo({top:anchorElement.offsetTop,behavior: 'smooth'})
            setTabIndex(index)
        }
    }

    const toBusinessDetail = item => {
        window.open(`/index/BusinessDetail/${goods_detail?.enterpriseInfo.userIdMerchant}`)
    }


    const addCollection = (e) => {
        e.stopPropagation()
        let data = {
            productId: goods_detail.productDetail.productId
        }
        if (goods_detail.isCollect) {
            Api.cancelCollectProduct(data).then(res => {
                if (res.code === 200) {
                    openNotificationWithIcon('success', intl.get('GoodsLists')['取消成功！'])
                    let t = JSON.parse(JSON.stringify(goods_detail))
                    t.isCollect = !t.isCollect
                    goods_detailSet(t)
                }
            })
        } else {
            Api.collectProduct(data).then(res => {
                if (res.code === 200) {
                    openNotificationWithIcon('success', intl.get('GoodsRanking')['收藏成功！'])
                    let t = JSON.parse(JSON.stringify(goods_detail))
                    t.isCollect = !t.isCollect
                    goods_detailSet(t)
                }
            })
        }
    }

    const changeDistribution = (e) => {
        e.stopPropagation()
        let data = {
            productId: goods_detail.productDetail.productId
        }
        if (goods_detail.isDistribute) {
            Api.cancelDistributeProduct(data).then(res => {
                if (res.code === 200) {
                    openNotificationWithIcon('success', intl.get('GoodsLists')['取消加入带货清单'])
                    let t = JSON.parse(JSON.stringify(goods_detail))
                    t.isDistribute = !t.isDistribute
                    goods_detailSet(t)
                }
            })
        } else {
            Api.collectDistribute(data).then(res => {
                if (res.code === 200) {
                    openNotificationWithIcon('success', intl.get('GoodsLists')['加入带货清单'])
                    let t = JSON.parse(JSON.stringify(goods_detail))
                    t.isDistribute = !t.isDistribute
                    goods_detailSet(t)
                }
            })
        }
    }

    return (
        <div id='GoodsDetail'>
            <div className='top'>
                <div>
                    <div style={{ width: 350, height: 350, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {
                            active_index === -1 ?
                                <video width="350" height="350" controls>
                                    <source src={goods_detail?.productDetail?.videoUrl} />
                                    <source src={goods_detail?.productDetail?.videoUrl} />
                                    您的浏览器不支持 video 标签。
                                </video>
                                :
                                <ImageMagnifier
                                    minImg={show_img ? show_img : goods_detail?.productDetail?.productImgUrls[active_index]}
                                    maxImg={show_img ? show_img : goods_detail?.productDetail?.productImgUrls[active_index]}
                                    width={350}
                                    height={350}
                                />
                        }
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                        {
                            goods_detail?.productDetail?.videoUrl ?
                                <div className={`img_box ${active_index === -1 ? 'active' : ''}`} onClick={e => indexChange(-1)}>
                                    <img src={goods_detail?.productDetail?.videoUrl + '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,m_fast'} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                </div>
                                :
                                ""
                        }
                        {
                            goods_detail?.productDetail?.productImgUrls.map((item, index) => (
                                <div key={index} className={`img_box ${active_index === index ? 'active' : ''}`} onClick={e => indexChange(index)}>
                                    <img src={item} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div style={{ flex: '1' }}>
                    <div style={{ marginLeft: '30px' }}>
                        <div style={{ fontSize: '16px', fontWeight: '800' }}>{goods_detail?.productDetail?.productName}</div>
                        <div style={{ display: 'flex', marginTop: '5px', fontSize: '12px' }}>
                            <div style={{ color: '#F7385B', backgroundColor: '#FFEEF1', border: '1px solid #F7385B', padding: '0px 6px', borderRadius: '5px' }}>{intl.get('GoodsDetail')['编号：']}{goods_detail?.productDetail?.productNo}</div>
                            <div style={{ marginLeft: '8px' }}>
                                {
                                    goods_detail?.productDetail?.categoryNameList.map((item, index) => (item.val + ((index + 1) === goods_detail?.productDetail?.categoryNameList.length ? '' : '/')))
                                }
                            </div>
                        </div>
                        <div>
                            <div style={{ width: '70%', borderTop: '1px #eee solid', margin: '20px 0' }}></div>
                        </div>
                        <div style={{ display: 'flex', lineHeight: '16px' }}>
                            <div style={{ width: 110, color: '#666' }}>{intl.get('GoodsDetail')['售价']}</div>
                            <div style={{ color: '#F7385B', fontSize: '20px', fontWeight: '800' }}>${price ? price : goods_detail.productPrice}</div>
                        </div>
                        <div>
                            <div style={{ width: '70%', borderTop: '1px #eee solid', margin: '20px 0' }}></div>
                        </div>
                        {/* {
                            goods_detail?.productDetail?.variations.map((item, index) => (
                                <div key={index}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: 110, color: '#666', lineHeight: '36px' }}>{item.name}</div>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {   
                                                index == 0 ? item?.options?.map((item1, index1) => (
                                                    item1.img ?
                                                        <div key={index1} className={`img_box ${var1Index === index1 ? 'active' : ''}`} style={{ width: 35, height: 35, marginRight: '10px' }} onClick={e => variationsChange(index, index1, item1)}>
                                                            <img src={item1.img} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                        </div>
                                                        :
                                                        <div key={index1} className={`size_box ${var1Index === index1 ? 'size_box_active' : ''}`} onClick={e => variationsChange(index, index1, item1)}>{item1?.str}</div>
                                                )) :
                                                index == 1 ? as[keys]?.map((item2, index2) => (
                                                    <div key={index2} className={`size_box ${var2Index === index2 ? 'size_box_active' : ''}`} onClick={e => variationsChange2(index, index2, item2)}>{item2?.var2}</div>
                                                )) : 
                                                thre[keys]?.map((item3, index3) => (
                                                    <div key={index3} className={`size_box ${var3Index === index3 ? 'size_box_active' : ''}`} onClick={e => variationsChange3(index, index3, item3)}>{item3?.var3}</div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ width: '70%', borderTop: '1px #eee solid', margin: '20px 0' }}></div>
                                    </div>
                                </div>
                            ))
                        } */}
                        {
                            goods_detail?.productDetail?.variations.map((item, index) => (
                                <div key={index}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: 110, color: '#666', lineHeight: '36px' }}>{item.name}</div>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {
                                                item?.options?.map((item2, index2) => (
                                                    item2.img ?
                                                        <div key={index2} className={`img_box ${variations[index] === index2 && quantity > 0 ? 'active' : variations[index] === index2 && quantity == 0 ? 'No_active' : ''}`} style={{ width: 35, height: 35, marginRight: '10px' }} onClick={e => variationsChange(index, index2, item2.img)}>
                                                            <img src={item2.img} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                        </div>
                                                        :
                                                        <div key={index2} className={`size_box ${variations[index] === index2 && quantity > 0 ? 'size_box_active' : variations[index] === index2 && quantity == 0 ? 'No_active' : ''}`} onClick={e => variationsChange(index, index2)}>{item2.str}</div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ width: '70%', borderTop: '1px #eee solid', margin: '20px 0' }}></div>
                                    </div>
                                </div>
                            ))
                        }
                        <div style={{ display: 'flex', lineHeight: '16px', marginTop: '20px' }}>
                            <div style={{ width: 110, color: '#666' }}>{intl.get('GoodsDetail')['库存']}</div>
                            <div>{ quantity > -1 ? quantity : goods_detail.productQuantity}{intl.get('GoodsDetail')['件']}</div>
                        </div>
                        {/* <div style={{ display: 'flex', lineHeight: '16px', marginTop: '20px' }}>
                            <div style={{ width: 110, color: '#666' }}>{intl.get('GoodsDetail')['商品重量']}</div>
                            <div style={{ fontWeight: '500' }}>435克</div>
                        </div>
                        <div style={{ display: 'flex', lineHeight: '16px', marginTop: '20px' }}>
                            <div style={{ width: 110, color: '#666' }}>{intl.get('GoodsDetail')['商品尺寸']}</div>
                            <div style={{ fontWeight: '500' }}>长35cm * 宽35cm * 高35cm</div>
                        </div> */}
                    </div>
                    <div className='flex-ac' style={{ marginLeft: 140, marginTop:100 }}>
                        <div style={{padding:'0 40px',height:'38px', background: goods_detail?.isCollect?'linear-gradient(180deg, #F7385B, #FF716E)':'#FFF',
                            display:'flex', justifyContent:'center', alignItems:'center', color: goods_detail?.isCollect?'#FFF':'#888888', fontSize:'12px',
                            borderRadius:'4px', cursor:'pointer', border: goods_detail?.isCollect?0:'1px solid #EEEEEE', marginTop:'10px'}}
                            onClick={addCollection}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/shoucang'+(goods_detail?.isCollect?'':'s')+'.png'} alt=""
                                 style={{width:'14px', marginRight: '4px',}}/>{goods_detail?.isCollect?intl.get('GoodsLists')['取消收藏']:intl.get('GoodsLists')['收藏商品']}
                        </div>
                        {
                            state.userInfo?.userType == 2 ? <div style={{marginLeft:16, padding:'0 40px',height:'38px', background: goods_detail?.isDistribute?'linear-gradient(180deg, #F7385B, #FF716E)':'#FFF',
                            display:'flex', justifyContent:'center', alignItems:'center', color: goods_detail?.isDistribute?'#FFF':'#888888', fontSize:'12px',
                            borderRadius:'4px', cursor:'pointer', border: goods_detail?.isDistribute?0:'1px solid #EEEEEE', marginTop:'10px'}}
                            onClick={changeDistribution}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/daihuo/daihuo'+(goods_detail?.isDistribute?'s':'')+'.png'} alt=""
                                 style={{width:'28px', marginRight: '4px',}}/>{goods_detail?.isDistribute?intl.get('GoodsLists')['取消加入带货清单']:intl.get('GoodsLists')['加入带货清单']}
                            </div> : ''
                        }
                    </div>
                </div>
            </div>
            <div className='bottom'>
                <div className='sd-title'>{intl.get('GoodsDetail')['商家信息']}</div>
                <div style={{ padding: '42px 5%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{  minWidth:'72px', width: 72, height: 72, borderRadius: '72px', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Image src={goods_detail?.enterpriseInfo?.logo} preview={false} />
                    </div>
                    <div style={{ marginLeft: '2%' }}>
                        <div style={{ fontSize: '16px', fontWeight: '800' }}>{goods_detail?.enterpriseInfo?.name}</div>
                        <div style={{ color: '#F7385B', background: '#FFEEF1', borderRadius: '100px', padding: '3px 10px', fontSize: '13px', marginTop: '5px' }}>{intl.get('GoodsLists')['主营行业类型：']}{goods_detail?.enterpriseInfo?.industryType}</div>
                    </div>
                    <div style={{ borderLeft: '1px solid #eee', height: '70px', marginLeft: '4%' }}></div>
                    <div style={{ minWidth:'60px', textAlign: 'center', marginLeft: '5%' }}>
                        <div style={{ fontSize: '16px', fontWeight: '800' }}>
                            {
                                goods_detail?.enterpriseInfo?.examineType === 2 ?
                                    <div style={{ flex: 1 }}>{intl.get('BusinessDetail')['已认证']}<img src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/mygoods/shenfenrenzheng.png' style={{ marginTop: '-4px', marginLeft: '4px' }} /></div>
                                    :
                                    <div style={{ flex: 1 }}>{intl.get('BusinessDetail')['未认证']}</div>
                            }
                        </div>
                        <div style={{ fontSize: '13px', color: '#888' }}>{intl.get('BusinessDetail')['商家认证']}</div>
                    </div>
                    {/* <div style={{ textAlign: 'center', marginLeft: '6%' }}>
                        <div style={{ fontSize: '16px', fontWeight: '800' }}>5年</div>
                        <div style={{ fontSize: '13px', color: '#888' }}>经营年限</div>
                    </div> */}
                    <div style={{ textAlign: 'center', marginLeft: '6%' }}>
                        <div style={{ minWidth:'220px', fontSize: '16px', fontWeight: '800' }}>{goods_detail?.enterpriseInfo?.market}</div>
                        <div style={{ fontSize: '13px', color: '#888' }}>{intl.get('BusinessDetail')['主营地区']}</div>
                    </div>
                    <div style={{ borderLeft: '1px solid #eee', height: '70px', marginLeft: '4%' }}></div>
                    <div style={{ minWidth:'135px', color: '#fff', marginLeft: '5%', background: 'linear-gradient(180deg, #F7385B, #FF716E)', padding: '8px 15px', borderRadius: '5px', cursor: 'pointer' }} onClick={e => toBusinessDetail()}>
                        <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/mygoods/zhuye.png" style={{ marginTop: '-4px', marginRight: '5px' }} />{intl.get('GoodsDetail')["查看商家主页"]}
                    </div>
                </div>
                <div className='shop_tabs'>
                    <div>
                        <div className={tabIndex == 0 ? 'shop_tabs_active' : ''} onClick={e => scrollToAnchor('tabs1',0)}>{intl.get('GoodsDetail')["商品规格参数"]}</div>
                        <div className={tabIndex == 1 ? 'shop_tabs_active' : ''} onClick={e => scrollToAnchor('tabs2',1)}>{intl.get('GoodsDetail')["商品详情介绍"]}</div>
                    </div>
                </div>
                <div style={{ width: '1133px', margin: '0 auto', marginTop: 40 }}>
                    <div id='tabs1' style={{ fontSize: 16, fontWeight: 800 }}>{intl.get('GoodsDetail')["商品规格参数"]}</div>
                    <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', padding: '33px 135px', background: '#F6F6F6', marginTop: '20px' }}>
                        {
                            goods_detail?.productDetail?.productAttributes?.map((item, index) => (
                                <div key={index} style={{ width: '50%', display: 'flex', marginBottom: '15px' }}>
                                    <div style={{ width: '120px', color: '#888' }}>{item.attribute}:</div>
                                    <div style={{ color: '#333' }}>
                                        {
                                            item?.selectList?.map((item1, index1) => { return `${item1.val}${(index1 + 1) === item.selectList.length ? '' : ','}` })
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div id='tabs2' style={{ fontSize: 16, fontWeight: 800, marginTop: 50 }}>{intl.get('GoodsDetail')['商品详情介绍']}</div>
                    <div dangerouslySetInnerHTML={{ __html: goods_detail?.productDetail?.productDescription }}></div>
                    {/* <div style={{ fontSize: 16, fontWeight: 800, marginTop: 50 }}>售后质保与政策</div>
                    <div style={{ padding: '52px 36px' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: 125 }}>保修期：</div>
                            <div>3年</div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '15px' }}>
                            <div style={{ width: 125 }}>保修政策：</div>
                            <div style={{ maxWidth: '620px' }}>添加商品时填写的保修政策的内容展示在这里，添加商品时填写的保修政策的内容展示在这里， 添加商品时填写的保修政策的内容展示在这里</div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default withRouter(GoodsDetail);