import React, {useState, useEffect} from 'react'
import withRouter from '../../utils/withRouter'
import intl from "react-intl-universal";

const GoodsRankingInfo = (props) => {
    const goods_detail = props.goods_detail
    const [skuIndex, setSkuIndex] = useState(0)

    return <div>
        <div style={{width: '1133px', padding: '23px 37px', background: '#FFF',}}>
            {/*<div id='tabs1' style={{ fontSize: 16, fontWeight: 800 }}>{intl.get('GoodsRankingDetail')["商品规格参数"]}</div>*/}
            {/*<div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', padding: '33px 135px', background: '#F6F6F6', marginTop: '20px' }}>*/}
            {/*    {*/}
            {/*        goods_detail?.productDetail?.skus?.map((item, index) => (*/}
            {/*            <div key={index} style={{ width: '50%', display: 'flex', marginBottom: '15px' }}>*/}
            {/*                <div style={{ width: '120px', color: '#888' }}>{item}:</div>*/}
            {/*                <div style={{ color: '#333' }}>*/}
            {/*                    {*/}
            {/*                        item?.selectList?.map((item1, index1) => { return `${item1.val}${(index1 + 1) === item.selectList.length ? '' : ','}` })*/}
            {/*                    }*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        ))*/}
            {/*    }*/}
            {/*</div>*/}
            <div id='tabs2' style={{fontSize: 16, fontWeight: 800,marginBottom:20}}>{intl.get('GoodsRankingDetail')['商品详情介绍']}</div>
            {/*<div dangerouslySetInnerHTML={{ __html: goods_detail?.productBase?.descDetailList }}></div>*/}
            {
                goods_detail?.productBase?.descDetailList.map((item, index) => {
                    return <div  key={index}>
                        {/*{item.type === 'text' ? <div>{item.text}</div> :<img style={{display: 'block', maxWidth: '100%'}} src={item.text} alt=""/>}*/}

                        {
                            item.type === 'ul' && item.content && item.content.length > 0 ? <ul style={{padding:'0 20px'}}>
                                {
                                    item.content.map(item=>{
                                        return <li>{item}</li>
                                    })
                                }
                                </ul>
                            : item.type === 'image' ? <img style={{display: 'block', maxWidth: '100%',margin:'0 auto'}} src={item?.image?.uri || ''} alt=""/>
                                : <div>{item.text}</div>
                        }
                    </div>
                })
            }
            <div style={{fontSize: 16, fontWeight: 800, marginTop: 50,marginBottom:20}}>{intl.get('GoodsRankingDetail')['商品政策']}</div>
            {
               goods_detail?.shopPolicies ? goods_detail.shopPolicies.map((item, index) => {
                    return <div key={index}>
                        <div>{item.name}</div><div>{item.description}</div>
                    </div>
                }) : ''
            }
        </div>
    </div>
}
export default withRouter(GoodsRankingInfo);