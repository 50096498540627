import React, { useState, useEffect, memo } from 'react'
import withRouter from '../../../utils/withRouter'
import '../../../asset/css/BloggerRanking.scss'
import '../../../asset/css/SensationDetail.scss'
import { CalendarOutlined } from '@ant-design/icons';
import { Select, Checkbox, List, notification, Spin, Skeleton, Divider, Image, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../../utils/redux/index'
import domtoimage from 'dom-to-image';
import intl from 'react-intl-universal';
import * as Api from '../../../service/https'
import toKMB from '../../../utils/KMB'
import { Area } from '@ant-design/plots';
import moment from 'moment';
import geolocation from '../../../utils/geolocation'
import ReactEcharts from 'echarts-for-react';
import ChildComp from '../../../components/Sensation/res'
import ExcellentWorks from "../../../components/Sensation/ExcellentWorks";


const { Option } = Select;

const SensationDetail = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [textJson, setTextJson] = useState(intl.get('Index'))
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）

    const [widths, setWidths] = useState(0)

    const bloggerId = props.params.bloggerId

    const openNotificationWithIcon = (type, message = '') => {
        notification[type]({
            description: message, top: 100, duration: 3
        });
    };

    useEffect(() => {
        store.subscribe(storeChange);
        // console.log(props)
        getBloggerDetail()
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
        }
    }, [])

    const storeChange = () => {
        setSate(store.getState())
    };

    const [bloggerDetail, setBloggerDetal] = useState()
    const [tiktok, setTikTok] = useState()
    const getBloggerDetail = () => {
        Api.redAccount({ id: props.params.bloggerId }).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setBloggerDetal(res.data)
                setTikTok(res.data.tiktok)
                getIsCollect(res.data.id)
            }
        })
    }
    const [isCollect, setIsCollect] = useState(false)
    const getIsCollect = (id) => {
        Api.getIsBloggerCollect({ bloggerUserId: id }).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setIsCollect(res.data)
            }
        })
    }

    const [left, setLeft] = useState(250)

    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(width)
        let num = width > 1836 ? 2 : 2
        if (state.collapsed) {
            setLeft(98)
        } else {
            setLeft(250)
        }
        setWidths(num)
    }
    useEffect(() => {
        heightFun()
    }, [state.collapsed])

    const [sortList, setSortList] = useState([
        intl.get('BloggerDetail')['发布时间'],
        intl.get('TopicList').PlaysNum,
        intl.get('BloggerRanking').LikesNum,
        intl.get('TopicList').CommentsNum,
        intl.get('TopicList').SharesNum
    ]);

    const [sortIndex, setSortIndex] = useState(0);
    const sortMethod = (index) => {
        setPageIndex(0)
        setSortIndex(index)
    }

    useEffect(() => {
        if (tiktok)
            loadMoreData()
    }, [sortIndex, tiktok])

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            authorId: tiktok,
            bloggerVideoSortRuler: sortIndex + 1,
            from: pageIndex * 10, size: 10
        }
        Api.getBloggerAllVideo(obj).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setTotal(res.data.total)
                setData(pageIndex == 0 ? res.data.data : [...data, ...res.data.data]);
                setPageIndex(pageIndex + 1)
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
    };




    const palyVideo = (e, item, index) => {
        e.stopPropagation();
        // console.log('collection', index)
        window.open(`https://www.tiktok.com/@${item.elasticSearchUser.nickname}/video/${item.video.id}`)
    }

    // 别表
    const SmallDiv = (item, index) => {
        return <div key={index} style={{
            width: '100%', height: '150px', display: 'flex', alignItems: 'center',
            backgroundColor: index % 2 == 0 ? '#FFFFFF' : '#F6F6F6', cursor: 'pointer'
        }}
            onClick={() => detail(item, index)}>

            <div style={{ width: '10%', paddingLeft: '19px' }}>
                {index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png'} alt=""
                    style={{}} /> :
                    <div style={{ width: '32px', textAlign: 'center', fontWeight: 500 }}>{index + 1}</div>
                }
            </div>
            <div style={{ width: '42%', display: 'flex' }}>
                <div style={{
                    width: '80px', height: '110px', backgroundImage: 'url(' + item.video.cover + ')',
                    backgroundSize: 'cover', borderRadius: '4px', backgroundRepeat: 'no-repeat'
                }}>
                    <div style={{
                        width: '100%', height: '100%', background: 'rgba(0,0,0,0.2)', color: '#FFF',
                        textAlign: 'center', paddingTop: '86px', borderRadius: '4px', fontSize: '13px'
                    }}>
                        <span>{Math.floor(item.video.duration / 60)}:{(item.video.duration % 60) < 10 ? '0' : ''}{item.video.duration % 60}</span>
                    </div>
                </div>
                <div className='videoList' style={{
                    width: 'calc(100% - 80px - 85px)', paddingLeft: '15px',
                    color: '#888888'
                }}>
                    <div className='over' style={{
                        fontSize: '16px', fontWeight: 'bold', lineHeight: '20px',
                        color: '#333', paddingTop: '2px'
                    }}>{item.desc}</div>
                    <div className='over1' style={{ height: '20px', marginTop: '8px' }}>
                        {
                            item.challenges?.map((item, index) => {
                                return <span key={index} style={{ marginRight: '5px' }}>#{item.title}</span>
                            })
                        }
                    </div>
                    <div style={{ marginTop: '13px' }}>{intl.get('BloggerDetail')['发布时间']}：{moment(item.createTime * 1000).format('YYYY-MM-DD')}</div>
                </div>
            </div>
            <div style={{ width: '9%' }}>{toKMB(item.playCount, 2)}</div>
            <div style={{ width: '9%' }}>{toKMB(item.commentCount, 2)}</div>
            <div style={{ width: '9%' }}>{toKMB(item.diggCount, 2)}</div>
            <div style={{ width: '9%' }}>{toKMB(item.shareCount, 2)}</div>
            <div style={{ width: '' }}>
                <Tooltip placement="top" title={<span style={{ fontSize: '12px' }}>{intl.get('VideoDetail')['播放视频']}</span>}>
                    <img onClick={(e) => palyVideo(e, item, index)}
                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/play_grey.png'} style={{ marginRight: '9px' }} alt="" />
                </Tooltip>
                {/* <img onClick={(e)=>addCollection(e, item,index)} 
                    src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc.png'} alt="" /> */}
            </div>
        </div>
    }
    // 大图列表
    const BigDiv = (item, index) => {
        return <div style={{
            width: '100%', height: '208px', backgroundColor: '#FFF', margin: '0 auto',
            marginBottom: '16px', paddingRight: '16px',
        }}
            onClick={() => detail(item, index)}>

            <div style={{
                width: '100%', height: '100%', padding: '14px', border: '1px solid #EEEEEE', borderRadius: '4px',
                display: 'flex', cursor: 'pointer'
            }}>
                <div style={{
                    width: '130px', height: '180px', backgroundImage: 'url(' + item.video.cover + ')',
                    backgroundSize: 'cover', borderRadius: '4px',
                }}>
                    <div style={{
                        width: '100%', height: '100%', background: 'rgba(0,0,0,0.2)', color: '#FFF',
                        textAlign: 'center', paddingTop: '150px', borderRadius: '4px'
                    }}>
                        <span>{Math.floor(item.video.duration / 60)}:{(item.video.duration % 60) < 10 ? '0' : ''}{item.video.duration % 60}</span>
                    </div>
                </div>
                <div style={{ width: 'calc(100% - 130px)', paddingLeft: '13px', paddingTop: '6px' }}>
                    <div className='over' style={{ height: '40px', fontSize: '16px', fontWeight: 'bold', lineHeight: '20px' }}>
                        {item.desc}
                    </div>
                    <div style={{
                        color: '#888888', marginTop: '5px',
                        wordBreak: 'break-all',
                        textOverflow: 'ellipsis', overflow: 'hidden',
                        display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical'
                    }}>
                        {
                            item.challenges?.map((item, index) => {
                                return <span key={index}>#{item.title}</span>
                            })
                        }
                    </div>
                    <div style={{
                        width: '100%', color: '#888888', marginTop: '20px',
                        textAlign: 'center', display: 'flex', alignItems: 'center', flexWrap: 'wrap', height: 44
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px', width: 67 }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/playCircleL.png'} alt=""
                                style={{ width: '14px', marginRight: '4px', }} /> {toKMB(item.playCount, 2)}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px', width: 67 }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hanhan.png'} alt=""
                                style={{ width: '14px', marginRight: '4px', }} /> {toKMB(item.diggCount, 2)}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px', width: 67 }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pinglun.png'} alt=""
                                style={{ width: '14px', marginRight: '4px', }} /> {toKMB(item.commentCount, 2)}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', width: 75 }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/fenxiang.png'} alt=""
                                style={{ width: '14px', marginRight: '4px', }} /> {toKMB(item.shareCount, 2)}
                        </div>
                    </div>
                    <div style={{ marginTop: '8px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span style={{ color: '#888888' }}>{moment(item.createTime * 1000).format('YYYY-MM-DD')}</span>
                        <div style={{ display: 'flex' }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/play_grey.png'} style={{ marginRight: '10px' }} alt="" />
                            {/* <img onClick={(e) => addCollection(e, item, index)}
                                src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc.png'} alt="" /> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    }

    const detail = (item, index) => {
        // console.log('detail',index)
        window.open(`https://www.tiktok.com/@${bloggerId}/video/${item.video.id}`)
    }

    const addCollection = (e, item, index) => {
        e.stopPropagation();
        // console.log('collection',index)
    }

    const [isAll, setIsAll] = useState(false)
    const changeAll = (e) => {
        setIsAll(e)
    }

    const [summary, setSummary] = useState(1)
    const [tabIndex, setTabIndex] = useState(1)
    const tabChange = e => {
        setTabIndex(e)
        if (e == 2) {
            Api.getRedSummary({ filterDays: 1, uniqueId: props.params.tiktok }).then(res => {
                if (res.code == 200) {
                    setSummary(res.data)
                }
            })

            getDatas()
            getAllDatas()
            getWord()
        }
    }

    const [choseList, setChoseList] = useState([7, 7, 7, 7])
    const [tagList, setTagList] = useState([1, 1])
    const chooseChange = (e, i) => {
        let t = JSON.parse(JSON.stringify(choseList))
        t[i] = e
        setChoseList(t)
    }

    const tagListChange = (e, i) => {
        let t = JSON.parse(JSON.stringify(tagList))
        t[i] = e
        setTagList(t)
    }


    const config = {
        data: [],
        color: '#F7385B',
        autoFit: true,
        areaStyle: () => {
            return {
                fill: 'l(270) 0:#FFFFFF 1:#F7385B',
            };
        },
        xField: 'date',
        yField: 'count',
        xAxis: {
            range: [0, 1],
        },
        tooltip: {
            formatter: (datum) => {
                return {
                    name: tagList[0] == 1 ? intl.get('TopicList').PlaysNum : tagList[0] == 2 ? intl.get('TopicList').LikesNum : tagList[0] == 3 ? intl.get('TopicList').CommentsNum : intl.get('TopicList').SharesNum,
                    value: toKMB(datum.count, 2)
                };
            },
        }
    };
    const [configV, setConfigV] = useState(config)
    const [videoData, setVideoData] = useState()

    const configsF = {
        data: [],
        height: 250,
        color: '#6FC644',
        areaStyle: () => {
            return {
                fill: 'l(270) 0:#FFFFFF 1:#6FC644',
            };
        },
        xField: 'date',
        yField: 'count',
        xAxis: {
            range: [0, 1],
        },
        tooltip: {
            formatter: (datum) => {
                return {
                    name: tagList[1] == 1 ? '总量' : '增量',
                    value: toKMB(datum.count, 2)
                };
            },
        }
    };
    const [configF, setConfigF] = useState(configsF)
    const [fansData, setFansData] = useState()


    useEffect(() => {
        if (tabIndex == 2) getDatas()
    }, [choseList[1]])
    useEffect(() => {
        if (tabIndex == 2) {
            let data = tagList[0] == 1 ? videoData.playCountMap.playCountList : tagList[0] == 2 ? videoData.diggCountMap.diggCountList :
                tagList[0] == 3 ? videoData.commentCountMap.commentCountList : tagList[0] == 4 ? videoData.shareCountMap.shareCountList : {}
            config.data = data
            setConfigV(config)
        }
    }, [tagList[0]])

    useEffect(() => {
        if (tabIndex == 2) getAllDatas()
    }, [choseList[2]])
    useEffect(() => {
        if (tabIndex == 2) {
            let data = tagList[1] == 1 ? fansData.fansTotalMap.fansTotalList : fansData.fansIncrementMap.fansIncrementList
            configsF.data = data
            setConfigF(configsF)
        }
    }, [tagList[1]])


    const getDatas = () => {
        let obj = {
            videoTimeScope: choseList[1] == 7 ? 5 : choseList[1] == 15 ? 6 : choseList[1] == 30 ? 7 : choseList[1] == 90 ? 8 : 5,
            uniqueId: props.params.tiktok
        }
        Api.getRedAnalysis(obj).then(res => {
            if (res.code == 200) {
                setVideoData(res.data)
                let data = tagList[0] == 1 ? res.data.playCountMap.playCountList : tagList[0] == 2 ? res.data.diggCountMap.diggCountList :
                    tagList[0] == 3 ? res.data.commentCountMap.commentCountList : tagList[0] == 4 ? res.data.shareCountMap.shareCountList : {}
                config.data = data
                setConfigV(config)
            }
        })
    }


    const getAllDatas = () => {
        let obj = {
            videoTimeScope: choseList[1] == 7 ? 5 : choseList[1] == 15 ? 6 : choseList[1] == 30 ? 7 : choseList[1] == 90 ? 8 : 5,
            uniqueId: props.params.tiktok
        }
        Api.getRedFansAnalysis(obj).then(res => {
            if (res.code == 200) {
                setFansData(res.data)
                let data = tagList[1] == 1 ? res.data.fansTotalMap.fansTotalList : res.data.fansIncrementMap.fansIncrementList
                configsF.data = data
                setConfigF(configsF)
            }
        })
    }

    const optionWs = {
        series: [
            {
                type: 'wordCloud', size: ['100%', '100%'],
                gridSize: 2,
                sizeRange: [12, 30],
                rotationRange: [0, 90],
                shape: 'square',
                drawOutOfBound: true,
                textStyle: {
                    color: () => {
                        return (
                            'rgb(' +
                            [
                                Math.round(Math.random() * 255),
                                Math.round(Math.random() * 255),
                                Math.round(Math.random() * 255),
                            ].join(',') +
                            ')'
                        );
                    },
                    fontWeight: 'bold',
                    emphasis: {
                        shadowBlur: 10,
                        shadowColor: '#333',
                    },
                },
                data: [],
            },
        ],
    };
    const [optionW, setOptionW] = useState(optionWs)

    const getWord = () => {
        let obj = {
            uniqueId: props.params.tiktok
        }
        Api.getRedWordAnalysis(obj).then(res => {
            if (res.code == 200) {
                let list = []
                res.data.tags?.map((item, index) => {
                    list.push({ name: item.title, value: item.count })
                })
                optionWs.series[0].data = list
                setOptionW(optionWs)
            }
        })
    }


    return bloggerDetail ? <div id="SensationDetail" style={{ paddingBottom: '50px' }}>
        <div className='information'>

            {/* 博主信息卡 */}
            <div className='info-box' style={{ position: 'fixed', top: '86px', left: left, transition: 'all 0.2s' }}>
                <div className='info-tips-box'>
                    <div className='info-tips'>
                        {textJson['红人信息']}
                    </div>
                </div>
                <div style={{ width: '300px', minWidth: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={bloggerDetail.avatarMedium} alt=""
                        style={{ width: '100px', height: '100px', borderRadius: '50px' }} />
                    <div style={{ color: '#888888', textAlign: 'center' }}>
                        <div style={{
                            color: '#333333', fontWeight: 'bold', fontSize: '16px',
                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                            <div className='over' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>
                                {bloggerDetail.nickname}
                            </div>
                            {
                                bloggerDetail.verified ?
                                    <img style={{ marginLeft: '3px', width: '14px', height: '14px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} />
                                    :
                                    ''
                            }
                        </div>
                        TikTok: {bloggerDetail.tiktok}
                    </div>
                    <div className='target-list'>
                        {
                            (lang=='zh'?bloggerDetail.cargoClassificationInterpretation:bloggerDetail.cargoClassificationInterpretationEn).split(',')?.map((item, index) => {
                                if (index < 3) {
                                    return <div className='over1' key={index}>{item}</div>
                                }
                            })
                        }
                    </div>
                    <div style={{ width: '100%', marginTop: 34, textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
                        <div>
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(bloggerDetail.followerCount, 2)}</div>
                            <div style={{ textAlign: 'center', color: '#888888', marginTop: '5px' }}>
                                {intl.get('BloggerRanking').FansNum}
                            </div>
                        </div>
                        <Divider type="vertical" style={{ height: 35 }} />
                        <div>
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>${bloggerDetail.videoQuotationDollar}/60s</div>
                            <div style={{ textAlign: 'center', color: '#888888', marginTop: '5px' }}>
                                {textJson['视频单价']}
                            </div>
                        </div>
                        <Divider type="vertical" style={{ height: 35 }} />
                        <div>
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>${bloggerDetail.liveBroadcastQuotationDollar}/h</div>
                            <div style={{ textAlign: 'center', color: '#888888', marginTop: '5px' }}>
                                {textJson['直播单价']}
                            </div>
                        </div>
                    </div>
                    <Divider dashed />
                    <div style={{ width: '100%', padding: '0 40px' }}>
                        <div className='flex-jb'>
                            <div>
                                <span style={{color:'#888888'}}>{textJson['国籍']}：</span>
                                <span><img src={bloggerDetail.nationalFlag} style={{width:'14px', marginRight:'3px', marginTop:'-3px'}} />{bloggerDetail.nationality}</span>
                            </div>
                            <div>
                                <span style={{color:'#888888'}}>{textJson['信用']}：</span>
                                <span>--</span>
                            </div>
                        </div>
                        {bloggerDetail.mcnCode?<div style={{ marginTop: '15px' }}>
                            <span style={{color:'#888888'}}>MCN：</span>
                            <span>{bloggerDetail.mcnOrganizationName}</span>
                        </div>:''}
                        <div style={{ marginTop: bloggerDetail.mcnCode?'8px':'15px' }}>
                            <span style={{color:'#888888'}}>Bio：</span>
                            <span>{bloggerDetail.signature != '' ? bloggerDetail.signature : ''}</span>
                        </div>
                        <div style={{ marginTop: '8px' }}>
                            <span style={{color:'#888888'}}>Link：</span>
                            <span onClick={() => window.open(bloggerDetail.bioLink?.link)} style={{ color: '#F7385B', cursor: 'pointer' }}>{bloggerDetail.bioLink?.link}</span>
                        </div>
                    </div>
                    <Divider dashed />
                    {/* <div className='flex-acjc' style={{ background: 'linear-gradient(180deg, #F7385B, #FF716E)', width: '160px', height: '38px', color: '#fff', borderRadius: '5px', cursor: 'pointer' }}>
                        <img src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/Sensation/hezuo.png' style={{ marginRight: '5px' }} alt='' />
                        {textJson['发起合作']}
                    </div> */}
                </div>
            </div>

        </div>
        <div style={{ marginLeft: '20px', width: '100%', flex: 1, minWidth: 0 }}>

            {/* 选项卡 */}
            <div style={{
                width: '100%', height: '60px', backgroundColor: '#FFF',
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '37px',
            }}>
                <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                    <div style={{
                        backgroundColor: tabIndex === 1 ? '#FFEEF1' : '#FFF', padding: '9px 27px', borderRadius: '4px',
                        color: tabIndex === 1 ? '#F7385B' : '#888888', cursor: 'pointer'
                    }}
                        onClick={() => tabChange(1)}>{textJson['全部视频']}</div>
                    <div style={{
                        backgroundColor: tabIndex === 2 ? '#FFEEF1' : '#FFF', padding: '9px 27px', borderRadius: '4px',
                        color: tabIndex === 2 ? '#F7385B' : '#888888', cursor: 'pointer'
                    }}
                        onClick={() => tabChange(2)}>{textJson['基础分析']}</div>
                    {/* <div style={{
                        backgroundColor: tabIndex === 3 ? '#FFEEF1' : '#FFF', padding: '9px 27px', borderRadius: '4px',
                        color: tabIndex === 3 ? '#F7385B' : '#888888', cursor: 'pointer'
                    }}
                         onClick={() => tabChange(3)}>{textJson['优秀作品']}</div> */}
                </div>
            </div>
            {
                tabIndex === 1 ?
                    <div>
                        {/* 数据卡 */}
                        <div style={{
                            width: '100%', height: '114px', backgroundColor: '#FFF', marginTop: '10px',
                            textAlign: 'center', display: 'flex', justifyContent: 'space-around', alignItems: 'center',
                            padding: '0 35px'
                        }}>
                            <div>
                                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(bloggerDetail.followerCount, 2)}</div>
                                <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/fensiguanli.png'} alt=""
                                        style={{ width: '14px', marginRight: '4px', }} /> {intl.get('BloggerRanking').FansNum}
                                </div>
                            </div>
                            <div>
                                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(bloggerDetail.videoCount, 2)}</div>
                                <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/shipin.png'} alt=""
                                        style={{ width: '14px', marginRight: '4px', }} /> {intl.get('TopicList').VideosNum}
                                </div>
                            </div>
                            <div>
                                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(bloggerDetail.ptvRatioSpeculate, 2)}</div>
                                <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/playCircleL.png'} alt=""
                                        style={{ width: '14px', marginRight: '4px', }} /> {intl.get('TopicList').AveragePlayback}
                                </div>
                            </div>
                            <div>
                                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(bloggerDetail.heartCount, 2)}</div>
                                <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hanhan.png'} alt=""
                                        style={{ width: '14px', marginRight: '4px', }} /> {intl.get('TopicList').LikesNum}
                                </div>
                            </div>
                            <div>
                                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(bloggerDetail.htfRatio, 2)}</div>
                                <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/bilifenxi.png'} alt=""
                                        style={{ width: '14px', marginRight: '4px', }} /> {intl.get('BloggerRanking').AveragePpowderRatio}
                                </div>
                            </div>
                        </div>

                        {/* 排序 */}
                        <div style={{
                            width: '100%', height: '60px', backgroundColor: '#FFF', marginTop: '10px',
                            display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 37px',
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {
                                    sortList?.map((item, index) => {
                                        return <div key={index} className='active' style={{
                                            display: 'flex', alignItems: 'center', marginRight: '36px',
                                            color: sortIndex == index ? '#F7385B' : '#888888', cursor: 'pointer'
                                        }}
                                            onClick={() => sortMethod(index)}>
                                            {item}
                                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index ? 's' : '') + '.png'} alt=""
                                                style={{ marginLeft: '6px', marginTop: '2px' }} />
                                        </div>
                                    })
                                }
                            </div>
                            <div>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/datu' + (!isAll ? 's' : '') + '.png'} alt=""
                                    style={{ cursor: 'pointer' }} onClick={() => changeAll(false)} />
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/lb' + (isAll ? 's' : '') + '.png'} alt=""
                                    style={{ marginLeft: '6px', cursor: 'pointer' }}
                                    onClick={() => changeAll(true)} />
                            </div>
                        </div>

                        {/* 列表 */}
                        <div style={{
                            width: '100%', backgroundColor: '#FFFFFF', padding: !isAll ? '24px 24px 18px 38px' : '24px 24px 24px 30px',
                            marginTop: '2px'
                        }}>
                            {!isAll ? '' : <div style={{
                                width: '100%', height: '50px', display: 'flex', alignItems: 'center', backgroundColor: '#F6F6F6',
                                fontWeight: 500
                            }}>
                                <div style={{ width: '10%', paddingLeft: '19px' }}>{intl.get('BloggerRanking').Ranking}</div>
                                <div style={{ width: '42%', paddingLeft: '24px' }}>{intl.get('TopicList').Video}</div>
                                <div style={{ width: '9%', paddingRight: '10px' }}>{intl.get('TopicList').PlaysNum}</div>
                                <div style={{ width: '9%', paddingRight: '10px' }}>{intl.get('BloggerRanking').LikesNum}</div>
                                <div style={{ width: '9%', paddingRight: '10px' }}>{intl.get('TopicList').CommentsNum}</div>
                                <div style={{ width: '9%', paddingRight: '10px' }}>{intl.get('TopicList').SharesNum}</div>
                                <div style={{ width: '' }}>{intl.get('BloggerRanking').Operation}</div>
                            </div>}
                            <Spin tip="数据加载中..." spinning={loading}>
                                <InfiniteScroll
                                    dataLength={data.length} scrollThreshold={'20px'}
                                    next={loadMoreData}
                                    hasMore={data.length < total}
                                    loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                                    endMessage={<Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>}
                                    scrollableTarget="scrollableDiv"
                                >
                                    <List
                                        dataSource={data} grid={{ gutter: 0, column: !isAll ? widths : 1 }}
                                        renderItem={(item, index) => <div style={{}}>
                                            {!isAll ? BigDiv(item, index) : SmallDiv(item, index)}
                                        </div>}
                                    />
                                </InfiniteScroll>
                            </Spin>

                        </div>
                    </div>
                    : tabIndex === 2 ?
                    <div id='analysis'>
                        <div style={{ padding: '24px 28px', backgroundColor: '#fff', marginTop: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='sd-title'>{textJson['视频概述']}</div>
                                <div className='time-box'>
                                    {/* <div className={choseList[0] === 7 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(7, 0)}>7天</div>
                                    <div className={choseList[0] === 15 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(15, 0)}>15天</div>
                                    <div className={choseList[0] === 30 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(30, 0)}>30天</div>
                                    <div className={choseList[0] === 90 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(90, 0)}>90天</div> */}
                                    <div style={{
                                        width: 240,
                                        height: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: '10px',
                                        border: '1px solid #EEEEEE',
                                        color: '#CCCCCC',
                                        marginLeft: '13px',
                                        fontSize: '12px'
                                    }}>
                                        <CalendarOutlined style={{ marginRight: '17px' }} />
                                        <div style={{ marginRight: '17px' }}>{moment(moment().subtract(choseList[0], 'days')).format('YYYY-MM-DD')}</div>
                                        <div style={{ marginRight: '17px' }}>~</div>
                                        <div style={{ marginRight: '17px' }}>{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                width: '100%', height: '132px', backgroundColor: '#FFF', marginTop: '10px',
                                textAlign: 'center', display: 'flex', justifyContent: 'space-around', alignItems: 'center',
                                // padding: '0 35px'
                            }}>
                                <div className='flex-ac' style={{ flexDirection: 'column' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(summary.videoCount, 2)}</div>
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/shipin.png'} alt=""
                                            style={{ width: '14px', marginRight: '4px', }} /> {textJson['近7天视频数']}
                                    </div>
                                    <div style={{ background: '#f6f6f6', fontSize: '12px', padding: '3px 8px', color: '#888', marginTop: '15px' }}>{textJson['占全部视频总数的']}{(summary.videoProportion != '--' ? summary.videoProportion : 0) * 100}%</div>
                                </div>
                                <div className='flex-ac' style={{ flexDirection: 'column' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(summary.avgPlayCount, 2)}</div>
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/playCircleL.png'} alt=""
                                            style={{ width: '14px', marginRight: '4px', }} /> {textJson['近7天视频平均播放数']}
                                    </div>
                                    <div style={{ background: '#f6f6f6', fontSize: '12px', padding: '3px 8px', color: '#888', marginTop: '15px' }}>{textJson['近7天视频最高播放数']}{toKMB(summary.maxPlayCount, 2)}</div>
                                </div>
                                <div className='flex-ac' style={{ flexDirection: 'column' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(summary.avgThumbsUpCount, 2)}</div>
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hanhan.png'} alt=""
                                            style={{ width: '14px', marginRight: '4px', }} /> {textJson['近7天视频平均点赞数']}
                                    </div>
                                    <div style={{ background: '#f6f6f6', fontSize: '12px', padding: '3px 8px', color: '#888', marginTop: '15px' }}>{textJson['近7天视频最高点赞数']}{toKMB(summary.maxThumbsUpCount, 2)}</div>
                                </div>
                                <div className='flex-ac' style={{ flexDirection: 'column' }}>
                                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(summary.avgCommentsCount, 2)}</div>
                                    <div style={{ display: 'flex', alignItems: 'center', color: '#888888' }}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pinglun.png'} alt=""
                                            style={{ width: '14px', marginRight: '4px', }} /> {textJson['近7天视频平均评论数']}
                                    </div>
                                    <div style={{ background: '#f6f6f6', fontSize: '12px', padding: '3px 8px', color: '#888', marginTop: '15px' }}>{textJson['近7天视频最高评论数']}{toKMB(summary.maxCommentsCount, 2)}</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: '24px 28px', backgroundColor: '#fff', marginTop: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='sd-title'>{textJson['视频数据趋势分析']}</div>
                                <div className='time-box'>
                                    <div className={choseList[1] === 7 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(7, 1)}>7{intl.get('ReleaseDemand')['天']}</div>
                                    <div className={choseList[1] === 15 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(15, 1)}>15{intl.get('ReleaseDemand')['天']}</div>
                                    <div className={choseList[1] === 30 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(30, 1)}>30{intl.get('ReleaseDemand')['天']}</div>
                                    <div className={choseList[1] === 90 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(90, 1)}>90{intl.get('ReleaseDemand')['天']}</div>
                                    <div style={{
                                        width: 240,
                                        height: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: '10px',
                                        border: '1px solid #EEEEEE',
                                        color: '#CCCCCC',
                                        marginLeft: '13px',
                                        fontSize: '12px'
                                    }}>
                                        <CalendarOutlined style={{ marginRight: '17px' }} />
                                        <div style={{ marginRight: '17px' }}>{moment(moment().subtract(choseList[1], 'days')).format('YYYY-MM-DD')}</div>
                                        <div style={{ marginRight: '17px' }}>~</div>
                                        <div style={{ marginRight: '17px' }}>{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: 26, width: 320, height: 30, alignItems: 'center', border: '1px solid #EEEEEE', borderRadius: '30px', position: 'relative', textAlign: 'center', lineHeight: '28px' }}>
                                <div style={{ width: 80, height: 28, background: 'linear-gradient(180deg, #F7385B, #FF716E)', position: 'absolute', left: `${(tagList[0] - 1) * 25}%`, top: 0, zIndex: 1, borderRadius: '30px', transition: 'all 0.5s' }}></div>
                                <div style={{ zIndex: 2, width: '25%', cursor: 'pointer', color: tagList[0] === 1 ? '#fff' : '', transition: 'all 0.5s' }} onClick={e => tagListChange(1, 0)}>{intl.get('TopicList').PlaysNum}</div>
                                <div style={{ zIndex: 2, width: '25%', cursor: 'pointer', color: tagList[0] === 2 ? '#fff' : '', transition: 'all 0.5s' }} onClick={e => tagListChange(2, 0)}>{intl.get('TopicList').LikesNum}</div>
                                <div style={{ zIndex: 2, width: '25%', cursor: 'pointer', color: tagList[0] === 3 ? '#fff' : '', transition: 'all 0.5s' }} onClick={e => tagListChange(3, 0)}>{intl.get('TopicList').CommentsNum}</div>
                                <div style={{ zIndex: 2, width: '25%', cursor: 'pointer', color: tagList[0] === 4 ? '#fff' : '', transition: 'all 0.5s' }} onClick={e => tagListChange(4, 0)}>{intl.get('TopicList').SharesNum}</div>
                            </div>
                            <div className='flex-acjb' style={{ marginTop: '24px', padding: '5px 19px', background: '#F6F6F6' }}>
                                {videoData ? <div className='flex' style={{ fontWeight: 600 }}>
                                    {/* <div>{moment(moment().subtract(choseList[1], 'days')).format('YYYY-MM-DD')} ～ {moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')}</div> */}
                                    <div style={{ marginLeft: '5px', marginRight: '10px' }}>{textJson['最高值为']}<span style={{ color: '#F7385B' }}>{toKMB(tagList[0] == 1 ? videoData.playCountMap.max : tagList[0] == 2 ? videoData.diggCountMap.max : tagList[0] == 3 ? videoData.commentCountMap.max : videoData.shareCountMap.max, 2)}</span></div>
                                    <div style={{ marginLeft: '5px', marginRight: '10px' }}>{textJson['最低值为']}<span style={{ color: '#F7385B' }}>{toKMB(tagList[0] == 1 ? videoData.playCountMap.min : tagList[0] == 2 ? videoData.diggCountMap.min : tagList[0] == 3 ? videoData.commentCountMap.min : videoData.shareCountMap.min, 2)}</span></div>
                                    <div style={{ marginLeft: '5px' }}>{textJson['平均值为']}<span style={{ color: '#F7385B' }}>{toKMB(tagList[0] == 1 ? videoData.playCountMap.avg : tagList[0] == 2 ? videoData.diggCountMap.avg : tagList[0] == 3 ? videoData.commentCountMap.avg : videoData.shareCountMap.avg, 2)}</span></div>
                                </div> : ''}
                                <div>{textJson['更新时间']}：{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD HH:mm:ss')}</div>
                            </div>
                            <div style={{ marginTop: 40 }}>
                                {
                                    configV.data.length > 0 ? <Area {...configV} /> :
                                        <div style={{ width: '345px', height: '200px', textAlign: 'center', margin: '0 auto', marginTop: '100px' }}>
                                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                                            <div style={{ marginTop: '24px', textAlign: 'center', color: '#333' }}>{intl.get('Common')['暂无数据']}</div>
                                        </div>
                                }
                                {/* <ChildComp config={configF} /> */}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 100 }}>
                                <div className='sd-title'>{textJson['粉丝变化趋势分析']}</div>
                                <div className='time-box'>
                                    <div className={choseList[2] === 7 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(7, 2)}>7{intl.get('ReleaseDemand')['天']}</div>
                                    <div className={choseList[2] === 15 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(15, 2)}>15{intl.get('ReleaseDemand')['天']}</div>
                                    <div className={choseList[2] === 30 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(30, 2)}>30{intl.get('ReleaseDemand')['天']}</div>
                                    <div className={choseList[2] === 90 ? 'time-item time-item-active' : 'time-item'} onClick={e => chooseChange(90, 2)}>90{intl.get('ReleaseDemand')['天']}</div>
                                    <div style={{
                                        width: 240,
                                        height: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: '10px',
                                        border: '1px solid #EEEEEE',
                                        color: '#CCCCCC',
                                        marginLeft: '13px',
                                        fontSize: '12px'
                                    }}>
                                        <CalendarOutlined style={{ marginRight: '17px' }} />
                                        <div style={{ marginRight: '17px' }}>{moment(moment().subtract(choseList[2], 'days')).format('YYYY-MM-DD')}</div>
                                        <div style={{ marginRight: '17px' }}>~</div>
                                        <div style={{ marginRight: '17px' }}>{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: 26, width: 160, height: 30, alignItems: 'center', border: '1px solid #EEEEEE', borderRadius: '30px', position: 'relative', textAlign: 'center', lineHeight: '28px' }}>
                                <div style={{ width: 80, height: 28, background: 'linear-gradient(180deg, #F7385B, #FF716E)', position: 'absolute', left: `${(tagList[1] - 1) * 50}%`, top: 0, zIndex: 1, borderRadius: '30px', transition: 'all 0.5s' }}></div>
                                <div style={{ zIndex: 2, width: '50%', cursor: 'pointer', color: tagList[1] === 1 ? '#fff' : '', transition: 'all 0.5s' }} onClick={e => tagListChange(1, 1)}>{textJson['总量']}</div>
                                <div style={{ zIndex: 2, width: '50%', cursor: 'pointer', color: tagList[1] === 2 ? '#fff' : '', transition: 'all 0.5s' }} onClick={e => tagListChange(2, 1)}>{textJson['增量']}</div>
                            </div>
                            <div className='flex-acjb' style={{ marginTop: '24px', padding: '5px 19px', background: '#F6F6F6' }}>
                                {fansData ? <div className='flex' style={{ fontWeight: 600 }}>
                                    {/* <div>{moment(moment().subtract(choseList[1], 'days')).format('YYYY-MM-DD')} ～ {moment(moment().subtract(1, 'days')).format('YYYY-MM-DD')}</div> */}
                                    <div style={{ marginLeft: '5px', marginRight: '10px' }}>{tagList[1] == 1 ? textJson['粉丝增长总量为'] : textJson['粉丝增量为']}<span style={{ color: '#F7385B' }}>{toKMB(fansData.fansIncrementMap.incrementTotal, 2)}</span></div>
                                </div> : ''}
                                <div>{textJson['更新时间']}：{moment(moment().subtract(1, 'days')).format('YYYY-MM-DD HH:mm:ss')}</div>
                            </div>
                            <div style={{ marginTop: 40 }}>
                                {
                                    configF.data.length > 0 ? <Area {...configF} /> :
                                        <div style={{ width: '345px', height: '200px', textAlign: 'center', margin: '0 auto', marginTop: '100px' }}>
                                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                                            <div style={{ marginTop: '24px', textAlign: 'center', color: '#333' }}>{intl.get('Common')['暂无数据']}</div>
                                        </div>
                                }
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '100px' }}>
                                <div className='sd-title'>常用话题分析</div>
                            </div>
                            <div className='flex-acjc'>
                                <ReactEcharts
                                    option={optionW}
                                    notMerge
                                    lazyUpdate
                                    style={{ height: '315px', width: '725px', marginTop: '50px' }}
                                />
                            </div>
                        </div>
                    </div>
                    : tabIndex == 3 ? <ExcellentWorks uid={bloggerDetail.uid} />
                    : ''
            }
        </div>
    </div> : ''
}
export default withRouter(SensationDetail);