import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import { Image, Tag, Skeleton, Badge, List, Radio, Input, message, Upload, Drawer, Spin } from 'antd';
import '../../asset/css/LivingDemand.scss'
import Modal from 'antd/lib/modal/Modal';
import * as Api from '../../service/https'
import Slider from "react-slick";
import store from '../../utils/redux/index'
import { baseUrl } from '../../service/request'
import intl from 'react-intl-universal';
import { CloseCircleOutlined } from '@ant-design/icons';
import three from '../../utils/three'
import {debounce} from '../../utils/debounce'
import Enum from '../../utils/Enum/Enum'
import "video-react/dist/video-react.css";
import { Player, ControlBar } from 'video-react';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div style={{ backgroundColor: '#EEEEEE', borderRadius: '100px', width: '22px', height: '22px', textAlign: 'center', lineHeight: '18px', cursor: 'pointer', position: 'absolute', top: 'calc(50% - 11px)', left: '-25px' }} onClick={onClick}><img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou3.png'} alt="" /></div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div style={{ backgroundColor: '#EEEEEE', borderRadius: '100px', width: '22px', height: '22px', textAlign: 'center', lineHeight: '18px', cursor: 'pointer', position: 'absolute', top: 'calc(50% - 11px)', right: '-25px' }} onClick={onClick}><img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou2.png'} alt="" /></div>
    );
}

const StatusVideoDetail = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh'
    const [textJson, setTextJson] = useState(intl.get('DemandHall'))
    const [textJsonL, setTextJsonL] = useState(intl.get('LivingDemand'))
    const [textJsonR, setTextJsonR] = useState(intl.get('ReleaseDemand'))
    const [marketDetail, setMarketDetail] = useState()
    const [showIndex, setShowIndex] = useState(-1)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [payType, setPayType] = useState(0)
    const [userInfo, setUserInfo] = useState({})
    const [state, setSate] = useState(store.getState());
    const [visible, setVisible] = useState(false);
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [keys, setKeys] = useState('marketList')

    useEffect(() => {
        // console.log(props)
        setMarketDetail(props.marketDetail)
        setID(props.marketDetail)
        getUserInfoFun()
    }, [])

    const getUserInfoFun = () => {
        Api.getUserInfo().then(res => {
            if (res.code === 200) {
                if(res.data.userType == 2){
                    setUserInfo(res.data)
                }else if(res.data.userType == 3){
                    getMCNaddress(res.data)
                }else{
                    setUserInfo(res.data)
                }
            }
        })
    }
    const getMCNaddress = item => {
        Api.getMCNaddress().then(res => {
            if (res.code === 200) {
                setAddressIndex(-1)
                let obj = item
                obj.redAccountDetail = res.data
                setUserInfo(obj)
            }
        })
    }


    const [statusList, setStatusList] = useState([
        {
            id: 0,
            text: intl.get('DemandHall')['待合作'], color:'#FFC146'
        },
        {
            id: 1,
            text: intl.get('DemandHall')['待合作'], color:'#FFC146'
        },
        {
            id: 2,
            text: intl.get('DemandHall')['合作中'], color:'#FFC146'
        },
        {
            id: 3,
            text: intl.get('DemandHall')['待确认'], color:'#FFC146'
        },
        {
            id: 4,
            text: intl.get('DemandHall')['合作成功'], color:'#68C33B'
        },
        {
            id: 5,
            text: intl.get('DemandHall')['失效申请'], color:'#F7385B'
        },
        {
            id: 6,
            text: intl.get('DemandHall')['商家指派'], color:'#32A7F5'
        },
    ])

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SampleNextArrow />,
        nextArrow: <SamplePrevArrow />
    };

    const [videoVisible, setVideoVisible] = useState(false)
    const [url, setUrl] = useState()

    const playVideo = e => {
        setVideoVisible(true)
        setUrl(e)
    }

    const addPK = (e, item, index) => {
        e.stopPropagation();
        console.log('pk', index)
    }

    const merchantSuccess = (item) => {
        let objS = {
            target: marketDetail.target, media: marketDetail.media, cooperationWay: marketDetail.cooperationWay,
            listId: marketDetail.listId, demandId: marketDetail.demandId,
            applicationId: marketDetail.application.id
        }
        Api.merchantSuccess(objS).then(res => {
            // console.log(res)
            if (res.code == 200) {
                message.success(textJsonL["确认成功！"])
                setTimeout(()=>{
                    window.location.reload()
                }, 1000)
            }
        })
    }

    const [list, setList] = useState([])
    const [bloggerCurrent, setBloggerCurrent] = useState(-1)
    const [reload, setReload] = useState(false)

    const uploadProps = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/video/upload',
        showUploadList: false,
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'video/mp4'
            if (!isJpgOrPng) {
                message.error(intl.get('EnterpriseCertification')['请上传正确的视频！']);
            }
            const isLt2M = file.size / 1024 / 1024 < 50;
            if (!isLt2M) {
                message.error(intl.get('EnterpriseCertification')['视频过大，请上传50MB以下的视频!']);
            }
            return isJpgOrPng && isLt2M;
        },
        headers: {
            'x-token': localStorage.getItem('token')
        }
    };
    const uploadImgProps = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/img/upload',
        showUploadList: false,
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error(intl.get('EnterpriseCertification')['请上传正确的图片！']);
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error(intl.get('EnterpriseCertification')['图片过大，请上传10MB以下的图片']);
            }
            return isJpgOrPng && isLt2M;
        },
        headers: {
            'x-token': localStorage.getItem('token')
        }
    };

    const [loading, setLoading] = useState(true)
    const onChange = (info, item) => {
        if (info.file.status === 'uploading') {
            if(loading){
                message.loading(`上传中...`, 0);
            }
            setLoading(false)
        }
        if (info.file.status === 'done') {
            message.destroy()
            bloggerConfirm(info.file.response.data, item)
        } else if (info.file.status === 'error') {
            setLoading(true)
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    const bloggerConfirm = (e, item) => {
        let data = {
            target: item.target,
            media: item.media,
            cooperationWay: item.cooperationWay,
            listId: item.listId,
            demandId: item.demandId,
            applicationId: item.application.id,
            confirmInfo: e
        }
        Api.bloggerConfirm(data).then(res => {
            setLoading(true)
            if (res.code === 200) {
                message.success(textJsonL['提交成功！'])
                setTimeout(()=>{
                    window.location.reload()
                },1000)
            }
        })
    }



    const [id, setID] = useState({})
    const [isSuccess, setScucess] = useState(false)
    const [isApply, setApply] = useState(false)
    const [addressList, setAddressList] = useState([])
    const [addressIndex, setAddressIndex] = useState(-1)
    const getCurrentUserAddressList = ()=>{
        Api.getCurrentUserAddressList().then(res=>{
            if(res.code == 200){
                setAddressList(res.data)
                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    if(element.defaultAddress){
                        setAddressIndex(index)
                        break
                    }
                }
            }
        })
    }
    useEffect(()=>{
        if(isApply){
            getCurrentUserAddressList()
        }
    },[isApply])
    const [isSample, setSample] = useState(true)
    const [applyParams, setApplyParams] = useState({})
    const [addParams, setAddParams] = useState({})
    const applyChange = (e, id) => {
        let t = JSON.parse(JSON.stringify(addParams))
        t[id] = e
        setAddParams(t)
    }
    useEffect(()=>{
        if(addressIndex > -1){
            let obj = {
                cost: 0,
                costDollar: 0,
                consigneeSpecificAddress: addressList[addressIndex].address , 
                consigneePostCode: addressList[addressIndex].postCode, 
                consigneePhone: addressList[addressIndex].phone, 
                consigneeName: addressList[addressIndex].contactName, 
            }
            setApplyParams(obj)
        }
    },[addressIndex])
    const addAddress = ()=>{
        if (JSON.stringify(addParams).indexOf(('""')) === -1 && JSON.stringify(addParams) !== '{}') {
            let obj = {
                uid: state.userInfo?.uid, address: addParams.consigneeSpecificAddress, 
                postCode: addParams.consigneePostCode, phone: addParams.consigneePhone, 
                contactName: addParams.consigneeName, defaultAddress: true 
            }
            // console.log(obj)
            Api.addAddress(obj).then(res=>{
                if(res.code == 200){
                    getCurrentUserAddressList()
                    setIsAddress(false)
                    setAddParams({})
                }
            })
        }else{
            message.warn("内容未填写完整！")
        }
    }

    const submit = () => {
        if(state.userInfo?.userType == 3) {
            if (!bloggerItem) {
                message.warn("内容未填写完整！")
                return
            }
        }
            if(id.cooperationWay == 1){
                if (id.item.sendSample === 1 && id.asettleWay === 1) {
                    applyParams.costDollar = 0
                } else {
                    applyParams.costDollar = Math.floor(id.item.duration / 60) * (userInfo.redAccountDetail?.videoQuotationDollar || bloggerItem?.videoQuotationDollar || 0)
                }
            }else{
                if (id.live.sendSample === 1 && id.asettleWay === 1) {
                    applyParams.costDollar = 0
                } else {
                    applyParams.costDollar = Math.floor(id.live.durationH) * (userInfo.redAccountDetail?.liveBroadcastQuotationDollar || bloggerItem?.liveBroadcastQuotationDollar || 0)
                }
            }
        // console.log(applyParams)
        if ((JSON.stringify(applyParams).indexOf(('""')) === -1 && JSON.stringify(applyParams) !== '{}' && addressIndex > -1) || id?.item?.sendSample != 1 || id?.live?.sendSample != 1) {
            let b = {
                target: marketDetail.target,
                media: marketDetail.media,
                cooperationWay: marketDetail.cooperationWay,
                sendSample: id.cooperationWay == 1 ? id.item.sendSample : id.live.sendSample
            }
            if(state.userInfo?.userType == 3 && bloggerItem) b.userIdBlogger = bloggerItem.uid
            let data = JSON.parse((JSON.stringify(id) + JSON.stringify(b)).replace(/}{/, ','));
            data = JSON.parse((JSON.stringify(data) + JSON.stringify(applyParams)).replace(/}{/, ','));
            // console.log(data)
            Api.marketApply(data).then(res => {
                if (res.code === 200) {
                    setApply(false);
                    setScucess(true);
                    setAddressIndex(-1)
                }
            })
        }
        else
            message.warn("内容未填写完整！")
    }



    const refuse = () => {
        let data = {
            target: marketDetail.target,
            media: marketDetail.media,
            cooperationWay: marketDetail.cooperationWay,
            listId: marketDetail.listId,
            demandId: marketDetail.demandId,
            asettleWay: marketDetail.asettleWay
        }
        Api.refuse(data).then(res => {
            if (res.code === 200) {
                message.success(textJson['拒绝成功！'])
            } else {
                message.error(textJson['拒绝失败！'])
            }
        })
    }

    const goToRelease = ()=>{
        let obj = props.location.state
        if(marketDetail.sendWay == 1) obj.blogger = props.marketBloggerList[0].userInfo
        props.navigate('/index/releaseDemand', { state: obj })
    }




    const [isHotR, setIsHotR] = useState(false)
    const [bloggerItem, setBloggerItem] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [valueSearch, setValueSearch] = useState(null)
    const [searchList, setSearchList] = useState([])
    const handleSearch = debounce((value) => {
        setValueSearch(value==''?null:value)
    }, 500)
    
    useEffect(()=>{
        if (valueSearch) {
            getSearch()
        } else {
            setSearchList([])
        }
    }, [valueSearch])
    const getSearch = () => {
        setFetching(true)
        let obj = {
            cargoClassification: "",
            classify: [],
            current: 1,
            filterName: valueSearch,
            nationality: "",
            redAccountSortRuler: 1,
            size: 20
        }
        Api.getRedAccount(obj).then(res=>{
            setFetching(false)
            if(res.code == 200){
                setSearchList(res.data.records)
            }
        })
    }
    const addBloggerS = (item,index) => {
        setBloggerItem(item)
        setIsHotR(false)
    }

    const [isAddress, setIsAddress] = useState(false)

    return (
        <div id='Status1'>
            {
                marketDetail ?
                    <div style={{ marginTop: '12px', backgroundColor: '#fff' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', display: 'flex', padding: '30px 0 30px 30px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '200px', marginRight: '28px',
                                backgroundImage: 'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png)', backgroundSize: 'cover',
                                backgroundPosition: '50% 50%' }}>
                                    <Slider {...settings} style={{ width: '130px', height: '180px', overflow: 'hidden' }}>
                                        {
                                            marketDetail.target === 2 ?
                                                <div>
                                                    <div style={{ width: '130px', height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', position: 'relative' }}>
                                                        <img src={marketDetail.marketList?.logo} style={{ width: '130px', height: '180px' }} alt='' />
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                        {
                                            marketDetail.marketList?.videos?.map((item, index) => (
                                                <div key={index}>
                                                    <div style={{ width: '130px', height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', position: 'relative', cursor: 'pointer' }} onClick={e => playVideo(item)}>
                                                        <img src={item + '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,m_fast'} style={{ width: '130px', height: '180px' }} alt='' />
                                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/player_red.png'} style={{ position: 'absolute' }} alt="" />
                                                        {/* <div style={{ position: 'absolute', bottom: '12px', color: '#fff' }}>0:59</div> */}
                                                    </div>
                                                </div>
                                            )) 
                                        }
                                        {
                                            marketDetail.marketList?.imgs?.map((item, index) => (
                                                <div key={index}>
                                                    <div style={{ width: '130px', height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', position: 'relative' }}>
                                                        <img src={item} style={{ width: '130px', height: '180px' }} alt='' />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                </div>
                                {marketDetail.target !=2 && marketDetail.cooperationWay == 1 ? <div style={{ paddingRight: '8%', width:'100%', background:'#FFF' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className='over' style={{ height: '25px', fontSize: '16px', fontWeight: '800', marginRight: '14px' }}>{marketDetail[keys]?.title}</div>
                                        {marketDetail.asettleWay == 0 ? <div><Tag color="green">{marketDetail.item?.sendSample == 1 ? textJsonL['支持样品寄送'] : textJsonL['不支持样品寄送']}</Tag></div>
                                        : <div><Tag color="green">{textJsonL['支持样品寄送（寄样抵佣金）']}</Tag></div>}
                                    </div>
                                    <div className='over' style={{ marginTop: '10px', height: '40px' }}>
                                        {marketDetail[keys]?.intro}
                                    </div>
                                    <div style={{ marginTop: '15px' }}>
                                        <span style={{background:'#FFE7E9', color:'#F7385B', fontSize:'12px', border:'1px solid #F7385B', padding:'1px 8px', borderRadius:'3px', display: 'inline-block', marginBottom:'4px', marginRight:'8px'}}>
                                            {textJsonR[Enum.propertiesTarget[marketDetail.target].val]} | {textJsonR['短视频']}
                                        </span>
                                        <Tag>{textJsonL['品牌名称：']}{marketDetail[keys]?.brand}</Tag>
                                        <Tag>{textJsonL['所属行业：']}{marketDetail[keys]?.biz?.val}</Tag>
                                        <Tag>{textJsonL['媒体传播：']}{Enum.propertiesMedia[marketDetail.media].val}</Tag>
                                    </div>
                                    {marketDetail.merchantCooperationEnum == 0 || marketDetail.merchantCooperationEnum == 1 || marketDetail.merchantCooperationEnum == 5 ? <div>
                                        {marketDetail.sendWay == 2 ? <div style={{ marginTop: '10px', color: '#888888' }}>{textJsonR["需求有效日期"]}：<span style={{ fontFamily: 'neue' }}>{marketDetail.item?.bloggerApplicationTimeTo?.substring(0,10) || intl.get('SmallShop')['永久有效']}</span></div>:''}
                                        <div>
                                            {marketDetail.application?.status == 6 ?<div style={{ marginTop: '10px', color: '#888888' }}>{textJsonR['红人截稿日期']}：<span style={{ fontFamily: 'neue' }}>{marketDetail.item?.bloggerPubDeadline?.substring(0,10)}</span></div>:''}
                                            <div style={{ marginTop: '5px', color: '#888888' }}>{textJsonL['视频制作时长']}：<span style={{ fontFamily: 'neue', color: '#F7385B', fontWeight: '800' }}>{marketDetail.item?.duration}s{textJsonL['内']}</span></div>
                                        </div>
                                    </div> :
                                        marketDetail.merchantCooperationEnum == 2 || marketDetail.merchantCooperationEnum == 3 || marketDetail.merchantCooperationEnum == 4 ? <div>
                                            <div style={{ marginTop: '10px', color: '#888888' }}>{textJsonR['红人截稿日期']}：<span style={{ fontFamily: 'neue' }}>{marketDetail.item?.bloggerPubDeadline?.substring(0,10)}</span></div>
                                            <div style={{ marginTop: '5px', color: '#888888' }}>{textJsonL['总计合作佣金']}：<span style={{ fontFamily: 'neue', color: '#F7385B', fontWeight: '800' }}><span style={{fontSize:'16px'}}>${marketDetail.asettleWay==0?(marketDetail.application.costDollar
                                                || (marketDetail.cooperationWay == 1 && marketDetail.item?.sendSample === 1 && marketDetail.asettleWay != 1 ? Math.floor(marketDetail.item?.duration / 60) * (marketDetail?.userInfo?.videoQuotationDollar || 0) : 0)
                                                ||(marketDetail.cooperationWay == 2 && marketDetail.live?.sendSample === 1 && marketDetail.asettleWay != 1 ? Math.floor(marketDetail.live?.durationH) * (marketDetail?.userInfo?.liveBroadcastQuotationDollar || 0) : 0)
                                                || 0):0}</span>（{marketDetail.asettleWay==0?`${textJsonL['红人视频单价']}$${marketDetail.userInfo?.videoQuotationDollar}/${intl.get('NewLogin')['60s内']} * ${intl.get('VideoDetail')['视频时长']}${marketDetail.item?.duration}s${textJsonL['内']}`:`${intl.get('VideoDetail')['视频时长']}${marketDetail.item?.duration}s${textJsonL['内']}`}）</span></div>
                                        </div> : ''}
                                </div> :
                                    marketDetail.target !=2 && marketDetail.cooperationWay == 2 ? <div style={{ paddingRight: '8%', width:'100%', background:'#FFF' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='over' style={{ height: '25px', fontSize: '16px', fontWeight: '800', marginRight: '14px' }}>{marketDetail[keys].title}</div>
                                            {marketDetail.asettleWay == 0 ? <div><Tag color="green">{marketDetail.live.sendSample == 1 ? textJsonL['支持样品寄送'] : textJsonL['不支持样品寄送']}</Tag></div>
                                            : <div><Tag color="green">{textJsonL['支持样品寄送（寄样抵佣金）']}</Tag></div>}
                                        </div>
                                        <div className='over' style={{ marginTop: '10px', height: '40px' }}>
                                            {marketDetail[keys].intro}
                                        </div>
                                        <div style={{ marginTop: '15px' }}>
                                            <span style={{background:'#FFE7E9', color:'#F7385B', fontSize:'12px', border:'1px solid #F7385B', padding:'1px 8px', borderRadius:'3px', display: 'inline-block', marginBottom:'4px', marginRight:'8px'}}>
                                                {textJsonR[Enum.propertiesTarget[marketDetail.target].val]} | {textJsonR['直播']}
                                            </span>
                                            <Tag>{textJsonL['品牌名称：']}{marketDetail[keys].brand}</Tag>
                                            <Tag>{textJsonL['所属行业：']}{marketDetail[keys]?.biz?.val}</Tag>
                                            <Tag>{textJsonL['媒体传播：']}{Enum.propertiesMedia[marketDetail.media].val}</Tag>
                                        </div>
                                        {marketDetail.merchantCooperationEnum == 0 || marketDetail.merchantCooperationEnum == 1 || marketDetail.merchantCooperationEnum == 5 ? <div>
                                            {marketDetail.sendWay == 2 ? <div style={{ marginTop: '10px', color: '#888888' }}>{textJsonR["需求有效日期"]}：<span style={{ fontFamily: 'neue' }}>{marketDetail.live?.bloggerApplicationTimeTo?.substring(0,10) || intl.get('SmallShop')['永久有效']}</span></div>:''}
                                            <div>
                                                {marketDetail.application?.status == 6 ?<div style={{ marginTop: '10px', color: '#888888' }}>{textJsonL['红人开播时间']}：<span style={{ fontFamily: 'neue' }}>{marketDetail.live.bloggerPubTime?.substring(0,10)}</span></div>:''}
                                                <div style={{marginTop: marketDetail.sendWay==2?'5px':'10px', color: '#888888' }}>{textJsonL['直播合作时长']}：<span style={{ fontFamily: 'neue', color: '#F7385B', fontWeight: '800' }}>{marketDetail.live.durationH}h{textJsonL['内']}</span></div>
                                            </div>
                                        </div> :
                                            marketDetail.merchantCooperationEnum == 2 || marketDetail.merchantCooperationEnum == 3 || marketDetail.merchantCooperationEnum == 4 ? <div>
                                                <div style={{ marginTop: '10px', color: '#888888' }}>{textJsonL['红人开播时间']}：<span style={{ fontFamily: 'neue' }}>{marketDetail.live.bloggerPubTime?.substring(0,10)}</span></div>
                                                <div style={{ marginTop: '5px', color: '#888888' }}>{textJsonL['总计合作佣金']}：<span style={{ fontFamily: 'neue', color: '#F7385B', fontWeight: '800' }}><span style={{fontSize:'16px'}}>${marketDetail.asettleWay==0?(marketDetail.application.costDollar
                                                    || (marketDetail.cooperationWay == 1 && marketDetail.item?.sendSample === 1 && marketDetail.asettleWay != 1 ? Math.floor(marketDetail.item?.duration / 60) * (marketDetail?.userInfo?.videoQuotationDollar || 0) : 0)
                                                    ||(marketDetail.cooperationWay == 2 && marketDetail.live?.sendSample === 1 && marketDetail.asettleWay != 1 ? Math.floor(marketDetail.live?.durationH) * (marketDetail?.userInfo?.liveBroadcastQuotationDollar || 0) : 0)
                                                    || 0):0}</span>（{marketDetail.asettleWay==0?`${textJsonL['红人直播单价']} $${marketDetail.userInfo?.liveBroadcastQuotationDollar}/${intl.get('NewLogin')['1h内']} * ${intl.get('ReleaseDemand')['直播时长']}${marketDetail.live.durationH}h${textJsonL['内']}`:`${intl.get('ReleaseDemand')['直播时长']}${marketDetail.live.durationH}h${textJsonL['内']}`}）</span></div>
                                            </div> : ''}
                                    </div> :
                                marketDetail.target == 2 && marketDetail.cooperationWay == 1 ? <div style={{ paddingRight: '8%', width:'100%', background:'#FFF' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className='over' style={{ height:'25px', fontSize: '16px', fontWeight: '800', marginRight: '14px' }}>{marketDetail.marketList.brand}</div>
                                        {marketDetail.asettleWay == 0 ? <div><Tag color="green">{marketDetail.item.sendSample == 1 ? textJsonL['支持样品寄送'] : textJsonL['不支持样品寄送']}</Tag></div>
                                        : <div><Tag color="green">{textJsonL['支持样品寄送（寄样抵佣金）']}</Tag></div>}
                                    </div>
                                    <div className='over' style={{ marginTop: '10px', height: '40px' }}>
                                        {marketDetail.marketList.intro}
                                    </div>
                                    <div style={{ marginTop: '15px' }}>
                                        <span style={{background:'#FFE7E9', color:'#F7385B', fontSize:'12px', border:'1px solid #F7385B', padding:'1px 8px', borderRadius:'3px', display: 'inline-block', marginBottom:'4px', marginRight:'8px'}}>
                                            {textJsonR[Enum.propertiesTarget[marketDetail.target].val]} | {textJsonR['短视频']}
                                        </span>
                                        <Tag>{textJsonL['所属行业：']}{marketDetail.marketList?.biz?.val}</Tag>
                                        <Tag>{textJsonL['媒体传播：']}{Enum.propertiesMedia[marketDetail.media].val}</Tag>
                                    </div>
                                    {marketDetail.merchantCooperationEnum == 0 || marketDetail.merchantCooperationEnum == 1 || marketDetail.merchantCooperationEnum == 5 ? <div>
                                        {marketDetail.sendWay == 2 ? <div style={{ marginTop: '10px', color: '#888888' }}>{textJsonR["需求有效日期"]}：<span style={{ fontFamily: 'neue' }}>{marketDetail.item?.bloggerApplicationTimeTo?.substring(0,10) || intl.get('SmallShop')['永久有效']}</span></div>:''}
                                        <div>
                                            {marketDetail.application?.status == 6 ?<div style={{ marginTop: '10px', color: '#888888' }}>{textJsonR['红人截稿日期']}：<span style={{ fontFamily: 'neue' }}>{marketDetail.item?.bloggerPubDeadline?.substring(0,10)}</span></div>:''}
                                            <div style={{ marginTop: '5px', color: '#888888' }}>{textJsonL['视频制作时长']}：<span style={{ fontFamily: 'neue', color: '#F7385B', fontWeight: '800' }}>{marketDetail.item?.duration}s{textJsonL['内']}</span></div>
                                        </div>
                                    </div> :
                                        marketDetail.merchantCooperationEnum == 2 || marketDetail.merchantCooperationEnum == 3 || marketDetail.merchantCooperationEnum == 4 ? <div>
                                            <div style={{ marginTop: '10px', color: '#888888' }}>{textJsonR['红人截稿日期']}：<span style={{ fontFamily: 'neue' }}>{marketDetail.item?.bloggerPubDeadline?.substring(0,10)}</span></div>
                                            <div style={{ marginTop: '5px', color: '#888888' }}>{textJsonL['总计合作佣金']}：<span style={{ fontFamily: 'neue', color: '#F7385B', fontWeight: '800' }}><span style={{fontSize:'16px'}}>${marketDetail.asettleWay==0?(marketDetail.application.costDollar
                                                || (marketDetail.cooperationWay == 1 && marketDetail.item?.sendSample === 1 && marketDetail.asettleWay != 1 ? Math.floor(marketDetail.item?.duration / 60) * (marketDetail?.userInfo?.videoQuotationDollar || 0) : 0)
                                                ||(marketDetail.cooperationWay == 2 && marketDetail.live?.sendSample === 1 && marketDetail.asettleWay != 1 ? Math.floor(marketDetail.live?.durationH) * (marketDetail?.userInfo?.liveBroadcastQuotationDollar || 0) : 0)
                                                || 0):0}</span>（{marketDetail.asettleWay==0?`${textJsonL['红人视频单价']}$${marketDetail.userInfo?.videoQuotationDollar}/60s${textJsonL['内']} * ${intl.get('VideoDetail')['视频时长']}${marketDetail.item.duration}s${textJsonL['内']}`:`${intl.get('VideoDetail')['视频时长']}${marketDetail.item.duration}s${textJsonL['内']}`}）</span></div>
                                        </div> : ''}
                                </div> : 
                                    marketDetail.target == 2 && marketDetail.cooperationWay == 2 ? <div style={{ paddingRight: '8%', width:'100%', background:'#FFF' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='over' style={{ height:'25px', fontSize: '16px', fontWeight: '800', marginRight: '14px' }}>{marketDetail.marketList.brand}</div>
                                            {marketDetail.asettleWay == 0 ? <div><Tag color="green">{marketDetail.live.sendSample == 1 ? textJsonL['支持样品寄送'] : textJsonL['不支持样品寄送']}</Tag></div>
                                            : <div><Tag color="green">{textJsonL['支持样品寄送（寄样抵佣金）']}</Tag></div>}
                                        </div>
                                        <div className='over' style={{ marginTop: '10px', height: '40px' }}>
                                            {marketDetail.marketList.intro}
                                        </div>
                                        <div style={{ marginTop: '15px' }}>
                                            <span style={{background:'#FFE7E9', color:'#F7385B', fontSize:'12px', border:'1px solid #F7385B', padding:'1px 8px', borderRadius:'3px', display: 'inline-block', marginBottom:'4px', marginRight:'8px'}}>
                                                {textJsonR[Enum.propertiesTarget[marketDetail.target].val]} | {textJsonR['直播']}
                                            </span>
                                            <Tag>{textJsonL['所属行业：']}{marketDetail.marketList?.biz?.val}</Tag>
                                            <Tag>{textJsonL['媒体传播：']}{Enum.propertiesMedia[marketDetail.media].val}</Tag>
                                        </div>
                                        {marketDetail.merchantCooperationEnum == 0 || marketDetail.merchantCooperationEnum == 1 || marketDetail.merchantCooperationEnum == 5 ? <div>
                                            {marketDetail.sendWay == 2 ? <div style={{ marginTop: '10px', color: '#888888' }}>{textJsonR["需求有效日期"]}：<span style={{ fontFamily: 'neue' }}>{marketDetail.live?.bloggerApplicationTimeTo?.substring(0,10) || intl.get('SmallShop')['永久有效']}</span></div>:''}
                                            <div>
                                                {marketDetail.application?.status == 6 ?<div style={{ marginTop: '10px', color: '#888888' }}>{textJsonL['红人开播时间']}：<span style={{ fontFamily: 'neue' }}>{marketDetail.live.bloggerPubTime?.substring(0,10)}</span></div>:''}
                                                <div style={{marginTop: marketDetail.sendWay==2?'5px':'10px', color: '#888888' }}>{textJsonL['直播合作时长']}：<span style={{ fontFamily: 'neue', color: '#F7385B', fontWeight: '800' }}>{marketDetail.live.durationH}h{textJsonL['内']}</span></div>
                                            </div>
                                        </div> :
                                            marketDetail.merchantCooperationEnum == 2 || marketDetail.merchantCooperationEnum == 3 || marketDetail.merchantCooperationEnum == 4 ? <div>
                                                <div style={{ marginTop: '10px', color: '#888888' }}>{textJsonL['红人开播时间']}：<span style={{ fontFamily: 'neue' }}>{marketDetail.live.bloggerPubTime?.substring(0,10)}</span></div>
                                                <div style={{ marginTop: '5px', color: '#888888' }}>{textJsonL['总计合作佣金']}<span style={{ fontFamily: 'neue', color: '#F7385B', fontWeight: '800' }}><span style={{fontSize:'16px'}}>${marketDetail.asettleWay==0?(marketDetail.application.costDollar
                                                    || (marketDetail.cooperationWay == 1 && marketDetail.item?.sendSample === 1 && marketDetail.asettleWay != 1 ? Math.floor(marketDetail.item?.duration / 60) * (marketDetail?.userInfo?.videoQuotationDollar || 0) : 0)
                                                    ||(marketDetail.cooperationWay == 2 && marketDetail.live?.sendSample === 1 && marketDetail.asettleWay != 1 ? Math.floor(marketDetail.live?.durationH) * (marketDetail?.userInfo?.liveBroadcastQuotationDollar || 0) : 0)
                                                    || 0):0}</span>（{marketDetail.asettleWay==0?`${textJsonL['红人直播单价']}$${marketDetail.userInfo?.liveBroadcastQuotationDollar}/${intl.get('NewLogin')['1h内']} * ${intl.get('ReleaseDemand')['直播时长']}${marketDetail.live.durationH}h${textJsonL['内']}`:`${intl.get('ReleaseDemand')['直播时长']}${marketDetail.live.durationH}h${textJsonL['内']}`}）</span></div>
                                            </div> : ''}
                                    </div>
                                :''}
                            </div>
                            {/* 5 失效需求 */}
                            {marketDetail.merchantCooperationEnum == 5 ? <div style={{
                                height: '240px', display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: 'linear-gradient(90deg, #FFEEF1, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF)',
                                position: 'relative'
                            }}>
                                <img src={"https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hzsx.png"} alt="" />
                                <div style={{ width: '221px', fontSize: '13px', textAlign: 'center', marginTop: '18px', color: '#666666' }}>{state.userInfo.userType == 2 ? intl.get('InfluencerAccount')['非常抱歉，您的合作申请商家未通过，感谢你的参与'] : intl.get('InfluencerAccount')['非常抱歉，您的需求在有效期内未收到博主的合作请求，请重新编辑后再发布']}</div>
                                {state.userInfo.userType == 1 ? <div style={{ width: '160px', height: '38px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '18px' }}
                                    onClick={() => goToRelease()}>{textJsonL['重新编辑需求']}</div> : ''}
                                <div style={{minWidth:'74px', textAlign:'center', position:'absolute', top:0, right:0, background: statusList[marketDetail.merchantCooperationEnum].color, 
                                    fontSize:'12px', color:'#FFF', padding:'3px 12px', borderBottomLeftRadius:'4px'}}>
                                    {statusList[marketDetail.merchantCooperationEnum].text}
                                </div>
                            </div> :
                            // 2 合作中
                            marketDetail.merchantCooperationEnum == 2 ?
                                state.userInfo?.userType == 1 ? <div style={{
                                    display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: 'linear-gradient(90deg, #FFEEF1, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF)',
                                    position: 'relative'
                                }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', height: '60px', overflow: 'hidden', borderRadius: '60px' }}>
                                            <Image src={marketDetail?.userInfo?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} width={60} preview={false} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '15px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='over1' style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{marketDetail.userInfo?.nickname}</div>
                                            </div>
                                            <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {marketDetail.userInfo?.tiktok}</div>
                                        </div>
                                    </div>
                                    <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', border: '1px solid #eee', color: '#666666' }}
                                        onClick={e => window.open('https://www.tiktok.com/@' + marketDetail.userInfo?.tiktok)}>{textJsonL['查看博主主页']}</div>
                                    <div style={{ fontSize: '12px', color: '#666666', marginTop: '20px', fontWeight: '500' }}>{marketDetail.cooperationWay == 1 ? textJsonL['博主创作中，将于'] : '红人将于'}
                                        <span style={{ color: '#F7385B', fontFamily: 'neue', fontSize: 14, fontWeight: 600 }}>{marketDetail.live?.bloggerPubTime?.substring(0,10)||marketDetail.item?.bloggerPubDeadline?.substring(0,10)}</span>
                                    </div>
                                    <div style={{ fontSize: '12px', color: '#666666', fontWeight: '500' }}>{marketDetail.cooperationWay == 1 ? textJsonL['前上传短视频作品，请耐心等待'] : textJsonL['进行直播，请耐心等待']}</div>
                                    <div style={{minWidth:'74px', textAlign:'center', position:'absolute', top:0, right:0, background: statusList[marketDetail.merchantCooperationEnum].color, 
                                        fontSize:'12px', color:'#FFF', padding:'3px 12px', borderBottomLeftRadius:'4px'}}>
                                        {statusList[marketDetail.merchantCooperationEnum].text}
                                    </div>
                                </div> :
                                // 
                                <div style={{
                                    display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: '#fff',
                                    position: 'relative'
                                }}>
                                    <div style={{ width: '1px', height: '160px', background: '#EEEEEE', position: 'absolute', left: '0' }}></div>
                                    <div style={{ color: '#666666', marginTop: '20px', fontWeight: '500', textAlign: 'center' }}>{textJsonL['请于']}
                                        <span style={{ color: '#F7385B', fontFamily: 'neue', fontSize: 14, fontWeight: 600 }}>{marketDetail.live?.bloggerPubTime?.substring(0,10)||marketDetail.item?.bloggerPubDeadline?.substring(0,10)}</span>
                                        <br />{marketDetail.cooperationWay == 1 ? textJsonL['前上传短视频'] : textJsonL['前进行直播']}
                                    </div>
                                    {marketDetail.cooperationWay == 1 ? <Upload {...uploadProps} onChange={e => onChange(e, marketDetail)}>
                                        <div style={{ minWidth:'160px', padding:'0 10px', height: '38px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '18px' }}>{textJsonL['上传短视频']}</div>
                                    </Upload>: <Upload {...uploadImgProps} onChange={e => onChange(e, marketDetail)}>
                                        <div style={{ minWidth:'160px', padding:'0 10px', height: '38px', lineHeight: '38px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', textAlign: 'center', cursor: 'pointer', marginTop: '18px' }}>{textJsonL["上传直播数据截图"]}</div>
                                    </Upload>}
                                    
                                    <div style={{minWidth:'74px', textAlign:'center', position:'absolute', top:0, right:0, background: statusList[marketDetail.merchantCooperationEnum].color, 
                                        fontSize:'12px', color:'#FFF', padding:'3px 12px', borderBottomLeftRadius:'4px'}}>
                                        {statusList[marketDetail.merchantCooperationEnum].text}
                                    </div>
                                </div>
                            :
                            // 3 合作中 待确认
                            marketDetail.merchantCooperationEnum == 3 ?
                                state.userInfo?.userType == 1 ? <div style={{
                                    display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: 'linear-gradient(90deg, #FFEEF1, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF)',
                                    position: 'relative'
                                }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', height: '60px', overflow: 'hidden', borderRadius: '60px' }}>
                                            <Image src={marketDetail.userInfo?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} width={60} preview={false} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '15px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='over1' style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{marketDetail.userInfo?.nickname}</div>
                                            </div>
                                            <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {marketDetail.userInfo?.tiktok}</div>
                                        </div>
                                    </div>
                                    <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', border: '1px solid #eee', color: '#666666' }}
                                        onClick={()=>window.open(`https://www.tiktok.com/@${marketDetail.userInfo?.tiktok}`)}>{textJsonL['查看博主主页']}</div>
                                    <div onClick={() => merchantSuccess()} style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '10px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)' }}>{textJsonL['确认合作完成']}</div>
                                   {marketDetail.cooperationWay == 1 ? <div onClick={()=>playVideo(marketDetail.confirmInfo)} style={{ fontSize: '12px', color: '#F7385B', fontWeight: '500', textAlign: 'center', marginTop: '5px', cursor: 'pointer' }}>{textJsonL['查看合作的短视频']}</div> :
                                    <div style={{ fontSize: '12px', color: '#666666', fontWeight: '500', textAlign: 'center', marginTop: '5px' }}>{textJsonL['直播已结束']},<span onClick={() => setVisible(true)} style={{ color: '#F7385B', cursor: 'pointer' }}>{textJsonL["查看直播数据"]}</span></div>}
                                    <Image
                                        width={200}
                                        style={{ display: 'none' }}
                                        src={marketDetail.confirmInfo}
                                        preview={{
                                            visible,
                                            src: marketDetail.confirmInfo,
                                            onVisibleChange: value => {
                                                setVisible(value);
                                            },
                                        }}
                                    />
                                    <div style={{minWidth:'74px', textAlign:'center', position:'absolute', top:0, right:0, background: statusList[marketDetail.merchantCooperationEnum].color, 
                                        fontSize:'12px', color:'#FFF', padding:'3px 12px', borderBottomLeftRadius:'4px'}}>
                                        {statusList[marketDetail.merchantCooperationEnum].text}
                                    </div>
                                </div> :
                                // 
                                <div style={{
                                    display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: '#fff',
                                    position: 'relative'
                                }}>
                                    <div style={{ width: '1px', height: '160px', background: '#EEEEEE', position: 'absolute', left: '0' }}></div>
                                    <div style={{ color: '#666666', marginTop: '20px', fontWeight: '500', textAlign: 'center' }}>{textJsonL['等待商家确认，否则佣金将在']}<span style={{ color: '#F7385B', fontFamily: 'neue', fontSize: 14, fontWeight: 600 }}>{textJsonL['7个工作日']}</span>{textJsonL['内到账']}</div>
                                    {marketDetail.cooperationWay == 1 ? <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '10px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)' }}
                                        onClick={e => playVideo(marketDetail.confirmInfo)}>{textJsonL['查看短视频']}</div> :
                                        <div style={{ color: '#666', marginTop: '10px', fontSize: '12px' }}>{textJsonL["直播已结束"]},<span onClick={() => setVisible(true)} style={{ color: '#F7385B', cursor: 'pointer' }}>{textJsonL["查看直播数据"]}</span></div>}
                                    <Image
                                        width={200}
                                        style={{ display: 'none' }}
                                        src={marketDetail.confirmInfo}
                                        preview={{
                                            visible,
                                            src: marketDetail.confirmInfo,
                                            onVisibleChange: value => {
                                                setVisible(value);
                                            },
                                        }}
                                    />
                                    <div style={{minWidth:'74px', textAlign:'center', position:'absolute', top:0, right:0, background: statusList[marketDetail.merchantCooperationEnum].color, 
                                        fontSize:'12px', color:'#FFF', padding:'3px 12px', borderBottomLeftRadius:'4px'}}>
                                        {statusList[marketDetail.merchantCooperationEnum].text}
                                    </div>
                                </div>
                            :
                            // 4 合作成功
                            marketDetail.merchantCooperationEnum == 4 ?
                                state.userInfo?.userType == 1 ? <div style={{
                                    display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: 'linear-gradient(90deg, #FFEEF1, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF)',
                                    position: 'relative'
                                }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', height: '60px', overflow: 'hidden', borderRadius: '60px' }}>
                                            <Image src={marketDetail.userInfo?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} width={60} preview={false} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '15px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='over1' style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{marketDetail.userInfo?.nickname}</div>
                                            </div>
                                            <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {marketDetail.userInfo?.tiktok}</div>
                                        </div>
                                    </div>
                                    <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', border: '1px solid #eee', color: '#666666' }}
                                        onClick={()=>window.open(`https://www.tiktok.com/@${marketDetail.userInfo?.tiktok}`)}>{textJsonL['查看博主主页']}</div>
                                    {marketDetail.cooperationWay == 1 ? <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '10px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)' }}
                                        onClick={e => playVideo(marketDetail.confirmInfo)}>{textJsonL['查看合作的短视频']}</div>:''}
                                    <div style={{minWidth:'74px', textAlign:'center', position:'absolute', top:0, right:0, background: statusList[marketDetail.merchantCooperationEnum].color, 
                                        fontSize:'12px', color:'#FFF', padding:'3px 12px', borderBottomLeftRadius:'4px'}}>
                                        {statusList[marketDetail.merchantCooperationEnum].text}
                                    </div>
                                </div> :
                                // 
                                <div style={{
                                    display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: '#fff',
                                    position: 'relative'
                                }}>
                                    <div style={{ width: '1px', height: '160px', background: '#EEEEEE', position: 'absolute', left: '0' }}></div>
                                    {marketDetail.cooperationWay == 1 ? <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '10px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)' }}
                                        onClick={e => playVideo(marketDetail.confirmInfo)}>{textJsonL['查看短视频']}</div>:
                                    <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '10px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)' }}
                                    onClick={() => setVisible(true)}>{textJsonL['查看直播数据']}</div>}
                                    <Image
                                        width={200}
                                        style={{ display: 'none' }}
                                        src={marketDetail.confirmInfo}
                                        preview={{
                                            visible,
                                            src: marketDetail.confirmInfo,
                                            onVisibleChange: value => {
                                                setVisible(value);
                                            },
                                        }}
                                    />
                                    <div style={{minWidth:'74px', textAlign:'center', position:'absolute', top:0, right:0, background: statusList[marketDetail.merchantCooperationEnum].color, 
                                        fontSize:'12px', color:'#FFF', padding:'3px 12px', borderBottomLeftRadius:'4px'}}>
                                        {statusList[marketDetail.merchantCooperationEnum].text}
                                    </div>
                                </div>
                            :
                            // 1 待合作
                            (marketDetail.merchantCooperationEnum == 0 || marketDetail.merchantCooperationEnum == 1) ?
                                state.userInfo?.userType == 1 ? <div style={{
                                    display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: '#fff',
                                    position: 'relative'
                                }}>
                                    <div style={{ width: '1px', height: '160px', background: '#EEEEEE', position: 'absolute', left: '0' }}></div>
                                    <div style={{ color: '#666666', marginTop: '20px', fontWeight: '500', textAlign: 'center' }}>{textJsonR['博主截稿日期']}<div style={{ color: '#F7385B', fontFamily: 'neue', fontSize: 14, fontWeight: 600 }}>{marketDetail.live?.bloggerPubTime?.substring(0,10)||marketDetail.item?.bloggerPubDeadline?.substring(0,10)}</div></div>
                                    <Badge count={props.marketBloggerList?.length} overflowCount={999} offset={[-10, 15]}>
                                        <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', border: '1px solid #eee', color: '#666666' }}>{textJsonL['申请合作的博主']}</div>
                                    </Badge>
                                    <div style={{minWidth:'74px', textAlign:'center', position:'absolute', top:0, right:0, background: statusList[marketDetail.merchantCooperationEnum].color, 
                                        fontSize:'12px', color:'#FFF', padding:'3px 12px', borderBottomLeftRadius:'4px'}}>
                                        {statusList[marketDetail.merchantCooperationEnum].text}
                                    </div>
                                </div> :
                                // 
                                marketDetail.application ? <div style={{
                                    display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: '#fff',
                                    position: 'relative'
                                }}>
                                    <div style={{ width: '1px', height: '160px', background: '#EEEEEE', position: 'absolute', left: '0' }}></div>
                                    <div style={{ color: '#666' }}>{textJsonL['总计合作佣金']} <span style={{ color: '#F7385B', fontSize: '16px' }}>${marketDetail.application?.costDollar
                                        || (marketDetail.cooperationWay == 1 && marketDetail.item?.sendSample === 1 && marketDetail.asettleWay != 1 ? Math.floor(marketDetail.item?.duration / 60) * (marketDetail?.userInfo?.videoQuotationDollar || 0) : 0)
                                        ||(marketDetail.cooperationWay == 2 && marketDetail.live?.sendSample === 1 && marketDetail.asettleWay != 1 ? Math.floor(marketDetail.live?.durationH) * (marketDetail?.userInfo?.liveBroadcastQuotationDollar || 0) : 0)
                                        || 0}</span></div>
                                    {marketDetail.application?.status == 1 ? <div style={{ color: '#F7385B', marginTop:'10px' }}>{textJsonL['等待商家处理']}</div>:''}
                                    {marketDetail.sendWay == 1 && marketDetail.application?.status == 6?<div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', background: 'linear-gradient(180deg, #F7385B, #FF716E)', color: '#FFF' }}
                                        onClick={()=>setApply(true)}>{textJsonL['同意与TA合作']}</div>:''}
                                    {marketDetail.sendWay == 1 && marketDetail.application?.status == 6?<div onClick={e => refuse()} style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', border: '1px solid #eee', color: '#666666' }}
                                        >{textJsonL['拒绝与TA合作']}</div>:''}
                                    <div style={{minWidth:'74px', textAlign:'center', position:'absolute', top:0, right:0, background: statusList[marketDetail.application.status].color, 
                                        fontSize:'12px', color:'#FFF', padding:'3px 12px', borderBottomLeftRadius:'4px'}}>
                                        {marketDetail.application?.status == 1 ? intl.get('DemandHall')['已申请'] : statusList[marketDetail.application.status].text}
                                    </div>
                                </div>
                                    :
                                <div style={{
                                    display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: '#fff',
                                    position: 'relative'
                                }}>
                                    <div style={{ width: '1px', height: '160px', background: '#EEEEEE', position: 'absolute', left: '0' }}></div>
                                    <div style={{ color: '#666666', marginTop: '20px', fontWeight: '500', textAlign: 'center' }}>{textJsonL['期望截稿日期']}<div style={{ color: '#F7385B', fontFamily: 'neue', fontSize: 14, fontWeight: 600 }}>{marketDetail.live?.bloggerPubTime?.substring(0,10)||marketDetail.item?.bloggerPubDeadline?.substring(0,10)}</div></div>
                                    {marketDetail.sendWay == 2 || marketDetail.sendWay == 0 || state.userInfo?.userType == 3 ?<div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', background: 'linear-gradient(180deg, #F7385B, #FF716E)', color: '#FFF' }}
                                        onClick={()=>setApply(true)}>{textJsonL['申请与TA合作']}</div>:''}
                                    {marketDetail.sendWay == 1 && state.userInfo?.userType != 3?<div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', background: 'linear-gradient(180deg, #F7385B, #FF716E)', color: '#FFF' }}
                                        onClick={()=>setApply(true)}>{textJsonL['同意与TA合作']}</div>:''}
                                    {marketDetail.sendWay == 1?<div onClick={e => refuse()} style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', border: '1px solid #eee', color: '#666666' }}
                                        >{textJsonL['拒绝与TA合作']}</div>:''}
                                    <div style={{minWidth:'74px', textAlign:'center', position:'absolute', top:0, right:0, background: statusList[marketDetail.merchantCooperationEnum].color, 
                                        fontSize:'12px', color:'#FFF', padding:'3px 12px', borderBottomLeftRadius:'4px'}}>
                                        {statusList[marketDetail.merchantCooperationEnum].text}
                                    </div>
                                </div>
                                // marketDetail?.application?.status == 6 ? <div style={{
                                //     display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: '#fff',
                                //     position: 'relative'
                                // }}>
                                //     <div style={{ width: '1px', height: '160px', background: '#EEEEEE', position: 'absolute', left: '0' }}></div>
                                //     <div style={{ color: '#666666', marginTop: '20px', fontWeight: '500', textAlign: 'center' }}>{textJsonL['期望截稿日期']}<div style={{ color: '#F7385B', fontFamily: 'neue', fontSize: 14, fontWeight: 600 }}>{marketDetail.live?.bloggerPubTime?.substring(0,10)||marketDetail.item?.bloggerPubDeadline?.substring(0,10)}</div></div>
                                //     {marketDetail.sendWay == 2?<div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', background: 'linear-gradient(180deg, #F7385B, #FF716E)', color: '#FFF' }}
                                //         onClick={()=>setApply(true)}>{textJsonL['申请与TA合作']}</div>:''}
                                //     {marketDetail.sendWay == 1?<div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', background: 'linear-gradient(180deg, #F7385B, #FF716E)', color: '#FFF' }}
                                //         onClick={()=>setApply(true)}>{textJsonL['同意与TA合作']}</div>:''}
                                //     {marketDetail.sendWay == 1?<div onClick={e => refuse()} style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', border: '1px solid #eee', color: '#666666' }}
                                //         >{textJsonL['拒绝与TA合作']}</div>:''}
                                //     <div style={{minWidth:'74px', textAlign:'center', position:'absolute', top:0, right:0, background: statusList[marketDetail?.application?.status].color, 
                                //         fontSize:'12px', color:'#FFF', padding:'3px 12px', borderBottomLeftRadius:'4px'}}>
                                //         {statusList[marketDetail?.application?.status].text}
                                //     </div>
                                // </div> :
                                // <div style={{
                                //     display: 'flex', flexShrink: '0', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: 320, background: '#fff',
                                //     position: 'relative'
                                // }}>
                                //     <div style={{ width: '1px', height: '160px', background: '#EEEEEE', position: 'absolute', left: '0' }}></div>
                                //     <div style={{ color: '#666' }}>{textJsonL['总计合作佣金']} <span style={{ color: '#F7385B', fontSize: '16px' }}>${marketDetail.application?.costDollar}</span></div>
                                //     {marketDetail?.application?.status == 1 ? <div style={{ color: '#F7385B', marginTop:'15px' }}>{textJsonL['等待商家处理']}</div>:''}
                                //     <div style={{minWidth:'74px', textAlign:'center', position:'absolute', top:0, right:0, background: statusList[marketDetail.merchantCooperationEnum].color, 
                                //         fontSize:'12px', color:'#FFF', padding:'3px 12px', borderBottomLeftRadius:'4px'}}>
                                //         {statusList[marketDetail.merchantCooperationEnum].text}
                                //     </div>
                                // </div>
                            : ''}

                        </div>
                    </div>
                    : ''
            }



            <Modal visible={videoVisible} centered={true} footer={null} onCancel={e => { setVideoVisible(false) }} destroyOnClose={true}
                getContainer={() => document.getElementById('Status1')}>
                <div style={{ padding: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <video height="500" controls>
                        <source src={url} />
                        <source src={url} />
                        您的浏览器不支持 video 标签。
                    </video>
                </div>
            </Modal>




            <Modal centered visible={isSuccess} footer={null} onCancel={e => { setScucess(false) }}
                getContainer={() => document.getElementById('Status1')}
                bodyStyle={{
                    padding: '0', backgroundColor: '#FFFFFF',
                    width: 443, height: 466,
                }}
            >
                <div style={{ textAlign: 'center', paddingTop: '55px' }}>
                    <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{textJsonR['申请合作发布成功']}</h3>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suc.png'} alt=""
                        style={{ width: '173px', marginTop: '40px' }} />
                    <div>{textJsonR['你的合作申请已送达至商家，请耐心等待商家的结果']}</div>
                    <div style={{
                        width: '210px', height: '40px', borderRadius: '4px', cursor: 'pointer',
                        textAlign: 'center', lineHeight: '40px', color: '#FFF', margin: '38px auto',
                        background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                    }}
                        onClick={() => {setTimeout(()=>{
                            window.location.reload()
                        },1000); setScucess(false)}} >
                        {textJsonR['前往我申请的合作']}
                    </div>
                </div>
            </Modal>

            


            <Modal
                centered
                visible={isApply}
                footer={null}
                onCancel={e => {
                    setApply(false);
                    setAddressIndex(-1)
                }}
                getContainer={() => document.getElementById('Status1')}
                bodyStyle={{
                    padding: '0', backgroundColor: '#FFFFFF',
                    width: 600,
                }}
            >
                <div style={{ textAlign: 'center', padding: '50px' }}>
                    <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{textJsonR['申请与商家合作']}</h3>
                    {state.userInfo?.userType == 3 ? <div style={{display:'flex', alignItems:'center', marginTop:'30px'}}>
                        <span style={{color: '#666666',}}>{intl.get('ReleaseDemand')['指定红人']}</span>
                        {bloggerItem ? <div style={{ display: 'flex', marginLeft:'10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '44px', height: '44px', overflow: 'hidden', borderRadius: '22px' }}>
                                <Image src={bloggerItem?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} width={44} height={44} style={{ borderRadius: '22px'}} preview={false} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='over1' style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{bloggerItem?.nickname}</div>
                                </div>
                                <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {bloggerItem?.tiktok}</div>
                            </div>
                        </div>:''}
                        <div style={{
                            width: '86px', height: '30px', borderRadius: '4px', cursor: 'pointer', fontSize:'12px',
                            textAlign: 'center', lineHeight: '30px', color: '#FFF', marginLeft:'20px',
                            background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                        }}
                            onClick={() => {getSearch(); setIsHotR(true)}} >
                            {bloggerItem?intl.get('ReleaseDemand')['更换红人']:intl.get('AccountRecharge')['选择红人']}
                        </div>
                    </div>:''}
                    <div style={{
                        color: '#666666',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: id.item?.sendSample || id.live?.sendSample || state.userInfo?.userType == 3 ? 'flex-start' : 'center',
                        marginTop: state.userInfo?.userType == 3?'30px':'53px',
                        fontWeight: 500
                    }}>
                        <div>{textJsonR['总计佣金']}</div>
                        {id.cooperationWay == 1 ?<div>
                            {
                                id.item?.sendSample === 1 ?
                                    id.asettleWay === 1 ?
                                        <div className='flex-ac'>
                                            <div style={{ fontSize: '20px', fontWeight: '800', color: '#F7385B', marginLeft: '8px' }}>
                                                $0
                                            </div>
                                            <div style={{ color: '#F7385B', fontSize: '14px', marginLeft: '10px' }}>({textJsonR['寄样抵佣金']})</div>
                                            <div style={{ color: '#888888', marginLeft: '8px' }}>
                                                ({intl.get('VideoDetail')['视频时长']}{store.getState().language === 'en' ? ' in' + id.item?.duration + 's' : id.item?.duration + 's'+textJsonL['内']})
                                            </div>
                                        </div>
                                        :
                                        <div className='flex-ac'>
                                            <div style={{ fontSize: '20px', fontWeight: '800', color: '#F7385B', marginLeft: '8px' }}>
                                                ${Math.floor(id.item?.duration / 60) * (userInfo.redAccountDetail?.videoQuotationDollar || bloggerItem?.videoQuotationDollar || 0)}
                                            </div>
                                            <div style={{ color: '#888888', marginLeft: '8px' }}>
                                                (
                                                {textJsonL['红人视频单价']}
                                                {
                                                    store.getState().language === 'en' ? ' in $' + (userInfo.redAccountDetail?.videoQuotationDollar || bloggerItem?.videoQuotationDollar || 0) + '/60s'
                                                        :
                                                        '$' + (userInfo.redAccountDetail?.videoQuotationDollar || bloggerItem?.videoQuotationDollar || 0) + '/'+intl.get('NewLogin')['60s内']
                                                } * 
                                                {
                                                    intl.get('VideoDetail')['视频时长']}{store.getState().language === 'en' ? ' in' + id.item?.duration + 's' : id.item?.duration + 's'+textJsonL['内']
                                                }
                                                )
                                            </div>
                                        </div>
                                    :
                                    <div className='flex-acjc'>
                                        <div style={{ fontSize: '20px', fontWeight: '800', color: '#F7385B', marginLeft: '8px' }}>
                                            ${Math.floor(id.item.duration / 60) * (userInfo.redAccountDetail?.videoQuotationDollar || bloggerItem?.videoQuotationDollar || 0)}
                                        </div>
                                        <div style={{ color: '#888888', marginLeft: '8px' }}>
                                            ({
                                                textJsonL['红人视频单价']}{store.getState().language === 'en' ?
                                                    ' in $' + (userInfo.redAccountDetail?.videoQuotationDollar || bloggerItem?.videoQuotationDollar || 0) + '/60s'
                                                    :
                                                    '$' + (userInfo.redAccountDetail?.videoQuotationDollar || bloggerItem?.videoQuotationDollar || 0) + '/'+intl.get('NewLogin')['60s内']} * {intl.get('VideoDetail')['视频时长']}{store.getState().language === 'en' ? ' in' + id.item.durationH + 's' : id.item.durationH + 's'+textJsonL['内']
                                            })
                                        </div>
                                    </div>
                            }
                        </div>:
                        <div>
                        {
                            id.live?.sendSample === 1 ?
                                id.asettleWay === 1 ?
                                    <div className='flex-ac'>
                                        <div style={{ fontSize: '20px', fontWeight: '800', color: '#F7385B', marginLeft: '8px' }}>
                                            $0
                                        </div>
                                        <div style={{ color: '#F7385B', fontSize: '14px', marginLeft: '10px' }}>({textJsonR['寄样抵佣金']})</div>
                                        <div style={{ color: '#888888', marginLeft: '8px' }}>
                                            ({intl.get('VideoDetail')['视频时长']}{store.getState().language === 'en' ? ' in' + id.live?.durationH + 'h' : id.live?.durationH + 'h'+textJsonL['内']})
                                        </div>
                                    </div>
                                    :
                                    <div className='flex-ac'>
                                        <div style={{ fontSize: '20px', fontWeight: '800', color: '#F7385B', marginLeft: '8px' }}>
                                            ${(id.live?.durationH * (userInfo.redAccountDetail?.liveBroadcastQuotationDollar || bloggerItem?.liveBroadcastQuotationDollar || 0)).toFixed(2)}
                                        </div>
                                        <div style={{ color: '#888888', marginLeft: '8px' }}>
                                            (
                                            {textJsonL['红人直播单价']}
                                            {
                                                store.getState().language === 'en' ? ' in $' + (userInfo.redAccountDetail?.liveBroadcastQuotationDollar || bloggerItem?.liveBroadcastQuotationDollar || 0) + '/1h'
                                                    :
                                                    '$' + (userInfo.redAccountDetail?.liveBroadcastQuotationDollar || bloggerItem?.liveBroadcastQuotationDollar || 0) + '/h内'
                                            } * 
                                            {
                                                intl.get('VideoDetail')['视频时长']}{store.getState().language === 'en' ? ' in' + id.live?.durationH + '1h' : id.live?.durationH + 'h'+textJsonL['内']
                                            }
                                            )
                                        </div>
                                    </div>
                                :
                                <div className='flex-acjc'>
                                    <div style={{ fontSize: '20px', fontWeight: '800', color: '#F7385B', marginLeft: '8px' }}>
                                        ${(id.live?.durationH * (userInfo.redAccountDetail?.liveBroadcastQuotationDollar || bloggerItem?.liveBroadcastQuotationDollar || 0)).toFixed(2)}
                                    </div>
                                    <div style={{ color: '#888888', marginLeft: '8px' }}>
                                        ({
                                            textJsonL['红人直播单价']}{store.getState().language === 'en' ?
                                                ' in $' + (userInfo.redAccountDetail?.liveBroadcastQuotationDollar || bloggerItem?.liveBroadcastQuotationDollar || 0) + '/1h'
                                                :
                                                '$' + (userInfo.redAccountDetail?.liveBroadcastQuotationDollar || bloggerItem?.liveBroadcastQuotationDollar || 0) + '/'+intl.get('NewLogin')['1h内']} * {intl.get('VideoDetail')['视频时长']}{store.getState().language === 'en' ? ' in' + id.live?.durationH + 'h' : id.live?.durationH + 'h'+textJsonL['内']
                                        })
                                    </div>
                                </div>
                        }
                    </div>}
                    </div>
                    {id.item?.sendSample == 1 || id.live?.sendSample == 1 ? <div>
                        <div style={{
                            color: '#666666',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '33px',
                            fontWeight: 500
                        }}>
                            {textJsonR['样品收件信息']}<span style={{ fontSize: '12px', color: '#888' }}>（{textJsonR['商家提供样品，请正确输入你的收件信息']}）</span>
                            <div style={{
                                width: '88px', height: '30px', borderRadius: '4px', cursor: 'pointer',
                                textAlign: 'center', lineHeight: '30px', color: '#F7385B',
                                border:'1px solid #F7385B', fontWeight: '500', fontSize:'13px'
                            }}
                                onClick={() => setIsAddress(true)} >
                                {intl.get('SmallShop')['添加地址']}
                            </div>
                        </div>
                        {
                            addressList?.map((item,index)=>{
                                return <div key={index} style={{width:'100%', display:'flex', alignItems:'center', marginTop:'17px', textAlign:'left', marginBottom:'10px'}}>
                                <div style={{width:'100%', display:'flex', alignItems:'center', height:'78px', border: '1px solid #E2E2E2', borderRadius:'4px', borderLeft:0,
                                }}>
                                    <img style={{height:'100%'}} src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/product/address.png" />
                                    <div style={{width:'100%', marginLeft:'29px'}}>
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            <div style={{width:'40%'}}>N : {item.contactName}</div><div>T : {item.phone}</div>
                                        </div>
                                        <div>{item.address}, {item.postCode}</div>
                                    </div>
                                </div>
                                <Radio checked={addressIndex == index} 
                                    onChange={e=>setAddressIndex(index)} style={{marginLeft:'19px'}} />
                            </div>
                            })
                        }
                    </div> : ''}
                    <div style={{
                        width: '210px', height: '40px', borderRadius: '4px', cursor: 'pointer',
                        textAlign: 'center', lineHeight: '40px', color: '#FFF', margin: '38px auto', marginBottom: '12px',
                        background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                    }}
                        onClick={() => submit()} >
                        {textJsonR['确定申请']}
                    </div>
                    <div style={{ fontSize: '12px', color: '#888888' }}>
                        {textJson['温馨提示：当商家同意与你合作，请准时在截稿日期前上传你的短视频作品，过期没有上传将会列入平台黑名单']}
                    </div>
                </div>
            </Modal>
            <Modal
                centered
                visible={isAddress}
                footer={null}
                onCancel={e => {
                    setIsAddress(false);
                    setAddParams({})
                }}
                getContainer={() => document.getElementById('Status1')}
                bodyStyle={{
                    padding: '0', backgroundColor: '#FFFFFF',
                    width: 600,
                }}
            >
                <div id='login' style={{ textAlign: 'center', padding: '50px' }}>
                    <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom:'30px' }}>{intl.get('SmallShop')['添加地址']}</h3>
                    <div>
                        <div style={{ marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Input value={addParams.consigneeName} onChange={e => applyChange(e.target.value, 'consigneeName')} style={{ width: '244px', height: '48px', borderRadius: '4px' }} placeholder={textJsonR['请输入收件人姓名']} />
                            <Input value={addParams.consigneePhone} onChange={e => applyChange(e.target.value, 'consigneePhone')} style={{ width: '244px', height: '48px', borderRadius: '4px' }} placeholder={textJsonR['请输入收件人手机号']} />
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <Input value={addParams.consigneePostCode} onChange={e => applyChange(e.target.value, 'consigneePostCode')} style={{ width: '100%', height: '48px', borderRadius: '4px' }} placeholder={textJsonR['请输入邮编']} />
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <Input value={addParams.consigneeSpecificAddress} onChange={e => applyChange(e.target.value, 'consigneeSpecificAddress')} style={{ width: '100%', height: '48px', borderRadius: '4px' }} placeholder={textJsonR['请输入具体的街道门牌']} />
                        </div>
                    </div>
                    <div style={{
                        width: '210px', height: '40px', borderRadius: '4px', cursor: 'pointer',
                        textAlign: 'center', lineHeight: '40px', color: '#FFF', margin: '38px auto', marginBottom: '12px',
                        background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                    }}
                        onClick={() => addAddress()} >
                        {intl.get('SmallShop')['确定添加']}
                    </div>
                </div>
            </Modal>




            <Drawer placement="right" visible={isHotR} closable={false} width={550}>
                <div id='login' style={{padding:'30px 10px'}}>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <span style={{fontSize:'18px', color:'#333'}}>{intl.get('ReleaseDemand')['指定红人']}</span>
                        <CloseCircleOutlined onClick={()=>setIsHotR(false)}
                            style={{color:'#888888', fontWeight:700, fontSize:'20px', cursor:'pointer'}} />
                    </div>     
                    <div style={{width:'345px', display:'flex', margin:'0 auto', marginTop:'70px'}}>
                        <Input onChange={e=>handleSearch(e.target.value)}
                            style={{width:'294px', height:'48px', borderTopLeftRadius:'4px', borderBottomLeftRadius:'4px'}} 
                            placeholder={intl.get('AccountRecharge')['请输入红人昵称或账号搜索全网红人']} />
                        <div style={{width:'50px', height:'48px', borderTopRightRadius:'4px', borderBottomRightRadius:'4px', cursor:'pointer',
                            textAlign:'center', lineHeight:'45px', color: '#FFF',
                            background: '#F7385B', fontWeight:'blod' }} >
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sousuo.png'} style={{width:'16px'}} alt="" />
                        </div>
                    </div>    
                    <Spin spinning={fetching}>
                    {searchList.length > 0 ? <div style={{width:'100%', marginTop:'25px' }}>
                        {
                            searchList.map((item,index)=>{
                                return <div key={index} style={{width:'100%', height:'85px', display:'flex', alignItems:'center', justifyContent:'space-between'}}
                                >
                                    <Image src={item.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                                    style={{ width: '44px', height: '44px', borderRadius: '22px' }} />
                                    <div style={{width:'calc(100% - 54px)', height:'100%', display:'flex', alignItems:'center', justifyContent:'space-between',
                                        borderBottom:'1px dashed #CCCCCC'}}>
                                        <div style={{width:'160px'}}>
                                            <div className='over1' style={{fontWeight:600, color:'#333'}}>{item.nickname}</div>
                                            <div style={{fontSize:'12px', color:'#888888', marginTop:'-4px'}}>TikTok: {item.tiktok}</div>
                                        </div>  
                                        {id.cooperationWay === 1 ? <div style={{textAlign:'center'}}>
                                            <div style={{fontWeight:600, color:'#F7385B'}}>${three(item.videoQuotationDollar)}/{intl.get('NewLogin')['60s内']}</div>
                                            <div style={{fontSize:'12px', color:'#888888', marginTop:'-3px'}}>{intl.get('ReleaseDemand')['视频制作单价']}</div>
                                        </div>:
                                            <div style={{textAlign:'center'}}>
                                            <div style={{fontWeight:600, color:'#F7385B'}}>${three(item.liveBroadcastQuotationDollar)}/{intl.get('NewLogin')['1h内']}</div>
                                            <div style={{fontSize:'12px', color:'#888888', marginTop:'-3px'}}>{intl.get('ReleaseDemand')['直播制作单价']}</div>
                                        </div>}
                                        <div onClick={()=>addBloggerS(item,index)} style={{width:'85px', height:'28px', borderRadius:'4px', cursor:'pointer',
                                            textAlign:'center', lineHeight:'28px', color: '#FFF', fontSize:'12px',
                                            background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:'blod' }} >
                                            确认选择
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div> :          
                    <div style={{width:'345px', textAlign:'center', margin:'0 auto', marginTop:'100px' }}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                        <div style={{marginTop:'24px', textAlign: 'center', color:'#333' }}>{intl.get('ReleaseDemand')['暂无数据，请搜索添加']}</div>
                    </div>  }        
                    </Spin>           
                </div>
            </Drawer>


        </div>
    )
}
export default withRouter(StatusVideoDetail);