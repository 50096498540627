import React, {useState, useEffect} from 'react'
import withRouter from '../../../utils/withRouter'
import * as Api from "../../../service/https";
import '../../../asset/css/GoodsRankingDetail.scss'
import {Image, Spin, Modal} from "antd";
import {DownOutlined} from "@ant-design/icons"
import intl from "react-intl-universal";
import ImageMagnifier from "../../../components/ImageMagnifier";
import GoodsRankingInfo from "../../../components/Goods/GoodsRankingInfo";
import GoodsRankingData from "../../../components/Goods/GoodsRankingData";
import GoodsRankingBlogger from "../../../components/Goods/GoodsRankingBlogger";
import GoodsRankingVideo from "../../../components/Goods/GoodsRankingVideo";
import {collectGoodsRanking, getGoodsRankingDetail, getGoodsRelatedBlogger} from "../../../service/https";
import toKMB from "../../../utils/KMB";
import three from "../../../utils/three";
import store from "../../../utils/redux";
import openNotificationWithIcon from '../../../components/Notification'

const GoodsRankingDetail = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);
    const [goods_detail, goods_detailSet] = useState({})
    const [shop_detail, shop_detailSet] = useState({})
    const [active_index, active_indexSet] = useState(0)
    const [show_img, show_imgSet] = useState()
    const [goodsType] = useState([intl.get('GoodsRankingDetail')['商品详情'],intl.get('GoodsRankingDetail')['基础分析'],intl.get('GoodsRankingDetail')['关联博主'],/*intl.get('GoodsRankingDetail')['关联视频']*/])
    const [tabIndex, setTabIndex] = useState(0)
    const [skuIndex, setSkuIndex] = useState(-1)
    const [skuVisible, setSkuVisible] = useState(false)
    const [variations, variationsSet] = useState([])
    const [price, priceSet] = useState({})
    const [quantity, quantitySet] = useState({})

    useEffect(() => {
        getProduct()
    },[])

    const changeTab = (index) => {
        setTabIndex(index)
    }

    const getProduct = () => {
        Api.getGoodsRankingDetail({ id: props.params.id,productId:props.params.productId }).then(res => {
            if (res.code === 200) {
                res.data.productInfo.soldTotal = res.data.soldTotal
                res.data.productInfo.collect = res.data.collect
                goods_detailSet(res.data.productInfo)
                shop_detailSet(res.data.shopInfo)
                let t = res.data?.productInfo?.saleProps.map((item) => { return 0 })
                variationsSet(t)
            }
        })
    }
    const indexChange = e => {
        active_indexSet(e)
    }

    const addCollection = (e) => {
        e.stopPropagation()
        Api.collectGoodsRanking({id: props.params.id || ''}).then(res => {
            if (res.code === 200) {
                openNotificationWithIcon('success', intl.get('GoodsRanking')[goods_detail.collect ? '取消成功！' : '收藏成功！'])
                let obj = Object.assign({},goods_detail)
                obj.collect = !obj.collect
                goods_detailSet(obj)
            }
        })
    }

    const toBusinessDetail = () => {
        window.open(`/index/SmallShopDetail/${shop_detail.id}`)
    }

    const openSkuModal = () => {
        setSkuVisible(true)
    }

    const variationsChange = (i, i1, img) => {
        if (img) {
            show_imgSet(img)
            active_indexSet(0)
        }
        let t = JSON.parse(JSON.stringify(variations))
        t[i] = i1
        let s = goods_detail?.saleProps[i].salePropValues[i1].propValueId
        let price = {}, quantity = {}
        goods_detail?.skus.some((item, index) => {
            if (item.salePropValueIds.includes(s)) {
                price = item.price
                quantity = item.quantityProperty
                return true
            }
        })
        priceSet(price)
        quantitySet(quantity)
        variationsSet(t)
    }

    return  goods_detail ? <div id='GoodsRankingDetail'>
        <div style={{width:'100%', display:'flex',justifyContent:'space-between', letterSpacing:'0.6px'}}>
            <div style={{width:'330px'}}>
                <div style={{width:'330px',left:'250px',top:'86px',backgroundColor:'#FFF',borderRadius:'4px'}}>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <div className='info-tips-box'>
                            <div className='info-tips'>{intl.get('GoodsRankingDetail')['商品信息']}</div>
                        </div>
                        {
                            state.userInfo?.userType == 1 ? <div style={{paddingTop: '10px', paddingRight: '23px', cursor: 'pointer'}}>
                            <img onClick={(e) => addCollection(e, goods_detail)}
                                 src={`https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc${goods_detail.collect ? 's' : ''}.png`}
                                 alt=""
                            />
                            </div> : ''
                        }
                    </div>
                    <div className='top'>
                        <div style={{ width: 178, height: 178, margin: '0 auto', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {
                                active_index === -1 ?
                                    <video width="178" height="178" controls>
                                        <source src={goods_detail?.productBase?.videoUrl} type="video/mp4" />
                                        <source src={goods_detail?.productBase?.videoUrl} type="video/ogg" />
                                        您的浏览器不支持 video 标签。
                                    </video>
                                    :
                                    <ImageMagnifier
                                        minImg={show_img ? show_img : goods_detail?.productBase?.images[active_index].uri}
                                        maxImg={show_img ? show_img : goods_detail?.productBase?.images[active_index].uri}
                                        width={178}
                                        height={178}
                                    />
                            }
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '13px' }}>
                            {
                                goods_detail?.productBase?.videoUrl ?
                                    <div className={`img_box ${active_index === -1 ? 'active' : ''}`} onClick={() => indexChange(-1)}>
                                        <img src={goods_detail?.productBase?.videoUrl + '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,m_fast'} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="" />
                                    </div>
                                    :
                                    ""
                            }
                            {
                                goods_detail?.productBase?.images.map((item, index) => (
                                    <div key={index} className={`img_box ${active_index === index ? 'active' : ''}`} onClick={() => indexChange(index)}>
                                        <img src={item.uri || ''} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='bottom'>
                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{goods_detail?.productBase?.title}</div>
                        {/*<div style={{display:'inline-block', margin:'16px 0 30px', fontSize: '12px', fontWeight: '400',padding: '3px 12px', color: '#F7385B', backgroundColor : 'rgba(247,56,91,.1)', borderRadius : '10px' }}>{goods_detail?.productBase?.title}</div>*/}
                        <div style={{color:'#F7385B', fontSize:'16px', margin:'28px 0'}}>{price?.realPrice?.priceStr && skuVisible ? price?.realPrice?.priceStr : goods_detail?.productBase?.price.realPrice}</div>
                        <div style={{
                            width: '100%', padding:'40px 0', textAlign: 'center',borderTop: '1px dashed #EEEEEE',borderBottom: '1px dashed #EEEEEE',
                        }}>
                            <div style={{marginBottom:'40px',display: 'flex', alignItems: 'center', justifyContent: 'space-between',color:'#888888',fontSize:'14px'}}>
                                <div style={{display:'flex',alignItems:'center'}}>{intl.get('GoodsRankingDetail')['国家']}：<img style={{width:'20px',height:'14px',marginRight:'6px'}} src={shop_detail.countryFlag} alt=""/><span style={{color:'#333333'}}>{lang === 'zh' ? shop_detail.countryName : shop_detail.countryEnName}</span></div>
                                <div onClick={openSkuModal} style={{cursor:'pointer'}}>{intl.get('GoodsRankingDetail')['规格']}：<span style={{color:'#333333', marginRight: '9px'}}>{goods_detail?.skus?.length}{intl.get('GoodsRankingDetail')['种']}</span><DownOutlined /></div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                                {/*<div style={{ width: '100px' , borderRight:'1px solid #EEEEEE'}}>*/}
                                {/*    <strong style={{ fontSize: '16px' }}>{toKMB(goods_detail?.seller.productCount,2)}</strong>*/}
                                {/*    <p style={{ fontSize: '12px', color: '#888888', marginBottom: '4px' }}>{intl.get('GoodsRankingDetail')['佣金率']}</p>*/}
                                {/*</div>*/}
                                <div style={{ width: '50%' , borderRight:'1px solid #EEEEEE' }}>
                                    <strong style={{ fontSize: '16px' }}>{toKMB(goods_detail?.productBase?.soldCount,2)}</strong>
                                    <p style={{ fontSize: '12px', color: '#888888', marginBottom: '4px' }}>{intl.get('GoodsRanking')['销量']}</p>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <strong style={{ fontSize: '16px' }}>{three(goods_detail?.soldTotal)}</strong>
                                    <p style={{ fontSize: '12px', color: '#888888', marginBottom: '4px' }}>{intl.get('GoodsRanking')['销售额']}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className='shop-des' style={{paddingBottom:'44px'}}>*/}
                    {/*    <div style={{display:'flex', justifyContent:'center',alignItems:'center',textAlign:'left'}}>*/}
                    {/*        <div style={{marginRight:'12px', width: 72, height: 72, borderRadius: '50%', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>*/}
                    {/*            <Image src={shop_detail.avatarMedium} preview={false} />*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <div style={{color:'#333333', fontSize:'16px'}}>{shop_detail.nickname}</div>*/}
                    {/*            <div style={{marginTop:'10px',display:'flex', justifyContent:'flex-start',fontSize:'12px',color:'#888888'}}>*/}
                    {/*                <div style={{marginRight:'20px'}}>{intl.get('GoodsRankingDetail')['商品数']}: <span style={{color:'#333333'}}>{shop_detail.productCount}{intl.get('GoodsRankingDetail')['种']}</span></div>*/}
                    {/*                <div>{intl.get('GoodsRankingDetail')['销量']}: <span style={{color:'#333333'}}>{toKMB(shop_detail.soldCount,2)}</span></div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div style={{display:'inline-block',marginTop:'14px', color: '#333333',padding: '12px 36px', borderRadius: '5px', cursor: 'pointer',border: '1px solid #EEEEEE' }} onClick={() => toBusinessDetail()}>*/}
                    {/*        <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/zhuye.png" alt="" style={{ marginTop: '-4px', marginRight: '10px',width:'16px', height:'16px' }} />{intl.get('GoodsRankingDetail')['进入小店']}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div style={{width:'calc(100% - 350px)',  paddingBottom:'90px', position:'relative'}}>
                <div style={{padding:'11px 37px',background:'#FFF', marginBottom:'10px'}}>
                    <div style={{display: 'flex',}}>
                        {
                            goodsType.map((item, index) => (
                                <span
                                    key={index}
                                    className='tabItem'
                                    style={{
                                        padding: '12px 28px',
                                        borderRadius: '4px',
                                        marginRight: '10px',
                                        cursor: 'pointer',
                                        color: index === tabIndex ? '#E94753' : '#333',
                                        background: index === tabIndex ? '#FFE7E9' : 'none',
                                    }}
                                    onClick={() => changeTab(index)}
                                >{item}</span>
                            ))
                        }
                    </div>
                </div>
                <div className='sku-modal'>
                    <Modal width={800} visible={skuVisible} centered={true} footer={null} onCancel={e => { setSkuVisible(false)}} >
                        <div style={{padding:'23px 37px',background:'#FFF'}}>
                            {
                                goods_detail?.saleProps ? goods_detail?.saleProps.map((item, index) => (
                                    <div key={index}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ width: 100, color: '#666', lineHeight: '36px', marginRight:'16px' }}>{item.propName}</div>
                                            <div style={{ flex:1, display: 'flex', flexWrap: 'wrap', alignItems:'center' }}>
                                                {
                                                    item?.salePropValues.map((item2, index2) => (
                                                        // item2.image ?
                                                        //     <div key={index2} className={`img_box ${variations[index] === index2 ? 'active' : ''}`} style={{ width: 50, height: 50}} onClick={e => variationsChange(index, index2, item2.image.uri)}>
                                                        //         <img src={item2.image.uri} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                        //     </div>
                                                        //     :
                                                            <div key={index2}
                                                                 style={{
                                                                     border: `1px solid ${variations[index] === index2 ? '#F7385B' : '#eee'}`,
                                                                     padding: '6px 15px',
                                                                     marginRight: '10px',
                                                                     marginBottom: '10px',
                                                                     cursor: 'pointer',
                                                                     color: variations[index] === index2 ? '#F7385B' : '#333',
                                                                     background: variations[index] === index2 ? '#FFEEF1' : '#fff'
                                                                 }}
                                                                 className={`size_box ${variations[index] === index2 ? 'size_box_active' : ''}`} onClick={e => variationsChange(index, index2)}>{item2.propValue}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div style={{margin: '10px 0' }}></div>
                                    </div>
                                )): ''
                            }
                        </div>
                    </Modal>
                </div>
                {/*商品详情*/}
                {tabIndex === 0 ?  <GoodsRankingInfo  goods_detail={goods_detail} /> : ''}
                {/*基础分析*/}
                {tabIndex === 1 ?  <GoodsRankingData id={props.params.id}  goods_detail={goods_detail} /> : ''}
                {/*关联博主*/}
                {tabIndex === 2 ?  <GoodsRankingBlogger id={props.params.id} /> : ''}
                {/*关联视频*/}
                {tabIndex === 3 ?  <GoodsRankingVideo /> : ''}
            </div>
        </div>
    </div> : <Spin spinning={true} tip={'加载中...'} />
}
export default withRouter(GoodsRankingDetail);