import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import { DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import { Modal, message, List, notification, Avatar, Skeleton, Divider } from 'antd';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import intl from 'react-intl-universal';
import { upload } from '@testing-library/user-event/dist/upload';
import toKMB from '../../utils/KMB';
import moment from 'moment';
import html2canvas from 'html2canvas'

const ShareMusicModel = (props) => {
    console.log(props);
    const shareObj = props.shareObj
    const dataList = props.dataList
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh'

    const uploads = () => {
        message.loading({ content: 'Loading...', key: 'updatable' });
        canvasImgFn()
    }

    const dataURLToBlob = (dataurl) => {
        let arr = dataurl.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    const canvasImgFn = () => {
        let a = document.createElement('a');
        html2canvas(document.getElementById("share"), {
            width: 695, //设置canvas的宽度
            scale: 1,//缩放
            allowTaint: true,
            useCORS: true,
            height: 3190, //设置截图的长度
        }).then((canvas) => {
            let dom = document.body.appendChild(canvas);
            dom.style.display = 'none';
            a.style.display = 'none';
            document.body.removeChild(dom);
            let blob = dataURLToBlob(dom.toDataURL('image/png'));
            a.setAttribute('href', URL.createObjectURL(blob));
            //这块是保存图片操作  可以设置保存的图片的信息
            a.setAttribute('download', `${intl.get('Workbench')[shareObj.shareName] || 'Share'}.png`);
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(blob);
            document.body.removeChild(a);
        })
    }

    return <div>
        <Modal centered visible={true} footer={null} closable={false} getContainer={() => document.getElementById(shareObj.shareName)} width={350} bodyStyle={{padding: '0', backgroundColor: '#FFFFFF', width: 350, height: 1698, margin: '130px 0', overflow: 'hidden'}}>
            <div style={{ width: 695, transform: 'scale(0.5)', position: 'absolute', top: -849, left: -172 }}>
                <div style={{ width: 695 }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share1.png?1111'} alt="" crossOrigin="anonymous"
                        style={{ width: '100%', display: 'inline-block' }} />
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share2.png?1111'} alt="" crossOrigin="anonymous"
                        style={{ width: '100%', display: 'inline-block', marginTop: '-1px' }} />
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share3.png?1111'} alt="" crossOrigin="anonymous"
                        style={{ width: '100%', display: 'inline-block', marginTop: '-1px' }} />
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share4.png?1111'} alt="" crossOrigin="anonymous"
                        style={{ width: '100%', display: 'inline-block', marginTop: '-1px' }} />
                    <div style={{ position: 'absolute', top: 0, left: 0 }}>
                        <div style={{
                            marginTop: '256px', marginLeft: '85px', fontSize: '35px',
                            color: '#444444', fontWeight: 'bold'
                        }}>{intl.get('Workbench')[shareObj.shareName]}</div>
                        <div style={{
                            textAlign: 'center', color: '#FFF', fontSize: '26px', fontWeight: 'bold',
                            marginTop: '30px', marginLeft: '77px'
                        }}>
                            {shareObj.topTitle}TOP10 <div style={{ fontSize: '26px' }}>{moment().format("YYYY-MM-DD")}{shareObj.topDayType}</div>
                        </div>
                    </div>
                    <div style={{ width: '100%', position: 'absolute', top: '555px', left: 0, padding: '0 30px' }}>
                        {
                            dataList?.map((item, index) => {
                                return index < 10 ? <div key={index} style={{
                                    width: '100%', height: '210px', backgroundColor: '#FFF',
                                    borderRadius: '10px', display: 'flex', alignItems: 'center', padding: '35px 34px',
                                    marginBottom: '16px', position: 'relative'
                                }}>
                                    <img src={(shareObj.itemImg ? item[shareObj.itemImg] : (shareObj.defaultImg || '')) + '?1111'} alt="" crossOrigin="anonymous"
                                        style={{ width: '140px', height: '140px', borderRadius: '70px' }} />
                                    <div style={{ marginLeft: '20px', marginRight:'10px', flex:1 }}>
                                        <div className='over' style={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '25px' }}>{item[shareObj.itemTitle]}</div>
                                        { shareObj.itemSubTitle ? <div style={{ color: '#888888', fontWeight: 500, fontSize: '16px', marginTop: '10px' }}>{shareObj.itemSubTitlePre || ''}{item[shareObj.itemSubTitle]}{shareObj.itemSubTitleAft || ''}</div> : '' }
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', justifyContent:'space-between' }}>
                                            <div style={{ fontSize: '16px', color: '#888888', display: 'flex', alignItems: 'center' }}>
                                                <img src={shareObj.itemLeftImg} alt="" crossOrigin="anonymous"
                                                    style={{ width: '18px', height: '18px' }} />
                                                <span style={{ marginLeft: '5px', marginRight: '8px' }}>{shareObj.itemLeftTag}</span>
                                                <span style={{ fontSize: '18px', color: '#333333', fontWeight: 'bold' }}>{toKMB(item[shareObj.itemLeftValue], 2)}</span>
                                            </div>
                                            <div style={{ fontSize: '16px', color: '#888888', display: 'flex', alignItems: 'center' }}>
                                                <img src={shareObj.itemRightImg} alt="" crossOrigin="anonymous"
                                                    style={{ width: '18px', height: '18px' }} />
                                                <span style={{ marginLeft: '5px', marginRight: '8px' }}>{shareObj.itemRightTag}</span>
                                                <span style={{ fontSize: '18px', color: '#333333', fontWeight: 'bold' }}>{shareObj.itemRightType === 'time' ? moment(item[shareObj.itemRightValue] * 1000).format('mm:ss') : toKMB(item[shareObj.itemRightValue], 2)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ position: 'absolute', top: '18px', right: '18px' }}>
                                        {index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png?1111'} alt="" crossOrigin="anonymous" /> :
                                            <div style={{
                                                width: '30px', height: '30px', borderRadius: '15px',
                                                lineHeight: '28px', textAlign: 'center', fontWeight: 500,
                                                backgroundColor: '#EEEEEE', fontSize: '20px', color: '#888888'
                                            }}>{index + 1}</div>
                                        }
                                    </div>
                                </div> : ''
                            })
                        }
                    </div>
                </div>
            </div>
            <div onClick={uploads} style={{
                width: lang == 'zh' ? '118px' : '168px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                textAlign: 'center', lineHeight: '40px', color: '#FFF', borderRadius: '4px', margin: '0 auto',
                cursor: 'pointer', marginTop: '26px', position: 'absolute', left: 'calc(100% + 24px)', top: '240px'
            }}>{intl.get('ShareModel')['下载图片']}</div>
            <div onClick={() => props.clickFun()} style={{
                width: '118px', height: '40px', background: '#FFF',
                textAlign: 'center', lineHeight: '40px', color: '#666', borderRadius: '4px', margin: '0 auto',
                cursor: 'pointer', marginTop: '26px', position: 'absolute', left: 'calc(100% + 24px)', top: '300px'
            }}>{intl.get('ShareModel')['取消分享']}</div>
        </Modal>
        {/*下载图片*/}
        <div id='share' style={{ width: 695, position: 'fixed', left: 10000 }}>
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share1.png?1111'} alt="" crossOrigin="anonymous"
                 style={{ width: '100%', display: 'inline-block' }} />
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share2.png?1111'} alt="" crossOrigin="anonymous"
                 style={{ width: '100%', display: 'inline-block', marginTop: '-1px' }} />
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share3.png?1111'} alt="" crossOrigin="anonymous"
                 style={{ width: '100%', display: 'inline-block', marginTop: '-1px' }} />
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share4.png?1111'} alt="" crossOrigin="anonymous"
                 style={{ width: '100%', display: 'inline-block', marginTop: '-1px' }} />
            <div style={{ position: 'absolute', top: 0, left: 0 }}>
                <div style={{
                    marginTop: '256px', marginLeft: '85px', fontSize: '35px',
                    color: '#444444', fontWeight: 'bold'
                }}>{intl.get('Workbench')[shareObj.shareName]}</div>
                <div style={{
                    textAlign: 'center', color: '#FFF', fontSize: '26px', fontWeight: 'bold',
                    marginTop: '30px', marginLeft: '77px'
                }}>
                    {shareObj.topTitle}TOP10 <div style={{ fontSize: '26px' }}>{moment().format("YYYY-MM-DD")}{shareObj.topDayType}</div>
                </div>
            </div>
            <div style={{ width: '100%', position: 'absolute', top: '555px', left: 0, padding: '0 30px' }}>
                {
                    dataList?.map((item, index) => {
                        return index < 10 ? <div key={index} style={{
                            width: '100%', height: '210px', backgroundColor: '#FFF',
                            borderRadius: '10px', display: 'flex', alignItems: 'center', padding: '35px 34px',
                            marginBottom: '16px', position: 'relative'
                        }}>
                            <img src={(shareObj.itemImg ? item[shareObj.itemImg] : (shareObj.defaultImg || '')) + '?1111'} alt="" crossOrigin="anonymous"
                                 style={{ width: '140px', height: '140px', borderRadius: '70px' }} />
                            <div style={{ marginLeft: '20px',flex:1, marginRight:'10px' }}>
                                <div className='over' style={{ fontWeight: 'bold', fontSize: '20px', lineHeight: '25px' }}>{item[shareObj.itemTitle]}</div>
                                { shareObj.itemSubTitle ? <div style={{ color: '#888888', fontWeight: 500, fontSize: '16px', marginTop: '10px' }}>{shareObj.itemSubTitlePre || ''}{item[shareObj.itemSubTitle]}{shareObj.itemSubTitleAft || ''}</div> : '' }
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', justifyContent:'space-between' }}>
                                    <div style={{ fontSize: '16px', color: '#888888', display: 'flex', alignItems: 'center' }}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/xiaoliang.png?1111'} alt="" crossOrigin="anonymous"
                                             style={{ width: '18px', height: '18px' }} />
                                        <span style={{ marginLeft: '5px', marginRight: '8px' }}>{shareObj.itemLeftTag}</span>
                                        <span style={{ fontSize: '18px', color: '#333333', fontWeight: 'bold' }}>{toKMB(item[shareObj.itemLeftValue], 2)}</span>
                                    </div>
                                    <div style={{ fontSize: '16px', color: '#888888', display: 'flex', alignItems: 'center'}}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/xiaoshoue.png?1111'} alt="" crossOrigin="anonymous"
                                             style={{ width: '18px', height: '18px' }} />
                                        <span style={{ marginLeft: '5px', marginRight: '8px' }}>{shareObj.itemRightTag}</span>
                                        <span style={{ fontSize: '18px', color: '#333333', fontWeight: 'bold' }}>{shareObj.itemRightType === 'time' ? moment(item[shareObj.itemRightValue] * 1000).format('mm:ss') : toKMB(item[shareObj.itemRightValue], 2)}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ position: 'absolute', top: '18px', right: '18px' }}>
                                {index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png?1111'} alt="" crossOrigin="anonymous" /> :
                                    <div style={{
                                        width: '30px', height: '30px', borderRadius: '15px',
                                        lineHeight: '28px', textAlign: 'center', fontWeight: 500,
                                        backgroundColor: '#EEEEEE', fontSize: '20px', color: '#888888'
                                    }}>{index + 1}</div>
                                }
                            </div>
                        </div> : ''
                    })
                }
            </div>
        </div>
    </div>
}
export default withRouter(ShareMusicModel);