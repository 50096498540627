import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import intl from 'react-intl-universal';
import { Line } from '@ant-design/plots';
import { Progress, Image } from 'antd';
import {getSupplyOrderTrend} from "../../../service/https";
import * as Api from '../../../service/https';

const DataOverview = ()=>{

    const config = {
        xField: 'date',
        yField: 'value',
        seriesField: 'category',
        color: ['#FC9A49', '#1B9EFF'],
    };

    const [chartConfig,setChartConfig] = useState(config)
    const [ordersData,setOrdersData] = useState([])
    const [salesData,setSalesData] = useState([])
    const getData = () =>{
        Api.getSupplyOrderTrend().then(res =>{
            if(res.code === 200){
                let vmData = []
                for(let key in res.data.ordersMonthTrack) vmData.push({ category:'订单量', date: key, value:res.data.ordersMonthTrack[key] })
                for(let key in res.data.salesMonthTrack) vmData.push({ category:'销售额', date: key,value:res.data.salesMonthTrack[key]})
                setChartConfig({...config, data: vmData})
            }
        })
    }

    useEffect(()=>{
        getData()
    },[])

    return <div style={{width:'100%'}}>

        <div style={{ display: 'flex', alignItems:'center' }}>
            <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/before.png" alt=""
            style={{width:'4px'}}/>
            <div style={{fontSize:'20px', fontWeight:600, marginLeft:'9px'}}>数据分析</div>
            {/* <span style={{color:'#888888', fontWeight:500, marginLeft:'15px', fontSize:'14px'}}>数据更新于2022-11-10 17:30:28</span>  */}
        </div>
        <div style={{width:'100%', padding: '20px 25px', backgroundColor: '#fff', marginTop: '10px' }}>

            <div style={{display:'flex', alignItems:'center', paddingLeft:'40px', marginTop:'40px'}}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/xiaoliangzoushi.png'} alt=""
                style={{ width: '30px', marginRight: '5px', }} />
                <span style={{fontWeight:500, color:'#888888'}}>订单量</span>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/dindanzoushi.png'} alt=""
                style={{ width: '30px', marginRight: '5px', marginLeft:'42px'}} />
                <span style={{fontWeight:500, color:'#888888'}}>销售额</span>
            </div>
            <div style={{padding:'40px 13px', position:'relative'}}>
                { chartConfig?.data ? <Line {...chartConfig} /> : '' }
                <div style={{ width:'200px', height:'30px',
                background:'#FFF', position:'absolute', top:'40px', left:0,}}></div>
            </div>
        </div>

    </div>
}
export default withRouter(DataOverview);
