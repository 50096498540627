import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import intl, { get } from 'react-intl-universal';
import { useNavigate } from 'react-router-dom'
import {
    Input, Select, Form, Button, Upload, message, notification, Image, Progress, Badge, Steps, Tooltip,
    Modal, Spin, List, Skeleton, Divider
} from 'antd';
import { CloseCircleOutlined, DownOutlined, UpOutlined, RightOutlined } from '@ant-design/icons';
import { getUserInfo, setUpUser } from '../../../service/https'
import * as Api from '../../../service/https'
import { baseUrl } from '../../../service/request'
import '../../../asset/css/Account.scss'
import store from '../../../utils/redux/index'
import Item from 'antd/lib/list/Item';
import toKMB from '../../../utils/KMB'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Column } from '@ant-design/plots';
import SampleDeliveryAddress from '../../../components/Personal/SampleDeliveryAddress'
const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input;

const MCNAccount = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh'
    const navigate = useNavigate();
    const [textJsonI, setTextJsonI] = useState(intl.get('InfluencerAccount'))
    const [textJson, setTextJson] = useState(intl.get('account'))
    const [textJsonNL, setTextJsonNL] = useState(intl.get('NewLogin'))
    const [lng, setLng] = useState(localStorage.getItem('locale'))
    const [params, setParams] = useState({})
    const [phone, setPhone] = useState('')
    const [userInfo, setUserInfo] = useState({})
    const [nicknameStatus, setNicknameStatus] = useState(false)
    const [nickname, setNickname] = useState()
    const [statInfo, setStatInfo] = useState({})
    const [newCargoClassifications, setNewCargoClassifications] = useState([])
    const [newMainMarkets, setNewMainMarkets] = useState([])
    const [visible, setVisible] = useState(false);
    const [tab1Index, setTab1Index] = useState(0)
    const intervalRef = useRef(null);

    useEffect(() => {
        getUserInfoFun()
        getNewsTotal()
        getMCNaddress()
        getMCNcontract()
        getMCNStatInfo()
        getCargo()
        marketSearch()
        return () => intervalRef ? clearInterval(intervalRef?.current) : ''
    }, [tab1Index])

    const getMCNcontract = e => {
        Api.getMCNcontract().then(res => {
            if (res.code === 200) {
                setNewCargoClassifications(res.data.cargoClassifications)
                setNewMainMarkets(res.data.mainMarkets)
                setUserInfo(res.data)
                setParams(res.data)
            }
        })
    }

    const getMCNStatInfo = e => {
        Api.getMCNStatInfo().then(res => {
            if (res.code === 200) {
                setStatInfo(res.data)
            }
        })
    }

    const getMCNaddress = e => {
        Api.getMCNaddress().then(res => {
            if (res.code === 200) {
                setInformation(res.data)
                if (res.data.recipientName && res.data.recipientPhone && res.data.postalCode && res.data.address) {
                    setIsAddress(false)
                } else {
                    setIsAddress(true)
                }
            }
        })
    }

    const [email, setEmail] = useState()

    const getUserInfoFun = () => {
        getUserInfo().then(res => {
            if (res.code === 200) {
                if (res.data.userType !== 3) {
                    navigate('/')
                }
                setEmail(res.data.email)
                store.dispatch({ type: 'userInfo', value: res.data })
            }
        })
    }

    const [unreadCount, setUnreadCount] = useState(0)

    const getNewsTotal = () => {
        Api.getNewsTotal().then(res => {
            if (res.code == 200) {
                setUnreadCount(res.data.unreadCount)
            }
        })
    }

    const [codes, setCode] = useState('')
    const [isCode, getCodes] = useState(true)
    const [time, setTime] = useState(60)
    const [password, setPassword] = useState('')

    const openNotificationWithIcon = (type, message = '') => {
        notification[type]({
            description: message, top: 100, duration: 3
        });
    };

    const uploadProps = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/avatar/upload',
        showUploadList: false,
        headers: {
            'x-token': localStorage.getItem('token')
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error('请上传正确的图片！');
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error('图片过大，请上传10MB以下的图片!');
            }
            return isJpgOrPng && isLt2M;
        },
        onChange(info) {
            if (info.file.status === 'done') {
                setTimeout(() => {
                    let t = JSON.parse(JSON.stringify(params))
                    t.avatar = info.file.response.data
                    setUserInfo(t)
                    editMCNcontract(info.file.response.data)
                }, 1000);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const [newCert, setNewCert] = useState('')

    const certConfig = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/avatar/upload',
        showUploadList: false,
        headers: {
            'x-token': localStorage.getItem('token')
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error('请上传正确的图片！');
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error('图片过大，请上传10MB以下的图片!');
            }
            return isJpgOrPng && isLt2M;
        },
        onChange(info) {
            if (info.file.status === 'done') {
                setNewCert(info.file.response.data)
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    }

    const logouts = () => {
        Api.logout().then(res => {
            if (res.code == 200) {
                localStorage.setItem("token", null)
                store.dispatch({ type: 'token', value: null })
                store.dispatch({ type: 'userInfo', value: null })
                props.navigate('/login')
                window.location.reload()
            }
        })
    }

    const [news1List, setNews1List] = useState([])

    const informationSearch = e => {
        Api.informationSearch().then(res => {
            if (res.code === 200) {
                setNews1List(res.data.informationList || [])
                setUnreadCount(res.data.unreadCount || 0)
            }
        })
    }

    const changeChecked = (bools, index, type) => {
        let v = JSON.parse(JSON.stringify(news1List))
        if ((v[index].informationType === 3 || v[index].informationType === 2) && v[index].extraInfo) {
            let s = JSON.parse(v[index].extraInfo)
            if (s.targetCode) {
                let obj = {
                    target: s.targetCode,
                    media: s.mediaCode,
                    cooperationWay: s.cooperationWayCode,
                    listId: s.listId,
                    demandId: s.demandId,
                }
                props.navigate('/index/videoDemandDetail', { state: obj })
            }
        }
        if (v[index].informationType === 4 && v[index].extraInfo) {
            let s = JSON.parse(v[index].extraInfo)
            window.open(`/index/sensationDetail/${s.redAccountId}/${s.tiktok}`)
        }
        if (v[index].readStatus === 2) {
            let data = {
                id: v[index].id,
                informationType: v[index].informationType,
                readStatus: 1
            }
            Api.updateStatus(data).then(res => {
                if (res.code == 200) {
                    v[index].readStatus = 1
                    v.unreadCount -= 1
                    setNews1List(v)
                }
            })
        } else {
            setNews1List(v)
        }
    }

    const tab1List = [
        { icon: 'info', title: intl.get('MCNAccount')['基本信息'] },
        { icon: 'zhuanzhang', title: intl.get('MCNAccount')['收款信息'] },
        { icon: 'dizhi', title: intl.get('MCNAccount')['寄样收货地址'] },
        { icon: 'xiaoxitongzhi', title: intl.get('MCNAccount')['消息通知'] + '(' + unreadCount + intl.get('MCNAccount')['条未读'] + ')' }
    ]

    useEffect(() => {
        if (tab1Index == 1 && (classList.length == 0 || geolocation.length == 0)) {
            getCountry()
        }
        setIsPassword(false)
        setIsInformation(false)
        setIsCommerce(false)
        setIsCollection(false)
        setIsAddress(false)
        if (tab1Index === 3) informationSearch()
    }, [tab1Index])

    const [information, setInformation] = useState({})

    const changeInfo = (key, val) => {
        let obj = JSON.parse(JSON.stringify(information))
        obj[key] = val
        setInformation(obj)
    }
    const changeUserInfo = (key, val) => {
        let obj = JSON.parse(JSON.stringify(params))
        obj[key] = val
        setParams(obj)
    }

    const [classList, setClassList] = useState([])

    const getCargo = () => {
        Api.getCargo().then(res => {
            if (res.code == 200) {
                setClassList(res.data)
            }
        })
    }

    const [marketList, setMarketList] = useState([])

    const marketSearch = () => {
        Api.marketSearch().then(res => {
            if (res.code === 200) {
                setMarketList(res.data)
            }
        })
    }

    const [geolocation, setGeolocation] = useState([])

    const getCountry = () => {
        Api.getCountry().then(res => {
            if (res.code == 200) {
                let all = res.data
                setGeolocation(all)
            }
        })
    }

    const [icon, setIcon] = useState('')
    const [nationalityEn, setNationalityEn] = useState('')
    const [nationalId, setNationalityId] = useState('')

    useEffect(() => {
        changeInfo('nationalFlag', icon)
    }, [icon])

    useEffect(() => {
        changeInfo('nationalityEn', nationalityEn)
    }, [information.nationalFlag])

    useEffect(() => {
        changeInfo('nationalId', nationalId)
    }, [information.nationalityEn])

    const [classId, setClassId] = useState('')
    const [cargoClassificationInterpretation, setCargoClassificationInterpretation] = useState('')

    const handleChanges = (value, e) => {
        // console.log(`selected ${value}`);
        if (value.length < 4) {
            changeInfo('cargoClassificationInterpretationEn', value == undefined ? [] : value)
            let idList = [], enLikst = []
            e.forEach(item => {
                idList.push(item.id)
                enLikst.push(item.name)
            })
            setClassId(idList.join(','))
            setCargoClassificationInterpretation(enLikst.join(','))
        }
    }

    useEffect(() => {
        changeInfo('cargoClassification', classId)
    }, [classId])

    useEffect(() => {
        changeInfo('cargoClassificationInterpretation', cargoClassificationInterpretation)
    }, [information.cargoClassification])

    const [loading, setLoading] = useState(false);
    const [isTixian, setTixian] = useState(false)

    useEffect(() => {
        if (isTixian) loadMoreData()
    }, [isTixian])

    const [totalTi, setTotalTi] = useState(0);
    const [dataTi, setDataTi] = useState([]);
    const [pageIndexTi, setPageIndexTi] = useState(1);

    const closeTixian = () => {
        setPageIndexTi(0)
        setTixian(false)
        setDataTi([])
    }

    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            current: pageIndexTi, size: 10,
        }
        Api.getCreditDetail(obj).then(res => {
            console.log(res)
            setLoading(false);
            if (res.code == 200) {
                setTotalTi(res.data.total)
                setDataTi(pageIndexTi == 1 ? res.data.records : [...dataTi, ...res.data.records]);
                setPageIndexTi(pageIndexTi + 1)
            }
        }).catch(() => {
            setLoading(false);
        });
    };

    const resetMCNPassword = e => {
        if (codes) {
            let data = {
                password: password,
                email: email,
                verifyCode: codes
            }
            Api.resetMCNPassword(data).then(res => {
                if (res.code === 200) {
                    openNotificationWithIcon('success', intl.get('MCNAccount')['修改成功！'])
                    window.location.href = '/login'
                    localStorage.setItem('token', '')
                }
            })
        } else {
            message.warn(intl.get('MCNAccount')['请填写验证码！'])
        }
    }

    const data = [
        {
            type: '',
            value: '350分',
        },
        {
            type: textJsonI['较差'],
            value: '550分',
        },
        {
            type: textJsonI['中等'],
            value: '600分',
        },
        {
            type: textJsonI['良好'],
            value: '650分',
        },
        {
            type: textJsonI['优秀'],
            value: '700分',
        },
        {
            type: textJsonI['极好'],
            value: '950分',
        },
    ];
    const paletteSemanticRed = '#FFEEF1';
    const brandColor = '#FFEEF1';
    const config = {
        data,
        xField: 'type',
        yField: 'value',
        seriesField: '',
        color: ({ type }) => {
            if (type === '10-30分' || type === '30+分') {
                return paletteSemanticRed;
            }

            return brandColor;
        },
        label: {
            content: (originData) => {
                const val = parseFloat(originData.value);

                if (val < 0.05) {
                    return (val * 100).toFixed(1) + '%';
                }
            },
            offset: 10,
        },
        legend: false,
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
    };

    const [isPassword, setIsPassword] = useState(false)
    const [isInformation, setIsInformation] = useState(false)
    useEffect(() => {
        if (isPassword) setIsInformation(false)
    }, [isPassword])
    useEffect(() => {
        if (isInformation) setIsPassword(false)
    }, [isInformation])

    const [isCommerce, setIsCommerce] = useState(false)
    const [isCollection, setIsCollection] = useState(false)
    const [isAddress, setIsAddress] = useState(false)

    // 获取验证码
    const getEmailCode = () => {
        if (isCode && email != '' && email) {
            getCodes(false)
            setTime(60)
            let times = 60
            Api.getEmailCode({ toEmail: email }).then((res) => {
                // console.log(res.data)
                if (res.code == 200) {
                    openNotificationWithIcon('success', '发送成功！')
                    let downTime = setInterval(() => {
                        if (times == 0) {
                            getCodes(true)
                            clearInterval(intervalRef.current)
                        }
                        setTime(times--)
                    }, 1000)
                    intervalRef.current = downTime
                } else {
                    openNotificationWithIcon('error', intl.get('MCNAccount')['发送失败！'])
                    getCodes(true)
                }
            })
        }
    }

    const editMCNcontract = (imgUrl = '') => {
        let data = params
        if (imgUrl) { data.logo = imgUrl }
        if (newCert) { data.certUrl = newCert }
        if (newCargoClassifications) { data.cargoClassifications = newCargoClassifications }
        if (newMainMarkets) { data.mainMarkets = newMainMarkets }
        if (tab1Index === 0 && !isPassword) {
            if (!data.name) {
                message.warn(intl.get('MCNAccount')['请输入MCN机构名称！'])
                return
            }
            if (!data.linkman) {
                message.warn(intl.get('MCNAccount')['请输入联系人姓名！'])
                return
            }
            if (!data.whatsApp) {
                message.warn(intl.get('MCNAccount')['请输入WhatsApp！'])
                return
            }
            if (!data.scale) {
                message.warn(intl.get('MCNAccount')['请输入机构红人规模！'])
                return
            }
            if (!data.operatingYears) {
                message.warn(intl.get('MCNAccount')['请输入机构经营年限！'])
                return
            }
            if (!data.cargoClassifications) {
                message.warn(intl.get('MCNAccount')['请选择机构擅长类型！'])
                return
            }
            if (!data.mainMarkets) {
                message.warn(intl.get('MCNAccount')['请选择机构主营市场！'])
                return
            }
            if (!data.certUrl) {
                message.warn(intl.get('MCNAccount')['请上传机构注册证！'])
                return
            }
        }
        if (tab1Index === 0 && isPassword) {
            if (!data.password) {
                message.warn(intl.get('MCNAccount')['请输入登录密码！'])
                return
            }
        }
        Api.editMCNcontract(data).then(res => {
            if (res.code === 200) {
                openNotificationWithIcon('success', intl.get('MCNAccount')['修改成功！'])
                setIsInformation(false)
                getMCNcontract()
                setIsCollection(false)
            }
        })
    }

    const setMCNaddress = e => {
        let data = information
        if (data.recipientName && data.recipientPhone && data.postalCode && data.address) {
            Api.setMCNaddress(data).then(res => {
                if (res.code === 200) {
                    message.success(intl.get('MCNAccount')['修改成功！'])
                    setIsAddress(false)
                }
            })
        } else {
            message.warn(intl.get('LivingDemand')['内容未填写完整！'])
        }
    }

    const creditLevelList = ['', textJsonI['中等'], textJsonI['良好'], textJsonI['优秀'], textJsonI['极好']]

    const handleChangeFlei = (value, e) => {
        if (e.length < 4) {
            let list = e.map(item => { return { id: item.id, val: item.goodsName } })
            setNewCargoClassifications(list)
        }
    }

    const changeMainMarkets = (value, e) => {
        if (e.length < 4) {
            let list = e.map(item => { return { id: item.id, val: item.marketName } })
            setNewMainMarkets(list)
        }
    }

    const getID = list => {
        let t = []
        list.map(item => { t.push(item.id) })
        return t
    }

    const [scaleList, setScaleList] = useState([
        { val: '1 ~ 50人', id: 0 },
        { val: '50 ~ 100人', id: 1 },
        { val: '100 ~ 150人', id: 2 },
        { val: '150 ~ 200人', id: 3 },
        { val: '200 ~ 300人', id: 4 },
        { val: '300 ~ 500人', id: 5 },
        { val: '500 ~ 1000人', id: 6 },
    ])

    const [operatingYearsList, setOperatingYearsList] = useState([
        { val: '1年', id: 1 },
        { val: '2年', id: 2 },
        { val: '3年', id: 3 },
        { val: '4年', id: 4 },
        { val: '5年', id: 5 },
        { val: '6年', id: 6 },
        { val: '7年', id: 7 },
        { val: '8年', id: 8 },
        { val: '9年', id: 9 },
        { val: '10年', id: 10 },
    ])

    return (
        <div id='Account'>
            <nav style={{ width: '100%', height: '200px', position: 'relative', fontWeight: 500 }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/hNav.png'} alt=""
                    style={{ width: '100%', height: '100%' }} />
                <div style={{
                    width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                    display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 95px',
                }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Image src={userInfo?.logo || ''} preview={false} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'}
                            style={{ width: '94px', height: '94px', borderRadius: '47px', border: '4px solid #FFF' }} />
                        <div style={{ marginLeft: '27px' }}>
                            <div style={{ fontSize: '24px', fontWeight: 'bold', lineHeight: '24px' }}>{userInfo?.name || ''}</div>
                            <div style={{ color: '#666666', fontWeight: 500, marginTop: '2px' }}>
                                <span>{intl.get('MCNAccount')['机构关联码：']}{userInfo?.mcnCode}</span>
                                <span style={{ marginLeft: '20px' }}>{intl.get('MCNAccount')['经营年限：']}{userInfo?.operatingYears}</span>
                                <span style={{ marginLeft: '20px' }}>{intl.get('MCNAccount')['主营地区：']}{userInfo?.mainMarkets?.map((item, index) => { if (index === 0) { return item.val } else { return ',' + item.val } })}</span>
                            </div>
                            <div style={{ marginTop: '13px', display: 'flex', flexWrap: 'wrap' }}>
                                {
                                    userInfo?.cargoClassifications?.map((item, index) => {
                                        return <span key={index} style={{
                                            padding: '4px 10px', border: '1px solid #F7385B', color: '#F7385B', marginRight: index < 2 ? '5px' : 0,
                                            fontSize: '13px', borderRadius: '20px', opacity: 0.75
                                        }}>{item.val}</span>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            width: '50%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginRight: '30px',
                            textAlign: 'center'
                        }}
                    >
                        <div>
                            <div style={{ fontSize: '22px', fontWeight: 'bold', lineHeight: '24px' }}>{statInfo.succeedListCount}</div>
                            <div style={{ textAlign: 'center', color: '#888888', marginTop: '5px' }}>
                                {intl.get('MCNAccount')['已完成需求']}
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: '22px', fontWeight: 'bold', lineHeight: '24px' }}>￥{statInfo.earningCount}</div>
                            <div style={{ textAlign: 'center', color: '#888888', marginTop: '5px' }}>
                                {intl.get('MCNAccount')['机构总收益']}
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: '22px', fontWeight: 'bold', lineHeight: '24px' }}>{toKMB(statInfo.bloggerCount)}</div>
                            <div style={{ textAlign: 'center', color: '#888888', marginTop: '5px' }}>
                                {intl.get('MCNAccount')['红人数']}
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: '22px', fontWeight: 'bold', lineHeight: '24px' }}>{toKMB(statInfo.fansCount)}</div>
                            <div style={{ textAlign: 'center', color: '#888888', marginTop: '5px' }}>
                                {intl.get('MCNAccount')['粉丝总数']}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <div id='InfluencerAccount' style={{ backgroundColor: '#fff', marginTop: '10px', display: 'flex', minHeight: '600px', marginBottom: 50 }}>
                <div style={{ width: '224px', minWidth: '224px', background: '#F0F0F0', letterSpacing: '1.2px' }}>
                    {
                        tab1List?.map((item, index) => {
                            return <div onClick={() => setTab1Index(index)} key={index} style={{
                                width: '100%', height: '110px',
                                background: tab1Index == index ? '#FFF' : '', cursor: 'pointer',
                                color: tab1Index == index ? '#F7385B' : '#333', textAlign: 'center', paddingTop: '30px'
                            }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/' + item.icon + (index == tab1Index ? 's' : '') + '.png'} style={{ width: index == 0 ? '28px' : index == 1 ? '32px' : index == 2 ? '33px' : '28px', marginRight: '5px' }} />
                                <div style={{ fontSize: '14px', fontWeight: 'bold', marginTop: '5px' }}>{item.title}</div>
                            </div>
                        })
                    }
                    <div
                        style={{
                            width: '152px',
                            height: '38px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            color: '#666',
                            margin: '49px auto',
                            border: '1px solid #EEEEEE',
                            fontWeight: 'blod',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#FFF',
                            marginBottom: '60px'
                        }}
                        onClick={() => logouts()}
                    >
                        <img
                            src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/tuichudenglu.png'}
                            style={{
                                width: '16px',
                                marginRight: '8px'
                            }}
                            alt=""
                        />
                        {intl.get('ReleaseDemand')['退出账号登录']}
                    </div>
                </div>
                <div style={{ maxWidth: tab1Index != 2 ? 860 : 'inherit', flex: 1, minWidth: '0' }}>
                    {
                        tab1Index == 0 ?
                            // 基本信息
                            <div id='MCNAccount_Tab1' className='InfluencerAccountInfo' style={{ paddingLeft: '50px', paddingTop: '50px', paddingLeft: '50px', position: 'relative', display: 'flex', flexWrap: 'wrap', paddingBottom: 50, width: 'auto' }}>
                                <div style={{ width: '100%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}><span style={{ color: '#F7385B', marginRight: '2px' }}>*</span>{intl.get('MCNAccount')['MCN机构名称']}</div>
                                        <Input
                                            disabled={!isInformation}
                                            onChange={e => changeUserInfo('name', e.target.value)}
                                            value={params.name}
                                            style={{ height: '48px', borderRadius: '4px' }}
                                            placeholder={intl.get('MCNAccount')['请输入MCN机构名称']}
                                        />
                                    </div>
                                </div>
                                <div style={{ marginTop: '20px', width: '50%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}><span style={{ color: '#F7385B', marginRight: '2px' }}>*</span>{intl.get('MCNAccount')['联系人姓名']}</div>
                                        <Input
                                            disabled={!isInformation}
                                            onChange={e => changeUserInfo('linkman', e.target.value)}
                                            value={params.linkman}
                                            style={{ height: '48px', borderRadius: '4px' }}
                                            placeholder={intl.get('MCNAccount')['请输入联系人姓名']}
                                        />
                                    </div>
                                </div>
                                <div style={{ marginTop: '20px', width: '50%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}><span style={{ color: '#F7385B', marginRight: '2px' }}>*</span>WhatsApp</div>
                                        <Input disabled={!isInformation} onChange={e => changeUserInfo('whatsApp', e.target.value)} value={params.whatsApp} style={{ height: '48px', borderRadius: '4px' }} placeholder={intl.get('NewLogin')['请输入WatsApp']} />
                                    </div>
                                </div>
                                <div style={{ marginTop: '20px', width: '50%' }}>
                                    <div id='MCNAccount' style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}><span style={{ color: '#F7385B', marginRight: '2px' }}>*</span>{intl.get('MCNAccount')['机构红人规模']}</div>
                                        <Select
                                            disabled={!isInformation}
                                            size='large'
                                            style={{ borderRadius: '4px', width: '100%' }}
                                            placeholder={<span style={{ fontSize: '14px' }}>{intl.get('AccountRecharge')['请选择红人规模']}</span>} allowClear={true}
                                            onChange={e => changeUserInfo('scale', e)}
                                            value={params.scale || undefined}
                                        >
                                            {
                                                scaleList?.map((item, index) => {
                                                    return <Option key={index} id={item.id} value={item.val}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {item.val}
                                                        </div>
                                                    </Option>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div style={{ marginTop: '20px', width: '50%' }}>
                                    <div id='MCNAccount' style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}><span style={{ color: '#F7385B', marginRight: '2px' }}>*</span>{intl.get('MCNAccount')['机构经营年限']}</div>
                                        <Select
                                            size='large'
                                            disabled={!isInformation}
                                            style={{ borderRadius: '4px', width: '100%' }}
                                            placeholder={<span style={{ fontSize: '14px' }}>{intl.get('AccountRecharge')['请选择经营年限']}</span>}
                                            allowClear={true}
                                            onChange={e => changeUserInfo('operatingYears', e)}
                                            value={params.operatingYears || undefined}
                                        >
                                            {
                                                operatingYearsList?.map((item, index) => {
                                                    return <Option key={index} id={item.id} value={item.id}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {item.val}
                                                        </div>
                                                    </Option>
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div style={{ marginTop: '20px', width: '50%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}><span style={{ color: '#F7385B', marginRight: '2px' }}>*</span>{intl.get('MCNAccount')['机构擅长类型']}</div>
                                        <Select
                                            mode="multiple"
                                            size='large'
                                            disabled={!isInformation}
                                            maxTagCount={1}
                                            maxTagTextLength={5}
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder={<span style={{ fontSize: '14px' }}>{['请选择机构擅长类型，最多选3个']}</span>}
                                            allowClear={true}
                                            showArrow={true}
                                            onChange={handleChangeFlei}
                                            value={newCargoClassifications ? getID(newCargoClassifications) : ''}
                                            optionLabelProp="label"
                                        >
                                            {
                                                classList?.length > 0 ?
                                                    classList.map((item, index) => {
                                                        return <Option key={index} id={item.id} value={item.id} label={item.goodsEnglishName}>
                                                            <div style={{ textAlign: 'left' }}>
                                                                {item.goodsEnglishName}（{item.goodsName}）
                                                            </div>
                                                        </Option>
                                                    })
                                                    :
                                                    ''
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div style={{ marginTop: '20px', width: '50%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}><span style={{ color: '#F7385B', marginRight: '2px' }}>*</span>{intl.get('MCNAccount')['机构主营市场']}</div>
                                        <Select
                                            mode="multiple"
                                            size='large'
                                            maxTagCount={1}
                                            disabled={!isInformation}
                                            style={{ width: '100%' }}
                                            placeholder={<span style={{ fontSize: '14px' }}>{textJson['请选择主营市场，可多选']}</span>}
                                            allowClear={true}
                                            showArrow={true}
                                            onChange={changeMainMarkets}
                                            value={newMainMarkets ? getID(newMainMarkets) : ''}
                                            optionLabelProp="label"
                                        >
                                            {
                                                marketList?.length > 0 ? marketList.map((item, index) => {
                                                    return <Option key={index} id={item.id} value={item.id} label={item.marketName}>
                                                        <div style={{ textAlign: 'left' }}>
                                                            {item.marketName}
                                                        </div>
                                                    </Option>
                                                }) : ''
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div style={{ marginTop: '20px', width: '100%' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0, height: '48px', lineHeight: '48px' }}>{intl.get('MCNAccount')['机构简介']}</div>
                                        <TextArea
                                            disabled={!isInformation}
                                            value={params.intro}
                                            onChange={e => changeUserInfo('intro', e.target.value)}
                                            placeholder={intl.get('MCNAccount')['请输入您的机构简介']}
                                            autoSize={{
                                                minRows: 3,
                                                maxRows: 5,
                                            }}
                                            style={{
                                                height: '48px',
                                                borderRadius: '4px'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ marginTop: '20px', width: '50%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}>{intl.get('MCNAccount')['机构关联码']}</div>
                                        <Input disabled={true} onChange={e => changeUserInfo('intro', e.target.value)} value={params.mcnCode} style={{ height: '48px', borderRadius: '4px' }} placeholder={intl.get('MCNAccount')['请输入您的机构关联码']} />
                                    </div>
                                </div>
                                <div style={{ marginTop: '20px', width: '50%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', height: '48px' }}>
                                        <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}><span style={{ color: '#F7385B', marginRight: '2px' }}>*</span>{intl.get('MCNAccount')['机构注册证']}</div>
                                        {
                                            isInformation ?
                                                <div>
                                                    <div style={{ cursor: 'pointer', color: '#F7385B', marginBottom: '5px' }} onClick={() => setVisible(true)}>
                                                        {newCert?.split('/')[newCert?.split('/').length - 1]}
                                                    </div>
                                                    <Upload {...certConfig}>
                                                        <Button
                                                            style={{
                                                                background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                                                fontSize: '12px',
                                                                color: '#FFF',
                                                                borderRadius: '4px',
                                                                borderRight: '4px',
                                                                letterSpacing: '0px',
                                                                border: 0
                                                            }}
                                                        >
                                                            {intl.get('MCNAccount')['上传注册证']}
                                                        </Button>
                                                    </Upload>
                                                </div>
                                                :
                                                params.certUrl ?
                                                    <div style={{ cursor: 'pointer', color: '#F7385B' }} onClick={() => setVisible(true)}>{params.certUrl.split('/')[params.certUrl.split('/').length - 1]}</div>
                                                    :
                                                    <div style={{ color: '#CCCCCC' }}>
                                                        {intl.get('MCNAccount')['暂未上传机构注册证']}
                                                    </div>
                                        }
                                        <div style={{ display: 'none' }}>
                                            <Image
                                                width={200}
                                                src={newCert || params.certUrl}
                                                preview={{
                                                    visible,
                                                    src: newCert || params.certUrl,
                                                    onVisibleChange: value => {
                                                        setVisible(value);
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {
                                        isInformation ?
                                            <div style={{ color: '#B3B3B3', fontSize: '12px', paddingLeft: '160px', marginTop: '5px' }}>{intl.get('MCNAccount')['请确保图片或扫描件大小小于10M，格式为JPG，PNG或PDF；']}</div>
                                            :
                                            ''
                                    }
                                </div>
                                <div style={{ marginTop: '20px', width: '50%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}>{intl.get('MCNAccount')['登录邮箱号']}</div>
                                        <Input disabled={true} value={email} style={{ height: '48px', borderRadius: '4px' }} placeholder={intl.get('MCNAccount')['请输入您的登录邮箱号']} />
                                    </div>
                                </div>
                                <div style={{ marginTop: '20px', width: '50%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}><span style={{ color: '#F7385B', marginRight: '2px' }}>*</span>{textJsonI['登录密码']}</div>
                                        <Input
                                            disabled={!isPassword}
                                            onChange={e => setPassword(e.target.value)}
                                            value={password == '' && !isPassword ? '********' : password}
                                            style={{ height: '48px', borderRadius: '4px' }}
                                            placeholder={textJsonI['请输入新的登录密码']}
                                        />
                                        {
                                            !isPassword ?
                                                <span onClick={() => setIsPassword(!isPassword)} style={{ color: '#F7385B', width: '100px', textAlign: 'center', cursor: 'pointer', marginLeft: '10px' }}>{textJsonI['修改']}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </div>
                                {
                                    isPassword ?
                                        <div style={{ marginTop: '20px', width: '100%' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}>{textJsonNL['验证码']}</div>
                                                <Input onChange={e => setCode(e.target.value)} value={codes}
                                                    style={{ width: '210px', height: '48px', borderRadius: '4px' }} placeholder={textJsonNL['请输入邮箱验证码']} />
                                                <Button disabled={!isCode} onClick={() => getEmailCode()} style={{
                                                    width: '210px', height: '48px', background: !isCode ? '#EEEEEE' : 'linear-gradient(180deg, #F7385B, #FF716E)',
                                                    color: '#FFF', borderRadius: '4px', borderRight: '4px', letterSpacing: '0px', border: 0, marginLeft: '10px',
                                                }}>{isCode ? textJsonNL['获取邮箱验证码'] : `${time}${intl.get('account')['s后可重新发送']}`}</Button>
                                            </div>
                                        </div>
                                        :
                                        ''
                                }
                                {
                                    isInformation ?
                                        <div style={{ marginTop: '20px', display: 'flex' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}></div>
                                                <Button disabled={false} onClick={() => editMCNcontract()} style={{
                                                    width: '210px', height: '40px', background: false ? '#EEEEEE' : 'linear-gradient(180deg, #F7385B, #FF716E)',
                                                    color: '#FFF', borderRadius: '4px', borderRight: '4px', letterSpacing: '0px', border: 0
                                                }}>{intl.get('MCNAccount')['确认修改']}</Button>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}></div>
                                                <Button
                                                    disabled={false}
                                                    onClick={() => {
                                                        setIsInformation(false);
                                                        setNewCargoClassifications(userInfo.cargoClassifications)
                                                        setNewMainMarkets(userInfo.mainMarkets)
                                                        setNewCert('')
                                                        setParams(userInfo)
                                                    }}
                                                    style={{
                                                        width: '210px',
                                                        height: '40px',
                                                        background: '#EEEEEE',
                                                        color: '#FFF',
                                                        borderRadius: '4px',
                                                        borderRight: '4px',
                                                        letterSpacing: '0px',
                                                        border: 0
                                                    }}>
                                                    {intl.get('MCNAccount')['取消修改']}
                                                </Button>
                                            </div>
                                        </div> :
                                        ''
                                }
                                {
                                    !isInformation && !isPassword ?
                                        <div style={{ marginTop: '20px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}></div>
                                                <Button disabled={false} onClick={() => setIsInformation(true)} style={{
                                                    width: '210px', height: '40px', background: false ? '#EEEEEE' : 'linear-gradient(180deg, #F7385B, #FF716E)',
                                                    color: '#FFF', borderRadius: '4px', borderRight: '4px', letterSpacing: '0px', border: 0
                                                }}>{intl.get('MCNAccount')['修改信息']}</Button>
                                            </div>
                                        </div> :
                                        ''
                                }
                                {
                                    isPassword ?
                                        <div style={{ marginTop: '20px', width: '100%', display: 'flex' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}></div>
                                                <Button disabled={codes == '' || password.length < 6} onClick={() => resetMCNPassword()} style={{
                                                    width: '210px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                                    color: '#FFF', borderRadius: '4px', borderRight: '4px', letterSpacing: '0px', border: 0
                                                }}>{intl.get('MCNAccount')['确认修改']}</Button>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ width: '160px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px', flexShrink: 0 }}></div>
                                                <Button
                                                    disabled={false}
                                                    onClick={() => {
                                                        setIsPassword(false)
                                                        setPassword('')
                                                    }}
                                                    style={{
                                                        width: '210px', height: '40px', background: '#EEEEEE',
                                                        color: '#FFF', borderRadius: '4px', borderRight: '4px', letterSpacing: '0px', border: 0
                                                    }}
                                                >
                                                    {intl.get('MCNAccount')['取消修改']}
                                                </Button>
                                            </div>
                                        </div> :
                                        ''
                                }
                            </div> :
                            tab1Index == 1 ?
                                // 收款信息
                                <div id='login' className='InfluencerAccountInfo' style={{ width: '860px', paddingLeft: '80px', paddingTop: '25px', position: 'relative' }}>
                                    <div style={{
                                        width: '554px',
                                        height: '48px',
                                        background: '#F0F0F0',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginLeft: '78px'
                                    }}>
                                        <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/wxts.png" style={{ width: '81px' }} />
                                        <span style={{ fontSize: '12px', color: '#888888', marginLeft: '25px', letterSpacing: '1px' }}>
                                            {textJsonI['本平台结算转账提现统一使用Payoneer，请正确填写您的收款信息']}
                                        </span>
                                    </div>
                                    <div style={{ marginTop: '45px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: '200px', fontWeight: 600, textAlign: 'right', paddingRight: '20px', color: '#888888', letterSpacing: '1px' }}>{textJsonNL['收款人真实姓名']}</div>
                                            <Input disabled={!isCollection} onChange={e => changeUserInfo('payee', e.target.value)} value={params.payee}
                                                style={{ width: '430px', height: '48px', borderRadius: '4px' }} placeholder={textJsonNL['请输入收款人的真实姓名']} />
                                            {!isCollection ? <span onClick={() => setIsCollection(!isCollection)} style={{ color: '#F7385B', width: '100px', textAlign: 'center', cursor: 'pointer' }}>{textJsonI['修改']}</span> : ''}
                                        </div>
                                        {
                                            isCollection ?
                                                <div style={{ paddingLeft: '200px', color: '#CCCCCC', fontSize: '12px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                                    <img style={{ width: '14px', marginRight: '6px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/tishi.png'} />
                                                    {textJsonNL['请输入注册Payoneer时的真实姓名']}
                                                </div>
                                                :
                                                ''
                                        }
                                    </div>
                                    <div style={{ marginTop: '36px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: '200px', fontWeight: 600, textAlign: 'right', paddingRight: '20px', color: '#888888', letterSpacing: '1px' }}>{textJsonNL['收款的账号']}</div>
                                            <Input disabled={!isCollection} onChange={e => changeUserInfo('payoneerEmail', e.target.value)} value={params.payoneerEmail}
                                                style={{ width: '430px', height: '48px', borderRadius: '4px' }} placeholder={textJsonNL['请输入收款的邮箱账号']} />
                                            {!isCollection ? <span onClick={() => setIsCollection(!isCollection)} style={{ color: '#F7385B', width: '100px', textAlign: 'center', cursor: 'pointer' }}>{textJsonI['修改']}</span> : ''}
                                        </div>
                                        {isCollection ? <div style={{ paddingLeft: '200px', textAlign: 'left', color: '#CCCCCC', fontSize: '12px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                            <img style={{ width: '14px', marginRight: '6px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/tishi.png'} />
                                            {textJsonNL['请输入注册Payoneer时的邮箱号']}
                                        </div> : ''}
                                    </div>
                                    {isCollection ? <Button onClick={() => editMCNcontract()} style={{
                                        width: '210px', height: '48px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                        color: '#FFF', borderRadius: '4px', borderRight: '4px', letterSpacing: '1px', border: 0,
                                        marginLeft: '200px', marginTop: '35px'
                                    }}>{textJsonI['确认收款信息']}</Button> : ''}
                                </div> :
                                tab1Index == 2 ?
                                    <SampleDeliveryAddress />
                                    // 样品收货地址
                                    // <div id='login' className='InfluencerAccountInfo' style={{ width: '860px', paddingLeft: '50px', paddingTop: '50px', position: 'relative' }}>
                                    //     <div style={{ marginTop: '20px' }}>
                                    //         <div style={{ display: 'flex', alignItems: 'center' }}>
                                    //             <div style={{ width: '200px', fontWeight: 600, textAlign: 'right', paddingRight: '20px', color: '#888888', letterSpacing: '1px' }}>{textJsonI['收件人姓名']}</div>
                                    //             <Input disabled={!isAddress} onChange={e => changeInfo('recipientName', e.target.value)} value={information.recipientName}
                                    //                 style={{ width: '430px', height: '48px', borderRadius: '4px' }} placeholder={intl.get('ReleaseDemand')['请输入收件人姓名']} />
                                    //             {!isAddress ? <span onClick={() => setIsAddress(!isAddress)} style={{ color: '#F7385B', width: '100px', textAlign: 'center', cursor: 'pointer' }}>{textJsonI['修改']}</span> : ''}
                                    //         </div>
                                    //     </div>
                                    //     <div style={{ marginTop: '16px' }}>
                                    //         <div style={{ display: 'flex', alignItems: 'center' }}>
                                    //             <div style={{ width: '200px', fontWeight: 600, textAlign: 'right', paddingRight: '20px', color: '#888888', letterSpacing: '1px' }}>{textJsonI['收件人手机号']}</div>
                                    //             <Input disabled={!isAddress} onChange={e => changeInfo('recipientPhone', e.target.value)} value={information.recipientPhone}
                                    //                 style={{ width: '430px', height: '48px', borderRadius: '4px' }} placeholder={intl.get('ReleaseDemand')['请输入收件人手机号']} />
                                    //             {!isAddress ? <span onClick={() => setIsAddress(!isAddress)} style={{ color: '#F7385B', width: '100px', textAlign: 'center', cursor: 'pointer' }}>{textJsonI['修改']}</span> : ''}
                                    //         </div>
                                    //     </div>
                                    //     <div style={{ marginTop: '16px' }}>
                                    //         <div style={{ display: 'flex', alignItems: 'center' }}>
                                    //             <div style={{ width: '200px', fontWeight: 600, textAlign: 'right', paddingRight: '20px', color: '#888888', letterSpacing: '1px' }}>{textJsonI['邮编']}</div>
                                    //             <Input disabled={!isAddress} onChange={e => changeInfo('postalCode', e.target.value)} value={information.postalCode}
                                    //                 style={{ width: '430px', height: '48px', borderRadius: '4px' }} placeholder={textJsonI['请输入邮编']} />
                                    //             {!isAddress ? <span onClick={() => setIsAddress(!isAddress)} style={{ color: '#F7385B', width: '100px', textAlign: 'center', cursor: 'pointer' }}>{textJsonI['修改']}</span> : ''}
                                    //         </div>
                                    //     </div>
                                    //     <div style={{ marginTop: '16px' }}>
                                    //         <div style={{ display: 'flex', alignItems: 'center' }}>
                                    //             <div style={{ width: '200px', fontWeight: 600, textAlign: 'right', paddingRight: '20px', color: '#888888', letterSpacing: '1px' }}>{textJsonI['具体地址']}</div>
                                    //             <Input disabled={!isAddress} onChange={e => changeInfo('address', e.target.value)} value={information.address} style={{ width: '430px', height: '48px', borderRadius: '4px' }} placeholder={textJsonI['请输入具体的收样品地址']} />
                                    //             {
                                    //                 !isAddress ?
                                    //                     <span onClick={() => setIsAddress(!isAddress)} style={{ color: '#F7385B', width: '100px', textAlign: 'center', cursor: 'pointer' }}>
                                    //                         {textJsonI['修改']}
                                    //                     </span>
                                    //                     :
                                    //                     ''
                                    //             }
                                    //         </div>
                                    //     </div>
                                    //     {
                                    //         isAddress ?
                                    //             <Button onClick={() => setMCNaddress()} style={{
                                    //                 width: '210px', height: '48px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                    //                 color: '#FFF', borderRadius: '4px', borderRight: '4px', letterSpacing: '1px', border: 0,
                                    //                 marginLeft: '200px', marginTop: '35px'
                                    //             }}>{textJsonI['确认修改']}</Button>
                                    //             :
                                    //             ''
                                    //     }
                                    // </div>
                                    :
                                    tab1Index == 3 ?
                                        // 消息通知
                                        <div style={{ width: '100%', paddingLeft: '100px', paddingRight: '100px' }}>
                                            {
                                                news1List?.length > 0 ?
                                                    news1List?.map((item, index) => {
                                                        return (
                                                            <div key={index} style={{ display: 'flex', marginTop: '40px' }}>
                                                                <Badge dot={item.readStatus == 2} offset={[-4, 4]}>
                                                                    <img style={{ width: '30px', height: '30px' }} src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/xitong.png" />
                                                                </Badge>
                                                                <div style={{ width: '100%', marginLeft: '20px' }}>
                                                                    <div style={{ display: 'flex', cursor: 'pointer' }}>
                                                                        <div onClick={() => item.extraInfo ? changeChecked(false, index, 1) : null} style={{ maxWidth: 'calc(100% - 80px)', fontWeight: 'bold', color: item.readStatus == 1 ? '#888888' : '#333' }}>{item.title}</div>
                                                                        {
                                                                            item.informationType === 2 || item.informationType === 3 || item.informationType === 4 ?
                                                                                <div style={{
                                                                                    width: '80px', minWidth: '80px', height: '20px', fontSize: '12px', color: '#888888',
                                                                                    background: '#F0F0F0', borderRadius: '4px', textAlign: 'center', marginLeft: '12px', fontWeight: 'normal'
                                                                                }}
                                                                                    onClick={() => changeChecked(true, index, 1)}>{textJsonI['查看详情']} <RightOutlined /></div>
                                                                                :
                                                                                !item.isChecked ? <div style={{
                                                                                    width: '80px', minWidth: '80px', height: '20px', fontSize: '12px', color: '#888888',
                                                                                    background: '#F0F0F0', borderRadius: '4px', textAlign: 'center', marginLeft: '12px', fontWeight: 'normal'
                                                                                }}
                                                                                    onClick={() => changeChecked(true, index, 1)}>{textJsonI['展开内容']} <DownOutlined /></div>
                                                                                    : <div style={{
                                                                                        width: '80px', minWidth: '80px', height: '20px', fontSize: '12px', color: '#888888',
                                                                                        background: '#F0F0F0', borderRadius: '4px', textAlign: 'center', marginLeft: '12px', fontWeight: 'normal'
                                                                                    }}
                                                                                        onClick={() => changeChecked(false, index, 1)}>{textJsonI['收起内容']} <UpOutlined /></div>
                                                                        }
                                                                    </div>
                                                                    <div style={{ fontSize: '12px', color: '#888888' }}>{item.createTime}</div>
                                                                    {
                                                                        item.isChecked ?
                                                                            <div style={{
                                                                                width: '100%', maxWidth: '100%', background: '#F0F0F0', borderRadius: '4px', marginTop: '14px',
                                                                                color: '#888888', fontSize: '12px', padding: '10px 25px', lineHeight: '22px'
                                                                            }}>
                                                                                <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                                                            </div>
                                                                            :
                                                                            ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <div className='flex-acjc' style={{ width: '100%', flexDirection: 'column', paddingTop: '100px' }}>
                                                        <Image width={105} height={100} preview={false} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png' alt='' />
                                                        <div style={{ marginTop: '23px' }}>{intl.get('EnterpriseAccount')['暂无消息通知~']}</div>
                                                    </div>
                                            }
                                        </div>
                                        : ''
                    }
                </div>
                {
                    tab1Index === 0 ?
                        <div style={{ textAlign: 'center', marginTop: '50px', marginLeft: '120px', marginRight: 50 }}>
                            <div>
                                <Image src={userInfo?.logo || ''} preview={false} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} style={{ width: '100px', height: '100px', borderRadius: '50px' }} />
                            </div>
                            <Upload {...uploadProps}>
                                <div style={{
                                    color: '#F7385B', cursor: 'pointer', border: '1px solid #F7385B',
                                    marginTop: '10px', padding: '3px 10px', borderRadius: '4px'
                                }}>
                                    {textJson["上传头像"]}
                                </div>
                            </Upload>
                        </div> : ''
                }
            </div>

            <Modal
                centered
                visible={isTixian}
                footer={null}
                closable={false}
                getContainer={() => document.getElementById('InfluencerAccount')}
                bodyStyle={{
                    padding: '0',
                    backgroundColor: '#FFFFFF',
                    width: 1000,
                    borderRadius: '10px',
                    zIndex: 1500
                }}
            >
                <div style={{ padding: '55px 30px 30px 30px', borderRadius: '10px' }}>
                    <h3 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>{textJson['提现记录']}</h3>
                    <div style={{
                        width: '100%', height: '50px', display: 'flex', alignItems: 'center',
                        background: '#F6F6F6', marginTop: '40px', fontWeight: 500
                    }}>
                        <div style={{ width: '64%', paddingLeft: '45px', paddingRight: '20px' }}>{textJsonI['内容']}</div>
                        <div style={{ width: '20%' }}>{textJsonI['完成时间']}</div>
                        <div style={{ width: '15%' }}>{textJsonI['成长值']}</div>
                    </div>
                    <div id='scrollableDiv' style={{ width: '100%', height: '60vh', overflowY: 'auto' }}>
                        <Spin tip={`${textJsonI['数据加载中']}...`} spinning={loading}>
                            <InfiniteScroll
                                dataLength={dataTi.length} scrollThreshold={'20px'}
                                next={loadMoreData}
                                hasMore={dataTi.length < totalTi}
                                // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                                endMessage={dataTi.length > 10 ? <Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider> : ''}
                                scrollableTarget="scrollableDiv"
                            >
                                <List
                                    dataSource={dataTi} grid={{ gutter: 0, column: 1 }}
                                    renderItem={(item, index) => <div key={index} style={{
                                        width: '100%', height: '100px', display: 'flex', alignItems: 'center',
                                        background: (index + 1) % 2 == 0 ? '#F6F6F6' : '#FFF'
                                    }}>
                                        <div className='over' style={{
                                            width: '65%', paddingLeft: '45px', fontWeight: 'bold', fontSize: '16px',
                                            paddingRight: '20px'
                                        }}>
                                            {item.content}
                                        </div>
                                        <div style={{ width: '20%' }}>{item.finishTime}</div>
                                        <div style={{ width: '15%', color: item.growthValue >= 0 ? '#68C33B' : '#F7385B' }}>{item.growthValue}</div>
                                    </div>}
                                />
                            </InfiniteScroll>
                        </Spin>
                    </div>

                    <CloseCircleOutlined style={{
                        position: 'absolute', right: '29px', top: '29px', fontSize: '20px', color: '#888888',
                        cursor: 'pointer'
                    }}
                        onClick={() => closeTixian()} />
                </div>
            </Modal>
        </div>
    )
}

export default withRouter(MCNAccount);