import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import { DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import { Modal, message, List, notification, Avatar, Skeleton, Divider } from 'antd';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import intl from 'react-intl-universal';
import { upload } from '@testing-library/user-event/dist/upload';
import html2canvas from 'html2canvas'
import moment from 'moment';
import toKMB from '../../utils/KMB';

const ShareTopicModel = (props) => {
    const [widths, setWidths] = useState(0)
    useEffect(() => {
        console.log(props)
    }, [])

    const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const uploads = () => {
        message.loading({ content: 'Loading...', key: 'updatable' });
        canvasImgFn()
    }

    const dataURLToBlob = (dataurl) => {
        let arr = dataurl.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    const canvasImgFn = () => {
        let a = document.createElement('a');
        html2canvas(document.getElementById("share"), {
            width: 695, //设置canvas的宽度
            scale: 1,//缩放
            allowTaint: true,
            useCORS: true,
            height: 3190, //设置截图的长度
        }).then((canvas) => {
            let dom = document.body.appendChild(canvas);
            dom.style.display = 'none';
            a.style.display = 'none';
            document.body.removeChild(dom);
            let blob = dataURLToBlob(dom.toDataURL('image/png'));
            a.setAttribute('href', URL.createObjectURL(blob));
            //这块是保存图片操作  可以设置保存的图片的信息
            a.setAttribute('download', '话题排行榜.png');
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(blob);
            document.body.removeChild(a);
        })
    }


    const ShareDiv = ()=>{
        return <div>
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share1.png?1111'} alt="" crossOrigin="anonymous"
                style={{ width: '100%', display: 'inline-block' }} />
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share2.png?1111'} alt="" crossOrigin="anonymous"
                style={{ width: '100%', display: 'inline-block', marginTop: '-1px' }} />
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share3.png?1111'} alt="" crossOrigin="anonymous"
                style={{ width: '100%', display: 'inline-block', marginTop: '-1px' }} />
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/share4.png?1111'} alt="" crossOrigin="anonymous"
                style={{ width: '100%', display: 'inline-block', marginTop: '-1px' }} />
            <div style={{ position: 'absolute', top: 0, left: 0 }}>
                <div style={{
                    marginTop: '256px', marginLeft: '85px', fontSize: '35px',
                    color: '#444444', fontWeight: 'bold'
                }}>话题排行榜</div>
                <div style={{
                    textAlign: 'center', color: '#FFF', fontSize: '26px', fontWeight: 'bold',
                    marginTop: '30px', marginLeft: '77px'
                }}>
                    播放数TOP10 <div style={{ fontSize: '26px' }}>{moment().format("YYYY-MM-DD")}日榜</div>
                </div>
            </div>
            <div style={{ width: '100%', position: 'absolute', top: '555px', left: 0, padding: '0 30px' }}>
                {
                    props.data?.map((item, index) => {
                        return index < 10?<div key={index} style={{
                            width: '100%', height: '210px', backgroundColor: '#FFF',
                            borderRadius: '10px', display: 'flex', alignItems: 'center', padding: '15px 34px',
                            marginBottom: '16px', position: 'relative'
                        }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/huati.png?1111'} alt="" crossOrigin="anonymous" 
                                style={{ width: '140px', height: '140px', borderRadius: '4px' }} />
                            <div style={{ marginLeft: '20px' }}>
                                <div className='over' style={{ width: '95%', height: '60px', fontWeight: 'bold', fontSize: '20px', lineHeight: '25px' }}>{item.title}</div>
                                <div style={{ color: '#888888', fontWeight: 500, fontSize: '16px', marginTop: '3px' }}>更新时间: {moment(item.updateTime * 1000).format('YYYY-MM-DD')}</div>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                                    <div style={{ fontSize: '16px', color: '#888888', display: 'flex', alignItems: 'center' }}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/playCircleL.png?1111'} alt="" crossOrigin="anonymous"
                                            style={{ width: '18px', height: '18px' }} />
                                        <span style={{ marginLeft: '5px', marginRight: '8px' }}>播放数</span>
                                        <span style={{ fontSize: '18px', color: '#333333', fontWeight: 'bold' }}>{toKMB(item.viewCount, 2)}</span>
                                    </div>
                                    <div style={{ fontSize: '16px', color: '#888888', display: 'flex', alignItems: 'center', marginLeft: '80px' }}>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/shipin.png?1111'} alt="" crossOrigin="anonymous"
                                            style={{ width: '18px', height: '18px' }} />
                                        <span style={{ marginLeft: '5px', marginRight: '8px' }}>视频数</span>
                                        <span style={{ fontSize: '18px', color: '#333333', fontWeight: 'bold' }}>{toKMB(item.videoCount, 2)}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ position: 'absolute', top: '18px', right: '18px' }}>
                                {index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png?1111'} alt="" crossOrigin="anonymous" /> :
                                    <div style={{
                                        width: '30px', height: '30px', borderRadius: '15px',
                                        lineHeight: '28px', textAlign: 'center', fontWeight: 500,
                                        backgroundColor: '#EEEEEE', fontSize: '20px', color: '#888888'
                                    }}>{index + 1}</div>
                                }
                            </div>
                        </div> :''
                    })
                }
            </div>
        </div>
    }

    return <div>
        <Modal centered visible={true} footer={null} closable={false}
            getContainer={() => document.getElementById('TopicList')}
            bodyStyle={{
                padding: '0', backgroundColor: '#FFFFFF',
                width: 350, height: 1698, margin: '130px 0', overflow: 'hidden'
            }}
        >
            <div id='share' style={{ width: 695, position: 'fixed', left: 10000}}>
                <ShareDiv />
            </div>
            <div style={{ width: 695, transform: 'scale(0.5)', position: 'absolute', top: -849, left: -172 }}>
                <ShareDiv />
            </div>
            
            
            <div onClick={uploads} style={{
                width: '118px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                textAlign: 'center', lineHeight: '40px', color: '#FFF', borderRadius: '4px', margin: '0 auto',
                cursor: 'pointer', marginTop: '26px', position: 'absolute', right: '-144px', top: '240px'
            }}>下载图片</div>
            <div onClick={() => props.clickFun()} style={{
                width: '118px', height: '40px', background: '#FFF',
                textAlign: 'center', lineHeight: '40px', color: '#666', borderRadius: '4px', margin: '0 auto',
                cursor: 'pointer', marginTop: '26px', position: 'absolute', right: '-144px', top: '300px'
            }}>取消分享</div>
        </Modal>
    </div>
}
export default withRouter(ShareTopicModel);