import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import { Input, message, Select, Switch, Image, Spin, Button, Popconfirm, DatePicker, Modal } from 'antd';
import { CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import * as Api from '../../../service/https';
import '../../../asset/css/MyGoods.scss'
import intl from 'react-intl-universal';
import store from '../../../utils/redux/index'
import { debounce } from '../../../utils/debounce'
import {editMyGoods} from "../../../service/https";
import openNotificationWithIcon from "../../../components/Notification";
import { color } from 'echarts';
import moment from 'moment';
import { baseUrl } from '../../../service/request'
const { RangePicker } = DatePicker;

const { Option } = Select

const SubAccount = (props) => {

    useEffect(()=>{
        getAccountList()
        getRole()
    },[])

    const [account, setAccount] = useState()
    const [accountList, setAccountList] = useState([])
    const getAccountList = e => {
        Api.accountList().then(res=>{
            if(res.code == 200){
                setAccount(res.data.filter(item=>item.mainAccount == true)[0])
                setAccountList(res.data.filter(item=>item.mainAccount == false))
            }
        })
    }
    const [roleList, setRoleList] = useState([])
    const getRole = e => {
        Api.getRole().then(res=>{
            if(res.code == 200){
                setRoleList(res.data)
            }
        })
    }

    const [isApply, setApply] = useState(false)

    useEffect(()=>{
        let body = document.documentElement.body || document.body
        if(body) body.style.overflow = isApply ? 'hidden' : 'auto'
    },[isApply])

    const [shopInfo, setShopInfo] = useState({
        phone:'', password:'', roleIds:[]
    })
    const onChangeInfo = (key, val) => {
        let obj = JSON.parse(JSON.stringify(shopInfo))
        obj[key] = val
        setShopInfo(obj)
    };
    const addAccount = () => {
        Api.subAccount(shopInfo).then(res=>{
            if(res.code == 200){
                setApply(false)
                setShopInfo({
                    phone:'', password:'', roleIds:[]
                })
                openNotificationWithIcon('success', '添加成功！')
                getAccountList()
            }
        })
    };
    const delAccount = (item) => {
        Api.delAccount(item.uid).then(res=>{
            if(res.code == 200){
                openNotificationWithIcon('success', '删除成功！')
                getAccountList()
            }
        })
    };

    return (
        <div id='MyGoods'>
            <div style={{ display: 'flex', alignItems:'center' }}>
                <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/before.png" alt=""
                style={{width:'4px'}}/>
                <div style={{fontSize:'20px', fontWeight:600, marginLeft:'9px'}}>子账号管理</div>
            </div>


            <div style={{width:'100%', background:'#FFF', padding:'20px 24px', marginTop:'10px'}}>
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div style={{fontSize:'20px', fontWeight:600}}>主账号</div>
                </div>
                <div style={{width:'100%', background:'#f6f6f6', display:'flex', justifyContent:'space-between',
                padding:'10px 40px', borderRadius:'5px', marginTop:'20px', fontWeight:'500', fontSize:'14px', marginBottom:'15px'}}>
                    <span>主账号手机号</span>
                    <div style={{width:'100px'}}>角色</div>
                </div>
                {account?<div style={{marginTop:'0px'}}>
                    <div style={{width:'100%', padding:'15px 40px', background:'#FFF', borderRadius:'5px',
                    display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <span style={{fontSize:'14px'}}>{account.phone}</span>
                        <div style={{fontSize:'14px', width:'100px'}}>超级管理员</div>
                    </div>
                </div>:''}
            </div>

            <div style={{width:'100%', background:'#FFF', padding:'20px 24px', marginTop:'10px'}}>
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div style={{fontSize:'20px', fontWeight:600}}>子账号</div>
                    <div onClick={()=>setApply(true)} style={{width:'98px', height:'34px',
                        border:'1px solid #F7385B',
                        borderRadius:'6px', color: '#F7385B', textAlign:'center', lineHeight:'32px', cursor:'pointer'}}>
                        添加子账号
                    </div>
                </div>
                <div style={{width:'100%', background:'#f6f6f6', display:'flex', justifyContent:'space-between',
                padding:'10px 40px', borderRadius:'5px', marginTop:'20px', fontWeight:'500', fontSize:'14px', marginBottom:'15px'}}>
                    <div style={{width:'200px'}}>子账号手机号</div>
                    <div style={{width:'100px'}}>角色</div>
                    <div style={{width:'100px'}}>操作</div>
                </div>
                <div>
                    {
                        accountList.map((item,index)=>{
                            return <div key={index} style={{width:'100%', padding:'15px 40px', background:'#FFF', borderRadius:'5px',
                            display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                <div style={{width:'200px'}}>{item.phone}</div>
                                <div style={{fontSize:'14px', width:'100px'}}>{item.roleList[0].name}</div>
                                <div style={{fontSize:'14px', width:'100px', color:'#F7385B'}} >
                                    <Popconfirm title="确定删除？" okText="删除" cancelText="取消" onConfirm={() => delAccount(item)}>
                                        <span style={{ color: '#FF4949', cursor: 'pointer' }}>删除</span>
                                    </Popconfirm>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>



            <div id='perfect'>
                <Modal centered visible={isApply} footer={null} closable={false}
                    getContainer={() => document.getElementById('perfect')}
                    bodyStyle={{
                        padding: '0', backgroundColor: '#FFFFFF',
                        // width: 554,
                    }}
                >
                    {account ?<div style={{ textAlign: 'center', padding: '57px 55px' }}>
                        <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{['修改资料']}</h3>
                        <div style={{
                            color: '#666666', display: 'flex', alignItems: 'center',
                            justifyContent: 'flex-start',
                            marginTop: '30px', fontWeight: 500
                        }}>
                            {['角色']}
                        </div>
                        <div style={{ marginTop: '2px', textAlign:'left' }}>
                            <Select allowClear placeholder={'请选择经营规模'} onChange={(e)=>onChangeInfo('roleIds',[e])}
                            style={{ width: 410, height: 48 }} value={shopInfo?.roleIds?shopInfo?.roleIds[0]:null}>
                                {
                                    roleList.map((item,index)=>{
                                        return <Option value={item.id} key={index}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div style={{
                            color: '#666666', display: 'flex', alignItems: 'center',
                            justifyContent: 'flex-start',
                            marginTop: '10px', fontWeight: 500
                        }}>
                            {['手机号']}
                        </div>
                        <div style={{ marginTop: '2px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Input style={{ height: '48px', borderRadius: '4px' }} placeholder={['请输入手机号']}
                                onChange={e => onChangeInfo('phone',e.target.value.trim())} value={shopInfo.phone} />
                        </div>
                        <div style={{
                            color: '#666666', display: 'flex', alignItems: 'center',
                            justifyContent: 'flex-start',
                            marginTop: '10px', fontWeight: 500
                        }}>
                            {['初始密码']}
                        </div>
                        <div style={{ marginTop: '2px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Input style={{ height: '48px', borderRadius: '4px' }} placeholder={['请输入初始密码']}
                                onChange={e => onChangeInfo('password',e.target.value.trim())} value={shopInfo.password} />
                        </div>
                        <Button disabled={shopInfo.phone.length < 11 || shopInfo.password.length < 6 || shopInfo.roleIds.length ==0} style={{
                            width: '210px', height: '40px', borderRadius: '4px', border: 0,
                            textAlign: 'center', color: '#FFF', margin: '0 auto', marginBottom: '12px',
                            background: shopInfo.phone.length < 11 || shopInfo.password.length < 6 || shopInfo.roleIds.length ==0 ? '#EEEEEE' : 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod', marginTop: '48px'
                        }}
                            onClick={()=>addAccount()}>
                            {['确定添加']}
                        </Button>
                        <CloseCircleOutlined style={{
                            position: 'absolute', right: '29px', top: '29px', fontSize: '20px', color: '#888888',
                            cursor: 'pointer'
                        }}
                            onClick={() => {setApply(false);setShopInfo({
                                phone:'', password:'', roleIds:[]
                            })}} />
                    </div>:''}
                </Modal>
            </div>

        </div>
    )
}
export default withRouter(SubAccount);
