import React, { useState, useEffect } from 'react'
import { DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import { Select, Input, List, notification, Avatar, Skeleton, Divider, Image, Spin } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom'
import * as Api from '../../../service/https';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../../utils/redux/index'
import withRouter from '../../../utils/withRouter'
import intl from 'react-intl-universal';
import '../../../asset/css/TopicList.scss'
import PkModel from '../../../components/PkModel'
import ShareTopicModel from '../../../components/Share/ShareTopicModel'
import toKMB from '../../../utils/KMB'
import toHMS from '../../../utils/toHMS'
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import {debounce} from '../../../utils/debounce'

const { Option } = Select;

const SubscribedTopics = (props) => {
    const navigate = useNavigate();
    const [state, setSate] = useState(store.getState());
    const [textJson, setTextJson] = useState(intl.get('TopicList'))
    const [sortList, setSortList] = useState([]);
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [widths, setWidths] = useState(0)
    useEffect(() => {
        // console.log(props)
        notification.destroy()
        setSortList([
            textJson.PlaysNum,
            textJson.VideosNum
        ])
        
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
            notification.destroy()
        }
    }, [])

    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(height)
        let num = state.collapsed + (width > 1890 ? 6 : width > 1650 ? 5 : 4)
        setWidths(num)
    }
    useEffect(() => {
        heightFun()
    }, [state.collapsed])


    const option = {
        series: [
          {
            type: 'wordCloud', size: ['100%', '100%'],
            // width:'55%', height:'55%',
            gridSize: 2,
            sizeRange: [12, 30],
            rotationRange: [0, 90],
            shape: 'square', 
            drawOutOfBound:true,
            // shape: (theta)=> {
            //     return Math.min(
            //     1 / Math.abs(Math.cos(theta)),
            //     1 / Math.abs(Math.sin(theta))
            //     )
            // },
            textStyle: {
                color: ()=> {
                    return (
                    'rgb(' +
                    [
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255),
                    ].join(',') +
                    ')'
                    );
                },
                fontWeight:'bold',
              emphasis: {
                shadowBlur: 10,
                shadowColor: '#333',
              },
            },
            data: [ ],
          },
        ],
    };

    const [options, setOptions] = useState(option)


    const [sortNumList, setItems] = useState([
        {start: 0, key: '<', end: 10000},
        {start: 10000, key: '~', end: 100000},
        {start: 100000, key: '~', end: 500000},
        {start: 500000, key: '~', end: 1000000},
        {start: 1000000, key: '~', end: 5000000},
        {start: 5000000, key: '~', end: 10000000},
        {start: 10000000, key: '~', end: 50000000},
        {start: 50000000, key: '<', end: 0},
        {start: '', key: '~', end: ''},
        {start: '', key: '~', end: ''}
    ]);
    const [sortVideoIndex, setRangeVideoNumDto] = useState(-1)
    const [sortPlayIndex, setRangePlayNumDto] = useState(-1)
    const changeMinInput = (index, value)=>{
        let v = JSON.parse(JSON.stringify(sortNumList))
        v[index].start = value
        setItems(v)
    }
    const changeMaxInput = (index, value)=>{
        let v = JSON.parse(JSON.stringify(sortNumList))
        v[index].end = value
        setItems(v)
    }
    const changeRangeVideoNumDto = (index)=>{
        setPageIndex(1)
        setRangeVideoNumDto(index == undefined ? -2 : index)
        if(index==undefined){
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[8] = {start: '', key: '~', end: ''}
            setItems(v)
        }
    }
    const changeRangePlayNumDto = (index)=>{
        setPageIndex(1)
        setRangePlayNumDto(index == undefined ? -2 : index)
        if(index==undefined){
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[9] = {start: '', key: '~', end: ''}
            setItems(v)
        }
    }

    
    const [sortIndex, setSortIndex] = useState(0);
    const sortMethod = (index) => {
        setData([])
        setSortIndex(index)
    }
    useEffect(() => {
        loadMoreData();
    }, [sortIndex])
    useEffect(()=>{
        if(sortVideoIndex != -1 || sortPlayIndex != -1) loadMoreData()
    },[sortVideoIndex, sortPlayIndex])

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0)
    const [page, setPageIndex] = useState(1)

    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let postValue = {
            bloggerTitleSortRuler: sortIndex + 1,
            current: page,
            size: 10,
            rangeViewNumDto: sortVideoIndex== (-2 || -1) ? {"start":0 , "end": 0} : sortNumList[sortVideoIndex] ,
            rangeVideoNumDto: sortPlayIndex== (-2 || -1) ? {"start":0 , "end": 0} : sortNumList[sortPlayIndex] ,
        }
        Api.getSubscribeTitle(postValue).then(res => {
            if(res.code == 200){
                setData(page == 1 ? res.data.records : [...data, ...res.data.records]);
                setTotal(res.data.total)
                setPageIndex(page + 1)
                setLoading(false);
            }
        })
    };
    useEffect(()=>{
        if(data.length > 0){
            let list = []
            data?.map((item,index)=>{
                list.push({name: item.title, value: index+1})
            })
            option.series[0].data = list
            setOptions(option)
        }
    },[data])

    const [collectList, setCollectList] = useState([])

    // 别表
    const SmallDiv = (item, index) => {
        return <div key={index} style={{
            width: '100%', height: '151px', display: 'flex', alignItems: 'center',
            backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F6F6F6', cursor: 'pointer'
        }}
            onClick={() => detail(item, index)}>

            <div style={{ width: '8%', paddingLeft: '19px' }}>
                <div style={{ width: '32px', textAlign: 'center', fontWeight: 500 }}>{index + 1}</div>
            </div>
            <div style={{ width: '25%', display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '14px' }}>
                    <Image src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/topic.png'} width={34} preview={false} />
                </div>
                <div>
                    <div style={{ fontFamily: 'neue', fontWeight: '800', fontSize: '16px' }}>{item.title}</div>
                    <div style={{ color: '#888888', fontFamily: 'neue' }}>{intl.get('Index')['更新时间']}：{item.updateTime}</div>
                </div>
            </div>
            <div style={{ width: '8%', fontFamily: 'neue' }}>{toKMB(item.viewCount,2)}</div>
            <div style={{ width: '8%', fontFamily: 'neue' }}>{toKMB(item.videoCount,2)}</div>
            <div style={{ width: '8%', fontFamily: 'neue' }}>{toKMB(item.diggCount,2)}</div>
            <div style={{ width: '8%', fontFamily: 'neue' }}>{toKMB(item.commentCount,2)}</div>
            <div style={{ width: '8%', fontFamily: 'neue' }}>{toKMB(item.shareCount,2)}</div>
            <div style={{ width: '20%', display: 'flex' }}>
                {
                    item.hotVideo?.map((item2, index) => index<2?<div key={index} style={{ marginRight: '10px', position: 'relative' }}
                        onClick={(e)=>playAddr(e,item2)}>
                        <Image src={item2.cover} preview={false} width={80} height={111} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'} />
                        <div style={{ position: 'absolute', bottom: '0', display: 'flex', alignItems: 'center', width: '80px', textAlign: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', width: '80px', justifyContent: 'center' }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/playCircle.png'} style={{ width: 12, height: 12, marginRight: '3px' }} alt="" />
                                <div style={{ color: '#fff', fontSize: '13px' }}>{toHMS(item2.duration || 0)}</div>
                            </div>
                        </div>
                    </div>:'')
                }
            </div>
            <div style={{ width: '' }}>
                        <img
                            onClick={(e) => delCollect(e, item, index, collectList.indexOf(index))}
                            src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/scDel.png'}
                            style={{width:'36px'}}
                        />
                </div>
        </div>
    }

    const detail = (item, index) => {
        window.open(`/index/TopicDetail/${item.titleId}/${item.title}`)
    }
    const playAddr = (e, item2) => {
        e.stopPropagation();
        window.open(item2.playAddr)
    }
    const delCollect = (e,item,index) => {
        e.stopPropagation();
        Api.delSubscribeTitle({subscribeTitleId: item.id}).then(res => {
            // console.log(res)
            if (res.code == 200) {
                setPageIndex(1)
            }
        })
    }
    useEffect(()=>{
        if(page == 1) loadMoreData()
    },[page])







    
    const [valueItem, setValItem] = useState(null)
    const addTopic = ()=>{
        if(valueSearch){
            // let v = JSON.parse(JSON.stringify(options))
            // // console.log(v.series)
            // v.series[0].data.push({name: valueItem.title || valueSearch, value: v.series[0].data.length+1})
            // option.series[0].data = v.series[0].data
            // setOptions(option)
            let obj = {
                titleId: valueItem?.id || '',
                title: valueItem?.title || valueSearch,
                videoCount: valueItem?.videoCount || 0,
                viewCount: valueItem?.viewCount || 0
            }
            Api.addSubscribeTitle(obj).then(res=>{
                setFetching(false)
                if(res.code == 200){
                    openNotificationWithIcon('success', '添加成功！')
                    setValueSearch(null)
                    setValItem(null)
                    setPageIndex(1)
                }
            })
        }
    }

    const openNotificationWithIcon = (type, message = '')=> {
        notification[type]({
          description: message, top: 100, duration: 3
        });
    };



    const [fetching, setFetching] = useState(false)
    const [valueSearch, setValueSearch] = useState(null)
    const [searchList, setSearchList] = useState([])
    const handleSearch = debounce((value) => {
        console.log(value)
        // setValueSearch(value==''?null:value)
        if(value!='')setValueSearch(value)
    }, 500)
    
    useEffect(()=>{
        if (valueSearch) {
            getSearch()
        } else {
            setSearchList([])
        }
    }, [valueSearch])
    const getSearch = () => {
        setFetching(true)
        Api.getSearch({keyword: valueSearch, leaderboardCategoryLabel: 3}).then(res=>{
            setFetching(false)
            if(res.code == 200){
                setSearchList(res.data.data)
            }
        })
    }
    const changeSearch = (index) => {
        let item = searchList[index]
        console.log(index)
        setValueSearch(item.title)
        setValItem(item)
    }

    

    return (
        <div id='TopicList'>
            <nav style={{ width: '100%', position: 'relative', fontWeight: 500 }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt=""
                    style={{ width: '100%' }} />
                <div style={{
                    width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt="" 
                    style={{width:'190px'}}/>
                    <div style={{
                        width: '10px', height: '10px', backgroundColor: 'black', borderRadius: '5px',
                        margin: '0 20px'
                    }}></div>
                    <span style={{ fontSize: '20px' }}>{intl.get('Index')['我的订阅话题']}</span>
                </div>
            </nav>

            <div id='login' style={{
                width: '100%', backgroundColor: '#FFF', paddingTop: '28px', paddingBottom: '28px',
                fontWeight: 500, display:'flex', alignItems:'center', justifyContent:'space-evenly'
            }}>

                <div>
                    {/* <Input value={texts} onChange={e=> setTexts(e.target.value)} style={{width:'398px', height:'48px'}} placeholder='请输入你想订阅的话题后，按确认按钮添加' /> */}
                    <div id='showSearchTopic' style={{height:'48px', border:'1px solid #EEEEEE', paddingTop:'8px'}}>
                        <Select
                            getPopupContainer={() => document.getElementById('showSearchTopic')}
                            showSearch bordered={false}
                            value={valueSearch}
                            placeholder={intl.get('ReleaseDemand')['请输入你想订阅的话题后，按确认按钮添加']}
                            style={{width:'398px', textAlign:'center'}}
                            defaultActiveFirstOption={true}
                            autoClearSearchValue={false}
                            showArrow={false}
                            filterOption={false}
                            onChange={(value)=>changeSearch(value)}
                            onSearch={(value)=>handleSearch(value)}
                            onBlur={()=>setValueSearch(valueSearch)}
                            notFoundContent={fetching ? <Spin size="small" /> : valueSearch?<div>No Data！ 🤐</div>:null}
                            // allowClear={true}
                            // suffixIcon={}
                        >
                            {
                                searchList?.map((item,index)=>{
                                    return <Option key={index} value={index}>
                                        <div><Image style={{width:'30px', marginRight:'10px'}} preview={false}
                                            src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/topic.png'} />{item.title}</div>
                                    </Option>
                                })
                            }
                        </Select>
                    </div>
                    <div onClick={()=>addTopic()} style={{width:'126px', height:'38px', background:'#F7385B', color:'#FFF', textAlign:'center',
                        lineHeight:'38px', borderRadius:'4px', margin:'0 auto', marginTop:'15px', cursor:'pointer'}}>{intl.get('ReleaseDemand')['确认添加']}</div>
                </div>
                <div style={{width:'1px', height:'145px', background:'#EEEEEE'}}></div>
                <div style={{width:'45%', height:'145px'}}>
                    {options.series[0].data.length > 0 ? <ReactEcharts
                        option={options}
                        notMerge
                        lazyUpdate
                        style={{ height: '100%', width: '100%' }}
                    /> :
                    <div style={{ height:'100%', display: 'flex', justifyContent:'center', alignItems: 'center' }}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                        <div style={{marginLeft:'30px', textAlign: 'center', color:'#666' }}>{intl.get('ReleaseDemand')['暂无订阅的话题，请在左侧添加订阅']}</div>
                    </div>}
                </div>

            </div>

            {/* 排序 */}
            <div style={{
                width: '100%', height: '60px', backgroundColor: '#FFF', marginTop: '10px',
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '47px',
                paddingRight: '24px'
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <div id='fans'>
                        <span>{textJson.PlaysNum}</span>
                        <Select
                            onClear={e=>changeRangeVideoNumDto(undefined)} 
                            allowClear={true}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <div id='login' style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0 10px', paddingBottom:'5px'}}>
                                        <Input value={sortNumList[8].start}
                                            onChange={e=>changeMinInput(8, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="min" /> ~ 
                                        <Input value={sortNumList[8].end} 
                                            onChange={e=>changeMaxInput(8, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="max" /> 
                                        <span style={{border: sortNumList[8].start>=0&&parseInt(sortNumList[8].end)>parseInt(sortNumList[8].start)?'':'1px solid #EEEEEE', borderRadius:'4px', padding:'5.5px 11px', marginLeft:'5px', cursor:'pointer',
                                            color: sortNumList[8].start>=0&&parseInt(sortNumList[8].end)>parseInt(sortNumList[8].start)?'#FFF':'#CCCCCC', 
                                            background: sortNumList[8].start>=0&&parseInt(sortNumList[8].end)>parseInt(sortNumList[8].start)?'#F7385B':'#FFF', fontSize:'12px'}} 
                                            onClick={()=>changeRangeVideoNumDto(8)}>{intl.get('Index')['确定']}</span>
                                    </div>
                                </>
                            )}
                            value={(sortVideoIndex == -1||sortVideoIndex ==-2) ? <span>{textJson.PleaseSelect}</span> : ((sortNumList[sortVideoIndex].start>0?toKMB(sortNumList[sortVideoIndex].start):'') + ' '+sortNumList[sortVideoIndex].key+' ' + (sortNumList[sortVideoIndex].end>0?toKMB(sortNumList[sortVideoIndex].end):''))}
                            style={{ width: 200, borderRadius:'26px', marginLeft:'10px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={textJson.PleaseSelect}>
                            {sortNumList?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeRangeVideoNumDto(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                            ))}
                        </Select>
                    </div>
                    <div id='fans'>
                        <span style={{ marginLeft: state.language == 'zh' ? '40px' : '20px' }}>{textJson.VideosNum}</span>
                        <Select
                            onClear={e=>changeRangePlayNumDto(undefined)} 
                            allowClear={true}
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <div id='login' style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0 10px', paddingBottom:'5px'}}>
                                        <Input value={sortNumList[9].start}
                                            onChange={e=>changeMinInput(9, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="min" /> ~ 
                                        <Input value={sortNumList[9].end} 
                                            onChange={e=>changeMaxInput(9, e.target.value)}
                                            style={{width:'50px', paddingLeft:'4px', paddingRight:'4px', textAlign:'center'}} placeholder="max" /> 
                                        <span style={{border: sortNumList[9].start>=0&&parseInt(sortNumList[9].end)>parseInt(sortNumList[9].start)?'':'1px solid #EEEEEE', borderRadius:'4px', padding:'5.5px 11px', marginLeft:'5px', cursor:'pointer',
                                            color: sortNumList[9].start>=0&&parseInt(sortNumList[9].end)>parseInt(sortNumList[9].start)?'#FFF':'#CCCCCC', 
                                            background: sortNumList[9].start>=0&&parseInt(sortNumList[9].end)>parseInt(sortNumList[9].start)?'#F7385B':'#FFF', fontSize:'12px'}} 
                                            onClick={()=>changeRangePlayNumDto(9)}>{intl.get('Index')['确定']}</span>
                                    </div>
                                </>
                            )}
                            value={(sortPlayIndex == -1||sortPlayIndex ==-2) ? <span>{textJson.PleaseSelect}</span> : ((sortNumList[sortPlayIndex].start>0?toKMB(sortNumList[sortPlayIndex].start):'') + ' '+sortNumList[sortPlayIndex].key+' ' + (sortNumList[sortPlayIndex].end>0?toKMB(sortNumList[sortPlayIndex].end):''))}
                            style={{ width: 200, borderRadius:'26px', marginLeft:'10px' }} getPopupContainer={() => document.getElementById('fans')}
                            placeholder={textJson.PleaseSelect}>
                            {sortNumList?.map((item, index) => (
                                index < 8 ? <Option key={index}><div onClick={e=>changeRangePlayNumDto(index)}>{item.start>0?toKMB(item.start):''} {item.key} {item.end>0?toKMB(item.end):''}</div></Option> : ''
                            ))}
                        </Select>
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        sortList?.map((item, index) => {
                            return <div key={index} className='active' style={{
                                display: 'flex', alignItems: 'center', marginRight: '36px',
                                color: sortIndex == index ? '#F7385B' : '#888888', cursor: 'pointer'
                            }}
                                onClick={() => sortMethod(index)}>
                                {item}
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex == index ? 's' : '') + '.png'} alt=""
                                    style={{ marginLeft: '6px', marginTop: '2px' }} />
                            </div>
                        })
                    }
                </div>
            </div>

            {/* 列表 */}
            <div style={{
                width: '100%', backgroundColor: '#FFFFFF', padding: '24px 24px 24px 30px',
                marginTop: '10px'
            }}>
                <div style={{
                    width: '100%', height: '50px', display: 'flex', alignItems: 'center', backgroundColor: '#F6F6F6',
                    fontWeight: 500
                }}>
                    <div style={{ width: '8%', paddingLeft: '19px' }}>{textJson.Ranking}</div>
                    <div style={{ width: '25%', paddingLeft: '14px' }}>{intl.get('Index')['我的订阅话题']}</div>
                    <div style={{ width: '8%', paddingRight: '10px' }}>{textJson.PlaysNum}</div>
                    <div style={{ width: '8%', paddingRight: '10px' }}>{textJson.VideosNum}</div>
                    <div style={{ width: '8%', paddingRight: '10px' }}>{textJson.LikesNum}</div>
                    <div style={{ width: '8%', paddingRight: '10px' }}>{textJson.CommentsNum}</div>
                    <div style={{ width: '8%', paddingRight: '10px' }}>{textJson.SharesNum}</div>
                    <div style={{ width: '20%', paddingRight: '10px' }}>{textJson.HotsNum}</div>
                    <div style={{ width: '' }}>{textJson.Operation}</div>
                </div>
                <Spin tip="数据加载中..." spinning={loading}>
                <InfiniteScroll
                    dataLength={data.length}
                    next={loadMoreData}
                    hasMore={data.length < total}
                    loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                    endMessage={<Divider plain>{textJson.Nothing} 🤐</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={data} grid={{ gutter: 0, column: 1 }}
                        renderItem={(item, index) => <div key={index} style={{ paddingTop: 0 }}>
                            {SmallDiv(item, index)}
                        </div>}
                    />
                </InfiniteScroll>
                </Spin>
            </div>



        </div>
    )
}

export default withRouter(SubscribedTopics);