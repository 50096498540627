import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import { SearchOutlined, UpSquareTwoTone } from '@ant-design/icons';
import { Select, Checkbox, List, notification, Avatar, Image, Spin, Input } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../utils/redux/index'
import intl from 'react-intl-universal';
import * as Api from '../../service/https'
import toKMB from '../../utils/KMB'
import PkModel from '../../components/PkModel'


const { Search } = Input;

const BloggerComparison = ()=>{

    useEffect(()=>{
        searchData()
    },[])

    const [data, setData] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [loading, setLoading] = useState(false);
    const searchData = () => {
        setLoading(true)
        let obj = {
            keyword: searchValue
        }
        Api.bloggerSearch(obj).then(res => {
            // console.log(res)
            setLoading(false)
            if (res.code == 200) {
                setData(res.data.data)
            }
        })
    };


    const [isHistory, setIsHistory] = useState(false)
    const changeHistory = (bools)=>{
        setIsHistory(bools)
        if(bools)compareHistory()
    }


    
    // 对比历史
    const [compareList, setCompareList] = useState([])
    const compareHistory = ()=>{
        setLoading(true)
        Api.compareHistory({current: 1, size: 50}).then(res=>{
            setLoading(false)
            if(res.code == 200){
                setCompareList(res.data.records)
            }
        })
    }


    // 别表
    const SmallDiv = (item, index) =>{
        return <div key={index} style={{width:'100%', height:'100px', display:'flex', alignItems:'center', 
            backgroundColor: index%2==0 ?'#FFFFFF':'#F6F6F6', cursor:'pointer'}}
            onClick={()=>detail(item)}>
            <div style={{width:'40%', display:'flex', alignItems:'center', paddingLeft:'40px'}}>
                <Image src={item.avatarMedium} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                    style={{width:'60px', height:'60px', borderRadius:'30px', marginRight:'16px'}}/>
                <div style={{color:'#888888'}}>
                    <div style={{color:'#333333', fontWeight:'bold', fontSize:'16px',
                        display:'flex', alignItems:'center'}}>
                        <div style={{height:'25px', fontWeight:'bold', fontSize:'16px', lineHeight:'25px'}}>{item.nickname}</div>
                        {item.verified?<img style={{marginLeft:'3px', width:'14px', height:'14px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} />:''}
                    </div>
                    TikTok: {item.uniqueId}
                </div>
            </div>
            <div style={{width:'15%'}}>{toKMB(item.followerCount,2)}</div>
            <div style={{width:'15%'}}>{toKMB(item.videoCount,2)}</div>
            <div style={{width:'30%', paddingRight:'40px'}}>
                <div onClick={e=>isChecked(item) ? delPkItem(e,item, -1) :addPK(e,item,index)} 
                    className='duibi' style={{width:'164px', height:'40px', border:'1px solid #EEEEEE', borderRadius:'4px',
                    textAlign:'center', lineHeight:'38px', float:'right', color:isChecked(item) ?'#FFF':'#888888',
                    background: isChecked(item)?'linear-gradient(180deg, #F7385B, #FF716E)':'' }}>
                    {isChecked(item) ?<span>— &nbsp;{intl.get('BloggerComparison').RemoveContrast}</span>:
                    <span>+ &nbsp;{intl.get('BloggerComparison').AddContrast}</span>}</div>
            </div>
        </div>
    }
    const isChecked = (item)=>{
        let bools = false
        for (let index = 0; index < imgList.length; index++) {
            const e = imgList[index]
            if(item.id == e.bloggerId){
                bools = true
                break
            }
        }
        return bools
    }


    const [imgList, setImgList] = useState([])
    const addPK = (e, item,index)=>{
        e.stopPropagation();
        if(imgList.length < 6){
            let list = JSON.parse(JSON.stringify(imgList))
            list.push({
                bloggerId: item.id, uniqueId: item.uniqueId, nickname: item.nickname,
                avatarMedium: item.avatarMedium
            })
            setImgList(list)
        }
    }
    const delPkItem = (e, item, i)=>{
        e.stopPropagation()
        let delIndex = i
        let list = JSON.parse(JSON.stringify(imgList))
        if(i == -1){
            for (let index = 0; index < imgList.length; index++) {
                const e = imgList[index]
                if(item.id == e.bloggerId){
                    delIndex = index
                    break
                }
            }
        }
        if(delIndex > -1){
            list.splice(delIndex, 1)
            setImgList(list)
        }
    }




    const [pkResultList, setPkResultList] = useState([])
    const [isPk, setIsPk] = useState(false)
    useEffect(()=>{
        if(pkResultList.length>0 && (imgList.length > 0 || isHistory)) setIsPk(true)
    },[pkResultList])
    const pkResult = (item = false)=>{
        let obj = {
            bloggerList: item ? item.bloggerCompareHistoryList : imgList, 
            type: item ? 2 : 1, compareId: item ? item.compareId : ''
        }
        Api.bloggerCompare(obj).then(res=>{
            if(res.code == 200){
                setPkResultList(res.data)
            }
        })
    }

    const detail = (item, index) => {
        // console.log(item)
        window.open(`/index/bloggerDetail/${item.uniqueId}`)
    }



    return <div id='BloggerComparison'>
        <div id='BloggerRanking'>
        <nav style={{width:'100%', position:'relative', fontWeight: 500}}>
            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt="" 
                style={{width:'100%'}}/>
            <div style={{width:'100%', height:'100%', position:'absolute', top:0, left:0,
                display:'flex', alignItems:'center', justifyContent:'center'}}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt="" 
                    style={{width:'190px'}}/>
                <div style={{width:'10px', height:'10px', backgroundColor:'black', borderRadius:'5px',
                    margin:'0 20px'}}></div>
                <span style={{fontSize:'20px'}}>{intl.get('BloggerComparison').BloggerComparison}</span>
            </div>
        </nav>


        {/*  */}
        <div style={{width:'100%', height:'60px', backgroundColor:'#FFF', marginTop:'10px',
            display:'flex', alignItems:'center', justifyContent:'space-between', padding: '0 30px'}}>
            <div style={{display:'flex', alignItems:'center', fontWeight:500}}>
                <div style={{backgroundColor: !isHistory?'#FFEEF1':'#FFF', padding:'9px 27px', borderRadius:'4px',
                    color: !isHistory?'#F7385B':'#888888', cursor:'pointer'}}
                    onClick={()=>changeHistory(false)}>{intl.get('BloggerComparison').BloggerComparison}</div>
                <div style={{backgroundColor: isHistory?'#FFEEF1':'#FFF', padding:'9px 27px', borderRadius:'4px',
                    color: isHistory?'#F7385B':'#888888', cursor:'pointer'}}
                    onClick={()=>changeHistory(true)}>{intl.get('BloggerComparison').ComparativeHistory}</div>
            </div>
            <div style={{color:'#888888'}}>{intl.get('BloggerComparison').UpToBloggers}</div>
        </div>

        <Spin tip="数据加载中..." spinning={loading}>
        {!isHistory ? <div style={{marginTop:'10px'}}>
            <div className='BloggerComparisonLeft' style={{width:'calc(100% - 470px)', backgroundColor:'#FFF', padding:'24px 30px', float:'left'}}>
                <div style={{width:'556px', position:'relative'}}>
                    <Input onChange={e=>setSearchValue(e.target.value)} placeholder={intl.get('Header')['请输入博主名称']} style={{height:'40px'}} />
                    <section onClick={()=>searchData()} style={{position:'absolute', top:'1px', right:'1px', width:'157px', height:'40px', 
                        textAlign:'center', cursor:'pointer', color: '#333333', borderLeft:'1px solid #EEEEEE',
                        lineHeight:'38px', fontSize:'14px', letterSpacing:'0.05px', 
                        }}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/search.png'} style={{width:'20px', marginRight:'5px', marginTop:'-1px'}} alt="" />
                        {intl.get('BloggerComparison').Search}
                    </section>
                </div>
                {data.length > 0 ? <div>
                    <div style={{width:'100%', height:'50px', display:'flex', alignItems:'center', backgroundColor:'#F6F6F6',
                        fontWeight:500, marginTop:'25px'}}>
                        <div style={{width:'40%', paddingLeft:'54px'}}>{intl.get('BloggerRanking').Blogger}</div>
                        <div style={{width:'15%', paddingRight:'10px'}}>{intl.get('BloggerRanking').FansNum}</div>
                        <div style={{width:'15%', paddingRight:'10px'}}>{intl.get('BloggerRanking').NumberVideos}</div>
                        <div style={{width:'30%', paddingRight:'40px',}}>
                            <div className='duibi' style={{width:'164px', height:'40px', lineHeight:'38px', float:'right',
                                textAlign:'center' }}>{intl.get('BloggerRanking').Operation}</div>
                            
                        </div>
                    </div>
                    
                    <div>
                        {
                            data?.map((item,index)=>{
                                return SmallDiv(item,index)
                            })
                        }
                    </div>
                </div>:''}
            </div>

            <div className='BloggerComparisonRight' style={{width:'450px', marginLeft:'20px', backgroundColor:'#FFF', padding:'36px 30px', float:'right'}}>
                <div style={{fontWeight:500, fontSize:'16px', marginBottom:'10px'}}>{intl.get('BloggerComparison').SelectedBloggers} 
                    <span style={{color:'#888888', fontWeight:400}}>{imgList.length}/6</span></div>
                {
                    imgList?.map((item,index)=>{
                        return <div key={index} style={{width:'100%', height:'100px', display:'flex', alignItems:'center', 
                            cursor:'pointer', justifyContent:'space-between'}}
                            >
                            <img src={item.avatarMedium} alt="" 
                                style={{width:'60px', height:'60px', borderRadius:'30px', marginRight:'16px'}}/>
                            <div style={{width:'100%', height:'100%', display:'flex', alignItems:'center', borderBottom:'1px solid #EEEEEE'}}>
                                <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between',
                                    }}>
                                    <div style={{color:'#888888'}}>
                                        <div style={{color:'#333333', fontWeight:'bold', fontSize:'16px',
                                            display:'flex', alignItems:'center'}}>
                                            <div style={{height:'25px', fontWeight:'bold', fontSize:'16px', lineHeight:'25px'}}>{item.nickname}</div>
                                        </div>
                                        TikTok: {item.uniqueId}
                                    </div>
                                    <img onClick={e=>delPkItem(e, item, index)} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/delete.png'} alt="" />
                                </div>
                            </div>
                        </div>
                    })
                }
                
                {imgList.length<6?<div style={{width:'100%', height:'100px', display:'flex', alignItems:'center', 
                    cursor:'pointer'}}
                    >
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} alt="" 
                        style={{width:'60px', height:'60px', borderRadius:'30px', marginRight:'16px'}}/>
                    {intl.get('BloggerComparison').CanAdd}
                </div>:''}
                <div onClick={()=>imgList.length>0 ? pkResult():''} style={{width:'70%', height:'40px', background:'linear-gradient(180deg, #F7385B, #FF716E)',
                    textAlign:'center', lineHeight:'40px', color:'#FFF', borderRadius:'4px', margin:'0 auto',
                    cursor:'pointer', marginTop:'40px'}}>{intl.get('BloggerComparison').StartComparison}</div>
                <div onClick={()=>setImgList([])} style={{width:'70%', height:'40px', border:'1px solid #EEEEEE', cursor:'pointer',
                    textAlign:'center', lineHeight:'38px', color:'#666666', borderRadius:'4px', margin:'0 auto',
                    marginTop:'10px'}}>{intl.get('BloggerComparison').Empty}</div>
            </div>
        </div>
        :
        <div>
            {
                compareList.length > 0 ? compareList?.map((item,index)=>{
                    return <div key={index} style={{width:'100%', height:'100px', display:'flex', alignItems:'center', backgroundColor:'#FFF',
                        marginTop:'10px', padding:'0 60px'}}>
                        <div style={{width:'60px'}}>{intl.get('BloggerRanking').Blogger}</div>
                        <div className='pkHis' style={{width:'calc(100% - 60px - 133px - 20px)', display:'flex', alignItems:'center',
                            overflowY:'auto', padding:'15px 0'}}>
                            {
                                item.bloggerCompareHistoryList?.map((itemS,indexS)=>{
                                    return <div key={indexS} style={{minWidth:'255px', display:'flex', alignItems:'center', cursor:'pointer'}}
                                    onClick={()=>detail(itemS)}>
                                        <img src={itemS.avatarMedium} alt="" 
                                            style={{width:'60px', height:'60px', borderRadius:'30px', marginRight:'16px'}}/>
                                        <div style={{color:'#888888'}}>
                                            <div style={{color:'#333333', fontWeight:'bold', fontSize:'16px',
                                                display:'flex', alignItems:'center'}}>
                                                <div style={{height:'25px', fontWeight:'bold', fontSize:'16px', lineHeight:'25px'}}>{itemS.nickname}</div>
                                                <img style={{marginLeft:'3px', width:'14px', height:'14px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} />
                                            </div>
                                            TikTok: {itemS.uniqueId}
                                        </div>
                                    </div>
                                })
                            }
                            
                        </div>
                        <div onClick={()=>pkResult(item)} style={{width:'133px', height:'40px', border:'1px solid #EEEEEE', cursor:'pointer',
                            textAlign:'center', lineHeight:'38px', color:'#888888', borderRadius:'4px',
                            marginLeft:'20px'}}>{intl.get('BloggerComparison').CompareAgain}</div>
                    </div>
                }) :
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '135px' }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 166, height: 159 }} alt="" />
                    <div style={{ width: '242px', textAlign: 'center', marginTop: '13px' }}>抱歉，当前没有对比记录</div>
                </div>
            }
        </div>}
        </Spin>


        {isPk ? <PkModel clickFun={()=>setIsPk(false)} pkResultList={pkResultList}/> : ''}
    </div>        
    </div>
}
export default withRouter(BloggerComparison);