import React, { useState, useEffect } from 'react'
import withRouter from '../utils/withRouter'
import { DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import { Modal, Table, List, notification, Avatar, Skeleton, Divider } from 'antd';
import intl from 'react-intl-universal';
import toKMB from '../utils/KMB';

const PkModel = (props) => {
    const [pkResultList, setPkResultList] = useState([])
    const [widths, setWidths] = useState(0)
    useEffect(() => {
        heightFun()
        getPkResultList()
        console.log(props)
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
            notification.destroy()
        }
    },[])

    const heightFun = () => {
        let width = document.documentElement.clientWidth
        // let height = document.documentElement.clientHeight
        // console.log(height)
        let num = width > 1750 ? 210 : 180
        setWidths(num)
    }
    const getPkResultList = () => {
        let list = []
        props.pkResultList?.map(item=>{
            item.avatarMedium = item.avatarMedium.split('https://brandgogo.oss-cn-beijing.aliyuncs.com').length>1? item.avatarMedium.replace('https://zc-tk.oss-cn-beijing.aliyuncs.com/',''):item.avatarMedium
            list.push(item)
        })
        setPkResultList(list)
    }

    const data = [
        {
            key: '1',
            name: 'John Brown',
            age: 32,
            address: 'New York No. 1 Lake Park',
        },
        {
            key: '2',
            name: 'Jim Green',
            age: 42,
            address: 'London No. 1 Lake Park',
        },
        {
            key: '3',
            name: 'Joe Black',
            age: 32,
            address: 'Sidney No. 1 Lake Park',
        },
        {
            key: '4',
            name: 'Jim Red',
            age: 32,
            address: 'London No. 2 Lake Park',
        },
        {
            key: '4',
            name: 'Jim Red',
            age: 32,
            address: 'London No. 2 Lake Park',
        },
        {
            key: '4',
            name: 'Jim Red',
            age: 32,
            address: 'London No. 2 Lake Park',
        },
    ];



    return <div>
        <Modal centered visible={true} footer={null} closable={false}
            getContainer={() => document.getElementById('BloggerRanking')}
            bodyStyle={{
                backgroundColor: '#FFFFFF', textAlign: 'center',
                padding: '30px', width: (pkResultList.length+1) * widths + 60
            }}
        >
            <div style={{ fontSize: '18px', fontWeight: 'bold' }}>{intl.get('BloggerComparisonResult')['对比结果']}</div>
            <div className='pkModel' style={{ width: '100%', height: '650px', border: '0.5px solid #EEEEEE', marginTop: '30px' }}>
                <div style={{ width: '100%', display: 'flex' }}>
                    <div style={{ width: '210px', height: '168px', border: '0.5px solid #EEEEEE', lineHeight: '168px' }}>{intl.get('Header')['博主']}</div>
                    {
                        pkResultList?.map((item, index) => {
                            return <div key={index} style={{ width: '210px', height: '168px', border: '0.5px solid #EEEEEE',
                                paddingTop:'30px' }}>
                                <img src={item.avatarMedium} alt="" 
                                    style={{width:'60px', height:'60px', borderRadius:'30px', marginRight:'16px'}}/>
                                    <div className='over1' style={{fontSize:'16px', fontWeight:'bold', marginTop:'5px'}}>{item.nickname}</div>
                                    <div className='over' style={{color:'#888888'}}>TikTok: {item.uniqueId}</div>
                            </div>
                        })
                    }
                </div>
                <div className='pkModelY' style={{ width: '100%', height: '480px', overflowY: 'auto' }}>
                    <div style={{
                        width: '100%', height: '51px', backgroundColor: '#EEEEEE', lineHeight: '51px',
                        color: '#666666'
                    }}>{intl.get('BloggerComparisonResult')['关键信息对比']}</div>
                    <div style={{ width: '100%', display: 'flex' }}>
                        <div style={{width: '210px'}}>
                            <div style={{ width:'100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>{intl.get('Common')['粉丝数']}</div>
                            <div style={{ width:'100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>{intl.get('Common')['视频数']}</div>
                            <div style={{ width:'100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>{intl.get('Common')['点赞数']}</div>
                            <div style={{ width:'100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>{intl.get('Common')['平均播放数']}</div>
                            <div style={{ width:'100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>{intl.get('Common')['平均点赞数']}</div>
                            <div style={{ width:'100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>{intl.get('Common')['平均评论数']}</div>
                            <div style={{ width:'100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>{intl.get('Common')['平均分享数']}</div>
                            <div style={{ width:'100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>{intl.get('Common')['平均赞粉比']}</div>
                        </div>
                        {
                            pkResultList?.map((item, index) => {
                                return <div key={index} style={{ width:'210px'}}>
                                <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px',
                                    color: item.fansFlag ? '#F7385B' : '#333' }}>
                                    {toKMB(item.followerCount,2)}
                                </div>
                                <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px',
                                    color: item.videoFlag ? '#F7385B' : '#333' }}>
                                    {toKMB(item.videoCount,2)}
                                </div>
                                <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px',
                                    color: item.diggFlag ? '#F7385B' : '#333' }}>
                                    {toKMB(item.heartCount,2)}
                                </div>
                                <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px',
                                    color: item.playsFlag ? '#F7385B' : '#333' }}>
                                    {toKMB(item.ptvRatio_speculate,2)}
                                </div>
                                <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px',
                                    color: item.averageDiggFlag ? '#F7385B' : '#333' }}>
                                    {toKMB(item.htvRatio,2)}
                                </div>
                                <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px',
                                    color: item.averageCommentFlag ? '#F7385B' : '#333' }}>
                                    {toKMB(item.commentCount,2)}
                                </div>
                                <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px',
                                    color: item.averageShareFlag ? '#F7385B' : '#333' }}>
                                    {toKMB(item.shareCount,2)}
                                </div>
                                <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px',
                                    color: item.averageRatioFlag ? '#F7385B' : '#333' }}>
                                    {toKMB(item.htfRatio,2)}
                                </div>
                            </div>
                            })
                        }
                    </div>
                    {/* <div style={{
                        width: '100%', height: '51px', backgroundColor: '#EEEEEE', lineHeight: '51px',
                        color: '#666666'
                    }}>带货数据对比</div>
                    <div style={{ width: '100%', display: 'flex' }}>
                        <div style={{width: '210px'}}>
                            <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>带货商品数（种）</div>
                            <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>销量（件）</div>
                            <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>销售额（$）</div>
                        </div>
                        {
                            data?.map((item, index) => {
                                return <div key={index} style={{ width:'210px'}}>
                                <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>
                                    {item.name}
                                </div>
                                <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>
                                    {item.name}
                                </div>
                                <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>
                                    {item.name}
                                </div>
                            </div>
                            })
                        }
                    </div> */}
                    <div style={{
                        width: '100%', height: '51px', backgroundColor: '#EEEEEE', lineHeight: '51px',
                        color: '#666666'
                    }}>{intl.get('BloggerComparisonResult')['基础信息']}</div>
                    <div style={{ width: '100%', display: 'flex' }}>
                        <div style={{width: '210px'}}>
                            <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>{intl.get('BloggerComparisonResult')['个人认证']}</div>
                            <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>{intl.get('BloggerComparisonResult')['企业认证']}</div>
                            <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>{intl.get('BloggerComparisonResult')['联系方式']}</div>
                            <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>{intl.get('BloggerComparisonResult')['社交账号']}</div>
                        </div>
                        {
                            pkResultList?.map((item, index) => {
                                return <div key={index} style={{ width:'210px'}}>
                                    <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>
                                        {item.verified?'Personal authentication':'--'}
                                    </div>
                                    <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>
                                        --
                                    </div>
                                    <div style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px' }}>
                                        --
                                    </div>
                                    <div onClick={()=>window.open(item.bioLink?.link)} className='over1' style={{ width: '100%', height: '49px', border: '0.5px solid #EEEEEE', lineHeight: '49px',
                                        padding:'0 10px', cursor:'pointer' }}>
                                        {item.bioLink?.link ? item.bioLink?.link : '--'}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                {/* <div style={{width:'100%', height:'5px', boxShadow: '0px 4px 8px 0px rgba(12, 5, 8, 0.08)',
                    marginTop:'-5px'}}></div> */}
            </div>
            <div onClick={()=>props.clickFun()} style={{
                width: '210px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                textAlign: 'center', lineHeight: '40px', color: '#FFF', borderRadius: '4px', margin: '0 auto',
                cursor: 'pointer', marginTop: '26px'
            }}>{intl.get('Common')['关闭']}</div>
        </Modal>
    </div>
}
export default withRouter(PkModel);