import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import {CloseOutlined} from '@ant-design/icons';
import {Input, Select, Upload, message, Image} from 'antd';
import intl from 'react-intl-universal';
import {baseUrl} from '../../service/request'
import {deleteNiceWork} from "../../service/https";
import * as Api from "../../service/https";

const {Option} = Select;
const {TextArea} = Input;

const UploadVideo = (props) => {
    const canEditVideo = props.canEditVideo
    // const fileTypeList = ['video/mp4','video/ogg','video/avi', 'video/webm','video/quicktime','video/x-matroska', 'video/x-ms-wmv','video/mpeg','video/flv']
    const fileTypeList = ['video/mp4']
    const [fileId,setFileId] = useState([])
    const [textJson, setTextJson] = useState(intl.get('NewLogin'))
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh'

    const [uploadIng, setUploadIng] = useState(false)
    const [loading, setLoading] = useState(true)
    // 视频
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    const fileVideoLists = {
        maxCount: props.maxCount,
        showUploadList: {
            showPreviewIcon: false,
            showRemoveIcon: false,
            showDownloadIcon: false,
            removeIcon: false,
            downloadIcon: false,
        },
        progress: {
            strokeColor: {
                '0%': '#C0C0C0',
                '100%': '#F7385B',
            },
            strokeWidth: 3,
            // format: percent => percent && `${percent.toFixed(1)}%`,
        },
        beforeUpload(file) {
            console.log(file);
            // const isJpgOrPng = file.type === 'video/mp4' || file.type === 'video/mkv' || file.type === 'video/avi'
            //     || file.type === 'video/flv' || file.type === 'video/mpg';
            let correctType = fileTypeList.includes(file.type)
            if (!correctType) {
                message.error(intl.get('UploadVideo')['请上传正确的视频！']);
            }
            const isLt2M = file.size / 1024 / 1024 < 200;
            if (!isLt2M) {
                message.error([intl.get('UploadVideo')['视频过大，请上传200MB以下的视频！']]);
            }
            return isLt2M && correctType;
        },
        customRequest(options) {
            let {file, onSuccess, onError, onProgress} = options
            const formData = new FormData()
            formData.append('file', file)
            axios.post(baseUrl + '/redAccount/niceWorkUpload', formData, {
                headers: {
                    'x-token': localStorage.getItem('token'),
                },
                cancelToken: source.token,
                onUploadProgress: ({total, loaded}) => {
                    onProgress({percent: Math.round((loaded / total) * 100).toFixed(2)}, file);
                }
            }).then((res) => {
                file.status = 'done'
                //这里onSuccess的第一个参数是接口响应结果，会传到change事件中去
                onSuccess(res.data, file)
            }).catch((res) => {
                file.status = 'error'
                onError(res.data, file)
            })

        },
        onChange(e) {
            let {file, fileList} = e
            if (file.status === 'uploading') {
                if (loading) {
                    setUploadIng(true)
                    message.loading(`${intl.get('UploadVideo')['上传中...']}`, 0);
                }
                setLoading(false)
            } else if (file.status === 'done') {
                if (file.response.code == 200) {
                    message.destroy()
                    setLoading(true)
                    props.callbackParent(file.response.data)
                    setFileId([file.response.data.id])
                    message.success(`${file.name}${intl.get('UploadVideo')['上传成功！']}`);
                } else {
                    message.destroy()
                    message.error(`${intl.get('UploadVideo')['上传失败！']}`);
                }
            } else if (file.status === 'error') {
                message.destroy()
                setLoading(true)
                if (file.response.status == 'error') {
                    message.warning(`${intl.get('UploadVideo')['取消上传']}`);
                } else {
                    message.error(`${file.name}${intl.get('UploadVideo')['上传失败']}！`);
                }
            }
            if (file.status !== 'uploading') setUploadIng(false)
        }
    };

    const uploadClick = () => {
        if(fileId.length > 0) Api.deleteNiceWork({ids: fileId}).then()
        document.querySelector('.upload-btn').click()
    }

    return <div>
        <div className='flex-ac' bodystyle={{backgroundColor: '#FFFFFF', padding: '60px 50px 30px 50px'}}>
            {
                props.videoUrl ? <div style={{
                        width: '108px',
                        height: '150px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        position: 'relative'
                    }}>
                        <Image src={props.videoUrl + '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,m_fast'}
                               style={{width: '108px', height: '150px'}} preview={false}/>
                        {/*<video src={props.videoUrl}*/}
                        {/*       style={{width: '108px', height: '150px'}}></video>*/}
                        {/*<div onClick={() => props.callbackParent(null)} style={{*/}
                        {/*    width: '16px',*/}
                        {/*    height: '16px',*/}
                        {/*    borderRadius: '50%',*/}
                        {/*    fontSize: '8px',*/}
                        {/*    background: '#FFEEF1',*/}
                        {/*    boxShadow: '0px 0px 6px 0px #A9A9A9',*/}
                        {/*    color: '#F7385B',*/}
                        {/*    cursor: 'pointer',*/}
                        {/*    textAlign: 'center',*/}
                        {/*    lineHeight: '12px',*/}
                        {/*    position: 'absolute',*/}
                        {/*    top: '-6px',*/}
                        {/*    right: '-6px'*/}
                        {/*}}>*/}
                        {/*    <CloseOutlined/>*/}
                        {/*</div>*/}
                    </div> :
                    <div style={{
                        width: '108px',
                        height: '150px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        background: '#C0C0C0'
                    }}></div>
            }
            <div style={{marginLeft: '14px'}}>
                <Upload  {...fileVideoLists}>
                    <div className='upload-btn'></div>
                </Upload>
                {
                    canEditVideo ? (!uploadIng ?
                        <div className='btn_top'
                             style={{width: '103px', height: '30px', lineHeight: '30px', borderRadius: '5px',marginTop:'10px'}}
                             onClick={uploadClick}>
                            {props.videoUrl ? intl.get('UploadVideo')['重新上传'] : intl.get('UploadVideo')['上传视频']}
                        </div>
                        : <div style={{
                            cursor: 'pointer',
                            color: '#F7385B',
                            width: 103,
                            height: 30,
                            lineHeight: '30px',
                            textAlign: 'center',
                            border: '1px solid #F7385B',
                            borderRadius: '5px',
                            marginTop:'10px'
                        }} onClick={() => source.cancel()}>{ intl.get('UploadVideo')['取消上传']}
                        </div>
                    ) : ''
                }
            </div>
        </div>
    </div>
}
export default UploadVideo;