import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import intl from 'react-intl-universal';
import { useNavigate } from 'react-router-dom'
import { Input, Select, Form, Button, Upload, message, notification } from 'antd';
import { getUserInfo, setUpUser } from '../../../service/https'
import * as Api from '../../../service/https'
import { baseUrl } from '../../../service/request'
import '../../../asset/css/Account.scss'
import store from '../../../utils/redux/index'
const { Option } = Select;

const Account = (props) => {
    const navigate = useNavigate();
    const [textJson, setTextJson] = useState(intl.get('account'))
    const [lng, setLng] = useState(localStorage.getItem('locale'))
    const [params, setParams] = useState({})
    const [phone, setPhone] = useState('')
    const [userInfo, setUserInfo] = useState({})
    const [nicknameStatus, setNicknameStatus] = useState(false)
    const [nickname, setNickname] = useState()
    const intervalRef = useRef(null);

    useEffect(() => {
        getUserInfoFun()
        return () => intervalRef ? clearInterval(intervalRef?.current) : ''
    }, [])

    const getUserInfoFun = () => {
        getUserInfo().then(res => {
            if (res.code === 200) {
                setUserInfo(res.data)
                setPhone(res.data.phone)
                store.dispatch({ type: 'userInfo', value: res.data })
            }
        })
    }

    const [pwdStatus, setPwdStatus] = useState(false)
    const [codes, setCode] = useState('')
    const [isCode, getCodes] = useState(true)
    const [time, setTime] = useState(60)
    const [phoneType, changePhoneType] = useState('86')
    const [password, setPassword] = useState('')

    const prefixSelector = (
        <Select style={{ width: 100 }} value={phoneType}
            getPopupContainer={() => document.getElementById('login')}
            onChange={(e) => changePhoneType(e)}>
            <Option value="86">CN +86</Option>
            <Option value="87">+87</Option>
        </Select>
    );

    const openNotificationWithIcon = (type, message = '') => {
        notification[type]({
            description: message, top: 100, duration: 3
        });
    };

    const getCode = async () => {
        if (isCode && phone !== '') {
            getCodes(false)
            setTime(60)
            let times = 60
            Api.getCode(phone).then((res) => {
                // console.log(res.data)
                if (res.code === 200) {
                    openNotificationWithIcon('success', '发送成功！')
                    let downTime = setInterval(() => {
                        if (times === 0) {
                            getCodes(true)
                            clearInterval(intervalRef.current)
                        }
                        setTime(times--)
                    }, 1000)
                    intervalRef.current = downTime
                }
            })
        }
    }

    const uploadProps = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/avatar/upload',
        showUploadList: false,
        headers: {
            'x-token': localStorage.getItem('token')
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error('请上传正确的图片！');
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error('图片过大，请上传10MB以下的图片!');
            }
            return isJpgOrPng && isLt2M;
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                setTimeout(() => {
                    let t = JSON.parse(JSON.stringify(userInfo))
                    t.avatar = info.file.response.data
                    setUserInfo(t)
                    setUpUserFun(t)
                }, 1000);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const setUpUserFun = e => {
        delete e['password']
        setUpUser(e).then(res => {
            if (res.code === 200) {
                message.success("修改成功！")
                getUserInfoFun()
                setNicknameStatus(false)
                setNickname('');
            }
        })
    }

    const resetPassword = () => {
        let obj = {
            phone: phone,
            password: password, verifyCode: codes
        }
        Api.resetPassword(obj).then((res) => {
            // console.log(res.data)
            if (res.code === 200) {
                openNotificationWithIcon('success', '修改成功！')
                setPwdStatus(!pwdStatus)
                setPassword('')
            }
        })
    }

    return (
        <div id='Account'>
            <nav style={{ width: '100%', position: 'relative', fontWeight: 500 }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt=""
                    style={{ width: '100%' }} />
                <div style={{
                    width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt=""
                        style={{ width: '190px' }} />
                    <div style={{
                        width: '10px', height: '10px', backgroundColor: 'black', borderRadius: '5px',
                        margin: '0 20px'
                    }}></div>
                    <span style={{ fontSize: '20px' }}>{textJson["账号管理"]}</span>
                </div>
            </nav>
            <div style={{ backgroundColor: '#fff', marginTop: '10px', padding: '54px 77px' }}>
                <div className='lab'>
                    <div>{textJson["当前头像"]}</div>
                    <div>
                        <img src={userInfo.avatar} alt=""
                            style={{ width: '60px', height: '60px', borderRadius: '30px', marginRight: '16px' }} />
                    </div>
                    {
                        userInfo.userType === 1 ?
                            <Upload {...uploadProps}>
                                <div style={{ color: '#F7385B', cursor: 'pointer' }}>
                                    {textJson["上传头像"]}<span style={{ color: '#B3B3B3', fontSize: '12px' }}>{textJson["（头像的大小不超过200KB，格式为JPG或PNG）"]}</span>
                                </div>
                            </Upload>
                            :
                            ""
                    }
                </div>
                <div className='lab'>
                    <div>{textJson["我的昵称"]}</div>
                    {
                        nicknameStatus ?
                            <Input placeholder='请输入' onChange={e => { setNickname(e.target.value) }} style={{ width: 180, marginRight: 30 }} />
                            :
                            <div style={{ color: userInfo.nickname ? '#333' : '#ccc' }}>{userInfo.nickname ? userInfo.nickname : textJson["还未设置昵称"]}</div>
                    }
                    {
                        userInfo.userType === 1 ?
                            nicknameStatus ?
                                <div style={{ display: 'flex' }}>
                                    <div style={{ marginRight: 20 }} onClick={e => {
                                        let t = JSON.parse(JSON.stringify(userInfo))
                                        t.nickname = nickname
                                        if (t.nickname) {
                                            setUserInfo(t)
                                            setUpUserFun(t)
                                        } else {
                                            message.warn("请输入昵称！")
                                        }
                                    }}>{textJson["确认修改"]}</div>
                                    <div style={{ color: 'rgb(179, 179, 179)' }} onClick={e => { setNicknameStatus(!nicknameStatus); setNickname(''); }}>{textJson["取消"]}</div>
                                </div>
                                :
                                <div onClick={e => { setNicknameStatus(!nicknameStatus) }}>{textJson["编辑昵称"]}</div>
                            : ''
                    }
                </div>
                {
                    userInfo.userType === 2 ?
                        <div className='lab'>
                            <div>{textJson["TikTok"]}</div>
                            <div>{userInfo?.TikTok || '-'}</div>
                        </div>
                        :
                        ""
                }
                {
                    userInfo.userType === 2 ?
                        <div className='lab'>
                            <div>{textJson["姓名"]}</div>
                            <div>{userInfo?.name || '-'}</div>
                        </div>
                        :
                        ""
                }
                {
                    userInfo.userType === 1 ?
                        <div className='lab'>
                            <div>{textJson["会员状态"]}</div>
                            <div style={{ color: userInfo.memberDetail?.memberType ? '#333' : '#ccc' }}>{userInfo.memberDetail?.memberType ? userInfo.memberDetail?.memberType : textJson["还未开通会员"]}</div>
                            <div onClick={e => { navigate(`/index/member`) }}>{textJson["开通会员"]}</div>
                        </div>
                        :
                        ""
                }
                <div className='lab'>
                    <div>{userInfo.userType === 1 ? textJson["手机绑定"] : textJson["手机号"]}</div>
                    <div>{userInfo?.phone}</div>
                </div>
                {
                    userInfo.userType === 2 ?
                        <div className='lab'>
                            <div>{textJson["邮箱号"]}</div>
                            <div>{userInfo?.email || '-'}</div>
                        </div>
                        :
                        ""
                }
                {
                    userInfo.userType === 1 ?
                        <div className='lab'>
                            <div>{textJson["我的企业"]}</div>
                            <div style={{ color: userInfo.enterprise ? '#333' : '#ccc' }}>{userInfo.enterprise ? userInfo.enterprise : textJson["还未认证企业"]}</div>
                            <div onClick={e => { navigate(`/index/EnterpriseCertification`) }}>{textJson["认证企业"]}</div>
                        </div>
                        :
                        ""
                }
                {userInfo.userType === 1 ?
                    pwdStatus ?
                        <div style={{ display: 'flex' }}>
                            <div style={{ fontWeight: 'Bold', width: '125px' }}>{textJson["登陆密码"]}</div>
                            <div style={{ width: '450px' }}>
                                <div id='login'>
                                    <Form name="basic" autoComplete="off">
                                        <div>
                                            <Form.Item
                                                name="phone"
                                                label=""
                                                rules={[{
                                                    required: true,
                                                    validator: (_, value) => {
                                                        if (value.length == 11) {
                                                            return Promise.resolve()
                                                        } else {
                                                            return Promise.reject(intl.get('Login').PhoneV)
                                                        }
                                                    }
                                                }]}
                                            >
                                                <div id='phones'>
                                                    <Input onChange={e => setPhone(e.target.value)} value={userInfo?.phone} addonBefore={prefixSelector} placeholder={intl.get('Login').PhoneT} />
                                                </div>
                                            </Form.Item>
                                        </div>

                                        <div>
                                            <Form.Item label="" name="code" rules={[{
                                                required: true,
                                                validator: (_, value) => {
                                                    if (value.length == 6) {
                                                        return Promise.resolve()
                                                    } else {
                                                        return Promise.reject(intl.get('Login').VerificationCodeV)
                                                    }
                                                }
                                            }]} >
                                                <div>
                                                    <Input onChange={(e) => { setCode(e.target.value) }} placeholder={intl.get('Login').PhoneVerificationCodeT}
                                                        style={{ height: '45px', paddingRight: '130px' }} />
                                                    <section onClick={getCode} style={{
                                                        position: 'absolute', top: '1px', right: '1px', width: '130px', height: '45px',
                                                        textAlign: 'center', cursor: 'pointer', color: isCode ? '#333333' : '#F7385B',
                                                        lineHeight: '45px', fontSize: '14px', letterSpacing: '0.05px',
                                                    }}>{isCode ? intl.get('Login').SendVerificationCode : time + textJson["s后可重新发送"]}</section>
                                                </div>
                                            </Form.Item>
                                        </div>
                                        <div>
                                            <Form.Item label="" name="password" rules={[{
                                                required: true,
                                                validator: (_, value) => {
                                                    if (value.length >= 6 && value.length <= 16) {
                                                        return Promise.resolve()
                                                    } else {
                                                        return Promise.reject(intl.get('Login').PasswordV)
                                                    }
                                                }
                                            }]} >
                                                <div>
                                                    <Input.Password onChange={(e) => { setPassword(e.target.value) }} placeholder={intl.get('Login').PasswordT} style={{ height: '45px' }}
                                                        iconRender={visible => (visible ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/kejian.png'} alt=""
                                                            style={{ width: '18px', cursor: 'pointer' }} /> : <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/kejians.png'} alt=""
                                                                style={{ width: '18px', cursor: 'pointer' }} />)} />
                                                </div>
                                            </Form.Item>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <Button
                                                className='sub'
                                                style={{
                                                    width: '100%', height: '45px', borderRadius: '4px', border: 0, marginTop: '14px',
                                                    background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 500, letterSpacing: '2px',
                                                    color: '#FFF', marginRight: '16px'
                                                }}
                                                onClick={() => resetPassword()}
                                            >{textJson["确定修改"]}
                                            </Button>
                                            <Button
                                                className='sub'
                                                style={{
                                                    width: '100%', height: '45px', borderRadius: '4px', border: 0, marginTop: '14px',
                                                    background: '#EEEEEE', fontWeight: 500, letterSpacing: '2px',
                                                    color: '#888888'
                                                }}
                                                onClick={e => { setPwdStatus(!pwdStatus) }}
                                            >{textJson["取消修改"]}
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='lab'>
                            <div>{textJson["登陆密码"]}</div>
                            <div>********</div>
                            <div onClick={e => { setPwdStatus(!pwdStatus) }}>{textJson["修改密码"]}</div>
                        </div>
                    :
                    ""
                }
            </div>
        </div>
    )
}

export default withRouter(Account);