import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import intl from 'react-intl-universal';
import * as Api from '../../../service/https'
import {Modal, Input, List, notification, Button, Image, Divider, Spin, message, Popover} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import store from '../../../utils/redux/index'

const ShopWallet = (props) => {
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [textJson, setTextJson] = useState(intl.get('Wallet'))
    useEffect(() => {
        getSettlementAmount()
        getAllAmount()
        getWithdrawalAmount()
        loadMoreData()
    }, [])
    const [settlementAmount, setSettlementAmount] = useState({})
    const getSettlementAmount = ()=>{
        Api.getSettlementAmount().then(res=>{
            if(res.code == 200){
                setSettlementAmount(res.data)
            }
        })
    }
    const [amount, setAmount] = useState({})
    const getAllAmount = ()=>{
        Api.getAllAmount().then(res=>{
            if(res.code == 200){
                setAmount(res.data)
            }
        })
    }
    const [withdrawalAmount, setWithdrawalAmount] = useState({})
    const getWithdrawalAmount = ()=>{
        Api.getWithdrawalAmount().then(res=>{
            if(res.code == 200){
                setWithdrawalAmount(res.data)
            }
        })
    }


    const orderStatus = {
        1:'未完成', 2:'待发货', 3:'已发货', 4:'已完成', 5:'已取消'
    }
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [orderList, setOrderList] = useState([])
    const [orderAllList, setOrderAllList] = useState([])
    const [orderListItem, setOrderListItem] = useState()
    const [pageIndex, setPageIndex] = useState(1);
    const [active_index, active_indexSet] = useState(false)
    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        let obj = {
            current: pageIndex, size: 10,
        }
        Api.getOrderList(obj).then(res => {
            // console.log(res)
            setLoading(false);
            if (res.code == 200) {
                setTotal(res.data.total)
                setOrderAllList(pageIndex == 1 ? res.data.records : [...orderList, ...res.data.records]);
                setPageIndex(pageIndex + 1)
                setOrderList(res.data.records.filter(item=> item.settlementStatus == active_index))
            }
        })
    };


    const tabsChange = e => {
        active_indexSet(e)
    }
    useEffect(()=>{
        setOrderList(orderAllList.filter(item=> item.settlementStatus == active_index))
    },[active_index])

    return <div id='wallet' style={{ paddingBottom: '43px' }}>
        <div style={{ display: 'flex', alignItems:'center' }}>
            <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/before.png" alt=""
            style={{width:'4px'}}/>
            <div style={{fontSize:'20px', fontWeight:600, marginLeft:'9px'}}>账单</div>
        </div>
        <div style={{
            width: '100%', height: '180px', backgroundColor: '#FFF', textAlign: 'center', marginTop:'10px',
            display: 'flex', justifyContent: 'space-evenly', padding: '25px 30px'
        }}>
            <div>
                <Popover placement="right" title={''} content={<div>
                    1) 订单状态不是已完成<br/>
                    2) 您的收款银行账号存在问题<br/>
                    3) 您的收入未达到最小结算金额$1.00
                </div>}>
                    <div><span style={{ color: '#666666' }}>待结算金额</span> <QuestionCircleOutlined /></div>
                </Popover>
                <div style={{ fontWeight: 500, fontSize: '22px' }}>${settlementAmount.noSettlement}</div>
            </div>
            <div style={{ width: '1px', height: '130px', background: '#EEEEEE' }}></div>
            <div>
                <Popover placement="right" title={''} content={<div>
                    1) 已经成功结算给您的金额<br/>
                    2) 已结算的金额会自动放入可提现金额中，商家可以按照提现规则提出<br/>
                    3) 系统默认再订单完成后的7个工作日后自动结算<br/>
                    4) 结算金额是按照产品的结算价格进行结算
                </div>}>
                    <div><span style={{ color: '#666666' }}>已结算金额</span> <QuestionCircleOutlined /></div>
                </Popover>
                <div style={{ fontWeight: 500, fontSize: '22px' }}>${settlementAmount.settlement}</div>
                上一个结算周期
            </div>
            <div style={{paddingTop:'22px'}}>
                <div style={{ fontWeight: 500, fontSize: '22px' }}>${amount.monthAmount}</div>
                本月
            </div>
            <div style={{paddingTop:'22px'}}>
                <div style={{ fontWeight: 500, fontSize: '22px' }}>${amount.totalAmount}</div>
                总计
            </div>
            <div style={{ width: '1px', height: '130px', background: '#EEEEEE' }}></div>
            <div>
                <span style={{ color: '#666666' }}>可提现金额</span>
                <div style={{ fontWeight: 500, fontSize: '22px' }}>${withdrawalAmount.withdrawableAmount}</div>
                <div style={{
                    width: '160px', height: '38px', borderRadius: '4px', cursor: 'pointer',
                    textAlign: 'center', lineHeight: '38px', color: '#FFF', margin: '16px auto',
                    background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod'
                }}
                    onClick={() => {props.navigate('/index/withdrawal');store.dispatch({ type: 'changeRouter', value: `/withdrawal&&/shopWallets` })}}>
                    {textJson['去提现']}
                </div>
            </div>
            <div>
                <span style={{ color: '#666666' }}>{textJson['已提现金额']}</span>
                <div style={{ fontWeight: 500, fontSize: '22px' }}>${withdrawalAmount.withdrawalAmount}</div>
                <div style={{
                    width: '160px', height: '38px', borderRadius: '4px',
                    textAlign: 'center', lineHeight: '34px', color: '#666', margin: '16px auto',
                    // border: '1px solid #EEEEEE', fontWeight: 'blod', cursor: 'pointer'
                }}
                    // onClick={() => getTixian()}
                    >
                    {/* {textJson['查看提现记录']} */}
                </div>
            </div>
        </div>
        <div id='MyGoods' style={{marginTop:'10px'}}>
            <div className='tabs' style={{background:'#FFF', height:'60px', padding:'15px 30px'}}>
                <div className={!active_index ? 'active' : ''} onClick={e => tabsChange(false)}>待结算</div>
                <div className={active_index ? 'active' : ''} onClick={e => tabsChange(true)}>已结算</div>
            </div>

            <div style={{ width: '100%', background: '#FFF',}}>
                <div className='goods_main' style={{marginBottom:'20px'}}>
                    <div style={{padding:0}}>
                    </div>
                    <div style={{fontWeight:500, fontSize:'13px', color:'#666'}}>
                        <div style={{ width: '160px' }}>订单ID</div>
                        <div style={{ width: '170px', marginLeft: '15px' }}>商品名称</div>
                        <div style={{ width: '100px', marginLeft: '25px'}}>SKU</div>
                        <div style={{ width: '80px' }}>数量</div>
                        <div style={{ width: '120px' }}>预计结算金额</div>
                        <div style={{ width: '180px' }}>订单支付时间</div>
                        <div style={{ width: '90px' }}>订单状态</div>
                        <div style={{ width: '90px' }}>结算状态</div>
                        <div style={{ width: '80px' }}>操作</div>
                    </div>
                </div>
                {/* <div style={{fontWeight:500,background:'#FFF', borderRadius:'5px',
                width:'100%', maxHeight:'100px', padding:'20px 24px', display:'flex', alignItems:'center'}}>
                    <div style={{ width: '160px'}}>ID: 1729837749203</div>
                    <div style={{ width: '170px', marginLeft: '15px'}}><div className='over' style={{fontWeight:600, lineHeight:'15px'}}>蔬菜切碎机10合1曼陀林切片机厨房多功能洋葱食片机厨房多功能洋葱食厨房多功能洋</div></div>
                    <div style={{ width: '100px', marginLeft: '25px'}}>黑色</div>
                    <div style={{ width: '80px' }}>1</div>
                    <div style={{ width: '120px' }}>$3.9</div>
                    <div style={{ width: '150px' }}>2022-06-27 18:06</div>
                    <div style={{ width: '90px' }}><span style={{background:'#DBFFEB', fontSize:'12px', borderRadius:'6px',
                    color:'#40D782', padding:'1px 6px'}}>未完成</span></div>
                    <div style={{ width: '90px' }}><span style={{background:'#DBFFEB', fontSize:'12px', borderRadius:'6px',
                    color:'#40D782', padding:'1px 6px'}}>待结算</span></div>
                    <div style={{ width: '80px',color:'#F7385B' }}><div style={{cursor:'pointer'}}>查看详情</div></div>
                </div> */}
                {orderList?.length > 0 ? <Spin tip="数据加载中..." spinning={loading}>
                    <InfiniteScroll
                        dataLength={orderList?.length} scrollThreshold={'20px'}
                        next={loadMoreData}
                        hasMore={orderList?.length < total}
                        // loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                        endMessage={<Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider>}
                        // scrollableTarget="wallet"
                    >
                        <List
                            dataSource={orderList} grid={{ gutter: 0, column: 1 }}
                            renderItem={(item, index) => <div key={index} style={{fontWeight:500,background:'#FFF', borderRadius:'5px',
                            width:'100%', maxHeight:'100px', padding:'20px 24px', display:'flex', alignItems:'center'}}>
                                <div style={{ width: '160px'}}>ID: {item.tkOrderId}</div>
                                <div style={{ width: '170px', marginLeft: '15px'}}><div className='over' style={{fontWeight:600, lineHeight:'15px'}}>{item.productName}</div></div>
                                <div style={{ width: '100px', marginLeft: '25px'}}>{item.tkSkuSubtotalAfterDiscount}</div>
                                <div style={{ width: '80px' }}>{item.quantity}</div>
                                <div style={{ width: '120px' }}>${item.subtotal}</div>
                                <div style={{ width: '180px' }}>{item.tkPaidTime}</div>
                                <div style={{ width: '90px' }}><span style={{background:'#DBFFEB', fontSize:'12px', borderRadius:'6px',
                                color:'#40D782', padding:'1px 6px'}}>{orderStatus[item.orderStatus]}</span></div>
                                <div style={{ width: '90px' }}><span style={{background:'#DBFFEB', fontSize:'12px', borderRadius:'6px',
                                color:'#40D782', padding:'1px 6px'}}>{item.settlementStatus==0?'待结算':'已结算'}</span></div>
                                <div style={{ width: '80px',color:'#F7385B' }}><div style={{cursor:'pointer'}}
                                onClick={()=>setOrderListItem(item)}>查看详情</div></div>
                            </div>}
                        />
                    </InfiniteScroll>
                </Spin> :
                <div style={{width:'345px', height:'200px', textAlign:'center', margin:'0 auto', marginTop:'100px' }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                    <div style={{marginTop:'24px', textAlign: 'center', color:'#333' }}>{'暂无数据'}</div>
                </div>}
            </div>
        </div>



        <Modal centered visible={orderListItem} footer={null} closable={false}
            getContainer={() => document.getElementById('wallet')}
            // bodyStyle={{
            //     padding: '0', backgroundColor: '#FFFFFF',
            //     width: 443, height: 466,
            // }}
        >
            <div id='MyGoods'>
                <div className='goods_main' style={{marginBottom:'20px'}}>
                    <div style={{padding:0}}>
                    </div>
                    <div style={{fontWeight:500, fontSize:'13px', color:'#666'}}>
                        <div style={{ width: '160px' }}>订单ID</div>
                        <div style={{ width: '170px', marginLeft: '15px' }}>商品名称</div>
                        <div style={{ width: '100px', marginLeft: '25px'}}>SKU</div>
                        <div style={{ width: '80px' }}>数量</div>
                        <div style={{ width: '120px' }}>预计结算金额</div>
                        <div style={{ width: '180px' }}>订单支付时间</div>
                        <div style={{ width: '90px' }}>订单状态</div>
                        <div style={{ width: '90px' }}>结算状态</div>
                    </div>
                </div>
            </div>
            {orderListItem?<div style={{fontWeight:500,background:'#FFF', borderRadius:'5px',
            width:'100%', maxHeight:'100px', padding:'20px 24px', display:'flex', alignItems:'center'}}>
                <div style={{ width: '160px'}}>ID: {orderListItem.tkOrderId}</div>
                <div style={{ width: '170px', marginLeft: '15px'}}><div className='over' style={{fontWeight:600, lineHeight:'15px'}}>{orderListItem.productName}</div></div>
                <div style={{ width: '100px', marginLeft: '25px'}}>{orderListItem.tkSkuSubtotalAfterDiscount}</div>
                <div style={{ width: '80px' }}>{orderListItem.quantity}</div>
                <div style={{ width: '120px' }}>${orderListItem.subtotal}</div>
                <div style={{ width: '180px' }}>{orderListItem.tkPaidTime}</div>
                <div style={{ width: '90px' }}><span style={{background:'#DBFFEB', fontSize:'12px', borderRadius:'6px',
                color:'#40D782', padding:'1px 6px'}}>{orderStatus[orderListItem.orderStatus]}</span></div>
                <div style={{ width: '90px' }}><span style={{background:'#DBFFEB', fontSize:'12px', borderRadius:'6px',
                color:'#40D782', padding:'1px 6px'}}>{orderListItem.settlementStatus==0?'待结算':'已结算'}</span></div>
            </div>:''}
            <CloseCircleOutlined style={{
                    position: 'absolute', right: '29px', top: '29px', fontSize: '20px', color: '#888888',
                    cursor: 'pointer'
                }}
                    onClick={() => setOrderListItem(null)} />
        </Modal>


    </div>
}
export default withRouter(ShopWallet);
