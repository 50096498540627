import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import intl from 'react-intl-universal';
import { useNavigate } from 'react-router-dom'
import { Input, Button, Upload, message, notification, Image, Badge, Form, Skeleton, Divider, List, Modal, Progress } from 'antd';
import { DownOutlined, UpOutlined, RightOutlined } from '@ant-design/icons';
import { getUserInfo, setUpUser } from '../../../service/https'
import * as Api from '../../../service/https'
import { baseUrl } from '../../../service/request'
import '../../../asset/css/Account.scss'
import store from '../../../utils/redux/index'
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { CloseCircleOutlined } from '@ant-design/icons';

const EnterpriseAccount = (props) => {
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        console.log(state);
        setSate(store.getState())
    };
    store.subscribe(storeChange);
    const [textJson, setTextJson] = useState(intl.get('EnterpriseAccount'))
    const [textJsonI, setTextJsonI] = useState(intl.get('InfluencerAccount'))
    const [userInfo, setUserInfo] = useState({})
    const [tabIndex, setTabIndex] = useState(0)
    const [amount, setAmount] = useState(0)
    const [nicknameStatus, setNicknameStatus] = useState(false)
    const [data, setData] = useState([]);
    const [videoList, setVideoList] = useState([]);
    const [logsList, setLogsList] = useState([]);
    const [payList, setPayList] = useState([]);
    const intervalRef = useRef(null);
    const [logsVisible, setLogsVisible] = useState(false)
    const [videoVisible, setVideoVisible] = useState(false)
    const [inter, setInter] = useState({})

    const tabList = [
        { icon: 'shezhi', title: textJson['信息设置'] },
        { icon: 'baogao_EnterpriseAccount', title: textJson['会员权限用量'] },
        { icon: 'pinpai_EnterpriseAccount', title: textJson['合作付款记录'] },
        { icon: 'xiaoxitongzhi', title: textJson['消息通知'] },
    ]

    const tab3List = [
        { icon: 'jifen', title: textJson['系统消息通知'] },
        { icon: 'baogao', title: textJson['我的合作申请'] },
        { icon: 'baogao', title: textJson['红人合作申请'] },
    ]

    const openNotificationWithIcon = (type, message = '') => {
        notification[type]({
            description: message, top: 100, duration: 3
        });
    };

    useEffect(() => {
        getUserInfoFun()
        getNewsTotal()
        if (tabIndex === 1) {
            searchinterests()
        }
        if (tabIndex === 2) {
            paymentRecord()
        }
        if (tabIndex === 3) {
            getNew1sList()
            getNews2List()
            getNews3List()
        }
        return () => intervalRef ? clearInterval(intervalRef?.current) : ''
    }, [tabIndex])

    useEffect(() => {
        if (logsVisible)
            getMembershipLogs()
    }, [logsVisible])

    const getUserInfoFun = () => {
        getUserInfo().then(res => {
            if (res.code === 200) {
                setUserInfo(res.data)
                store.dispatch({ type: 'userInfo', value: res.data })
            }
        })
    }

    const searchinterests = e => {
        Api.searchinterests().then(res => {
            if (res.code === 200) {
                setInter(res.data)
            }
        })
    }

    const paymentRecord = e => {
        Api.paymentRecord().then(res => {
            if (res.code === 200) {
                setPayList(res.data.recordList)
                setAmount(res.data.amountDollar)
            }
        })
    }

    const getMembershipLogs = () => {
        let data = {}
        Api.getMembershipLogs(data).then(res => {
            if (res.code === 200) {
                setLogsList(res.data)
            }
        })
    }

    const logouts = () => {
        Api.logout().then(res => {
            if (res.code == 200) {
                localStorage.setItem("token", null)
                store.dispatch({ type: 'token', value: null })
                store.dispatch({ type: 'userInfo', value: null })
                props.navigate('/login')
                window.location.reload()
            }
        })
    }

    const [tab3Index, setTab3Index] = useState(0)

    const uploadProps = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/avatar/upload',
        showUploadList: false,
        headers: {
            'x-token': localStorage.getItem('token')
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error('请上传正确的图片！');
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error('图片过大，请上传10MB以下的图片!');
            }
            return isJpgOrPng && isLt2M;
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                setTimeout(() => {
                    let t = JSON.parse(JSON.stringify(userInfo))
                    t.avatar = info.file.response.data
                    setUpUserFun(t)
                }, 1000);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const [newNickname, setNewNickname] = useState('')

    const setNickname = e => {
        if (e) {
            setNicknameStatus(true);
            setNewNickname('')
        } else {
            if (newNickname) {
                let t = JSON.parse(JSON.stringify(userInfo))
                t.nickname = newNickname
                setUpUserFun(t).then(e => setNicknameStatus(false))
            } else {
                message.warn(textJson['请输入新昵称！'])
            }
        }
    }

    const setUpUserFun = e => {
        return new Promise((resolve, reject) => {
            delete e['password']
            setUpUser(e).then(res => {
                if (res.code === 200) {
                    message.success("修改成功！")
                    getUserInfoFun()
                    resolve(true)
                } else {
                    reject(false)
                }
            })
        })
    }

    const [pwdStatus, setPwdStatus] = useState(false)
    const [codes, setCode] = useState('')
    const [isCode, getCodes] = useState(true)
    const [time, setTime] = useState(60)
    const [password, setPassword] = useState('')

    const getCode = async () => {
        if (isCode && userInfo.phone !== '') {
            getCodes(false)
            setTime(60)
            let times = 60
            Api.getCode(userInfo.phone).then((res) => {
                // console.log(res.data)
                if (res.code === 200) {
                    openNotificationWithIcon('success', '发送成功！')
                    let downTime = setInterval(() => {
                        if (times === 0) {
                            getCodes(true)
                            clearInterval(intervalRef.current)
                        }
                        setTime(times--)
                    }, 1000)
                    intervalRef.current = downTime
                }
            })
        }
    }

    const resetPassword = () => {
        let obj = {
            phone: userInfo.phone,
            password: password,
            verifyCode: codes
        }
        Api.resetPassword(obj).then((res) => {
            if (res.code === 200) {
                openNotificationWithIcon('success', '修改成功！')
                setPwdStatus(!pwdStatus)
                setPassword('')
                getCodes(true)
                clearInterval(intervalRef.current)
            }
        })
    }

    const [loading, setLoading] = useState(false);

    const loadMoreData = (e) => {
        if (loading) {
            return;
        }
        setLoading(true);
        setLoading(false);
    };

    const [purposeList,setPurposeList] = useState([
        {val: '商品销售', id:4,icon:''},
        {val: '品牌传播', id:2, icon:''},
        {val: '商品种草', id:3, icon:''},
        {val: '商品好评', id:5,icon:''},
        {val: '开箱视频', id:6,icon:''},
    ])

    const [mediaList,setMediaList] = useState([
        {val: 'TikTok', id:1,icon:''},
        {val: 'Youtube', id:2, icon:''},
        {val: 'Facebook', id:3, icon:''},
        {val: 'Instagram', id:4,icon:''},
    ])

    // 别表
    const SmallDiv = (item, index) => {
        return <div key={index} style={{ width: '100%', height: '110px', display: 'flex', alignItems: 'center', backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F6F6F6', paddingLeft: '33px' }}>
            <div style={{ width: '25%', paddingLeft: '15px' }}>{item.listName}</div>
            <div style={{ width: '10%', paddingLeft: '15px' }}>{purposeList.find(vm=>vm.id == item.target) ? purposeList.find(vm=>vm.id == item.target).val : '--'}</div>
            <div style={{ width: '10%', paddingRight: '15px' }}>{item.cooperationWay === 1 ? intl.get("ReleaseDemand")['短视频'] : intl.get("ReleaseDemand")['直播']}</div>
            <div style={{ width: '10%', paddingRight: '15px' }}>{mediaList.find(vm=>vm.id == item.media) ? mediaList.find(vm=>vm.id == item.media).val : '--'}</div>
            <div style={{ width: '35%', paddingRight: '15px', height: '100%', display: 'flex', alignItems: 'center' }}>
                <Image src={item.avatarMedium} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                    style={{ width: '60px', height: '60px', borderRadius: '30px', marginRight: '16px' }} preview={false} />
                <div style={{ width: 'calc(100% - 76px)', color: '#888888' }}>
                    <div style={{
                        color: '#333333', fontWeight: 'bold', fontSize: '16px',
                        display: 'flex', alignItems: 'center', marginTop: '-8px'
                    }}>
                        <div className='over' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item.nickname}</div>
                    </div>
                    <div style={{ fontSize: '12px', lineHeight: '15px', position: 'relative', top: '-2px' }}>TikTok: {item.tiktok}</div>
                    <div style={{ fontSize: '12px', color: '#F7385B', display: 'flex', marginTop: '3px' }}>
                        {
                            item.cargoClassificationInterpretation ? item.cargoClassificationInterpretation.split(',')?.map((val, index) => {
                                return <span key={index} style={{ background: '#FFE7E9', padding: '1px 10px', borderRadius: '15px', marginRight: '6px' }}>{val}</span>
                            }) : ''
                        }
                    </div>
                </div>
            </div>
            <div style={{ width: '10%', paddingRight: '15px' }}>{moment(item.payFinishTime).format('YYYY-MM-DD hh:mm:ss')}</div>
            <div style={{ width: '11%', paddingRight: '15px' }}>${item.payPriceDollar}(￥{item.payPrice})</div>
        </div>
    }

    // 别表
    const SmallDiv2 = (item, index) => {
        return <div key={index} style={{ width: '100%', height: '90px', display: 'flex', alignItems: 'center', backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F6F6F6', paddingLeft: '33px' }}>
            <div style={{ width: '25%', paddingLeft: '15px' }}>{item.membershipType}</div>
            <div style={{ width: '30%', paddingLeft: '15px' }}>{moment(item.membershipStartTime).format('YYYY-MM-DD')}</div>
            <div style={{ width: '20%', paddingRight: '15px' }}>{item.openDuration}</div>
            <div style={{ width: '25%', paddingRight: '15px', color: '#F7385B', fontWeight: '800' }}>-{item.membershipYearPrice}</div>
        </div>
    }

    const showVideo = e => {
        searchVideos(e)
    }

    const [videoType, setVideoType] = useState()

    const searchVideos = e => {
        let data = {
            type: e,
            interests_id: inter.interests_id
        }
        if (!inter.interests_id) return
        Api.searchVideos(data).then(res => {
            if (res.code === 200) {
                let t = res.data
                setVideoType(e)
                setVideoList(t)
                setVideoVisible(true)
            }
        })
    }

    const [url, setUrl] = useState()
    const [videoVisible2, setVideoVisible2] = useState(false)

    const playVideo = e => {
        if (e) {
            setVideoVisible2(true)
            setUrl(e)
        }
    }

    const download = (e) => {
        window.open(e)
    }

    const [unreadCount, setUnreadCount] = useState(0)
    const getNewsTotal = () => {
        Api.getNewsTotal().then(res => {
            if (res.code == 200) {
                setUnreadCount(res.data.unreadCount)
            }
        })
    }

    const [news1List, setNews1List] = useState([])
    const [news2List, setNews2List] = useState([])
    const [news3List, setNews3List] = useState([])

    const changeChecked = (bools, index, type) => {
        let v = JSON.parse(JSON.stringify(type == 1 ? news1List : type == 2 ? news2List : news3List))
        if (tab3Index == 0) v.informationList[index].isChecked = bools
        if (v.informationList[index].extraInfo) {
            let obj = {
                target: v.informationList[index].extraInfo.targetCode,
                media: v.informationList[index].extraInfo.mediaCode,
                cooperationWay: v.informationList[index].extraInfo.cooperationWayCode,
                listId: v.informationList[index].extraInfo.listId,
                demandId: v.informationList[index].extraInfo.demandId,
            }
            props.navigate('/index/videoDemandDetail', { state: obj })
        }
        if (v.informationList[index].readStatus === 2) {
            let data = {
                id: v.informationList[index].id,
                informationType: v.informationList[index].informationType,
                readStatus: 1
            }
            Api.updateStatus(data).then(res => {
                if (res.code == 200) {
                    v.informationList[index].readStatus = 1
                    v.unreadCount -= 1
                    if (type == 1) setNews1List(v)
                    if (type == 2) setNews2List(v)
                    if (type == 3) setNews3List(v)
                }
            })
        } else {
            if (type == 1) setNews1List(v)
            if (type == 2) setNews2List(v)
            if (type == 3) setNews3List(v)
        }
    }

    const getNews3List = () => {
        Api.getNewsList({ type: 3 }).then(res => {
            if (res.code == 200) {
                res.data.informationList?.map(item => {
                    item.isChecked = false
                    item.extraInfo = JSON.parse(item.extraInfo)
                })
                setNews3List(res.data)
            }
        })
    }

    const getNews2List = () => {
        Api.getNewsList({ type: 2 }).then(res => {
            if (res.code == 200) {
                res.data.informationList?.map(item => {
                    item.isChecked = false
                    item.extraInfo = JSON.parse(item.extraInfo)
                })
                setNews2List(res.data)
            }
        })
    }

    const getNew1sList = () => {
        Api.getNewsList({ type: 1 }).then(res => {
            if (res.code == 200) {
                res.data.informationList?.map(item => {
                    item.isChecked = false
                    item.extraInfo = JSON.parse(item.extraInfo)
                })
                setNews1List(res.data)
            }
        })
    }

    return (
        <div id='EnterpriseAccount'>
            <nav style={{ width: '100%', height: '180px', position: 'relative', fontWeight: 500, marginTop: 40 }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseAccount/EnterpriseAccount_bg.png'} alt=""
                    style={{ width: '100%', height: '100%' }} />
                <div style={{
                    width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                    display: 'flex', justifyContent: 'space-between', padding: '0 40px 0 80px',
                }}>
                    <div style={{ position: 'absolute', top: '-30px' }}>
                        <div style={{ width: 146, height: 146, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#fff', borderRadius: '4px' }}>
                            <Image src={userInfo.avatar || ''} width={130} height={130} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} preview={false} alt='' />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', marginTop: 5 }}>
                            <div>
                                <div style={{ fontWeight: '800' }}>{userInfo.videoNumber}</div>
                                <div style={{ fontWeight: '400', color: '#888' }}>{textJson["视频需求"]}</div>
                            </div>
                            <div>
                                <div style={{ fontWeight: '800' }}>{userInfo.liveNumber}</div>
                                <div style={{ fontWeight: '400', color: '#888' }}>{textJson["直播需求"]}</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '171px' }}>
                        <div style={{ display: 'inline-block', background: 'linear-gradient(180deg, #F7385B, #FF716E)', color: '#fff', borderRadius: '5px', padding: '2px 8px', fontSize: '12px' }}>{textJson["主账号"]}</div>
                        <div style={{ fontSize: '24px', fontWeight: '800', marginTop: '5px' }}>{userInfo.phone}</div>
                        <div style={{ fontSize: '15px', color: '#666666', marginTop: '5px' }}>{userInfo.nickname ? userInfo.nickname : textJson["你还未设置昵称"]}</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginLeft: '10%' }}>
                        {
                            userInfo.memberDetail ?
                                <div style={{ display: 'flex', marginTop: '48px', flex: 1 }}>
                                    <Image src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseAccount/vip.png?123'} preview={false} width={79} alt="" />
                                    <div style={{ marginLeft: '20px', flex: 1, height: '73px', paddingTop: '15px' }}>
                                        <div style={{ fontWeight: 800 }}>{userInfo.memberDetail.memberType}</div>
                                        <div style={{ color: '#888' }}>{textJson['会员有效期至']}{moment(userInfo.memberDetail.date).format('YYYY-MM-DD')}</div>
                                    </div>
                                </div>
                                :
                                <div style={{ display: 'flex', marginTop: '48px', flex: 1 }}>
                                    <Image src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseAccount/notVip.png'} preview={false} width={79} alt="" />
                                    <div style={{ marginLeft: '20px', flex: 1 }}>
                                        <div style={{ fontSize: '15px', fontWeight: '600', marginTop: '15px' }}>{textJson["开通会员"]}</div>
                                        <div style={{ color: '#888888', fontSize: '12px' }}>{textJson["升级会员，体验更好的服务"]}</div>
                                        <div style={{ display: 'inline-flex', color: '#F7385B', fontSize: '12px', background: '#FFE7E9', border: '1px #F7385B solid', borderRadius: '20px', marginTop: '5px', padding: '0px 8px', cursor: 'pointer' }} onClick={e => props.navigate('/index/member')}>{textJson["去开通"]}</div>
                                    </div>
                                </div>
                        }
                        {
                            userInfo.enterprise ?
                                <div style={{ display: 'flex', marginTop: '48px', flex: 1 }}>
                                    <Image src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseAccount/enterprise.png'} preview={false} width={74} alt="" />
                                    <div style={{ marginLeft: '20px', flex: 1, height: '73px', paddingTop: '15px' }}>
                                        <div style={{ fontSize: '15px', fontWeight: '600' }}>{textJson["企业认证"]}</div>
                                        <div style={{ color: '#888', cursor: 'pointer' }} onClick={e => props.navigate('/index/EnterpriseCertification')}>{textJson["已认证，点击可查看"]}</div>

                                    </div>
                                </div>
                                :
                                <div style={{ display: 'flex', marginTop: '48px', flex: 1 }}>
                                    <Image src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseAccount/notEnterprise.png'} preview={false} width={74} alt="" />
                                    <div style={{ marginLeft: '20px', flex: 1 }}>
                                        <div style={{ fontSize: '15px', fontWeight: '600', marginTop: '15px' }}>{textJson["企业认证"]}</div>
                                        <div style={{ color: '#888888', fontSize: '12px' }}>{textJson["认证企业信息，随时随地发需求"]}</div>
                                        <div style={{ display: 'inline-flex', color: '#F7385B', fontSize: '12px', background: '#FFE7E9', border: '1px #F7385B solid', borderRadius: '20px', padding: '0px 8px', marginTop: '5px', cursor: 'pointer' }} onClick={e => props.navigate('/index/EnterpriseCertification')}>{textJson["去认证"]}</div>
                                    </div>
                                </div>
                        }
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '4px', cursor: 'pointer', color: '#666', background: '#F6F6F6', padding: '8px 25px', marginTop: '32px', height: 'fit-content' }}
                            onClick={() => logouts()}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/tuichudenglu.png'} style={{ width: '16px', marginRight: '8px' }} alt="" />
                            {intl.get('ReleaseDemand')['退出账号']}
                        </div>
                    </div>
                </div>
            </nav>

            <div style={{ width: '100%', height: '60px', background: '#FFF', display: 'flex', alignItems: 'center', letterSpacing: '1.2px', marginTop: '14px', position:'relative' }}>
                {
                    tabList?.map((item, index) => {
                        return <div onClick={() => setTabIndex(index)} key={index} style={{
                            height: '100%', display: 'flex', alignItems: 'center',
                            borderBottom: tabIndex == index ? '2px solid #F7385B' : '2px solid #FFF', cursor: 'pointer',
                            marginLeft: '90px', color: tabIndex == index ? '#F7385B' : '#333'
                        }}>
                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/' + item.icon + (index == tabIndex ? 's' : '') + '.png'}
                                style={{ width: index == 2 ? '18px' : '20px', marginRight: '5px' }} />
                            {index == 3 ? <Badge count={state.news?.unreadCount >= 0 ? state.news?.unreadCount : unreadCount} offset={[unreadCount > 9 ? 16 : 8, 0]} size="small">
                                <span style={{ fontSize: '15px', fontWeight: 'bold', color: tabIndex == index ? '#F7385B' : '#333' }}>{item.title}</span>
                            </Badge> : <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{item.title}</span>}
                        </div>
                    })
                }
            </div>

            <div id='EnterpriseAccount' style={{ backgroundColor: '#fff', marginTop: '10px', display: 'flex', marginBottom: '50px', paddingBottom: tabIndex == 3 || tabIndex === 2 ? '0' : '50px' }}>
                {
                    tabIndex == 3 ?
                        <div style={{ width: '224px', minWidth: '224px', background: '#F0F0F0', letterSpacing: '1.2px' }}>
                            {
                                tab3List?.map((item, index) => {
                                    return <div onClick={() => setTab3Index(index)} key={index} style={{
                                        width: '100%', height: '110px',
                                        background: tab3Index == index ? '#FFF' : '', cursor: 'pointer', lineHeight: '18px',
                                        color: tab3Index == index ? '#F7385B' : '#333', textAlign: 'center', paddingTop: '35px'
                                    }}>
                                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{item.title}</div>
                                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>（{index == 0 ? news1List.unreadCount : index == 1 ? news2List.unreadCount : news3List.unreadCount}{textJson["条未读"]}）</div>
                                    </div>
                                })
                            }
                        </div>
                        : ''
                }
                {
                    // 信息设置
                    tabIndex == 0 ?
                        <div>
                            <div id='login' className='InfluencerAccountInfo' style={{ width: '860px', paddingLeft: '50px', paddingTop: '50px', position: 'relative' }}>
                                <div style={{ paddingLeft: '40px', margin: '0 auto' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '135px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px' }}>{textJson['头像']}</div>
                                        <Image width={108} height={108} style={{ borderRadius: '3px' }} src={userInfo.avatar || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} alt="" />
                                    </div>
                                </div>
                                <div style={{ marginLeft: '175px', width: '108px', display: 'flex', justifyContent: 'center' }}>
                                    <Upload {...uploadProps} >
                                        <div style={{ border: '1px solid #F7385B', borderRadius: '3px', padding: '4px 20px', textAlign: 'center', color: '#F7385B', marginTop: '17px', cursor: 'pointer' }}>{textJson['修改头像']}</div>
                                    </Upload>
                                </div>
                                <div style={{ paddingLeft: '40px', margin: '0 auto', marginTop: '20px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '135px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px' }}>{['手机号']}</div>
                                        <div style={{ border: '1px solid #d9d9d9', borderRadius: '4px', width: '430px', lineHeight: '46px', padding: '0px 11px' }}>{userInfo.phone}</div>
                                    </div>
                                </div>
                                <div style={{ paddingLeft: '40px', margin: '0 auto', marginTop: '20px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '135px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px' }}>{['昵称']}</div>
                                        {
                                            nicknameStatus ?
                                                <Input onChange={e => { setNewNickname(e.target.value) }} value={newNickname}
                                                    style={{ width: '430px', height: '48px', borderRadius: '4px' }} placeholder={['请输入您的昵称']} />
                                                :
                                                <div style={{ border: '1px solid #d9d9d9', borderRadius: '4px', width: '430px', lineHeight: '46px', padding: '0px 11px' }}>{userInfo.nickname}</div>
                                        }
                                        {
                                            nicknameStatus ?
                                                <div>
                                                    <span style={{ display: 'inline-block', color: '#F7385B', marginLeft: '30px', textAlign: 'center', cursor: 'pointer' }} onClick={e => setNickname(false)}>{textJson["设置"]}</span>
                                                    <span style={{ display: 'inline-block', color: '#ccc', marginLeft: '30px', textAlign: 'center', cursor: 'pointer' }} onClick={e => setNicknameStatus(false)}>{textJson["取消"]}</span>
                                                </div>
                                                :
                                                <span style={{ color: '#F7385B', marginLeft: '30px', textAlign: 'center', cursor: 'pointer' }} onClick={e => setNickname(true)}>{textJson["修改"]}</span>
                                        }
                                    </div>
                                </div>
                                {
                                    pwdStatus ?
                                        <div style={{ paddingLeft: '40px', margin: '0 auto', marginTop: '20px' }}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '135px', fontWeight: 600, lineHeight: '48px', paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px' }}>{['登陆密码']}</div>
                                                <div style={{ width: '430px' }}>
                                                    <div id='login'>
                                                        <Form name="basic" autoComplete="off" onFinish={resetPassword}>
                                                            <Form.Item label="" name="code" rules={[{
                                                                required: true,
                                                                validator: (_, value) => {
                                                                    if (value.length == 6) {
                                                                        return Promise.resolve()
                                                                    } else {
                                                                        return Promise.reject(intl.get('Login').VerificationCodeV)
                                                                    }
                                                                }
                                                            }]} >
                                                                <div>
                                                                    <Input onChange={(e) => { setCode(e.target.value) }} placeholder={intl.get('Login').PhoneVerificationCodeT}
                                                                        style={{ height: '45px', width: 430, paddingRight: '130px' }} />
                                                                    <section onClick={getCode} style={{
                                                                        position: 'absolute', top: '1px', right: '1px', width: '130px', height: '45px',
                                                                        textAlign: 'center', cursor: 'pointer', color: isCode ? '#333333' : '#F7385B',
                                                                        lineHeight: '45px', fontSize: '14px', letterSpacing: '0.05px',
                                                                    }}>{isCode ? intl.get('Login').SendVerificationCode : time.toString() + textJson["s后可重新发送"]}</section>
                                                                </div>
                                                            </Form.Item>
                                                            <Form.Item label="" name="password" rules={[{
                                                                required: true,
                                                                validator: (_, value) => {
                                                                    if (value.length >= 6 && value.length <= 16) {
                                                                        return Promise.resolve()
                                                                    } else {
                                                                        return Promise.reject(intl.get('Login').PasswordV)
                                                                    }
                                                                }
                                                            }]} >
                                                                <div>
                                                                    <Input.Password onChange={(e) => { setPassword(e.target.value) }} placeholder={intl.get('Login').PasswordT} style={{ height: '45px', width: 430 }}
                                                                        iconRender={visible => (visible ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/kejian.png'} alt=""
                                                                            style={{ width: '18px', cursor: 'pointer' }} /> : <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/kejians.png'} alt=""
                                                                                style={{ width: '18px', cursor: 'pointer' }} />)} />
                                                                </div>
                                                            </Form.Item>
                                                            <Form.Item>
                                                                <div style={{ display: 'flex' }}>
                                                                    <Button
                                                                        htmlType="submit"
                                                                        className='sub'
                                                                        style={{
                                                                            width: '100%', height: '45px', borderRadius: '4px', border: 0, marginTop: '14px',
                                                                            background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 500, letterSpacing: '2px',
                                                                            color: '#FFF', marginRight: '16px'
                                                                        }}
                                                                    >{textJson["确定修改"]}
                                                                    </Button>
                                                                    <Button
                                                                        className='sub'
                                                                        style={{
                                                                            width: '100%', height: '45px', borderRadius: '4px', border: 0, marginTop: '14px',
                                                                            background: '#EEEEEE', fontWeight: 500, letterSpacing: '2px',
                                                                            color: '#888888'
                                                                        }}
                                                                        onClick={e => { setPwdStatus(!pwdStatus) }}
                                                                    >{textJson["取消修改"]}
                                                                    </Button>
                                                                </div>
                                                            </Form.Item>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div style={{ paddingLeft: '40px', margin: '0 auto', marginTop: '20px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ width: '135px', fontWeight: 600, paddingRight: '25px', textAlign: 'right', color: '#888888', letterSpacing: '1px' }}>{['登陆密码']}</div>
                                                <div style={{ border: '1px solid #d9d9d9', borderRadius: '4px', width: '430px', lineHeight: '46px', padding: '0px 11px' }}>********</div>
                                                <span style={{ color: '#F7385B', marginLeft: '30px', textAlign: 'center', cursor: 'pointer' }} onClick={e => {
                                                    setPwdStatus(!pwdStatus)
                                                    getCodes(true)
                                                    clearInterval(intervalRef.current)
                                                }}>{textJson["修改"]}</span>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div> :
                        // 会员权限用量
                        tabIndex == 1 ?
                            <div style={{ width: '100%' }}>
                                {
                                    userInfo.memberStatus > 0 ?
                                        <div style={{ padding: '35px 90px', position: 'relative' }}>
                                            <div style={{ background: 'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseAccount/bg2.png) no-repeat 100% 100%', width: 155, height: 39, paddingLeft: 35, lineHeight: '39px', color: '#F7385B', position: 'absolute', top: 0, right: 0, cursor: 'pointer' }} onClick={e => setLogsVisible(true)}>
                                                {textJson['会员开通记录']}
                                            </div>
                                            <div className='flex-ac' style={{ marginBottom: '27px' }}>
                                                <div style={{ color: '#666666', marginRight: '15px' }}>{textJson['当前会员版本']}：</div>
                                                {
                                                    userInfo.memberDetail?.memberType === '起航版' ?
                                                        <Image src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/v1.png'} alt='' />
                                                        : userInfo.memberDetail?.memberType === '远航版' ?
                                                            <Image src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/v2.png'} alt='' />
                                                            : userInfo.memberDetail?.memberType === '领航版' ?
                                                                <Image src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/v3.png'} alt='' />
                                                                : ''
                                                }
                                                <div style={{ fontSize: '16px', fontWeight: 800, marginLeft: 10 }}>{userInfo.memberDetail?.memberType ? userInfo.memberDetail?.memberType + '会员' : ''}</div>
                                                <div style={{ marginLeft: '10px', color: '#666' }}>{textJson['有效期至']}{moment(userInfo.memberDetail?.date).format('YYYY-MM-DD')}</div>
                                                <div style={{ width: 2, height: 23, backgroundColor: '#EEEEEE', margin: '0 15px' }}></div>
                                                <div style={{ color: '#666666' }}>{textJson['平台带货佣金比例']}<span style={{ color: '#F7385B', fontWeight: '800' }}>{userInfo.memberDetail?.memberType === '起航版' ? 10 : userInfo.memberDetail?.memberType === '远航版' ? 9 : userInfo.memberDetail?.memberType === '领航版' ? 8 : ''}%</span></div>
                                            </div>
                                            <div>
                                                <div style={{ display: 'flex', border: '1px #eee solid', borderRadius: '3px', marginBottom: '18px' }}>
                                                    <div style={{ width: '40px', height: '200px', writingMode: 'vertical-lr', backgroundColor: '#F6F6F6', textAlign: 'center', lineHeight: '40px' }}>{textJson['运营权益']}</div>
                                                    <div style={{ padding: '0 100px 0 63px', width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                                        <div style={{ width: '45%', height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div className='flex-ac'>
                                                                    <img style={{ width: 16, height: 16, marginRight: '8px' }} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseAccount/icon2.png' />
                                                                    <div style={{ color: '#666666' }}>{textJson['TikTok账号搭建：']}<span style={{ color: '#333' }}><span style={{ color: '#F7385B', fontWeight: 800 }}>{inter.tk_account_number?.currentNum}</span>/<span style={{ fontWeight: '800' }}>{inter.tk_account_number?.currentNumSum}</span></span></div>
                                                                </div>
                                                            </div>
                                                            <Progress strokeColor={{
                                                                '0%': '#FF716E',
                                                                '100%': '#F7385B',
                                                            }} showInfo={false} percent={inter.tk_account_number?.currentNum / inter.tk_account_number?.currentNumSum * 100} />
                                                        </div>
                                                        <div style={{ width: '45%', height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div className='flex-ac'>
                                                                    <img style={{ width: 16, height: 16, marginRight: '8px' }} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseAccount/icon3.png' />
                                                                    <div style={{ color: '#666666' }}>{textJson['混剪短视频数：']}<span style={{ color: '#333' }}><span style={{ color: '#F7385B', fontWeight: 800 }}>{inter.mixed_short_videos_number?.currentNum}</span>/<span style={{ fontWeight: '800' }}>{inter.mixed_short_videos_number?.currentNumSum}</span>个</span></div>
                                                                </div>
                                                                <div style={{ color: '#F7385B', cursor: 'pointer' }} onClick={e => showVideo(1)}>{textJson['查看已交付视频']}</div>
                                                            </div>
                                                            <Progress strokeColor={{
                                                                '0%': '#FF716E',
                                                                '100%': '#F7385B',
                                                            }} showInfo={false} percent={inter.mixed_short_videos_number?.currentNum / inter.mixed_short_videos_number?.currentNumSum * 100} />
                                                        </div>
                                                        <div style={{ width: '45%', height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div className='flex-ac'>
                                                                    <img style={{ width: 16, height: 16, marginRight: '8px' }} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseAccount/icon4.png' />
                                                                    <div style={{ color: '#666666' }}>{textJson['无坑位费直播场次：']}<span style={{ color: '#333' }}><span style={{ color: '#F7385B', fontWeight: 800 }}>{inter.live_number?.currentNum}</span>/<span style={{ fontWeight: '800' }}>{inter.live_number?.currentNumSum}</span>个</span></div>
                                                                </div>
                                                            </div>
                                                            <Progress strokeColor={{
                                                                '0%': '#FF716E',
                                                                '100%': '#F7385B',
                                                            }} showInfo={false} percent={inter.live_number?.currentNum / inter.live_number?.currentNumSum * 100} />
                                                        </div>
                                                        <div style={{ width: '45%', height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div className='flex-ac'>
                                                                    <img style={{ width: 16, height: 16, marginRight: '8px' }} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseAccount/icon5.png' />
                                                                    <div style={{ color: '#666666' }}>{textJson['原创短视频数：']}<span style={{ color: '#333' }}><span style={{ color: '#F7385B', fontWeight: 800 }}>{inter.original_short_videos_number?.currentNum}</span>/<span style={{ fontWeight: '800' }}>{inter.original_short_videos_number?.currentNumSum}</span>个</span></div>
                                                                </div>
                                                                <div style={{ color: '#F7385B', cursor: 'pointer' }} onClick={e => showVideo(2)}>{textJson['查看已交付视频']}</div>
                                                            </div>
                                                            <Progress strokeColor={{
                                                                '0%': '#FF716E',
                                                                '100%': '#F7385B',
                                                            }} showInfo={false} percent={inter.original_short_videos_number?.currentNum / inter.original_short_videos_number?.currentNumSum * 100} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', border: '1px #eee solid', borderRadius: '3px' }}>
                                                    <div style={{ width: '40px', height: '100px', writingMode: 'vertical-lr', backgroundColor: '#F6F6F6', textAlign: 'center', lineHeight: '40px' }}>{textJson['增值权益']}</div>
                                                    <div style={{ padding: '0 100px 0 63px', width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                                        <div style={{ width: '45%', height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div className='flex-ac'>
                                                                    <img style={{ width: 16, height: 16, marginRight: '8px' }} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseAccount/icon6.png' />
                                                                    <div style={{ color: '#666666' }}>{textJson['TikTok实操线下培训：']}<span style={{ color: '#333' }}><span style={{ color: '#F7385B', fontWeight: 800 }}>{inter.tk_offline_training?.currentNum}</span>/<span style={{ fontWeight: '800' }}>{inter.tk_offline_training?.currentNumSum}</span></span></div>
                                                                </div>
                                                            </div>
                                                            <Progress strokeColor={{
                                                                '0%': '#FF716E',
                                                                '100%': '#F7385B',
                                                            }} showInfo={false} percent={inter.tk_offline_training?.currentNum / inter.tk_offline_training?.currentNumSum * 100} />
                                                        </div>
                                                        <div style={{ width: '45%', height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div className='flex-ac'>
                                                                    <img style={{ width: 16, height: 16, marginRight: '8px' }} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseAccount/icon7.png' />
                                                                    <div style={{ color: '#666666' }}>{textJson['TikTok账号运营线上指导：']}<span style={{ color: '#333' }}><span style={{ color: '#F7385B', fontWeight: 800 }}>{inter.tk_online_guidance?.currentNum}</span>/<span style={{ fontWeight: '800' }}>{inter.tk_online_guidance?.currentNumSum}</span></span></div>
                                                                </div>
                                                            </div>
                                                            <Progress strokeColor={{
                                                                '0%': '#FF716E',
                                                                '100%': '#F7385B',
                                                            }} showInfo={false} percent={inter.tk_online_guidance?.currentNum / inter.tk_online_guidance?.currentNumSum * 100} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='flex-acjc' style={{ width: '100%', flexDirection: 'column', paddingTop: '100px' }}>
                                            <Image width={105} height={100} preview={false} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png' alt='' />
                                            <div style={{ marginTop: '23px' }}>{textJson['你还未开通会员，']}<span style={{ color: '#F7385B', cursor: 'pointer' }}>{textJson['去开通']}</span>{textJson['会员']}</div>
                                        </div>
                                }
                            </div> :
                            // 合作付款记录
                            tabIndex == 2 ?
                                <div style={{ width: '100%' }}>
                                    {
                                        false ?
                                            <div className='flex-acjc' style={{ width: '100%', flexDirection: 'column', paddingTop: '100px' }}>
                                                <Image width={105} height={100} preview={false} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png' alt='' />
                                                <div style={{ marginTop: '23px' }}><span style={{ color: '#F7385B', cursor: 'pointer' }}>{textJson['企业认证']}</span>{textJson['成功后，才可以发布需求']}</div>
                                            </div>
                                            :
                                            <div style={{ padding: '40px 30px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '47px' }}>
                                                    <div style={{ color: '#666' }}>{textJson['当前合作总支出']}</div>
                                                    <div style={{ marginLeft: 18, fontWeight: '600', fontSize: '22px' }}>${amount}</div>
                                                </div>
                                                <div style={{
                                                    width: '100%', height: '50px', display: 'flex', alignItems: 'center', backgroundColor: '#F6F6F6',
                                                    fontWeight: 500, paddingLeft: '33px', marginTop: '30px'
                                                }}>
                                                    <div style={{ width: '25%', paddingLeft: '15px' }}>{textJson['商品/品牌']}</div>
                                                    <div style={{ width: '10%', paddingLeft: '15px' }}>{textJson['需求目标']}</div>
                                                    <div style={{ width: '10%', paddingRight: '15px' }}>{textJson['内容形式']}</div>
                                                    <div style={{ width: '10%', paddingRight: '15px' }}>{textJson['媒体传播']}</div>
                                                    <div style={{ width: '35%', paddingRight: '15px' }}>{textJson['合作红人']}</div>
                                                    <div style={{ width: '10%', paddingRight: '15px' }}>{textJson['支付时间']}</div>
                                                    <div style={{ width: '11%', paddingRight: '15px' }}>{textJson['支付佣金']}</div>
                                                </div>
                                                <InfiniteScroll
                                                    dataLength={payList.length}
                                                    next={loadMoreData}
                                                    hasMore={false}
                                                    loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                                                    endMessage={<Divider plain>{intl.get('TopicList').Nothing} 🤐</Divider>}
                                                    scrollableTarget="scrollableDiv"
                                                >
                                                    <List
                                                        dataSource={payList}
                                                        grid={{ gutter: 0, column: 1 }}
                                                        renderItem={
                                                            (item, index) => (
                                                                <div key={index} style={{ paddingTop: 0 }}>
                                                                    {SmallDiv(item, index)}
                                                                </div>
                                                            )
                                                        }
                                                    />
                                                </InfiniteScroll>
                                            </div>
                                    }
                                </div> :
                                // 消息通知
                                tabIndex == 3 ?
                                    <div style={{ width: '100%', paddingLeft: '100px', paddingRight: '100px' }}>
                                        {tab3Index == 0 ?
                                            <div>
                                                {
                                                    news1List.informationList?.length > 0 ?
                                                        news1List.informationList?.map((item, index) => {
                                                            return <div key={index} style={{ display: 'flex', marginTop: '40px' }}>
                                                                <Badge dot={item.readStatus == 2} offset={[-4, 4]}>
                                                                    <img style={{ width: '30px', height: '30px' }} src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/xitong.png" />
                                                                </Badge>
                                                                <div style={{ width: '100%', marginLeft: '20px' }}>
                                                                    <div style={{ display: 'flex', cursor: 'pointer' }}>
                                                                        <div onClick={() => item.extraInfo ? changeChecked(false, index, 1) : null} style={{ maxWidth: 'calc(100% - 80px)', fontWeight: 'bold', color: item.readStatus == 1 ? '#888888' : '#333' }}>{item.title}</div>
                                                                        {
                                                                            item.extraInfo ? <div style={{
                                                                                width: '80px', minWidth: '80px', height: '20px', fontSize: '12px', color: '#888888',
                                                                                background: '#F0F0F0', borderRadius: '4px', textAlign: 'center', marginLeft: '12px', fontWeight: 'normal'
                                                                            }}
                                                                                onClick={() => changeChecked(true, index, 1)}>{textJsonI['查看详情']} <RightOutlined /></div>
                                                                                : !item.isChecked ? <div style={{
                                                                                    width: '80px', minWidth: '80px', height: '20px', fontSize: '12px', color: '#888888',
                                                                                    background: '#F0F0F0', borderRadius: '4px', textAlign: 'center', marginLeft: '12px', fontWeight: 'normal'
                                                                                }}
                                                                                    onClick={() => changeChecked(true, index, 1)}>{textJsonI['展开内容']} <DownOutlined /></div>
                                                                                    : <div style={{
                                                                                        width: '80px', minWidth: '80px', height: '20px', fontSize: '12px', color: '#888888',
                                                                                        background: '#F0F0F0', borderRadius: '4px', textAlign: 'center', marginLeft: '12px', fontWeight: 'normal'
                                                                                    }}
                                                                                        onClick={() => changeChecked(false, index, 1)}>{textJsonI['收起内容']} <UpOutlined /></div>
                                                                        }
                                                                    </div>
                                                                    <div style={{ fontSize: '12px', color: '#888888' }}>{item.createTime}</div>
                                                                    {item.isChecked ? <div style={{
                                                                        width: '100%', maxWidth: '100%', background: '#F0F0F0', borderRadius: '4px', marginTop: '14px',
                                                                        color: '#888888', fontSize: '12px', padding: '10px 25px', lineHeight: '22px'
                                                                    }}>
                                                                        <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                                                    </div> : ''}
                                                                </div>
                                                            </div>
                                                        })
                                                        :
                                                        <div className='flex-acjc' style={{ width: '100%', flexDirection: 'column', paddingTop: '100px' }}>
                                                            <Image width={105} height={100} preview={false} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png' alt='' />
                                                            <div style={{ marginTop: '23px' }}>{textJson['暂无消息通知~']}</div>
                                                        </div>
                                                }
                                            </div> :
                                            tab3Index == 1 ?
                                                <div>
                                                    {
                                                        news2List.informationList?.length > 0 ?
                                                            news2List.informationList?.map((item, index) => {
                                                                return <div key={index} style={{ display: 'flex', marginTop: '40px' }}
                                                                    onClick={() => changeChecked(true, index, 2)}>
                                                                    <Badge dot={item.readStatus == 2} offset={[-4, 4]}>
                                                                        <img style={{ width: '30px', height: '30px' }} src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/shangjia.png" />
                                                                    </Badge>
                                                                    <div style={{ width: '100%', marginLeft: '20px' }}>
                                                                        <div style={{ display: 'flex', cursor: 'pointer' }}>
                                                                            <span style={{ fontWeight: 'bold', color: item.readStatus == 1 ? '#888888' : '#333' }}>{item.title}</span>
                                                                            <div style={{
                                                                                width: '80px', minWidth: '80px', height: '20px', fontSize: '12px', color: '#888888',
                                                                                background: '#F0F0F0', borderRadius: '4px', textAlign: 'center', marginLeft: '12px', fontWeight: 'normal'
                                                                            }}>{textJsonI['查看详情']} <RightOutlined /></div>
                                                                        </div>
                                                                        <div style={{ fontSize: '12px', color: '#888888' }}>{item.createTime}</div>
                                                                    </div>
                                                                </div>
                                                            })
                                                            :
                                                            <div className='flex-acjc' style={{ width: '100%', flexDirection: 'column', paddingTop: '100px' }}>
                                                                <Image width={105} height={100} preview={false} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png' alt='' />
                                                                <div style={{ marginTop: '23px' }}>{textJson['暂无消息通知~']}</div>
                                                            </div>
                                                    }
                                                </div> :
                                                tab3Index == 2 ?
                                                    <div>
                                                        {
                                                            news3List.informationList?.length > 0 ?
                                                                news3List.informationList?.map((item, index) => {
                                                                    return <div key={index} style={{ display: 'flex', marginTop: '40px' }}
                                                                        onClick={() => changeChecked(true, index, 3)}>
                                                                        <Badge dot={item.readStatus == 2} offset={[-4, 4]}>
                                                                            <img style={{ width: '30px', height: '30px', borderRadius: '15px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user/' + (item.title.split('已接受').length > 1 ? 'jieshou' : 'weijieshou') + '.png'} />
                                                                        </Badge>
                                                                        <div style={{ width: '100%', marginLeft: '20px' }}>
                                                                            <div style={{ display: 'flex', cursor: 'pointer' }}>
                                                                                <span style={{ fontWeight: 'bold', color: item.readStatus == 1 ? '#888888' : '#333' }}>{item.title}</span>
                                                                                <div style={{
                                                                                    width: '80px', minWidth: '80px', height: '20px', fontSize: '12px', color: '#888888',
                                                                                    background: '#F0F0F0', borderRadius: '4px', textAlign: 'center', marginLeft: '12px', fontWeight: 'normal'
                                                                                }}>{textJsonI['查看详情']}{item.title.split('已接受').length} <RightOutlined /></div>
                                                                            </div>
                                                                            <div style={{ fontSize: '12px', color: '#888888' }}>{item.createTime}</div>
                                                                        </div>
                                                                    </div>
                                                                })
                                                                :
                                                                <div className='flex-acjc' style={{ width: '100%', flexDirection: 'column', paddingTop: '100px' }}>
                                                                    <Image width={105} height={100} preview={false} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png' alt='' />
                                                                    <div style={{ marginTop: '23px' }}>{textJson['暂无消息通知~']}</div>
                                                                </div>
                                                        }
                                                    </div> : ''
                                        }
                                    </div>
                                    : ''
                }
            </div>
            <Modal
                centered={true}
                visible={logsVisible}
                footer={null}
                closable={false}
                onCancel={() => setLogsVisible(false)}
                getContainer={() => document.getElementById('EnterpriseAccount')}
                width={800}
            >
                <div style={{ width: 800 }}>
                    <CloseCircleOutlined style={{
                        position: 'absolute', right: '29px', top: '29px', fontSize: '20px', color: '#888888',
                        cursor: 'pointer'
                    }}
                        onClick={() => setLogsVisible(false)} />
                    <div style={{ width: '100%', textAlign: 'center', fontSize: '18px', margin: '16px 0 40px', fontWeight: '800' }}>{textJson['会员开通记录']}</div>
                    <div style={{
                        width: '100%', height: '50px', display: 'flex', alignItems: 'center', backgroundColor: '#F6F6F6',
                        fontWeight: 500, paddingLeft: '33px', marginTop: '30px'
                    }}>
                        <div style={{ width: '25%', paddingLeft: '15px' }}>{textJson['会员版本']}</div>
                        <div style={{ width: '30%', paddingLeft: '15px' }}>{textJson['开通时间']}</div>
                        <div style={{ width: '20%', paddingRight: '15px' }}>{textJson['开通时长']}</div>
                        <div style={{ width: '25%', paddingRight: '15px' }}>{textJson['支付会员金额']}</div>
                    </div>
                    <InfiniteScroll
                        dataLength={logsList.length}
                        next={loadMoreData}
                        hasMore={false}
                        loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
                        endMessage={<Divider plain>{intl.get('TopicList').Nothing} 🤐</Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                        <List
                            dataSource={logsList}
                            grid={{ gutter: 0, column: 1 }}
                            renderItem={
                                (item, index) => (
                                    <div key={index} style={{ paddingTop: 0 }}>
                                        {SmallDiv2(item, index)}
                                    </div>
                                )
                            }
                        />
                    </InfiniteScroll>
                </div>
            </Modal>
            <Modal
                visible={videoVisible}
                footer={null}
                closable={false}
                onCancel={() => setVideoVisible(false)}
                getContainer={() => document.getElementById('EnterpriseAccount')}
                width={1560}
                centered={true}
            >
                <div style={{ width: 1560 }}>
                    <CloseCircleOutlined style={{ position: 'absolute', right: '29px', top: '29px', fontSize: '20px', color: '#888888', cursor: 'pointer' }} onClick={() => setVideoVisible(false)} />
                    <div style={{ width: '100%', textAlign: 'center', fontSize: '18px', margin: '16px 0 40px', fontWeight: '800' }}>{videoType === 1 ? textJson['混剪短视频（已交付'] : textJson['原创短视频（已交付']}{videoList.length}{textJson['个）']}</div>
                    <div style={{ maxHeight: 600, overflowY: 'auto', display: 'flex', flexWrap: 'wrap' }}>
                        {
                            videoList.length > 0 ? videoList?.map((item, index) => (
                                <div key={index} style={{
                                    width: '33%', height: '208px', backgroundColor: '#FFF',
                                    marginBottom: '16px', paddingRight: '16px',
                                }}>
                                    <div style={{
                                        width: '100%', height: '100%', padding: '14px', border: '1px solid #EEEEEE', borderRadius: '4px',
                                        display: 'flex'
                                    }}>
                                        <div style={{ width: '130px', height: '180px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', position: 'relative', background: '#eee' }}>
                                            <Image
                                                width={130}
                                                src={item.url + '?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,m_fast'}
                                                fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                                                preview={false} alt='' />
                                        </div>
                                        <div style={{ width: 'calc(100% - 130px)', paddingLeft: '13px', paddingTop: '6px' }}>
                                            <div className='over' style={{ height: '40px', fontSize: '16px', fontWeight: 'bold', lineHeight: '20px' }}>{item.title}</div>
                                            <div style={{ color: '#888' }}>{textJson['视频时长：']}{item.duration}</div>
                                            <div style={{ color: '#888' }}>{textJson['交付时间：']}{item.deliveryTime}</div>
                                            <div style={{ display: 'flex', marginTop: '50px' }}>
                                                <div style={{
                                                    width: '127px', height: '30px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                                    display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                                                    borderRadius: '4px', cursor: 'pointer', border: 0
                                                }}
                                                    onClick={() => playVideo(item.url)}>
                                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/bofang.png'} alt=""
                                                        style={{ width: '11px', marginRight: '4px', }} />{textJson['播放视频']}
                                                </div>
                                                <div style={{
                                                    width: '127px', height: '30px', background: '#FFF',
                                                    display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#888888', fontSize: '12px',
                                                    borderRadius: '4px', cursor: 'pointer', border: '1px solid #EEEEEE', marginLeft: '8px'
                                                }}
                                                    onClick={(e) => download(item.url)}>
                                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/EnterpriseAccount/xiazai-.png'} alt=""
                                                        style={{ width: '14px', marginRight: '4px', }} />{textJson['下载视频']}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : ''
                        }
                    </div>
                </div>
            </Modal>
            <Modal visible={videoVisible2} centered={true} footer={null} onCancel={e => { setVideoVisible2(false) }} destroyOnClose={true} width={900}
                getContainer={() => document.getElementById('EnterpriseAccount')}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <video width="800" height="500" controls>
                        <source src={url} type="video/mp4" />
                        <source src={url} type="video/ogg" />
                        您的浏览器不支持 video 标签。
                    </video>
                </div>
            </Modal>
        </div>
    )
}

export default withRouter(EnterpriseAccount);