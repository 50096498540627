import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import intl from 'react-intl-universal';
import { useNavigate } from 'react-router-dom'
import { getMembershipPrice, getUserInfo } from '../../../service/https'
import '../../../asset/css/Member.scss'
import store from '../../../utils/redux/index'
import { message, Spin } from 'antd';
import moment from 'moment'

const Member = (props) => {
    const navigate = useNavigate();
    const [textJson, setTextJson] = useState(intl.get('member'))
    const [lng, setLng] = useState(localStorage.getItem('locale'))
    const [userInfo, setUserInfo] = useState({})
    const [data, setData] = useState([])
    const [time, setTime] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getUserInfoFun()
        getMembershipPriceFun()
    }, [])

    const getUserInfoFun = () => {
        setLoading(true)
        getUserInfo().then(res => {
            if (res.code === 200) {
                setUserInfo(res.data)
            }
            setLoading(false)
        })
    }

    const getMembershipPriceFun = () => {
        getMembershipPrice().then(res => {
            if (res.code === 200) {
                setTime(res.data.memberValidity)
                setData(res.data.membershipPrices)
            }
        })
    }

    return (
        <Spin spinning={loading}>
            <div id='Member'>
                <div className='top'>
                    {
                        // userInfo.memberStatus ?
                        //     <div>
                        //         <div style={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 900, fontSize: '19px' }}>{textJson.accountStatus}<div style={{ background: 'linear-gradient(180deg, #F7385B, #FF716E)', borderRadius: '32px', width: 100, height: 32, lineHeight: '30px', textAlign: 'center', marginLeft: '14px', fontSize: '14px', fontFamily: 'sans-medium' }}>{
                        //             userInfo.memberDetail.memberType === '起航版' ?
                        //                 textJson.vip :
                        //                 userInfo.memberDetail.memberType === '远航版' ?
                        //                     textJson.vipPor :
                        //                     userInfo.memberDetail.memberType === '领航版' ?
                        //                         textJson.vipPorMax : 'error'
                        //         }</div></div>
                        //         {/*<div style={{ textAlign: 'center', color: '#ccc', marginTop: '13px' }}>{textJson.expiryDate}{moment(userInfo.memberDetail?.date).format('YYYY-MM-DD')}</div>*/}
                        //     </div>
                        //     :
                        //     <div>
                        //         <div style={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 900, fontSize: '19px' }}>{textJson.accountStatus}<div style={{ backgroundColor: '#ccc', borderRadius: '32px', width: 100, height: 32, lineHeight: '30px', textAlign: 'center', marginLeft: '14px', fontSize: '14px', fontFamily: 'sans-medium' }}>{textJson.status}</div></div>
                        //         <div style={{ textAlign: 'center', color: '#ccc', marginTop: '13px' }}>{textJson.tips}</div>
                        //     </div>
                    }
                    <div className='content'>
                        {
                            data?.map((item, index) =>
                                index == 2 ? <div className='box' key={index}>
                                    <div>{item.id === 1 ? textJson.vip : item.id === 2 ? textJson.vipPor : item.id === 3 ? textJson.vipPorMax : 'error'}</div>
                                    <div>
                                        <span>￥</span>
                                        <span>{item.membershipYearPrice}</span>
                                        {lng === 'en' ? '/year' : '/年'}
                                    </div>
                                    <div onClick={e => {
                                        if (userInfo.memberDetail?.memberType === item.membershipType || !userInfo.memberStatus)
                                            navigate('/index/member/buyVip', { state: { vipType: index + 1, data: item, phone: userInfo.phone, time: time } })
                                        else
                                            message.warn("暂不支持开通其他版本~")
                                    }}>
                                        {textJson.buy}<img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/go.png'} alt='' style={{ width: 30, height: 30 }}></img>
                                    </div>
                                </div> : ''
                            )
                        }
                    </div>
                </div>
                <div className='list'>
                    {/*<div className='list_box'>*/}

                    {/*    <div className='title'>{textJson["AI数据分析"]}</div>*/}

                    {/*    <div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["博主排行榜"]}</div>*/}
                    {/*            <div>{textJson["前200条"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["视频排行榜"]}</div>*/}
                    {/*            <div>{textJson["前200条"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["话题排行榜"]}</div>*/}
                    {/*            <div>{textJson["前200条"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["商品排行榜"]}</div>*/}
                    {/*            <div>{textJson["前200条"]}</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className='title'>{textJson["平台权益"]}</div>*/}
                    {/*    <div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["短视频需求发布"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["直播需求发布"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["样品管理"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["红人库"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["外部平台"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["收藏管理"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className='title'>{textJson["带货权益"]}</div>*/}
                    {/*    <div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["TikTok开店指导"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["带货网红数"]}</div>*/}
                    {/*            <div>{textJson["不限"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["带货产品数"]}</div>*/}
                    {/*            <div>{textJson["10个"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["平台带货佣金比例"]}</div>*/}
                    {/*            <div>{textJson["10%"]}</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className='title'>{textJson["运营权益"]}</div>*/}
                    {/*    <div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>*/}
                    {/*                <div>{textJson["TikTok账号搭建"]}</div>*/}
                    {/*            </div>*/}
                    {/*            <div>{textJson["1个账号"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>*/}
                    {/*                <div>{textJson["混剪短视频数"]}</div>*/}
                    {/*                <div style={{ color: '#68C33B', fontSize: '11px' }}>{textJson["短视频时长30-120s"]}</div>*/}
                    {/*            </div>*/}
                    {/*            <div>{textJson["30个"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>*/}
                    {/*                <div>{textJson["原创短视频数"]}</div>*/}
                    {/*                <div style={{ color: '#68C33B', fontSize: '11px' }}>{textJson["短视频时长30-120s"]}</div>*/}
                    {/*            </div>*/}
                    {/*            <div>{textJson["0个"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>*/}
                    {/*                <div>{textJson["无坑位费直播场次"]}</div>*/}
                    {/*                <div style={{ color: '#68C33B', fontSize: '11px' }}>{textJson["直播时间不低于1小时"]}</div>*/}
                    {/*            </div>*/}
                    {/*            <div>{textJson["0场"]}</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className='title'>{textJson["增值权益"]}</div>*/}
                    {/*    <div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["平台banner推广"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/X.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["新品推荐榜"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/X.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["1V1商家服务"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/X.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["三方平台对接辅导"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/X.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["独立站搭建辅导"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/X.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["直播辅导"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/X.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["商业变现辅导"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/X.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["国际物流和海外仓对接服务"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/X.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["TikTok实操线下培训"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/X.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["TikTok账号运营线上指导"]}</div>*/}
                    {/*            <div>{textJson["3个月"]}</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*</div>*/}
                    {/*<div className='list_box'>*/}

                    {/*    <div className='title'>{textJson["AI数据分析"]}</div>*/}

                    {/*    <div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["博主排行榜"]}</div>*/}
                    {/*            <div>{textJson["前500条"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["视频排行榜"]}</div>*/}
                    {/*            <div>{textJson["前500条"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["话题排行榜"]}</div>*/}
                    {/*            <div>{textJson["前500条"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["商品排行榜"]}</div>*/}
                    {/*            <div>{textJson["前500条"]}</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className='title'>{textJson["平台权益"]}</div>*/}
                    {/*    <div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["短视频需求发布"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["直播需求发布"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["样品管理"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["红人库"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["外部平台"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["收藏管理"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className='title'>{textJson["带货权益"]}</div>*/}
                    {/*    <div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["TikTok开店指导"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["带货网红数"]}</div>*/}
                    {/*            <div>{textJson["不限"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["带货产品数"]}</div>*/}
                    {/*            <div>{textJson["50个"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["平台带货佣金比例"]}</div>*/}
                    {/*            <div>{textJson["9%"]}</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className='title'>{textJson["运营权益"]}</div>*/}
                    {/*    <div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>*/}
                    {/*                <div>{textJson["TikTok账号搭建"]}</div>*/}
                    {/*            </div>*/}
                    {/*            <div>{textJson["1个账号"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>*/}
                    {/*                <div>{textJson["混剪短视频数"]}</div>*/}
                    {/*                <div style={{ color: '#68C33B', fontSize: '11px' }}>{textJson["短视频时长30-120s"]}</div>*/}
                    {/*            </div>*/}
                    {/*            <div>{textJson["60个"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>*/}
                    {/*                <div>{textJson["原创短视频数"]}</div>*/}
                    {/*                <div style={{ color: '#68C33B', fontSize: '11px' }}>{textJson["短视频时长30-120s"]}</div>*/}
                    {/*            </div>*/}
                    {/*            <div>{textJson["4个"]}</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>*/}
                    {/*                <div>{textJson["无坑位费直播场次"]}</div>*/}
                    {/*                <div style={{ color: '#68C33B', fontSize: '11px' }}>{textJson["直播时间不低于1小时"]}</div>*/}
                    {/*            </div>*/}
                    {/*            <div>{textJson["2场"]}</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className='title'>{textJson["增值权益"]}</div>*/}
                    {/*    <div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["平台banner推广"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duihao.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["新品推荐榜"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duihao.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["1V1商家服务"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duihao.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["三方平台对接辅导"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duihao.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["独立站搭建辅导"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duihao.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["直播辅导"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duihao.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["商业变现辅导"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/X.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["国际物流和海外仓对接服务"]}</div>*/}
                    {/*            <div>*/}
                    {/*                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/X.png'} alt="" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["TikTok实操线下培训"]}</div>*/}
                    {/*            <div>*/}
                    {/*                {textJson["1个学位"]}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='content'>*/}
                    {/*            <div>{textJson["TikTok账号运营线上指导"]}</div>*/}
                    {/*            <div>{textJson["半年"]}</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*</div>*/}
                    <div className='list_box'>

                        <div className='title'>{textJson["AI数据分析"]}</div>

                        <div>
                            <div className='content'>
                                <div>{textJson["博主排行榜"]}</div>
                                <div>{textJson["无限制"]}</div>
                            </div>
                            <div className='content'>
                                <div>{textJson["视频排行榜"]}</div>
                                <div>{textJson["无限制"]}</div>
                            </div>
                            <div className='content'>
                                <div>{textJson["话题排行榜"]}</div>
                                <div>{textJson["无限制"]}</div>
                            </div>
                            <div className='content'>
                                <div>{textJson["商品排行榜"]}</div>
                                <div>{textJson["无限制"]}</div>
                            </div>
                        </div>

                        <div className='title'>{textJson["平台权益"]}</div>
                        <div>
                            <div className='content'>
                                <div>{textJson["短视频需求发布"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />
                                </div>
                            </div>
                            <div className='content'>
                                <div>{textJson["直播需求发布"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />
                                </div>
                            </div>
                            <div className='content'>
                                <div>{textJson["样品管理"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />
                                </div>
                            </div>
                            <div className='content'>
                                <div>{textJson["红人库"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />
                                </div>
                            </div>
                            <div className='content'>
                                <div>{textJson["外部平台"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />
                                </div>
                            </div>
                            <div className='content'>
                                <div>{textJson["收藏管理"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className='title'>{textJson["带货权益"]}</div>
                        <div>
                            <div className='content'>
                                <div>{textJson["TikTok开店指导"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duiha.png'} alt="" />
                                </div>
                            </div>
                            <div className='content'>
                                <div>{textJson["带货网红数"]}</div>
                                <div>{textJson["不限"]}</div>
                            </div>
                            <div className='content'>
                                <div>{textJson["带货产品数"]}</div>
                                <div>{textJson["200个"]}</div>
                            </div>
                            <div className='content'>
                                <div>{textJson["平台带货佣金比例"]}</div>
                                <div>{textJson["8%"]}</div>
                            </div>
                        </div>

                        <div className='title'>{textJson["运营权益"]}</div>
                        <div>
                            <div className='content'>
                                <div>
                                    <div>{textJson["TikTok账号搭建"]}</div>
                                </div>
                                <div>{textJson["2个账号"]}</div>
                            </div>
                            <div className='content'>
                                <div>
                                    <div>{textJson["混剪短视频数"]}</div>
                                    <div style={{ color: '#68C33B', fontSize: '11px' }}>{textJson["短视频时长30-120s"]}</div>
                                </div>
                                <div>{textJson["120个"]}</div>
                            </div>
                            <div className='content'>
                                <div>
                                    <div>{textJson["原创短视频数"]}</div>
                                    <div style={{ color: '#68C33B', fontSize: '11px' }}>{textJson["短视频时长30-120s"]}</div>
                                </div>
                                <div>{textJson["10个"]}</div>
                            </div>
                            <div className='content'>
                                <div>
                                    <div>{textJson["无坑位费直播场次"]}</div>
                                    <div style={{ color: '#68C33B', fontSize: '11px' }}>{textJson["直播时间不低于1小时"]}</div>
                                </div>
                                <div>{textJson["5场"]}</div>
                            </div>
                        </div>

                        <div className='title'>{textJson["增值权益"]}</div>
                        <div>
                            <div className='content'>
                                <div>{textJson["平台banner推广"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duihao.png'} alt="" />
                                </div>
                            </div>
                            <div className='content'>
                                <div>{textJson["新品推荐榜"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duihao.png'} alt="" />
                                </div>
                            </div>
                            <div className='content'>
                                <div>{textJson["1V1商家服务"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duihao.png'} alt="" />
                                </div>
                            </div>
                            <div className='content'>
                                <div>{textJson["三方平台对接辅导"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duihao.png'} alt="" />
                                </div>
                            </div>
                            <div className='content'>
                                <div>{textJson["独立站搭建辅导"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duihao.png'} alt="" />
                                </div>
                            </div>
                            <div className='content'>
                                <div>{textJson["直播辅导"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duihao.png'} alt="" />
                                </div>
                            </div>
                            <div className='content'>
                                <div>{textJson["商业变现辅导"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duihao.png'} alt="" />
                                </div>
                            </div>
                            <div className='content'>
                                <div>{textJson["国际物流和海外仓对接服务"]}</div>
                                <div>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/member/duihao.png'} alt="" />
                                </div>
                            </div>
                            <div className='content'>
                                <div>{textJson["TikTok实操线下培训"]}</div>
                                <div>{textJson["2个学位"]}</div>
                            </div>
                            <div className='content'>
                                <div>{textJson["TikTok账号运营线上指导"]}</div>
                                <div>{textJson["1年"]}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Spin>
    )
}

export default withRouter(Member);